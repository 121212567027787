import { fromPairs } from 'lodash';
import {
  populateEventAttendees,
  populateEventAttendeeLogs,
  updateEventAttendee,
  populateAttendeeShifts,
} from './actions/populateEventAttendees';
import populateEventInfo from './actions/populateEventInfo';
import populateAttendingCharacters from './actions/populateAttendingCharacters';
import adjustShift from './actions/adjustShift';
import {
  transformTransactionPartners,
  extractUserInfo,
  extractUserId,
} from './actions/transactionPartners';

export const initialState = {
  eventAttendees: {},
  eventPlayers: [],
  attendeeShifts: {},
  attendingCharacters: {},
  isFetching: false,
  eventInfo: {},
  filters: {},
  itemTaxonomies: {},
  transactionPartners: {
    isSearching: false,
    data: {},
  },
  transaction: {
    transactions: null,
    isExecuting: false,
  },
  eventTransactions: [],
  registeredItems: {},
  lockboxes: {
    inventories: {},
    items: {},
    registeredItems: {},
    collapses: {},
  },
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'ADJUST_SHIFT':
      return {
        ...state,
        attendeeShifts: adjustShift({
          attendeeShifts: state.attendeeShifts,
          payload,
        }),
      };
    case 'EXECUTE_TRANSACTION':
      return {
        ...state,
        transaction: { id: null, isExecuting: true },
      };
    case 'EXECUTE_TRANSACTION_SUCCEEDED':
      return {
        ...state,
        transaction: {
          transactions: payload,
          isExecuting: false,
        },
      };
    case 'EXECUTE_TRANSACTION_FAILED':
      return {
        ...state,
        transaction: {
          transactions: [],
          isExecuting: false,
        },
      };
    case 'FETCH_EVENT_TRANSACTIONS_SUCCEEDED':
      return {
        ...state,
        eventTransactions: fromPairs(payload.map(x => [x.id, x])),
      };
    case 'FETCH_EVENT_ATTENDEES':
    case 'FETCH_EVENT_ATTENDEE_SHIFTS':
    case 'FETCH_ATTENDING_CHARACTERS':
      return {
        ...state,
        isFetching: true,
      };
    case 'TOGGLE_CHARACTER_INVENTORY':
      return {
        ...state,
        lockboxes: {
          ...state.lockboxes,
          collapses: {
            ...state.lockboxes.collapses,
            [payload.characterId]: payload.value,
          },
        },
      };
    case 'HIDE_ITEM_TRANSACTION':
      return {
        ...state,
        lockboxes: {
          ...state.lockboxes,
          inventories: {
            ...state.lockboxes.inventories,
            [payload.characterId]: {
              ...state.lockboxes.inventories[payload.characterId],
              [payload.accessorId]: {
                ...state.lockboxes.inventories[payload.characterId][
                  payload.accessorId
                ],
                isExpanded: false,
              },
            },
          },
        },
      };
    case 'FETCH_ITEM_TRANSACTIONS':
      return {
        ...state,
        lockboxes: {
          ...state.lockboxes,
          inventories: {
            ...state.lockboxes.inventories,
            [payload.characterId]: {
              ...state.lockboxes.inventories[payload.characterId],
              [payload.accessorId]: {
                ...state.lockboxes.inventories[payload.characterId][
                  payload.accessorId
                ],
                isExpanded: true,
              },
            },
          },
        },
      };
    case 'FETCH_ITEM_TRANSACTIONS_SUCCEEDED':
      return {
        ...state,
        lockboxes: {
          ...state.lockboxes,
          inventories: {
            ...state.lockboxes.inventories,
            [payload.characterId]: {
              ...state.lockboxes.inventories[payload.characterId],
              [payload.accessorId]: {
                ...state.lockboxes.inventories[payload.characterId][
                  payload.accessorId
                ],
                isExpanded: true,
                transactions: payload,
              },
            },
          },
        },
      };
    case 'FETCH_INVENTORY_SUCCEEDED':
      const serialize = x =>
        `${x.item_id}-${x.registered_item_id}${
          x.augmentations.length > 0
            ? [''].concat(x.augmentations.sort()).join('-')
            : ''
        }`;

      return {
        ...state,
        lockboxes: {
          ...state.lockboxes,
          inventories: {
            ...state.lockboxes.inventories,
            [payload.characterId]: fromPairs(
              payload.data.inventories.map(x => [
                serialize(x),
                {
                  ...x,
                  isExpanded:
                    state.lockboxes.inventories[payload.characterId] &&
                    state.lockboxes.inventories[payload.characterId][
                      serialize(x)
                    ].isExpanded,
                  transactions:
                    state.lockboxes.inventories[payload.characterId] &&
                    state.lockboxes.inventories[payload.characterId][
                      serialize(x)
                    ].transactions,
                },
              ]),
            ),
          },
          items: {
            ...state.lockboxes.items,
            ...fromPairs(payload.data.items.map(x => [x.id, x])),
          },
          registeredItems: {
            ...state.lockboxes.registeredItems,
            ...fromPairs(payload.data.registered_items.map(x => [x.id, x])),
          },
        },
      };
    case 'FETCH_TAXONOMIES_SUCCEEDED':
      return {
        ...state,
        itemTaxonomies: {
          ...state.itemTaxonomies,
          ...fromPairs(payload.map(x => [x.name, x.items])),
        },
      };
    case 'FETCH_TRANSACTION_PARTNERS':
      return {
        ...state,
        transactionPartners: {
          ...state.transactionPartners,
          isSearching: true,
        },
      };
    case 'FETCH_TRANSACTION_PARTNERS_SUCCEEDED':
      return {
        ...state,
        transactionPartners: {
          ...state.transactionPartners,
          isSearching: false,
          data: {
            ...state.transactionPartners.data,
            [extractUserId(payload)]: {
              ...state.transactionPartners[extractUserId(payload)],
              user: extractUserInfo(payload),
              characters: transformTransactionPartners(payload),
            },
          },
        },
      };
    case 'FETCH_TRANSACTION_PARTNERS_FAILED':
      return {
        ...state,
        transactionPartners: {
          ...state.transactionPartners,
          isSearching: false,
          data: {
            ...state.transactionPartners.data,
            [payload]: null,
          },
        },
      };
    case 'FETCH_REGISTERED_ITEMS_SUCCEEDED':
      return {
        ...state,
        registeredItems: {
          ...state.registeredItems,
          [payload.characterId]: payload.data,
        },
      };
    case 'FETCH_EVENT_ATTENDEES_FAILED':
      return {
        ...state,
        isFetching: false,
        eventInfo: { error: payload.message },
      };
    case 'FETCH_EVENT_ATTENDEES_COMPLETED':
      return {
        ...state,
        isFetching: false,
        eventInfo: populateEventInfo(payload),
        eventAttendees: populateEventAttendees(
          payload.event_attendees,
          Date.parse(payload.starts_at),
        ),
      };
    case 'FETCH_EVENT_PLAYERS_COMPLETED':
      return {
        ...state,
        eventPlayers: payload,
      };
    case 'FETCH_ATTENDING_CHARACTERS_COMPLETED':
      return {
        ...state,
        isFetching: false,
        eventInfo: populateEventInfo(payload),
        attendingCharacters: populateAttendingCharacters(
          payload.event_attendees,
        ),
      };
    case 'FETCH_ATTENDEE_LOGS_COMPLETED':
      return {
        ...state,
        isFetching: false,
        eventInfo: populateEventInfo(payload),
        eventAttendees: populateEventAttendeeLogs(payload.event_attendees),
      };
    case 'FETCH_ATTENDEE_SHIFTS_COMPLETED':
      return {
        ...state,
        isFetching: false,
        eventInfo: populateEventInfo(payload),
        attendeeShifts: populateAttendeeShifts(payload.shifts),
      };
    case 'UPDATE_ATTENDEE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          [payload.type]: payload.value,
        },
      };
    case 'UPDATE_ATTENDEE':
      return {
        ...state,
        eventAttendees: {
          ...state.eventAttendees,
          [payload.attendeeID]: {
            ...state.eventAttendees[payload.attendeeID],
            ...payload.changes,
          },
        },
      };
    case 'UPDATE_ATTENDEE_LOGS_SUCCEEDED':
      return {
        ...state,
        eventAttendees: {
          ...state.eventAttendees,
          [payload.id]: {
            ...state.eventAttendees[payload.id],
            logs: payload.logs,
          },
        },
      };
    case 'UPDATE_ATTENDEE_BUILD_GROWTH_PENDING':
      return {
        ...state,
        eventAttendees: {
          ...state.eventAttendees,
          [payload.attendeeID]: {
            ...state.eventAttendees[payload.attendeeID],
            buildGrowth: `[${payload.value}]`,
          },
        },
      };
    case 'UPGRADE_TICKET_PENDING':
      return {
        ...state,
        eventAttendees: {
          ...state.eventAttendees,
          [payload]: {
            ...state.eventAttendees[payload],
            ticketType: 'updating...',
            ticketCost: '',
            ticketIdentifier: '',
          },
        },
      };
    case 'UPDATE_ATTENDEE_RESPONSE_RECEIVED':
      return {
        ...state,
        eventAttendees: {
          ...state.eventAttendees,
          [payload.id]: {
            ...state.eventAttendees[payload.id],
            ...updateEventAttendee(
              payload,
              payload.attendee_characters,
              state.eventAttendees[payload.id].characters,
            ),
          },
        },
      };
    case 'UPDATE_TRANSACTION_LEGITIMACY_SUCCEEDED':
      return {
        ...state,
        eventTransactions: {
          ...state.eventTransactions,
          [payload.id]: payload.data,
        },
      };
    case 'UPDATE_TRANSACTION_DESCRIPTION_SUCCEEDED':
      return {
        ...state,
        eventTransactions: {
          ...state.eventTransactions,
          [payload.id]: payload.data,
        },
      };
    case 'UPGRADE_TICKET_SUCCEEDED':
      return {
        ...state,
        eventAttendees: {
          ...state.eventAttendees,
          [payload.id]: {
            ...state.eventAttendees[payload.id],
            ...{
              ticketType: payload.ticket.label,
              ticketIdentifier: payload.ticket.identifier,
              ticketCost: payload.ticket.cost,
            },
          },
        },
        eventInfo: {
          ...state.eventInfo,
          tickets: payload.event.tickets,
        },
      };
    case 'UPGRADE_TICKET_FAILED':
      return {
        ...state,
        eventAttendees: {
          ...state.eventAttendees,
          [payload.id]: {
            ...state.eventAttendees[payload.id],
            ...payload.previousPayload,
          },
        },
      };
    case 'CANCEL_ATTENDEE':
      return {
        ...state,
        eventAttendees: fromPairs(
          Object.keys(state.eventAttendees)
            .filter(x => x !== payload.attendeeID.toString())
            .map(x => [x, state.eventAttendees[x]]),
        ),
      };
    case 'CANCEL_ATTENDEE_CHARACTER':
      return {
        ...state,
        eventAttendees: {
          ...state.eventAttendees,
          [payload.attendeeID]: {
            ...state.eventAttendees[payload.attendeeID],
            characters: fromPairs(
              Object.keys(state.eventAttendees[payload.attendeeID].characters)
                .filter(x => x !== payload.attendeeCharacterID.toString())
                .map(x => [
                  x,
                  state.eventAttendees[payload.attendeeID].characters[x],
                ]),
            ),
          },
        },
      };

    case 'UPDATE_ATTENDEE_CHARACTER':
      return {
        ...state,
        eventAttendees: {
          ...state.eventAttendees,
          [payload.attendeeID]: {
            ...state.eventAttendees[payload.attendeeID],
            characters: {
              ...state.eventAttendees[payload.attendeeID].characters,
              [payload.attendeeCharacterID]: {
                ...state.eventAttendees[payload.attendeeID].characters[
                  payload.attendeeCharacterID
                ],
                ...payload.changes,
              },
            },
          },
        },
      };

    default:
      return state;
  }
};
