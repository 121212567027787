import React from 'react';
import { range } from 'lodash';
import styles from './BackSheet.module.scss';

export default ({ isPrintable, isBackPrintable, isSample }) => {
  const tableMaker = i => (
    <table className={styles.table} key={i}>
      <thead>
        <tr>
          <th className={styles.filler}>
            Circle Day: Th F Sa Su | Morning | Evening
          </th>
          <th className={styles.staffInitials}>Staff Initials</th>
        </tr>
      </thead>
      <tbody>
        {range(14).map(r => (
          <tr key={r}>
            <td className={styles.row} />
            <td className={styles.row} />
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div
      className={[
        styles.page,
        isPrintable && isBackPrintable ? '' : styles.notPrintable,
      ].join(' ')}
    >
      <div
        className={[styles.placebo, isSample ? styles.sample : ''].join(' ')}
      >
        {isSample
          ? 'Press CTRL + P. The actual page will show up in preview.'
          : 'Back of Character Sheet Will Be Printed'}
      </div>
      <div className={styles.twoColumns}>
        <div className={styles.left}>{range(3).map(i => tableMaker(i))}</div>
        <div className={styles.right}>{range(3).map(i => tableMaker(i))}</div>
      </div>
      <div className={styles.note}>
        All Skill Use, Brews Consumed, And Effects Received MUST Be Recorded.
        <br />
        Sheets May Be Audited By Card Check Or Post Event. Failure To Have Your
        Card Signed During A Card
        <br />
        Check Will Result In Lost XP And Additional Corrective Actions.
      </div>
    </div>
  );
};
