import { all, put, select, takeLatest } from 'redux-saga/effects';
import { startCase } from 'lodash';
import axios from 'axios';
import Toast from '../Shared/Toastify/Toast';

function* fetchBranchAttendances({ payload }) {
  const authConfig = yield select(state => state.user.session);
  const queryString = () => {
    switch (payload.category) {
      case 'inactives':
        return '?inactives=true';
      case 'actives':
        return '?actives=true';
      case 'travelers':
        return '?travelers=true';
      default:
        return '';
    }
  };

  try {
    const res = yield axios.get(
      `/branches/${payload.branchID}/attendances${queryString()}`,
      authConfig,
    );

    yield put({
      type: 'FETCH_BRANCH_ATTENDANCES_SUCCEEDED',
      payload: {
        branchID: payload.branchID,
        category: payload.category || 'staffs',
        data: res.data,
      },
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* fetchBranchFinancials({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield axios.get(`/statistics`, authConfig);

    yield put({
      type: 'FETCH_BRANCH_FINANCIALS_SUCCEEDED',
      payload: {
        branchID: payload.branchID,
        category: 'financials',
        data: res.data,
      },
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* fetchBranchTransfers({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield axios.get(`/branches/${payload}/transfers`, authConfig);
    yield put({
      type: 'FETCH_BRANCH_TRANSFERS_SUCCEEDED',
      payload: {
        branchID: payload,
        data: res.data,
      },
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* fetchBranchMemberships() {
  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield axios.get(`/memberships/granted_memberships`, authConfig);
    yield put({
      type: 'FETCH_BRANCH_MEMBERSHIPS_SUCCEEDED',
      payload: { data: res.data },
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* fetchBranchRevenue({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield axios.get(`/branches/${payload}/revenue`, authConfig);
    yield put({
      type: 'FETCH_BRANCH_REVENUE_SUCCEEDED',
      payload: { data: res.data, branchID: payload },
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* fetchBranchTickets({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield axios.get(`/branches/${payload}/tickets`, authConfig);
    yield put({
      type: 'FETCH_BRANCH_TICKETS_SUCCEEDED',
      payload: { data: res.data, branchID: payload },
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* toggleUserFlag({ payload }) {
  const authConfig = yield select(state => state.user.session);
  const endpoint = payload.value ? 'add_flags' : 'remove_flags';

  try {
    yield axios.post(
      `/users/${payload.userID}/${endpoint}`,
      { flags: [payload.flag] },
      authConfig,
    );
    Toast({
      text: `${startCase(payload.flag)} privilege successfully ${
        payload.value ? 'granted to' : 'revoked from'
      } #${payload.userID}`,
      type: 'success',
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* watchFetchBranchAttendances() {
  yield takeLatest('FETCH_BRANCH_ATTENDANCES', fetchBranchAttendances);
}

function* watchFetchBranchFinancials() {
  yield takeLatest('FETCH_BRANCH_FINANCIALS', fetchBranchFinancials);
}

function* watchFetchBranchTransfers() {
  yield takeLatest('FETCH_BRANCH_TRANSFERS', fetchBranchTransfers);
}

function* watchFetchBranchMemberships() {
  yield takeLatest('FETCH_BRANCH_MEMBERSHIPS', fetchBranchMemberships);
}

function* watchFetchBranchRevenue() {
  yield takeLatest('FETCH_BRANCH_REVENUE', fetchBranchRevenue);
}

function* watchFetchBranchTickets() {
  yield takeLatest('FETCH_BRANCH_TICKETS', fetchBranchTickets);
}

function* watchToggleUserFlag() {
  yield takeLatest('TOGGLE_USER_FLAG', toggleUserFlag);
}

export default function* () {
  yield all([
    watchFetchBranchAttendances(),
    watchFetchBranchFinancials(),
    watchFetchBranchTransfers(),
    watchFetchBranchMemberships(),
    watchFetchBranchRevenue(),
    watchFetchBranchTickets(),
    watchToggleUserFlag(),
  ]);
}
