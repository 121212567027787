export const initialState = {
  earned: null,
  used: 0,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'SYNC_BUFFER':
      return {
        ...state,
        ...payload.totalBuild,
      };
    default:
      return state;
  }
};
