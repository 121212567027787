const WITH_SERIALIZE = true;

const arbiter = (key, serialize = false, session = false) => {
  const get = () => {
    const ls = session
      ? sessionStorage.getItem(key)
      : localStorage.getItem(key);
    if (ls && serialize) return JSON.parse(ls);
    return ls;
  };
  const set = payload => {
    const ls = x =>
      session ? sessionStorage.setItem(key, x) : localStorage.setItem(key, x);
    return ls(serialize ? JSON.stringify(payload) : payload);
  };

  return {
    get,
    set,
  };
};

export const characterStorage = () =>
  arbiter('characterStorage', WITH_SERIALIZE);

export const currentCharacterID = () => arbiter('currentCharacterID');

export const currentDataVersion = () => arbiter('currentDataVersion');

export const ui = () => arbiter('ui', WITH_SERIALIZE);

export const user = () => arbiter('user', WITH_SERIALIZE);

export const tradingUi = () => arbiter('tradingUi', WITH_SERIALIZE);

export const postalUi = () => arbiter('postalUi', WITH_SERIALIZE);
