export const HOURS_DISPLAY_SHIFT = 6;
export const DAYS = {
  0: 'Special',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
  8: 'Cleanup',
};
