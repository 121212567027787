export default ({ state, payload }) => ({
  strain: payload.strain,
  stats: {
    body: {
      ...state.characterStorage[state.currentCharacterID].stats.body,
      added: payload.body,
    },
    mind: {
      ...state.characterStorage[state.currentCharacterID].stats.mind,
      added: payload.mind,
    },
    resolve: {
      ...state.characterStorage[state.currentCharacterID].stats.resolve,
      added: payload.resolve,
    },
    infection: {
      ...state.characterStorage[state.currentCharacterID].stats.infection,
      added: payload.infection,
    },
    deaths: {
      count: payload.deaths,
    },
  },
  skills: {
    ...state.characterStorage[state.currentCharacterID].skills,
    trees: {
      ...state.characterStorage[state.currentCharacterID].skills.trees,
      ...payload.skills,
    },
  },
  faith: payload.faith,
  lores: {
    ...state.characterStorage[state.currentCharacterID].lores,
    ...payload.lores,
  },
  name: payload.name,
  totalBuild: {
    ...state.characterStorage[state.currentCharacterID].totalBuild,
    used: payload.usedXP,
    earned: payload.earnedXP,
  },
});
