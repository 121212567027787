import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

export default ({ styles, children, autoHeightMax, autoHeightMin }) => {
  return (
    <Scrollbars
      className={styles.belowControl}
      renderView={props => (
        <div {...props} className={styles.noHorizontalScroll} />
      )}
      renderThumbVertical={({ style, ...props }) => {
        return (
          <div
            {...props}
            style={{
              ...style,
              backgroundColor: '#ccc',
              borderRadius: '8px',
            }}
          />
        );
      }}
      autoHeight
      autoHeightMin={autoHeightMin}
      autoHeightMax={autoHeightMax}
      autoHide
    >
      {children}
    </Scrollbars>
  );
};
