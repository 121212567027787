import './Bar.scss';
import { useSelector, useDispatch } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { Link } from 'react-router-dom';
import CharacterSelector from './CharacterSelector/Container';
import history from '../history';
import styles from './Bar.module.scss';
import { hasValidToken } from '../utils/token';
import { fullName } from '../utils/user';

const ENABLE_TOKEN_EXPIRATION_DEBUG = false;

const Bar = () => {
  const dispatch = useDispatch();
  const expireToken = () => dispatch({ type: 'DEBUG_EXPIRE_TOKEN' });
  const { authConfig, user, popper } = useSelector(state => ({
    authConfig: state.user.session,
    user: state.user,
    popper: state.ui.character.popperOpened,
  }));
  const openLoginModal = () => {
    dispatch({ type: 'TOGGLE_POPPER', payload: 'loginModal' });
    dispatch({ type: 'LOAD_MODAL', payload: { username: '', password: '' } });
  };
  const tokenExpirationDebug = () => {
    if (ENABLE_TOKEN_EXPIRATION_DEBUG) {
      return <span onClick={expireToken}>X</span>;
    }

    return '';
  };
  const currentUserName = fullName(user);

  const titleBar = () => {
    if (history.location.pathname === '/login') {
      return (
        <div className={styles.title}>
          <Link className={styles.login} to='/'>
            DRE
          </Link>
        </div>
      );
    }
    if (hasValidToken(authConfig)) {
      return (
        <React.Fragment>
          <div className={styles.title}>{currentUserName || 'Fetching...'}</div>
          {tokenExpirationDebug()}
        </React.Fragment>
      );
    }
    return (
      <div className={styles.title} style={{ textDecoration: 'none' }}>
        <div className={styles.login} onClick={openLoginModal}>
          Login
        </div>
        <div className={styles.appTitle}>Dystopia Rising 3.0</div>
      </div>
    );
  };

  const characterSelector = () => {
    const exactPathMatch = ['/', '/characters', '/print'].includes(
      history.location.pathname,
    );
    const fuzzyPathMatch = history.location.pathname.match(/\/checkout/);
    if (exactPathMatch || fuzzyPathMatch) {
      return (
        <div className={styles.characterSelectorContainer}>
          <CharacterSelector />
        </div>
      );
    }

    return null;
  };

  if (history.location.pathname.match(/admin_print/)) return null;
  return (
    <AppBar position='fixed'>
      <div
        className={[
          styles.container,
          window.location.href.match(/staging/) ? styles.staging : null,
        ].join(' ')}
      >
        <IconButton
          className={[styles.burger, styles.sidebarIgnoreable].join(' ')}
          color='inherit'
          onClick={() => {
            if (popper === 'eventList') {
              dispatch({
                type: 'TOGGLE_POPPER',
                payload: 'eventList',
              });
            }
            dispatch({ type: 'TOGGLE_MENU' });
          }}
        >
          <MenuIcon className={styles.sidebarIgnoreable} />
        </IconButton>
        {titleBar()}
        {characterSelector()}
      </div>
    </AppBar>
  );
};

export default React.memo(Bar);
