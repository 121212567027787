export const initialState = {
  printBackSheet: false,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'SYNC_UI':
      return {
        ...state,
        ...payload.adminPrint,
      };
    case 'BACKSHEET_PRINTABLE':
      return {
        ...state,
        printBackSheet: payload,
      };
    default:
      return state;
  }
};
