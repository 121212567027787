import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import createRootReducer from './reducer';
import history from './history';

const enhancer =
  process.env.NODE_ENV === 'production'
    ? compose
    : composeWithDevTools({ trace: true }) || compose;
const sagaMiddleware = createSagaMiddleware();
const middleware = [routerMiddleware(history), sagaMiddleware];

const doCreateStore = () =>
  createStore(
    createRootReducer(history),
    enhancer(applyMiddleware(...middleware)),
  );

let sagaRunner;

export const runSaga = rootSaga => {
  if (module.hot && sagaRunner) {
    sagaRunner.cancel();
  }

  sagaRunner = sagaMiddleware.run(rootSaga);
};

export default doCreateStore();
