import React from 'react';
import { max } from 'lodash';
import lores from '../gameData/lores';
import remoteLores from '../gameData/remoteLores';
import styles from './Character.module.scss';

// Education 64, 74, 84
const maxLoresCount = {
  64: 3,
  74: 6,
  84: 9,
};

export default ({ characterData }) => {
  const highestEducation = max(
    characterData.skills_id.filter(x => [64, 74, 84].includes(x)),
  );
  const maxLores = maxLoresCount[highestEducation] || 0;
  const loresAcquired = characterData.lores_id
    .map(x => lores[remoteLores[x]].name)
    .sort();
  const renderLores = loresAcquired.map((x, i) => (
    <tr key={x}>
      <td className={styles.xMark}>{i + 1}</td>
      <td className={styles.text}>{x}</td>
    </tr>
  ));

  return (
    <div className={styles.lores}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th colSpan={2}>{`Lores (max: ${maxLores})`}</th>
          </tr>
        </thead>
        <tbody>{renderLores}</tbody>
      </table>
    </div>
  );
};
