import React from 'react';
import SharedCell from '../../../../../Shared/Skill/Cell';

export default ({
  category,
  tier,
  name,
  levelAcquired,
  achievementIsAcquired,
  remoteLevelAcquired,
  remoteAchievementIsAcquired,
  visible,
  innate,
  infoExpanded,
  isOnline,
  passClick,
  isEditable,
  resolveLocked,
}) => {
  const handleClick = () => {
    if (
      tier === 0 ||
      (tier <= 3 && (isEditable || tier > remoteLevelAcquired)) ||
      (tier <= 3 &&
        (isEditable ||
          (tier === remoteLevelAcquired && tier < levelAcquired))) ||
      (tier === 4 && (isEditable || !remoteAchievementIsAcquired))
    ) {
      passClick(tier);
    }
  };

  return (
    <SharedCell
      handleClick={handleClick}
      infoExpanded={infoExpanded}
      category={category}
      tier={tier}
      levelAcquired={levelAcquired}
      achievementIsAcquired={achievementIsAcquired}
      remoteLevelAcquired={remoteLevelAcquired}
      remoteAchievementIsAcquired={remoteAchievementIsAcquired}
      visible={visible}
      innate={innate}
      isEditable={isEditable}
      name={name}
      resolveLocked={resolveLocked}
    />
  );
};
