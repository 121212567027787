import React, { useEffect } from 'react';
import { fromPairs, groupBy, max, range } from 'lodash';
import { getMonth, parseISO } from 'date-fns';
import { useDispatch } from 'react-redux';
import { line } from 'd3-shape';
import uuid from 'uuid';
import BarChart from '../Events/Statistics/BarChart';
import WindowedScrollbar from '../Shared/WindowedScrollbar';
import styles from './Financials.module.scss';

const MONTH_NAMES = [
  '',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const ATTENDANCE_TYPES = {
  home_game: 'Local',
  travel_game: 'Travelers',
};

export default ({ user, branches, branchID }) => {
  const dispatch = useDispatch();
  const financialsData = branches.financials || {};
  const attendances = groupBy(financialsData.attendances, 'branch_name');
  const branchKeys = Object.keys(attendances).sort();
  const extraBuildCost = eventId => {
    const metadata = financialsData.metadata[eventId];
    const isVirtual = metadata.virtual;
    const costOverride = metadata.extra_xp_cost;

    if (isVirtual) {
      return costOverride || 15;
    }

    return 15;
  };
  const extraBuildSales = (eventId, amount) => amount * extraBuildCost(eventId);
  const monthIndex = (name, startDate) => {
    if (name.match(/Jan/)) return 1;
    if (name.match(/Feb/)) return 2;
    if (name.match(/Mar/)) return 3;
    if (name.match(/Apr/)) return 4;
    if (name.match(/May/)) return 5;
    if (name.match(/Jun/)) return 6;
    if (name.match(/Jul/)) return 7;
    if (name.match(/Aug/)) return 8;
    if (name.match(/Sep/)) return 9;
    if (name.match(/Oct/)) return 10;
    if (name.match(/Nov/)) return 11;
    if (name.match(/Dec/)) return 12;

    return getMonth(parseISO(startDate)) + 1;
  };

  const attendanceTransformer = branch => {
    if (!attendances[branch]) return [];
    const baseMonthAttendances = fromPairs(
      range(1, 13).map(x => [
        x,
        {
          home_game: 0,
          travel_game: 0,
          monthId: x,
          month: MONTH_NAMES[x],
        },
      ]),
    );

    const eventGrouped = groupBy(attendances[branch], 'event_name');
    const salesGrouped = groupBy(
      financialsData.sales.filter(x => x.branch_name === branch),
      'event_name',
    );
    const extraBuildGrouped = groupBy(
      financialsData.extra_builds.filter(x => x.branch_name === branch),
      'event_name',
    );

    const maxSales = fromPairs(
      Object.keys(salesGrouped).map(x => [
        x,
        salesGrouped[x].reduce((a, b) => a + b.value, 0) +
          extraBuildGrouped[x].reduce(
            (a, b) =>
              a +
              extraBuildSales(
                eventGrouped[x][0].event_id,
                b.value * b.category,
              ),
            0,
          ),
      ]),
    );

    const monthsWithGames = fromPairs(
      Object.keys(eventGrouped).map(event => {
        const month = monthIndex(event, eventGrouped[event][0].event_starts_at);
        const sales = salesGrouped[event].reduce((a, b) => a + b.value, 0);
        const attendanceCategories = groupBy(eventGrouped[event], 'category');
        const eventsThisMonth = Object.values(
          groupBy(eventGrouped[event], 'event_id'),
        ).map(x => x[0].event_name);
        const categories = fromPairs(
          Object.keys(attendanceCategories).map(x => [
            x,
            attendanceCategories[x].reduce((a, b) => a + b.value, 0),
          ]),
        );
        const extraBuildAmount = extraBuildGrouped[event].reduce(
          (a, b) => a + b.value * b.category,
          0,
        );

        return [
          month,
          {
            event,
            monthId: month,
            month: MONTH_NAMES[month],
            eventsThisMonth,
            sales,
            extraBuildSales: extraBuildSales(
              eventGrouped[event][0].event_id,
              extraBuildAmount,
            ),
            maxSales: max(Object.values(maxSales)),
            ...categories,
          },
        ];
      }),
    );

    const monthsNormalized = {
      ...baseMonthAttendances,
      ...monthsWithGames,
    };
    const nivoNormalized = Object.keys(monthsNormalized)
      .sort((a, b) => monthsNormalized[a].monthId - monthsNormalized[b].monthId)
      .map(x => monthsNormalized[x]);

    return nivoNormalized;
  };

  const attendanceTooltip = (title, d) => {
    return (
      <div className={styles.tooltip}>
        <div>
          {d.data.eventsThisMonth.length > 1 ? (
            <React.Fragment>
              <div>{`${d.data.eventsThisMonth.length} Events this month:`}</div>
              <ul className={styles.listGroup}>
                {d.data.eventsThisMonth.map(x => (
                  <li key={uuid.v1()}>{x}</li>
                ))}
              </ul>
            </React.Fragment>
          ) : (
            <div>{d.data.event}</div>
          )}
        </div>
        <div>{`${ATTENDANCE_TYPES[d.id]}: ${d.value}`}</div>
        <hr className={styles.divider} />
        <div>{`Gross Ticket Sales: $${d.data.sales.toLocaleString()}`}</div>
        <div>{`Gross +XP Sales: $${d.data.extraBuildSales.toLocaleString()}`}</div>
        <div>
          {`Total Gross Sales: $${(
            d.data.sales + d.data.extraBuildSales
          ).toLocaleString()}`}
        </div>
      </div>
    );
  };

  const Line = ({ bars }) => {
    const ySize = 166;
    const homeGamePoints = bars.filter(x => x.data.id === 'home_game');
    const travelGamePoints = bars.filter(x => x.data.id === 'travel_game');

    const salePoints = (
      homeGamePoints.length === 0 ? travelGamePoints : homeGamePoints
    ).map(x => Object.assign(x.data.data, { x: x.x, width: x.width }));
    const scaledSalePoints = salePoints.map(d => [
      d.x + d.width / 2,
      ySize - ((d.sales + 0.0) / (d.maxSales + 0.0)) * ySize,
    ]);
    const scaledExtraBuildPoints = salePoints.map(d => [
      d.x + d.width / 2,
      ySize - (((d.extraBuildSales || 0) + 0.0) / (d.maxSales + 0.0)) * ySize,
    ]);
    const scaledTotalSalePoints = salePoints.map(d => [
      d.x + d.width / 2,
      ySize -
        ((d.sales + (d.extraBuildSales || 0) + 0.0) / (d.maxSales + 0.0)) *
          ySize,
    ]);
    const saleSvgPath = line()(scaledSalePoints);
    const extraBuildSvgPath = line()(scaledExtraBuildPoints);
    const totalSaleSvgPath = line()(scaledTotalSalePoints);

    return (
      <React.Fragment>
        <svg className={styles.hoverable}>
          <path
            d={saleSvgPath}
            fill='transparent'
            stroke='#ccc'
            style={{ pointerEvents: 'stroke' }}
          />
          {scaledSalePoints.map(point => (
            <circle
              key={point[0]}
              cx={point[0]}
              cy={point[1]}
              r={4}
              fill='#3f3f3f'
              stroke='#ccc'
              style={{ pointerEvents: 'none' }}
            />
          ))}
        </svg>
        <svg className={styles.hoverable}>
          <path
            d={extraBuildSvgPath}
            fill='transparent'
            stroke='#ccc'
            style={{ pointerEvents: 'stroke' }}
          />
          {scaledExtraBuildPoints.map(point => (
            <circle
              key={point[0]}
              cx={point[0]}
              cy={point[1]}
              r={4}
              fill='#3f3f3f'
              stroke='#ccc'
              style={{ pointerEvents: 'none' }}
            />
          ))}
        </svg>
        <svg className={styles.hoverable}>
          <path
            d={totalSaleSvgPath}
            fill='transparent'
            stroke='#ccc'
            style={{ pointerEvents: 'stroke' }}
          />
          {scaledTotalSalePoints.map(point => (
            <circle
              key={point[0]}
              cx={point[0]}
              cy={point[1]}
              r={4}
              fill='#3f3f3f'
              stroke='#ccc'
              style={{ pointerEvents: 'none' }}
            />
          ))}
        </svg>
      </React.Fragment>
    );
  };

  const charts = branchKeys.map(branch => (
    <div key={branch} style={{ marginBottom: '32px' }}>
      <div className={styles.title}>{branch}</div>
      <div className={[styles.chart, styles.attendances].join(' ')}>
        <BarChart
          data={attendanceTransformer(branch)}
          keys={['home_game', 'travel_game']}
          indexBy='month'
          tooltip={attendanceTooltip}
          Line={Line}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    if (!branchID) return;
    if (!user) return;
    if (!user.session) return;

    dispatch({ type: 'FETCH_BRANCH_FINANCIALS', payload: branchID });
  }, [branchID, dispatch, user]);

  return (
    <div className={styles.container}>
      <WindowedScrollbar styles={styles} autoHeightMax='calc(100vh - 64px)'>
        {charts}
      </WindowedScrollbar>
    </div>
  );
};
