import React, { useEffect, useState } from 'react';
import { startCase } from 'lodash';
import { isPrivileged as hasPrivilege } from '../../utils/user';
import styles from './Referrals.module.scss';

const REMOVE_REFERRAL_MESSAGE =
  'Remove Player referral? This will automatically recalculate Referral XP';

const REMOVE_INVITER_MESSAGE =
  'Remove Player invitation? This will automatically recalculate Referral XP';

export default ({
  user,
  passAddNewbie,
  passRemoveNewbie,
  passAddInviter,
  passRemoveInviter,
  passBasicSearch,
  isUpdating,
  isError,
  fragment,
  nonceID,
  rootUser,
}) => {
  const [searchText, setSearchText] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [searchSection, setSearchSection] = useState('newbie');
  const isPrivileged = hasPrivilege(rootUser);
  const handleSearchChange = event => setSearchText(event.target.value);
  const handleExecSearch = () =>
    searchText.trim().length > 0 && passBasicSearch(searchText.trim());
  const handleSearchKeyDown = event =>
    event.keyCode === 13 && handleExecSearch();
  const newbieIDs = user.userReferrals.map(x => x.newbie.id);
  const invitedPlayers = user.userReferrals
    .sort((a, b) => b.newbie.id - a.newbie.id)
    .map(x => (
      <div className={styles.row} key={x.id}>
        <div className={styles.id}>{x.newbie.id}</div>
        <div className={styles.name}>
          {[x.newbie.first_name, x.newbie.last_name].join(' ').trim()}
        </div>
        {isPrivileged ? (
          <div
            className={[styles.right, styles.link].join(' ')}
            onClick={() =>
              window.confirm(REMOVE_REFERRAL_MESSAGE) &&
              passRemoveNewbie(x.newbie.id)
            }
          >
            Remove Referral
          </div>
        ) : (
          <div className={styles.right} />
        )}
      </div>
    ));
  const inviter = user.inviter;

  const renderReferralAction = () => {
    if (searchResult.id === user.id) {
      return <div className={styles.right}>Cannot self-invite</div>;
    }

    if (newbieIDs.includes(searchResult.id)) {
      return <div className={styles.right}>Already in Referral</div>;
    }

    return (
      <div
        className={[styles.right, styles.link].join(' ')}
        onClick={() => passAddNewbie(searchResult.id)}
      >
        Add Referral
      </div>
    );
  };

  const renderInvitationAction = () => {
    if (searchResult.id === user.id) {
      return <div className={styles.right}>Cannot self-invite</div>;
    }

    return (
      <div
        className={[styles.right, styles.link].join(' ')}
        onClick={() => passAddInviter(searchResult.id)}
      >
        Add as Inviter
      </div>
    );
  };
  const simpleSearchBox = sectionName =>
    isPrivileged && (
      <React.Fragment>
        <div className={styles.entryForm}>
          <input
            type='number'
            className={styles.inputText}
            placeholder={`${startCase(sectionName)} ID`}
            onChange={evt => {
              handleSearchChange(evt);
              setSearchSection(sectionName);
            }}
            onKeyDown={handleSearchKeyDown}
            value={sectionName === searchSection ? searchText : ''}
          />
          <button
            type='button'
            className={[
              styles.button,
              sectionName === searchSection
                ? searchText.trim().length === 0 && styles.muted
                : styles.muted,
            ].join(' ')}
            onClick={() => {
              handleExecSearch();
              setSearchSection(sectionName);
            }}
          >
            {`Find ${startCase(sectionName)}`}
          </button>
        </div>
        <div className={styles.list}>
          {searchResult && searchSection === sectionName && (
            <div className={styles.row}>
              <div className={styles.id}>{searchResult.id}</div>
              <div className={styles.name}>
                {[searchResult.first_name, searchResult.last_name].join(' ')}
              </div>
              {sectionName === 'newbie'
                ? renderReferralAction()
                : renderInvitationAction()}
            </div>
          )}
        </div>
      </React.Fragment>
    );

  useEffect(() => {
    setSearchText('');
    setSearchResult(null);
  }, [nonceID, fragment]);

  useEffect(() => {
    setSearchResult(rootUser.simpleUserSearch);
  }, [rootUser.simpleUserSearch]);

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.header}>Player Referrals</div>
        <div className={styles.content}>
          <div className={styles.subtitle}>
            {isPrivileged
              ? `Use this section to record New Players that this Player has invited
            to DR. Please do NOT add Referrals from 2.0.`
              : `This section indicates New Players that you have invited to DR`}
          </div>

          {simpleSearchBox('newbie')}

          {user.userReferrals.length > 0 ? (
            <div className={styles.list}>
              <div className={styles.subSection}>Existing Referrals</div>
              {invitedPlayers}
            </div>
          ) : (
            <div>No Player Referrals Recorded</div>
          )}
        </div>
      </div>

      {(isPrivileged || (!isPrivileged && inviter)) && (
        <div className={styles.section}>
          <div className={styles.header}>Inviter</div>
          <div className={styles.content}>
            <div className={styles.subtitle}>
              {isPrivileged
                ? `Use this section to indicate who invited this particular Player to
              DR.`
                : `This section indicates the Player that invited you to DR`}
            </div>

            {isPrivileged && inviter ? (
              <div>
                A Player can only have one Inviter. To change Inviter, please
                remove the existing Inviter below first.
              </div>
            ) : (
              simpleSearchBox('inviter')
            )}

            {inviter && (
              <div className={styles.list}>
                <div className={styles.row}>
                  <div className={styles.id}>{inviter.inviter.id}</div>
                  <div className={styles.name}>
                    {[inviter.inviter.first_name, inviter.inviter.last_name]
                      .join(' ')
                      .trim()}
                  </div>
                  {isPrivileged ? (
                    <div
                      className={[styles.right, styles.link].join(' ')}
                      onClick={() => {
                        window.confirm(REMOVE_INVITER_MESSAGE) &&
                          passRemoveInviter(inviter.inviter.id);
                      }}
                    >
                      Remove Inviter
                    </div>
                  ) : (
                    <div className={styles.right} />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
