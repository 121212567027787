import React, { useEffect, useState } from 'react';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import SharedStatButton from '../../Shared/Stat/Button';
import SharedStatControl from '../../Shared/Stat/Control';

const showDeathControl = true;
const Button = ({
  statType,
  reductionType,
  icon,
  name,
  innate,
  acquired,
  reduction,
  fractureReduction,
  build,
  total,
  nextCost,
  statControls,
  reductionControls,
  passClick,
  passChange,
  passPopperToggle,
  passReduction,
  placement,
  popper,
  remoteTotal,
  isEditable,
  isEditor,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? `popper-${statType}` : undefined;
  const overlayRef = React.createRef();

  const handleToggle = () => passPopperToggle(statType);
  const handleSubtract = () => passClick(statType, -1);
  const handleAdd = () => passClick(statType, 1);
  const handleChange = event =>
    passChange(statType, parseInt(event.target.value) || 0); //eslint-disable-line

  const handleReductionSubtract = () => passReduction(reductionType, -1);
  const handleReductionAdd = () => passReduction(reductionType, 1);
  const fractureReductionCount = fractureReduction
    ? fractureReduction.filter(x => !x.removed_at).length
    : 0;
  const maxMindReduction = React.useMemo(() => {
    if (fractureReductionCount < 1) return 0;
    if (fractureReductionCount === 1) return -5;

    return ((fractureReductionCount - 1) * 10 + 5) * -1;
  }, [fractureReductionCount]);
  const getFractureInfo = () => {
    if (fractureReductionCount < 1) return;

    return (
      <div className='fracture-control'>
        <div className='fracture-left'>FRACTURES</div>
        <div className='fracture-right'>{fractureReductionCount}</div>
      </div>
    );
  };
  const getDeathControl = () => {
    if (!showDeathControl) return null;
    if (statType !== 'infection') return '';

    const editableControl = () => (
      <div className='reduction-control'>
        <div
          className={`stat-control left ${
            reductionControls.isDecrementable ? '' : 'disabled'
          }`}
          onClick={handleReductionSubtract}
        >
          <span>&laquo;</span>
        </div>
        <div className='numeric'>{reduction}</div>
        <div
          className={`stat-control right ${
            reductionControls.isIncrementable ? '' : 'disabled'
          }`}
          onClick={handleReductionAdd}
        >
          <span>&raquo;</span>
        </div>
      </div>
    );

    const viewOnlyControl = () => (
      <div className='reduction-view-only'>{reduction}</div>
    );

    return (
      <div className='reduction-container'>
        <div className='title'>DEATHS</div>
        {isEditable && isEditor ? editableControl() : viewOnlyControl()}
      </div>
    );
  };
  const getMaxMindSubtraction = () => {
    if (fractureReductionCount < 1) return;

    return (
      <span>
        {` - ${-maxMindReduction}`}
        &nbsp;
      </span>
    );
  };
  const getDeathSubtraction = () => {
    if (!showDeathControl) return '';
    if (statType !== 'infection') return '';
    return (
      <span>
        {` - ${reduction}`}
        &nbsp;
      </span>
    );
  };
  const getTotalStat = () => <span>{` = ${total + maxMindReduction} `}</span>;

  useEffect(() => {
    setAnchorEl(popper === statType ? overlayRef.current : null);
  }, [popper, overlayRef, setAnchorEl, statType]);

  return (
    <React.Fragment>
      <SharedStatButton
        handleToggle={handleToggle}
        overlayRef={overlayRef}
        innate={innate}
        acquired={acquired}
        reduction={reduction}
        isEditable={isEditable}
        remoteTotal={remoteTotal}
        anchorEl={anchorEl}
        statType={statType}
        icon={icon}
      />
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        className={`statgrid-overlay ${statType}`}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={0}>
            <div className={`grid grid-${statType}`}>
              <div className='stat-element'>
                <div className='control-container'>
                  <SharedStatControl
                    innate={innate}
                    acquired={acquired}
                    passChange={handleChange}
                    passSubtract={handleSubtract}
                    passAdd={handleAdd}
                    isIncrementable={statControls.isIncrementable}
                    isDecrementable={statControls.isDecrementable}
                  >
                    {getDeathSubtraction()}
                    {getMaxMindSubtraction()}
                    {getTotalStat()}
                  </SharedStatControl>
                </div>
                <div className='stat-info'>
                  <div>
                    <span className='focus-stat'>{name}</span>
                    <span className='focus-point'>{nextCost}</span>
                    /pt
                  </div>
                  <div>
                    <span className='focus-point'>{build}</span>
                    XP
                  </div>
                </div>
                {getFractureInfo()}
                {getDeathControl()}
              </div>
            </div>
          </Fade>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default Button;
