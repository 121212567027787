export default {
  3: 'digitarian',
  9: 'pureBlood',
  20: 'solestros',
  1: 'baywalker',
  16: 'yorker',
  15: 'vegasian',
  17: 'dieselJock',
  13: 'rover',
  19: 'saltwise',
  2: 'fullDead',
  14: 'semperMort',
  5: 'lascarian',
  11: 'remnant',
  12: 'retrograde',
  22: 'tainted',
  6: 'merican',
  8: 'naturalOne',
  23: 'quietFolk',
  7: 'accensorite',
  10: 'redStar',
  21: 'unborn',
  4: 'iron',
  18: 'reclaimer',
  24: 'unstable',
  0: 'default',
};
