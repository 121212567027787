import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { startCase } from 'lodash';
import axios from 'axios';
import Toast from '../../Shared/Toastify/Toast';

const EVENT_SUBNESTS = {
  attendees: { subnest: '/', action: 'FETCH_EVENT_ATTENDEES_COMPLETED' },
  logs: { subnest: '/attendee_logs', action: 'FETCH_ATTENDEE_LOGS_COMPLETED' },
  shifts: {
    subnest: '/attendee_shifts',
    action: 'FETCH_ATTENDEE_SHIFTS_COMPLETED',
  },
};

function* fetchEventData({ type, eventID }) {
  const authConfig = yield select(state => state.user.session);
  const { subnest, action } = EVENT_SUBNESTS[type];
  const endpoint = `events/${eventID}${subnest}`;

  try {
    const res = yield axios.get(endpoint, authConfig);
    yield put({ type: action, payload: res.data });
  } catch (error) {
    yield put({
      type: 'FETCH_EVENT_ATTENDEES_FAILED',
      payload: error.response
        ? error.response.data.message
        : "Can't fetch Event data...",
    });
  }
}

function* fetchEventAttendees({ payload }) {
  yield fetchEventData({ type: 'attendees', eventID: payload.eventID });
}

function* fetchEventAttendeeLogs({ payload }) {
  yield fetchEventData({ type: 'logs', eventID: payload.eventID });
}

function* fetchEventAttendeeShifts({ payload }) {
  yield fetchEventData({ type: 'shifts', eventID: payload.eventID });
}

function* fetchAttendingCharacters({ payload }) {
  const authConfig = yield select(state => state.user.session);
  const filter = payload.playerFilterID
    ? `?user_id=${payload.playerFilterID}`
    : '';

  try {
    const res = yield axios.get(
      `events/${payload.eventID}/printouts${filter}`,
      authConfig,
    );
    yield put({
      type: 'FETCH_ATTENDING_CHARACTERS_COMPLETED',
      payload: res.data,
    });
  } catch (error) {
    yield put({
      type: 'FETCH_EVENT_ATTENDEES_FAILED',
      payload: error.response
        ? error.response.data.message
        : "Can't fetch Event data...",
    });
  }
}

function* maybeAdjustShift({ payload }) {
  if (!payload.updateUpstream) return;

  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield axios.patch(
      `events/${payload.eventID}/shifts/${payload.shiftID}`,
      {
        [payload.kind]: payload.value,
      },
      authConfig,
    );
    Toast({
      text: `Shift ${res.data.label} ${startCase(payload.kind)} updated to ${
        res.data[payload.kind]
      }`,
      type: 'success',
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* updateShiftFulfillment({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    const value = payload.value ? 'fulfill' : 'unfulfill';
    const label = payload.value ? 'fulfilled' : 'unfulfilled';
    yield axios.post(
      `event_attendees/${payload.eventAttendeeID}/attendee_shifts/${payload.attendeeShiftID}/${value}`,
      {},
      authConfig,
    );
    Toast({
      text: `${payload.playerName} ${payload.shiftLabel} marked ${label}`,
      type: 'success',
    });
  } catch (error) {
    console.log(error);
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* fetchEventPlayers({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield axios.get(
      `events/${payload.eventID}/attendees`,
      authConfig,
    );
    yield put({
      type: 'FETCH_EVENT_PLAYERS_COMPLETED',
      payload: res.data,
    });
  } catch (error) {
    yield put({
      type: 'FETCH_EVENT_ATTENDEES_FAILED',
      payload: error.response
        ? error.response.data.message
        : "Can't fetch Event data...",
    });
  }
}

function* updateAttendee({ payload }) {
  const authConfig = yield select(state => state.user.session);
  if (!authConfig) return;

  const verb =
    payload.changes.isPaid !== undefined ? 'approve' : 'confirm_attendance';
  const key = payload.changes.isPaid !== undefined ? 'paid' : 'attending';
  const value =
    payload.changes.isPaid !== undefined
      ? payload.changes.isPaid
      : payload.changes.isAttending;
  const endpoint = payload.attendeeCharacterID
    ? `event_attendees/${payload.attendeeID}/attendee_characters/${payload.attendeeCharacterID}/${verb}`
    : `event_attendees/${payload.attendeeID}/${verb}`;

  try {
    const res = yield axios.post(endpoint, { [key]: value }, authConfig);
    yield put({ type: 'UPDATE_ATTENDEE_RESPONSE_RECEIVED', payload: res.data });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* updateAttendeeLogs({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield axios.patch(
      `events/${payload.eventID}/event_attendees/${payload.eventAttendeeID}`,
      {
        logs: payload.value,
      },
      authConfig,
    );
    yield put({ type: 'UPDATE_ATTENDEE_LOGS_SUCCEEDED', payload: res.data });
    Toast({
      text: `Successfully updated Event Logs for #${payload.playerID} ${payload.name}`,
      type: 'success',
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* cancelAttendee({ payload }) {
  const authConfig = yield select(state => state.user.session);
  const endpoint = payload.attendeeCharacterID
    ? `event_attendees/${payload.attendeeID}/attendee_characters/${payload.attendeeCharacterID}/reject`
    : `event_attendees/${payload.attendeeID}/reject`;

  try {
    yield axios.delete(endpoint, authConfig);
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* updateAttendeeBuildGrowth({ payload }) {
  const authConfig = yield select(state => state.user.session);
  const eventInfo = yield select(state => state.eventAdministration.eventInfo);
  const upstreamPayload = { build_growth: payload.value };

  yield put({
    type: 'UPDATE_ATTENDEE_BUILD_GROWTH_PENDING',
    payload: {
      attendeeID: payload.attendeeID,
      value: payload.value,
    },
  });
  try {
    const res = yield axios.post(
      `event_attendees/${payload.attendeeID}/update_build_growth`,
      upstreamPayload,
      authConfig,
    );
    yield put({ type: 'UPDATE_ATTENDEE_RESPONSE_RECEIVED', payload: res.data });
    const newGrowth =
      res.data.attendee_characters[0].build_growth - (eventInfo.baseBuild || 0);
    Toast({
      text: `Extra build set to +${newGrowth}`,
      type: 'success',
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* upgradeTicket({ payload }) {
  const authConfig = yield select(state => state.user.session);
  const previousPayload = yield select(
    state => state.eventAdministration.eventAttendees[payload.eventAttendeeID],
  );

  yield put({
    type: 'UPGRADE_TICKET_PENDING',
    payload: payload.eventAttendeeID,
  });

  try {
    const res = yield axios.post(
      `event_attendees/${payload.eventAttendeeID}/upgrade_ticket`,
      { ticket_identifier: payload.ticketIdentifier },
      authConfig,
    );

    yield put({
      type: 'UPGRADE_TICKET_SUCCEEDED',
      payload: res.data,
    });
    Toast({
      text: `Successfully Upgraded Ticket to $${res.data.ticket.cost} ${res.data.ticket.label}`,
      type: 'success',
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
    yield put({
      type: 'UPGRADE_TICKET_FAILED',
      payload: {
        id: payload.eventAttendeeID,
        previousPayload,
      },
    });
  }
}

function* fetchTaxonomies({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    const kinds = payload.map(x => `taxonomies[]=${x}`).join('&');
    const res = yield axios.get(`items/?${kinds}`, authConfig);

    yield put({
      type: 'FETCH_TAXONOMIES_SUCCEEDED',
      payload: res.data,
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* fetchTransactionPartners({ payload }) {
  const authConfig = yield select(state => state.user.session);
  if (!authConfig) return;

  const { id: eventId } = yield select(
    state => state.eventAdministration.eventInfo,
  );

  try {
    const res = yield axios.get(
      payload.eventIndependent
        ? `users/${payload.userId}/characters`
        : `users/${payload.userId}/events/${eventId}`,
      authConfig,
    );

    yield put({
      type: 'FETCH_TRANSACTION_PARTNERS_SUCCEEDED',
      payload: res.data,
    });
  } catch (error) {
    // Toast({ text: error.response.data.message, type: 'error' });
    yield put({
      type: 'FETCH_TRANSACTION_PARTNERS_FAILED',
      payload: payload.userId,
    });
  }
}

function* executeTransaction({ payload }) {
  const authConfig = yield select(state => state.user.session);
  const { id: eventId } = yield select(
    state => state.eventAdministration.eventInfo,
  );

  try {
    const res = yield axios.post(
      `events/${eventId}/item_transactions`,
      payload,
      authConfig,
    );

    Toast({ text: 'Transaction Succeeded', type: 'success' });
    yield put({
      type: 'EXECUTE_TRANSACTION_SUCCEEDED',
      payload: res.data,
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
    yield put({
      type: 'EXECUTE_TRANSACTION_FAILED',
    });
  }
}

function* fetchEventTransactions({ payload }) {
  const authConfig = yield select(state => state.user.session);
  if (!authConfig) return;

  try {
    const scope = payload.userScoping
      ? `self_scoped=${payload.userScoping}`
      : '';
    const res = yield axios.get(
      `events/${payload.id}/item_transactions?${scope}`,
      authConfig,
    );

    yield put({
      type: 'FETCH_EVENT_TRANSACTIONS_SUCCEEDED',
      payload: res.data,
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* fetchInventory({ payload }) {
  const authConfig = yield select(state => state.user.session);
  if (!authConfig) return;

  try {
    const res = yield axios.get(
      `characters/${payload.characterId}/inventories`,
      authConfig,
    );

    yield put({
      type: 'FETCH_INVENTORY_SUCCEEDED',
      payload: { characterId: payload.characterId, data: res.data },
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* fetchItemTransactions({ payload }) {
  const authConfig = yield select(state => state.user.session);
  if (!authConfig) return;

  try {
    const res = yield axios.get(
      `characters/${payload.characterId}/item_audits/${payload.itemId}`,
      authConfig,
    );

    yield put({
      type: 'FETCH_ITEM_TRANSACTIONS_SUCCEEDED',
      payload: {
        characterId: payload.characterId,
        itemId: payload.itemId,
        accessorId: payload.accessorId,
        data: res.data,
      },
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* fetchRegisteredItems({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield axios.get(
      `characters/${payload.characterId}/registered_items`,
      authConfig,
    );

    yield put({
      type: 'FETCH_REGISTERED_ITEMS_SUCCEEDED',
      payload: {
        characterId: payload.characterId,
        data: res.data,
      },
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* updateTransactionLegitimacy({ payload }) {
  const authConfig = yield select(state => state.user.session);
  const { id: eventId } = yield select(
    state => state.eventAdministration.eventInfo,
  );

  try {
    const verb = payload.value ? 'legitimize' : 'invalidate';
    const res = yield axios.post(
      `events/${eventId}/item_transactions/${payload.transactionId}/${verb}`,
      {},
      authConfig,
    );

    yield put({
      type: 'UPDATE_TRANSACTION_LEGITIMACY_SUCCEEDED',
      payload: {
        id: res.data.id,
        data: res.data,
      },
    });

    Toast({
      text: `Transaction ID ${payload.transactionId} ${
        payload.value ? 'revalidated' : 'invalidated'
      }`,
      type: 'success',
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* updateTransactionDescription({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield axios.patch(
      `events/${payload.eventId}/item_transactions/${payload.transactionId}`,
      {
        description: payload.value,
      },
      authConfig,
    );

    yield put({
      type: 'UPDATE_TRANSACTION_DESCRIPTION_SUCCEEDED',
      payload: {
        id: res.data.id,
        data: res.data,
      },
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* watchAdjustShift() {
  yield takeLatest('ADJUST_SHIFT', maybeAdjustShift);
}

function* watchFetchEventAttendees() {
  yield takeLatest('FETCH_EVENT_ATTENDEES', fetchEventAttendees);
}

function* watchFetchEventAttendeeShifts() {
  yield takeLatest('FETCH_EVENT_ATTENDEE_SHIFTS', fetchEventAttendeeShifts);
}

function* watchFetchEventAttendeeLogs() {
  yield takeLatest('FETCH_EVENT_ATTENDEE_LOGS', fetchEventAttendeeLogs);
}

function* watchFetchEventPlayers() {
  yield takeLatest('FETCH_EVENT_PLAYERS', fetchEventPlayers);
}

function* watchUpdateAttendee() {
  yield takeLatest('UPDATE_ATTENDEE', updateAttendee);
}

function* watchUpdateAttendeeCharacter() {
  yield takeLatest('UPDATE_ATTENDEE_CHARACTER', updateAttendee);
}

function* watchUpdateAttendeeLogs() {
  yield takeLatest('UPDATE_ATTENDEE_LOGS', updateAttendeeLogs);
}

function* watchCancelAttendee() {
  yield takeLatest('CANCEL_ATTENDEE', cancelAttendee);
}

function* watchCancelAttendeeCharacter() {
  yield takeLatest('CANCEL_ATTENDEE_CHARACTER', cancelAttendee);
}

function* watchFetchAttendingCharacters() {
  yield takeLatest('FETCH_ATTENDING_CHARACTERS', fetchAttendingCharacters);
}

function* watchUpdateAttendeeBuildGrowth() {
  yield takeLatest('UPDATE_ATTENDEE_BUILD_GROWTH', updateAttendeeBuildGrowth);
}

function* watchUpdateShiftFulfillment() {
  yield takeLatest('UPDATE_SHIFT_FULFILLMENT', updateShiftFulfillment);
}

function* watchUpgradeTicket() {
  yield takeLatest('UPGRADE_TICKET', upgradeTicket);
}

function* watchFetchRegisteredItems() {
  yield takeEvery('FETCH_REGISTERED_ITEMS', fetchRegisteredItems);
}

function* watchFetchTaxonomies() {
  yield takeLatest('FETCH_TAXONOMIES', fetchTaxonomies);
}

function* watchFetchTransactionPartners() {
  yield takeLatest('FETCH_TRANSACTION_PARTNERS', fetchTransactionPartners);
}

function* watchExecuteTransaction() {
  yield takeLatest('EXECUTE_TRANSACTION', executeTransaction);
}

function* watchFetchEventTransactions() {
  yield takeLatest('FETCH_EVENT_TRANSACTIONS', fetchEventTransactions);
}

function* watchFetchInventory() {
  yield takeEvery('FETCH_INVENTORY', fetchInventory);
}

function* watchFetchItemTransactions() {
  yield takeLatest('FETCH_ITEM_TRANSACTIONS', fetchItemTransactions);
}

function* watchUpdateTransactionLegitimacy() {
  yield takeLatest(
    'UPDATE_TRANSACTION_LEGITIMACY',
    updateTransactionLegitimacy,
  );
}

function* watchUpdateTransactionDescription() {
  yield takeLatest(
    'UPDATE_TRANSACTION_DESCRIPTION',
    updateTransactionDescription,
  );
}

export default function* () {
  yield all([
    watchFetchEventAttendees(),
    watchFetchEventAttendeeLogs(),
    watchFetchEventAttendeeShifts(),
    watchFetchAttendingCharacters(),
    watchFetchEventPlayers(),
    watchUpdateAttendee(),
    watchUpdateAttendeeCharacter(),
    watchUpdateAttendeeLogs(),
    watchUpdateShiftFulfillment(),
    watchAdjustShift(),
    watchCancelAttendee(),
    watchCancelAttendeeCharacter(),
    watchUpdateAttendeeBuildGrowth(),
    watchUpgradeTicket(),
    watchFetchRegisteredItems(),
    watchFetchTaxonomies(),
    watchFetchTransactionPartners(),
    watchExecuteTransaction(),
    watchFetchEventTransactions(),
    watchFetchInventory(),
    watchFetchItemTransactions(),
    watchUpdateTransactionLegitimacy(),
    watchUpdateTransactionDescription(),
  ]);
}
