import { fromPairs } from 'lodash';
import SkillTable from '../../gameData/skillInitializer';

export const initialState = {
  categoriesShown: {
    combat: true,
    civilized: true,
    wasteland: true,
    anomaly: true,
    community: true,
  },
  infoShown: fromPairs(Object.keys(SkillTable().skills).map(x => [x, false])),
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'UPDATE_SKILL_INFO_VISIBILITY':
      return {
        ...state,
        infoShown: {
          ...state.infoShown,
          ...payload,
        },
      };
    case 'TOGGLE_SKILL_CATEGORY_VISIBILITY':
      return {
        ...state,
        categoriesShown: {
          ...state.categoriesShown,
          [payload]: !state.categoriesShown[payload],
        },
      };
    case 'SYNC_UI':
      return {
        ...state,
        categoriesShown: {
          ...state.categoriesShown,
          ...(payload.skills
            ? payload.skills.categoriesShown
            : state.categoriesShown),
        },
        infoShown: {
          ...state.infoShown,
          ...(payload.skills ? payload.skills.infoShown : state.infoShown),
        },
      };
    default:
      return state;
  }
};
