import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popper from '@material-ui/core/Popper';
import Paid from '@material-ui/icons/AttachMoney';
import CheckedIn from '@material-ui/icons/Check';
import NotVisible from '@material-ui/icons/VisibilityOff';
import NationalIcon from '@material-ui/icons/Public';
import PremiereIcon from '@material-ui/icons/Star';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import { Animated } from 'react-animated-css';
import styles from './Cell.module.scss';
import { shiftHours } from '../../utils/time';
import {
  addDays,
  format,
  isPast,
  formatDistanceToNow,
  parseISO,
} from 'date-fns';

export default ({
  eventID,
  isPaid,
  isGuide,
  isPrivileged,
  isExternalRun,
  isLoggedIn,
  isAdmin,
  homeBranchID,
  isCheckedIn,
  ownedBranches,
  name,
  event,
  popper,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useSelector(state => ({ user: state.user }));
  const popperName = `eventCell-${event.id}`;
  const open = Boolean(anchorEl);
  const id = open ? `event-cell` : undefined;
  const ref = useRef(null);
  const dispatch = useDispatch();
  const shiftedDate = shiftHours(Date.parse(event.starts_at));
  const isPastEvent = isPast(addDays(shiftedDate, 3)); // until weekend
  const isOpenForRegistration = isPast(parseISO(event.registration_opens_at));
  const isPremiere = event.kind === 'premiere';
  const isNational = event.kind === 'national';
  const isIrl = event.location.region !== 'VIRTUAL';
  const isLocalGuide =
    (isGuide && (isNational || homeBranchID === event.branch_id)) ||
    (isPrivileged && isNational);
  const isLocalGuideWithManageModPrivilege =
    isLocalGuide && user.flags.includes('mod_manager');
  const isGuideWithLogisticsPrivilege =
    isGuide && user.flags.includes('logistics');

  const hasPrivilege = () =>
    isAdmin ||
    (isPrivileged &&
      ownedBranches &&
      ownedBranches.includes(event.branch_id)) ||
    isExternalRun;
  const hasPrivilegeOrLocalGuide = () => hasPrivilege() || isLocalGuide;
  const hasPrivilegeOrLocalGuideWithManageModPrivilege = () =>
    hasPrivilege() || isLocalGuideWithManageModPrivilege;

  const paid = () => (isPaid ? <Paid className={styles.icon} /> : null);
  const checkedIn = () =>
    isCheckedIn ? <CheckedIn className={styles.icon} /> : null;
  const friendlyDateTime = () => {
    const text = format(shiftedDate, 'eee, MMM d');
    const pastStyle = isPastEvent ? styles.muted : '';

    return (
      <React.Fragment>
        <div className={[styles.text, styles.date, pastStyle].join(' ')}>
          {text}
        </div>
        <div className={[styles.text, styles.location, pastStyle].join(' ')}>
          {event.location ? event.location.name : '???'}
        </div>
        {isPremiereText()}
      </React.Fragment>
    );
  };

  const loginAction = () =>
    !isLoggedIn && (
      <div
        onClick={() =>
          dispatch({ type: 'TOGGLE_POPPER', payload: 'loginModal' })
        }
        className={[styles.text, styles.link, 'login-link'].join(' ')}
      >
        Login to Checkin
      </div>
    );

  const action = (endpoint, text, condition) =>
    condition() ? (
      <a href={`/${endpoint}/${eventID}`} className={styles.printLink}>
        <div className={[styles.text, styles.link].join(' ')}>{text}</div>
      </a>
    ) : null;

  const isVisible = () =>
    event.registration_opens_at ? null : hasPrivilege() ? (
      <NotVisible className={styles.icon} />
    ) : null;

  const isIrlIcon = () =>
    isIrl ? <FontAwesomeIcon icon='users' className={styles.iconIrl} /> : null;
  const isPremiereIcon = () => {
    if (isNational)
      return <NationalIcon className={styles.icon}></NationalIcon>;
    if (isPremiere)
      return <PremiereIcon className={styles.icon}></PremiereIcon>;
  };

  const isPremiereText = () =>
    isPremiere || isNational ? (
      <div className={[styles.text, styles.isPremiere].join(' ')}>
        {isNational ? 'National' : 'Premiere'}
      </div>
    ) : null;

  const handleClick = evt => {
    if (evt.target.classList.contains('login-link')) return;
    dispatch({ type: 'TOGGLE_POPPER', payload: popperName });
  };
  const cellOverlayClass = () =>
    [
      styles.cell,
      popper === popperName ? styles.overlayActive : '',
      isPastEvent || !isOpenForRegistration ? styles.past : '',
      isCheckedIn && isCheckedIn.checkin_approved ? styles.approved : '',
      isCheckedIn && isCheckedIn.build_awarded ? styles.awarded : '',
    ].join(' ');

  const dropper = () =>
    popper === popperName ? (
      <ArrowDropUp className={[styles.icon, styles.downshift].join(' ')} />
    ) : (
      <ArrowDropDown className={[styles.icon, styles.downshift].join(' ')} />
    );
  const content = () => (
    <React.Fragment>
      {isIrlIcon()}
      <div className={styles.name}>{name}</div>
      {paid()}
      {checkedIn()}
      {isVisible()}
      {isPremiereIcon()}
      {dropper()}
    </React.Fragment>
  );
  const renderCheckinDate = () => {
    if (isCheckedIn || isPastEvent) return null;
    if (!event.registration_opens_at)
      return <div className={styles.disabledCheckin}>Checkin disabled</div>;
    if (isOpenForRegistration) return null;
    return (
      <div className={styles.disabledCheckin}>
        Checkin opens in
        {` ${formatDistanceToNow(parseISO(event.registration_opens_at))}`}
      </div>
    );
  };

  useEffect(() => {
    setAnchorEl(popper === popperName ? ref.current : null);
  }, [popper, popperName, ref, setAnchorEl]);

  return (
    <Animated
      animationIn='bounceIn'
      animationOut='zoomOutDown'
      animationInDelay={Math.random() * 300}
      animationInDuration={300 + Math.random() * 400}
    >
      <div className={cellOverlayClass()} ref={ref} onClick={handleClick}>
        {content()}
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          className={[
            styles.overlay,
            isCheckedIn && isCheckedIn.checkin_approved ? styles.approved : '',
            isCheckedIn && isCheckedIn.build_awarded ? styles.awarded : '',
          ].join(' ')}
          placement='bottom'
        >
          <div className={styles.mask}>{content()}</div>

          {friendlyDateTime()}
          {loginAction()}
          {action(
            'checkin',
            'Checkin',
            () =>
              !isCheckedIn &&
              !isPastEvent &&
              isOpenForRegistration &&
              isLoggedIn,
          )}

          {renderCheckinDate()}
          {action(
            'checkin',
            isCheckedIn
              ? isCheckedIn.build_awarded
                ? 'XP Awarded'
                : isCheckedIn.checkin_approved
                ? 'Checkin Approved'
                : 'Checked In'
              : 'Checkin',
            () => isCheckedIn,
          )}
          {action('checkin', 'Mods Signup', () => isCheckedIn && !isPastEvent)}
          {action(
            'update_event',
            hasPrivilege() ? 'Edit Event' : 'Manage Mods',
            hasPrivilegeOrLocalGuideWithManageModPrivilege,
          )}
          {action('event_statistics', 'Event Statistics', hasPrivilege)}
          {action('admin_event', 'Manage Attendees', hasPrivilege)}
          {action(
            'admin_transactions',
            'Manage Logistics',
            () => isPrivileged || isGuideWithLogisticsPrivilege,
          )}
          {action(
            'admin_shift',
            hasPrivilege() ? 'Manage Shifts' : 'See Shifts',
            hasPrivilegeOrLocalGuide,
          )}
          {action('assisted_checkin', 'Assisted Checkin', hasPrivilege)}
          {action('admin_print', 'Print Sheets', hasPrivilege)}
          {action('checkout', 'Checkout', hasPrivilege)}
        </Popper>
      </div>
    </Animated>
  );
};

//{adminPrint()}
