import React, { useEffect, useRef } from 'react';
import Clear from '@material-ui/icons/Clear';
import Undo from '@material-ui/icons/Undo';
import styles from './TextField.module.scss';

export default ({
  fieldKey,
  title,
  value,
  passChange,
  passBlur,
  initialValue,
  isBlankState,
  errors,
  revert,
  passFocus,
  passKeyDown,
  autoFocus,
  type,
  autoComplete,
  placeholder,
}) => {
  const autoFocusRef = useRef(null);
  const handleChange = event => passChange(fieldKey, event.target.value);
  const handleBlur = () => passBlur && passBlur(fieldKey);
  const errorClass = () => (!isBlankState && errors ? styles.error : '');
  const identicalClass = () => (value === initialValue ? styles.hidden : '');
  const handleRevert = () => passChange(fieldKey, initialValue);
  const arbitrateRevert = () => {
    switch (revert) {
      case 'clear':
        return (
          <Clear
            className={[styles.revert, identicalClass()].join(' ')}
            onClick={handleRevert}
          />
        );
      case 'never':
        return null;
      default:
        return (
          <Undo
            className={[styles.revert, identicalClass()].join(' ')}
            onClick={handleRevert}
          />
        );
    }
  };

  useEffect(() => {
    if (autoFocus) autoFocusRef.current.focus();
  }, [autoFocus]);

  return (
    <React.Fragment>
      <div className={styles.wrapper}>
        <div className={[styles.title, errorClass()].join(' ')}>{title}</div>
        <input
          className={[styles.input, styles.text, errorClass()].join(' ')}
          value={value}
          placeholder={placeholder}
          name={fieldKey}
          onChange={handleChange}
          onKeyDown={passKeyDown}
          onBlur={handleBlur}
          onFocus={passFocus}
          ref={autoFocusRef}
          type={type || 'text'}
          autoComplete={autoComplete ? 'on' : 'off'}
        />
        {arbitrateRevert()}
      </div>
      <div className={styles.errorMessage}>{errors}</div>
    </React.Fragment>
  );
};
