import React from 'react';
import { useSelector } from 'react-redux';
import Print from '@material-ui/icons/Print';
import Edit from '@material-ui/icons/Edit';
import Players from '@material-ui/icons/People';
import Timeline from '@material-ui/icons/Timeline';
import EventList from '@material-ui/icons/ListRounded';
import EventLogs from '@material-ui/icons/Assignment';
import ItemTransactions from '@material-ui/icons/LocalShipping';
import ReactTooltip from 'react-tooltip';
import { isPrivileged as hasPrivilege } from '../../utils/user';
import history from '../../history';
import styles from './EventLinks.module.scss';

export default () => {
  const { user } = useSelector(state => ({ user: state.user }));
  const linkID = history.location.pathname.split(/\//)[2];
  const isPrivileged = hasPrivilege(user);
  const maybeLink = ({ href, title, icon, bare, target }) =>
    href.match(history.location.pathname.split(/\//)[1]) ? (
      <span
        data-tip={icon ? title : null}
        key={href}
        className={[styles.link, styles.current].join(' ')}
      >
        {icon || title}
      </span>
    ) : (
      <a
        key={href}
        className={styles.link}
        href={bare ? `${href}` : `${href}/${linkID}`}
        target={target ? target : '_self'}
        data-tip={icon ? title : null}
      >
        {icon || title}
      </a>
    );

  const links = [
    {
      href: '/events',
      icon: <EventList fontSize='small' />,
      title: 'Events List',
      bare: true,
    },
    {
      href: '/update_event',
      icon: <Edit fontSize='small' />,
      title: 'Edit This Event',
    },
    {
      href: '/event_statistics',
      icon: <Timeline fontSize='small' />,
      title: 'Event Statistics',
    },
    {
      href: '/admin_event',
      icon: <Players fontSize='small' />,
      title: 'Players List',
    },
    {
      href: '/admin_logs',
      icon: <EventLogs fontSize='small' />,
      title: 'Event Logs',
    },
    {
      href: '/admin_transactions',
      icon: <ItemTransactions fontSize='small' />,
      title: 'Event Transactions',
    },
    { href: '/admin_shift', title: 'Shifts' },
    { href: '/assisted_checkin', title: 'Checkin' },
    { href: '/checkout', title: 'Checkout' },
    {
      href: '/admin_print',
      icon: <Print fontSize='small' />,
      target: '_print',
      title: 'Print Character Sheets',
    },
  ];

  const headerLinks = () =>
    links.map(x =>
      maybeLink({
        href: x.href,
        title: x.title,
        icon: x.icon,
        bare: x.bare,
        target: x.target,
      }),
    );

  if (!isPrivileged) return null;

  return (
    <React.Fragment>
      <ReactTooltip place='bottom' effect='solid' className={styles.tooltip} />
      <div className={styles.eventLinks}>{headerLinks()}</div>
    </React.Fragment>
  );
};
