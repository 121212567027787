export const initialState = {
  reverseStatistics: {
    skills: {},
    lores: {},
    faiths: {},
    residency: {},
    strains: {},
  },
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'FETCH_EVENT_STATISTICS_SUCCEEDED':
      return {
        ...state,
        ...payload,
        reverseStatistics: initialState.reverseStatistics,
      };
    case 'FETCH_EVENT_DETAILED_STATISTICS_SUCCEEDED':
      return {
        ...state,
        reverseStatistics: {
          ...state.reverseStatistics,
          [payload.attribute]: {
            ...state.reverseStatistics[payload.attribute],
            [payload.id]: payload.values,
          },
        },
      };
    default:
      return state;
  }
};
