import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDistanceToNow, parseISO } from 'date-fns';
import history from '../history';
import styles from './ActiveSessions.module.scss';

export default () => {
  const dispatch = useDispatch();
  const { activeSessions, user } = useSelector(state => ({
    activeSessions: state.activeSessions,
    user: state.user,
  }));

  const renderActiveSession = session => (
    <div className={styles.row} key={session.id}>
      <div className={styles.browser}>{session.browser || '???'}</div>
      <div className={styles.os}>{session.os}</div>
      <div className={styles.timestamp}>
        {formatDistanceToNow(parseISO(session.created_at), { addSuffix: true })}
      </div>
      <div className={styles.recentActivity}>
        {formatDistanceToNow(parseISO(session.updated_at), { addSuffix: true })}
        {session.ip_address ? ` from ${session.ip_address}` : ''}
      </div>
    </div>
  );
  const renderActiveSessions = () =>
    activeSessions.length === 0
      ? 'Fetching active sessions...'
      : activeSessions.map(x => renderActiveSession(x));

  useEffect(() => {
    dispatch({ type: 'APP_LOADED' });
  }, [dispatch]);

  useEffect(() => {
    if (!user || !user.session) return;
    dispatch({ type: 'FETCH_ACTIVE_SESSIONS' });
  }, [user, user.session, dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Active Sessions</div>
      <div className={styles.header}>
        <div className={styles.browser}>Browser</div>
        <div className={styles.os}>OS</div>
        <div className={styles.timestamp}>First login</div>
        <div className={styles.recentActivity}>Most recent activity</div>
      </div>
      <div className={styles.content}>{renderActiveSessions()}</div>
      <button
        type='button'
        className={styles.logout}
        onClick={() => {
          dispatch({ type: 'LOGOUT_ALL_SESSIONS' });
          history.push('/');
        }}
      >
        Logout All Sessions
      </button>
    </div>
  );
};
