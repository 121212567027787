import React from 'react';
import styles from './LastResortErrorHandler.module.scss';

export default () => {
  const hardReload = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location = '/';
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Something Is Terribly Wrong</div>
      <div className={styles.subtitle}>
        A Whisperkin might have stolen your cookies
      </div>
      <div className={styles.actionable}>
        <span className={styles.link} onClick={hardReload}>
          Click here to force hard-reload
        </span>
      </div>
    </div>
  );
};
