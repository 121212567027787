import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import branches from '../../gameData/branches';
import { format } from 'date-fns';
import styles from './EventName.module.scss';

export default ({ event, passChange }) => {
  const handleChange = event => passChange(event.target.value);
  const dateString = () =>
    event.startDate ? `${format(event.startDate, 'yyyy MMM')}` : null;

  const premiereEvent = () => (event.isPremiere ? '- Premiere' : null);

  const nameJoiner = () =>
    event.startDate
      ? [
          branches[event.selectedBranch].shortName,
          dateString(),
          premiereEvent(),
        ].join(' ')
      : '';

  const eventNameArbiter = () =>
    event.hasModifiedName ? event.eventName : nameJoiner();

  return (
    <div className={styles.container}>
      <div className={styles.header}>Event Name</div>
      <div className={styles.input}>
        <InputBase
          className={styles.inputText}
          value={eventNameArbiter()}
          placeholder='Fill event start date first'
          onChange={handleChange}
        />
      </div>
    </div>
  );
};
