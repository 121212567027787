export default {
  faithless: { name: 'Faithless', remoteID: 0 },
  churchOfDarwin: { name: 'Church of Darwin', remoteID: 4 },
  cultOfTheFallowHopes: { name: 'Cult of the Fallow Hopes', remoteID: 5 },
  finalKnights: { name: 'Final Knights', remoteID: 6 },
  lightOfTheHedon: { name: 'Light of The Hedon', remoteID: 10 },
  theNuclearFamily: { name: 'The Nuclear Family', remoteID: 3 },
  theTellingVisionaries: { name: 'The Telling Visionaries', remoteID: 1 },
  sainthoodOfAshes: { name: 'Sainthood of Ashes', remoteID: 2 },
  tribesOfTheSeasons: { name: 'Tribes of The Seasons', remoteID: 8 },
  kingsAndQueens: { name: 'Kings & Queens', remoteID: 7 },
  cults: { name: 'Cults', remoteID: 9 },
};
