import { useDispatch, useSelector } from 'react-redux';
import { fromPairs, isEqual } from 'lodash';
import React, { useEffect } from 'react';
import Close from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import DropdownField from '../FormComponents/DropdownField';
import TextField from '../FormComponents/TextField';
import styles from './EditUserModal.module.scss';
import strains from '../../gameData/strains';

export default () => {
  const remoteFieldMap = {
    //name: 'legal_name',
    strainID: 'strain_id',
  };

  const presence = value => {
    return value.length > 0 ? null : 'Cannot be empty';
  };

  const fieldValidations = {
    name: [presence],
  };
  const dispatch = useDispatch();
  const { popper, modal, user } = useSelector(state => ({
    popper: state.ui.character.popperOpened,
    modal: state.ui.modal,
    user: state.user,
  }));
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState(null);
  const [errors, setErrors] = React.useState({});
  const popperName = 'createCharacterModal';
  const isErrorVisible = () => (modal.error ? '' : styles.hidden);
  const handleClose = () =>
    dispatch({ type: 'TOGGLE_POPPER', payload: popperName });
  const validate = (field, value) => {
    if (!fieldValidations[field]) return null;

    const errorMessages = [];
    fieldValidations[field]
      .map(functor => {
        const errorMessage = functor(value);
        if (errorMessage) errorMessages.push(errorMessage);
        return errorMessage === null;
      })
      .reduce((a, b) => a && b, true);

    return errorMessages.length > 0 ? errorMessages.join('. ') : null;
  };

  const handleChange = (field, value) => {
    setErrors({
      ...errors,
      [field]: validate(field, value),
    });
    dispatch({
      type: 'UPDATE_MODAL',
      payload: {
        field: field,
        value: value,
      },
    });
  };
  const filterDelta = () =>
    Object.assign(
      fromPairs(
        Object.keys(modal.initial)
          .filter(x => modal.initial[x] !== modal.current[x])
          .map(x => [remoteFieldMap[x] || x, modal.current[x]]),
      ),
      {
        branch_id: user.impersonatee.branchId || user.branchId,
        user_id: user.impersonatee.id || user.id,
        name: modal.current.name,
      },
    );

  const handleSubmit = () => {
    setState('updating');
    dispatch({
      type: 'CREATE_CHARACTER_REMOTE',
      payload: {
        changes: filterDelta(),
        id: modal.initial.id,
      },
    });
  };

  const actionButton = () => {
    const buttonState = () => {
      switch (state) {
        case 'modified':
          return styles.modified;
        case 'error':
          return styles.error;
        case 'done':
          return styles.done;
        default:
          return styles.disabled;
      }
    };

    const buttonText = () => {
      switch (state) {
        case 'modified':
          return 'Create Character';
        case 'updating':
          return 'Creating Character...';
        case 'done':
          return 'Character Created!';
        case 'error':
          return 'Error, retry?';
        default:
          return 'Create Character';
      }
    };

    return (
      <button
        className={[styles.button, buttonState()].join(' ')}
        disabled={!['modified', 'error'].includes(state)}
        onClick={handleSubmit}
      >
        {buttonText()}
      </button>
    );
  };

  const hasLocalErrors = () =>
    Object.keys(fieldValidations)
      .map(field => validate(field, modal.current[field]) !== null)
      .reduce((a, b) => a || b, false);

  const sortedStrains = () =>
    Object.keys(strains)
      .filter(x => strains[x].remoteID)
      .sort()
      .map(strain => ({
        value: strains[strain].remoteID,
        label: strains[strain].name,
      }));

  useEffect(() => {
    const isOpen = popper === popperName;
    setOpen(isOpen);
  }, [popper]);

  useEffect(() => {
    if (!open) return;
    if (modal.current.strainID) return;
    dispatch({
      type: 'UPDATE_MODAL',
      payload: {
        field: 'strainID',
        value: 1,
      },
    });
  }, [modal, open, dispatch]);

  useEffect(() => {
    if (!open) return;
    if (modal.error) {
      setState('error');
    } else {
      setState(
        isEqual(modal.initial, modal.current)
          ? state === 'updating'
            ? 'done'
            : null
          : hasLocalErrors()
          ? null
          : 'modified',
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal, open]); // hack to allow Done to be displayed

  // useEffect(() => {
  //   if (user.isNewUser) {
  //     dispatch({ type: 'UPDATE_MODAL', payload: { field: 'name', value: '' } });

  //     setIsBlankState(true);
  //   }
  // }, [user, dispatch])

  // <div onClick={handleChange}>Hi!</div>
  // <div onClick={handleTestError}>Error?</div>
  return (
    <Modal open={open}>
      <Fade in={open}>
        <div className={styles.base}>
          <div className={styles.header}>
            <div className={styles.title}>
              {`Create New Character for ${
                user.impersonatee.name || user.name
              }`}
            </div>
            <Close className={styles.close} onClick={handleClose} />
          </div>
          <div className={styles.body}>
            <TextField
              title='Character Name'
              fieldKey='name'
              value={modal.current.name || ''}
              initialValue=''
              errors={errors.name}
              passChange={handleChange}
              revert='clear'
              autoFocus
            />
            <DropdownField
              title='Strain'
              fieldKey='strainID'
              value={modal.current.strainID || 0}
              options={sortedStrains()}
              initialValue={modal.initial.strainID}
              isError={false}
              revert='never'
              passChange={handleChange}
            />
          </div>
          <div className={styles.footer}>
            <div className={[styles.errorMessage, isErrorVisible()].join(' ')}>
              {modal.error}
            </div>
            <button
              className={[styles.button, styles.cancel].join(' ')}
              onClick={handleClose}
              type='button'
            >
              Close
            </button>
            {actionButton()}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
