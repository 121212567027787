import achievements from './achievements';

export default {
  avoid: {
    1: {
      description:
        '5 Mind counters single Strike or Ranged Attack to User or equipment on User. Does not work for AoE damage or when immobilized. Requires 1 working limb, and does not counter Mangle from behind.',
      name: 'Avoid',
      startingPage: 109,
    },
  },
  agricultural: {
    1: {
      description:
        '5 Mind and 10 minutes to get 1 Basic, 1 Uncommon, 1 Rare, or 1 Named Herb. May spend up to 30 Mind and 60 minutes in one go, but must be same Herb. Requires Active RP and Full Engagement.',
      name: 'Basic Agricultural',
      startingPage: 114,
    },
    2: {
      description:
        '5 Mind and 10 minutes to get 1 Produce other than Infectious Material. User may spend up to 30 Mind and 60 minutes in one go, but must be same Produce. Requires Active RP and Full Engagement.',
      name: 'Proficient Agricultural',
      startingPage: 116,
    },
    3: {
      description:
        '5 Mind and 10 minutes to get 1 Produce or Infectious Material. User may spend up to 30 Mind and 60 minutes in one go, but must be same Produce. May harvest out of season herbs with Basic Agricultural. Requires Active RP and Full Engagement.',
      name: 'Master Agricultural',
      startingPage: 118,
    },
    4: achievements.sheperdOfTheLand,
  },
  artisan: {
    1: {
      description:
        '5 Mind and 20 minutes to craft an item or to repair damaged or Broken armor or weapon. Requires Active RP and Full Engagement.',
      name: 'Basic Artisan',
      startingPage: 114,
    },
    2: {
      description:
        '10 Mind and 20 minutes to craft an item from Basic to Proficient, or 15 Mind and 40 minutes from scratch to Proficient. Requires Active RP and Full Engagement.',
      name: 'Proficient Artisan',
      startingPage: 116,
    },
    3: {
      description:
        '15 Mind and 20 minutes to craft an item from Proficient to Master, or 30 Mind and 60 minutes from scratch to Master. Requires Active RP and Full Engagement.',
      name: 'Master Artisan',
      startingPage: 118,
    },
    4: achievements.technoSavant,
  },
  awareness: {
    1: {
      description:
        '5 Mind, point at Target, and call "Alert" to reveal Target using Stealth symbol for 5 minutes.',
      name: 'Basic Awareness',
      startingPage: 121,
    },
    2: {
      description:
        '10 Mind to call "By the Sound of My Voice, Alert" to negate Stealth skills for 5 minutes.',
      name: 'Proficient Awareness',
      startingPage: 122,
    },
    3: {
      description:
        '1 Resolve and 10 minutes of Active RP for Target or self to call "No effect: Master Awareness" to Blinding and Blinding zone effects for 1 hour, and call "Master Awareness: Alert" while gesturing at a Target to negate a single Stealth skill for 5 minutes at no cost once during the 1 hour. Passively, User can also say "I think we\'re not alone" when Target is using Stealth within line of sight, without conveying any other details about Target.',
      name: 'Master Awareness',
      startingPage: 125,
      resolveLocked: true,
    },
    4: achievements.veteran,
  },
  balance: {
    1: {
      description:
        '5 Mind counters single effect that knocks User off their feet, moves them against their will, or Stuns them.',
      name: 'Balance',
      startingPage: 109,
    },
  },
  biogenetics: {
    1: {
      description: '5 Mind and Psionic Attack to call "Psionic: Heal 5 Body."',
      name: 'Basic Biogenetics',
      startingPage: 128,
    },
    2: {
      description:
        '10 Mind, Psionic Attack, and call "Psionic: Unconscious." to knock Target Unconscious. Does not work on Zombies. Counts as CVC against LCs or SCs.',
      name: 'Proficient Biogenetics',
      startingPage: 130,
    },
    3: {
      description:
        '1 Resolve, taking a knee or sitting, holding a hand in the air with no artificial means, and calling "Master Biogenetics: You will not die, no zombies will approach" at least once per minute to halt and reset Bleed Out timers as well as repel all Zombies within Area of Effect range. All helpful skill effects on User immediately end. Targets within range are immune to Coup De Gras. Ends if the arm drops, User is moved or drops into bleedout, or uses another skill. Cannot be used while Fractured.',
      name: 'Master Biogenetics',
      startingPage: 132,
      resolveLocked: true,
    },
    4: achievements.freeRadical,
  },
  combatTactics: {
    1: {
      description:
        '1 Mind, Strike or Ranged Attack, and calling "Double tap, coup de gras” ensures Target stays dead. Counters Targets faking death.',
      name: 'Basic Combat Tactics',
      startingPage: 109,
    },
    2: {
      description:
        '10 Mind, gesture to up to 5 Targets in a 5-feet, 180-degree, forward-facing arc, and call "Scatter Shot" to cause those Targets to be automatically hit by a single Strike or Ranged Attack. Skills and effects that normally exceed 20 damage are reduced to 20 damage. May be combined with equipment effects or skills, except for AoE or area effects and single-use items.',
      name: 'Proficient Combat Tactics',
      startingPage: 112,
    },
    3: {
      description:
        '1 Resolve and 5 minutes giving a rally speech to call "10-foot radius: All allies heal 1 body and Upsurge 20 armor."',
      name: 'Master Combat Tactics',
      startingPage: 113,
      resolveLocked: true,
    },
    4: achievements.battlefieldCommander,
  },
  criminalInfluence: {
    1: {
      description:
        "Check-in tells User time and location of all criminal, guild-specific, or black market meetings. 5 Mind and 5 minutes of Active RP and Full Engagement to disarm and recover Basic traps. Once per event, User may see and buy 1 item from this skill's buyer’s list. Allows use of this skill’s equipment.",
      name: 'Basic Criminal Influence',
      startingPage: 115,
    },
    2: {
      description:
        'Allows User to give up to 3 item cards at 5 Mind per item to Logistics, before criminal or black market meeting occurs, to be sold by an Extra during the meeting. 5 Mind and 5 minutes of Active RP and Full Engagement to disarm and recover Proficient traps. Once per event, User may see and buy 1 item from this skill’s buyer’s list. Allows use of this skill’s equipment.',
      name: 'Proficient Criminal Influence',
      startingPage: 117,
    },
    3: {
      description:
        'Considered Inner Circle in local criminal organizations. Can submit an Action Request to get a job for up to 15 people maximum with some level of this skill. Reward is Gizmo: Free X Criminal Influence Voucher (X = level of skill of recipient) to view and get 1 free item from X level of this skill’s list. User may spend 5 trade notes for an item that may help during the mission. 10 Mind and 5 minutes Active RP and Full Engagement to disarm and recover Master traps. Once per event, User may see and buy 1 item from this skill’s buyer’s list. Allows use of this skill’s equipment.',
      name: 'Master Criminal Influence',
      startingPage: 120,
    },
    4: achievements.innerCircle,
  },
  culinary: {
    1: {
      description:
        '5 Mind and 20 minutes of Active RP and Full Engagement to craft item.',
      name: 'Basic Culinary',
      startingPage: 114,
    },
    2: {
      description:
        '10 Mind and 20 minutes of Active RP and Full Engagement to craft item.',
      name: 'Proficient Culinary',
      startingPage: 116,
    },
    3: {
      description:
        '15 Mind and 20 minutes of Active RP and Full Engagement to craft item.',
      name: 'Master Culinary',
      startingPage: 119,
    },
    4: achievements.artisanalCuisiner,
  },
  education: {
    1: {
      description: 'Grants 3 Lores. Used to reproduce many blueprints.',
      name: 'Basic Education',
      startingPage: 114,
    },
    2: {
      description:
        '1 Mind per participant and 15 minutes of Active RP to teach a skill possessed by User to Target participants. Skill is usable at next 12s. Grants 3 additional Lores.',
      name: 'Proficient Education',
      startingPage: 116,
    },
    3: {
      description:
        '5 Mind and 15 minutes of Active RP to teach a skill that the User possesses. Skill is immediately usable by Target. Grants 3 additional Lores.',
      name: 'Master Education',
      startingPage: 118,
    },
    4: achievements.sage,
  },
  exoticWeapon: {
    1: {
      description: 'All characters have free use of exotic weapons.',
      name: 'Basic Exotic Weapon',
      startingPage: 112,
    },
    2: {
      description:
        '5 Mind for a single use of mechanics awarded by Proficient or higher items of this type.',
      name: 'Proficient Exotic Weapon',
      startingPage: 112,
    },
    3: {
      description:
        '10 Mind for a single use of mechanics awarded by Master level items of this type.',
      name: 'Master Exotic Weapon',
      startingPage: 113,
    },
    4: achievements.violenceTechnician,
  },
  enhancedMovement: {
    1: {
      description:
        '5 Mind grants 5 unchasable steps with Stealth symbol for 3 seconds max. Must be used from cover unless escaping, and is intended to ignore environmental conditions that break Stealth. Can carry Target in bleed out at full speed. Countered for 5 minutes by Alert, but does not stop User from continuing to carry a Target for the full 5 steps. Cannot chain or link with other mechanics during movement.',
      name: 'Basic Enhanced Movement',
      startingPage: 121,
    },
    2: {
      description:
        '10 Mind to take 5 steps (max 3 seconds) out-of-character. Must be used starting from cover. Cannot be chained or linked with any other mechanic during movement.',
      name: 'Proficient Enhanced Movement',
      startingPage: 123,
    },
    3: {
      description:
        '20 Mind to take 10 steps (max 5 seconds) out-of-character. Must be used starting from cover. Cannot be chained or linked with any other mechanic during movement.',
      name: 'Master Enhanced Movement',
      startingPage: 125,
    },
    4: achievements.flanker,
  },
  faithVessel: {
    1: {
      description:
        '5 Mind, Active verbal RP, and Psionic Attack on same-faith Target to call "Psionics (Faith): Immune first Break Armor until next 12s." Does not stack.',
      name: 'Basic Faith Vessel',
      startingPage: 129,
    },
    2: {
      description:
        '10 Mind and Psionic Attack to call "Psionic (Faith): 1 minute no Anomalies."',
      name: 'Proficient Faith Vessel',
      startingPage: 132,
    },
    3: {
      description:
        '1 Resolve to gain 5 uses of Psionic Attack "Heal 10 body recover 5 Mind". After 10 minutes or all 5 uses, User becomes Unconscious and drops into Bleed Out (with 0 health) wherever they are. Gains 2 Fractures. Cannot be used while Fractured.',
      name: 'Master Faith Vessel',
      startingPage: 134,
      resolveLocked: true,
    },
    4: achievements.apocatastianTemplar,
  },
  faithfulMiracles: {
    1: {
      description:
        '5 Mind, verbal prayer, and hands over meal/brew to temporarily remove all following effects for immediate consumption: damage done by consuming or contact, Unconsciousness or Stun caused by consuming, and some plot effects.',
      name: 'Basic Faithful Miracles',
      startingPage: 129,
    },
    2: {
      description:
        '10 Mind and Active verbal RP to call "Psionics: By the Sound of My Voice all (Faith) heal 5."',
      name: 'Proficient Faithful Miracles',
      startingPage: 132,
    },
    3: {
      description:
        '10 seconds Active channeling RP, 1 Resolve, and Psionic Attack to call "Psionic (Faith): Heal all Body, fix limbs." User takes a standard Stun after use. Cannot be used while Fractured.',
      name: 'Master Faithful Miracles',
      startingPage: 134,
      resolveLocked: true,
    },
    4: achievements.saint,
  },
  faithfulPatterns: {
    1: {
      description:
        "5 Mind and 10 minutes Active RP with Active Participation inducts one willing Target into User's faith.",
      name: 'Basic Faithful Patterns',
      startingPage: 129,
    },
    2: {
      description:
        '10 Mind, Active verbal RP, and Psionic Attack to call "Psionic Suggestion: Declare your faith and devotion." If User and Target are of the same faith, User\'s Mind is refunded. May not knowingly reuse on Target for another hour. Mute Targets will try to non-verbally answer. Countered by Mental Endurance.',
      name: 'Proficient Faithful Patterns',
      startingPage: 131,
    },
    3: {
      description:
        '1 Resolve while in a Morgue to allow Target to enter Grave Mind scene of another. If User, Target, and person who passed are all the same faith, then Target may spend their Resolve on behalf of person who passed. Can be used for other Grave Mind scenes, such as Graverobber procedures, for the same purpose. Cannot be used while Fractured.',
      name: 'Master Faithful Patterns',
      startingPage: 133,
      resolveLocked: true,
    },
    4: achievements.doctrinarian,
  },
  faithfulSpirit: {
    1: {
      description:
        '5 Mind and Psionic Attack on same faith Target to call "Psionics (Faith): Immune first Blind or Mangle until next 12s." Does not stack. Requires Active verbal RP.',
      name: 'Basic Faithful Spirit',
      startingPage: 129,
    },
    2: {
      description:
        '10 Mind, 10 minutes Active prayer RP, Psionic Attack, and calling "Psionics (Faith): Undying Spirit" allows Target of same faith to spend 1 Resolve and call "Faithful Spirit: No effect 1 minute" to coup de gras on Target until next 12s or until it’s used. Does not prevent standard Bleed Out and does not stack.',
      name: 'Proficient Faithful Spirit',
      startingPage: 131,
    },
    3: {
      description:
        '1 Resolve and Psionic Attack to call "Psionic (Faith): 50 Body to zombies" (single target). Cannot be used while Fractured.',
      name: 'Master Faithful Spirit',
      startingPage: 134,
      resolveLocked: true,
    },
    4: achievements.perniciousSavior,
  },
  faithfulWill: {
    1: {
      description:
        '5 Mind, Psionic Attack on same faith Target, Active verbal RP, and call "Psionics (Faith): Soothe Fractures." to soothe all Fractures for 1 hour.',
      name: 'Basic Faithful Will',
      startingPage: 129,
    },
    2: {
      description:
        '10 Mind, at least 20 minutes of Active benediction RP, and call "Psionics (Faith): Extended Bleed Out" to extend Bleedout timers of up to 5 participant Targets of same faith to 10 minutes until next 12s. Does not stack.',
      name: 'Proficient Faithful Will',
      startingPage: 131,
    },
    3: {
      description:
        '1 Resolve and Psionic Attack to prevent Infection loss up to 10 minutes after Target’s death. Target may gain multiple Fractures in Grave Mind scene. 1 additional Resolve prevents User from gaining a Fracture upon use. Cannot be used while Fractured.',
      name: 'Master Faithful Will',
      startingPage: 134,
      resolveLocked: true,
    },
    4: achievements.lifeBinder,
  },
  financialInfluence: {
    1: {
      description:
        '5 Mind and 1 minute Active RP to learn expiration and all mechanical effects of one item. Mind and time lost if interrupted.',
      name: 'Basic Financial Influence',
      startingPage: 115,
    },
    2: {
      description:
        'During Check-in, can gamble 4 Local Currency to add to modifier (between -3 and 3) of one of the six local Financial Institutions. A D6 is rolled. 1 = Lose all 4 gambled Currency. 2 = Lose only 2 Currency. 3 = Neutral. 4 = Gain the gambled 4 and 1 additional Currency, 5 = Gain 2 Currency, 6 = Gain 4 Currency.',
      name: 'Proficient Financial Influence',
      startingPage: 117,
    },
    3: {
      description:
        'May create a sister organization to one of the Financial Institutions via an Action Request, then request plot to help it (only 1 per month, can include up to 10 with some level of this skill.). Will gain up to 10 Currency on successful mission. Once per event may spend 10 Mind to trade up to 20 trade notes to local currency anywhere, or vice versa.',
      name: 'Master Financial Influence',
      startingPage: 119,
    },
    4: achievements.fiscalMysticist,
  },
  florentine: {
    1: {
      description:
        'Allows use of 2 weapons and 6-strike flurry for melee. Only a brawler is usable in the offhand without this skill.',
      name: 'Basic Florentine',
      startingPage: 109,
    },
    2: {
      description:
        '5 Mind for a single use of mechanics awarded by Proficient or higher items of this type.',
      name: 'Proficient Florentine',
      startingPage: 112,
    },
    3: {
      description:
        '10 Mind for a single use of mechanics awarded by Master level items of this type.',
      name: 'Master Florentine',
      startingPage: 113,
    },
    4: achievements.mercenary,
  },
  foraging: {
    1: {
      description:
        '5 Mind and 30 minutes Active RP in woods or wilderness to get 1 Craftable Stone, 1 High Grade Lumber, or 1 Tree Nuts. 0 Mind to pick up Foraging cards for 1 Basic Herb or Scrap.',
      name: 'Basic Foraging',
      startingPage: 121,
    },
    2: {
      description:
        '5 Mind per Foraging card to make them a Named Resource. 10 Mind and 30 minutes Active RP in wilderness to get 1 Combustible Materials or 1 Natural Fibers.',
      name: 'Proficient Foraging',
      startingPage: 123,
    },
    3: {
      description:
        '15 Mind, 30 minutes of Active RP in woods or wilderness to find 1 Psionic Crystals.',
      name: 'Master Foraging',
      startingPage: 125,
    },
    4: achievements.scavenger,
  },
  hunting: {
    1: {
      description:
        '5 Mind and 30 minutes Active RP at nearby body of water to get 1 Fish or Shellfish, or setting nonfunctional traps in forest to get 1 Meat.',
      name: 'Basic Hunting',
      startingPage: 121,
    },
    2: {
      description:
        '5 Mind and 20 minutes of Active RP, may combine 2 of the same Produce that are about to expire to extend lifespan 3 months.',
      name: 'Proficient Hunting',
      startingPage: 123,
    },
    3: {
      description:
        '1 Resolve and 20 minutes Active RP to set a trap representation that includes paper with mechanics 50 Body damage and Stuns any Creature within the area when the trap triggers. May submit an Action Request to request a big game hunt to gain appropriate Produce cards.',
      name: 'Master Hunting',
      startingPage: 125,
      resolveLocked: true,
    },
    4: achievements.wastelandStalker,
  },
  larceny: {
    1: {
      description:
        '5 Mind and 2 minutes Active RP and Full Engagement opens Basic lock. 5 Mind and successfully placing black clothespins unnoticed on the pockets or bags steals 1 random item card from Supply Bag. Aiming for an item displayed on Target requires a clothespin close to that item. Counts as CvC and requires Guide.',
      name: 'Basic Larceny',
      startingPage: 115,
    },
    2: {
      description:
        '5 Mind and 2 minutes to open Proficient lock. 5 Mind and successfully placing black clothespins on the pockets or bags of Target with Guide present steals 1 highest value item card. Aiming for an item displayed on Target requires a clothespin closer to that item. Counts as CvC.',
      name: 'Proficient Larceny',
      startingPage: 117,
    },
    3: {
      description:
        '15 Mind and 2 minutes RP picks Master lock. Once per event, spend 1 Resolve to instantly unlock.',
      name: 'Master Larceny',
      startingPage: 119,
      resolveLocked: true,
    },
    4: achievements.villon,
  },
  malicious: {
    1: {
      description:
        '5 Mind and 5 minutes Active RP makes Target answer one question truthfully. Basic Mental Endurance allows Target to not answer. Counts as CvC against LC or SC. 5 Mind and minimum 10 seconds RP with binding phys-rep to bind willing or Subdued Target, who will then not be able to move of their own volition, use any skills other than Anomaly skills, or use any items at all, unless stated otherwise on the item card.',
      name: 'Basic Malicious',
      startingPage: 121,
    },
    2: {
      description:
        '5 Mind and successful Strike on Target’s limb to call "Mangle." Can only be countered by Avoid if delivered to the front.',
      name: 'Proficient Malicious',
      startingPage: 123,
    },
    3: {
      description:
        '1 Resolve and point at Target while calling "Death Stalk" to ignore 20 seconds of strikes and effects while stalking directly towards the Target no more than 15 paces away. Must strike Target upon reaching them, and will then begin taking damage and skill effects as per normal.',
      name: 'Master Malicious',
      startingPage: 126,
      resolveLocked: true,
    },
    4: achievements.inquisitor,
  },
  medical: {
    1: {
      description:
        '1 minute of Full Engagement RP to stabilize Target in Bleed Out. 1 minute Full Engagement to learn Target’s current Body, total Body, Fractures, Diseases, and drugs or poisons they may be effected by. 1 minute to check if Target is dead, alive, Unconscious, or under an effect. Allows use of this skill’s equipment.',
      name: 'Basic Medical',
      startingPage: 114,
    },
    2: {
      description:
        '5 minutes to use 1 Mind per every 2 Body healed on Target.  Requires Active Role-Play and Full Engagement. 5 Mind and 5 minutes active RP to heal Mangle.',
      name: 'Proficient Medical',
      startingPage: 116,
    },
    3: {
      description:
        '5 Mind and 5 minutes of Active RP and Full Engagement to restore 15 Body to the Target. May be a requirement to cure deadlier diseases.',
      name: 'Master Medical',
      startingPage: 118,
    },
    4: achievements.graverobber,
  },
  meleeSmall: {
    1: {
      description: 'All characters have free use of melee small weapons.',
      name: 'Basic Melee Small',
      startingPage: 112,
    },
    2: {
      description:
        '5 Mind for a single use of mechanics awarded by Proficient or higher items of this type.',
      name: 'Proficient Melee Small',
      startingPage: 112,
    },
    3: {
      description:
        '10 Mind for a single use of mechanics awarded by Master level items of this type.',
      name: 'Master Melee Small',
      startingPage: 113,
    },
    4: achievements.assassin,
  },
  meleeStandard: {
    1: {
      description: 'All characters have free use of melee standard weapons.',
      name: 'Basic Melee Standard',
      startingPage: 112,
    },
    2: {
      description:
        '5 Mind for a single use of mechanics awarded by Proficient or higher items of this type.',
      name: 'Proficient Melee Standard',
      startingPage: 112,
    },
    3: {
      description:
        '10 Mind for a single use of mechanics awarded by Master level items of this type.',
      name: 'Master Melee Standard',
      startingPage: 113,
    },
    4: achievements.badAss,
  },
  meleeTwoHanded: {
    1: {
      description: 'All characters have free use of melee two-handed weapons.',
      name: 'Basic Melee Two-Handed',
      startingPage: 112,
    },
    2: {
      description:
        '5 Mind for a single use of mechanics awarded by Proficient or higher items of this type.',
      name: 'Proficient Melee Two-Handed',
      startingPage: 112,
    },
    3: {
      description:
        '10 Mind for a single use of mechanics awarded by Master level items of this type.',
      name: 'Master Melee Two-Handed',
      startingPage: 113,
    },
    4: achievements.bloodiedLance,
  },
  mentalEndurance: {
    1: {
      description:
        '5 Mind and call "Mental Endurance" to counter an Anomaly skill Targeted at User or User’s carried equipment or to remain silent under effects of Basic Malicious torture.',
      name: 'Basic Mental Endurance',
      startingPage: 122,
    },
    2: {
      description:
        '5 Mind and 10 minutes of Active Participation with sentient Target, may ask 1 yes or no question about the Target’s skill set, short-term intent, or mind-set. Malicious use is considered a CVC action.',
      name: 'Proficient Mental Endurance',
      startingPage: 123,
    },
    3: {
      description:
        '20 Mind and 5 minutes of Active RP to declare "Area of effect: No Anomaly skills." User cannot move from this area while this is active or the effect ends. User must declare "No effect: Mental Endurance." to any use of Anomaly skills within the zone. Anomaly effects end when entering the zone.',
      name: 'Master Mental Endurance',
      startingPage: 126,
    },
    4: achievements.mindKiller,
  },
  necrokinetics: {
    1: {
      description:
        '5 Mind to listen silently to a post-death scene with that player’s consent. Cost is refunded if they do not consent.',
      name: 'Basic Necrokinetics',
      startingPage: 128,
    },
    2: {
      description:
        '10 Mind and Psionic Attack allows Target access to a single Lore that the User does not have for 30 minutes. May also be used in specific Mortis Amaranthine scenes. User gains a Fracture.',
      name: 'Proficient Necrokinetics',
      startingPage: 130,
    },
    3: {
      description:
        "1 Resolve and Psionic Attack on willing or Subdued Target to gradually remove all of Target's Fractures and Diseases. Target is Stunned for 5 minutes for every Fracture and each progressed stage of a Disease a Target has, up to a maximum of 30 minutes. During this duration, User must engage Target in mental psychic surgery RP. Cannot be used during combat or while Fractured. Counts as CVC against unwilling and Subdued LCs or SCs.",
      name: 'Master Necrokinetics',
      startingPage: 132,
      resolveLocked: true,
    },
    4: achievements.graveAttuned,
  },
  projectile: {
    1: {
      description: 'All characters have free use of projectile weapons.',
      name: 'Basic Projectile',
      startingPage: 112,
    },
    2: {
      description:
        '5 Mind for a single use of mechanics awarded by Proficient or higher items of this type.',
      name: 'Proficient Projectile',
      startingPage: 112,
    },
    3: {
      description:
        '10 Mind for a single use of mechanics awarded by Master level items of this type.',
      name: 'Master Projectile',
      startingPage: 113,
    },
    4: achievements.marksman,
  },
  pyrokinetics: {
    1: {
      description:
        '5 Mind and Psionic Attack to call "Psionic: 5 Body" or "Psionic: Break (Blueprint, Brew, or Meal)." Countered by Mental Endurance for damage and held items. Can be used as coup de gras.',
      name: 'Basic Pyrokinetics',
      startingPage: 128,
    },
    2: {
      description:
        '10 Mind, Psionic Attack to call "Psionic: 20 Body." Use of this skill causes the User’s hands to ash and char over time.',
      name: 'Proficient Pyrokinetics',
      startingPage: 130,
    },
    3: {
      description:
        '1 Resolve to call "Psionic: 3 Steps - 30 Body, Self: Break all brews and injectibles" to affect all Targets within range of the User. Cannot be used while Fractured. Countered by Master Mental Endurance only. Counts as CVC when used against LCs or SCs.',
      name: 'Master Pyrokinetics',
      startingPage: 133,
      resolveLocked: true,
    },
    4: achievements.incinerator,
  },
  sailing: {
    1: {
      description:
        '5 Mind to sail during specific modules. Once per event, User may see and buy 1 item from this skill’s buyer’s list. Allows use of this skill’s equipment.',
      name: 'Basic Sailing',
      startingPage: 122,
    },
    2: {
      description:
        '5 Mind to state "I’m the captain." during a sailing mod to ignore damage and skill effects for 1 minute, during which they can do nothing but sail. Allows use of this skill’s equipment. Once per event, User may see and buy 1 item from this skill’s buyer’s list.',
      name: 'Proficient Sailing',
      startingPage: 124,
    },
    3: {
      description:
        '10 Mind during a sailing module and call "Hard to starboard!" to cause enemies on the ship to be knocked off for 1 minute. Allows use of this skill’s equipment. May submit an Action request to request a sailing module for up to 10 Users with some level of this skill. If successful, all participants receive Gizmo: Free X Sailing Voucher ((X = level of skill of recipient) to view and get 1 free item from X level of this skill’s buyer’s list.',
      name: 'Master Sailing',
      startingPage: 126,
    },
    4: achievements.blueJacket,
  },
  salvaging: {
    1: {
      description:
        '5 Mind and 30 minutes of Active RP in wilderness area, to get 1 Machined Components or 1 Synthetic Fibers.',
      name: 'Basic Salvaging',
      startingPage: 122,
    },
    2: {
      description:
        '10 Mind and 30 minutes of Active RP in a wilderness to get 1 Plastics or 1 Mechanical Components.',
      name: 'Proficient Sailing',
      startingPage: 124,
    },
    3: {
      description:
        '15 Mind and 30 minutes of Active RP in wilderness to get 1 Recovered Electronics.',
      name: 'Master Salvaging',
      startingPage: 127,
    },
    4: achievements.avontuur,
  },
  shield: {
    1: {
      description: 'All characters have free use of shields.',
      name: 'Basic Shield',
      startingPage: 112,
    },
    2: {
      description:
        '5 Mind for a single use of mechanics awarded by Proficient or higher items of this type.',
      name: 'Proficient Shield',
      startingPage: 112,
    },
    3: {
      description:
        '10 Mind for a single use of mechanics awarded by Master level items of this type.',
      name: 'Master Shield',
      startingPage: 113,
    },
    4: achievements.phalanxDoyen,
  },
  socialInfluence: {
    1: {
      description:
        '5 Mind to gain access to any private meeting or event for 30 minutes, before needing to use again. Does not prevent later repercussions. At check-in, may learn up to 5 very valuable pieces of information.',
      name: 'Basic Social Influence',
      startingPage: 115,
    },
    2: {
      description:
        '10 Mind and 30 minutes of Active Participation with Target to resolve 1 Fracture.',
      name: 'Proficient Social Influence',
      startingPage: 118,
    },
    3: {
      description:
        '1 Resolve and 30 minutes Active RP to give Target 1 Resolve.',
      name: 'Master Social Influence',
      startingPage: 120,
      resolveLocked: true,
    },
    4: achievements.overlord,
  },
  stealth: {
    1: {
      description:
        '5 Mind to put up Stealth symbol while under cover after dark and away from bright lights.',
      name: 'Basic Stealth',
      startingPage: 122,
    },
    2: {
      description:
        '10 minutes camouflaging User or Target. After night, may spend 10 Mind to put up the Stealth symbol 3 times in the next hour. Countered by Awareness for 5 minutes. User may also spend 5 Mind and do a full costume change to be in disguise for 30 minutes. Can be refreshed for 5 Mind.',
      name: 'Proficient Stealth',
      startingPage: 124,
    },
    3: {
      description:
        'Once per event, may spend 1 Resolve and declare "Never here" to put up the out-of-game symbol and go to logistics to appear in a different location. Countered by "No Escape" which exists in rare items and certain higher tier threat.',
      name: 'Master Stealth',
      startingPage: 127,
      resolveLocked: true,
    },
    4: achievements.shadow,
  },
  telekinetics: {
    1: {
      description:
        '5 Mind, Psionic Attack, and call "Psionic: Drop (left hand or right hand) toward me" to make Target drop held item at least 1 step toward User. If 2 hands are required, then both hands are dropped.',
      name: 'Basic Telekinetics',
      startingPage: 128,
    },
    2: {
      description:
        '10 Mind, Psionic Attack, and call "Psionic: Knock back 5 steps and Stun." to cause Target to be knocked back 5 steps or until hitting a larger solid object. Can be used on self.',
      name: 'Proficient Telekinetics',
      startingPage: 130,
    },
    3: {
      description:
        '1 Resolve, Psionic Attack, and call "Break all armor and brews." Countered by Mental Endurance. Cannot be used if User has a Fracture. Counts as CvC against LCs or SCs.',
      name: 'Master Telekinetics',
      startingPage: 133,
      resolveLocked: true,
    },
    4: achievements.mentalist,
  },
  telepathy: {
    1: {
      description:
        '5 Mind, Psionic Attack on Target Creature, make eye contact, and call "Psionic: Suggestion (no more than 3 words)". Causes Target Creature to follow suggestion until they are harmed, 10 minutes have passed, their instructions would cause self-destruction, or they reach a point where the request is outside their capacities.',
      name: 'Basic Telepathy',
      startingPage: 128,
    },
    2: {
      description:
        '10 Mind, Psionic Attack, and call "Psionic: Suggestion: Speak without filters." to cause Target to vocalize all internalized thoughts for 5 minutes. Questions must be answered without limitation. Mute or characters under Choking Blow will do their best to communicate. Countered by Mental Endurance.',
      name: 'Proficient Telepathy',
      startingPage: 130,
    },
    3: {
      description:
        '1 Resolve and Psionic Attack to call "Psionic: Blinding, Stun, 10 Body." Countered by Mental Endurance. Cannot be used if User has a Fracture. Counts as CvC against LCs or SCs.',
      name: 'Master Telepathy',
      startingPage: 133,
      resolveLocked: true,
    },
    4: achievements.thoughtBender,
  },
  thrownWeapon: {
    1: {
      description: 'All characters have free use of thrown weapons.',
      name: 'Basic Thrown Weapon',
      startingPage: 112,
    },
    2: {
      description:
        '5 Mind for a single use of mechanics awarded by Proficient or higher items of this type.',
      name: 'Proficient Thrown Weapon',
      startingPage: 112,
    },
    3: {
      description:
        '10 Mind for a single use of mechanics awarded by Master level items of this type.',
      name: 'Master Thrown Weapon',
      startingPage: 113,
    },
    4: achievements.deathChuckah,
  },
  tradeConnections: {
    1: {
      description:
        'Once per event, User may see and buy 1 item from this skill’s buyer’s list, and trade up to 3 Uncommon Herb for 3 Uncommon Scrap.',
      name: 'Basic Trade Connections',
      startingPage: 115,
    },
    2: {
      description:
        'Once per event, User may see and buy 1 item from this skill’s buyer’s list. Once per event, may trade up to 3 Rare Herb for 3 Rare Scrap.',
      name: 'Proficient Trade Connections',
      startingPage: 117,
    },
    3: {
      description:
        'Once per event, User may see and buy 1 item from this skill’s buyer’s list. Once per event, may trade up to 3 Named Herb for 3 Named Scrap.',
      name: 'Master Trade Connections',
      startingPage: 119,
    },
    4: achievements.entrepreneur,
  },
  trailblazing: {
    1: {
      description:
        '5 Mind to drive in specific modules. Allows use of this skill’s equipment. Once per event, 5 Mind to get 1 Alloy Metal or 1 Conductive Metal.',
      name: 'Basic Trailblazing',
      startingPage: 122,
    },
    2: {
      description:
        '5 Mind and a Ride at Check-in to skip opening modules. Allows use of this skill’s equipment. Once per event, may spend 5 Mind to get 1 Hard Metal or 1 Radioactive Metal.',
      name: 'Proficient Trailblazing',
      startingPage: 124,
    },
    3: {
      description:
        '10 Mind and a ride to enable up to 3 others to skip the opening module. Once per event, may spend 10 Mind to get 1 Soft Metal.',
      name: 'Master Trailblazing',
      startingPage: 127,
    },
    4: achievements.gearHead,
  },
  unarmedCombat: {
    1: {
      description: 'All characters have free use of unarmed weapons.',
      name: 'Basic Unarmed Combat',
      startingPage: 111,
    },
    2: {
      description:
        '5 Mind for a single use of mechanics awarded by Proficient or higher items of this type. May block with brawlers without taking damage or effects from Strikes.',
      name: 'Proficient Unarmed Combat',
      startingPage: 111,
    },
    3: {
      description:
        '10 Mind for a single use of mechanics awarded by Master level items of this type. 10 Mind, successful brawler-to-torso Strike, and calling "Knockout" causes Target to fall Unconscious for 5 minutes until they take damage or mechanical effects, or someone RPs 30 seconds to rouse them.',
      name: 'Master Unarmed Combat',
      startingPage: 114,
    },
    4: achievements.boneBreaker,
  },
  blinding: {
    1: {
      description:
        '5 Mind and make a successful Strike or Ranged Attack to the torso to cause Target to be unable to use any learned skills, equipment that relies on those skills, or any Achievement bonuses. Target cannot recall those 10 seconds. May still run and use basic weapon skills. Countered by Avoid.',
      name: 'Blinding',
      startingPage: 110,
    },
  },
  break: {
    1: {
      description:
        '5 Mind, and specify one variable: Armor, Shield, Weapon, Gizmo, Barricade, Door, Brew, Meal, or Non-Mechanic. A successful Strike or Ranged Attack on a crafted target or a limb holding a crafted target, renders the specified object nonfunctional until repaired.',
      name: 'Break',
      startingPage: 110,
    },
  },
  chokingBlow: {
    1: {
      description:
        '5 Mind and make a successful Strike or Ranged Attack to the torso to cause Target to be unable to make noises above a low gurgle for 5 minutes. Prevents Target from using any skills that require social engagement or verbal aspects.',
      name: 'Choking Blow',
      startingPage: 110,
    },
  },
  interfere: {
    1: {
      description:
        '0 Mind to call "Interfere" and take on the negative effects and damage delivered via single target Strike or Ranged Attack. User must be a valid target. The interfered effect is uncounterable.',
      name: 'Interfere',
      startingPage: 111,
    },
  },
  piercingStrike: {
    1: {
      description:
        '5 Mind for a single successful melee, unarmed, thrown, or projectile attack to take on the Body modifier and ignore armor. Overrides any pre-existing modifiers.',
      name: 'Piercing Strike',
      startingPage: 111,
    },
  },
  takeDown: {
    1: {
      description:
        '5 Mind, successful Strike or Ranged Attack to the torso, and call "Takedown" to Stun Target for 5 seconds. Target must sit or flail to the ground.',
      name: 'Take Down',
      startingPage: 111,
    },
  },
};
