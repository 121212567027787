import React from 'react';
import styles from './Character.module.scss';

export default ({
  structuredSkill,
  structuredStat,
  characterData,
  ticketData,
  buildGrowth,
  eventBaseBuild,
  buildAlreadyIncluded,
  isHomeGame,
  isBlankSheet,
}) => {
  const buildComponents = {
    stat: structuredStat.statBuildTotal,
    combat: structuredSkill.combat.categorySum,
    civilized: structuredSkill.civilized.categorySum,
    wasteland: structuredSkill.wasteland.categorySum,
    anomaly: structuredSkill.anomaly.categorySum,
  };
  const totalBuildSpent = Object.values(buildComponents).reduce(
    (a, b) => a + b,
    0,
  );
  const isDayPass = ticketData.pass_type === 'day_pass';
  const homeGamesCount = characterData.total_home_games;

  const forwardGrowthCurve = React.useMemo(() => {
    const dayPassReduction = isDayPass ? 2 : 0;

    if (!isHomeGame && isDayPass) return 1;
    if (!isHomeGame && !isDayPass) return 2;

    if (homeGamesCount < 1) return 16 - dayPassReduction;
    if (homeGamesCount < 10) return 8 - dayPassReduction;
    if (homeGamesCount < 24) return 4 - dayPassReduction;

    return 3 - dayPassReduction;
  }, [isDayPass, isHomeGame, homeGamesCount]);

  const backwardGrowthCurve = React.useMemo(() => {
    const dayPassReduction = isDayPass ? 2 : 0;

    if (!isHomeGame && isDayPass) return 1;
    if (!isHomeGame && !isDayPass) return 2;

    if (homeGamesCount <= 1) return 16 - dayPassReduction;
    if (homeGamesCount <= 10) return 8 - dayPassReduction;
    if (homeGamesCount <= 24) return 4 - dayPassReduction;

    return 3 - dayPassReduction;
  }, [isDayPass, isHomeGame, homeGamesCount]);

  const eventBuildGrowth =
    (buildAlreadyIncluded ? backwardGrowthCurve : forwardGrowthCurve) +
    (buildGrowth || 0) +
    (eventBaseBuild || 0);

  const preBuild =
    characterData.build_earned - (buildAlreadyIncluded ? eventBuildGrowth : 0);
  const postBuild =
    characterData.build_earned + (buildAlreadyIncluded ? 0 : eventBuildGrowth);
  const deltaBuild = postBuild - preBuild - buildGrowth;
  const plusBuild = buildGrowth && buildGrowth > 0 ? ` + ${buildGrowth}` : '';

  return (
    <div className={styles.buildComponents}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.leftSide}>XP Components</th>
            <th className={styles.rightSide}>Qty</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={styles.text}>Stats XP</td>
            <td className={[styles.text, styles.rightAlign].join(' ')}>
              {isBlankSheet ? '' : buildComponents.stat}
            </td>
          </tr>
          <tr>
            <td className={styles.text}>Combat Skills</td>
            <td className={[styles.text, styles.rightAlign].join(' ')}>
              {isBlankSheet ? '' : buildComponents.combat}
            </td>
          </tr>
          <tr>
            <td className={styles.text}>Civilized Skills</td>
            <td className={[styles.text, styles.rightAlign].join(' ')}>
              {isBlankSheet ? '' : buildComponents.civilized}
            </td>
          </tr>
          <tr>
            <td className={styles.text}>Wasteland Skills</td>
            <td className={[styles.text, styles.rightAlign].join(' ')}>
              {isBlankSheet ? '' : buildComponents.wasteland}
            </td>
          </tr>
          <tr>
            <td className={styles.text}>Anomaly Skills</td>
            <td className={[styles.text, styles.rightAlign].join(' ')}>
              {isBlankSheet ? '' : buildComponents.anomaly}
            </td>
          </tr>
          <tr>
            <td className={[styles.text, styles.bold].join(' ')}>
              Total XP Spent
            </td>
            <td
              className={[styles.text, styles.rightAlign, styles.bold].join(
                ' ',
              )}
            >
              {isBlankSheet ? '' : totalBuildSpent}
            </td>
          </tr>
          <tr>
            <td colSpan={2} />
          </tr>
          <tr>
            <td className={styles.text}>Current XP Earned</td>
            <td className={[styles.text, styles.rightAlign].join(' ')}>
              {isBlankSheet ? '' : preBuild}
            </td>
          </tr>
          <tr>
            <td className={styles.text}>Event XP Growth</td>
            <td className={[styles.text, styles.rightAlign].join(' ')}>
              {isBlankSheet ? '' : `${deltaBuild}${plusBuild}`}
            </td>
          </tr>
          <tr>
            <td className={styles.text}>Post-Event XP</td>
            <td className={[styles.text, styles.rightAlign].join(' ')}>
              {isBlankSheet ? '' : postBuild}
            </td>
          </tr>
          <tr>
            <td className={[styles.text, styles.bold].join(' ')}>
              Available XP
            </td>
            <td
              className={[styles.text, styles.rightAlign, styles.bold].join(
                ' ',
              )}
            >
              {isBlankSheet ? '' : postBuild - totalBuildSpent}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
