import { useDispatch, useSelector } from 'react-redux';
import { fromPairs, isEqual } from 'lodash';
import React, { useEffect } from 'react';
import Close from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import DropdownField from '../FormComponents/DropdownField';
import TextField from '../FormComponents/TextField';
import branches from '../../gameData/branches';
import styles from './EditUserModal.module.scss';

export default () => {
  const remoteFieldMap = {
    // name: 'legal_name',
    branchId: 'branch_id',
    firstName: 'first_name',
    lastName: 'last_name',
    emailAddress: 'email_address',
  };

  const presence = value => {
    return value.length > 0 ? null : 'Cannot be empty';
  };
  const validEmail = value => {
    return value.match(/.+@.+\.\w+/) !== null ? null : 'Invalid email format';
  };

  const fieldValidations = {
    lastName: [presence],
    emailAddress: [presence, validEmail],
  };
  const dispatch = useDispatch();
  const { popper, modal, user } = useSelector(state => ({
    popper: state.ui.character.popperOpened,
    modal: state.ui.modal,
    user: state.user,
  }));
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState(null);
  const [isBlankState, setIsBlankState] = React.useState(true);
  const [showNewUser, setShowNewUser] = React.useState(false);
  const [hotlinkedNewUser, setHotlinkedNewUser] = React.useState(null);
  const [errors, setErrors] = React.useState({});
  const [refocus, setRefocus] = React.useState(true);
  const reacquireFocus = () => setRefocus(Date.now());
  const popperName = 'createUserModal';
  const handleClose = () => {
    dispatch({ type: 'TOGGLE_POPPER', payload: popperName });
  };
  const impersonate = () => {
    handleClose();
    dispatch({
      type: 'IMPERSONATE_USER',
      payload: {
        userID: hotlinkedNewUser.id,
        isImpersonating: true,
      },
    });
  };
  const isErrorVisible = () => (modal.error ? '' : styles.hidden);
  const validate = (field, value) => {
    if (!fieldValidations[field]) return null;

    const errorMessages = [];
    fieldValidations[field]
      .map(functor => {
        const errorMessage = functor(value);
        if (errorMessage) errorMessages.push(errorMessage);
        return errorMessage === null;
      })
      .reduce((a, b) => a && b, true);

    return errorMessages.length > 0 ? errorMessages.join('. ') : null;
  };

  const handleChange = (field, value) => {
    setErrors({
      ...errors,
      [field]: validate(field, value),
    });
    dispatch({
      type: 'UPDATE_MODAL',
      payload: {
        field,
        value,
      },
    });
    setIsBlankState(false);
    if (user.newUser) dispatch({ type: 'CLEAR_NEW_USER' });
  };
  const filterDelta = () =>
    fromPairs(
      Object.keys(modal.initial)
        .filter(x => modal.initial[x] !== modal.current[x])
        .map(x => [remoteFieldMap[x] || x, modal.current[x]]),
    );

  const handleSubmit = () => {
    setState('updating');
    dispatch({
      type: 'CREATE_USER',
      payload: {
        changes: Object.assign(filterDelta(), {
          [remoteFieldMap['branchId']]: modal.current.branchId,
        }),
      },
    });
  };

  const newUserNotification = () => {
    if (!showNewUser) return null;
    const newUserName = [
      hotlinkedNewUser.first_name,
      hotlinkedNewUser.last_name,
    ]
      .join(' ')
      .trim();
    return (
      <React.Fragment>
        <div className={styles.break} />
        <div className={styles.success}>
          {`Player `}
          <span onClick={impersonate} className={styles.link}>
            {`#${hotlinkedNewUser.id}`}
          </span>
          {` (${newUserName}) successfully created.`}
        </div>
      </React.Fragment>
    );
  };

  const actionButton = () => {
    const buttonState = () => {
      switch (state) {
        case 'modified':
          return styles.modified;
        case 'error':
          return styles.error;
        case 'done':
          return styles.done;
        default:
          return styles.disabled;
      }
    };

    const buttonText = () => {
      switch (state) {
        case 'modified':
          return 'Create Player';
        case 'updating':
          return 'Creating Player...';
        case 'done':
          return 'Player Created!';
        case 'error':
          return 'Error, retry?';
        default:
          return 'Create Player';
      }
    };

    return (
      <button
        className={[styles.button, buttonState()].join(' ')}
        disabled={!['modified', 'error'].includes(state)}
        onClick={handleSubmit}
        type='button'
      >
        {buttonText()}
      </button>
    );
  };

  const hasLocalErrors = () =>
    Object.keys(fieldValidations)
      .map(field => validate(field, modal.current[field]) !== null)
      .reduce((a, b) => a || b, false);

  const sortedBranches = () =>
    Object.keys(branches)
      .sort((a, b) => {
        return branches[a].shortName.toLowerCase() >
          branches[b].shortName.toLowerCase()
          ? 1
          : -1;
      })
      .map(branchID => ({
        value: branchID,
        label: branches[branchID].shortName,
      }));

  useEffect(() => {
    const isOpen = popper === popperName;
    setOpen(isOpen);
  }, [popper]);

  useEffect(() => {
    if (!open) return;
    if (modal.error) {
      setState('error');
    } else {
      setState(() => {
        if (user.newUser) return 'done';
        if (isEqual(modal.initial, modal.current)) return null;

        return hasLocalErrors() ? null : 'modified';
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal, user, open]); // hack to allow Done to be displayed

  useEffect(() => {
    if (!open) return;
    if (user.newUser) {
      dispatch({
        type: 'UPDATE_MODAL',
        payload: { field: 'firstName', value: '' },
      });
      dispatch({
        type: 'UPDATE_MODAL',
        payload: { field: 'lastName', value: '' },
      });
      dispatch({
        type: 'UPDATE_MODAL',
        payload: { field: 'emailAddress', value: '' },
      });

      setShowNewUser(true);
      setHotlinkedNewUser(user.newUser);
      setIsBlankState(true);
      reacquireFocus();
    }
  }, [user, dispatch, open]);

  return (
    <Modal open={open}>
      <Fade in={open}>
        <div className={styles.base}>
          <div className={styles.header}>
            <div className={styles.title}>Create New Player</div>
            <Close className={styles.close} onClick={handleClose} />
          </div>
          <div className={styles.body}>
            <TextField
              title='First Name'
              fieldKey='firstName'
              value={modal.current.firstName || ''}
              initialValue=''
              errors={errors['firstName']}
              isBlankState={isBlankState}
              passChange={handleChange}
              revert='clear'
              autoFocus={refocus}
            />
            <TextField
              title='Last Name'
              fieldKey='lastName'
              value={modal.current.lastName || ''}
              initialValue=''
              errors={errors['lastName']}
              isBlankState={isBlankState}
              passChange={handleChange}
              revert='clear'
            />
            <TextField
              title='Email'
              fieldKey='emailAddress'
              value={modal.current.emailAddress || ''}
              initialValue=''
              errors={errors['emailAddress']}
              isBlankState={isBlankState}
              passChange={handleChange}
              revert='clear'
            />
            <DropdownField
              title='Home Branch'
              fieldKey='branchId'
              value={modal.current.branchId || 0}
              options={sortedBranches()}
              initialValue={modal.initial.branchId}
              isError={false}
              passChange={handleChange}
              revert='placebo'
            />
          </div>
          <div className={styles.footer}>
            <div className={[styles.errorMessage, isErrorVisible()].join(' ')}>
              {modal.error}
            </div>
            <button
              className={[styles.button, styles.cancel].join(' ')}
              onClick={handleClose}
              type='button'
            >
              Close
            </button>
            {actionButton()}
            {newUserNotification()}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
