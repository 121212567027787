import React from 'react';
import { fromPairs, groupBy, startCase } from 'lodash';
import { GRADE_SORT_REAL, GRADE_SORT } from './constants';
import styles from './ItemSearch.module.scss';

const REFOCUS_SEARCH_BOX = true;
const IS_TAXONOMY = true;

export default ({ itemData, setUrl, fireSearch }) => {
  const grades = Object.keys(GRADE_SORT_REAL);
  const craftingFinalProducts = itemData.item_craftings
    ? itemData.item_craftings.map(x => x.crafting_final_products).flat()
    : [];
  const itemCraftingStacks = groupBy(
    craftingFinalProducts,
    x => x.final_product_id,
  );
  const isStackGraded =
    itemData.craftable_items.length > 1 &&
    Object.keys(itemCraftingStacks).length === 1;

  const renderCraftableItems = craftableItems =>
    craftableItems
      .sort((a, b) => GRADE_SORT_REAL[a.grade] - GRADE_SORT_REAL[b.grade])
      .map((x, i) => {
        const key = isStackGraded ? `${x.id} - ${i}` : x.id;
        const grade = isStackGraded
          ? Object.keys(GRADE_SORT_REAL)[i][0]
          : x.grade[0];

        const stack = () =>
          isStackGraded
            ? itemCraftingStacks[x.id].sort((a, b) => a.stack - b.stack)[i]
                .stack
            : itemCraftingStacks[x.id][0].stack;

        return (
          <div className={styles.finalProduct} key={key}>
            <div className={styles.grade}>{grade.toUpperCase()}</div>
            <div
              className={styles.name}
              onClick={() =>
                ['taxonomy', 'abstract'].includes(x.kind)
                  ? fireSearch(x.name, REFOCUS_SEARCH_BOX, IS_TAXONOMY)
                  : setUrl(x.id)
              }
            >
              <span className={styles.clickable}>{x.name}</span>
              <span className={styles.kind}>[{startCase(x.kind)}]</span>
              <span className={styles.stack}>
                {stack() > 1 && `(${stack()} stacks)`}
              </span>
            </div>
          </div>
        );
      });

  const renderBlueprintForProducts = finalProducts =>
    grades.map(grade => (
      <td className={styles.amount} key={grade}>
        {finalProducts[grade] ? finalProducts[grade][0].amount : ''}
      </td>
    ));

  const renderBlueprintComponents = craftingComponentsById =>
    Object.keys(craftingComponentsById)
      .sort((a, b) => {
        const kindA = craftingComponentsById[a][0].component.kind;
        const kindB = craftingComponentsById[b][0].component.kind;

        if (kindA === kindB) {
          return (
            craftingComponentsById[b].length - craftingComponentsById[a].length
          );
        }

        return kindA.localeCompare(kindB);
      })
      .map(x => {
        const finalProducts = craftingComponentsById[x];
        const gradeGroupedComponents = isStackGraded
          ? fromPairs(
              finalProducts
                .sort((a, b) => a.amount - b.amount)
                .map((y, i) => [Object.keys(GRADE_SORT)[i], [y]]),
            )
          : groupBy(
              finalProducts,
              y => y.item_crafting.final_products[0].grade,
            );

        const interact = () => {
          switch (finalProducts[0].component.kind) {
            case 'abstract':
              return fireSearch(
                finalProducts[0].component.name.replace(/\(any\)/i, ''),
                REFOCUS_SEARCH_BOX,
              );
            case 'taxonomy':
              return fireSearch(
                finalProducts[0].component.name.replace(/\(any\)/i, ''),
                REFOCUS_SEARCH_BOX,
                IS_TAXONOMY,
              );
            default:
              return setUrl(finalProducts[0].component.id);
          }
        };

        return (
          <tr key={finalProducts[0].component.id}>
            <td className={styles.name}>
              <div onClick={interact}>{finalProducts[0].component.name}</div>
            </td>
            {renderBlueprintForProducts(gradeGroupedComponents)}
          </tr>
        );
      });

  const renderBlueprintCraftables = (craftableItems, components) => {
    const craftingComponentsById = groupBy(components, x => x.component.id);

    return (
      <div className={styles.content}>
        <div className={styles.finalProductList}>
          {renderCraftableItems(craftableItems)}
        </div>

        <table className={styles.components}>
          <thead>
            <tr>
              <th className={styles.header}>Components</th>
              {grades.map(x => (
                <th className={styles.header} key={x}>
                  {x[0].toUpperCase()}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{renderBlueprintComponents(craftingComponentsById)}</tbody>
        </table>
      </div>
    );
  };

  if (itemData.craftable_items.length === 0) return null;
  return (
    <div className={styles.blueprintCraftables}>
      <div className={styles.title}>Craftable Items</div>
      {renderBlueprintCraftables(
        itemData.craftable_items,
        itemData.crafting_components,
      )}
    </div>
  );
};
