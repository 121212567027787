import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Attendances from './Attendances';
import Financials from './Financials';
import Transfers from './Transfers';
import Revenue from './Revenue';
import BranchMemberships from './BranchMemberships';
import WindowedScrollbar from '../Shared/WindowedScrollbar';
import history from '../history';
import CharacterLoader from '../utils/loader';
import ErrorPage from '../Shared/ErrorPages/ErrorPage';
import BranchSelect from '../Events/Branches/SingleSelect';
import EventStatistics from '../Events/Statistics/Page';
import { isPrivileged as hasPrivilege } from '../utils/user';
import styles from './Branches.module.scss';

export default () => {
  const dispatch = useDispatch();
  const { currentCharacterID, characterStorage, user, branches } = useSelector(
    state => ({
      currentCharacterID: state.localStorage.currentCharacterID,
      characterStorage: state.localStorage.characterStorage,
      user: state.user,
      branches: state.branches,
    }),
  );
  const [selectedBranchID, setSelectedBranchID] = useState(null);
  const isPrivileged = hasPrivilege(user);
  const handleChange = (_, value) => setSelectedBranchID(value);
  const isTransferPage = history.location.pathname.match(/transfers/);
  const isStatisticsPage = history.location.pathname.match(/statistics/);
  const isFinancialsPage = history.location.pathname.match(/financials/);
  const isMembershipsPage = history.location.pathname.match(/memberships/);

  const renderContent = () => {
    if (isStatisticsPage)
      return <EventStatistics overrideBranchID={selectedBranchID || -1} />;
    if (isMembershipsPage)
      return (
        <WindowedScrollbar styles={styles} autoHeightMax='calc(100vh - 118px)'>
          <BranchMemberships
            user={user}
            data={branches.data}
            branchID={selectedBranchID}
            isFetching={branches.isFetching}
          />
          <Revenue
            user={user}
            data={branches.data}
            branchID={selectedBranchID}
            isFetching={branches.isFetching}
          />
        </WindowedScrollbar>
      );
    if (isTransferPage) {
      return (
        <Transfers
          user={user}
          branches={branches.data}
          branchID={selectedBranchID}
        />
      );
    }
    if (isFinancialsPage) {
      return (
        <Financials
          user={user}
          branches={branches.data}
          branchID={selectedBranchID}
        />
      );
    }

    return (
      <Attendances
        user={user}
        branches={branches.data}
        isFetching={branches.isFetching}
        branchID={selectedBranchID}
      />
    );
  };

  useEffect(() => {
    dispatch({ type: 'APP_LOADED' });
  }, [dispatch]);

  useEffect(() => {
    CharacterLoader({ characterStorage, currentCharacterID, dispatch });
  }, [characterStorage, currentCharacterID, dispatch]);

  useEffect(() => {
    user.branchId && setSelectedBranchID(user.branchId);
  }, [user.branchId]);

  if (!(user && user.ownedBranches)) return null;
  if (!isPrivileged) return <ErrorPage errorType='unauthorized' />;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {!isFinancialsPage && (
          <BranchSelect
            user={user}
            selectedBranch={selectedBranchID || -1}
            branchLocations={{}}
            passChange={handleChange}
            allowNational
            hideLocations
          />
        )}
      </div>
      <div className={styles.content}>{renderContent()}</div>
    </div>
  );
};
