import React, { useState } from 'react';
import InputBase from '@material-ui/core/InputBase';
import styles from './administration.module.scss';
import Toggle from 'react-toggle';
import { DAYS } from '../../constants';

const UPDATE_UPSTREAM = true;

export default ({
  attendeeShifts,
  updateShiftFulfillment,
  eventInfo,
  nameFilter,
  passChange,
  isReadOnly,
  isPrivileged,
}) => {
  const [value, setValue] = useState(0);
  const [initialValue, setInitialValue] = useState(0);
  const [hideFulfilled, setHideFulfilled] = useState(true);
  const [fulfillments, setFulfillments] = useState({});

  const isEnabled = shift => shift.state === 'enabled';
  const renderableShifts = Object.keys(attendeeShifts).filter(x =>
    eventInfo.isPremiere ? x : x !== '4',
  );

  const handleKeyDown = (kind, id, identifier, keyCode) => {
    if (keyCode === 13) {
      handleChange(kind, id, identifier, value, UPDATE_UPSTREAM);
      setInitialValue(value);
    }
  };
  const handleFocus = evt => {
    setValue(evt.target.value);
    setInitialValue(evt.target.value);
  };
  const handleChange = (kind, id, identifier, newValue, updateUpstream) => {
    setValue(newValue);
    passChange(kind, id, identifier, newValue, updateUpstream);
  };
  const handleBlur = (kind, id, identifier) => {
    value !== initialValue &&
      passChange(kind, id, identifier, value, UPDATE_UPSTREAM);
  };

  const renderAttendees = (attendeeShifts, shiftID, day, shiftLabel) =>
    attendeeShifts
      .filter(x =>
        nameFilter
          ? x.event_attendee.user.id.toString().includes(nameFilter) ||
            [x.event_attendee.user.first_name, x.event_attendee.user.last_name]
              .join(' ')
              .trim()
              .toLowerCase()
              .includes(nameFilter)
          : x,
      )
      .filter(x =>
        hideFulfilled
          ? fulfillments[x.id]
            ? fulfillments[x.id] !== 'fulfilled'
            : x.status !== 'fulfilled'
          : x,
      )
      .sort((a, b) => a.event_attendee.user.id - b.event_attendee.user.id)
      .map(x => (
        <div
          className={styles.attendeesContainer}
          key={`${shiftID}-${x.event_attendee.user.id}`}
        >
          <div className={styles.buffer} />
          <div className={styles.playerID}>
            {x.event_attendee.user.id}
            {isPrivileged && (
              <span
                className={[
                  styles.paid,
                  !x.event_attendee.paid && styles.muted,
                ].join(' ')}
              >
                $
              </span>
            )}
          </div>
          <div className={styles.playerName}>
            <input
              id={x.id}
              type='checkbox'
              checked={
                fulfillments[x.id]
                  ? fulfillments[x.id] === 'fulfilled'
                  : x.status === 'fulfilled'
              }
              className={styles.clickable}
              onChange={evt => {
                updateShiftFulfillment({
                  eventAttendeeID: x.event_attendee.id,
                  attendeeShiftID: x.id,
                  value: evt.target.checked,
                  day: day,
                  shiftLabel: `${DAYS[day]} ${shiftLabel}`,
                  playerName: [
                    x.event_attendee.user.first_name,
                    x.event_attendee.user.last_name,
                  ]
                    .join(' ')
                    .trim(),
                });
                setFulfillments({
                  ...fulfillments,
                  [x.id]: evt.target.checked ? 'fulfilled' : 'unfulfilled',
                });
              }}
            />
            <label className={styles.clickable} htmlFor={x.id}>
              {[
                x.event_attendee.user.first_name,
                x.event_attendee.user.last_name,
              ]
                .join(' ')
                .trim()}
            </label>
          </div>
        </div>
      ));

  const renderShifts = () =>
    renderableShifts.map(day => (
      <div className={styles.shiftDay} key={day}>
        <div className={styles.dayHeader}>{DAYS[day]}</div>
        {renderShift(attendeeShifts[day], day)}
      </div>
    ));

  const renderShift = (shiftDay, day) =>
    Object.values(shiftDay)
      .sort((a, b) => (a.identifier > b.identifier ? 1 : -1))
      .filter(x =>
        eventInfo.isPremiere
          ? x
          : ['cleanup', 'special'].includes(x.kind)
          ? x
          : x.identifier % 100 >= 50,
      )
      .map(shift => (
        <div className={styles.shiftSlot} key={shift.id}>
          <div className={styles.shiftSlotHeader}>
            <div
              className={[
                styles.shiftSlotLabel,
                isEnabled(shift) ? '' : styles.disabled,
              ].join(' ')}
            >
              {shift.label}
            </div>
            <div className={styles.shiftLimit}>
              {shiftLimit(shift, 'limit')}
              {shiftRatio(shift, 'base_ratio')}
            </div>
          </div>
          {renderAttendees(shift.attendee_shifts, shift.id, day, shift.label)}
        </div>
      ));

  const shiftLimit = (shift, kind) => {
    if (!isEnabled(shift)) return;

    return (
      <React.Fragment>
        <span>{` ${shift.attendee_shifts.length} / `}</span>
        {isReadOnly ? (
          shift[kind]
        ) : (
          <InputBase
            className={styles.inputLimit}
            value={shift[kind]}
            onBlur={evt => handleBlur(kind, shift.id, shift.identifier)}
            onFocus={evt => handleFocus(evt)}
            onChange={evt =>
              handleChange(kind, shift.id, shift.identifier, evt.target.value)
            }
            onKeyDown={evt =>
              handleKeyDown(kind, shift.id, shift.identifier, evt.keyCode)
            }
          />
        )}
      </React.Fragment>
    );
  };

  const shiftRatio = (shift, kind) => {
    if (!isEnabled(shift) || isReadOnly || shift.kind === 'special') return;

    return (
      <React.Fragment>
        <span>*</span>
        <InputBase
          className={styles.inputLimit}
          value={shift[kind]}
          onBlur={evt => handleBlur(kind, shift.id, shift.identifier)}
          onFocus={evt => handleFocus(evt)}
          onChange={evt =>
            handleChange(kind, shift.id, shift.identifier, evt.target.value)
          }
          onKeyDown={evt =>
            handleKeyDown(kind, shift.id, shift.identifier, evt.keyCode)
          }
        />
      </React.Fragment>
    );
  };

  const enabledShifts = renderableShifts
    .map(x => attendeeShifts[x])
    .map(daySlot => daySlot.filter(x => x.state === 'enabled'))
    .flat();
  const castingShifts = enabledShifts.filter(x => x.kind === 'casting');
  const cleanupShifts = enabledShifts.filter(x => x.kind === 'cleanup');
  const castingShiftOccupied = castingShifts
    .map(x => x.attendee_shifts.length)
    .reduce((a, b) => a + parseInt(b, 10), 0);
  const castingShiftCapacity = castingShifts
    .map(x => x.limit)
    .reduce((a, b) => a + parseInt(b, 10), 0);
  const castingPercentage = `${
    (castingShiftOccupied / castingShiftCapacity) * 100
  }%`;
  const cleanupShiftOccupied = cleanupShifts
    .map(x => x.attendee_shifts.length)
    .reduce((a, b) => a + parseInt(b, 10), 0);
  const cleanupShiftCapacity = cleanupShifts
    .map(x => x.limit)
    .reduce((a, b) => a + parseInt(b, 10), 0);
  const cleanupPercentage = `${
    (cleanupShiftOccupied / cleanupShiftCapacity) * 100
  }%`;
  const codify = text => <span className={styles.code}>{text}</span>;

  if (renderableShifts.length === 0) return <div>No Shifts Found</div>;
  return (
    <div className={styles.shiftsContainer}>
      <div className={styles.shiftsMetadata}>
        <div className={styles.amount}>
          <div className={styles.text}>
            {`Casting ${castingShiftOccupied}/${castingShiftCapacity}`}
          </div>
          <div
            style={{
              background: `linear-gradient(90deg, #ccc 0%, #ccc ${castingPercentage}, #333 ${castingPercentage})`,
            }}
            className={styles.scale}
          />
        </div>
        <div className={styles.amount}>
          <div className={styles.text}>
            {`Cleanup ${cleanupShiftOccupied}/${cleanupShiftCapacity}`}
          </div>
          <div
            style={{
              background: `linear-gradient(90deg, #ccc 0%, #ccc ${cleanupPercentage}, #333 ${cleanupPercentage})`,
            }}
            className={styles.scale}
          />
        </div>
      </div>
      <div className={styles.fulfillmentToggle}>
        <div className={styles.toggle}>
          <Toggle
            checked={!hideFulfilled}
            onChange={evt => setHideFulfilled(!evt.target.checked)}
          />
        </div>
        <div className={styles.label}>
          {hideFulfilled
            ? 'Currently only showing unfulfilled shifts'
            : 'Currently showing all shifts'}
        </div>
      </div>
      <div className={styles.explanation}>
        Each shift is represented with {codify('X/Y *Z')} values.
        <div>{codify('X')}: Number of Players in this Shift.</div>
        <div>
          {codify('Y')}: Soft Limit of this Shift. Set this value to{' '}
          {codify(-1)} to disable expansion. Leave blank to allow unlimited
          amount of Players.
        </div>
        <div>
          {codify('Z')}: The number {codify('Y')} will grow if expansion
          condition is satisfied. This number will be copied to new Event.
        </div>
      </div>
      <div>{renderShifts()}</div>
    </div>
  );
};
