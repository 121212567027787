import React, { useMemo } from 'react';
import styles from './ItemTransactionNavigator.module.scss';
import history from '../../history';

const PAGES = {
  lockboxes: 'Lockboxes',
  crafting: 'Crafting',
  postal: 'Postal',
  trading: 'Trading',
  audit: 'Audit',
};

export default ({ eventId, currentPage, isAuditCapable }) => {
  const scopedPages = useMemo(() => {
    return Object.keys(PAGES)
      .filter(x =>
        isAuditCapable
          ? x
          : ['crafting', 'postal', 'trading', 'audit'].includes(x),
      )
      .map(x => x);
  }, [isAuditCapable]);

  const renderSection = pageId => (
    <div
      key={pageId}
      className={[
        styles.section,
        pageId === currentPage && styles.selected,
      ].join(' ')}
      onClick={() => history.push(`/admin_transactions/${eventId}/${pageId}`)}
    >
      {PAGES[pageId]}
    </div>
  );

  return (
    <div className={styles.container}>
      {scopedPages.map(x => renderSection(x))}
    </div>
  );
};
