import React, { useEffect, useState } from 'react';
import InputBase from '@material-ui/core/InputBase';
import styles from './CharacterRetirement.module.scss';

export default ({
  remoteStatus,
  characterName,
  retirementBuild,
  futureAttendances,
  passRetire,
  isAdminMode,
}) => {
  const [inputName, setInputName] = useState('');
  const futureEvents = futureAttendances
    ? futureAttendances.map(x => x.event_attendee.event)
    : [];
  const handleRetire = () =>
    inputName === characterName ? passRetire() : null;
  if (remoteStatus === 'retired') return null;
  const renderFutureEvents = () => (
    <div className={styles.futureRender}>
      This character is checked in to the following events:
      <div className={styles.futureEvents}>
        {futureEvents.map(x => (
          <div className={styles.futureEvent} key={x.id}>
            <a href={`/admin_event/${x.id}`} className={styles.text}>
              {x.name}
            </a>
          </div>
        ))}
      </div>
      Please uncheckin them first before retiring this character.
    </div>
  );
  const renderRetirementProcess = () => (
    <React.Fragment>
      <div className={styles.blurb}>
        Retirement process is irreversible. Please re-type the character name
        <span className={styles.retype}>{characterName}</span>
        in the box below to confirm:
      </div>
      <div className={styles.confirmation}>
        <InputBase
          className={styles.input}
          value={inputName}
          onChange={event => setInputName(event.target.value)}
        />
      </div>
      <div className={styles.buttonRow}>
        <button
          type='button'
          onClick={handleRetire}
          className={[
            styles.button,
            inputName === characterName ? '' : styles.disabled,
          ].join(' ')}
        >
          Retire
        </button>
      </div>
    </React.Fragment>
  );

  useEffect(() => {
    setInputName('');
  }, []);

  if (!isAdminMode) return null;

  return (
    <div className={styles.container}>
      <div className={styles.blurb}>
        {`Retiring this character will grant ${
          retirementBuild === null ? '...' : retirementBuild
        } XP to the new character.`}
        <hr />
      </div>
      {futureEvents.length === 0
        ? renderRetirementProcess()
        : renderFutureEvents()}
    </div>
  );
};
