import React from 'react';
import BuildComponents from './BuildComponents';
import Notes from './Notes';
import Caps from './Caps';
import Event from './Event';
import Lores from './Lores';
import Fractures from './Fractures';
import NewLearnings from './NewLearnings';
import Persona from './Persona';
import Shifts from './Shifts';
import Skills from './Skills';
import Stats from './Stats';
import styles from './Character.module.scss';
import structifySkill from './actions/structifySkill';
import structifyStat from './actions/structifyStat';

export default ({
  user,
  shifts,
  attendeeCharacter,
  event,
  isPrintable,
  isBlankSheet,
}) => {
  const { character, ticket, kind, payment, attendance } = attendeeCharacter;
  const eventBaseBuild = event.baseBuild;
  const buildGrowth = attendeeCharacter.build_growth - (eventBaseBuild || 0);
  const isHomeGame = kind === 'home_game' || kind === 'travel_as_home_game';
  const isPaid = payment === 'paid';
  const isAttending = attendance === 'attending';
  const buildAlreadyIncluded =
    (isHomeGame && isPaid) || (!isHomeGame && isAttending);
  const structuredSkill = structifySkill(character);
  const structuredStat = structifyStat(character);

  return (
    <div className={styles.wrapper}>
      <div className={[styles.page, !isPrintable && styles.muted].join(' ')}>
        <Persona
          atcData={attendeeCharacter}
          userData={user}
          eventData={event}
        />
        {isPrintable ? (
          <div className={styles.duo}>
            <div className={[styles.builds, styles.leftSide].join(' ')}>
              <Stats
                structuredStat={structuredStat}
                isBlankSheet={isBlankSheet}
              />
              <div className={styles.bimodal}>
                <div className={styles.miscColumn}>
                  <Lores characterData={character} />
                  <Fractures characterData={character} />
                  <Caps user={user} event={event} />
                  <Notes characterData={character} />
                </div>
                <div className={styles.skillsColumn}>
                  <Skills
                    category='combat'
                    structuredSkill={structuredSkill.combat}
                    isBlankSheet={isBlankSheet}
                  />
                  <Skills
                    category='civilized'
                    structuredSkill={structuredSkill.civilized}
                    isBlankSheet={isBlankSheet}
                  />
                  <Skills
                    category='wasteland'
                    structuredSkill={structuredSkill.wasteland}
                    isBlankSheet={isBlankSheet}
                  />
                  <Skills
                    category='anomaly'
                    structuredSkill={structuredSkill.anomaly}
                    isBlankSheet={isBlankSheet}
                  />
                </div>
              </div>
            </div>
            <div className={[styles.misc, styles.rightSide].join(' ')}>
              <Event
                data={event}
                ticketName={ticket.label}
                ticketCost={ticket.cost}
              />
              <Shifts data={shifts} />
              <BuildComponents
                structuredSkill={structuredSkill}
                structuredStat={structuredStat}
                characterData={character}
                ticketData={ticket}
                buildGrowth={buildGrowth}
                eventBaseBuild={eventBaseBuild}
                buildAlreadyIncluded={buildAlreadyIncluded}
                isHomeGame={isHomeGame}
                isBlankSheet={isBlankSheet}
              />
              <NewLearnings />
            </div>
          </div>
        ) : null}
      </div>
      <div className={styles.break} />
    </div>
  );
};
