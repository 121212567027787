import InputBase from '@material-ui/core/InputBase';
import React from 'react';
import initializeTickets from './actions/initializeTickets';
import styles from './ShiftControls.module.scss';

export default ({ tickets, isPremiere, passTicketUpdate }) => {
  const ticketInfo = initializeTickets();
  const handleChange = (id, field, value) =>
    passTicketUpdate('tickets', id, field, value);
  const maybeRenderDivider = (k, ticket) =>
    ticketInfo[k] &&
    ticketInfo[k].divider && (
      <div key={`${ticket.order}-divider`}>
        <div className={styles.divider}>
          <div className={styles.header}>{ticketInfo[k].divider}</div>
        </div>
        <div className={styles.description}>{ticketInfo[k].description}</div>
      </div>
    );
  const renderTicket = (k, ticket) => (
    <React.Fragment>
      {maybeRenderDivider(k, ticket)}
      <div key={ticket.order} className={styles.row}>
        <div className={styles.price}>
          <InputBase
            value={ticket.cost}
            onChange={event => handleChange(k, 'cost', event.target.value)}
            className={styles.inputText}
          />
        </div>
        <div className={[styles.name, styles.ticket].join(' ')}>
          <div className={styles.forInput}>
            <InputBase
              value={ticket.name}
              onChange={event => handleChange(k, 'name', event.target.value)}
              className={styles.inputText}
            />
          </div>
          <div className={styles.forShift}>
            {ticket.dayPass && '[Day Pass] '}
            {`${ticket.shiftResponsibility} shift${
              ticket.shiftResponsibility === 1 ? '' : 's'
            }`}
          </div>
        </div>
        <div className={styles.limit}>
          <InputBase
            value={ticket.limit || ''}
            onChange={event => handleChange(k, 'limit', event.target.value)}
            className={styles.inputText}
          />
        </div>
      </div>
    </React.Fragment>
  );
  const renderExtraBuild = () => {
    if (!isPremiere) return;
    return (
      <div className={styles.extraBuild}>
        Extra XP options are automatically included
      </div>
    );
  };

  const renderTickets = () =>
    Object.keys(tickets)
      .sort((a, b) => (tickets[a].order < tickets[b].order ? -1 : 1))
      .map(x => renderTicket(x, tickets[x]));

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.day}>Tickets</div>
        <div className={styles.subtitle}>
          Leave Ticket name blank to hide it from Players during checkin
          process.
        </div>
        {renderExtraBuild()}
        {renderTickets()}
      </div>
    </div>
  );
};
