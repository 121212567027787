import React, { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import styles from './SearchBar.module.scss';

export default ({ handleSearchChange }) => {
  const [searchText, setSearchText] = useState('');
  const hasSearchText = searchText.trim().length > 0;
  const updateSearch = text => {
    setSearchText(text);
    handleSearchChange(text.trim());
  };
  const clearSearch = () => updateSearch('');

  return (
    <div className={styles.container}>
      <input
        type='text'
        className={styles.textBox}
        onChange={evt => updateSearch(evt.target.value)}
        placeholder='Search ID or name'
        value={searchText}
      />
      {hasSearchText ? (
        <CloseIcon className={styles.icon} onClick={clearSearch} />
      ) : (
        <SearchIcon className={styles.icon} />
      )}
    </div>
  );
};
