import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import styles from './LineChart.module.scss';

export default ({ data, title }) => (
  <ResponsiveLine
    data={data}
    tooltip={d => (
      <div className={styles.tooltip}>
        {`<= ${d.point.data.x}XP: ${(
          (d.point.data.y * 100) /
          d.point.data.max
        ).toLocaleString('en-US', { maximumFractionDigits: 1 })}%`}
      </div>
    )}
    curve='catmullRom'
    enableArea={true}
    useMesh={true}
    xScale={{ type: 'linear' }}
    yScale={{ type: 'linear' }}
    margin={{
      top: 10,
      right: 10,
      bottom: 48,
      left: 40,
    }}
    axisBottom={{
      legend: title,
      legendPosition: 'middle',
      legendOffset: 36,
      orient: 'bottom',
      tickSize: 5,
    }}
    axisLeft={{
      legend: 'cumulative count',
      legendPosition: 'middle',
      legendOffset: -36,
      tickSize: 0,
    }}
    theme={{
      axis: {
        legend: {
          text: {
            fill: '#ccc',
            fontFamily: 'Bellefair',
            fontSize: '14px',
          },
        },
        ticks: {
          text: {
            fill: '#ccc',
            fontFamily: 'Bellefair',
            fontSize: '14px',
          },
        },
      },
      grid: {
        line: {
          stroke: '#555',
          strokeWidth: 1,
        },
      },
      crosshair: {
        line: {
          stroke: '#ccc',
        },
      },
      tooltip: {
        container: {
          background: '#333',
        },
        tableCell: {
          background: 'red',
        },
        basic: {
          backgroundColor: 'red',
        },
      },
    }}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
    padding={0.3}
  />
);
