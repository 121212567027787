import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { pick, startCase, mapKeys, snakeCase, without } from 'lodash';
import BioData from './BioData';
import Caps from './Caps';
import EventLogs from './EventLogs';
import Membership from './Membership';
import Password from './Password';
import Referrals from './Referrals';
import Lockbox from '../../Events/Administration/Lockbox';
import PlayerItemTransactionAudit from './PlayerItemTransactionAudit';
import CorrectiveActions from '../../Events/Checkin/CorrectiveActions';
import Lock from '@material-ui/icons/Lock';
import styles from './UserEditor.module.scss';
import { isPrivileged as hasPrivilege } from '../../utils/user';

export default ({ user, isUpdating, isError, isSuccessful }) => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState('bio');
  const isPrivileged = hasPrivilege(user);
  const tabMasterList = [
    'bio',
    'inventory',
    'transaction_audit',
    'eventLogs',
    'membership',
    'security',
    'correctives',
    'caps',
    'referrals',
  ];
  const restrictedList = ['correctives'];
  const accountRestrictedTabs = isPrivileged
    ? tabMasterList
    : without(tabMasterList, 'correctives');
  const tabs = accountRestrictedTabs.map(x => (
    <div
      key={x}
      className={[styles.tab, selectedTab === x && styles.selected].join(' ')}
      onClick={() => setSelectedTab(x)}
    >
      {restrictedList.includes(x) && <Lock className={styles.icon} />}
      {startCase(x)}
    </div>
  ));
  const resolveUser = () => {
    if (user.lastEditUserID === user.id) return [user, 'root'];
    if (user.lastEditUserID === user.impersonatee.id)
      return [user.impersonatee, 'impersonatee'];
    return [null, null];
  };
  const [resolvedUser, fragment] = resolveUser();
  const handleUpdate = changes =>
    dispatch({
      type: 'UPDATE_USER',
      payload: {
        ...mapKeys(changes, (_, key) => snakeCase(key)),
        id: resolvedUser.id,
      },
    });
  const handleAddMembership = () =>
    dispatch({
      type: 'ADD_MEMBERSHIP',
      payload: {
        id: resolvedUser.id,
        fragment,
      },
    });
  const handleUpdateMembership = (id, t) =>
    dispatch({
      type: 'UPDATE_MEMBERSHIP',
      payload: {
        userID: resolvedUser.id,
        value: t,
        id,
        fragment,
      },
    });
  const handleRemoveMembership = id =>
    dispatch({
      type: 'REMOVE_MEMBERSHIP',
      payload: {
        userID: resolvedUser.id,
        id,
        fragment,
      },
    });
  const handleUpdatePassword = (oldPassword, newPassword) =>
    dispatch({
      type: 'UPDATE_SELF_PASSWORD',
      payload: {
        oldPassword,
        newPassword,
      },
    });
  const handleSendResetEmail = () =>
    dispatch({
      type: 'UPDATE_OTHER_PASSWORD',
      payload: {
        id: resolvedUser.id,
      },
    });
  const handleAddNewbie = id =>
    dispatch({
      type: 'PLAYER_REFERRAL',
      payload: {
        inviterID: resolvedUser.id,
        newbieID: id,
        action: 'add',
        predicate: 'newbie',
        fragment,
      },
    });
  const handleRemoveNewbie = id =>
    dispatch({
      type: 'PLAYER_REFERRAL',
      payload: {
        inviterID: resolvedUser.id,
        newbieID: id,
        action: 'remove',
        predicate: 'newbie',
        fragment,
      },
    });
  const handleAddInviter = id =>
    dispatch({
      type: 'PLAYER_REFERRAL',
      payload: {
        inviterID: id,
        newbieID: resolvedUser.id,
        action: 'add',
        predicate: 'inviter',
        fragment,
      },
    });
  const handleRemoveInviter = id =>
    dispatch({
      type: 'PLAYER_REFERRAL',
      payload: {
        inviterID: id,
        newbieID: resolvedUser.id,
        action: 'remove',
        predicate: 'inviter',
        fragment,
      },
    });
  const handleBasicSearch = id =>
    dispatch({
      type: 'SIMPLE_USER_SEARCH',
      payload: id,
    });
  const handleUpdateCaps = (id, field, value) =>
    dispatch({
      type: 'UPDATE_CAPS',
      payload: {
        id,
        userID: resolvedUser.id,
        field: field,
        value: value,
        fragment,
      },
    });
  const handleDeleteCaps = (id, action) =>
    dispatch({
      type: 'DELETE_CAPS',
      payload: { userID: resolvedUser.id, id, action, fragment },
    });
  const handleCreateCaps = payload =>
    dispatch({
      type: 'CREATE_CAPS',
      payload: { ...payload, id: resolvedUser.id, fragment },
    });

  const tabContent = () => {
    let sliced;
    if (!user || !user.id) return;

    switch (selectedTab) {
      case 'bio':
        sliced = pick(resolvedUser, [
          'firstName',
          'lastName',
          'emailAddress',
          'branchId',
          'notes',
          'branchTransfers',
          'role',
          'genesisDate',
        ]);
        return (
          <BioData
            user={sliced}
            passUpdate={handleUpdate}
            isUpdating={isUpdating}
            isError={isError}
            fragment={fragment}
            nonceID={resolvedUser.id}
            rootUser={user}
          />
        );
      case 'inventory':
        return (
          <Lockbox
            eventIndependent
            overrideUserId={resolvedUser.id}
            isPrivileged={isPrivileged}
          />
        );
      case 'transaction_audit':
        return (
          <PlayerItemTransactionAudit
            isPrivileged={isPrivileged}
            userId={resolvedUser.id}
          />
        );
      case 'eventLogs':
        sliced = pick(resolvedUser, ['id', 'eventLogs']);
        return (
          <EventLogs
            user={sliced}
            rootUser={user}
            fragment={fragment}
            nonceID={resolvedUser.id}
          />
        );
      case 'referrals':
        sliced = pick(resolvedUser, [
          'id',
          'userReferrals',
          'inviter',
          'simpleUserSearch',
        ]);
        return (
          <Referrals
            user={sliced}
            passAddNewbie={handleAddNewbie}
            passRemoveNewbie={handleRemoveNewbie}
            passAddInviter={handleAddInviter}
            passRemoveInviter={handleRemoveInviter}
            passBasicSearch={handleBasicSearch}
            isUpdating={isUpdating}
            isError={isError}
            fragment={fragment}
            nonceID={resolvedUser.id}
            rootUser={user}
          />
        );
      case 'membership':
        sliced = pick(resolvedUser, [
          'memberships',
          'membershipValidUntil',
          'branchId',
        ]);
        return (
          <Membership
            user={sliced}
            addMembership={handleAddMembership}
            updateMembership={handleUpdateMembership}
            removeMembership={handleRemoveMembership}
            isUpdating={isUpdating}
            isError={isError}
            fragment={fragment}
            nonceID={resolvedUser.id}
            rootUser={user}
          />
        );
      case 'correctives':
        return resolvedUser.correctiveActions.length > 0 ? (
          <CorrectiveActions
            data={resolvedUser.correctiveActions}
            isRenderable={true}
          />
        ) : (
          <div>
            <br />
            No Corrective Actions recorded.
          </div>
        );

      case 'security':
        sliced = pick(resolvedUser, ['emailAddress']);
        return (
          <Password
            user={sliced}
            updatePassword={handleUpdatePassword}
            sendResetEmail={handleSendResetEmail}
            fragment={fragment}
            nonceID={resolvedUser.id}
            isUpdating={isUpdating}
            isError={isError}
            isSuccessful={isSuccessful}
          />
        );
      case 'caps':
        sliced = pick(resolvedUser, ['caps']);

        return (
          <Caps
            user={sliced}
            createCaps={handleCreateCaps}
            updateCaps={handleUpdateCaps}
            deleteCaps={handleDeleteCaps}
            rootUser={user}
          />
        );
      default:
        return `Unimplemented tab ${selectedTab}`;
    }
  };

  if (!resolvedUser) return null;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div
            className={styles.return}
            onClick={() => dispatch({ type: 'HIDE_USER_EDITOR' })}
          >
            &laquo; Return to Character Editor
          </div>
          <div className={styles.playerID}>
            {`Player ID: #${resolvedUser.id}`}
          </div>
        </div>
        <div className={styles.tabbed}>{tabs}</div>
        <div className={styles.tabContent}>{tabContent()}</div>
      </div>
    </div>
  );
};
