export default {
  elitariat: {
    name: 'Elitariat',
    advantage: '',
    stats: {
      body: 8,
      mind: 12,
      resolve: 4,
      infection: 4,
    },
    strains: ['digitarian', 'solestros', 'pureBlood'],
  },
  townie: {
    name: 'Townie',
    advantage: '',
    stats: {
      body: 10,
      mind: 10,
      resolve: 4,
      infection: 4,
    },
    strains: ['baywalker', 'yorker', 'vegasian'],
  },
  nomad: {
    name: 'Nomad',
    advantage: '',
    stats: {
      body: 9,
      mind: 11,
      resolve: 5,
      infection: 3,
    },
    strains: ['dieselJock', 'rover', 'saltwise'],
  },
  gorger: {
    name: 'Gorger',
    advantage: '',
    stats: {
      body: 11,
      mind: 9,
      resolve: 5,
      infection: 3,
    },
    strains: ['fullDead', 'semperMort', 'lascarian'],
  },
  mutant: {
    name: 'Mutant',
    advantage: '',
    stats: {
      body: 12,
      mind: 8,
      resolve: 3,
      infection: 5,
    },
    strains: ['remnant', 'retrograde', 'tainted'],
  },
  landsman: {
    name: 'Landsman',
    advantage: '',
    stats: {
      body: 12,
      mind: 8,
      resolve: 5,
      infection: 3,
    },
    strains: ['merican', 'naturalOne', 'quietFolk'],
  },
  devoted: {
    name: 'Devoted',
    advantage: '',
    stats: {
      body: 10,
      mind: 10,
      resolve: 2,
      infection: 6,
    },
    strains: ['accensorite', 'redStar', 'unborn'],
  },
  evolved: {
    name: 'Evolved',
    advantage: '',
    stats: {
      body: 11,
      mind: 9,
      resolve: 3,
      infection: 5,
    },
    strains: ['iron', 'reclaimer', 'unstable'],
  },
  default: {
    name: null,
    advantage: null,
    stats: {
      body: 0,
      mind: 0,
      resolve: 0,
      infection: 0,
    },
    strains: [],
  },
};
