import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { startCase } from 'lodash';
import CharacterLoader from '../../utils/loader';
import Toggle from 'react-toggle';
import Toast from '../../Shared/Toastify/Toast';
import SingleSelectBranch from '../Branches/SingleSelect';
import WindowedScrollbar from '../../Shared/WindowedScrollbar';
import DatePicker from 'react-datepicker';
import ShiftControls from './ShiftControls';
import TicketControls from './TicketControls';
import ModControls from './ModControls';
import ExternalManagers from './ExternalManagers';
import CreateEventButton from './CreateEventButton';
import ConfigDuplicator from './ConfigDuplicator';
import EventName from './EventName';
import EventLinks from '../Checkin/EventLinks';
import BlockedIcon from '@material-ui/icons/Block';
import styles from './Page.module.scss';
import ErrorPage from '../../Shared/ErrorPages/ErrorPage';
import history from '../../history';
import {
  addDays,
  eachDayOfInterval,
  formatDistance,
  isAfter,
  getDay,
  parseISO,
} from 'date-fns';
import { shiftHours } from '../../utils/time';
import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.scss';
import {
  isPrivileged as hasPrivilege,
  isGuide as hasGuideRole,
} from '../../utils/user';
import {
  isEventRunner as hasEventRunPrivilege,
  isExternalRunner as hasExternalRunPrivilege,
} from '../../utils/events';

export default () => {
  const dispatch = useDispatch();
  const { user, event, eventsRun, currentCharacterID, characterStorage, ui } =
    useSelector(state => ({
      user: state.user,
      event: state.eventSetup,
      eventsRun: state.events.eventsRun,
      currentCharacterID: state.localStorage.currentCharacterID,
      characterStorage: state.localStorage.characterStorage,
      ui: state.localStorage.ui,
    }));
  const tabs = ['tickets', 'shifts', 'mods'];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const eventID = history.location.pathname.match(/(\d+)$/);
  const headerToken = user && user.session && user.session.headers;
  const pastEventDates = event.pastEvents
    ? Object.keys(event.pastEvents).flatMap(x =>
        eachDayOfInterval({
          start: shiftHours(parseISO(event.pastEvents[x].starts_at)),
          end: shiftHours(parseISO(event.pastEvents[x].ends_at)),
        }),
      )
    : [];

  const isPrivileged = hasPrivilege(user);
  const isEventRunner = hasEventRunPrivilege({ event, user });
  const isExternalRunner = hasExternalRunPrivilege({ event, eventsRun, user });
  const isGuide = hasGuideRole(user);
  const isLocalGuide = isGuide && event && user.branchId === event.branchID;
  const isLocalGuideWithManageModPrivilege =
    isLocalGuide && user.flags.includes('mod_manager');
  const windowedScrollbarHeight =
    event.eventID && !isLocalGuideWithManageModPrivilege
      ? 'calc(100vh - 88px)'
      : 'calc(100vh - 48px)';

  const handleCreateEvent = () =>
    dispatch({ type: 'EVENT_SETUP', payload: event });
  const handleConfigDuplication = pastEventID => {
    dispatch({
      type: 'APPLY_EVENT_CONFIGURATION',
      payload: event.pastEvents[pastEventID],
    });
    Toast({
      text: 'Successfully applied Event configuration',
      type: 'success',
    });
  };

  const renderTabHeader = entry => (
    <div
      className={[styles.tab, selectedTab === entry && styles.selected].join(
        ' ',
      )}
      key={entry}
      onClick={() => setSelectedTab(entry)}
    >
      {startCase(entry)}
    </div>
  );
  const renderTabHeaders = (
    <div className={styles.tabs}>{tabs.map(tab => renderTabHeader(tab))}</div>
  );

  const renderTabContent = () => {
    switch (selectedTab) {
      case 'mods':
        return eventID ? (
          <ModControls
            event={event}
            modsUI={ui.mods}
            modSectionsUI={ui.modSections}
          />
        ) : (
          <div className={styles.modBlocked}>
            <BlockedIcon className={styles.icon} />
            <div className={styles.text}>
              Please finalize the Event first
              <br />
              Before setting up Mods
            </div>
          </div>
        );
      case 'tickets':
        return (
          <TicketControls
            tickets={event.tickets}
            isPremiere={event.isPremiere}
            passTicketUpdate={handleEventPropertyUpdate}
          />
        );
      case 'shifts':
        return (
          <ShiftControls
            isEditEvent={eventID !== null}
            startDate={event.startDate}
            endDate={event.endDate}
            shifts={event.shifts}
            passShiftUpdate={handleEventPropertyUpdate}
            passAddCleanupShift={addCleanupShift}
            passRemoveCleanupShift={removeCleanupShift}
          />
        );
    }
  };
  const handleEventNameChange = value => {
    dispatch({
      type: 'UPDATE_EVENT_SETUP',
      payload: {
        field: 'hasModifiedName',
        value: true,
      },
    });

    dispatch({
      type: 'UPDATE_EVENT_SETUP',
      payload: {
        field: 'eventName',
        value: value,
      },
    });
  };

  const updateBranchOrLocation = (field, branchID) =>
    dispatch({
      type: 'UPDATE_EVENT_SETUP',
      payload: {
        field: field,
        value: branchID,
      },
    });

  const updateStartDate = date => {
    dispatch({
      type: 'UPDATE_EVENT_SETUP',
      payload: {
        field: 'startDate',
        value: date,
      },
    });

    if (event.endDate === null || isAfter(date, event.endDate)) {
      dispatch({
        type: 'UPDATE_EVENT_SETUP',
        payload: {
          field: 'endDate',
          value: addDays(date, 7 - getDay(date)),
        },
      });
    }
  };

  const updateEndDate = date =>
    dispatch({
      type: 'UPDATE_EVENT_SETUP',
      payload: {
        field: 'endDate',
        value: date,
      },
    });

  const updateRegistrationDate = date =>
    dispatch({
      type: 'UPDATE_EVENT_SETUP',
      payload: {
        field: 'registrationDate',
        value: date,
      },
    });

  const setPremiereEvent = event =>
    dispatch({
      type: 'UPDATE_EVENT_SETUP',
      payload: {
        field: 'isPremiere',
        value: event.target.checked,
      },
    });

  const registrationDistance = () => {
    if (!event.registrationDate || !event.startDate) {
      return ' If you leave this blank, nobody can checkin.';
    }

    if (event.registrationDate > event.startDate) {
      return ' Registration opens after event starts. Please check your input date!';
    }

    const distance = formatDistance(event.startDate, event.registrationDate);

    return ` Players will have about ${distance} to checkin before the event starts.`;
  };

  const handleEventPropertyUpdate = (category, id, field, value) =>
    dispatch({
      type: 'UPDATE_EVENT_PROPERTY',
      payload: {
        id: id,
        field: field,
        value: value,
        category: category,
      },
    });

  const addCleanupShift = () =>
    dispatch({
      type: 'ADD_CLEANUP_SHIFT',
    });

  const removeCleanupShift = id =>
    dispatch({
      type: 'REMOVE_CLEANUP_SHIFT',
      payload: id,
    });

  useEffect(() => {
    dispatch({ type: 'APP_LOADED' });
  }, [dispatch]);

  useEffect(() => {
    if (!eventID) dispatch({ type: 'RESET_EVENT_SETUP_FORMS' });
  }, [dispatch, eventID]);

  useEffect(() => {
    CharacterLoader({ characterStorage, currentCharacterID, dispatch });
  }, [characterStorage, currentCharacterID, dispatch]);

  useEffect(() => {
    if (ui) dispatch({ type: 'SYNC_UI', payload: ui });
  }, [ui, dispatch]);

  useEffect(() => {
    if (!event.eventID) return;
    history.push(`/update_event/${event.eventID}`);
  }, [event.eventID]);

  useEffect(() => {
    if (!event.selectedBranch || !headerToken) return;
    if (!isEventRunner) return;

    dispatch({
      type: 'FETCH_PAST_EVENT_CONFIGURATIONS',
      payload: event.selectedBranch,
    });
  }, [
    dispatch,
    headerToken,
    event.selectedBranch,
    isExternalRunner,
    isEventRunner,
  ]);

  useEffect(() => {
    if (event.isProcessing || !isPrivileged) return;
    dispatch({ type: 'FETCH_EVENTS_RUN' });
  }, [event.isProcessing, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!user.session) return;
    if (eventID && eventID[1]) {
      dispatch({ type: 'FETCH_EXISTING_EVENT', payload: eventID[1] });
    } else {
      if (user.branchId)
        updateBranchOrLocation('selectedBranch', user.branchId);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname, user.session, dispatch, user.branchId]);

  useEffect(() => {
    if (isLocalGuide) setSelectedTab('mods');
  }, [isLocalGuide]);

  if (!user.session || !user.session.headers)
    return <ErrorPage errorMessage='Reading credentials...' />;
  if (event.remoteError) return <ErrorPage errorMessage={event.remoteError} />;
  if (eventID && !event.eventName)
    return <ErrorPage errorMessage='Fetching Event Data...' />;
  if (!(isPrivileged || isLocalGuideWithManageModPrivilege))
    return <ErrorPage errorType='unauthorized' />;
  if (
    eventID &&
    !isEventRunner &&
    !isExternalRunner &&
    !isLocalGuideWithManageModPrivilege
  )
    return <ErrorPage errorType='mismatchedBranch' />;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {event.eventID ? (
          <React.Fragment>
            <div className={styles.buffer} />
            <EventLinks />
          </React.Fragment>
        ) : null}
      </div>
      <WindowedScrollbar
        styles={styles}
        autoHeightMax={windowedScrollbarHeight}
      >
        <div className={styles.wrapper}>
          {event.eventID ? null : <div className={styles.buffer} />}
          {isLocalGuide ? (
            <div className={styles.modManagerHeader}>
              <div className={styles.title}>Mod Manager</div>
              <div className={styles.name}>{event.eventName}</div>
            </div>
          ) : (
            <React.Fragment>
              <SingleSelectBranch
                user={user}
                selectedBranch={event.selectedBranch}
                branchLocations={event.locations}
                selectedLocation={event.selectedLocation}
                passChange={updateBranchOrLocation}
                isPersisted={event.isPersisted}
                isExternalRunner={isExternalRunner}
              />
              <div className={styles.dateControls}>
                <div className={styles.dateHalf}>
                  <DatePicker
                    placeholderText='Event Start Date'
                    onChange={updateStartDate}
                    selected={shiftHours(event.startDate)}
                    highlightDates={pastEventDates}
                  />
                  <DatePicker
                    placeholderText='Event End Date'
                    onChange={updateEndDate}
                    selected={shiftHours(event.endDate)}
                    highlightDates={pastEventDates}
                  />
                  <div className={styles.divider} />
                  <DatePicker
                    placeholderText='Registration Open Date'
                    onChange={updateRegistrationDate}
                    selected={event.registrationDate}
                    isClearable
                  />
                </div>
                <div className={styles.dateHalf}>
                  <div className={styles.premiereToggle}>
                    <Toggle
                      checked={event.isPremiere || false}
                      onChange={setPremiereEvent}
                    />
                    <div className={styles.text}>
                      {event.isPremiere ? 'Premiere Event' : 'Regular Event'}
                    </div>
                  </div>
                  Registration Open Date indicates when players are able to
                  checkin.
                  {registrationDistance()}
                </div>
              </div>
              <EventName event={event} passChange={handleEventNameChange} />
              <CreateEventButton
                event={event}
                passCreateEvent={handleCreateEvent}
                eventID={eventID}
              />
              <ConfigDuplicator
                isPremiere={event.isPremiere}
                isEventRunner={isEventRunner}
                isExternalRunner={isExternalRunner}
                passDuplicatorChange={handleConfigDuplication}
                pastEvents={event.pastEvents}
                currentEventID={event.eventID}
              />
              <ExternalManagers
                eventID={event.eventID}
                managers={event.externalManagers}
                isExternalRunner={!isEventRunner && isExternalRunner}
              />
            </React.Fragment>
          )}

          <div className={styles.shiftControls}>
            {!isLocalGuide && renderTabHeaders}
            {renderTabContent()}
            {!isLocalGuide && (
              <CreateEventButton
                event={event}
                passCreateEvent={handleCreateEvent}
                eventID={eventID}
              />
            )}
          </div>
        </div>
      </WindowedScrollbar>
    </div>
  );
};
