import React from 'react';
import Toggle from 'react-toggle';
import styles from './ShiftsSelect.module.scss';
import {
  selectedCastingShiftsCount,
  isAdequateCastingShifts,
  isCleanupShiftSelected,
  isSpecialShiftSelected,
} from './actions/shiftCalc';

const DAYS = {
  0: 'Special',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
  8: 'Cleanup',
};

export default ({
  shifts,
  shiftResponsibility,
  updateSelectedShifts,
  selectedTicket,
  isCastingOnly,
  isEventRunner,
}) => {
  const noCastingShiftTicketID = [100, 150];
  const toggleable = shift => {
    if (shift.isAttendeeSelected) return true;
    switch (shift.kind) {
      case 'casting':
      case 'special':
        return !isAdequateCastingShifts(shifts, shiftResponsibility);
      case 'cleanup':
        return !isCleanupShiftSelected(shifts) && selectedTicket !== -1;
      default:
        return true;
    }
  };
  const handleChange = (id, value, kind) =>
    updateSelectedShifts(id, value, kind);
  const renderByAvailability = shift =>
    shift.isFullyBooked ? (
      <div
        className={[styles.row, styles.disabled].join(' ')}
        key={shift.order}
      >
        <div className={styles.toggle}></div>
        <div className={styles.day}>{DAYS[shift.day]}</div>
        <div className={[styles.label, styles.disabled].join(' ')}>
          {shift.label}
        </div>
      </div>
    ) : (
      <div className={styles.row} key={shift.order}>
        <div className={styles.toggle}>
          <Toggle
            checked={shift.isAttendeeSelected}
            onChange={event =>
              handleChange(shift.order, event.target.checked, shift.kind)
            }
            disabled={!toggleable(shift)}
          />
        </div>
        <div className={styles.day}>{DAYS[shift.day]}</div>
        <div className={styles.label}>{shift.label}</div>
      </div>
    );

  const renderCastingShifts = () =>
    Object.keys(shifts)
      .filter(x => shifts[x].isEnabled)
      .filter(x => ['casting', 'special'].includes(shifts[x].kind))
      .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
      .map(x => renderByAvailability(shifts[x]));

  const renderCleanupShifts = () =>
    Object.keys(shifts)
      .filter(x => shifts[x].isEnabled)
      .filter(x => shifts[x].kind === 'cleanup')
      .sort()
      .map(x => renderByAvailability(shifts[x]));

  if (!shifts) return null;
  if (isEventRunner || selectedTicket >= 500) return null;

  return (
    <div className={styles.container}>
      {noCastingShiftTicketID.includes(selectedTicket) ? null : (
        <React.Fragment>
          <div className={styles.header}>
            {selectedTicket === -1
              ? 'Select Ticket First'
              : isCastingOnly
              ? 'Just Come Anytime'
              : isSpecialShiftSelected(shifts)
              ? 'Special Shift selected'
              : `${selectedCastingShiftsCount(
                  shifts,
                )} / ${shiftResponsibility} Casting Shifts selected`}
          </div>
          <div className={styles.shiftList}>{renderCastingShifts()}</div>
        </React.Fragment>
      )}
      <div className={styles.header}>
        {selectedTicket === -1
          ? 'Select Ticket First'
          : isCastingOnly
          ? 'Happy Go Lucky'
          : isCleanupShiftSelected(shifts)
          ? 'Cleanup Shift Selected'
          : 'Select One Cleanup Shift'}
      </div>
      <div className={styles.shiftList}>{renderCleanupShifts()}</div>
    </div>
  );
};
