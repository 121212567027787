import React from 'react';
import styles from './CheckInButton.module.scss';

export default ({
  error,
  isProcessing,
  isResponsibilitiesSatisfied,
  selectedTicket,
  totalCost,
  passExecuteCheckin,
}) => {
  const isClickable = isResponsibilitiesSatisfied && !isProcessing;
  const executeCheckin = () => isClickable && passExecuteCheckin();
  const totalCostArbiter = () => `$${totalCost}`;

  const text = () =>
    error
      ? `${error} - Retry?`
      : isProcessing
      ? 'Checking In...'
      : isResponsibilitiesSatisfied
      ? ['Complete Checkin', totalCostArbiter()].join(' - ')
      : 'Please Select Ticket and Shifts First';

  return (
    <div
      className={[styles.checkin, isClickable ? '' : styles.disabled].join(' ')}
      onClick={executeCheckin}
    >
      {text()}
    </div>
  );
};
