import React from 'react';
import Cell from './Cell/Container';
import { Transition } from 'react-transition-group';
import styles from './Row.module.scss';
import { hasAnyAcquisition } from '../../../../utils/skills';

export default ({
  category,
  sid,
  t1,
  t4,
  innate,
  maxTier,
  levelAcquired,
  achievementIsAcquired,
  remoteLevelAcquired,
  remoteAchievementIsAcquired,
  visible,
  infoExpanded,
  passClick,
  isEditable,
  resolveLocks,
}) => {
  // const isAcquired = tier => {
  //   if (tier === 4) return t4acquired;
  //   return (tier === 1 && innate === true) || acquired >= tier;
  // };
  const anyAcquired = () =>
    hasAnyAcquisition({
      maxTier,
      levelAcquired,
      achievementIsAcquired,
      innate,
    });
  const handleClick = tier => {
    passClick(sid, tier);
  };
  const getName = tier => {
    switch (tier) {
      case 1:
        return t1;
      case 2:
        return 'II';
      case 3:
        return 'III';
      case 4:
        return t4;
      default:
        return null;
    }
  };

  const buildCells = () => {
    const tiers = [...Array(maxTier + 1).keys()];
    const [, ...rest] = tiers;

    return rest.map(tier => (
      <Cell
        key={`${t1}_${tier}`}
        category={category}
        tier={tier}
        name={getName(tier)}
        levelAcquired={levelAcquired}
        achievementIsAcquired={achievementIsAcquired}
        remoteLevelAcquired={remoteLevelAcquired}
        remoteAchievementIsAcquired={remoteAchievementIsAcquired}
        innate={innate}
        visible
        infoExpanded={infoExpanded}
        passClick={handleClick}
        isEditable={isEditable}
        resolveLocked={resolveLocks[tier]}
      />
    ));
  };

  return (
    <React.Fragment>
      <Transition
        in={visible || (!visible && anyAcquired())}
        timeout={300}
        unmountOnExit={false}
      >
        {state => (
          <div className={[styles.row, styles[state]].join(' ')}>
            {buildCells()}
          </div>
        )}
      </Transition>
    </React.Fragment>
  );
};
