import React, { useCallback, useEffect, useState } from 'react';
import Attendee from './Attendee';
import WindowedScrollbar from '../../Shared/WindowedScrollbar';
import EventHeader from '../Checkin/EventHeader';
import EventLinks from '../Checkin/EventLinks';
import TicketAccounting from './TicketAccounting';
import ItemTransactions from './ItemTransactions';
import ErrorPage from '../../Shared/ErrorPages/ErrorPage';
import Shift from './Shift';
import Logs from './Logs';
import TextField from '../../Shared/FormComponents/TextField';
import { useSelector, useDispatch } from 'react-redux';
import history from '../../history';
import styles from './administration.module.scss';
import useInterval from '@use-it/interval';
import Reload from '@material-ui/icons/Sync';
import { groupBy } from 'lodash';
import { formatDistanceToNow, isAfter, parseISO } from 'date-fns';
import { buildBoostCost } from '../Checkin/actions/shiftCalc';
import {
  isPrivileged as hasPrivilege,
  isAdmin as hasAdminPrivilege,
  isGuide as hasGuideRole,
  isGuideWithLogisticsPrivilege as hasGuideLogisticsPrivilege,
} from '../../utils/user';
import {
  isEventRunner as hasEventRunPrivilege,
  isExternalRunner as hasExternalRunPrivilege,
} from '../../utils/events';
import CharacterLoader from '../../utils/loader';

const REFRESH_RATE_MS = 10000;

export default () => {
  const isShiftPage = history.location.pathname.match(/admin_shift/);
  const isLogsPage = history.location.pathname.match(/admin_logs/);
  const isTransactionsPage =
    history.location.pathname.match(/admin_transactions/);
  const urlPlayerID = history.location.search.match(/player_id=(\d+)/);
  const urlPlayerIDMatch = urlPlayerID ? urlPlayerID[1] : null;
  const urlEventID = history.location.pathname.match(/\d+/);
  const urlEventIDMatch = urlEventID ? urlEventID[0] : null;
  const [nameFilter, setNameFilter] = useState('');
  const [hasSuccessfulFetch, setHasSuccessfulFetch] = useState(false);
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [lastRefreshDisplay, setLastRefreshDisplay] = useState('...');
  const dispatch = useDispatch();
  const {
    user,
    userID,
    eventAttendees,
    eventInfo,
    eventsRun,
    isFetching,
    attendeeShifts,
    attendeeFilters,
    currentCharacterID,
    characterStorage,
    ui,
  } = useSelector(state => ({
    user: state.user,
    userID: state.user.id,
    eventAttendees: state.eventAdministration.eventAttendees,
    eventsRun: state.events.eventsRun,
    attendeeShifts: state.eventAdministration.attendeeShifts,
    eventInfo: state.eventAdministration.eventInfo,
    isFetching: state.eventAdministration.isFetching,
    attendeeFilters: state.eventAdministration.filters,
    currentCharacterID: state.localStorage.currentCharacterID,
    characterStorage: state.localStorage.characterStorage,
    ui: state.localStorage.ui,
  }));
  const computeNetworkCost = useCallback((x, isVirtual, isPriceHike) => {
    if (!isPriceHike) return x.ticketCost;
    if (x.ticketIdentifier < 100 || x.ticketIdentifier >= 800)
      return x.ticketCost;
    if (isVirtual) return 55;
    switch (x.ticketIdentifier) {
      case 100:
        return 120; // opt-out
      case 101:
        return 105; // minimum casting
      case 102:
        return 85; // standard castinga
      case 200:
        return 35; // 10-hours
      case 500:
        return 85; // non-attending

      default: {
        const castingHours = x.ticketLabel.match(/ ([\d])/);

        if (castingHours) {
          switch (parseInt(castingHours[1], 10)) {
            case 2:
              return 105;
            case 4:
              return 85;
            case 6:
              return 45;
            default:
              return x.ticketCost;
          }
        }

        return x.ticketCost;
      }
    }
  }, []);
  const [filteredTicketID, setFilteredTicketID] = useState({});
  const registeredTickets = Object.values(eventAttendees);
  const ticketBreakdown = groupBy(
    registeredTickets.map(x => ({
      name: `$${x.ticketCost} ${x.ticketType}`,
      id: x.ticketIdentifier,
      cost: x.ticketCost,
      networkCost: computeNetworkCost(
        x,
        eventInfo.isVirtual,
        isAfter(parseISO(eventInfo.startsAt), new Date(2023, 9, 1)),
      ),
    })),
    'id',
  );

  const isPrivileged = hasPrivilege(user);
  const isAdmin = hasAdminPrivilege(user);
  const isGuide = hasGuideRole(user);
  const isGuideWithLogisticsPrivilege = hasGuideLogisticsPrivilege(user);
  const isLocalGuide =
    isGuide &&
    eventInfo &&
    eventInfo.branch &&
    user.branchId === eventInfo.branch.id;
  const reshapedEventInfo = {
    ...eventInfo,
    eventID: eventInfo.id,
    branchID:
      eventInfo.branchID ||
      (eventInfo && eventInfo.branch && eventInfo.branch.id),
  };
  const maybeNationalEvent =
    eventInfo.baseBuild > 0 ? (
      <div
        className={styles.playerGroup}
      >{`Event has +${eventInfo.baseBuild} base Build`}</div>
    ) : null;
  const isEventRunner = hasEventRunPrivilege({
    event: reshapedEventInfo,
    user,
  });
  const isExternalRunner = hasExternalRunPrivilege({
    event: reshapedEventInfo,
    eventsRun,
    user,
  });

  const cancelAttendee = attendeeID => {
    if (window.confirm("Cancel this player's checkin? This is irreversible!")) {
      dispatch({
        type: 'CANCEL_ATTENDEE',
        payload: {
          attendeeID: attendeeID,
        },
      });
    }
  };

  const handleBuildGrowthChange = (attendeeID, value) =>
    dispatch({
      type: 'UPDATE_ATTENDEE_BUILD_GROWTH',
      payload: {
        attendeeID: attendeeID,
        value: value + (eventInfo.baseBuild || 0),
      },
    });
  const handleAttendeePaid = (attendeeID, isPaid) =>
    dispatch({
      type: 'UPDATE_ATTENDEE',
      payload: {
        attendeeID: attendeeID,
        changes: { isPaid: isPaid },
      },
    });
  const handleAttendeeAttending = (attendeeID, isAttending) =>
    dispatch({
      type: 'UPDATE_ATTENDEE',
      payload: {
        attendeeID: attendeeID,
        changes: { isAttending: isAttending },
      },
    });
  const handleShiftAdjustment = (kind, id, identifier, value, updateUpstream) =>
    dispatch({
      type: 'ADJUST_SHIFT',
      payload: {
        eventID: eventInfo.id,
        shiftID: id,
        kind,
        identifier,
        value,
        updateUpstream,
      },
    });
  const handleTicketUpgrade = (eventAttendeeID, ticketIdentifier) =>
    dispatch({
      type: 'UPGRADE_TICKET',
      payload: {
        eventAttendeeID,
        ticketIdentifier,
      },
    });
  const updateShiftFulfillment = ({
    eventAttendeeID,
    attendeeShiftID,
    value,
    day,
    shiftLabel,
    playerName,
  }) =>
    dispatch({
      type: 'UPDATE_SHIFT_FULFILLMENT',
      payload: {
        eventAttendeeID,
        attendeeShiftID,
        value,
        day,
        shiftLabel,
        playerName,
      },
    });

  const cancelAttendeeCharacter = (attendeeID, attendeeCharacterID) => {
    if (
      window.confirm('Cancel this character checkin? This is irreversible!')
    ) {
      dispatch({
        type: 'CANCEL_ATTENDEE_CHARACTER',
        payload: {
          attendeeID: attendeeID,
          attendeeCharacterID: attendeeCharacterID,
        },
      });
    }
  };

  const fullTextFilter = x => {
    const trimmed = nameFilter.trim();
    return trimmed.length === 0
      ? x
      : eventAttendees[x].playerID.toString().includes(trimmed) ||
          eventAttendees[x].name.toLowerCase().includes(trimmed) ||
          Object.values(eventAttendees[x].characters).some(y =>
            y.name.toLowerCase().includes(trimmed),
          );
  };

  const handleAttendeeCharacterPaid = (
    attendeeID,
    attendeeCharacterID,
    isPaid,
  ) =>
    dispatch({
      type: 'UPDATE_ATTENDEE_CHARACTER',
      payload: {
        attendeeID: attendeeID,
        attendeeCharacterID: attendeeCharacterID,
        changes: { isPaid: isPaid },
      },
    });
  const handleAttendeeCharacterAttending = (
    attendeeID,
    attendeeCharacterID,
    isAttending,
  ) =>
    dispatch({
      type: 'UPDATE_ATTENDEE_CHARACTER',
      payload: {
        attendeeID: attendeeID,
        attendeeCharacterID: attendeeCharacterID,
        changes: { isAttending: isAttending },
      },
    });

  //Object.keys(filteredTicketID)
  // .filter(x =>
  // !Object.keys(filteredTicketID).filter(y => filteredTicketID[y])
  //   .includes(eventAttendees[x].ticketIdentifier.toString())
  const filterAttendee = attendee =>
    !Object.keys(filteredTicketID)
      .filter(y => filteredTicketID[y])
      .includes(attendee.ticketIdentifier.toString());

  const renderLocalPlayers = () =>
    renderPlayers(
      Object.keys(eventAttendees)
        .filter(x => fullTextFilter(x))
        .filter(x => !eventAttendees[x].isTraveler)
        .filter(x => filterAttendee(eventAttendees[x])),
    );

  const renderTravelers = () =>
    renderPlayers(
      Object.keys(eventAttendees)
        .filter(x => fullTextFilter(x))
        .filter(x => eventAttendees[x].isTraveler)
        .filter(x => filterAttendee(eventAttendees[x])),
    );

  const renderPlayers = (players, section) => {
    if (!hasSuccessfulFetch && isFetching) return 'Fetching data...';
    if (players.length === 0) return 'No players have checked-in';
    return players
      .sort((a, b) => eventAttendees[a].playerID - eventAttendees[b].playerID)
      .filter(id =>
        attendeeFilters.guidesOnly
          ? eventAttendees[id].ticketIdentifier >= 700
          : id,
      )
      .map(id => (
        <Attendee
          key={id}
          name={eventAttendees[id].name}
          playerID={eventAttendees[id].playerID}
          role={eventAttendees[id].role}
          isAdvancedMember={eventAttendees[id].isAdvancedMember}
          ticketType={eventAttendees[id].ticketType}
          ticketCost={eventAttendees[id].ticketCost}
          ticketIdentifier={eventAttendees[id].ticketIdentifier}
          buildGrowth={
            eventAttendees[id].buildGrowth - (eventInfo.baseBuild || 0)
          }
          eventBaseBuild={eventInfo.baseBuild || 0}
          allTickets={eventInfo.tickets}
          isPaid={eventAttendees[id].isPaid}
          isAttending={eventAttendees[id].isAttending}
          isTraveler={eventAttendees[id].isTraveler}
          isPremiereEvent={eventInfo.isPremiere}
          isNationalEvent={eventInfo.isNational}
          isVirtualEvent={eventInfo.isVirtual}
          eventID={eventInfo.id}
          homeBranch={section === 'traveler' ? players[id].homeBranch : null}
          attendeeID={eventAttendees[id].attendeeID}
          inviter={eventAttendees[id].inviter}
          characters={eventAttendees[id].characters}
          passCancelAttendee={cancelAttendee}
          passAttendeePaid={handleAttendeePaid}
          passAttendeeAttending={handleAttendeeAttending}
          passCancelAttendeeCharacter={cancelAttendeeCharacter}
          passAttendeeCharacterPaid={handleAttendeeCharacterPaid}
          passAttendeeCharacterAttending={handleAttendeeCharacterAttending}
          passBuildGrowthChange={handleBuildGrowthChange}
          passTicketUpgrade={handleTicketUpgrade}
          eventConfig={eventInfo.config}
        />
      ));
  };

  const renderRawData = () =>
    Object.values(eventAttendees)
      .sort((a, b) => a.playerID - b.playerID)
      .map(x => {
        const extraBuildCost = buildBoostCost({
          buildBoost: x.buildGrowth,
          isNational: eventInfo.isNational,
          isPremiere: eventInfo.isPremiere,
          isVirtual: eventInfo.isVirtual,
          eventConfig: eventInfo.config,
          isEventRunner: false,
        });

        return (
          <div key={x.playerID}>
            {[
              x.playerID,
              x.name,
              x.role,
              x.isTraveler ? 'Traveler' : 'Local',
              x.isAdvancedMember ? 'AM' : 'Basic',
              x.ticketType,
              x.ticketCost,
              x.buildGrowth,
              extraBuildCost,
              x.ticketCost + extraBuildCost,
              x.isPaid ? 'Paid' : 'Not Paid',
              x.isAttending ? 'Attending' : 'Absent',
            ].join(', ')}
          </div>
        );
      });

  const renderEventInfo = () => {
    if (!eventInfo) return 'Fetching Event data...';
    if (eventInfo.error) return eventInfo.error;
    if (!eventInfo.name) return 'Fetching Event data...';

    if (!hasSuccessfulFetch) setHasSuccessfulFetch(true);

    return (
      <EventHeader
        eventName={eventInfo.name}
        eventLocation={eventInfo.location.name}
        eventStartDate={eventInfo.startsAt}
        eventEndDate={eventInfo.endsAt}
        eventBranch={eventInfo.branch}
        isPremiere={eventInfo.isPremiere}
        nextEvent={eventInfo.nextEvent}
        prevEvent={eventInfo.prevEvent}
        isPrivileged={isPrivileged}
      />
    );
  };

  const fetchEventAttendees = () => {
    if (!userID) return;
    dispatch({
      type: isShiftPage
        ? 'FETCH_EVENT_ATTENDEE_SHIFTS'
        : isLogsPage
        ? 'FETCH_EVENT_ATTENDEE_LOGS'
        : 'FETCH_EVENT_ATTENDEES',
      payload: {
        // eventID: [...history.location.pathname.split(/\//)].pop(),
        eventID: urlEventID,
      },
    });
  };

  const renderLastRefresh = () =>
    isFetching
      ? 'Updating data...'
      : `Updated ${formatDistanceToNow(lastRefresh, { addSuffix: true })}`;

  const updateLastRefreshInfo = updateLastRefresh => {
    if (updateLastRefresh) {
      setLastRefresh(new Date());
      setLastRefreshDisplay('Fetching data...');
    } else {
      setLastRefreshDisplay(renderLastRefresh());
    }
  };

  const arbitratedShiftPage = (
    <React.Fragment>
      <div className={styles.playerGroup}>
        <Reload
          fontSize='small'
          className={styles.reloadIcon}
          onClick={() => {
            updateLastRefreshInfo(true);
            fetchEventAttendees();
          }}
        />
        {lastRefreshDisplay}
      </div>
      <TextField
        title='Filter by ID/Name'
        revert='clear'
        initialValue=''
        value={nameFilter}
        passChange={(_, value) => setNameFilter(value.toLowerCase())}
      />
      <div className={styles.playerGroup}>
        <Shift
          attendeeShifts={attendeeShifts}
          updateShiftFulfillment={updateShiftFulfillment}
          eventInfo={eventInfo}
          passChange={handleShiftAdjustment}
          nameFilter={nameFilter.trim().length > 0 && nameFilter.trim()}
          isReadOnly={!(isEventRunner || isExternalRunner)}
          isPrivileged={isPrivileged}
        />
      </div>
    </React.Fragment>
  );

  const arbitratedLogsPage = (
    <Logs eventID={eventInfo.id} eventAttendees={eventAttendees} />
  );

  const arbitratedTransactionsPage = (
    <ItemTransactions
      isAuditCapable={isEventRunner || isExternalRunner}
      isPostie={isGuideWithLogisticsPrivilege}
      eventId={urlEventIDMatch}
    />
  );

  const arbitratedAttendeePage = (
    <React.Fragment>
      <TicketAccounting
        eventAttendees={eventAttendees}
        eventInfo={eventInfo}
        filteredTicketID={filteredTicketID}
        setFilteredTicketID={setFilteredTicketID}
        ticketBreakdown={ticketBreakdown}
        registeredTickets={registeredTickets}
        isPremiere={eventInfo.isPremiere}
        isNational={eventInfo.isNational}
        isVirtual={eventInfo.isVirtual}
        eventConfig={eventInfo.config}
      />
      <div className={styles.playerGroup}>
        <Reload
          fontSize='small'
          className={styles.reloadIcon}
          onClick={() => {
            updateLastRefreshInfo(true);
            fetchEventAttendees();
          }}
        />
        {lastRefreshDisplay}
      </div>
      {maybeNationalEvent}
      <TextField
        title='Filter by ID/Name'
        revert='clear'
        initialValue=''
        value={nameFilter}
        passChange={(_, value) => setNameFilter(value.toLowerCase())}
      />
      <div className={styles.playerGroup}>
        Local Players
        <div className={[styles.header, styles.local].join(' ')}>
          <div className={[styles.cell, styles.playerID].join(' ')}>ID</div>
          <div className={[styles.cell, styles.playerName].join(' ')}>Name</div>
          <div className={[styles.cell, styles.paid].join(' ')}>Paid?</div>
        </div>
        <WindowedScrollbar styles={styles} autoHeightMax='calc(67vh)'>
          <div className={styles.content}>{renderLocalPlayers()}</div>
        </WindowedScrollbar>
      </div>
      <div className={styles.playerGroup}>
        Travelers
        <div className={[styles.header, styles.traveling].join(' ')}>
          <div className={[styles.cell, styles.playerID].join(' ')}>ID</div>
          <div className={[styles.cell, styles.playerName].join(' ')}>Name</div>
          <div className={[styles.cell, styles.paid].join(' ')}>Paid?</div>
          <div className={[styles.cell, styles.paid].join(' ')}>Attd?</div>
        </div>
        <WindowedScrollbar styles={styles} autoHeightMax='calc(67vh)'>
          <div className={styles.content}>{renderTravelers()}</div>
        </WindowedScrollbar>
      </div>
      <div className={styles.playerGroup}>
        Raw Data
        <div className={styles.subtitle}>
          Click anywhere at the raw data below to copy. You can then paste the
          raw data to external spreadsheet (e.g. Google Spreadsheet) for
          advanced post-processing.
        </div>
        <WindowedScrollbar styles={styles} autoHeightMax='calc(67vh)'>
          <div className={styles.rawData}>
            <div>
              {[
                'ID',
                'Name',
                'Role',
                'Residency',
                'Membership',
                'Ticket',
                'Cost ($)',
                '+XP',
                '+XP ($)',
                'Total Cost ($)',
                'Paid',
                'Attending',
              ].join(', ')}
            </div>
            {renderRawData()}
          </div>
        </WindowedScrollbar>
      </div>
    </React.Fragment>
  );

  const adminArbiter = () => {
    if (isShiftPage) return arbitratedShiftPage;
    if (isLogsPage) return arbitratedLogsPage;
    if (isTransactionsPage) return arbitratedTransactionsPage;
    return arbitratedAttendeePage;
  };

  useEffect(() => {
    dispatch({ type: 'APP_LOADED' });
  }, [dispatch]);

  useEffect(() => {
    CharacterLoader({ characterStorage, currentCharacterID, dispatch });
  }, [characterStorage, currentCharacterID, dispatch]);

  useEffect(() => {
    if (ui) dispatch({ type: 'SYNC_UI', payload: ui });
  }, [ui, dispatch]);

  useEffect(() => {
    fetchEventAttendees();
    updateLastRefreshInfo(true); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID, urlEventIDMatch]);

  useEffect(() => {
    updateLastRefreshInfo(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, urlEventIDMatch]);

  useInterval(() => {
    updateLastRefreshInfo();
  }, REFRESH_RATE_MS);

  useEffect(() => {
    if (urlPlayerIDMatch) setNameFilter(urlPlayerIDMatch);
  }, [urlPlayerIDMatch]);

  if (!user.session || !user.session.headers)
    return <ErrorPage errorMessage='Reading credentials...' />;
  if (eventInfo && eventInfo.error)
    return <ErrorPage errorMessage={eventInfo.error} />;
  if (!eventInfo || !eventInfo.name)
    return <ErrorPage errorMessage='Fetching Event Data...' />;

  if (isShiftPage) {
    if (eventInfo.isNational) {
      if (!(isGuide || isPrivileged))
        return <ErrorPage errorType='unauthorized' />;
    } else {
      if (!(isLocalGuide || isEventRunner || isExternalRunner))
        return <ErrorPage errorType='unauthorized' />;
    }
  } else if (isTransactionsPage) {
    if (!(isGuide || isPrivileged))
      return <ErrorPage errorType='unauthorized' />;
  } else {
    if (!(isEventRunner || isExternalRunner))
      return <ErrorPage errorType='mismatchedBranch' />;
  }

  return (
    <WindowedScrollbar styles={styles} autoHeightMax='calc(100vh)'>
      <div className={styles.container}>
        <div className={styles.eventInfo}>{renderEventInfo()}</div>
        {isEventRunner && <EventLinks />}
        {adminArbiter()}
      </div>
    </WindowedScrollbar>
  );
};
