import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { groupBy } from 'lodash';
import { compareDesc, format, getYear, getMonth, parseISO } from 'date-fns';
import WindowedScrollbar from '../Shared/WindowedScrollbar';
import styles from './BranchMemberships.module.scss';

const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export default ({ user, data, branchID, isFetching }) => {
  const dispatch = useDispatch();
  const memberships = data.memberships || [];
  const branchFilteredData = memberships.filter(
    x => x.granting_branch.id === branchID,
  );
  const yearGrouped = groupBy(branchFilteredData, x =>
    getYear(parseISO(x.created_at)),
  );

  const renderMonthlyData = monthlyData =>
    monthlyData
      .sort((a, b) =>
        compareDesc(parseISO(a.created_at), parseISO(b.created_at)),
      )
      .map(x => (
        <div className={styles.dataRow} key={x.id}>
          <div className={styles.grantorID}>
            {x.grantor ? x.grantor.id : '-'}
          </div>
          <div className={styles.grantorName}>
            {x.grantor
              ? [x.grantor.first_name, x.grantor.last_name].join(' ').trim()
              : 'System'}
          </div>
          <div className={styles.userID}>{x.user.id}</div>
          <div className={styles.userName}>
            {[x.user.first_name, x.user.last_name].join(' ').trim()}
          </div>
          <div className={styles.dateExecuted}>
            {format(parseISO(x.created_at), 'M/dd')}
          </div>
          <div className={styles.dateActivated}>
            {format(parseISO(x.starts_at), 'yyyy/MM/dd')}
          </div>
        </div>
      ));

  const renderAnnualData = annualData => {
    const monthlyData = groupBy(annualData, x =>
      getMonth(parseISO(x.created_at)),
    );

    return Object.keys(monthlyData)
      .sort((a, b) => b - a)
      .map(x => (
        <div className={styles.monthContainer} key={`month-${x}`}>
          <div className={styles.monthHeader}>
            <div className={styles.monthName}>{MONTH_NAMES[x]}</div>
            <div className={styles.playerName}>Players</div>
            <div className={styles.dateExecuted}>Exec</div>
            <div className={styles.dateActivated}>From</div>
          </div>
          <div className={styles.monthContent}>
            {renderMonthlyData(monthlyData[x])}
          </div>
          <div className={styles.monthlyFooter}>
            <div className={styles.text}>{`${MONTH_NAMES[x]} purchases`}</div>
            <div className={styles.numeric}>{monthlyData[x].length}</div>
          </div>
        </div>
      ));
  };

  const content = Object.keys(yearGrouped)
    .sort((a, b) => b - a)
    .map(x => (
      <div className={styles.yearContainer} key={`year-${x}`}>
        <div className={styles.yearHeader}>
          <div className={styles.text}>{x}</div>
          <div className={styles.numeric}>
            {`Total membership purchases this year: ${yearGrouped[x].length}`}
          </div>
        </div>
        <div className={styles.yearContent}>
          {renderAnnualData(yearGrouped[x])}
        </div>
      </div>
    ));

  useEffect(() => {
    if (!user) return;
    if (!user.session) return;

    dispatch({ type: 'FETCH_BRANCH_MEMBERSHIPS' });
  }, [user, dispatch]);

  return (
    <div className={styles.container}>
      <WindowedScrollbar styles={styles} autoHeightMax='calc(100vh - 64px)'>
        <div className={styles.content}>
          {isFetching
            ? 'Fetching data...'
            : branchFilteredData.length === 0
            ? 'No recorded Membership purchases'
            : content}
        </div>
      </WindowedScrollbar>
    </div>
  );
};
