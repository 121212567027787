import remoteStrains from '../../gameData/remoteStrains';
import strains from '../../gameData/strains';
import { zip } from 'lodash';

const deciDiv = x => [
  x >= 20 ? 20 : x,
  x >= 30 ? 10 : x <= 20 ? null : x % 20,
  x >= 40 ? 10 : x <= 30 ? null : x % 30,
  x >= 50 ? 10 : x <= 40 ? null : x % 40,
  x >= 60 ? 10 : x <= 50 ? null : x % 50,
  x <= 60 ? null : x % 60,
];

const deciMultiplier = [1, 3, 5, 7, 9, 10];

export default characterData => {
  const strainKey = remoteStrains[characterData.strain_id];
  const strainStats = strains[strainKey].lineage.stats;
  const { body, mind, resolve, infection, deaths } = characterData;
  const deciBody = deciDiv(body || 0);
  const deciMind = deciDiv(mind || 0);
  const totalBodyBuild = zip(deciBody, deciMultiplier)
    .map(x => x[0] * x[1])
    .reduce((a, b) => a + b, 0);
  const totalMindBuild = zip(deciMind, deciMultiplier)
    .map(x => x[0] * x[1])
    .reduce((a, b) => a + b, 0);
  const resolveBuild = (resolve || 0) * 10;
  const infectionBuild = (infection || 0) * 10;
  const statBuildTotal =
    totalBodyBuild + totalMindBuild + resolveBuild + infectionBuild;

  return {
    strainStats,
    deciBody,
    deciMind,
    body: body,
    mind: mind,
    resolve: resolve,
    infection: infection,
    deaths: deaths,
    totalBodyBuild,
    totalMindBuild,
    resolveBuild,
    infectionBuild,
    statBuildTotal,
  };
};
