import React from 'react';
import {
  addDays,
  compareAsc,
  format,
  isPast,
  isWithinInterval,
  parseISO,
} from 'date-fns';
import {
  isAdmin as hasAdminPrivilege,
  isPrivileged as hasPrivilege,
  ownsBranch,
  membershipValidityPeriod,
} from '../../utils/user';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './Membership.module.scss';

export default ({
  user,
  fragment,
  nonceID,
  rootUser,
  addMembership,
  updateMembership,
  removeMembership,
  isUpdating,
  isError,
}) => {
  const isAdmin = hasAdminPrivilege(rootUser);
  const isBranchOwner =
    hasPrivilege(rootUser) && ownsBranch(rootUser, user.branchId);
  const isAdminOrBranchOwner = isAdmin || isBranchOwner;

  const handleRemove = m => {
    const start = format(parseISO(m.starts_at), 'yyyy/MM');
    const end = format(parseISO(m.ends_at), 'yyyy/MM');

    const msg = `You are removing membership from ${start} to ${end}. Please click OK to confirm`;
    window.confirm(msg) && removeMembership(m.id);
  };
  const renderMembership = membership => (
    <div
      key={membership.id}
      className={[
        styles.row,
        isPast(parseISO(membership.ends_at)) ? styles.expired : '',
      ].join(' ')}
    >
      <div className={styles.marker}>
        {isWithinInterval(new Date(), {
          start: addDays(parseISO(membership.starts_at), -1),
          end: addDays(parseISO(membership.ends_at), 1),
        })
          ? '»'
          : ''}
      </div>
      <div className={styles.date}>
        <div className={styles.picker}>
          {isAdminOrBranchOwner ? (
            <DatePicker
              dateFormat='yyyy/MM/dd'
              selected={parseISO(membership.starts_at)}
              onChange={val => updateMembership(membership.id, val)}
            />
          ) : (
            <div className={styles.unpickable}>
              {format(parseISO(membership.starts_at), 'yyyy/MM/dd')}
            </div>
          )}
        </div>
        <div className={styles.unpickable}>
          {` - `}
          {format(parseISO(membership.ends_at), 'yyyy/MM/dd')}
        </div>
      </div>
      {isAdminOrBranchOwner && (
        <div className={styles.remove} onClick={() => handleRemove(membership)}>
          Remove
        </div>
      )}
    </div>
  );

  const membershipExtension = () => {
    if (!isAdminOrBranchOwner) return null;

    const noMembership = () => (
      <div className={styles.membership}>
        {`This Player does not have Advanced Membership. `}
        <div className={styles.link} onClick={addMembership}>
          Add Advanced Membership
        </div>
      </div>
    );

    const validity = membershipValidityPeriod(user);

    const existingMembership = () => (
      <div className={styles.membership} onClick={addMembership}>
        {`Advanced Membership valid until ${validity.calendarDate} (expires ${validity.humanDate}). `}
        <div className={styles.link}>Extend membership for another year</div>
      </div>
    );

    const membershipUpdating = () => (
      <div className={styles.membership} onClick={addMembership}>
        Updating Membership...
      </div>
    );

    if (isUpdating) return membershipUpdating();
    return validity ? existingMembership() : noMembership();
  };

  return (
    <div className={styles.container}>
      {isUpdating ? (
        'Updating...'
      ) : (
        <React.Fragment>
          <div className={styles.recent}>{membershipExtension()}</div>
          <div className={styles.membershipHistory}>
            <div className={styles.title}>Membership History</div>
            <div className={styles.content}>
              {user && user.memberships && user.memberships.length > 0
                ? user.memberships
                    .sort((a, b) =>
                      compareAsc(parseISO(b.starts_at), parseISO(a.starts_at)),
                    )
                    .map(x => renderMembership(x))
                : 'No membership history'}
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
