export const initialState = {};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'SYNC_UI':
      return {
        ...state,
        ...payload.modSections,
      };
    default:
      return state;
  }
};
