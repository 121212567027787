export default () => ({
  0: {
    name: 'New Player',
    cost: 35,
    shiftResponsibility: 2,
    limit: 500,
    order: 0,
    divider: 'New Player Ticket',
    description:
      'Only new players can use this ticket. Referral ID is optional.',
  },
  1: {
    name: 'New Player via Guide Referral',
    cost: 0,
    shiftResponsibility: 2,
    limit: 500,
    order: 1,
    divider: 'New Player via Guide Referral',
    description:
      "Only new players can use this ticket. Player is required to enter Guide's referral ID.",
  },
  100: {
    name: 'No Casting',
    cost: 100,
    shiftResponsibility: 0,
    limit: 5,
    order: 2,
    divider: 'Attendance Ticket',
  },
  101: {
    name: 'Minimum Casting',
    cost: 85,
    shiftResponsibility: 1,
    limit: 25,
    order: 3,
  },
  102: {
    name: 'Standard Casting',
    cost: 65,
    shiftResponsibility: 2,
    limit: 500,
    order: 4,
  },
  103: {
    name: 'Maximum Casting',
    cost: 45,
    shiftResponsibility: 3,
    limit: 500,
    order: 5,
  },
  104: {
    name: 'Casting Only',
    cost: 0,
    shiftResponsibility: 0,
    limit: 500,
    castingOnly: true,
    order: 6,
  },
  200: {
    name: '10-Hour Play',
    cost: 35,
    shiftResponsibility: 1,
    limit: 500,
    dayPass: true,
    order: 7,
  },
  500: {
    name: 'Non-Attending',
    cost: 65,
    shiftResponsibility: 0,
    limit: 500,
    order: 8,
    divider: 'Non-Attendance (Local Players only)',
  },
  700: {
    name: '[GUIDES ONLY]',
    cost: 0,
    shiftResponsibility: 0,
    limit: 5,
    order: 9,
    divider: 'Guides-only Ticket',
    description:
      'Only visible to Guides. Guides do not select their own shifts',
  },
  701: {
    name: '[GUIDES ONLY]',
    cost: 85,
    shiftResponsibility: 0,
    limit: 5,
    order: 10,
  },
  702: {
    name: '[GUIDES ONLY]',
    cost: 65,
    shiftResponsibility: 0,
    limit: 50,
    order: 11,
  },
  703: {
    name: '[GUIDES ONLY]',
    cost: 45,
    shiftResponsibility: 0,
    limit: 50,
    order: 12,
  },
  800: {
    name: 'Branch Owner',
    cost: 0,
    shiftResponsibility: 0,
    limit: 10,
    order: 13,
    divider: 'Owners-only Ticket',
    description: 'Only visible Branch Owners.',
  },
});
