import React from 'react';
import uuid from 'uuid';
import styles from './Character.module.scss';

export default ({ characterData }) => (
  <div className={[styles.freeForm, styles.diseases].join(' ')}>
    <div className={styles.content}>
      <div className={styles.noteSection}>
        <div className={styles.lead}>Notes: </div>
        {(characterData.player_notes || '').split(/\s*\|\|\s*/).map(x => (
          <div className={styles.subSection} key={uuid.v1()}>
            {x}
          </div>
        ))}
      </div>
    </div>
  </div>
);
