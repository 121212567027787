import React, { useEffect, useState } from 'react';
import TextField from '../../Shared/FormComponents/TextField';
import styles from './BioData.module.scss';

export default ({
  user,
  updatePassword,
  sendResetEmail,
  fragment,
  nonceID,
  isUpdating,
  isError,
  isSuccessful,
  isPrivileged,
}) => {
  const [latchedUser, setLatchedUser] = useState({});
  const [passwordMatched, setPasswordMatched] = useState(null);

  const isEmpty = x => (x ? x.length === 0 : true);
  const notEmpty = x => !isEmpty(x);
  const newPasswordMatch =
    isEmpty(latchedUser.newPassword) || isEmpty(latchedUser.newPasswordConfirm)
      ? true
      : latchedUser.newPassword === latchedUser.newPasswordConfirm;

  const dirty =
    notEmpty(latchedUser.oldPassword) ||
    notEmpty(latchedUser.newPassword) ||
    notEmpty(latchedUser.newPasswordConfirm);
  const valid =
    notEmpty(latchedUser.oldPassword) &&
    notEmpty(latchedUser.newPassword) &&
    notEmpty(latchedUser.newPasswordConfirm) &&
    latchedUser.newPassword === latchedUser.newPasswordConfirm;

  const handleBlur = () => setPasswordMatched(newPasswordMatch);
  const handleUpdate = () =>
    passwordMatched &&
    updatePassword(latchedUser.oldPassword, latchedUser.newPassword);
  const handleChange = (field, value) => {
    setLatchedUser({
      ...latchedUser,
      [field]: field === 'branchId' ? parseInt(value, 10) : value,
    });

    if (
      notEmpty(latchedUser.newPassword) &&
      notEmpty(latchedUser.newPasswordConfirm) &&
      (field === 'newPassword' ? value : latchedUser.newPassword) ===
        (field === 'newPasswordConfirm'
          ? value
          : latchedUser.newPasswordConfirm)
    ) {
      setPasswordMatched(true);
    }
  };
  const buttonText = () => {
    if (isError) return 'Error. Retry?';
    if (isUpdating) return 'Updating Password...';
    return 'Update Password';
  };

  useEffect(() => {
    if (isUpdating || isError) return;
    setLatchedUser({
      ...user,
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    });
  }, [nonceID, fragment, isUpdating, isError]); // eslint-disable-line react-hooks/exhaustive-deps

  const emailAddress = (
    <a
      className={styles.emailAddress}
      href={`mailto:${user.emailAddress}`}
      target='_email'
    >
      {user.emailAddress}
    </a>
  );
  const selfReset = (
    <div className={styles.selfSecurity}>
      <TextField
        title='Old Password'
        fieldKey='oldPassword'
        type='password'
        value={latchedUser.oldPassword || ''}
        initialValue=''
        passChange={handleChange}
      />
      <hr className={styles.hr} />
      <TextField
        title='New Password'
        fieldKey='newPassword'
        type='password'
        value={latchedUser.newPassword || ''}
        initialValue=''
        passChange={handleChange}
        passBlur={handleBlur}
      />
      <TextField
        title='Confirm Password'
        fieldKey='newPasswordConfirm'
        placeholder='Type New Password again to Confirm'
        type='password'
        value={latchedUser.newPasswordConfirm || ''}
        initialValue=''
        passChange={handleChange}
        passBlur={handleBlur}
      />
      {passwordMatched === false && (
        <div className={styles.newPasswordMismatch}>
          Error: Passwords do not match
        </div>
      )}
      <div className={styles.updateContainer}>
        <div
          className={[
            styles.button,
            (isUpdating || !dirty || !valid) && styles.disabled,
            isError && styles.error,
          ].join(' ')}
          onClick={handleUpdate}
        >
          {buttonText()}
        </div>
      </div>
    </div>
  );
  const otherReset = isUpdating ? (
    'Sending password reset email...'
  ) : isSuccessful ? (
    <div className={styles.impersonateSecurity}>
      Password reset instruction email successfully dispatched to
      {emailAddress}
    </div>
  ) : (
    <div className={styles.impersonateSecurity}>
      <span onClick={sendResetEmail} className={styles.padRight}>
        Click here
      </span>
      to send Password Reset Email to
      {emailAddress}
    </div>
  );
  return (
    <div className={styles.container}>
      {fragment === 'impersonatee' ? otherReset : selfReset}
    </div>
  );
};
