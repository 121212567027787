import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CharacterLoader from '../../utils/loader';
import AssistedSearch from './Search';
import WindowedScrollbar from '../../Shared/WindowedScrollbar';
import TicketSelect from './TicketSelect';
import InviterLookup from './InviterLookup';
import ShiftsSelect from './ShiftsSelect';
import ModSignup from './ModSignup';
import StylishTicket from './StylishTicket';
import EventHeader from './EventHeader';
import EventLinks from './EventLinks';
import CharacterSelect from './CharacterSelect';
import CheckInButton from './CheckInButton';
import CorrectiveActions from './CorrectiveActions';
import SuspensionMessage from './SuspensionMessage';
import PaidOrAttending from './PaidOrAttending';
import ErrorPage from '../../Shared/ErrorPages/ErrorPage';
import Print from '@material-ui/icons/Print';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import history from '../../history';
import {
  isMembershipActive,
  isPrivileged as hasPrivilege,
  isGuide as hasGuideRole,
} from '../../utils/user';
import { isOwnerOrExternal } from '../../utils/events';
import { hasValidToken } from '../../utils/token';
import styles from './Checkin.module.scss';
import {
  responsibilitiesSatisfied,
  totalTicketCost,
} from './actions/shiftCalc';
import { isPast, parseISO } from 'date-fns';

const CASTING_ONLY = false;
export default ({ isAssisted, searchComponent }) => {
  const dispatch = useDispatch();
  const {
    rootUser,
    event,
    eventsRun,
    currentCharacterID,
    characterStorage,
    ui,
  } = useSelector(state => ({
    rootUser: state.user,
    event: state.eventCheckin,
    eventsRun: state.events.eventsRun,
    currentCharacterID: state.localStorage.currentCharacterID,
    characterStorage: state.localStorage.characterStorage,
    ui: state.localStorage.ui,
  }));

  const user = rootUser.impersonatee.id ? rootUser.impersonatee : rootUser;
  const isUserFirstEvent = user.isFirstEvent;
  const hasExpandedTicketOptions = hasGuideRole(user) || hasPrivilege(user);
  const eventID = history.location.pathname.match(/(\d+)$/);
  const eventIDMatch = eventID ? eventID[1] : null;
  const windowedScrollbarHeight = isAssisted
    ? 'calc(100vh - 168px)'
    : 'calc(100vh - 132px)';
  const isOpenForRegistration =
    event.registrationDate && isPast(parseISO(event.registrationDate));
  const isPastEvent = event.endDate && isPast(parseISO(event.endDate));
  const isPrivileged = hasPrivilege(rootUser);
  const isEventRunner = isOwnerOrExternal({
    event: { eventID: event.id || event.eventID, branchID: event.branchID },
    user: rootUser,
    eventsRun,
  });

  const isBranchOwner =
    isPrivileged && user.ownedBranches.includes(event.branchID);

  const hasActiveMembership = () =>
    user.memberships &&
    event &&
    isMembershipActive(user, Date.parse(event.startDate));

  const handleSearch = id => {
    dispatch({
      type: 'ASSISTED_CHECKIN_PLAYER_SEARCH_BY_ID',
      payload: id,
    });
  };
  const handleLookup = id => {
    dispatch({
      type: 'INVITER_LOOKUP',
      payload: id,
    });
  };
  const handleBuildBoost = value => {
    dispatch({
      type: 'UPDATE_EVENT_CHECKIN_PROPERTY',
      payload: {
        field: 'buildBoost',
        value: value,
      },
    });
    dispatch({
      type: 'UPDATE_EVENT_CHECKIN_PROPERTY',
      payload: { field: 'isBuildBoosted', value: value },
    });
  };

  const handleTicketChange = value => {
    dispatch({
      type: 'UPDATE_EVENT_CHECKIN_PROPERTY',
      payload: {
        field: 'selectedTicket',
        value: value,
      },
    });
  };

  const handleResetShifts = allShifts =>
    dispatch({
      type: 'RESET_EVENT_CHECKIN_SHIFTS',
      payload: allShifts,
    });

  const handleShiftChange = (id, value, kind) => {
    if (kind === 'special') {
      dispatch({
        type: 'RESET_EVENT_CHECKIN_SHIFTS',
        payload: CASTING_ONLY,
      });
    }
    dispatch({
      type: 'UPDATE_EVENT_CHECKIN_SHIFT',
      payload: {
        id: id,
        field: 'isAttendeeSelected',
        value: value,
      },
    });
  };

  const handleCharacterChange = value =>
    dispatch({
      type: 'UPDATE_EVENT_CHECKIN_PROPERTY',
      payload: {
        field: 'selectedCharacter',
        value: value,
      },
    });

  const handlePaidOrAttending = (field, value) =>
    dispatch({
      type: 'UPDATE_EVENT_CHECKIN_PROPERTY',
      payload: {
        field: field,
        value: value,
      },
    });

  const executeCheckin = () =>
    dispatch({
      type: 'EXECUTE_CHECKIN',
      payload: event,
    });

  const assistedDisplayArbiter = () =>
    isAssisted ? (
      <React.Fragment>
        <AssistedSearch
          passSearch={handleSearch}
          impersonatee={rootUser.impersonatee}
          error={event.searchError}
        />
        <CorrectiveActions
          isRenderable={rootUser.impersonatee.id}
          data={user.correctiveActions}
          event={event}
        />
      </React.Fragment>
    ) : (
      event.registrationUrl && (
        <div className={styles.beNice}>
          <div className={styles.big}>
            <div className={styles.exclamationIcon}>
              <FontAwesomeIcon icon='exclamation-circle' />
            </div>
            <div>Please Be Nice to Your Logistics Personnel</div>
          </div>
          Don't forget to{' '}
          <a
            className={styles.registrationLink}
            href={`${event.registrationUrl}`}
            target='_ticket'
          >
            purchase your Ticket
          </a>
          .
        </div>
      )
    );

  const checkedInDisplayArbiter = () => {
    if (isAssisted && !rootUser.impersonatee.id) return;
    return event.hasCheckedIn && event.purchasedTicket ? (
      <div className={styles.checkedIn}>
        <div className={styles.thanks}>
          {isAssisted ? 'This player has checked in' : 'Thanks for checking in'}
          {isAssisted ? (
            <a
              className={styles.link}
              href={`/admin_print/${eventIDMatch}?player_id=${user.id}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Print />
            </a>
          ) : null}
        </div>
        <StylishTicket
          player={event.user}
          ticket={event.purchasedTicket}
          shifts={event.assignedShifts}
          characters={event.attendeeCharacters}
          isEventRunner={isBranchOwner}
          isPremiere={event.isPremiere}
          isNational={event.isNational}
          isVirtual={event.isVirtual}
          eventName={event.name}
          eventLocation={event.location}
          eventStartDate={event.startDate}
          eventEndDate={event.endDate}
          eventAttendeeID={event.attendeeID}
          eventBaseBuild={event.baseBuild}
          eventInviter={event.inviter}
          mods={event.mods}
          eventConfig={event.config}
        />
      </div>
    ) : (
      <div className={styles.notCheckedIn}>
        <CharacterSelect
          user={user}
          isMembershipActive={hasActiveMembership()}
          isEventRunner={isBranchOwner}
          characters={characterStorage}
          selectedCharacter={event.selectedCharacter}
          passCharacterChange={handleCharacterChange}
        />
        <TicketSelect
          tickets={event.tickets}
          isVirtual={event.isVirtual}
          isPremiere={event.isPremiere}
          isNational={event.isNational}
          isFirstEvent={isUserFirstEvent}
          hasExpandedTicketOptions={isPrivileged || hasExpandedTicketOptions}
          isTravelEvent={!event.isNational && user.branchId !== event.branchID}
          isEventRunner={isBranchOwner}
          buildBoost={event.buildBoost}
          selectedTicket={event.selectedTicket}
          passBuildBoost={handleBuildBoost}
          passTicketChange={handleTicketChange}
          passResetShifts={handleResetShifts}
          eventConfig={event.config}
        />
        <InviterLookup
          user={user}
          inviter={event.inviter}
          selectedTicket={event.selectedTicket}
          passLookup={handleLookup}
        />
        <ShiftsSelect
          selectedTicket={event.selectedTicket}
          isCastingOnly={[104, 154].includes(event.selectedTicket)}
          isEventRunner={isBranchOwner}
          shifts={event.shifts}
          updateSelectedShifts={handleShiftChange}
          shiftResponsibility={
            event.tickets && event.tickets[event.selectedTicket]
              ? event.tickets[event.selectedTicket].shiftResponsibility
              : 0
          }
        />
        <PaidOrAttending
          isAssisted={isAssisted}
          isPaid={event.isPaid}
          isAttending={event.isAttending}
          isTravelEvent={user.branchId !== event.branchID}
          passPaidOrAttending={handlePaidOrAttending}
        />
        <CheckInButton
          error={event.error}
          isProcessing={event.isProcessing}
          passExecuteCheckin={executeCheckin}
          selectedTicket={event.selectedTicket}
          isResponsibilitiesSatisfied={responsibilitiesSatisfied({
            tickets: event.tickets,
            selectedTicket: event.selectedTicket,
            shifts: event.shifts,
            isAdvanced: hasActiveMembership(),
            isEventRunner: isBranchOwner,
            selectedCharacter: event.selectedCharacter,
            inviter: event.inviter,
          })}
          totalCost={totalTicketCost({
            isPremiere: event.isPremiere,
            isNational: event.isNational,
            isVirtual: event.isVirtual,
            tickets: event.tickets,
            selectedTicket: event.selectedTicket,
            buildBoost: event.buildBoost,
            isEventRunner: isBranchOwner,
            eventConfig: event.config,
          })}
        />
      </div>
    );
  };

  useEffect(() => {
    dispatch({ type: 'APP_LOADED' });
  }, [dispatch]);

  useEffect(() => {
    CharacterLoader({ characterStorage, currentCharacterID, dispatch });
  }, [characterStorage, currentCharacterID, dispatch]);

  useEffect(() => {
    if (ui) dispatch({ type: 'SYNC_UI', payload: ui });
  }, [ui, dispatch]);

  useEffect(() => {
    if (!rootUser.session) return;
    dispatch({ type: 'FETCH_CHECK_IN_STATUS', payload: eventIDMatch });
  }, [rootUser.session, dispatch, eventIDMatch]);

  useEffect(() => {
    if (!rootUser.impersonatee.id) return;
    dispatch({ type: 'FETCH_CHECK_IN_STATUS', payload: eventIDMatch });
  }, [rootUser.impersonatee.id, eventIDMatch, dispatch]);

  useEffect(() => {
    if (event.hasCheckedIn !== false) return;
    dispatch({ type: 'FETCH_EVENT_DATA', payload: eventIDMatch });
  }, [event.hasCheckedIn, isAssisted, dispatch, eventIDMatch, user.id]);

  if (event.error) return <ErrorPage errorMessage={event.error} />;
  if (event.isFetching)
    return <ErrorPage errorMessage='Fetching Event data...' />;
  if (isAssisted && !isPrivileged)
    return <ErrorPage errorType='unauthorized' />;
  if (isAssisted && !isEventRunner)
    return <ErrorPage errorType='mismatchedBranch' />;
  if (!isAssisted && !isOpenForRegistration && !event.isSuspended)
    return (
      <ErrorPage errorMessage='Unable to checkin to event that is not yet open' />
    );
  if (!isAssisted && isPastEvent && !event.hasCheckedIn)
    return (
      <ErrorPage errorMessage='Unable to checkin to event that has ended' />
    );

  if (!isAssisted && !hasValidToken(user.session)) return <ErrorPage />;
  if (event.isSuspended) {
    return (
      <SuspensionMessage
        isSuspended={event.isSuspended}
        contacts={event.contacts}
      />
    );
  }
  if (!event.name) return null;

  return (
    <WindowedScrollbar styles={styles} autoHeightMax='calc(100vh)'>
      <div className={styles.container}>
        <div className={styles.eventHeader}>
          <EventHeader
            eventName={event.name}
            eventLocation={event.location}
            eventStartDate={event.startDate}
            eventEndDate={event.endDate}
            eventBranch={{ id: event.branchID }}
            isPremiere={event.isPremiere}
            nextEvent={event.nextEvent}
            prevEvent={event.prevEvent}
            isPrivileged={isPrivileged}
          />

          {isAssisted ? <EventLinks /> : null}
        </div>
        <WindowedScrollbar
          styles={styles}
          autoHeightMax={windowedScrollbarHeight}
        >
          <div className={styles.checkinContent}>
            {assistedDisplayArbiter()}
            {checkedInDisplayArbiter()}
            <ModSignup
              event={event}
              user={rootUser}
              ui={ui}
              isAssisted={isAssisted}
            />
          </div>
        </WindowedScrollbar>
      </div>
    </WindowedScrollbar>
  );
};
