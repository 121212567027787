import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import styles from './Locations.module.scss';
import BranchSelect from '../Events/Branches/SingleSelect';
import TextField from '../Shared/FormComponents/TextField';
import ErrorPage from '../Shared/ErrorPages/ErrorPage';
import {
  isAdmin,
  isBranchOwner as hasBranchOwnership,
  isPrivileged as hasPrivilege,
} from '../utils/user';

export default () => {
  const dispatch = useDispatch();
  const { branches, user } = useSelector(state => ({
    branches: state.branches.data,
    user: state.user,
  }));
  const isBranchOwner = hasBranchOwnership(user);
  const isPrivileged = hasPrivilege(user);
  const [selectedBranchId, setSelectedBranchId] = useState(user.branchId || 0);
  const [focusedLocationIndex, setFocusedLocationIndex] = useState(null);
  const asyncLocations = branches[selectedBranchId]
    ? branches[selectedBranchId].locations
    : {};
  const [locations, setLocations] = useState({});

  useEffect(() => {
    dispatch({ type: 'APP_LOADED' });
  }, [dispatch]);

  useEffect(() => {
    if (user) setSelectedBranchId(user.branchId);
  }, [user]);

  useEffect(() => {
    if (selectedBranchId > 0) {
      dispatch({
        type: 'FETCH_BRANCH_LOCATIONS',
        payload: { branchId: selectedBranchId },
      });
      setFocusedLocationIndex(null);
    }
  }, [dispatch, selectedBranchId]);

  const arbitrateDisabledClass = x =>
    !locations[x] || isEqual(asyncLocations[x], locations[x])
      ? styles.disabled
      : '';

  const arbitrateHiddenClass = x =>
    !locations[x] || isEqual(asyncLocations[x], locations[x])
      ? styles.hidden
      : '';

  const handleSelect = (_, value) => {
    setSelectedBranchId(value);
  };

  const handleFocus = (x, i) => {
    if (focusedLocationIndex !== i) {
      setFocusedLocationIndex(i);
    }
    if (!locations[x]) setLocations({ ...locations, [x]: asyncLocations[x] });
    return focusedLocationIndex !== i;
  };

  const handleChange = (x, field, value) => {
    setLocations({
      ...locations,
      [x]: {
        ...locations[x],
        location: {
          ...locations[x].location,
          [field]: value,
        },
      },
    });
  };

  const handleCancel = x => {
    if (!isEqual(asyncLocations[x], locations[x])) {
      setLocations({
        ...locations,
        [x]: asyncLocations[x],
      });
      setFocusedLocationIndex(null);
    }
  };

  const handleSubmit = x => {
    if (!isEqual(asyncLocations[x], locations[x])) {
      dispatch({
        type: 'UPDATE_BRANCH_LOCATION',
        payload: {
          branchId: selectedBranchId,
          locationId: x,
          locationData: locations[x].location,
        },
      });
      setFocusedLocationIndex(null);
    }
  };

  if (!isPrivileged) return <ErrorPage errorType='unauthorized' />;
  if (isBranchOwner && !user.ownedBranches.includes(selectedBranchId))
    return <ErrorPage errorType='mismatchedBranch' />;

  const renderLocation = (x, i) => (
    <li key={x} className={i === 0 ? null : styles.hr}>
      <div className={styles.locationHeader}>
        <div>{`Location ${i + 1}`}</div>
        <div className={styles.buttonGroup}>
          <div
            className={[styles.button, arbitrateHiddenClass(x)].join(' ')}
            onClick={() => handleCancel(x)}
          >
            Cancel
          </div>
          <div
            className={[styles.button, arbitrateDisabledClass(x)].join(' ')}
            onClick={() => handleSubmit(x)}
          >
            Save
          </div>
        </div>
      </div>
      <TextField
        title='Name'
        fieldKey='name'
        passFocus={() => handleFocus(x, i)}
        passChange={(field, value) => {
          handleChange(x, field, value);
        }}
        revert='never'
        value={
          locations[x]
            ? locations[x].location.name
            : asyncLocations[x].location.name || ''
        }
      />
      <TextField
        title='Street Address'
        fieldKey='street_address'
        passFocus={() => handleFocus(x, i)}
        passChange={(field, value) => handleChange(x, field, value)}
        revert='never'
        value={
          locations[x]
            ? locations[x].location.street_address
            : asyncLocations[x].location.street_address || ''
        }
      />
      <TextField
        title='City'
        fieldKey='city'
        passFocus={() => handleFocus(x, i)}
        passChange={(field, value) => handleChange(x, field, value)}
        revert='never'
        value={
          locations[x]
            ? locations[x].location.city
            : asyncLocations[x].location.city || ''
        }
      />
      <TextField
        title='Zip Code'
        fieldKey='zip_code'
        passFocus={() => handleFocus(x, i)}
        passChange={(field, value) => handleChange(x, field, value)}
        revert='never'
        value={
          locations[x]
            ? locations[x].location.zip_code
            : asyncLocations[x].location.zip_code || ''
        }
      />
    </li>
  );

  return (
    <div className={styles.page}>
      <BranchSelect
        user={user}
        selectedBranch={selectedBranchId || 0}
        passChange={handleSelect}
        allowNational={isAdmin}
        isExternalRunner={isBranchOwner}
        hideLocations
      />
      <ul className={styles.ul}>
        {asyncLocations &&
          Object.keys(asyncLocations)
            .filter(x => asyncLocations[x].location.region !== 'VIRTUAL')
            .map((x, i) => renderLocation(x, i))}
      </ul>
    </div>
  );
};
