import React from 'react';
import styles from './ErrorPage.module.scss';

const defaultMessage = 'Please login to access this page';
const errorTypes = {
  unauthorized: 'You are not authorized to access this page',
  mismatchedBranch: 'You are not allowed to manage Events from this Branch',
};

export default ({ errorType, errorMessage }) => (
  <div className={styles.container}>
    {errorTypes[errorType] || errorMessage || defaultMessage}
  </div>
);
