export const initialState = {
  searchResults: [],
  localSearchResults: [],
  selectedItemId: null,
  cachedItems: {},
  isSearching: false,
  lastExecutedSearch: '',
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'EXECUTE_ITEM_SEARCH':
      return {
        ...state,
        isSearching: true,
        lastExecutedSearch: payload.query,
      };
    case 'EXECUTE_ITEM_SEARCH_SUCCEEDED':
      return {
        ...state,
        isSearching: false,
        searchResults: payload,
      };
    case 'EXECUTE_ITEM_SEARCH_LOCAL_SUCCEEDED':
      return {
        ...state,
        isSearching: false,
        localSearchResults: payload,
      };
    case 'EXECUTE_ITEM_SEARCH_FAILED':
      return {
        ...state,
        isSearching: false,
      };
    case 'FETCH_ITEM':
      return {
        ...state,
        selectedItemId: payload,
        cachedItems: {
          ...state.cachedItems,
          [payload]: {
            ...state.cachedItems[payload],
            isFetching: true,
            isFailed: false,
            data: state.cachedItems[payload]
              ? state.cachedItems[payload].data
              : null,
          },
        },
      };
    case 'FETCH_ITEM_WITHOUT_SELECTED_ITEM_CHANGE':
      return {
        ...state,
        cachedItems: {
          ...state.cachedItems,
          [payload]: {
            ...state.cachedItems[payload],
            isFetching: true,
            isFailed: false,
            data: state.cachedItems[payload]
              ? state.cachedItems[payload].data
              : null,
          },
        },
      };
    case 'FETCH_ITEM_SUCCEEDED':
      return {
        ...state,
        cachedItems: {
          ...state.cachedItems,
          [payload.id]: {
            ...state.cachedItems[payload.id],
            isFetching: false,
            isFailed: false,
            data: payload.data,
          },
        },
      };
    case 'FETCH_ITEM_FAILED':
      return {
        ...state,
        cachedItems: {
          ...state.cachedItems,
          [payload.id]: {
            ...state.cachedItems[payload.id],
            isFetching: false,
            isFailed: true,
            data: state.cachedItems[payload.id].data || null,
          },
        },
      };
    default:
      return state;
  }
};
