import React from 'react';
import branches from '../gameData/branches';
import styles from './Character.module.scss';

export default ({ user, event }) => {
  if (!user.caps) return null;

  const tally = user.caps
    .filter(x => x.state === 'active')
    .filter(x => x.branch_id === event.branch.id)
    .map(x => x.amount)
    .reduce((a, b) => a + b, 0);

  return (
    <div className={styles.caps}>
      CAPS
      {` (${branches[event.branch.id].shortName}): ${tally}`}
    </div>
  );
};
