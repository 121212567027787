import React, { useState } from 'react';
import { startCase, camelCase } from 'lodash';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import {
  compareAsc,
  format,
  formatDistanceToNow,
  parseISO,
  isAfter,
  isBefore,
} from 'date-fns';
import styles from './CorrectiveActions.module.scss';

const SORT_ORDER = {
  refusal_of_service: 1,
  suspension: 2,
  violation: 3,
  warning: 4,
};

export default ({ isRenderable, data, event }) => {
  const [displayExpired, setDisplayExpired] = useState(false);
  const renderExpiration = endDate => {
    if (endDate === null) return 'Permanent';

    const isoDate = parseISO(endDate);

    return `${format(isoDate, 'yyyy-MM-dd')} (${formatDistanceToNow(isoDate, {
      addSuffix: true,
    })})`;
  };

  const sortDate = (a, b) => {
    if (a === null) return 1;
    if (b === null) return -1;
    return compareAsc(parseISO(b), parseISO(a));
  };

  const toggleDisplay = () => setDisplayExpired(!displayExpired);

  const sortCorrectiveActions = (a, b) =>
    a.kind === b.kind
      ? sortDate(a.ends_at, b.ends_at)
      : SORT_ORDER[a.kind] - SORT_ORDER[b.kind];

  const renderCorrectiveAction = ca => (
    <div
      className={[styles.row, styles[camelCase(ca.kind)]].join(' ')}
      key={ca.id}
    >
      <div className={styles.title}>
        {startCase(ca.kind)}
        {` - `}
        {ca.branch.name}
        {` - `}
        {`(#${ca.issuer.id}) ${[ca.issuer.first_name, ca.issuer.last_name]
          .join(' ')
          .trim()}`}
      </div>
      <div className={styles.reason}>{ca.reason || 'No reason given'}</div>
      <div className={styles.expiration}>
        {`Expiration: ${renderExpiration(ca.ends_at)}`}
      </div>
    </div>
  );

  const eventStartDate =
    event && event.startDate ? parseISO(event.startDate) : new Date();

  const activeCorrectiveActions = data
    .filter(
      x => x.ends_at === null || isAfter(parseISO(x.ends_at), eventStartDate),
    )
    .sort(sortCorrectiveActions)
    .map(x => renderCorrectiveAction(x));

  const expiredCorrectiveActions = data
    .filter(
      x => x.ends_at !== null && isBefore(parseISO(x.ends_at), eventStartDate),
    )
    .sort(sortCorrectiveActions)
    .map(x => renderCorrectiveAction(x));

  const dropIcon = displayExpired ? (
    <ArrowDropUp className={styles.drop} />
  ) : (
    <ArrowDropDown className={styles.drop} />
  );

  const expiredHeader = (
    <div className={styles.sectionHeader} onClick={toggleDisplay}>
      {expiredCorrectiveActions.length === 0 ? null : (
        <React.Fragment>
          {`Expired Corrective Actions (${expiredCorrectiveActions.length})`}
          {dropIcon}
        </React.Fragment>
      )}
    </div>
  );

  if (!isRenderable) return null;
  return (
    <div className={styles.container}>
      <div className={styles.active}>{activeCorrectiveActions}</div>
      <div className={styles.expired}>
        {expiredHeader}
        {displayExpired && expiredCorrectiveActions}
      </div>
    </div>
  );
};
