import React, { useEffect, useState } from 'react';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import styles from './index.module.scss';

export default ({
  options,
  onChange,
  selectedOptionId,
  placeholder,
  emptyOptions,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [forcedBlur, setForcedBlur] = useState(false);

  useEffect(() => {
    if (selectedOptionId === null) setSelectedOption(null);
  }, [selectedOptionId]);

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setForcedBlur(false)}
      onClick={() => setForcedBlur(false)}
    >
      <div className={styles.beacon}>
        <ArrowDropDown className={styles.icon} />
        <div className={styles.text}>
          {selectedOption || placeholder || '...'}
        </div>
      </div>
      <div
        className={[styles.options, forcedBlur && styles.forcedBlur].join(' ')}
      >
        {options && options.length > 0 ? (
          options.map(x => (
            <div
              className={[
                styles.option,
                selectedOptionId === x.value && styles.selected,
              ].join(' ')}
              key={x.value}
              onClick={() => {
                setSelectedOption(x.label);
                setForcedBlur(true);
                onChange(x.value);
              }}
            >
              {x.label}
            </div>
          ))
        ) : (
          <div className={[styles.option, styles.emptyOption].join(' ')}>
            {emptyOptions || 'No available options'}
          </div>
        )}
      </div>
    </div>
  );
};
