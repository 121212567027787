import { fromPairs } from 'lodash';
import { SYNC_BUFFER } from '../types';
import lores from '../../gameData/lores';

export const initialState = fromPairs(Object.keys(lores).map(x => [x, false]));

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SYNC_BUFFER:
      return payload.lores;
    default:
      return state;
  }
};
