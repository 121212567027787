import InputBase from '@material-ui/core/InputBase';
import Toggle from 'react-toggle';
import React, { useState } from 'react';
import styles from './ShiftControls.module.scss';
import { eachDayOfInterval, getDay } from 'date-fns';

const DAYS = {
  0: 'Special',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
  8: 'Cleanup',
};

export default ({
  startDate,
  endDate,
  isEditEvent,
  shifts,
  passShiftUpdate,
  passAddCleanupShift,
  passRemoveCleanupShift,
}) => {
  const [currentFocusID, setCurrentFocusID] = useState(null);
  const handleChange = (id, field, value) =>
    passShiftUpdate('shifts', id, field, value);

  const handleRemove = id => passRemoveCleanupShift(id);

  const renderRow = shift => (
    <div
      key={shift.order}
      className={[styles.row, shift.isEnabled ? null : styles.disabled].join(
        ' ',
      )}
    >
      <div className={styles.toggle}>
        <Toggle
          checked={shift.isEnabled || false}
          onChange={event =>
            handleChange(shift.order, 'isEnabled', event.target.checked)
          }
        />
      </div>
      <div className={styles.name}>
        <InputBase
          value={shift.label}
          onChange={event =>
            handleChange(shift.order, 'label', event.target.value)
          }
          onFocus={() => setCurrentFocusID(shift.order)}
          onBlur={() => setCurrentFocusID(null)}
          className={styles.inputText}
        />
      </div>
      {isEditEvent &&
      shift.kind === 'cleanup' &&
      Object.values(shifts).filter(x => x.kind === 'cleanup').length > 1 ? (
        <div
          className={[
            styles.remove,
            currentFocusID === shift.order ? styles.hidden : '',
          ].join(' ')}
          onClick={() => handleRemove(shift.order)}
        >
          <div className={styles.text}>Remove</div>
        </div>
      ) : null}
      <div className={styles.limit}>
        <InputBase
          value={shift.limit || ''}
          onChange={event =>
            handleChange(shift.order, 'limit', event.target.value)
          }
          className={styles.inputText}
        />
      </div>
    </div>
  );

  const renderSection = day => (
    <div className={styles.section}>
      <div className={styles.day}>Shift: {DAYS[day]}</div>

      {Object.keys(shifts)
        .filter(x => shifts[x].day === day)
        .map(x => renderRow(shifts[x]))}
    </div>
  );

  const render = day => renderSection(day);

  return (
    <div className={styles.container}>
      {render(0)}
      {eachDayOfInterval({ start: startDate, end: endDate }).map(date =>
        render(getDay(date) === 0 ? 7 : getDay(date)),
      )}
      {render(8)}

      <div className={styles.addCleanupShift} onClick={passAddCleanupShift}>
        Add More Cleanup Shifts
      </div>
    </div>
  );
};
