import React, { useEffect, useState } from 'react';
import { parseISO } from 'date-fns';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import Toggle from 'react-toggle';
import styles from '../ModControls.module.scss';

const ALWAYS_UPDATE = true;

export default ({
  eventModSignupOpensAt,
  eventModMaxSignup,
  eventModEnableWaitlist,
  eventID,
  branchID,
  eventStartDate,
}) => {
  const dispatch = useDispatch();
  const [modSignupOpenTime, setModSignupOpenTime] = useState(null);
  const [modMaxSignup, setModMaxSignup] = useState('');
  const [modEnableWaitlist, setModEnableWaitlist] = useState(
    eventModEnableWaitlist,
  );
  const updateModMaxSignup = value => setModMaxSignup(value.toString());
  const updateEventModSignupOpensAt = timestamp => {
    setModSignupOpenTime(timestamp);
    dispatch({
      type: 'UPDATE_EVENT_MODS_METADATA',
      payload: {
        field: 'mod_signup_opens_at',
        value: timestamp,
        eventID,
        branchID,
      },
    });
  };

  const updateModMaxSignupUpstream = alwaysUpdate => {
    if (!alwaysUpdate) {
      if (eventModMaxSignup === null) {
        if (modMaxSignup.trim() === '') return;
      } else {
        if (eventModMaxSignup.toString() === modMaxSignup.trim()) return;
      }
    }
    dispatch({
      type: 'UPDATE_EVENT_MODS_METADATA',
      payload: {
        field: 'mod_max_signup',
        value: modMaxSignup,
        eventID,
        branchID,
      },
    });
  };

  const updateModEnableWaitlist = val => {
    setModEnableWaitlist(val);
    dispatch({
      type: 'UPDATE_EVENT_MODS_METADATA',
      payload: {
        field: 'mod_enable_waitlist',
        value: val,
        eventID,
        branchID,
      },
    });
  };

  useEffect(() => {
    setModSignupOpenTime(
      eventModSignupOpensAt ? parseISO(eventModSignupOpensAt) : null,
    );
  }, [eventModSignupOpensAt]);

  useEffect(() => {
    setModMaxSignup(eventModMaxSignup ? eventModMaxSignup.toString() : '');
  }, [eventModMaxSignup]);

  useEffect(() => {
    setModEnableWaitlist(eventModEnableWaitlist);
  }, [eventModEnableWaitlist]);

  return (
    <div className={styles.scheduler}>
      <div className={styles.row}>
        <div className={styles.title}>Signup Time</div>
        <div className={styles.picker}>
          <DatePicker
            placeholderText='Keep this blank to disable Mod Signup'
            onChange={updateEventModSignupOpensAt}
            selected={modSignupOpenTime}
            maxDate={eventStartDate}
            isClearable
            showTimeSelect
            dateFormat='EEE MMMM d, yyyy h:mm aa'
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.title}>Global Limit</div>
        <input
          className={styles.input}
          type='text'
          placeholder='optional'
          value={modMaxSignup || ''}
          onChange={evt => updateModMaxSignup(evt.target.value)}
          onBlur={() => updateModMaxSignupUpstream()}
          onKeyDown={evt =>
            evt.keyCode === 13 && updateModMaxSignupUpstream(ALWAYS_UPDATE)
          }
        />
        <div className={styles.description}>
          Global limit enforces maximum Mods a Player can join for this entire
          Event. Leave blank to defer to Mod Section limit.
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.title}>Enable Waitlist</div>
        <div className={styles.toggleContainer}>
          <Toggle
            checked={modEnableWaitlist}
            onChange={evt => updateModEnableWaitlist(evt.target.checked)}
          />
        </div>
        <div className={styles.description}>
          Disabling Waitlist will not kick Players already in the Waitlist. You
          have to manually kick them.
        </div>
      </div>
    </div>
  );
};
