import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import styles from './Select.module.scss';

export default ({
  user,
  isMembershipActive,
  isEventRunner,
  characters,
  selectedCharacter,
  passCharacterChange,
}) => {
  const isMultiCheckin = isMembershipActive || isEventRunner;
  const handleCharacterChange = event =>
    passCharacterChange(event.target.value);
  const headerText = () =>
    isMultiCheckin
      ? 'All characters will be checked in'
      : 'Select Characters to Check In';

  const charactersBuilder = () =>
    [
      <MenuItem key={-1} value={-1} className={styles.option} disabled>
        {headerText()}
      </MenuItem>,
    ].concat(
      Object.keys(characters)
        .filter(x => characters[x].remoteID)
        .filter(x => ['active', 'staged'].includes(characters[x].remoteStatus))
        .sort((a, b) =>
          characters[a].remoteID < characters[b].remoteID ? -1 : 1,
        )
        .map(x => (
          <MenuItem
            key={x}
            value={characters[x].remoteID}
            className={styles.option}
            disabled={isMultiCheckin}
          >
            {characters[x].name}
          </MenuItem>
        )),
    );
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.header}>Characters</div>
        <div className={styles.selector}>
          <Select
            autoWidth={true}
            className={styles.base}
            classes={{
              select: styles.select,
              icon: styles.icon,
            }}
            value={selectedCharacter}
            onChange={handleCharacterChange}
            MenuProps={{
              className: styles.menu,
            }}
            disableUnderline
          >
            {charactersBuilder()}
          </Select>
        </div>
      </div>
    </div>
  );
};
