import React from 'react';
import styles from './Floater.module.scss';
import Modal from 'react-modal-resizable-draggable';

export default ({ isOpen, closeModal, title, content }) => {
  return (
    <Modal
      isOpen={isOpen}
      className={styles.container}
      initWidth={256}
      initHeight={400}
    >
      <div className={styles.title}>{title || 'Modal'}</div>
      <div className={styles.close} onClick={closeModal}>
        X
      </div>
      <div className={styles.content}>{content}</div>
    </Modal>
  );
};
