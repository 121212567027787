import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { startCase } from 'lodash';

const colors = {
  combat: {
    basic: '#8f4a26',
    proficient: '#7e3a16',
    master: '#722f0b',
    achievement: '#682603',
  },
  civilized: {
    basic: '#6f4a7f',
    proficient: '#613674',
    master: '#46145b',
    achievement: '#3c0952',
  },
  wasteland: {
    basic: '#546a4c',
    proficient: '#374532',
    master: '#213819',
    achievement: '#1a3111',
  },
  anomaly: {
    basic: '#007a80',
    proficient: '#00696e',
    master: '#00585c',
    achievement: '#004a4d',
  },
};

export default ({ data, title, onClick }) => {
  return (
    <ResponsiveBar
      data={data}
      colors={({ id, data }) => colors[data.category][id]}
      keys={['basic', 'proficient', 'master', 'achievement']}
      indexBy='skill'
      onClick={point => onClick(title, point)}
      tooltip={d => {
        return (
          <div>
            <div>
              {`
                ${
                  d.id === 'achievement'
                    ? d.data.t4
                    : d.data.maxTier === 1
                    ? ''
                    : startCase(d.id)
                }
                ${d.id === 'achievement' ? '' : ' '}
                ${d.id === 'achievement' ? '' : d.indexValue}:
                ${d.value}
              `}
            </div>
            <div>Click to show detailed data</div>
          </div>
        );
      }}
      layout='horizontal'
      enableGridY={false}
      enableGridX={true}
      axisBottom={{
        legend: 'count',
        legendPosition: 'middle',
        legendOffset: 36,
        tickSize: 5,
      }}
      axisTop={{
        legend: 'count',
        legendPosition: 'middle',
        legendOffset: -36,
        tickSize: 5,
      }}
      defs={[
        {
          id: 'achievement',
          type: 'patternLines',
          rotation: -45,
          lineWidth: 4,
          background: '#ccc',
          color: 'inherit',
        },
      ]}
      fill={[
        {
          match: { id: 'achievement' },
          id: 'achievement',
        },
      ]}
      margin={{
        top: 48,
        right: 10,
        bottom: 48,
        left: 128,
      }}
      labelSkipHeight={16}
      theme={{
        axis: {
          legend: {
            text: {
              fill: '#ccc',
              fontFamily: 'Bellefair',
              fontSize: '14px',
            },
          },
          ticks: {
            text: {
              fill: '#ccc',
              fontFamily: 'Bellefair',
              fontSize: '14px',
            },
          },
        },
        grid: {
          line: {
            stroke: '#888',
            strokeWidth: 1,
          },
        },
        labels: {
          text: {
            fontFamily: 'Bellefair',
          },
        },
        tooltip: {
          container: {
            background: '#333',
          },
        },
      }}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      padding={0.3}
    />
  );
};
