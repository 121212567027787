import { useDispatch } from 'react-redux';
import Input from '@material-ui/core/Input';
import React, { useCallback, useState, useEffect } from 'react';
import Search from '@material-ui/icons/Search';
import GuideIcon from '@material-ui/icons/SupervisedUserCircle';
import ReactTooltip from 'react-tooltip';
import Help from '@material-ui/icons/HelpOutline';
import styles from './Impersonator.module.scss';

export default ({ impersonate, visible, searchResult }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [result, setResult] = React.useState(null);
  const [message, setMessage] = React.useState(null);
  const dispatchSearch = () => {
    setMessage('Searching...');
    dispatch({ type: 'CLEANUP_SEARCH_RESULT' });
    dispatch({
      type: 'SEARCH_USER',
      payload: value,
    });
  };
  const handleChange = event => {
    setValue(event.target.value);
  };
  const handleSelectAllText = event => event.target.select();

  const onKeyPressed = event => {
    if (event.keyCode !== 13) return;

    event.preventDefault();
    if (value.length === 0) return;
    dispatchSearch();
  };
  const handleEnter = () => (value.length > 0 ? dispatchSearch() : null);

  const messageContainer = () => {
    if (!message) return null;

    return <div className={styles.message}>{message}</div>;
  };

  const searchResultContainer = () => {
    if (!searchResult) return null;
    if (!result) return null;

    return (
      <div className={styles.result}>
        {result.length === 0 ? 'No result found' : result}
      </div>
    );
  };

  const processSearchResult = searchResults =>
    searchResults.map(res =>
      res.user
        ? {
            playerName: [res.user.first_name, res.user.last_name]
              .join(' ')
              .trim(),
            playerID: res.user.id,
            characterName: res.name,
            characterID: res.id,
            isGuide: res.user.role === 'guide',
          }
        : {
            playerID: res.id,
            playerName: [res.first_name, res.last_name].join(' ').trim(),
            isGuide: res.role === 'guide',
          },
    );

  const updateSearchResult = useCallback(() => {
    if (!searchResult) return;

    setMessage(null);
    setResult(
      processSearchResult(searchResult).map(x => {
        const mutedClass = () => (x.characterName ? styles.muted : '');

        return (
          <div key={`${x.playerID}_${x.characterID}`} className={styles.entry}>
            <div className={styles.id} onClick={() => impersonate(x.playerID)}>
              {`${x.playerID}`}
            </div>
            <div className={styles.name}>
              <span className={mutedClass()}>{x.playerName}</span>
              {x.isGuide ? (
                <GuideIcon fontSize='small' className={styles.icon} />
              ) : null}
              {x.characterName ? <br /> : null}
              {x.characterName ? ` » ${x.characterName}` : ''}
            </div>
          </div>
        );
      }),
    );
  }, [searchResult, impersonate]);

  useEffect(() => {
    updateSearchResult();
  }, [updateSearchResult]);

  if (!visible) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <ReactTooltip
          id='filter-help'
          place='bottom'
          effect='solid'
          multiline
          insecure
          className={styles.tooltip}
        >
          <div>
            Filter players by branch by appending &lsquo;filter:
            [branch]&rsquo;. Examples:
            <br />-<span className={styles.mono}>jane branch: socal</span>
            <br />-<span className={styles.mono}>joe branch: OK</span>
            <br />
            <br />
            List players in a branch by omitting the search term. Example:
            <span className={styles.mono}>branch: MA</span>
            <br />
            <br />
            Filter can also be applied to include only or exclude any Guide.
            Example:
            <br />-<span className={styles.mono}>is: guide</span>
            <br />-<span className={styles.mono}>not: guide</span>
            <br />
            <br />
            Filters can be combined conjunctively. Example:
            <span className={styles.mono}>joe branch: nm not: guide</span>
          </div>
        </ReactTooltip>
        <Input
          className={styles.impersonator}
          classes={{
            root: styles.impersonator,
            input: styles.input,
            underline: styles.underline,
            focused: styles.focused,
          }}
          placeholder='Search Anything...'
          onChange={handleChange}
          onKeyDown={e => onKeyPressed(e)}
          onClick={handleSelectAllText}
          value={value}
        />
        <Help className={styles.help} data-for='filter-help' data-tip />
        <Search
          className={[
            styles.search,
            value.length === 0 ? styles.muted : '',
          ].join(' ')}
          onClick={handleEnter}
        />
      </div>
      {messageContainer()}
      {searchResultContainer()}
    </div>
  );
};
