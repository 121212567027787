import React from 'react';
import styles from './Character.module.scss';
import skills from '../gameData/skills';

export default ({
  level,
  isInnate,
  maxTier,
  name,
  hasAchievement,
  achievementName,
  skillKey,
}) => {
  return (
    <React.Fragment>
      <tr>
        <td className={styles.skillName}>
          <div className={styles.sharedCell}>
            <div className={styles.basicName}>{name}</div>

            {hasAchievement ? (
              <div className={styles.pfaName}>{`[${achievementName}]`}</div>
            ) : null}
          </div>
        </td>
        <td
          className={[
            styles.text,
            styles.rightAlign,
            styles.fixedSkillLevel,
          ].join(' ')}
        >
          {level['1']
            ? isInnate
              ? 0
              : level['1'] === -1
              ? '_'
              : level['1'] * 2
            : '*'}
        </td>
        <td
          className={[
            styles.text,
            styles.rightAlign,
            styles.fixedSkillLevel,
          ].join(' ')}
        >
          {level['2'] ? level['2'] * 3 : maxTier === 1 ? '-' : null}
        </td>
        <td
          className={[
            styles.text,
            styles.rightAlign,
            styles.fixedSkillLevel,
            level['3'] &&
              skills[skillKey][3].resolveLocked &&
              styles.resolveLocked,
          ].join(' ')}
        >
          {level['3'] ? level['3'] * 4 : maxTier === 1 ? '-' : null}
        </td>
        <td
          className={[
            styles.text,
            styles.rightAlign,
            styles.pfa,
            styles.fixedSkillLevel,
          ].join(' ')}
        >
          {hasAchievement ? 10 : null}
        </td>
      </tr>
    </React.Fragment>
  );
};
