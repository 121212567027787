import { useSelector, useDispatch } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Lock from '@material-ui/icons/Lock';
import faiths from '../../gameData/faiths';
import styles from '../Strain/style.module.scss';
import { isEditable as hasEditPrivilege } from '../../utils/user';

const FaithSelect = () => {
  const dispatch = useDispatch();
  const { characterFaith, approvedForPlay, user } = useSelector(state => ({
    characterFaith: state.character.faith,
    approvedForPlay: state.character.approvedForPlay,
    user: state.user,
  }));
  const isEditable = hasEditPrivilege(user, approvedForPlay);
  const handleFaithChange = event => {
    dispatch({
      type: 'UPDATE_FAITH',
      payload: event.target.value,
    });
  };
  const faithBuilder = () => {
    return Object.keys(faiths).map(faith => {
      return (
        <MenuItem key={faith} value={faith} className={styles.item}>
          {faiths[faith].name}
        </MenuItem>
      );
    });
  };

  return (
    <Select
      className={[
        styles.root,
        styles.fullWidth,
        isEditable ? '' : styles.disabled,
      ].join(' ')}
      classes={{
        select: styles.select,
      }}
      value={characterFaith}
      onChange={handleFaithChange}
      inputProps={{ disabled: !isEditable }}
      IconComponent={isEditable ? ArrowDropDownIcon : Lock}
      MenuProps={{
        className: styles.menu,
      }}
      disableUnderline
    >
      {faithBuilder()}
    </Select>
  );
};

export default React.memo(FaithSelect);
