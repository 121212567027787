import { fromPairs } from 'lodash';

export const GRADE_SORT = { basic: 1, proficient: 2, master: 3, ungraded: 4 };
export const GRADE_SORT_REAL = fromPairs(
  Object.keys(GRADE_SORT)
    .filter(x => x !== 'ungraded')
    .map(x => [x, GRADE_SORT[x]]),
);
export const BLANK_ITEM_DATA = {
  name: '',
  kind: '',
  craftable_items: [],
  crafting_components: [],
  parts: [],
};
export const BLANK_ITEM_STATE = {
  isFetching: false,
  isFailed: false,
  data: BLANK_ITEM_DATA,
};
