import { SYNC_BUFFER } from '../types';

export const initialState = {
  body: { added: 0, nextCost: 1, total: 0, build: 0 },
  mind: { added: 0, nextCost: 1, total: 0, build: 0 },
  resolve: { added: 0, nextCost: 10, total: 0, build: 0 },
  infection: { added: 0, nextCost: 10, total: 0, build: 0 },
  deaths: { count: 0 },
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SYNC_BUFFER:
      return payload.stats;
    default:
      return state;
  }
};
