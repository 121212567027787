import { parseISO } from 'date-fns';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isPrivileged as hasPrivilege } from '../../../utils/user';
import s from './index.module.scss';

const Fracture = ({ fracture, maxId }) => {
  const dispatch = useDispatch();
  const { id, removed_at: removedAt, created_at: createdAt } = fracture;
  const [name, setName] = React.useState((fracture && fracture.name) || '');
  const { user } = useSelector(state => ({
    user: state.user,
  }));
  const isPrivileged = hasPrivilege(user);
  const isResolved = removedAt !== null;

  const addFracture = React.useCallback(() => {
    if (name.trim().length === 0) return;

    dispatch({
      type: 'ADD_FRACTURE',
      payload: { name },
    });
  }, [name]);
  const updateFracture = React.useCallback(() => {
    if (name.trim().length === 0) return;
    if (name.trim() === fracture.name.trim()) return;

    dispatch({
      type: 'UPDATE_FRACTURE',
      payload: { id, name },
    });
  }, [name]);
  const toggleResolution = React.useCallback(() => {
    dispatch({
      type: 'TOGGLE_FRACTURE',
      payload: {
        id,
        value: removedAt ? 'undo' : 'remove',
      },
    });
  }, [removedAt]);

  React.useEffect(() => {
    if (!maxId) return;
    setName('');
  }, [maxId]);

  if (id === null && !isPrivileged) return <></>;
  return (
    <div className={s.container}>
      {isPrivileged ? (
        id ? (
          <React.Fragment>
            <div className={[s.status, isResolved ? s.resolved : s.active].join(' ')}>
              {isResolved ? (
                <div>
                  <div className={s.resolved}>RESOLVED</div>
                  <div>{parseISO(removedAt).toLocaleDateString()}</div>
                </div>
              ) : (
                <div>
                  <div className={s.active}>ACTIVE</div>
                  <div>{parseISO(createdAt).toLocaleDateString()}</div>
                </div>
              )}
            </div>
            {isResolved ? (
              <div className={[s.left, s.plain].join(' ')}>{fracture.name}</div>
            ) : (
              <input
                className={s.left}
                type='text'
                placeholder='Add New Fracture'
                value={name}
                onBlur={updateFracture}
                onChange={evt => setName(evt.target.value)}
                onKeyUp={evt => evt.keyCode === 13 && updateFracture()}
              />
            )}
            <button className={s.right} onClick={toggleResolution}>
              {isResolved ? 'Unresolve' : 'Resolve'}
            </button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <input
              className={s.left}
              type='text'
              placeholder='Add New Fracture'
              value={name}
              onChange={evt => setName(evt.target.value)}
              onKeyUp={evt => evt.keyCode === 13 && addFracture()}
            />
            <button
              className={[s.right, name.trim().length === 0 && s.disabled].join(
                ' ',
              )}
              type='button'
              onClick={addFracture}
            >
              Add
            </button>
          </React.Fragment>
        )
      ) : (
        <div className={[s.regular, isResolved && s.resolved].join(' ')}>
          <div>{fracture.name}</div>
          <div>
            {isResolved
              ? `RESOLVED at ${parseISO(removedAt).toLocaleDateString()}`
              : `ACTIVE since ${parseISO(createdAt).toLocaleDateString()}`}
          </div>
        </div>
      )}
    </div>
  );
};

export default Fracture;
