import React from 'react';
import styles from './BaseRaster.module.scss';

export default ({ source, styleOverride }) => (
  <img
    style={styleOverride}
    className={styles.raster}
    src={`/icons/${source}.png`}
    alt={source}
  />
);
