import React from 'react';
import { ResponsivePie } from '@nivo/pie';

export default ({ data, title, onClick }) => (
  <ResponsivePie
    data={data}
    cornerRadius={3}
    innerRadius={0.5}
    padAngle={0.7}
    radialLabel='label'
    radialLabelsSkipAngle={10}
    slicesLabelsSkipAngle={10}
    slicesLabelsTextColor={{ theme: 'grid.line.stroke' }}
    margin={{ top: 32, right: 10, bottom: 32, left: 10 }}
    colors={{ scheme: 'set3' }}
    sortByValue={true}
    onClick={point => onClick(title, point)}
    theme={{
      labels: {
        text: {
          fontFamily: 'Bellefair',
          fontSize: 14,
          fill: '#ccc',
        },
      },
      tooltip: {
        container: {
          background: '#333',
        },
      },
      grid: {
        line: {
          stroke: '#333',
        },
      },
    }}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
  />
);
