import React from 'react';
import Toggle from 'react-toggle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Staged from '@material-ui/icons/SettingsEthernet';
import styles from './attendee.module.scss';

export default ({
  name,
  ticketLabel,
  buildGrowth,
  status,
  isAdvancedMember,
  isPaid,
  isAttending,
  isTraveler,
  isPremiereEvent,
  isNationalEvent,
  homeBranch,
  buildGrowths,
  upgradeableTicket,
  attendeeCharacterID,
  passCancelAttendeeCharacter,
  passAttendeeCharacterPaid,
  passAttendeeCharacterAttending,
  passBuildGrowthChange,
  passTicketUpgrade,
}) => {
  const handleBuildGrowthChange = event => passBuildGrowthChange(event);
  const cancelAttendeeCharacter = () =>
    passCancelAttendeeCharacter(attendeeCharacterID);
  const handleAttendeeCharacterPaid = event =>
    passAttendeeCharacterPaid(attendeeCharacterID, event.target.checked);

  const optionalBuildGrowth = () => (
    <span
      className={[
        styles.buildGrowth,
        buildGrowth ? '' : styles.noExtraBuild,
      ].join(' ')}
    >
      <Select
        value={buildGrowth || 0}
        onChange={handleBuildGrowthChange}
        className={styles.dropdown}
        classes={{
          select: styles.select,
          icon: styles.icon,
        }}
        MenuProps={{
          className: styles.menu,
        }}
        disableUnderline
      >
        {buildGrowths.map(x => (
          <MenuItem key={x} value={x} className={styles.option}>
            {x}
          </MenuItem>
        ))}
      </Select>
    </span>
  );

  const ticketInfo = () => {
    if (isAdvancedMember) return null;
    return (
      <React.Fragment>
        <span className={styles.ticketCancel} onClick={cancelAttendeeCharacter}>
          X
        </span>

        {optionalBuildGrowth()}
        {upgradeableTicket()}
      </React.Fragment>
    );
  };

  const paidToggle = () => {
    if (isAdvancedMember) return null;
    return (
      <Toggle
        className={styles.toggle}
        onChange={handleAttendeeCharacterPaid}
        checked={isPaid}
      />
    );
  };

  const administrativeInfo = () => {
    if (isAdvancedMember) return null;
    return <div className={styles.administrativeInfo}>{ticketInfo()}</div>;
  };

  return (
    <div className={styles.characterRow}>
      <div className={[styles.cell, styles.buffer].join(' ')} />
      <div className={[styles.cell, styles.characterName].join(' ')}>
        <div className={styles.fantasyName}>
          {status === 'staged' ? <Staged className={styles.staged} /> : null}
          {name}
        </div>
        {administrativeInfo()}
      </div>
      <div className={[styles.cell, styles.toggles].join(' ')}>
        {paidToggle()}
      </div>
    </div>
  );
};
