import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fromPairs } from 'lodash';
import Toggle from 'react-toggle';
import BackSheet from './BackSheet';
import Character from './Character';
import SearchBar from './SearchBar';
import WindowedScrollbar from '../Shared/WindowedScrollbar';
import CharacterLoader from '../utils/loader';
import EventLinks from '../Events/Checkin/EventLinks';
import ErrorPage from '../Shared/ErrorPages/ErrorPage';
import { isOwnerOrExternal } from '../utils/events';
import { isPrivileged as hasPrivilege } from '../utils/user';
import history from '../history';
import styles from './Page.module.scss';

const PLAYABLE_SORT_ORDER = {
  active: 0,
  staged: 1,
  retired: 2,
  inactive: 3,
};

export default () => {
  const dispatch = useDispatch();
  const eventID = history.location.pathname.match(/(\d+)$/);
  const eventIDMatch = eventID ? eventID[1] : null;
  const playerFilter = history.location.search.match(/(\d+)$/);
  const playerFilterMatch = playerFilter ? playerFilter[1] : null;
  const [isPrintable, setIsPrintable] = useState({});
  const [isPrintAll, setIsPrintAll] = useState('paidOnly');
  const [searchString, setSearchString] = useState('');
  const [selectedPlayer, setSelectedPlayer] = useState(-1);
  const { currentCharacterID, characterStorage, event, eventsRun, user, ui } =
    useSelector(state => ({
      currentCharacterID: state.localStorage.currentCharacterID,
      characterStorage: state.localStorage.characterStorage,
      event: state.eventAdministration,
      eventsRun: state.events.eventsRun,
      user: state.user,
      ui: state.ui.adminPrint,
    }));

  const eventPlayers = playerFilterMatch
    ? event.eventPlayers.map(x => ({
        ...x.user,
        isPaid: x.attendee_characters.some(y => y.payment === 'paid'),
      }))
    : Object.keys(event.attendingCharacters).map(x => ({
        ...event.attendingCharacters[x].user,
        isPaid: event.attendingCharacters[x].attendee_characters.some(
          y => y.payment === 'paid',
        ),
      }));

  const selectedPlayerMetadata = useCallback(() => {
    if (!event.attendingCharacters) return null;
    if (!event.attendingCharacters[playerFilterMatch]) return null;

    const currentUserViewed = event.attendingCharacters[playerFilterMatch].user;

    return [currentUserViewed.first_name, currentUserViewed.last_name]
      .join(' ')
      .trim();
  }, [event.attendingCharacters, playerFilterMatch]);

  const handlePrintToggle = (id, value) => {
    const isPrintableNextState = {
      ...isPrintable,
      [id]: value,
    };

    const maybePrintAll = Object.values(isPrintableNextState).reduce(
      (a, b) => a && b,
      true,
    );
    setIsPrintAll(maybePrintAll);
    setIsPrintable(isPrintableNextState);
  };
  const handleSearchChange = val => setSearchString(val.trim());
  const handlePrintAllChange = evt => {
    setIsPrintable({
      ...isPrintable,
      ...fromPairs(Object.keys(isPrintable).map(x => [x, evt.target.checked])),
    });
    setIsPrintAll(evt.target.checked);
  };
  const handleBackPrintChange = evt =>
    dispatch({ type: 'BACKSHEET_PRINTABLE', payload: evt.target.checked });
  const handlePlayerChange = value => {
    const playerFilterUrl = value === -1 ? '' : `?player_id=${value}`;
    history.push(`/admin_print/${eventIDMatch}${playerFilterUrl}`);
  };
  const renderPlayer = player => (
    <div
      className={[
        styles.playerRow,
        player.id.toString() === playerFilterMatch && styles.highlighted,
      ].join(' ')}
      onClick={() => handlePlayerChange(player.id)}
      key={player.id}
    >
      <div className={styles.playerID}>{player.id}</div>
      <div
        className={[
          styles.playerName,
          !player.isPaid && styles.notYetPaid,
        ].join(' ')}
      >
        {[player.first_name, player.last_name].join(' ').trim()}
      </div>
    </div>
  );
  const renderCurrentPlayer = playerFilterMatch ? (
    <div className={styles.filtered}>
      <div className={styles.playerMetadata}>
        <div>{`Current Player: #${playerFilterMatch}`}</div>
        <div>{selectedPlayerMetadata()}</div>
      </div>
      <div
        className={styles.clearFilter}
        onClick={() => handlePlayerChange(-1)}
      >
        View All Character Sheets
      </div>
    </div>
  ) : (
    <div className={styles.unfiltered}>Player List</div>
  );

  const filteredPlayers =
    searchString.length === 0
      ? eventPlayers
      : searchString.match(/^\d+$/)
      ? eventPlayers.filter(x => x.id.toString().includes(searchString))
      : eventPlayers.filter(x =>
          [x.first_name, x.last_name]
            .join(' ')
            .toLowerCase()
            .includes(searchString.toLowerCase()),
        );

  const filteredPlayerWrapper =
    filteredPlayers.length === 0 ? (
      <div className={styles.noMatch}>
        No matching players. Perhaps they have not checked in?
      </div>
    ) : (
      filteredPlayers.sort((a, b) => a.id - b.id).map(x => renderPlayer(x))
    );
  const isPrivileged = hasPrivilege(user);
  const isEventRunner =
    event &&
    event.eventInfo &&
    isOwnerOrExternal({
      event: {
        eventID: event.eventInfo.id,
        branchID: event.eventInfo.branch ? event.eventInfo.branch.id : null,
      },
      eventsRun,
      user,
    });

  const renderableAttendees = Object.keys(event.attendingCharacters)
    .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
    .filter(x =>
      selectedPlayer === -1 ? x : parseInt(x, 10) === selectedPlayer,
    )
    .map(x => event.attendingCharacters[x]);

  const renderAttendingCharacters = () =>
    renderableAttendees.length === 0 ? (
      <ErrorPage
        errorMessage={`Player ID ${selectedPlayer} has not checked in to this event`}
      />
    ) : (
      renderableAttendees.map(attendee =>
        Object.values(attendee.attendee_characters)
          .filter(x => ['active', 'staged'].includes(x.character.status))
          .sort(
            (a, b) =>
              PLAYABLE_SORT_ORDER[a.character.status] -
                PLAYABLE_SORT_ORDER[b.character.status] ||
              a.character.id - b.character.id,
          )
          .map(attendeeCharacter => (
            <div
              className={[
                styles.row,
                isPrintable[attendeeCharacter.character.id]
                  ? ''
                  : styles.notPrinted,
              ].join(' ')}
              key={attendeeCharacter.character.id}
            >
              <div className={styles.sheet}>
                <Character
                  key={attendeeCharacter.character.id}
                  user={attendee.user}
                  shifts={attendee.shifts}
                  attendeeCharacter={attendeeCharacter}
                  event={event.eventInfo}
                  isPrintable={isPrintable[attendeeCharacter.character.id]}
                />
                <BackSheet
                  isPrintable={isPrintable[attendeeCharacter.character.id]}
                  isBackPrintable={ui.printBackSheet}
                />
              </div>
              <div className={styles.checkbox}>
                <Toggle
                  key={attendeeCharacter.character.id}
                  onChange={evt =>
                    handlePrintToggle(
                      attendeeCharacter.character.id,
                      evt.target.checked,
                    )
                  }
                  checked={
                    isPrintable[attendeeCharacter.character.id] === null
                      ? true
                      : isPrintable[attendeeCharacter.character.id]
                  }
                />
              </div>
            </div>
          )),
      )
    );

  useEffect(() => {
    dispatch({ type: 'APP_LOADED' });
  }, [dispatch]);

  useEffect(() => {
    CharacterLoader({ characterStorage, currentCharacterID, dispatch });
  }, [characterStorage, currentCharacterID, dispatch]);

  useEffect(() => {
    if (!user.session || !user.session.headers) return;
    if (!eventIDMatch) return;
    dispatch({
      type: 'FETCH_ATTENDING_CHARACTERS',
      payload: {
        eventID: eventIDMatch,
        playerFilterID: playerFilterMatch,
      },
    });

    if (playerFilterMatch) {
      dispatch({
        type: 'FETCH_EVENT_PLAYERS',
        payload: {
          eventID: eventIDMatch,
        },
      });
    }
  }, [dispatch, user.session, eventIDMatch, playerFilterMatch]);

  useEffect(() => {
    setIsPrintable(
      fromPairs(
        Object.values(event.attendingCharacters)
          .flatMap(x =>
            x.attendee_characters.map(y => ({
              characterID: y.character.id,
              isPaid: y.payment === 'paid',
              isActive: y.character.status === 'active',
              isStaged: y.character.status === 'staged',
              isOnlyCharacter: x.attendee_characters.length === 1,
            })),
          )
          .map(x => [
            x.characterID,
            isPrintAll === true
              ? true
              : x.isPaid && (x.isActive || (x.isStaged && x.isOnlyCharacter)),
          ]),
      ),
    );
    setSelectedPlayer(playerFilterMatch ? parseInt(playerFilterMatch, 10) : -1);
  }, [event.attendingCharacters, isPrintAll, playerFilterMatch]);

  if (!user.session || !user.session.headers) {
    return <ErrorPage errorMessage='Reading credentials...' />;
  }
  if (!isPrivileged) return <ErrorPage errorType='unauthorized' />;

  if (event.isFetching) {
    return <ErrorPage errorMessage='Fetching Event Data...' />;
  }
  if (event.eventInfo.error)
    return <ErrorPage errorMessage={event.eventInfo.error} />;

  if (!isEventRunner) return <ErrorPage errorType='mismatchedBranch' />;
  if (Object.keys(event.attendingCharacters).length === 0) {
    return <ErrorPage errorMessage='Fetching Attending Characters...' />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.fixedHeader}>
        <div className={styles.eventLinks}>
          <EventLinks />
        </div>
        <div className={styles.controls}>
          <div className={styles.columned}>
            <div className={styles.left}>
              <div className={styles.control}>
                <Toggle
                  checked={isPrintAll === true}
                  onChange={handlePrintAllChange}
                />
                <div className={styles.toggleLabel}>
                  {isPrintAll === 'paidOnly'
                    ? 'Currently Printing Paid Only'
                    : isPrintAll
                    ? 'Currently Printing All'
                    : 'Currently Printing Selectively'}
                </div>
              </div>
              <div className={styles.control}>
                <Toggle
                  checked={ui.printBackSheet}
                  onChange={handleBackPrintChange}
                />
                <div className={styles.toggleLabel}>
                  {ui.printBackSheet
                    ? 'Currently Printing Backsheet'
                    : 'Currently Not Printing Backsheet'}
                </div>
              </div>
            </div>
            <div className={styles.right}>
              <div className={styles.control}>
                <a href='/frontsheet' target='_blank' className={styles.link}>
                  Frontsheet
                </a>
              </div>
              <div className={styles.control}>
                <a href='/backsheet' target='_blank' className={styles.link}>
                  Backsheet
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.boardColumn}>
        <div className={styles.playerList}>
          <div className={styles.title}>{renderCurrentPlayer}</div>
          <SearchBar handleSearchChange={handleSearchChange} />
          <div className={styles.content}>
            <WindowedScrollbar
              styles={styles}
              autoHeightMax='calc(100vh - 272px)'
            >
              {filteredPlayerWrapper}
            </WindowedScrollbar>
          </div>
        </div>
        <div className={styles.characters}>{renderAttendingCharacters()}</div>
      </div>
    </div>
  );
};
