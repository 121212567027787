import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

const defaultTooltip = (title, d) => {
  switch (title) {
    case 'resolve':
    case 'infection':
      return `${d.indexValue} ${title}: ${d.value} characters`;
    default:
      switch (d.indexValue) {
        case '10':
          return `<=${d.indexValue} ${title}: ${d.value} characters`;
        case '100':
          return `101+ ${title}: ${d.value} characters`;
        default:
          return `${d.indexValue - 4}->${d.indexValue} ${title}: ${
            d.value
          } characters`;
      }
  }
};

export default ({
  data,
  title,
  keys,
  indexBy,
  tooltip,
  axisRight,
  margin = {},
  Line,
}) => (
  <ResponsiveBar
    data={data}
    keys={keys}
    indexBy={indexBy}
    tooltip={d => (tooltip ? tooltip(title, d) : defaultTooltip(title, d))}
    defs={[
      {
        id: 'count',
        type: 'linearGradient',
        colors: [
          { offset: 0, color: '#ccc' },
          { offset: 100, color: '#888' },
        ],
      },
      {
        id: 'travel_game',
        type: 'linearGradient',
        colors: [
          { offset: 0, color: '#00858b' },
          { offset: 100, color: '#007a80' },
        ],
      },
      {
        id: 'home_game',
        type: 'linearGradient',
        colors: [
          { offset: 0, color: '#9c5935' },
          { offset: 100, color: '#8f4a26' },
        ],
      },
    ]}
    axisBottom={{
      legend: title,
      legendPosition: 'middle',
      legendOffset: 36,
      tickSize: 5,
    }}
    axisLeft={{
      legend: 'count',
      legendPosition: 'middle',
      legendOffset: -32,
      tickSize: 0,
    }}
    axisRight={axisRight}
    fill={[
      {
        match: {
          id: 'travel_game',
        },
        id: 'travel_game',
      },
      {
        match: {
          id: 'home_game',
        },
        id: 'home_game',
      },
      {
        match: '*',
        id: 'count',
      },
    ]}
    margin={{
      ...{
        top: 10,
        right: 10,
        bottom: 48,
        left: 36,
      },
      ...margin,
    }}
    labelSkipHeight={16}
    labelSkipWidth={16}
    theme={{
      axis: {
        legend: {
          text: {
            fill: '#ccc',
            fontFamily: 'Bellefair',
            fontSize: '14px',
          },
        },
        ticks: {
          text: {
            fill: '#ccc',
            fontFamily: 'Bellefair',
            fontSize: '14px',
          },
        },
      },
      grid: {
        line: {
          stroke: '#555',
          strokeWidth: 1,
        },
      },
      labels: {
        text: {
          fontFamily: 'Bellefair',
        },
      },
      tooltip: {
        container: {
          background: '#333',
          opacity: 0.8,
        },
      },
    }}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
    padding={0.3}
    layers={['grid', 'axes', 'bars', Line, 'markers', 'legends']}
  />
);
