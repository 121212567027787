import {
  characterStorage,
  currentCharacterID,
  currentDataVersion,
  user,
  ui,
} from '../arbiter';
import { initialState as initialUserState } from '../../User/reducer';
import { initialState as initialCharacter } from '../../Character/reducers/index';

const filteredCharacters = version => {
  const jStorage = characterStorage().get();
  const jCharacter = currentCharacterID().get();
  const jDataVersion = parseInt(currentDataVersion().get());
  const filtered = {};
  let preloaded = jCharacter;

  if (
    !jStorage ||
    jDataVersion !== version ||
    Object.keys(jStorage).filter(uuid => jStorage[uuid].state !== 'deleted')
      .length === 0
  ) {
    return {
      characterStorage: null,
      currentCharacterID: null,
    };
  }

  Object.keys(jStorage)
    .filter(uuid => jStorage[uuid].state !== 'deleted')
    .forEach(uuid => {
      filtered[uuid] = {
        ...initialCharacter,
        ...jStorage[uuid],
      };
    });

  if (!(preloaded in filtered)) {
    if (Object.keys(filtered).length === 0) preloaded = null;
    [preloaded] = Object.keys(filtered);
  }

  return {
    characterStorage: filtered,
    currentCharacterID: preloaded,
  };
};

export default currentDataVersion => {
  const filtered = filteredCharacters(currentDataVersion);

  return {
    characterStorage: filtered.characterStorage,
    currentCharacterID: filtered.currentCharacterID,
    okToWrite: true,
    user: { ...initialUserState, ...user().get() },
    ui: ui().get(),
  };
};
