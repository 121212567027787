import { all, put, select, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../Shared/Toastify/Toast';

function* fetchActiveSessions() {
  const user = yield select(state => state.user);
  const authConfig = user.session;

  try {
    const res = yield axios.get(
      `/users/${user.id}/active_sessions`,
      authConfig,
    );
    yield put({
      type: 'FETCH_ACTIVE_SESSIONS_SUCCEEDED',
      payload: res.data,
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* watchFetchActiveSessions() {
  yield takeLatest('FETCH_ACTIVE_SESSIONS', fetchActiveSessions);
}

export default function* () {
  yield all([watchFetchActiveSessions()]);
}
