import React from 'react';
import styles from './Control.module.scss';
import InputBase from '@material-ui/core/InputBase';

export default ({
  innate,
  acquired,
  passChange,
  passSubtract,
  passAdd,
  isIncrementable,
  isDecrementable,
  swapPosition,
  invertColor,
  children,
}) => {
  const handleSelectAllText = event => {
    event.target.select();
    event.stopPropagation();
  };
  const fixedSection = () => <span>{innate}</span>;
  const inputSection = () => (
    <InputBase
      className={styles.acq}
      type='number'
      value={acquired}
      onChange={passChange}
      onClick={handleSelectAllText}
    />
  );
  const renderSection = side =>
    side === 'left'
      ? swapPosition
        ? inputSection()
        : fixedSection()
      : swapPosition
      ? fixedSection()
      : inputSection();

  return (
    <div className={styles.container}>
      <div
        className={[
          styles.statControl,
          styles.left,
          isDecrementable ? '' : styles.disabled,
          invertColor ? styles.invert : '',
        ].join(' ')}
        onClick={passSubtract}
      >
        <span>&laquo;</span>
      </div>
      <div
        className={[styles.statLead, invertColor ? styles.invert : ''].join(
          ' ',
        )}
      >
        {renderSection('left')}
        <span>&nbsp;+&nbsp;</span>
        {renderSection('right')}
        {children}
      </div>
      <div
        className={[
          styles.statControl,
          styles.right,
          isIncrementable ? '' : styles.disabled,
          invertColor ? styles.invert : '',
        ].join(' ')}
        onClick={passAdd}
      >
        <span>&raquo;</span>
      </div>
    </div>
  );
};
