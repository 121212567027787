import React, { useEffect, useState } from 'react';
import { isEqual, pickBy } from 'lodash';
import TextField from '../../Shared/FormComponents/TextField';
import DropdownField from '../../Shared/FormComponents/DropdownField';
import TextArea from '../../Shared/FormComponents/TextArea';
import TransferHistory from '../../Shared/TransferHistory';
import sortedBranches from '../../utils/branches';
import { isPrivileged as hasPrivilege } from '../../utils/user';
import uuid from 'uuid';
import styles from './BioData.module.scss';

export default ({
  user,
  passUpdate,
  isUpdating,
  isError,
  fragment,
  nonceID,
  rootUser,
}) => {
  const isPrivileged = hasPrivilege(rootUser);
  const [latchedUser, setLatchedUser] = useState({});
  const dirty = !isEqual(user, latchedUser);
  const handleUpdate = () =>
    dirty && passUpdate(pickBy(latchedUser, (v, k) => !isEqual(v, user[k])));

  const handleChange = (field, value) =>
    setLatchedUser({
      ...latchedUser,
      [field]: field === 'branchId' ? parseInt(value, 10) : value,
    });
  const hasValidEmail =
    latchedUser &&
    latchedUser.emailAddress &&
    latchedUser.emailAddress.match(/.+@\w+\.\w+/);
  const hasValidLastName =
    latchedUser &&
    latchedUser.lastName &&
    latchedUser.lastName.trim().length > 0;
  const hasError = !hasValidEmail || !hasValidLastName;

  useEffect(() => {
    setLatchedUser(user);
  }, [nonceID, fragment]); // eslint-disable-line react-hooks/exhaustive-deps

  const buttonText = () => {
    if (isError) return 'Error. Retry?';
    if (isUpdating) return 'Updating...';
    return dirty ? 'Update Biodata' : 'Up to Date';
  };
  return (
    <div className={styles.container}>
      <TextField
        title='First Name'
        fieldKey='firstName'
        value={latchedUser.firstName || ''}
        initialValue={user.firstName || ''}
        passChange={handleChange}
      />
      <TextField
        title='Last Name'
        fieldKey='lastName'
        value={latchedUser.lastName || ''}
        initialValue={user.lastName || ''}
        passChange={handleChange}
      />
      {!hasValidLastName && (
        <div className={styles.newPasswordMismatch}>
          Error: Last Name can not be blank
        </div>
      )}
      <TextField
        title='Email'
        fieldKey='emailAddress'
        value={latchedUser.emailAddress || ''}
        initialValue={user.emailAddress || ''}
        passChange={handleChange}
      />
      {!hasValidEmail && (
        <div className={styles.newPasswordMismatch}>
          Error: invalid Email format
        </div>
      )}
      {isPrivileged && (
        <React.Fragment>
          <DropdownField
            hidden={['branch_owner', 'admin'].includes(user.role)}
            title='Role'
            fieldKey='role'
            value={latchedUser.role || 'regular'}
            options={[
              { value: 'guide', label: 'Guide' },
              { value: 'regular', label: 'Regular Player' },
            ]}
            initialValue={user.role}
            passChange={handleChange}
          />
          <DropdownField
            title='Home Branch'
            fieldKey='branchId'
            value={latchedUser.branchId || 0}
            options={sortedBranches()}
            initialValue={user.branchId}
            passChange={handleChange}
          />
        </React.Fragment>
      )}
      <TransferHistory
        data={user.branchTransfers}
        genesisDate={user.genesisDate}
      />
      <div className={styles.nonEditableNotes}>
        <div className={styles.header}>Notes</div>
        {isPrivileged ? (
          <div>
            <div className={styles.obsolescence}>
              Notice: Virtual Event Loot should go to Event Logs.
            </div>
            <TextArea
              content={latchedUser.notes || ''}
              placeholder='Use this space to record Player-level information. This Notes will not appear on Character Sheet Printouts. Player can see this Notes.'
              handleUpdate={evt => handleChange('notes', evt.target.value)}
            />
          </div>
        ) : (
          <div className={styles.content}>
            {latchedUser.notes
              ? latchedUser.notes.split('\n').map(x => (
                  <span key={uuid.v1()}>
                    {x}
                    <br />
                  </span>
                ))
              : 'No Notes Recorded'}
          </div>
        )}
      </div>
      <div className={styles.updateContainer}>
        <div
          className={[
            styles.button,
            (isUpdating || !dirty || hasError) && styles.disabled,
            isError && styles.error,
          ].join(' ')}
          onClick={handleUpdate}
        >
          {buttonText()}
        </div>
      </div>
    </div>
  );
};
