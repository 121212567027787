import React from 'react';
import styles from './ExternalManagers.module.scss';

export default ({ managers, passRemove, isExternalRunner }) => {
  const isRealUser = id => managers[id].user;

  if (Object.keys(managers).length === 0)
    return 'No External Managers for this Event';
  return Object.keys(managers).map(managerID => (
    <div key={managerID} className={styles.row}>
      <div className={styles.managerText}>
        {managers[managerID].user || managers[managerID].message}
      </div>
      {isExternalRunner ? null : (
        <div
          className={styles.remove}
          onClick={() => passRemove(managerID, !isRealUser(managerID))}
        >
          {isRealUser(managerID) ? 'Remove' : 'Dismiss'}
        </div>
      )}
    </div>
  ));
};
