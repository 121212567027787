import { fromPairs } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  characterStorage as csd,
  currentCharacterID as ccd,
  currentDataVersion as cdv,
  ui as uid,
  user as udd,
} from './arbiter';

const Daemon = () => {
  const dispatch = useDispatch();
  const [latchedUserID, setLatchedUserID] = useState(null);
  const [latchedImpersonateID, setLatchedImpersonateID] = useState(null);
  const [latchedRole, setLatchedRole] = useState(null);
  const {
    currentCharacterID,
    currentDataVersion,
    characterStorage,
    okToWrite,
    monitoredUser,
    downstreamUI,
    monitoredUI,
  } = useSelector(state => ({
    currentCharacterID: state.localStorage.currentCharacterID,
    currentDataVersion: state.localStorage.currentDataVersion,
    characterStorage: state.localStorage.characterStorage,
    okToWrite: state.localStorage.okToWrite,
    monitoredUser: state.localStorage.user,
    downstreamUI: state.ui,
    monitoredUI: state.localStorage.ui,
  }));

  useEffect(() => {
    if (!okToWrite) return;
    if (!characterStorage) return;
    if (currentCharacterID === null) return;
    ccd().set(currentCharacterID);
    csd().set({
      ...characterStorage,
      [currentCharacterID]: characterStorage[currentCharacterID],
    });
  });

  useEffect(() => {
    if (!okToWrite) return;
    cdv().set(currentDataVersion);
  }, [currentDataVersion, okToWrite]);

  useEffect(() => {
    if (!okToWrite) return;

    // use deep comparison to prevent render loop
    if (
      latchedUserID !== monitoredUser.id ||
      latchedRole !== monitoredUser.role
    ) {
      setLatchedUserID(monitoredUser.id);
      setLatchedRole(monitoredUser.role);
      dispatch({
        type: 'SYNC_USER',
        payload: monitoredUser,
      });
    } else {
      dispatch({
        type: 'SYNC_USER_SHALLOW', // hack: prevent refetch whole player object
        payload: monitoredUser,
      });
    }

    udd().set(monitoredUser);
    if (!monitoredUser.session) dispatch({ type: 'CLEANUP_PREVIOUS_SESSION' });
  }, [monitoredUser, okToWrite, dispatch, latchedUserID, latchedRole]);

  useEffect(() => {
    if (!okToWrite) return;
    if (latchedImpersonateID !== monitoredUser.impersonatee.id) {
      udd().set(monitoredUser);
      setLatchedImpersonateID(monitoredUser.impersonatee.id);
      dispatch({
        type: 'SYNC_IMPERSONATEE',
        payload: monitoredUser,
      });
    }
  }, [monitoredUser, okToWrite, dispatch, latchedImpersonateID]);

  useEffect(() => {
    if (!okToWrite) return;
    uid().set(
      fromPairs(
        Object.keys(downstreamUI)
          .filter(x =>
            [
              'skills',
              'selectedBranches',
              'adminPrint',
              'mods',
              'modSections',
            ].includes(x),
          )
          .map(x => [x, downstreamUI[x]]),
      ),
    );
  }, [downstreamUI, okToWrite]);

  useEffect(() => {
    if (!okToWrite) return;
    if (!monitoredUI) return;
    dispatch({
      type: 'SYNC_UI',
      payload: monitoredUI,
    });
  }, [monitoredUI, okToWrite, dispatch]);

  return null;
};

export default React.memo(Daemon);
