export default {
  167: 'animals',
  168: 'faith',
  171: 'mortisAmaranthine',
  169: 'lineages',
  170: 'medical',
  172: 'nature',
  173: 'postFallHistory',
  174: 'preFallHistory',
  175: 'raiders',
  176: 'undead',
  177: 'wastelandScience',
  178: 'aberrants',
};
