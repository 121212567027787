export const isAdequateCastingShifts = (shifts, minimumCount) =>
  isSpecialShiftSelected(shifts) ||
  selectedCastingShiftsCount(shifts) >= minimumCount;

export const selectedCastingShiftsCount = shifts =>
  Object.keys(shifts)
    .filter(x => shifts[x].isAttendeeSelected)
    .filter(x => shifts[x].kind === 'casting').length;

export const isCleanupShiftSelected = shifts =>
  Object.keys(shifts)
    .filter(x => shifts[x].isAttendeeSelected)
    .filter(x => shifts[x].kind === 'cleanup').length > 0;

export const isSpecialShiftSelected = shifts =>
  Object.keys(shifts)
    .filter(x => shifts[x].isAttendeeSelected)
    .some(x => shifts[x].kind === 'special');

export const isValidGuideInvitation = (selectedTicket, inviter) =>
  selectedTicket !== 1 ||
  (selectedTicket === 1 &&
    inviter &&
    inviter.id &&
    ['guide', 'employee', 'admin', 'branch_owner'].includes(inviter.role));

export const responsibilitiesSatisfied = ({
  tickets,
  selectedTicket,
  shifts,
  isAdvanced,
  isEventRunner,
  selectedCharacter,
  inviter,
}) => {
  if (!(tickets && tickets[selectedTicket] && shifts) || selectedTicket < 0)
    return false;
  if (isEventRunner) return true;
  if (selectedTicket >= 500)
    return isAdvanced || (!isAdvanced && selectedCharacter !== -1);

  return (
    !tickets[selectedTicket].isCastingOnly &&
    isCleanupShiftSelected(shifts) &&
    (isAdvanced || (!isAdvanced && selectedCharacter !== -1)) &&
    isAdequateCastingShifts(
      shifts,
      tickets[selectedTicket].shiftResponsibility,
    ) &&
    isValidGuideInvitation(selectedTicket, inviter)
  );
};

export const totalTicketCost = ({
  tickets,
  selectedTicket,
  buildBoost,
  isEventRunner,
  isNational,
  isPremiere,
  isVirtual,
  eventConfig,
}) =>
  (tickets && selectedTicket && tickets[selectedTicket]) !== undefined
    ? isEventRunner
      ? 0
      : tickets[selectedTicket].cost +
        buildBoostCost({
          buildBoost,
          isEventRunner,
          isNational,
          isPremiere,
          isVirtual,
          eventConfig,
        })
    : null;

export const buildBoostCost = ({
  buildBoost,
  isNational,
  isPremiere,
  isEventRunner,
  isVirtual,
  eventConfig,
}) => {
  if (isEventRunner) return 0;
  if (isNational)
    return (eventConfig.extra_xp_cost || (isVirtual ? 7.5 : 10)) * buildBoost;
  if (isPremiere) {
    if (eventConfig.extra_xp_cost) {
      return buildBoost * eventConfig.extra_xp_cost;
    }

    return buildBoost <= 5 ? 10 * buildBoost : 50 + (buildBoost - 5) * 15;
  }
  if (isVirtual) return (eventConfig.extra_xp_cost || 15) * buildBoost;
  return 15 * buildBoost;
};
