export default {
  1: { shortName: 'SoCal' },
  2: { shortName: 'WA' },
  3: { shortName: 'NJ' },
  4: { shortName: 'MA' },
  5: { shortName: 'TX' },
  6: { shortName: 'PA' },
  7: { shortName: 'IN' },
  8: { shortName: 'FL' },
  9: { shortName: 'TO' },
  10: { shortName: 'HI' },
  11: { shortName: 'AB' },
  12: { shortName: 'PNW' },
  13: { shortName: 'Natl' },
  14: { shortName: 'VA' },
  15: { shortName: 'KY' },
  16: { shortName: 'WI' },
  17: { shortName: 'CO' },
  18: { shortName: 'AR' },
  19: { shortName: 'OK' },
  20: { shortName: 'NY' },
  21: { shortName: 'NM' },
  22: { shortName: 'OH' },
  23: { shortName: 'NorCal' },
  24: { shortName: 'GA' },
  25: { shortName: 'MI' },
  29: { shortName: 'CT' },
  30: { shortName: 'IL' },
  31: { shortName: 'MD' },
  32: { shortName: 'PSW' },
  33: { shortName: 'ANV' },
};
