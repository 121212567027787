import React, { useState } from 'react';
import styles from './Checkout.module.scss';

export default ({ event, impersonate }) => {
  const [selectedAttendeeID, setSelectedAttendeeID] = useState(null);
  const renderableAttendees = Object.values(event.eventAttendees).sort(
    (a, b) => a.playerID - b.playerID,
  );

  const renderAttendee = attendee => (
    <div
      className={[
        styles.attendeeRow,
        selectedAttendeeID === attendee.playerID ? styles.highlighted : '',
      ].join(' ')}
      key={attendee.playerID}
      onClick={() => {
        impersonate(attendee.playerID);
        setSelectedAttendeeID(attendee.playerID);
      }}
    >
      <div className={styles.playerID}>{attendee.playerID}</div>
      <div className={styles.playerName}>{attendee.name}</div>
    </div>
  );
  const renderAttendees = () =>
    renderableAttendees.length === 0 ? (
      <div className={styles.noCheckout}>No Players to Checkout</div>
    ) : (
      renderableAttendees.map(x => renderAttendee(x))
    );

  const renderEventInfo = () => (
    <div className={styles.eventInfo}>
      <div className={styles.title}>Event Checkout</div>
      <div className={styles.eventName}>{event.eventInfo.name}</div>
    </div>
  );

  return (
    <div className={styles.container}>
      {renderEventInfo()}
      <div className={styles.eventAttendees}>{renderAttendees()}</div>
    </div>
  );
};
