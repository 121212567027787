export const initialState = null;

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'TOKEN_VERIFIED':
      return payload;
    case 'TOKEN_INVALID':
      return 'invalid';
    case 'PASSWORD_UPDATED':
      return 'passwordUpdated';
    default:
      return state;
  }
};
