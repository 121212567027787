import { combineReducers } from 'redux';
import approvedForPlay, {
  initialState as initialApprovedForPlay,
} from './approvedForPlay';
import branch, { initialState as initialBranch } from './branch';
import communityEmpowermentAchievements, {
  initialState as initialCommunityEmpowermentAchievements,
} from './communityEmpowermentAchievements';
import createdAt, { initialState as initialCreatedAt } from './createdAt';
import dirtyTracking, {
  initialState as initialDirtyTracking,
} from './dirtyTracking';
import diseases, { initialState as initialDiseases } from './diseases';
import eventsAttended, {
  initialState as initialEventsAttended,
} from './eventsAttended';
import extraBuilds, { initialState as initialExtraBuilds } from './extraBuilds';
import faith, { initialState as initialFaith } from './faith';
import fractures, { initialState as initialFractures } from './fractures';
import homeGameAttendances, {
  initialState as initialHomeGameAttendances,
} from './homeGameAttendances';
import homeGamesAttended, {
  initialState as initialHomeGamesAttended,
} from './homeGamesAttended';
import legacyDatum, { initialState as initialLegacyDatum } from './legacyDatum';
import legacyHomeGames, {
  initialState as initialLegacyHomeGames,
} from './legacyHomeGames';
import lores, { initialState as initialLores } from './lores';
import name, { initialState as initialName } from './name';
import notes, { initialState as initialNotes } from './notes';
import playerNotes, { initialState as initialPlayerNotes } from './playerNotes';
import remoteID, { initialState as initialRemoteID } from './remoteID';
import remotePersistance, {
  initialState as initialRemotePersistance,
} from './remotePersistance';
import remotePlayerID, {
  initialState as initialRemotePlayerID,
} from './remotePlayerID';
import remoteStatus, {
  initialState as initialRemoteStatus,
} from './remoteStatus';
import saveState, { initialState as initialSaveState } from './state';
import skills, { initialState as initialSkills } from './skills';
import societies, { initialState as initialSocieties } from './societies';
import stats, { initialState as initialStats } from './stats';
import strain, { initialState as initialStrain } from './strain';
import totalBuild, { initialState as initialTotalBuild } from './totalBuild';
import travelGameAttendances, {
  initialState as initialTravelGameAttendances,
} from './travelGameAttendances';

export const initialState = {
  approvedForPlay: initialApprovedForPlay,
  branch: initialBranch,
  communityEmpowermentAchievements: initialCommunityEmpowermentAchievements,
  createdAt: initialCreatedAt,
  dirtyTracking: initialDirtyTracking,
  diseases: initialDiseases,
  eventsAttended: initialEventsAttended,
  extraBuilds: initialExtraBuilds,
  faith: initialFaith,
  fractures: initialFractures,
  homeGameAttendances: initialHomeGameAttendances,
  homeGamesAttended: initialHomeGamesAttended,
  legacyDatum: initialLegacyDatum,
  legacyHomeGames: initialLegacyHomeGames,
  lores: initialLores,
  name: initialName,
  notes: initialNotes,
  playerNotes: initialPlayerNotes,
  remoteID: initialRemoteID,
  remotePersistance: initialRemotePersistance,
  remotePlayerID: initialRemotePlayerID,
  remoteStatus: initialRemoteStatus,
  skills: initialSkills,
  societies: initialSocieties,
  state: initialSaveState,
  stats: initialStats,
  strain: initialStrain,
  totalBuild: initialTotalBuild,
  travelGameAttendances: initialTravelGameAttendances,
};

export default combineReducers({
  approvedForPlay,
  branch,
  extraBuilds,
  createdAt,
  dirtyTracking,
  communityEmpowermentAchievements,
  diseases,
  eventsAttended,
  faith,
  fractures,
  homeGameAttendances,
  homeGamesAttended,
  legacyDatum,
  legacyHomeGames,
  lores,
  name,
  notes,
  playerNotes,
  remoteID,
  remotePersistance,
  remotePlayerID,
  remoteStatus,
  skills,
  societies,
  state: saveState,
  stats,
  strain,
  totalBuild,
  travelGameAttendances,
});
