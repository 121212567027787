import { useSelector, useDispatch } from 'react-redux';
import React from 'react';
import SkillGrid from './Grid/Bar';
import styles from './SkillQuad.module.scss';
import skillCalc from '../Grid/calc';
import { isEditable as hasEditPrivilege } from '../../../utils/user';

function SkillQuad(props) {
  const dispatch = useDispatch();
  const {
    popper,
    skillCategories,
    skillCategoriesShown,
    skillInfoShown,
    skillPersisted,
    approvedForPlay,
    user,
  } = useSelector(state => ({
    popper: state.ui.character.popperOpened,
    skillCategories: state.character.skills.categories,
    skillCategoriesShown: state.ui.skills.categoriesShown,
    skillInfoShown: state.ui.skills.infoShown,
    skillPersisted: state.character.remotePersistance.skills,
    approvedForPlay: state.character.approvedForPlay,
    user: state.user,
  }));

  const isEditable = hasEditPrivilege(user, approvedForPlay);
  const categories = ['combat', 'civilized', 'wasteland', 'anomaly'];

  const placement = {
    combat: 'top-start',
    wasteland: 'top',
    civilized: 'top',
    anomaly: 'top-end',
  };

  const handlePopperToggle = category =>
    dispatch({ type: 'TOGGLE_POPPER', payload: category });
  const handleSkillVisibilityToggle = category => {
    dispatch({
      type: 'TOGGLE_SKILL_CATEGORY_VISIBILITY',
      payload: category,
    });
  };
  const persistedSkillBuild = skillCalc(skillPersisted);

  const generateSkillGrids = () =>
    categories.map(category => (
      <SkillGrid
        key={category}
        category={category}
        data={skillCategories[category]}
        passPopperToggle={handlePopperToggle}
        passSkillVisibilityToggle={handleSkillVisibilityToggle}
        placement={placement[category]}
        openState={skillInfoShown[category]}
        toggleState={!skillCategoriesShown[category]}
        popper={popper}
        isEditable={isEditable}
        persistedBuild={persistedSkillBuild[category].totalBuild}
      />
    ));

  return <div className={styles.skillquad}>{generateSkillGrids()}</div>;
}

export default React.memo(SkillQuad);
