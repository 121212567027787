import { SYNC_BUFFER } from '../types';

export const initialState = {
  stats: {},
  skills: {},
  lores: {},
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SYNC_BUFFER:
      return payload.remotePersistance;
    default:
      return state;
  }
};
