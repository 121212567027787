import { toRemoteFaith } from './transformFaith';
import { toRemoteLores } from './transformLores';
import { toRemoteSkills } from './transformSkills';
import { toRemoteStat } from './transformStat';
import { toRemoteStrain } from './transformStrain';

export default character => {
  const batchedPayload = {};
  const statMap = {
    body: 'body',
    mind: 'mind',
    resolve: 'resolve',
    infection: 'infection',
  };

  const currentStrain = character.strain;
  const skillTrees = character.skills.trees;
  const stats = character.stats;
  const faith = character.faith;
  const lores = character.lores;
  const notes = character.notes;
  const playerNotes = character.playerNotes;
  const name = character.name;

  batchedPayload.strain_id = toRemoteStrain(currentStrain);
  batchedPayload.faith_id = toRemoteFaith(faith);
  batchedPayload.skills_id = toRemoteSkills(skillTrees);
  batchedPayload.lores_id = toRemoteLores(lores);
  batchedPayload.notes = notes;
  batchedPayload.player_notes = playerNotes;
  batchedPayload.name = name;
  Object.assign(batchedPayload, toRemoteStat(statMap, stats));

  return batchedPayload;
};
