import React from 'react';
import styles from './ItemDescriptions.module.scss';

export default ({ itemData }) => {
  const descriptions =
    itemData && itemData.description ? JSON.parse(itemData.description) : {};
  const textualized = Object.keys(descriptions).map(key => (
    <div key={key}>
      <div className={styles.descriptionLead}>{key}</div>
      <div className={styles.descriptionEntry}>{descriptions[key]}</div>
    </div>
  ));

  return <div>{textualized}</div>;
};
