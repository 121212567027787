import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import styles from './TaxonomySelect.module.scss';

export default ({ value, onChange, itemTaxonomies, taxonomyName }) => {
  const baseOption = [{ value: -1, label: ` -- Select ${taxonomyName} -- ` }];
  const options = baseOption.concat(
    (itemTaxonomies[taxonomyName] || []).map(x => ({
      value: x.id,
      label: x.name,
    })),
  );

  return (
    <Select
      autoWidth
      disableUnderline
      className={[styles.taxonomySelect, value === -1 && styles.blinking].join(
        ' ',
      )}
      classes={{
        select: styles.select,
        icon: styles.icon,
      }}
      MenuProps={{ className: styles.menu }}
      value={value}
      onChange={evt => onChange(evt.target.value)}
    >
      {options
        .sort((a, b) => a.label.localeCompare(b.label))
        .map(x => (
          <MenuItem key={x.value} value={x.value} className={styles.option}>
            {x.label}
          </MenuItem>
        ))}
    </Select>
  );
};
