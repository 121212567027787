import { SYNC_BUFFER } from '../types';

export const initialState = [];

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SYNC_BUFFER:
      return payload.fractures;
    case 'ADD_FRACTURE_SUCCEEDED':
      return state.concat(payload);
    case 'UPDATE_FRACTURE_SUCCEEDED':
      return state.map(x => {
        if (x.id === payload.id) {
          return { ...x, name: payload.name };
        }

        return x;
      });

    case 'TOGGLE_FRACTURE':
      return state.map(x => {
        if (x.id === payload.id) {
          return {
            ...x,
            removed_at:
              payload.value === 'undo' ? null : new Date().toISOString(),
          };
        }

        return x;
      });
    case 'TOGGLE_FRACTURE_SUCCEEDED':
      return state.map(x => {
        if (x.id === payload.id) return payload;
        return x;
      });
    default:
      return state;
  }
};
