import { fork } from 'redux-saga/effects';
import activeSessionsSaga from './ActiveSessions/saga';
import authSaga from './User/actions/generateToken';
import branchesSaga from './Branches/saga';
import eventsRunSaga from './User/actions/eventsRun';
import itemsSaga from './Items/saga';
import searchSaga from './User/actions/searchUser';
import locationSaga from './Branches/locationSaga';
import loginSaga from './User/actions/createSession';
import logoutSaga from './User/actions/deleteSession';
import resetPasswordSaga from './User/actions/resetPassword';
import staffsSaga from './Staffs/saga';
import updateUserSaga from './User/actions/updateUser';
import characterSaga from './Character/saga';
import gameDataSaga from './gameData/saga';
import eventsSaga from './Events/saga';
import eventAdministrationSaga from './Events/Administration/saga';
import eventSetupSaga from './Events/Setup/saga';
import eventCheckinSaga from './Events/Checkin/saga';
import eventStatisticsSaga from './Events/Statistics/saga';

export default function* rootSaga() {
  yield fork(activeSessionsSaga);
  yield fork(authSaga);
  yield fork(branchesSaga);
  yield fork(eventsRunSaga);
  yield fork(locationSaga);
  yield fork(loginSaga);
  yield fork(logoutSaga);
  yield fork(characterSaga);
  yield fork(itemsSaga);
  yield fork(eventsSaga);
  yield fork(eventAdministrationSaga);
  yield fork(eventSetupSaga);
  yield fork(eventCheckinSaga);
  yield fork(eventStatisticsSaga);
  yield fork(gameDataSaga);
  yield fork(resetPasswordSaga);
  yield fork(searchSaga);
  yield fork(staffsSaga);
  yield fork(updateUserSaga);
}
