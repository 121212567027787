import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import styles from './Container.module.scss';
import MonthSpace from './MonthSpace';
import { visibleDatePoints, visibleDateRange } from '../../utils/events';

export default ({
  data,
  isLoggedIn,
  isGuide,
  isPrivileged,
  isAdmin,
  ownedBranches,
  homeBranchID,
  selectedBranches,
  eventsCheckedIn,
  eventsExternalRun,
}) => {
  const [datePoints, setDatePoints] = useState(visibleDatePoints({}));
  const { popper } = useSelector(state => ({
    popper: state.ui.character.popperOpened,
  }));

  const renderRanges = () =>
    visibleDateRange(datePoints.start, datePoints.end).map(x => (
      <MonthSpace
        key={`${x.year}-${x.month}`}
        year={x.year}
        month={x.month + 1}
        data={data}
        popper={popper}
        isLoggedIn={isLoggedIn}
        isGuide={isGuide}
        isPrivileged={isPrivileged}
        isAdmin={isAdmin}
        homeBranchID={homeBranchID}
        ownedBranches={ownedBranches}
        selectedBranches={selectedBranches}
        eventsCheckedIn={eventsCheckedIn}
        eventsExternalRun={eventsExternalRun}
      />
    ));
  const advanceDate = () =>
    setDatePoints(
      visibleDatePoints({
        start: datePoints.start,
        end: datePoints.end,
        adjustment: 3,
      }),
    );

  const regressDate = () =>
    setDatePoints(
      visibleDatePoints({
        start: datePoints.start,
        end: datePoints.end,
        adjustment: -1,
      }),
    );

  renderRanges();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.wide}>
          <div className={styles.control} onClick={regressDate}>
            Previous Month
          </div>
        </div>
        {renderRanges()}
        <div className={styles.wide}>
          <div className={styles.control} onClick={advanceDate}>
            Next 3 Months
          </div>
        </div>
        <div style={{ height: '64px' }} />
      </div>
    </div>
  );
};
