export default eventInfo => ({
  id: eventInfo.id,
  name: eventInfo.name,
  registrationOpensAt: eventInfo.registration_opens_at,
  kind: eventInfo.kind,
  startsAt: eventInfo.starts_at,
  endsAt: eventInfo.ends_at,
  branch: eventInfo.branch,
  isPremiere: ['premiere', 'national_premiere'].includes(eventInfo.kind),
  isNational: ['national', 'national_premiere'].includes(eventInfo.kind),
  isVirtual: eventInfo.location.region === 'VIRTUAL',
  baseBuild: eventInfo.base_build,
  location: eventInfo.location,
  nextEvent: eventInfo.next_event,
  prevEvent: eventInfo.prev_event,
  tickets: eventInfo.tickets,
  config: eventInfo.config,
});
