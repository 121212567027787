import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { startCase } from 'lodash';
import { compareAsc, formatDistanceToNow, parseISO } from 'date-fns';
import useInterval from '@use-it/interval';
import Toggle from 'react-toggle';
import TransactionGroup from './TransactionGroup';
import Dispensary from './Dispensary';
import ChevronDown from '@material-ui/icons/KeyboardArrowDown';
import ChevronUp from '@material-ui/icons/KeyboardArrowUp';
import Absent from '@material-ui/icons/LocationOff';
import Present from '@material-ui/icons/LocationOn';
import Badge from '../../Shared/Badge';
import history from '../../history';
import styles from './Lockbox.module.scss';

const REFRESH_RATE_MS = 10000;

export default ({
  isPostie,
  eventIndependent,
  overrideUserId,
  isPrivileged,
}) => {
  const dispatch = useDispatch();
  const [deltaUpdate, setDeltaUpdate] = useState('Fetching data...');
  const [lastUpdateTimestamp, setLastUpdateTimeStamp] = useState(Date.now());
  const [userId, setUserId] = useState('');
  const { transaction, transactionPartners, lockboxes } = useSelector(
    state => ({
      transaction: state.eventAdministration.transaction,
      transactionPartners: state.eventAdministration.transactionPartners,
      lockboxes: state.eventAdministration.lockboxes,
    }),
  );
  const eventIdMatch = history.location.pathname.match(
    /(admin|audit)_transactions\/(\d+)/,
  );
  const eventId = eventIdMatch ? eventIdMatch[1] : null;
  const playerIdUrlMatch = history.location.pathname.match(/lockboxes\/(\d+)/);
  const playerIdUrl = playerIdUrlMatch ? playerIdUrlMatch[1] : null;
  const highlightItemIdMatch = history.location.search.match(
    /highlightItemId=(\d+)/,
  );
  const highlightItemId = highlightItemIdMatch ? highlightItemIdMatch[1] : null;
  const highlightCharacterIdMatch = history.location.search.match(
    /highlightCharacterId=(\d+)/,
  );
  const highlightCharacterId = highlightCharacterIdMatch
    ? highlightCharacterIdMatch[1]
    : null;
  const transactionPartnersData = transactionPartners.data;
  const [user, setUser] = useState({});
  const [characters, setCharacters] = useState({});
  const [showZeroQuantity, setShowZeroQuantity] = useState(false);
  const { inventories, items, registeredItems, collapses } = lockboxes;
  const [pendingDispensaryItem, setPendingDispensaryItem] = useState(null);

  const dispatchSearch = useCallback(userId => {
    dispatch({
      type: 'FETCH_TRANSACTION_PARTNERS',
      payload: { userId, eventIndependent },
    });
  }, []);

  const performSearch = useCallback(() => {
    if (userId.trim().length === 0) return;

    dispatchSearch(userId);
  }, [userId]);

  const toggleCharacterInventoryDisplay = useCallback(
    characterId => {
      dispatch({
        type: 'TOGGLE_CHARACTER_INVENTORY',
        payload: {
          characterId,
          value: !collapses[characterId],
        },
      });
    },
    [collapses],
  );

  const fetchTransactions = useCallback(
    ({ itemId, characterId, registeredItemId, augmentations }) => {
      const baseAccessorId = `${itemId}-${registeredItemId}`;
      const accessorId =
        augmentations.length > 0
          ? `${baseAccessorId}-${augmentations}`
          : baseAccessorId;

      dispatch({
        type: 'FETCH_ITEM_TRANSACTIONS',
        payload: { itemId, characterId, accessorId },
      });
    },
    [],
  );

  const dispense = useCallback(
    ({
      itemId,
      characterId,
      registeredItemId,
      augmentations,
      dispenseAmount,
    }) => {
      if (!eventId) return;

      setPendingDispensaryItem({
        itemId,
        characterId,
        registeredItemId,
        augmentations,
      });

      dispatch({
        type: 'EXECUTE_TRANSACTION',
        payload: {
          description: 'From Dispensary',
          transactions: [
            {
              item_id: itemId,
              character_id: characterId,
              stack: dispenseAmount,
              augmentations: augmentations,
              registered_item_id: registeredItemId,
            },
          ],
        },
      });
    },
    [eventId],
  );

  const hideOrFetchTransactions = useCallback(
    ({ itemId, characterId, registeredItemId, augmentations, isExpanded }) => {
      const baseAccessorId = `${itemId}-${registeredItemId}`;
      const accessorId =
        augmentations.length > 0
          ? `${baseAccessorId}-${augmentations}`
          : baseAccessorId;

      if (isExpanded) {
        dispatch({
          type: 'HIDE_ITEM_TRANSACTION',
          payload: { itemId, characterId, accessorId },
        });
      } else {
        fetchTransactions({
          itemId,
          characterId,
          registeredItemId,
          augmentations,
        });
      }
    },
    [fetchTransactions],
  );

  const renderItemTransaction = ({
    txn,
    highlightItemId,
    highlightCharacterId,
  }) => {
    const rootTransaction = txn.filter(
      x => x.parent_transaction_id === null,
    )[0];
    const leafTransactions = txn.filter(x => x.parent_transaction_id !== null);

    return (
      <TransactionGroup
        key={rootTransaction.id}
        txn={rootTransaction}
        leafTransactions={leafTransactions}
        highlightItemId={highlightItemId}
        highlightCharacterId={highlightCharacterId}
        eventId={eventId}
        isLegit={rootTransaction.status === 'legit'}
        showEventName
        isPrivileged={isPrivileged}
      />
    );
  };

  const isHighlightInventoryReady = useMemo(() => {
    return (
      highlightCharacterId &&
      highlightItemId &&
      inventories &&
      inventories[highlightCharacterId] &&
      inventories[highlightCharacterId][highlightItemId] &&
      inventories[highlightCharacterId][highlightItemId].item_id
    );
  }, [inventories, highlightCharacterId, highlightItemId]);

  const renderInventory = characterId =>
    Object.keys(inventories[characterId]).length === 0
      ? 'No Recorded Inventory'
      : Object.keys(inventories[characterId])
          .filter(concatItemId =>
            showZeroQuantity
              ? concatItemId
              : inventories[characterId][concatItemId].stack !== 0,
          )
          .sort((a, b) => {
            const [itemIdA] = a.split('-');
            const [itemIdB] = b.split('-');

            const itemA = items[itemIdA];
            const itemB = items[itemIdB];

            if (itemA.kind === itemB.kind) {
              return itemA.name.localeCompare(itemB.name);
            }

            return itemA.kind.localeCompare(itemB.kind);
          })
          .map(concatItemId => {
            const [itemId, registeredItemId, ...augmentations] =
              concatItemId.split('-');

            return (
              <div
                key={concatItemId}
                className={[
                  styles.inventory,
                  inventories[characterId][concatItemId].stack === 0 &&
                    styles.zeroQuantity,
                  inventories[characterId][concatItemId].stack < 0 &&
                    styles.negativeQuantity,
                ].join(' ')}
              >
                <div
                  className={[
                    styles.aggregate,
                    inventories[characterId][concatItemId].isExpanded &&
                      styles.expanded,
                  ].join(' ')}
                  onClick={() =>
                    hideOrFetchTransactions({
                      characterId,
                      itemId,
                      registeredItemId,
                      augmentations,
                      isExpanded:
                        inventories[characterId][concatItemId].isExpanded,
                    })
                  }
                >
                  <div className={styles.kind}>
                    {startCase(items[itemId].kind)}
                  </div>
                  <div className={styles.itemName}>
                    <div className={styles.firstRow}>
                      <div>
                        {inventories[characterId][concatItemId]
                          .augmentations[0] && (
                          <Badge
                            text={
                              inventories[characterId][concatItemId]
                                .augmentations[0]
                            }
                          />
                        )}
                      </div>
                      <div>{items[itemId].name}</div>
                    </div>
                    {registeredItems[registeredItemId] && (
                      <div>
                        <span className={styles.uniqueHint}>Unique Item: </span>
                        {registeredItems[registeredItemId].description}
                      </div>
                    )}
                  </div>
                  <div className={styles.grade}>
                    {startCase(items[itemId].grade)}
                  </div>
                  <div className={styles.amount}>
                    {inventories[characterId][concatItemId].stack}
                  </div>
                </div>
                {inventories[characterId][concatItemId].isExpanded && (
                  <React.Fragment>
                    {eventId && (
                      <Dispensary
                        onDispense={dispense}
                        characterId={characterId}
                        itemId={itemId}
                        registeredItemId={
                          inventories[characterId][concatItemId]
                            .registered_item_id
                        }
                        augmentations={augmentations}
                      />
                    )}
                    <div className={styles.details}>
                      {inventories[characterId][concatItemId].transactions &&
                      inventories[characterId][concatItemId].transactions.data
                        ? inventories[characterId][
                            concatItemId
                          ].transactions.data
                            .sort((a, b) =>
                              compareAsc(
                                parseISO(b[0].created_at),
                                parseISO(a[0].created_at),
                              ),
                            )
                            .map(x =>
                              renderItemTransaction({
                                txn: x,
                                highlightItemId: itemId,
                                highlightCharacterId: characterId,
                              }),
                            )
                        : 'Fetching...'}
                    </div>
                  </React.Fragment>
                )}
              </div>
            );
          });

  const renderCharacterInventory = characterId => {
    const character = characters[characterId];

    return (
      <div key={characterId} className={styles.character}>
        <div
          className={styles.name}
          onClick={() => toggleCharacterInventoryDisplay(characterId)}
        >
          <div className={styles.left}>
            {!eventIndependent &&
              (character.checked_in_to_event ? (
                <Present className={styles.icon} />
              ) : (
                <Absent className={styles.icon} />
              ))}
            <div className={styles.text}>{character.name}</div>
          </div>
          {collapses[characterId] ? (
            <ChevronDown className={styles.icon} />
          ) : (
            <ChevronUp className={styles.icon} />
          )}
        </div>
        {!collapses[characterId] &&
          inventories[characterId] &&
          renderInventory(characterId)}
      </div>
    );
  };

  const fetchData = () => {
    setDeltaUpdate('Fetching data...');
    Object.keys(characters).map(characterId =>
      dispatch({
        type: 'FETCH_INVENTORY',
        payload: { characterId },
      }),
    );
  };

  useEffect(() => {
    if (!transactionPartnersData[userId]) return;

    const userData = transactionPartnersData[userId];

    setUser(userData.user);
    setCharacters(userData.characters);
  }, [transactionPartnersData]);

  useEffect(() => {
    setLastUpdateTimeStamp(Date.now);
    setDeltaUpdate('Data is up-to-date.');
  }, [
    inventories,
    items,
    registeredItems,
    setLastUpdateTimeStamp,
    setDeltaUpdate,
  ]);

  useEffect(() => {
    if (!transaction) return;
    if (!pendingDispensaryItem) return;

    fetchTransactions(pendingDispensaryItem);
    fetchData();
    setPendingDispensaryItem(null);
  }, [transaction, pendingDispensaryItem]);

  useEffect(() => {
    fetchData();
  }, [characters]);

  useEffect(() => {
    if (!playerIdUrl) return;

    setUserId(playerIdUrl);
    dispatchSearch(playerIdUrl);
  }, [playerIdUrl]);

  useEffect(() => {
    if (!overrideUserId) return;

    setUserId(overrideUserId);
    dispatchSearch(overrideUserId);
  }, [overrideUserId]);

  useEffect(() => {
    if (!isHighlightInventoryReady) return;

    hideOrFetchTransactions({
      itemId: highlightItemId,
      characterId: highlightCharacterId,
      isExpanded: false,
    });
  }, [
    user,
    isHighlightInventoryReady,
    highlightCharacterId,
    highlightItemId,
    hideOrFetchTransactions,
  ]);

  useInterval(() => {
    const diff = Date.now() - lastUpdateTimestamp;

    if (diff < REFRESH_RATE_MS) return;
    const distance = formatDistanceToNow(lastUpdateTimestamp, {
      addSuffix: true,
    });
    setDeltaUpdate(
      <div className={styles.clickable} onClick={fetchData}>
        {`Updated ${distance}. Click to refresh.`}
      </div>,
    );
  }, REFRESH_RATE_MS);

  return (
    <div className={styles.container}>
      {!eventIndependent && (
        <div className={styles.control}>
          <input
            type='text'
            placeholder='Find Player ID'
            className={styles.userId}
            value={userId}
            onChange={evt => setUserId(evt.target.value)}
            onKeyDown={evt => evt.keyCode === 13 && performSearch()}
          />
          <button
            type='button'
            className={[
              styles.performSearch,
              (userId.trim().length === 0 || transactionPartners.isSearching) &&
                styles.disabled,
            ].join(' ')}
            onClick={() => performSearch()}
          >
            {transactionPartners.isSearching ? 'Searching...' : 'Search'}
          </button>
        </div>
      )}
      {user && user.id && (
        <div className={styles.user}>
          <div className={styles.title}>
            <div
              className={styles.userName}
            >{`#${user.id} - ${user.full_name}`}</div>
            <div className={styles.filter}>
              <div className={styles.filterText}>
                {showZeroQuantity ? 'Show Empty Item' : 'Hide Empty Item'}
              </div>
              <Toggle
                onChange={evt => setShowZeroQuantity(evt.target.checked)}
                checked={showZeroQuantity}
              />
            </div>
          </div>
          <div className={styles.recency}>{deltaUpdate}</div>
          {Object.keys(characters).map(characterId =>
            renderCharacterInventory(characterId),
          )}
        </div>
      )}
    </div>
  );
};
