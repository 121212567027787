import React from 'react';
import { groupBy, startCase } from 'lodash';
import styles from './ItemSearch.module.scss';

export default ({ isFetchingItem, isFetchItemFailed, itemData, setUrl }) => {
  const renderProducedStacks = sources => {
    const stacks = sources
      .map(x => x.crafting_final_products)
      .flat()
      .filter(x => x.final_product_id === itemData.id);

    if (stacks.length === 1)
      return stacks[0].stack > 1 && `(Produces ${stacks[0].stack} stacks)`;

    return `(Produces ${stacks
      .sort((a, b) => a.stack - b.stack)
      .map(x => x.stack)
      .join(', ')} stacks)`;
  };

  const condensedBlueprintCraftingSources = groupBy(
    itemData.blueprint_crafting_sources,
    x => x.blueprint.id,
  );

  const itemDataHeaderWithData = (
    <React.Fragment>
      <div className={styles.itemName}>{itemData.name}</div>
      <div className={styles.itemClassifier}>
        <span className={styles.itemType}>{startCase(itemData.kind)}</span>
        {itemData.grade && itemData.grade !== 'ungraded' && (
          <span className={styles.itemGrade}>
            -&nbsp;
            {startCase(itemData.grade)}
          </span>
        )}
      </div>
      {Object.values(condensedBlueprintCraftingSources).map(x => (
        <div className={styles.itemBlueprint} key={x[0].blueprint.id}>
          <span>{`${startCase(x[0].blueprint.kind)}: `}</span>
          <span
            className={styles.clickable}
            onClick={() => setUrl(x[0].blueprint.id)}
          >
            {x[0].blueprint.name}
          </span>
          <span className={styles.stack}>{renderProducedStacks(x)}</span>
        </div>
      ))}
    </React.Fragment>
  );

  const renderItemDataHeader = () => {
    if (isFetchingItem) {
      if (itemData.name.length > 0) return itemDataHeaderWithData;

      return <div className={styles.itemType}>Fetching Item Data...</div>;
    }
    if (isFetchItemFailed)
      return (
        <div className={styles.itemType}>
          Item does not exist or is top-sekrit ¯\_(ツ)_/¯
        </div>
      );

    return itemDataHeaderWithData;
  };

  return <div className={styles.header}>{renderItemDataHeader()}</div>;
};
