import { all, put, select, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../../Shared/Toastify/Toast';

function* fetchEventsRun({ payload }) {
  const user = yield select(state => state.user);
  const authConfig = user.session;

  try {
    const res = yield axios.get(`/users/${user.id}/events`, authConfig);
    yield put({ type: 'FETCH_EVENTS_RUN_SUCCEEDED', payload: res.data });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* watchFetchEventsRun() {
  yield takeLatest('FETCH_EVENTS_RUN', fetchEventsRun);
}

export default function* () {
  yield all([watchFetchEventsRun()]);
}
