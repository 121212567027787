import React from 'react';
import ItemDescriptions from './ItemDescriptions';
import ItemDetailsHeader from './ItemDetailsHeader';
import ItemCraftables from './ItemCraftables';
import ItemParts from './ItemParts';
import { BLANK_ITEM_DATA, BLANK_ITEM_STATE } from './constants';
import styles from './ItemSearch.module.scss';

export default ({ cachedItem, setUrl, fireSearch, hasElevatedAccess }) => {
  const selectedItem = cachedItem || BLANK_ITEM_STATE;
  const {
    isFetching: isFetchingItem,
    isFailed: isFetchItemFailed,
    data: unsafeItemData,
  } = selectedItem;
  const itemData = unsafeItemData || BLANK_ITEM_DATA;

  return (
    <div className={styles.itemDetailed}>
      <ItemDetailsHeader
        isFetchingItem={isFetchingItem}
        isFetchItemFailed={isFetchItemFailed}
        itemData={itemData}
        setUrl={setUrl}
      />
      <ItemCraftables
        itemData={itemData}
        setUrl={setUrl}
        fireSearch={fireSearch}
      />
      <ItemParts
        itemData={itemData}
        setUrl={setUrl}
        hasElevatedAccess={hasElevatedAccess}
      />
      <ItemDescriptions itemData={itemData} />
    </div>
  );
};
