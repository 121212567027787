import { fromPairs } from 'lodash';

export default (shifts, captureBaseRatio) =>
  fromPairs(
    shifts.map(shift => [
      shift.identifier,
      {
        id: shift.id,
        order: shift.identifier,
        label: shift.label,
        kind: shift.kind,
        limit:
          captureBaseRatio && shift.kind !== 'special'
            ? shift.base_ratio
            : shift.limit,
        isEnabled: shift.state === 'enabled',
        isPremiere: parseInt(shift.identifier) % 100 < 50,
        isFullyBooked: shift.fully_booked,
        day: parseInt(parseInt(shift.identifier) / 100),
        isAttendeeSelected: false,
      },
    ]),
  );
