import { clamp, fromPairs } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  faBalanceScale,
  faBiohazard,
  faBolt,
  faHeart,
} from '@fortawesome/free-solid-svg-icons';
import Button from './Button';
import { calcStatBuild, calcStatControl, calcStatControlDual } from './calc';
import styles from './Bar.module.scss';
import strains from '../../gameData/strains';
import './Bar.scss';
import { isEditable as hasEditPrivilege, isPrivileged } from '../../utils/user';

const StatBar = () => {
  const dispatch = useDispatch();

  const {
    currentCharacterID,
    popper,
    stats,
    fractures,
    strain,
    statControls,
    approvedForPlay,
    user,
    remotePersistance,
  } = useSelector(state => ({
    currentCharacterID: state.localStorage.currentCharacterID,
    popper: state.ui.character.popperOpened,
    strain: state.character.strain,
    stats: state.character.stats,
    fractures: state.character.fractures,
    statControls: state.ui.stats,
    approvedForPlay: state.character.approvedForPlay,
    user: state.user,
    remotePersistance: state.character.remotePersistance,
  }));

  const isEditable = hasEditPrivilege(user, approvedForPlay);
  const isEditor = isPrivileged(user);
  const statTypes = {
    body: {
      icon: faHeart,
      altName: 'HP',
      name: 'Body',
      placement: 'bottom-start',
      build: stats.body.build,
    },
    mind: {
      icon: faBolt,
      altName: 'MP',
      name: 'Mind',
      placement: 'bottom',
      build: stats.mind.build,
    },
    resolve: {
      icon: faBalanceScale,
      altName: 'RP',
      name: 'Resolve',
      placement: 'bottom',
      build: stats.resolve.build,
    },
    infection: {
      icon: faBiohazard,
      altName: 'INF',
      name: 'Infection',
      placement: 'bottom-end',
      build: stats.infection.build,
      reduction: 'deaths',
    },
  };

  const coalesceStrain = () => strains[strain || 'default'];
  const routineDispatch = payload => {
    dispatch({
      type: 'UPDATE_STAT',
      payload: payload.newStat,
    });
    dispatch({
      type: 'UPDATE_STAT_CONTROLS',
      payload: payload.newStatControl,
    });

    if (payload.isForced) {
      if (isEditable) {
        dispatch({ type: 'UPDATE_STAT_UPSTREAM' });
      } else {
        dispatch({ type: 'UPDATE_STAT_DRAFTS' });
      }
    }
  };
  const computeNewStat = (statKey, adjustment) => {
    let aggregate;
    const innate = coalesceStrain().lineage.stats;
    const lowerLimit =
      'stats' in remotePersistance &&
      statKey in remotePersistance.stats &&
      !isEditable
        ? remotePersistance.stats[statKey] - innate[statKey]
        : 0;

    switch (statKey) {
      case 'resolve':
        aggregate = innate.resolve + stats.resolve.added + adjustment;
        if (aggregate < lowerLimit) return lowerLimit;
        if (stats.resolve.added + adjustment < lowerLimit) return lowerLimit;
        if (aggregate > 6) return 6 - innate.resolve;
        return stats.resolve.added + adjustment;
      case 'infection':
        if (!isEditor && isEditable) return stats.infection.added;

        aggregate =
          innate.infection +
          stats.infection.added -
          stats.deaths.count +
          adjustment;

        if (aggregate < 0) return -(innate.infection - stats.deaths.count);
        if (stats.infection.added + adjustment < 0) return 0;
        if (aggregate > 8) return 8 + stats.deaths.count - innate.infection;
        return stats.infection.added + adjustment;
      case 'deaths':
        aggregate =
          innate.infection +
          stats.infection.added -
          stats.deaths.count -
          adjustment;
        if (aggregate < 0) return innate.infection + stats.infection.added;
        if (aggregate > 8) return innate.infection + stats.infection.added - 8;
        if (stats.deaths.count + adjustment < 0) return 0;
        return stats.deaths.count + adjustment;
      default:
        return clamp(stats[statKey].added + adjustment, lowerLimit, 999);
    }
  };
  const computeNextCost = (statKey, acquired) => {
    switch (statKey) {
      case 'body':
      case 'mind': {
        const divisor = parseInt(acquired / 10, 10);
        if (divisor < 2) return 1;
        if (divisor < 6) return 2 * (divisor - 1) + 1;
        return 10;
      }
      default:
        return 10;
    }
  };
  const computeNewStatObject = (statKey, adjustment) => {
    const added = computeNewStat(statKey, adjustment);
    const innate = coalesceStrain().lineage.stats;
    switch (statKey) {
      case 'deaths':
        return { deaths: { count: added } };
      default: {
        const reductionKey = statTypes[statKey].reduction;
        const reduction = reductionKey in stats ? stats[reductionKey].count : 0;
        return {
          [statKey]: {
            added,
            total: added + innate[statKey] - reduction,
            nextCost: computeNextCost(statKey, added),
            build: calcStatBuild(statKey, added),
          },
        };
      }
    }
  };
  const computeNewStatControl = (statVals, statKey) => {
    // use lazy-coalescing evaluation tricks
    let statInnate = coalesceStrain().lineage.stats[statKey];
    let statValue = statVals[statKey].added;
    let statReduction;
    let dual;

    switch (statKey) {
      case 'infection':
        statReduction = stats.deaths.count;

        if (!isEditor && isEditable) {
          return {
            infection: { isDecrementable: false, isIncrementable: false },
            detahs: { isDecrementable: false, isIncrementable: false },
          };
        }

        dual = calcStatControlDual(
          statInnate,
          statValue,
          statReduction,
          8,
          isEditable
            ? 0
            : (remotePersistance.stats[statKey] || 0) -
                (remotePersistance.stats.deaths || 0),
        );

        return {
          infection: dual.main,
          deaths: dual.reduction,
        };
      case 'deaths':
        statInnate = coalesceStrain().lineage.stats.infection;
        statValue = stats.infection.added;
        statReduction = statVals[statKey].count;
        dual = calcStatControlDual(
          statInnate,
          statValue,
          statReduction,
          8,
          remotePersistance.stats[statKey] || 0,
        );
        return {
          infection: dual.main,
          deaths: dual.reduction,
        };
      case 'resolve':
        return {
          [statKey]: calcStatControl(
            statInnate,
            statValue,
            6,
            isEditable ? 0 : remotePersistance.stats[statKey] || 0,
          ),
        };
      default:
        return {
          [statKey]: calcStatControl(
            statInnate,
            statValue,
            null,
            isEditable ? 0 : remotePersistance.stats[statKey] || 0,
          ),
        };
    }
  };
  const handlePopperToggle = statType =>
    dispatch({ type: 'TOGGLE_POPPER', payload: statType });
  const handleStatAdjustment = (changedStat, adjustment) => {
    const newStat = computeNewStatObject(changedStat, adjustment);
    const newStatControl = computeNewStatControl(newStat, changedStat);
    routineDispatch({
      newStat,
      newStatControl,
      isForced: true,
    });
  };
  const handleStatChange = (changedStat, value) => {
    const newStat = computeNewStatObject(
      changedStat,
      value - stats[changedStat].added,
    );
    const newStatControl = computeNewStatControl(newStat, changedStat);
    routineDispatch({
      newStat,
      newStatControl,
      isForced: true,
    });
  };
  const unconditionalRecompute = () => {
    const statBatch = {};
    const statControlBatch = {};

    Object.keys(statTypes).forEach(updateStat => {
      const newStat = computeNewStatObject(updateStat, 0);
      Object.assign(statBatch, newStat);
      Object.assign(
        statControlBatch,
        computeNewStatControl(statBatch, updateStat),
      );
    });

    routineDispatch({
      newStat: statBatch,
      newStatControl: statControlBatch,
    });
  };

  useEffect(() => {
    if (!currentCharacterID) return;
    unconditionalRecompute(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strain]); // hack. don't change this until we can figure out better way

  useEffect(() => {
    if (!currentCharacterID) return;
    const controls = [];

    Object.keys(statTypes).forEach(stat => {
      const recomputed = computeNewStatControl(stats, stat);
      Object.keys(recomputed).forEach(recomputeKey => {
        controls.push([recomputeKey, recomputed[recomputeKey]]);
      });
    });

    dispatch({
      type: 'UPDATE_STAT_CONTROLS',
      payload: fromPairs(controls),
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stats]); // also hack

  return (
    <div className={styles.statBar}>
      {Object.keys(statTypes).map(statType => {
        const reductionKey = statTypes[statType].reduction;
        return (
          <Button
            key={statType}
            statType={statType}
            reductionType={reductionKey}
            icon={statTypes[statType].icon}
            name={statTypes[statType].name}
            altName={statTypes[statType].altName}
            innate={coalesceStrain().lineage.stats[statType]}
            acquired={stats[statType].added || 0}
            reduction={stats[reductionKey] ? stats[reductionKey].count : 0}
            fractureReduction={statType === 'mind' ? fractures : null}
            total={
              coalesceStrain().lineage.stats[statType] +
              stats[statType].added -
              (stats[reductionKey] ? stats[reductionKey].count : 0)
            }
            build={stats[statType].build}
            nextCost={stats[statType].nextCost}
            statControls={statControls[statType]}
            reductionControls={statControls[reductionKey]}
            passClick={handleStatAdjustment}
            passChange={handleStatChange}
            passPopperToggle={handlePopperToggle}
            passReduction={handleStatAdjustment}
            placement={statTypes[statType].placement}
            popper={popper}
            remoteTotal={
              'stats' in remotePersistance
                ? remotePersistance.stats[statType] -
                  (remotePersistance.stats[statTypes[statType].reduction] || 0)
                : null
            }
            isEditable={isEditable}
            isEditor={isEditor}
          />
        );
      })}
    </div>
  );
};

export default React.memo(StatBar);
