import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import axios from 'axios';
import App from './App';
import railsIsCool from './railsIsCool';
import * as serviceWorker from './serviceWorker';

const isDev = window.location.host.match(/(localhost|192\.168)/);
const isStaging = window.location.host.match(/staging/);
const isRailsCool = railsIsCool();

if (isDev) {
  axios.defaults.baseURL = isRailsCool
    ? '//localhost:4000/api'
    : '//drbe-staging.herokuapp.com/api';
} else if (isStaging) {
  axios.defaults.baseURL = '//drbe-staging.herokuapp.com/api';
} else {
  axios.defaults.baseURL = '//p0.dystopiarisingnetwork.com/api';
}

ReactDOM.render(<App />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
