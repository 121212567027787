import React, { useEffect, useState } from 'react';
import InputBase from '@material-ui/core/InputBase';
import AddExtraBuild from '@material-ui/icons/AddCircleOutline';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { isEqual, omit, omitBy } from 'lodash';
import styles from './CharacterExtraBuild.module.scss';

export default ({ data, isPrivileged, passChanges }) => {
  const [cachedContent, setCachedContent] = useState({});
  const [dirtyTracker, setDirtyTracker] = useState({});
  const isDirty = !isEqual(cachedContent, dirtyTracker);

  const handleChange = (id, field, value) => {
    setCachedContent({
      ...cachedContent,
      [id]: {
        ...cachedContent[id],
        [field]: value,
      },
    });
  };
  const handleUpdateAndReload = () =>
    isDirty &&
    passChanges(omitBy(cachedContent, (v, k) => isEqual(v, dirtyTracker[k])));

  const handleCreate = () => {
    const dateID = Date.now();
    setCachedContent({
      ...cachedContent,
      [dateID]: {
        ...cachedContent[dateID],
        reason: '',
        amount: '',
        isNew: true,
        kind: 'generic',
      },
    });
  };
  const handleDelete = id => setCachedContent(omit(cachedContent, [id]));
  const grantor = content =>
    content.user
      ? [content.user.first_name, content.user.last_name].join(' ').trim()
      : 'System';
  const humanizedTimestamp = content =>
    formatDistanceToNow(parseISO(content.updated_at), { addSuffix: true });

  const extraBuildViewer = (id, content) => (
    <div className={[styles.row, styles.extraBuild].join(' ')} key={id}>
      <div className={styles.left}>
        <div className={styles.date}>
          {content.updated_at ? (
            format(parseISO(content.updated_at), 'yyyy-M-d')
          ) : (
            <div className={styles.clear} onClick={() => handleDelete(id)}>
              Cancel
            </div>
          )}
        </div>
      </div>
      <div className={styles.middle}>
        <div className={styles.reason}>
          <div className={styles.text}>{content.reason}</div>
        </div>
        {content.isNew || !isPrivileged ? null : (
          <div className={styles.grantor}>
            {`Granted by ${grantor(content)} ${humanizedTimestamp(content)}`}
          </div>
        )}
      </div>
      <div className={styles.right}>
        <div className={styles.amount}>
          <div className={styles.text}>{content.amount}</div>
        </div>
      </div>
    </div>
  );

  const extraBuildContents = () =>
    Object.keys(cachedContent).length > 0 ? (
      Object.keys(cachedContent)
        .sort((a, b) => {
          if (cachedContent[a].isNew) return 1;
          if (cachedContent[b].isNew) return -1;
          return b - a;
        })
        .map(x => extraBuildViewer(x, cachedContent[x]))
    ) : (
      <div className={styles.noCxb}>-- No Extra XP Recorded --</div>
    );

  useEffect(() => {
    if (!data) return;
    setCachedContent(data);
    setDirtyTracker(data);
  }, [data]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>Extra XP</div>
      <div className={styles.content}>{extraBuildContents()}</div>
    </div>
  );
};
