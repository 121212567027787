import skillInitializer from '../../gameData/skillInitializer';
import { groupBy, max } from 'lodash';

const aSum = (tier, a) => {
  const count = a ? a.length : 0;
  return tier === 4
    ? 10 * count
    : (count * (2 * (tier + 1) + (count - 1) * (tier + 1))) / 2;
};

const structify = data => {
  const byKeys = groupBy(data, 'key');
  const byKeysSortedTier = Object.keys(byKeys)
    .sort((a, b) => {
      const maxTierA = max(byKeys[a].filter(x => x.tier < 4).map(x => x.tier));
      const maxTierB = max(byKeys[b].filter(x => x.tier < 4).map(x => x.tier));
      if (maxTierA !== maxTierB) return maxTierB - maxTierA;

      const isOneTierA = byKeys[a]
        .map(x => x.maxTier === 1)
        .reduce((r, s) => r || s, false);
      const isOneTierB = byKeys[b]
        .map(x => x.maxTier === 1)
        .reduce((r, s) => r || s, false);

      if (isOneTierA === isOneTierB) return 0;
      return isOneTierA ? 1 : -1;
    })
    .map(x => byKeys[x]);
  const tierGroup = groupBy(byKeysSortedTier.flatMap(x => x.map(y => y.tier)));

  const tierSum = {
    1: aSum(1, tierGroup['1']),
    2: aSum(2, tierGroup['2']),
    3: aSum(3, tierGroup['3']),
    4: aSum(4, tierGroup['4']),
  };
  const categorySum = Object.values(tierSum).reduce((a, b) => a + b, 0);

  return {
    byKeysSortedTier,
    tierSum,
    categorySum,
  };
};

export default characterData => {
  const { remoteSkills, skills } = skillInitializer();
  const acquiredSkills = characterData.skills_id.map(x => {
    const skill = skills[remoteSkills[x].key];
    return Object.assign(remoteSkills[x], {
      category: skill.category,
      isInnate: skill.innate,
      t4Name: skill.t4,
      name: skill.name,
      maxTier: skill.maxTier,
    });
  });
  const { combat, civilized, wasteland, anomaly } = groupBy(
    acquiredSkills,
    'category',
  );

  return {
    combat: structify(combat),
    civilized: structify(civilized),
    wasteland: structify(wasteland),
    anomaly: structify(anomaly),
  };
};
