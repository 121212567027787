import { fromPairs } from 'lodash';
import { SYNC_BUFFER } from '../types';
import SkillTable from '../../gameData/skillInitializer';

export const initialState = {
  categories: {
    combat: {
      1: { count: 0, nextCost: 2, build: 0 },
      2: { count: 0, nextCost: 3, build: 0 },
      3: { count: 0, nextCost: 4, build: 0 },
      4: { count: 0, nextCost: 10, build: 0 },
      totalBuild: 0,
    },
    civilized: {
      1: { count: 0, nextCost: 2, build: 0 },
      2: { count: 0, nextCost: 3, build: 0 },
      3: { count: 0, nextCost: 4, build: 0 },
      4: { count: 0, nextCost: 10, build: 0 },
      totalBuild: 0,
    },
    wasteland: {
      1: { count: 0, nextCost: 2, build: 0 },
      2: { count: 0, nextCost: 3, build: 0 },
      3: { count: 0, nextCost: 4, build: 0 },
      4: { count: 0, nextCost: 10, build: 0 },
      totalBuild: 0,
    },
    anomaly: {
      1: { count: 0, nextCost: 2, build: 0 },
      2: { count: 0, nextCost: 3, build: 0 },
      3: { count: 0, nextCost: 4, build: 0 },
      4: { count: 0, nextCost: 10, build: 0 },
      totalBuild: 0,
    },
  },
  trees: fromPairs(
    Object.keys(SkillTable().skills).map(x => [
      x,
      { levelAcquired: 0, achievementIsAcquired: false },
    ]),
  ),
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SYNC_BUFFER:
      return {
        ...state,
        ...payload.skills,
      };
    default:
      return state;
  }
};
