import React, { useEffect, useState } from 'react';
import ItemTransactionNavigator from './ItemTransactionNavigator';
import ItemTransactionCrafting from './ItemTransactionCrafting';
import EventItemTransactionAudit from './EventItemTransactionAudit';
import Lockbox from './Lockbox';
import history from '../../history';

export default ({ eventId, isAuditCapable, isPostie }) => {
  const [currentPage, setCurrentPage] = useState('crafting');
  const isAudit = history.location.pathname.match(/audit/);
  const isCrafting = history.location.pathname.match(/crafting/);
  const isLockboxes = history.location.pathname.match(/lockboxes/);
  const isPostal = history.location.pathname.match(/postal/);
  const isTrading = history.location.pathname.match(/trading/);

  const arbitratedPageContent = () => {
    switch (currentPage) {
      case 'crafting':
        return <ItemTransactionCrafting isPostie={isPostie} mode='blueprint' />;
      case 'postal':
        return <ItemTransactionCrafting isPostie={isPostie} mode='freeform' />;
      case 'trading':
        return (
          <ItemTransactionCrafting
            isPostie={isPostie}
            mode='freeform'
            isBidirectional
          />
        );
      case 'audit':
        return (
          <EventItemTransactionAudit
            isAuditCapable={isAuditCapable}
            isPostie={isPostie}
          />
        );
      case 'lockboxes':
        return <Lockbox isPrivileged={isAuditCapable} />;
      default:
        return <ItemTransactionCrafting isPostie={isPostie} />;
    }
  };

  useEffect(() => {
    if (isAudit) setCurrentPage('audit');
    if (isCrafting) setCurrentPage('crafting');
    if (isLockboxes) setCurrentPage('lockboxes');
    if (isPostal) setCurrentPage('postal');
    if (isTrading) setCurrentPage('trading');
  }, [isAudit, isCrafting, isLockboxes, isPostal, isTrading]);

  return (
    <div>
      <ItemTransactionNavigator
        eventId={eventId}
        currentPage={currentPage}
        isAuditCapable={isAuditCapable}
      />
      {arbitratedPageContent()}
    </div>
  );
};
