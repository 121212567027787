import React from 'react';
import { isEqual, omit } from 'lodash';
import { isAfter, isBefore } from 'date-fns';
import styles from './CreateEventButton.module.scss';

export default ({ event, passCreateEvent, eventID }) => {
  const isValid = () =>
    event.remoteError === null &&
    event.startDate &&
    event.endDate &&
    !isAfter(event.startDate, event.endDate) &&
    (!event.registrationDate ||
      isBefore(event.registrationDate, event.startDate));

  const omittedFields = [
    'dirtyTracking',
    'locations',
    'selectedBranch',
    'isProcessing',
    'remoteError',
    'pastEvents',
    'externalManagers',
    'mods',
    'modSections',
    'modSignupOpensAt',
    'modDescription',
    'modParticipants',
    'modMaxSignup',
    'modEnableWaitlist',
  ];
  const eventWithoutDirtyTracking = omit(event, omittedFields);

  const isDirty = () =>
    eventID === null
      ? true
      : !isEqual(
          eventWithoutDirtyTracking,
          omit(event.dirtyTracking, omittedFields),
        );

  const text = () =>
    event.remoteError ||
    (eventID
      ? event.isProcessing
        ? 'Updating Event...'
        : isEqual(eventWithoutDirtyTracking, event.dirtyTracking)
        ? 'Event is Up to Date'
        : 'Update Event'
      : event.isProcessing
      ? 'Creating Event...'
      : isValid()
      ? "Create Event - Don't worry you can update later"
      : 'Fill Start and End Date to Create Event');

  // Debug helper: Do not remove!!
  // console.log(isValid(), isDirty(), !event.isProcessing);
  // if (event.dirtyTracking) {
  //   Object.keys(eventWithoutDirtyTracking).map(k =>
  //     console.log(k, isEqual(eventWithoutDirtyTracking[k], event.dirtyTracking[k]))
  //   )
  // }

  return (
    <div
      className={[
        styles.button,
        isValid() && isDirty() && !event.isProcessing ? '' : styles.invalid,
      ].join(' ')}
      onClick={() => isValid() && passCreateEvent()}
    >
      {text()}
    </div>
  );
};
