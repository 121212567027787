import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { find, fromPairs, groupBy, range, startCase, uniq } from 'lodash';
import WindowedScrollbar from '../../Shared/WindowedScrollbar';
import ItemSearch from '../../Items/ItemSearch';
import TaxonomySelect from './TaxonomySelect';
import TransactionApplicator from './TransactionApplicator';
import ItemTransactionTutorial from './ItemTransactionTutorial';
import ItemTransactionUnique from './ItemTransactionUnique';
import Badge from '../../Shared/Badge';
import ChevronDown from '@material-ui/icons/KeyboardArrowDown';
import ChevronUp from '@material-ui/icons/KeyboardArrowUp';
import { tradingUi, postalUi } from '../../LocalStorage/arbiter';
import Slider, { Range } from 'rc-slider';
import { GRADE_SORT_REAL } from '../../Items/constants';
import styles from './ItemTransactionCrafting.module.scss';

const GRADE_RANGES = ['basic', 'proficient', 'master'];
const AUGMENTATION_KIND_RULES = {
  produce: ['Salted', 'Canned', 'Preserved'],
  recovery_meal: [
    'Stir Fry',
    'Pan Fry',
    'Deep Fry',
    'Canned',
    'Canned + Stir Fry',
    'Canned + Pan Fry',
    'Canned + Deep Fry',
  ],
  healing_meal: [
    'Stir Fry',
    'Pan Fry',
    'Deep Fry',
    'Canned',
    'Canned + Stir Fry',
    'Canned + Pan Fry',
    'Canned + Deep Fry',
  ],
};
const AUGMENTATION_NAME_RULES = {
  'Generic Meal: Basic': [
    'Stir Fry',
    'Pan Fry',
    'Deep Fry',
    'Canned',
    'Canned + Stir Fry',
    'Canned + Pan Fry',
    'Canned + Deep Fry',
  ],
  'Generic Meal: Proficient': [
    'Stir Fry',
    'Pan Fry',
    'Deep Fry',
    'Canned',
    'Canned + Stir Fry',
    'Canned + Pan Fry',
    'Canned + Deep Fry',
  ],
  'Generic Meal: Master': [
    'Stir Fry',
    'Pan Fry',
    'Deep Fry',
    'Canned',
    'Canned + Stir Fry',
    'Canned + Pan Fry',
    'Canned + Deep Fry',
  ],
};
const INVERT = true;
const KEEP_LS = true;

export default ({ isPostie, mode, isBidirectional }) => {
  const dispatch = useDispatch();
  const isFreeform = mode === 'freeform';
  const page = isBidirectional ? 'trading' : isFreeform ? 'postal' : 'crafting';
  const { itemTaxonomies, eventInfo, transaction, registeredItems } =
    useSelector(state => ({
      eventInfo: state.eventAdministration.eventInfo,
      itemTaxonomies: state.eventAdministration.itemTaxonomies,
      transaction: state.eventAdministration.transaction,
      registeredItems: state.eventAdministration.registeredItems,
    }));
  const [item, setItem] = useState(null);
  const [uniqueItemEntries, setUniqueItemEntries] = useState({});
  const [craftableItems, setCraftableItems] = useState([]);
  const [gradeRanges, setGradeRanges] = useState(null);
  const [gradedItems, setGradedItems] = useState([]);
  const [kind, setKind] = useState('');
  const [craftingComponents, setCraftingComponents] = useState({});
  const [lowerLevelItems, setLowerLevelItems] = useState({});
  const [upperLevelItems, setUpperLevelItems] = useState({});
  const [outputStacks, setOutputStacks] = useState({});
  const [transactionDetails, setTransactionDetails] = useState('');
  const [filteredCraftingComponents, setFilteredCraftingComponents] = useState(
    {},
  );
  const [globalSelectedCharacter, setGlobalSelectedCharacter] = useState(null);
  const [repeatOperation, setRepeatOperation] = useState(1);
  const [tradeLeftCharacter, setTradeLeftCharacter] = useState(null);
  const [tradeRightCharacter, setTradeRightCharacter] = useState(null);
  const [annotationOpenState, setAnnotationOpenState] = useState({});
  const {
    transactions: lastTransactions,
    isExecuting: isTransactionExecuting,
  } = transaction;

  const getLsUi = useMemo(() => {
    return page === 'postal'
      ? postalUi().get()
      : page === 'trading'
      ? tradingUi().get()
      : null;
  }, [page]);

  const setLsUi = useCallback(
    struct => {
      switch (page) {
        case 'postal':
          return postalUi().set(struct ? { ...struct, page } : null);
        case 'trading':
          return tradingUi().set(struct ? { ...struct, page } : null);
        default:
          return;
      }
    },
    [page],
  );

  const resetTable = useCallback(
    (keepLs = false) => {
      setItem(null);
      setRepeatOperation(1);
      setTransactionDetails('');
      setTradeLeftCharacter(null);
      setTradeRightCharacter(null);
      setFilteredCraftingComponents({});
      setUpperLevelItems({});
      setUniqueItemEntries({});

      if (!keepLs) setLsUi(null);
    },
    [
      setItem,
      setRepeatOperation,
      setFilteredCraftingComponents,
      setUpperLevelItems,
      isFreeform,
    ],
  );

  const handleUniqueItemsDescriptionAdd = ({ itemId }) =>
    setUniqueItemEntries({
      ...uniqueItemEntries,
      [itemId]: {
        ...uniqueItemEntries[itemId],
        data: [
          ...uniqueItemEntries[itemId].data,
          {
            character: null,
            description: null,
            quantity: 1,
          },
        ],
      },
    });

  const handleUniqueItemsDescriptionUpdate = ({
    field,
    index,
    itemId,
    value,
  }) =>
    setUniqueItemEntries({
      ...uniqueItemEntries,
      [itemId]: {
        ...uniqueItemEntries[itemId],
        data: uniqueItemEntries[itemId].data.map((x, i) =>
          i === index ? { ...x, [field]: value } : x,
        ),
      },
    });

  const handleUniqueItemsDescriptionRemove = ({ index, itemId }) =>
    setUniqueItemEntries({
      ...uniqueItemEntries,
      [itemId]: {
        ...uniqueItemEntries[itemId],
        data: uniqueItemEntries[itemId].data.filter((_, i) => i !== index),
      },
    });

  const handleAddBatchUniqueItem = item =>
    setUniqueItemEntries({
      ...uniqueItemEntries,
      [item.id]: { item, data: [] },
    });

  const handleRemoveBatch = itemId =>
    setUniqueItemEntries(
      fromPairs(
        Object.keys(uniqueItemEntries)
          .filter(x => x !== itemId)
          .map(x => [x, uniqueItemEntries[x]]),
      ),
    );

  const renderEmblem = ({ value, onChange }) => (
    <div
      className={[
        styles.emblem,
        value.trim().length > 0 && styles.visible,
      ].join(' ')}
    >
      <textarea
        onChange={onChange}
        value={value}
        className={styles.emblemText}
        placeholder='Annotate to make this item unique. E.g. plot items, branch-specific, etc...'
      />
    </div>
  );

  const renderAugmentationOptions = ({
    onClick,
    currentAugmentation,
    augmentations,
  }) => {
    if (!augmentations) return null;
    if (augmentations.length === 0) return null;

    return (
      <div className={styles.augmentations}>
        {[
          <div
            key='plain'
            className={[
              styles.augmentation,
              !currentAugmentation && styles.currentAugmentation,
            ].join(' ')}
            onClick={() => onClick(null)}
          >
            Plain
          </div>,
        ].concat(
          augmentations.map(augmentation => (
            <div
              key={augmentation}
              className={[
                styles.augmentation,
                currentAugmentation &&
                  currentAugmentation === augmentation &&
                  styles.currentAugmentation,
              ].join(' ')}
              onClick={() => onClick(augmentation)}
            >
              {augmentation}
            </div>
          )),
        )}
      </div>
    );
  };

  const renderAugmentations = ({ item, currentAugmentation, onClick }) => {
    const augmentationByKind = AUGMENTATION_KIND_RULES[item.kind] || [];
    const augmentationByName = AUGMENTATION_NAME_RULES[item.name] || [];

    const augmentations = uniq(augmentationByKind.concat(augmentationByName));

    return renderAugmentationOptions({
      onClick,
      currentAugmentation,
      augmentations,
    });
  };

  const renderUniqueItems = ({
    item,
    character,
    selectedUniqueItem,
    onClick,
  }) => {
    if (!character) return null;
    if (!registeredItems[character.id]) return null;

    const uniqueItems = registeredItems[character.id].registered_items.filter(
      x => x.item.id === item.id,
    );

    if (uniqueItems.length === 0) return null;

    return (
      <div className={styles.registeredItemSelector}>
        <div
          className={[
            styles.registeredItem,
            selectedUniqueItem === null && styles.currentSelection,
          ].join(' ')}
          key='non-unique'
          onClick={() => onClick(null)}
        >
          Use Non-Unique Item
        </div>
        {uniqueItems.map(x => (
          <div
            className={[
              styles.registeredItem,
              selectedUniqueItem &&
                selectedUniqueItem.id === x.id &&
                styles.currentSelection,
            ].join(' ')}
            key={x.id}
            onClick={() => onClick(x)}
          >
            {`${x.description} (${
              registeredItems[character.id].quantity[x.id]
            })`}
          </div>
        ))}
      </div>
    );
  };

  const fetchRegisteredItems = useCallback(
    character => {
      if (!character) return;
      dispatch({
        type: 'FETCH_REGISTERED_ITEMS',
        payload: { characterId: character.id },
      });
    },
    [dispatch],
  );

  const renderCraftableItems = () => {
    if (
      kind === 'conversion' &&
      uniq(craftableItems.map(x => x.grade)).length === 1
    )
      return null;
    if (craftableItems.length < 2) return null;

    return kind === 'blueprint' ? (
      <Range
        min={0}
        max={craftableItems.length}
        defaultValue={[0, 1]}
        dots={true}
        pushable={1}
        onChange={x => setGradeRanges(x)}
      />
    ) : (
      <Slider
        min={0}
        max={craftableItems.length - 1}
        dots={true}
        onChange={x => setGradeRanges([0, x + 1])}
      />
    );
  };

  const renderGradeRanges = () => {
    if (kind !== 'blueprint') return null;
    if (!gradeRanges) return null;

    const lowerGrade = gradedItems[gradeRanges[0]];
    const upperGrade = gradedItems[gradeRanges[1]];
    const lowerStacks = outputStacks[lowerGrade.id];
    const upperStacks = outputStacks[upperGrade.id];
    const lowerStack = lowerStacks
      ? (lowerStacks.length === 1
          ? lowerStacks[0]
          : lowerStacks[gradeRanges[0] - 1]
        ).stack
      : 1;
    const upperStack = upperStacks
      ? (upperStacks.length === 1
          ? upperStacks[0]
          : upperStacks[gradeRanges[1] - 1]
        ).stack
      : 1;

    if (kind !== 'blueprint') {
      return (
        <div>
          {upperGrade.name}
          {upperStack > 1 && ` (${upperStack} stacks)`}
        </div>
      );
    }

    return (
      <div>
        {lowerGrade.name}
        {lowerStack > 1 && ` (${lowerStack} stacks)`}
        {` -> `}
        {upperGrade.name}
        {upperStack > 1 && ` (${upperStack} stacks)`}
      </div>
    );
  };

  const fetchTaxonomies = useCallback(components => {
    const taxonomies = uniq(
      Object.values(components)
        .flat()
        .filter(x => x.component.kind === 'taxonomy')
        .map(x => x.component.name),
    );

    if (taxonomies.length > 0)
      dispatch({ type: 'FETCH_TAXONOMIES', payload: taxonomies });
  }, []);

  const filterComponents = useCallback(
    filters => {
      return fromPairs(
        Object.values(craftingComponents)
          .flat()
          .filter(
            craftingComponent =>
              craftingComponent.item_crafting &&
              filters.includes(
                craftingComponent.item_crafting.final_products[0].grade,
              ),
          )
          .map(x => [
            x.component.id,
            { amount: x.amount, component: x.component },
          ]),
      );
    },
    [craftingComponents],
  );

  const recalculateComponents = useCallback(
    ranges => {
      if (!ranges) {
        setFilteredCraftingComponents({});
        setLowerLevelItems({});
        setUpperLevelItems({});
        return;
      }

      const filters = range(ranges[0], ranges[1]).map(x => GRADE_RANGES[x]);
      const lowerLevelFilter = ranges[0] - 1;
      const upperLevelFilter = ranges[1] - 1;

      const filteredComponents = () => {
        if (kind === 'blueprint') {
          const endComponents = filterComponents([GRADE_RANGES[ranges[1] - 1]]);
          const subtractComponents = filterComponents([
            GRADE_RANGES[ranges[0] - 1],
          ]);

          return Object.keys(endComponents).map(ecId => {
            const endComponent = endComponents[ecId];
            const subtractComponent = subtractComponents[ecId];

            if (!subtractComponent) return endComponent;

            return {
              amount: endComponent.amount - subtractComponent.amount,
              component: endComponent.component,
            };
          });
        }

        const t = Object.keys(craftingComponents)
          .filter(
            x =>
              craftingComponents[x][0].item_crafting.final_products[0].grade ===
              GRADE_RANGES[upperLevelFilter],
          )
          .map(x => craftingComponents[x])
          .flat();
        const u = Object.values(craftingComponents).flat()[upperLevelFilter];
        const v = t.length === 0 ? u : t[0];

        return [
          {
            component: v.component,
            amount: v.amount,
          },
        ];
      };

      setFilteredCraftingComponents(
        fromPairs(
          filteredComponents()
            .filter(x => x.amount > 0)
            .map(x => [
              x.component.id,
              {
                ...x,
                splits: filteredCraftingComponents[x.component.id]
                  ? filteredCraftingComponents[x.component.id].splits
                  : null,
                transactionPartner:
                  filteredCraftingComponents[x.component.id] &&
                  filteredCraftingComponents[x.component.id].transactionPartner,
              },
            ]),
        ),
      );
      setLowerLevelItems(() => {
        if (lowerLevelFilter < 0) return {};

        return fromPairs(
          craftableItems
            .filter(x => x.grade === GRADE_RANGES[lowerLevelFilter])
            .map(x => [
              x.id,
              {
                ...x,
                splits: null,
                amount:
                  outputStacks[x.id] && outputStacks[x.id].length === 1
                    ? outputStacks[x.id][0] && outputStacks[x.id][0].stack
                    : outputStacks[x.id][ranges[0] - 1] &&
                      outputStacks[x.id][ranges[0] - 1].stack,
                transactionPartner:
                  lowerLevelItems[x.id] &&
                  lowerLevelItems[x.id].transactionPartner,
              },
            ]),
        );
      });
      setUpperLevelItems(() => {
        const upperLevelCraftableItems = craftableItems.filter(
          x => x.grade === GRADE_RANGES[upperLevelFilter],
        );
        const baseLevelCraftableItems = craftableItems.filter(
          x => x.grade === GRADE_RANGES[0],
        );
        const normalizedCraftableItems =
          upperLevelCraftableItems.length === 0
            ? baseLevelCraftableItems
            : upperLevelCraftableItems;

        return fromPairs(
          normalizedCraftableItems.map(x => [
            x.id,
            {
              ...x,
              splits: null,
              amount:
                outputStacks[x.id] && outputStacks[x.id].length === 1
                  ? outputStacks[x.id][0] && outputStacks[x.id][0].stack
                  : outputStacks[x.id][ranges[1] - 1] &&
                    outputStacks[x.id][ranges[1] - 1].stack,
              transactionPartner:
                upperLevelItems[x.id] &&
                upperLevelItems[x.id].transactionPartner,
            },
          ]),
        );
      });
    },
    [craftingComponents, filteredCraftingComponents],
  );

  const renderIOItem = ({
    items,
    style,
    align,
    grade,
    onChange,
    onRemove,
    onQuantityChange,
    onPartnerChange,
    onAugmentComponent,
    onEmblemChange,
    onSplit,
    onSplitPartnerChange,
    onSplitAmountChange,
    onSplitPartnerAdd,
    onSplitPartnerRemove,
    onUniqueItemChange,
  }) =>
    Object.keys(items).map(itemId => {
      return (
        <div key={itemId} className={styles.component}>
          <div
            className={[
              styles.mainControl,
              page === 'trading' ? styles.hoverable : styles[align],
              annotationOpenState[itemId] && styles.annotationOpen,
            ].join(' ')}
          >
            <div
              className={styles.removeComponent}
              onClick={() => onRemove(itemId)}
            >
              ✘
            </div>
            {!isBidirectional && !items[itemId].splits && (
              <React.Fragment>
                <TransactionApplicator
                  type='compact'
                  onChange={value => onPartnerChange({ itemId, value })}
                  selectedCharacter={items[itemId].transactionPartner}
                  align={align}
                  required
                />
                {!isBidirectional && (
                  <div className={styles.split} onClick={() => onSplit(itemId)}>
                    {!items[itemId].splits && 'Split'}
                  </div>
                )}
                {align === 'left' ? <span>&larr;</span> : <span>&rarr;</span>}
              </React.Fragment>
            )}

            {items[itemId].kind === 'taxonomy' ? (
              <TaxonomySelect
                value={items[itemId].selectedItemId || -1}
                onChange={value => onChange({ itemId, value })}
                itemTaxonomies={itemTaxonomies}
                taxonomyName={items[itemId].name}
              />
            ) : (
              <div
                className={[
                  styles.name,
                  items[itemId].kind === 'blueprint' && styles.blueprint,
                  isBidirectional && align === 'left' && styles.leftFlex,
                  align === 'left' && !items[itemId].splits && styles.pushLeft,
                ].join(' ')}
              >
                <Badge text={items[itemId].augmentation} />
                {items[itemId].name}
                {items[itemId].blueprint_crafting_sources &&
                  items[itemId].blueprint_crafting_sources.length > 0 &&
                  ` [${startCase(items[itemId].grade)[0]}] `}
                {items[itemId].isOverride && !isFreeform && '*'}
                {items[itemId].uniqueItem && (
                  <div className={styles.uniqueItem}>
                    <span className={styles.uniqueItemLeading}>
                      Unique Item:{' '}
                    </span>
                    {items[itemId].uniqueItem.description}
                  </div>
                )}

                <div
                  className={[
                    onEmblemChange
                      ? styles.augmentationAndEmblem
                      : styles.augmentationOnly,
                    styles[page],
                    items[itemId].splits && styles.noMargin,
                  ].join(' ')}
                >
                  {renderAugmentations({
                    item: items[itemId],
                    currentAugmentation: items[itemId].augmentation,
                    onClick: augmentation => {
                      onAugmentComponent({ itemId, augmentation });
                    },
                  })}
                  {page !== 'trading' &&
                    onEmblemChange &&
                    renderEmblem({
                      value: items[itemId].emblem || '',
                      onChange: evt => {
                        onEmblemChange({ itemId, emblem: evt.target.value });
                      },
                    })}
                  {onUniqueItemChange &&
                    renderUniqueItems({
                      item: items[itemId],
                      character: tradeRightCharacter, //items[itemId].transactionPartner,
                      selectedUniqueItem: items[itemId].uniqueItem,
                      onClick: uniqueItem =>
                        onUniqueItemChange({ itemId, uniqueItem }),
                    })}
                </div>
              </div>
            )}

            {items[itemId].splits ? (
              <div className={styles.nonEditable}>
                {items[itemId].splits.reduce(
                  (a, b) => a + parseInt(b.amount, 10),
                  0,
                )}
                /{items[itemId].amount}
              </div>
            ) : (
              <input
                type='number'
                min={1}
                size={2}
                className={styles.amount}
                value={items[itemId].amount}
                onChange={evt =>
                  onQuantityChange({ itemId, value: evt.target.value })
                }
              />
            )}

            {page !== 'trading' &&
              (items[itemId].emblem || '').trim().length === 0 && (
                <span
                  className={styles.pointyCursor}
                  onClick={() =>
                    setAnnotationOpenState({
                      ...annotationOpenState,
                      [itemId]: !annotationOpenState[itemId],
                    })
                  }
                >
                  {annotationOpenState[itemId] ? (
                    <ChevronUp fontSize='small' />
                  ) : (
                    <ChevronDown fontSize='small' />
                  )}
                </span>
              )}
          </div>
          {items[itemId].splits && (
            <div className={styles.splitControl}>
              <div
                className={styles.addMoreSplit}
                onClick={() => onSplitPartnerAdd(itemId)}
              >
                Add More Splits
              </div>
              {items[itemId].splits.map((x, index) => (
                <div className={styles.split} key={`${itemId}-${index}`}>
                  <div
                    className={styles.removeSplit}
                    onClick={() => onSplitPartnerRemove({ itemId, index })}
                  >
                    ✘
                  </div>
                  <TransactionApplicator
                    type='compact'
                    onChange={value =>
                      onSplitPartnerChange({ itemId, index, value })
                    }
                    selectedCharacter={x.transactionPartner}
                    required
                  />
                  {align === 'left' ? <span>&larr;</span> : <span>&rarr;</span>}
                  <input
                    type='number'
                    min={1}
                    size={2}
                    className={styles.amount}
                    value={x.amount || 1}
                    onChange={evt =>
                      onSplitAmountChange({
                        itemId,
                        index,
                        value: evt.target.value,
                      })
                    }
                  />
                </div>
              ))}
              <div className={styles.unsplit} onClick={() => onSplit(itemId)}>
                Unsplit
              </div>
            </div>
          )}
        </div>
      );
    });

  const applyGlobalApplicator = ({ setLeft, setRight }) => {
    fetchRegisteredItems(globalSelectedCharacter);
    const oneSided = setLeft || setRight;

    (!oneSided || setLeft) &&
      setUpperLevelItems(
        fromPairs(
          Object.keys(upperLevelItems).map(itemId => [
            itemId,
            {
              ...upperLevelItems[itemId],
              transactionPartner: globalSelectedCharacter,
              splits: upperLevelItems[itemId].splits
                ? upperLevelItems[itemId].splits.map(x => ({
                    ...x,
                    transactionPartner: globalSelectedCharacter,
                  }))
                : null,
            },
          ]),
        ),
      );

    setLowerLevelItems(
      fromPairs(
        Object.keys(lowerLevelItems).map(itemId => [
          itemId,
          {
            ...lowerLevelItems[itemId],
            transactionPartner: globalSelectedCharacter,
            splits: lowerLevelItems[itemId].splits
              ? lowerLevelItems[itemId].splits.map(x => ({
                  ...x,
                  transactionPartner: globalSelectedCharacter,
                }))
              : null,
          },
        ]),
      ),
    );

    setUniqueItemEntries(
      fromPairs(
        Object.keys(uniqueItemEntries).map(itemId => [
          itemId,
          {
            ...uniqueItemEntries[itemId],
            data: uniqueItemEntries[itemId].data.map(x => ({
              ...x,
              character: globalSelectedCharacter,
            })),
          },
        ]),
      ),
    );

    (!oneSided || setRight) &&
      setFilteredCraftingComponents(
        fromPairs(
          Object.keys(filteredCraftingComponents).map(itemId => [
            itemId,
            {
              ...filteredCraftingComponents[itemId],
              transactionPartner: globalSelectedCharacter,
              splits: filteredCraftingComponents[itemId].splits
                ? filteredCraftingComponents[itemId].splits.map(x => ({
                    ...x,
                    transactionPartner: globalSelectedCharacter,
                  }))
                : null,
            },
          ]),
        ),
      );

    if (oneSided) {
      if (setLeft) setTradeLeftCharacter(globalSelectedCharacter);
      if (setRight) setTradeRightCharacter(globalSelectedCharacter);

      setGlobalSelectedCharacter(null);
    }
  };

  const renderLowerLevelItem =
    gradeRanges &&
    renderIOItem({
      items: lowerLevelItems,
      style: styles.lowerLevel,
      align: 'right',
      grade: gradeRanges[0],
      onChange: ({ itemId, value }) =>
        setLowerLevelItems({
          ...lowerLevelItems,
          [itemId]: {
            ...lowerLevelItems[itemId],
            selectedItemId: value,
          },
        }),
      onRemove: itemId =>
        setLowerLevelItems(
          fromPairs(
            Object.keys(lowerLevelItems)
              .filter(x => x !== itemId)
              .map(x => [x, lowerLevelItems[x]]),
          ),
        ),
      onQuantityChange: ({ itemId, value }) =>
        setLowerLevelItems({
          ...lowerLevelItems,
          [itemId]: {
            ...lowerLevelItems[itemId],
            amount: value,
          },
        }),
      onPartnerChange: ({ itemId, value }) => {
        fetchRegisteredItems(value);
        setLowerLevelItems({
          ...lowerLevelItems,
          [itemId]: {
            ...lowerLevelItems[itemId],
            transactionPartner: value,
          },
        });
      },
      onSplit: itemId =>
        setLowerLevelItems({
          ...lowerLevelItems,
          [itemId]: {
            ...lowerLevelItems[itemId],
            transactionPartner: null,
            splits: lowerLevelItems[itemId].splits
              ? null
              : [{ transactionPartner: null, amount: 1 }],
          },
        }),
      onSplitPartnerChange: ({ itemId, index, value }) =>
        setLowerLevelItems({
          ...lowerLevelItems,
          [itemId]: {
            ...lowerLevelItems[itemId],
            splits: Object.assign([...lowerLevelItems[itemId].splits], {
              [index]: {
                ...lowerLevelItems[itemId].splits[index],
                transactionPartner: value,
              },
            }),
          },
        }),
      onSplitAmountChange: ({ itemId, index, value }) =>
        setLowerLevelItems({
          ...lowerLevelItems,
          [itemId]: {
            ...lowerLevelItems[itemId],
            splits: Object.assign([...lowerLevelItems[itemId].splits], {
              [index]: {
                ...lowerLevelItems[itemId].splits[index],
                amount: value,
              },
            }),
          },
        }),
      onSplitPartnerAdd: itemId =>
        setLowerLevelItems({
          ...lowerLevelItems,
          [itemId]: {
            ...lowerLevelItems[itemId],
            splits: lowerLevelItems[itemId].splits.concat([
              { transactionPartner: null, amount: 1 },
            ]),
          },
        }),
      onSplitPartnerRemove: ({ itemId, index }) =>
        setLowerLevelItems({
          ...lowerLevelItems,
          [itemId]: {
            ...lowerLevelItems[itemId],
            splits: lowerLevelItems[itemId].splits.filter(
              (x, i) => i !== index,
            ),
          },
        }),
      onAugmentComponent: ({ itemId, augmentation }) =>
        setLowerLevelItems({
          ...lowerLevelItems,
          [itemId]: {
            ...lowerLevelItems[itemId],
            augmentation,
          },
        }),
      onUniqueItemChange: ({ itemId, uniqueItem }) =>
        setLowerLevelItems({
          ...lowerLevelItems,
          [itemId]: {
            ...lowerLevelItems[itemId],
            uniqueItem,
          },
        }),
    });

  const renderUpperLevelItem =
    (isFreeform || gradeRanges) &&
    renderIOItem({
      items: upperLevelItems,
      style: styles.upperLevel,
      align: 'left',
      grade: !isFreeform && gradeRanges[1],
      onChange: ({ itemId, value }) =>
        setUpperLevelItems({
          ...upperLevelItems,
          [itemId]: {
            ...upperLevelItems[itemId],
            selectedItemId: value,
          },
        }),
      onRemove: itemId =>
        setUpperLevelItems(
          fromPairs(
            Object.keys(upperLevelItems)
              .filter(x => x !== itemId)
              .map(x => [x, upperLevelItems[x]]),
          ),
        ),
      onQuantityChange: ({ itemId, value }) =>
        setUpperLevelItems({
          ...upperLevelItems,
          [itemId]: {
            ...upperLevelItems[itemId],
            amount: value,
          },
        }),
      onPartnerChange: ({ itemId, value }) =>
        setUpperLevelItems({
          ...upperLevelItems,
          [itemId]: {
            ...upperLevelItems[itemId],
            transactionPartner: value,
          },
        }),
      onSplit: itemId =>
        setUpperLevelItems({
          ...upperLevelItems,
          [itemId]: {
            ...upperLevelItems[itemId],
            transactionPartner: null,
            splits: upperLevelItems[itemId].splits
              ? null
              : [{ transactionPartner: null, amount: 1 }],
          },
        }),
      onSplitPartnerChange: ({ itemId, index, value }) =>
        setUpperLevelItems({
          ...upperLevelItems,
          [itemId]: {
            ...upperLevelItems[itemId],
            splits: Object.assign([...upperLevelItems[itemId].splits], {
              [index]: {
                ...upperLevelItems[itemId].splits[index],
                transactionPartner: value,
              },
            }),
          },
        }),
      onSplitAmountChange: ({ itemId, index, value }) =>
        setUpperLevelItems({
          ...upperLevelItems,
          [itemId]: {
            ...upperLevelItems[itemId],
            splits: Object.assign([...upperLevelItems[itemId].splits], {
              [index]: {
                ...upperLevelItems[itemId].splits[index],
                amount: value,
              },
            }),
          },
        }),
      onSplitPartnerAdd: itemId =>
        setUpperLevelItems({
          ...upperLevelItems,
          [itemId]: {
            ...upperLevelItems[itemId],
            splits: upperLevelItems[itemId].splits.concat([
              { transactionPartner: null, amount: 1 },
            ]),
          },
        }),
      onSplitPartnerRemove: ({ itemId, index }) =>
        setUpperLevelItems({
          ...upperLevelItems,
          [itemId]: {
            ...upperLevelItems[itemId],
            splits: upperLevelItems[itemId].splits.filter(
              (x, i) => i !== index,
            ),
          },
        }),
      onAugmentComponent: ({ itemId, augmentation }) =>
        setUpperLevelItems({
          ...upperLevelItems,
          [itemId]: {
            ...upperLevelItems[itemId],
            augmentation,
          },
        }),
      onEmblemChange: ({ itemId, emblem }) =>
        setUpperLevelItems({
          ...upperLevelItems,
          [itemId]: {
            ...upperLevelItems[itemId],
            emblem,
          },
        }),
      onUniqueItemChange:
        isBidirectional &&
        (({ itemId, uniqueItem }) =>
          setUpperLevelItems({
            ...upperLevelItems,
            [itemId]: {
              ...upperLevelItems[itemId],
              uniqueItem,
            },
          })),
    });

  const renderBlueprint = item && item.data && (
    <div>{`${startCase(item.data.kind)}: ${item.data.name}`}</div>
  );

  const handleTaxonomyChange = ({ componentId, value }) => {
    setFilteredCraftingComponents({
      ...filteredCraftingComponents,
      [componentId]: {
        ...filteredCraftingComponents[componentId],
        selectedItemId: value,
      },
    });
  };

  const handleAmountChange = ({ componentId, value }) => {
    setFilteredCraftingComponents({
      ...filteredCraftingComponents,
      [componentId]: {
        ...filteredCraftingComponents[componentId],
        amount: parseInt(value, 10),
      },
    });
  };

  const handlePartnerChange = ({ componentId, value }) => {
    fetchRegisteredItems(value);
    setFilteredCraftingComponents({
      ...filteredCraftingComponents,
      [componentId]: {
        ...filteredCraftingComponents[componentId],
        transactionPartner: value,
      },
    });
  };

  const splitComponent = componentId => {
    setFilteredCraftingComponents({
      ...filteredCraftingComponents,
      [componentId]: {
        ...filteredCraftingComponents[componentId],
        transactionPartner: null,
        splits: filteredCraftingComponents[componentId].splits
          ? null
          : [{ transactionPartner: null, amount: 1 }],
      },
    });
  };

  const handlePartnerSplitChange = ({ componentId, index, value }) => {
    setFilteredCraftingComponents({
      ...filteredCraftingComponents,
      [componentId]: {
        ...filteredCraftingComponents[componentId],
        splits: Object.assign(
          [...filteredCraftingComponents[componentId].splits],
          {
            [index]: {
              ...filteredCraftingComponents[componentId].splits[index],
              transactionPartner: value,
            },
          },
        ),
      },
    });
  };

  const handleSplitAmountChange = ({ componentId, index, value }) => {
    setFilteredCraftingComponents({
      ...filteredCraftingComponents,
      [componentId]: {
        ...filteredCraftingComponents[componentId],
        splits: Object.assign(
          [...filteredCraftingComponents[componentId].splits],
          {
            [index]: {
              ...filteredCraftingComponents[componentId].splits[index],
              amount: value,
            },
          },
        ),
      },
    });
  };

  const handlePartnerSplitAdd = componentId => {
    setFilteredCraftingComponents({
      ...filteredCraftingComponents,
      [componentId]: {
        ...filteredCraftingComponents[componentId],
        splits: filteredCraftingComponents[componentId].splits.concat([
          { transactionPartner: null, amount: 1 },
        ]),
      },
    });
  };

  const handlePartnerSplitRemove = ({ componentId, index }) => {
    setFilteredCraftingComponents({
      ...filteredCraftingComponents,
      [componentId]: {
        ...filteredCraftingComponents[componentId],
        splits: filteredCraftingComponents[componentId].splits.filter(
          (x, i) => i !== index,
        ),
      },
    });
  };

  const handleAddComponent = x => {
    if (filteredCraftingComponents[x.id]) return;

    setFilteredCraftingComponents({
      ...filteredCraftingComponents,
      [x.id]: {
        component: x,
        amount: 1,
        isOverride: true,
      },
    });
  };

  const handleRemoveComponent = componentId =>
    setFilteredCraftingComponents(
      fromPairs(
        Object.keys(filteredCraftingComponents)
          .filter(x => x !== componentId)
          .map(x => [x, filteredCraftingComponents[x]]),
      ),
    );

  const handleAddOutput = x => {
    if (upperLevelItems[x.id]) return;

    setUpperLevelItems({
      ...upperLevelItems,
      [x.id]: {
        ...x,
        amount: 1,
        isOverride: true,
      },
    });
  };

  const renderComponents = Object.keys(filteredCraftingComponents).map(
    componentId => (
      <div key={componentId} className={styles.component}>
        <div className={[styles.mainControl, styles.hoverable].join(' ')}>
          <div
            className={styles.removeComponent}
            onClick={() => handleRemoveComponent(componentId)}
          >
            ✘
          </div>
          {!isBidirectional && !filteredCraftingComponents[componentId].splits && (
            <div className={styles.applicator}>
              <TransactionApplicator
                type='compact'
                onChange={value => handlePartnerChange({ componentId, value })}
                selectedCharacter={
                  filteredCraftingComponents[componentId].transactionPartner
                }
                required
              />
              {!isBidirectional && (
                <div
                  className={styles.split}
                  onClick={() => splitComponent(componentId)}
                >
                  {!filteredCraftingComponents[componentId].splits && 'Split'}
                </div>
              )}
              &rarr;
            </div>
          )}

          {filteredCraftingComponents[componentId].component.kind ===
          'taxonomy' ? (
            <div className={styles.taxonomySelect}>
              <Badge
                text={filteredCraftingComponents[componentId].augmentation}
              />
              <TaxonomySelect
                value={
                  filteredCraftingComponents[componentId].selectedItemId || -1
                }
                onChange={value => handleTaxonomyChange({ componentId, value })}
                itemTaxonomies={itemTaxonomies}
                taxonomyName={
                  filteredCraftingComponents[componentId].component.name
                }
              />
              {renderAugmentationOptions({
                augmentations:
                  AUGMENTATION_KIND_RULES[
                    filteredCraftingComponents[
                      componentId
                    ].component.name.toLowerCase()
                  ],
                onClick: augmentation => {
                  setFilteredCraftingComponents({
                    ...filteredCraftingComponents,
                    [componentId]: {
                      ...filteredCraftingComponents[componentId],
                      augmentation,
                    },
                  });
                },
                currentAugmentation:
                  filteredCraftingComponents[componentId].augmentation,
              })}
            </div>
          ) : (
            <div
              className={[
                styles.name,
                filteredCraftingComponents[componentId].component.kind ===
                  'blueprint' && styles.blueprint,
              ].join(' ')}
            >
              <Badge
                text={filteredCraftingComponents[componentId].augmentation}
              />
              {filteredCraftingComponents[componentId].component.name}
              {filteredCraftingComponents[componentId].component
                .blueprint_crafting_sources &&
                filteredCraftingComponents[componentId].component
                  .blueprint_crafting_sources.length > 0 &&
                ` [${
                  startCase(
                    filteredCraftingComponents[componentId].component.grade,
                  )[0]
                }] `}
              {filteredCraftingComponents[componentId].isOverride &&
                !isFreeform &&
                '*'}
              {filteredCraftingComponents[componentId].uniqueItem && (
                <div className={styles.uniqueItem}>
                  <span className={styles.uniqueItemLeading}>
                    Unique Item:{' '}
                  </span>
                  {
                    filteredCraftingComponents[componentId].uniqueItem
                      .description
                  }
                </div>
              )}
              <div
                className={[styles.augmentationOnly, styles[page]].join(' ')}
              >
                {renderAugmentations({
                  item: filteredCraftingComponents[componentId].component,
                  currentAugmentation:
                    filteredCraftingComponents[componentId].augmentation,
                  onClick: augmentation => {
                    setFilteredCraftingComponents({
                      ...filteredCraftingComponents,
                      [componentId]: {
                        ...filteredCraftingComponents[componentId],
                        augmentation,
                      },
                    });
                  },
                })}
                {renderUniqueItems({
                  item: filteredCraftingComponents[componentId].component,
                  character: isBidirectional
                    ? tradeLeftCharacter
                    : filteredCraftingComponents[componentId]
                        .transactionPartner,
                  selectedUniqueItem:
                    filteredCraftingComponents[componentId].uniqueItem,
                  onClick: uniqueItem => {
                    setFilteredCraftingComponents({
                      ...filteredCraftingComponents,
                      [componentId]: {
                        ...filteredCraftingComponents[componentId],
                        uniqueItem,
                      },
                    });
                  },
                })}
              </div>
            </div>
          )}
          {filteredCraftingComponents[componentId].splits ? (
            <div className={styles.nonEditable}>
              {filteredCraftingComponents[componentId].splits.reduce(
                (a, b) => a + parseInt(b.amount, 10),
                0,
              )}
              /{filteredCraftingComponents[componentId].amount}
            </div>
          ) : (
            <input
              type='number'
              min={1}
              size={2}
              className={styles.amount}
              value={filteredCraftingComponents[componentId].amount}
              onChange={evt =>
                handleAmountChange({ componentId, value: evt.target.value })
              }
            />
          )}
        </div>
        {filteredCraftingComponents[componentId].splits && (
          <div className={styles.splitControl}>
            <div
              className={styles.addMoreSplit}
              onClick={() => handlePartnerSplitAdd(componentId)}
            >
              Add More Splits
            </div>
            {filteredCraftingComponents[componentId].splits.map((x, index) => (
              <div className={styles.split} key={`${componentId}-${index}`}>
                <div
                  className={styles.removeSplit}
                  onClick={() =>
                    handlePartnerSplitRemove({ componentId, index })
                  }
                >
                  ✘
                </div>
                <TransactionApplicator
                  type='compact'
                  onChange={value =>
                    handlePartnerSplitChange({ componentId, index, value })
                  }
                  selectedCharacter={x.transactionPartner}
                  required
                />
                &rarr;
                <input
                  type='number'
                  min={1}
                  size={2}
                  className={styles.amount}
                  value={x.amount || 1}
                  onChange={evt =>
                    handleSplitAmountChange({
                      componentId,
                      index,
                      value: evt.target.value,
                    })
                  }
                />
              </div>
            ))}
            <div className={styles.footerControl}>
              <div
                className={styles.unsplit}
                onClick={() => splitComponent(componentId)}
              >
                Unsplit
              </div>
            </div>
          </div>
        )}
      </div>
    ),
  );

  const generatePayload = () =>
    extractPayload(lowerLevelItems, INVERT)
      .concat(extractPayload(filteredCraftingComponents, INVERT))
      .concat(extractPayload(upperLevelItems))
      .concat(extractUniqueItemsPayload());

  const extractUniqueItemsPayload = () => {
    if (!isFreeform) return [];

    return Object.keys(uniqueItemEntries || {})
      .map(itemId => {
        const { item, data } = uniqueItemEntries[itemId];

        return data.map(row => ({
          item_id: itemId,
          character_id: row.character && row.character.id,
          stack: row.quantity,
          registered_item_description: row.description,
          isSplit: false,
        }));
      })
      .flat();
  };

  const extractPayload = (components, invert = false) => {
    const multiplier = isBidirectional ? 1 : invert ? -1 : 1;

    return Object.keys(components)
      .map(componentId => {
        const component = components[componentId];
        const isTaxonomy =
          component.kind === 'taxonomy' ||
          (component.component && component.component.kind === 'taxonomy');
        const taxonomyOverrideId = component.selectedItemId;

        if (component.splits) {
          return component.splits.map(split => ({
            isTaxonomy,
            taxonomyOverrideId,
            item_id: taxonomyOverrideId || componentId,
            character_id:
              split.transactionPartner && split.transactionPartner.id,
            stack: parseInt(split.amount, 10) * multiplier,
            augmentations: [component.augmentation],
            registered_item_description: component.emblem,
            registered_item_id: component.uniqueItem && component.uniqueItem.id,
            isSplit: true,
          }));
        } else {
          return {
            isTaxonomy,
            taxonomyOverrideId,
            item_id: taxonomyOverrideId || componentId,
            character_id: isBidirectional
              ? invert
                ? tradeRightCharacter && tradeRightCharacter.id
                : tradeLeftCharacter && tradeLeftCharacter.id
              : component.transactionPartner && component.transactionPartner.id,
            stack: parseInt(component.amount, 10) * multiplier,
            augmentations: [component.augmentation],
            registered_item_description: component.emblem,
            registered_item_id: component.uniqueItem && component.uniqueItem.id,
            transaction_partner_id:
              isBidirectional &&
              (invert
                ? tradeLeftCharacter && tradeLeftCharacter.id
                : tradeRightCharacter && tradeRightCharacter.id),
          };
        }
      })
      .flat();
  };

  const hasSplit = useMemo(() => {
    const payload = generatePayload();

    return payload.some(x => x.isSplit);
  }, [generatePayload]);

  const isPayloadValid = useCallback(() => {
    const payload = generatePayload();

    if (payload.length === 0) return false;

    const validPartners = payload
      .map(x => !!x.character_id)
      .flat()
      .reduce((a, b) => a && b, true);

    const validTaxonomies = payload
      .filter(x => x.isTaxonomy)
      .reduce((a, b) => a && !!b.taxonomyOverrideId, true);

    return validPartners && validTaxonomies;
  }, [generatePayload]);

  const executeTransaction = useCallback(() => {
    if (!isPayloadValid()) return;

    dispatch({
      type: 'EXECUTE_TRANSACTION',
      payload: {
        description: transactionDetails,
        repeats: repeatOperation,
        transactions: generatePayload(),
      },
    });
  }, [generatePayload, isPayloadValid, repeatOperation]);

  const successfulTransactionId =
    lastTransactions &&
    lastTransactions.filter(x => !x.parent_transaction_id)[0].id;

  useEffect(() => {
    if (hasSplit) {
      setRepeatOperation(1);
    }
  }, [hasSplit]);

  useEffect(() => {
    if (!(item && item.data)) {
      setCraftableItems([]);
      setGradeRanges(null);
      setGradedItems([]);
      setOutputStacks({});
      setKind('');

      return;
    }

    setKind(item.data.kind);
    setCraftableItems(item.data.craftable_items);
    setGradeRanges(null);

    const sortedItemGrades = item.data.craftable_items.sort(
      (a, b) => GRADE_SORT_REAL[a.grade] - GRADE_SORT_REAL[b.grade],
    );
    setGradedItems(
      [{ id: -1, name: 'From Scratch' }].concat(
        sortedItemGrades.map(x => ({ id: x.id, name: x.name })),
      ),
    );
    setGradeRanges([0, 1]);
    setCraftingComponents(
      groupBy(
        item.data.crafting_components.sort((a, b) => a.amount - b.amount),
        x => x.component.id,
      ),
    );
    setOutputStacks(
      groupBy(
        item.data.item_craftings
          .map(x => x.crafting_final_products)
          .flat()
          .sort((a, b) => a.stack - b.stack),
        x => x.final_product_id,
      ),
    );
  }, [item]);

  useEffect(() => {
    recalculateComponents(gradeRanges);
  }, [gradeRanges]);

  useEffect(() => {
    fetchTaxonomies(craftingComponents);
  }, [craftingComponents]);

  useEffect(() => {
    fetchTaxonomies(
      fromPairs(craftableItems.map(x => [x.id, { component: x }])),
    );
  }, [craftableItems]);

  useEffect(() => {
    if (!lastTransactions) return;

    resetTable();
  }, [lastTransactions]);

  useEffect(() => {
    if (page === 'crafting') resetTable();
  }, [page, resetTable]);

  useEffect(() => {
    const isEmptyTable = [
      filteredCraftingComponents,
      lowerLevelItems,
      upperLevelItems,
      uniqueItemEntries,
    ]
      .map(x => Object.keys(x).length === 0)
      .reduce((a, b) => a && b, true);
    const isEmptyScalar =
      transactionDetails.trim().length === 0 &&
      tradeLeftCharacter === null &&
      tradeRightCharacter === null;

    if (isEmptyTable && isEmptyScalar) return;

    const struct = {
      filteredCraftingComponents,
      lowerLevelItems,
      upperLevelItems,
      transactionDetails,
      tradeLeftCharacter,
      tradeRightCharacter,
      uniqueItemEntries,
    };

    setLsUi(struct);
  }, [
    filteredCraftingComponents,
    lowerLevelItems,
    upperLevelItems,
    transactionDetails,
    tradeLeftCharacter,
    tradeRightCharacter,
    uniqueItemEntries,
  ]);

  useEffect(() => {
    if (page === 'crafting') return;
    if (!getLsUi || (getLsUi && getLsUi.page !== page))
      return resetTable(KEEP_LS);

    setFilteredCraftingComponents(getLsUi.filteredCraftingComponents);
    setLowerLevelItems(getLsUi.lowerLevelItems);
    setUpperLevelItems(getLsUi.upperLevelItems);
    setTradeLeftCharacter(getLsUi.tradeLeftCharacter);
    setTradeRightCharacter(getLsUi.tradeRightCharacter);
    setTransactionDetails(getLsUi.transactionDetails);
    setUniqueItemEntries(getLsUi.uniqueItemEntries);
  }, [getLsUi, page, resetTable]);

  useEffect(() => {
    if (isBidirectional && tradeLeftCharacter)
      fetchRegisteredItems(tradeLeftCharacter);
  }, [isBidirectional, tradeLeftCharacter]);

  useEffect(() => {
    if (isBidirectional && tradeLeftCharacter)
      fetchRegisteredItems(tradeRightCharacter);
  }, [isBidirectional, tradeRightCharacter]);

  return (
    <div className={styles.container}>
      <ItemTransactionTutorial page={page} />
      <div className={styles.blueprintSearch}>
        {!isFreeform && (
          <ItemSearch
            includeSynonyms
            includeDescription={false}
            placeholder='Search Blueprints/Buy Lists/Conversions'
            filters={['blueprint', 'buy_list', 'conversion']}
            renderItemDetails={x => {
              if (item && x.data && item.data && x.data.id === item.data.id)
                return;

              setItem(x);
            }}
          />
        )}
      </div>
      {!isFreeform && (
        <div className={styles.craftingControl}>
          <div className={styles.blueprint}>{renderBlueprint}</div>
          <div className={styles.slider}>{renderCraftableItems()}</div>
          <div className={styles.progression}>{renderGradeRanges()}</div>
        </div>
      )}
      {(item || isFreeform) && (
        <div className={styles.globalApplicator}>
          {isBidirectional && (
            <div
              className={[
                styles.apply,
                styles.left,
                !globalSelectedCharacter && styles.inactive,
                globalSelectedCharacter &&
                  tradeRightCharacter &&
                  globalSelectedCharacter.user.id ===
                    tradeRightCharacter.user.id &&
                  styles.inactive,
              ].join(' ')}
              onClick={() => applyGlobalApplicator({ setLeft: true })}
            >
              &larr; Set
              <div className={styles.partner}>
                {tradeLeftCharacter &&
                  `${tradeLeftCharacter.user.id} - ${tradeLeftCharacter.name}`}
              </div>
            </div>
          )}
          <div className={isBidirectional && styles.applicator}>
            <TransactionApplicator
              onChange={value => setGlobalSelectedCharacter(value)}
              selectedCharacter={globalSelectedCharacter}
            />
          </div>
          {isBidirectional ? (
            <div
              className={[
                styles.apply,
                styles.right,
                !globalSelectedCharacter && styles.inactive,
                globalSelectedCharacter &&
                  tradeLeftCharacter &&
                  globalSelectedCharacter.user.id ===
                    tradeLeftCharacter.user.id &&
                  styles.inactive,
              ].join(' ')}
              onClick={() => applyGlobalApplicator({ setRight: true })}
            >
              Set &rarr;
              <div className={styles.partner}>
                {tradeRightCharacter &&
                  `${tradeRightCharacter.user.id} - ${tradeRightCharacter.name}`}
              </div>
            </div>
          ) : (
            <div
              className={[
                styles.apply,
                !globalSelectedCharacter && styles.inactive,
              ].join(' ')}
              onClick={applyGlobalApplicator}
            >
              Set All
            </div>
          )}
        </div>
      )}
      <div className={styles.transactionTable}>
        {isBidirectional && tradeLeftCharacter && (
          <div className={styles.right}>
            {`Receives Items from ${tradeLeftCharacter.name}`}
          </div>
        )}
        {item && !isFreeform && (
          <div className={styles.repeatTop}>
            {!hasSplit && (
              <div className={styles.repeat}>
                Repeat
                <input
                  type='number'
                  min={1}
                  className={styles.amount}
                  value={repeatOperation}
                  onChange={evt => setRepeatOperation(evt.target.value, 10)}
                />
                times
              </div>
            )}
            {hasSplit ? (
              <div className={styles.repeatInfo}>
                Repeat is disabled when input/output are Split
              </div>
            ) : (
              <div className={styles.repeatInfo}>
                Repeating a Transaction automatically multiply ingredients
                quantity. Please make sure the Transaction Configuration above
                is accurate for ONE transaction only.
              </div>
            )}
          </div>
        )}
        {Object.keys(lowerLevelItems).length > 0 && (
          <div className={styles.lowerLevelItems}>{renderLowerLevelItem}</div>
        )}
        <div
          className={[
            styles.components,
            isBidirectional && styles.bidirectional,
          ].join(' ')}
        >
          {renderComponents}
        </div>

        {(isFreeform || item) && (
          <div>
            <ItemSearch
              placeholder={
                isBidirectional
                  ? 'Add Item →'
                  : isFreeform
                  ? 'Item To Postal'
                  : 'Add Component'
              }
              renderItemDetails={x => {
                if (x && x.data) handleAddComponent(x.data);
              }}
              isLocal
              isTangible
            />
          </div>
        )}

        {isBidirectional && tradeRightCharacter && (
          <div>{`Receives Items from ${tradeRightCharacter.name}`}</div>
        )}

        {Object.keys(upperLevelItems).length > 0 && (
          <div className={styles.upperLevelItems}>{renderUpperLevelItem}</div>
        )}

        {(isFreeform || item) && (
          <div>
            <ItemSearch
              placeholder={
                isBidirectional
                  ? '← Add Item'
                  : isFreeform
                  ? 'Item To Player'
                  : 'Add Output'
              }
              renderItemDetails={x => {
                if (x && x.data) handleAddOutput(x.data);
              }}
              isLocal
              isTangible
              align='left'
            />
          </div>
        )}
      </div>

      {page === 'postal' && (
        <div>
          <ItemSearch
            placeholder='Batch Unique Item to Player'
            renderItemDetails={x => {
              if (x && x.data) handleAddBatchUniqueItem(x.data);
            }}
            isLocal
            isTangible
            align='left'
          />
        </div>
      )}

      {Object.keys(uniqueItemEntries).map(itemId => (
        <ItemTransactionUnique
          key={itemId}
          entry={uniqueItemEntries[itemId]}
          onDescriptionUpdate={({ field, value, index }) =>
            handleUniqueItemsDescriptionUpdate({ itemId, field, value, index })
          }
          onDescriptionAdd={() => handleUniqueItemsDescriptionAdd({ itemId })}
          onDescriptionRemove={index =>
            handleUniqueItemsDescriptionRemove({ itemId, index })
          }
          onRemoveBatch={() => handleRemoveBatch(itemId)}
        />
      ))}

      {(isFreeform || item) && (
        <div className={styles.description}>
          <textarea
            rows={4}
            placeholder='Add transaction details (optional)'
            className={styles.textarea}
            value={transactionDetails}
            onChange={evt => setTransactionDetails(evt.target.value)}
          />
        </div>
      )}
      {(isFreeform || item) && (
        <div className={styles.executionControl}>
          <div className={styles.reset}>
            <button
              type='button'
              className={styles.button}
              onClick={resetTable}
            >
              Reset
            </button>
          </div>
          <div className={styles.execute}>
            {!isFreeform && !hasSplit && (
              <div className={styles.repeat}>
                Repeat
                <input
                  type='number'
                  min={1}
                  className={styles.amount}
                  value={repeatOperation}
                  onChange={evt => setRepeatOperation(evt.target.value, 10)}
                />
                times
              </div>
            )}
            <div className={styles.executeButton}>
              <button
                type='button'
                onClick={executeTransaction}
                className={[
                  styles.button,
                  (!isPayloadValid() || isTransactionExecuting) &&
                    styles.disabled,
                ].join(' ')}
              >
                {isTransactionExecuting
                  ? 'Executing...'
                  : isFreeform
                  ? 'Commit Transaction'
                  : `Perform ${repeatOperation} times`}
              </button>
            </div>
          </div>
        </div>
      )}
      {lastTransactions && lastTransactions.length > 0 && (
        <div className={styles.successfulTransaction}>
          Transaction
          <a
            href={`/admin_transactions/${eventInfo.id}/audit/${successfulTransactionId}`}
            target='_successfulTransactionRecord'
            className={styles.link}
          >
            {successfulTransactionId}
          </a>
          Succeeded
        </div>
      )}
    </div>
  );
};
