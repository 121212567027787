import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ParticipantIcon from '@material-ui/icons/EmojiPeople';
import { useDispatch } from 'react-redux';
import { compareAsc, formatDistanceToNow, parseISO } from 'date-fns';
import PaymentConfirmedIcon from '@material-ui/icons/MonetizationOn';
import useInterval from '@use-it/interval';
import skillInitializer from '../../../gameData/skillInitializer';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextArea from '../../../Shared/FormComponents/TextArea';
import styles from '../ModControls.module.scss';

export default ({
  mod,
  modSectionID,
  modsUI,
  modState,
  modParticipants,
  newModName,
  newModButtonState,
  updateModState,
  updateNewModName,
  addMod,
  maybeUpdateModUpstream,
  kickModParticipant,
  fetchModParticipants,
}) => {
  const dispatch = useDispatch();
  const [beingKicked, setBeingKicked] = useState(null);
  const [lastRefresh, setLastRefresh] = useState(Date.now());
  const [refreshDiff, setRefreshDiff] = useState('just now');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const toggleMod = id => dispatch({ type: 'TOGGLE_MOD', payload: id });
  const addModButtonClass = modSectionID =>
    !(
      newModName &&
      newModName[modSectionID] &&
      newModName[modSectionID].trim().length > 0
    ) && styles.disabled;
  const newModButtonTitle = modSectionID => {
    if (!newModButtonState) return;
    switch (newModButtonState[modSectionID]) {
      case 'init':
        return 'Add Mod';
      case 'processing':
        return 'Adding...';
      default:
        return 'Huh???';
    }
  };
  const skillConstraint = modID => {
    if (!(modState.constraint && modState.constraint[modID])) return 0;

    const skillConstraints = Object.values(modState.constraint[modID]).map(
      x => x.skill_id,
    );

    return skillConstraints[0] || 0;
  };
  const updateSkillConstraintChange = (modID, val) => {
    const value = val === 0 ? [] : [{ skill_id: val }];
    updateModState({
      id: modID,
      field: 'constraint',
      value: value,
    });
    maybeUpdateModUpstream({
      field: 'constraint',
      id: modID,
      alwaysUpdate: value,
    });
  };
  const maybeKick = (id, name) => {
    const modName = modState.name[mod.id];
    if (
      window.confirm(
        `Kick ${name} from ${modName}? This action is logged and irreversible!`,
      )
    ) {
      setBeingKicked(id);
      kickModParticipant({ modID: mod.id, playerID: id });
    }
  };
  const { skills } = skillInitializer();
  const skillOptions = Object.keys(skills)
    .sort((a, b) => skills[b].name.localeCompare(skills[a].name))
    .map(skill => (
      <MenuItem
        key={skill}
        value={skills[skill].remoteID[0] || skills[skill].remoteID[1]}
        className={styles.item}
      >
        {skills[skill].name}
      </MenuItem>
    ))
    .concat(
      <MenuItem key={'unrestricted'} value={0} className={styles.item}>
        Unrestricted
      </MenuItem>,
    )
    .reverse();

  const renderToggle = mod => (
    <div className={styles.modToggle} onClick={() => toggleMod(mod.id)}>
      {modsUI[mod.id] === 'expanded' ? (
        <ExpandLess className={styles.icon} />
      ) : (
        <ExpandMore className={styles.icon} />
      )}
    </div>
  );
  const renderModName = mod => (
    <div className={[styles.modName, mod && styles.persisted].join(' ')}>
      <div className={styles.input}>
        <input
          type='text'
          placeholder='Mod Name (Required, ex: Licking the Gravemind)'
          className={styles.modInput}
          value={(mod ? modState.name[mod.id] : newModName[modSectionID]) || ''}
          onChange={evt =>
            mod
              ? updateModState({
                  id: mod.id,
                  field: 'name',
                  value: evt.target.value,
                })
              : updateNewModName({
                  id: modSectionID,
                  value: evt.target.value,
                })
          }
          onBlur={() =>
            mod && maybeUpdateModUpstream({ field: 'name', id: mod.id })
          }
          onKeyDown={evt => {
            if (evt.keyCode !== 13) return;
            mod
              ? maybeUpdateModUpstream({ field: 'name', id: mod.id })
              : addMod(modSectionID);
          }}
        />
      </div>
    </div>
  );
  const renderModLimitOrAddMod = mod =>
    mod ? renderModLimit(mod) : renderAddMod;
  const renderModLimit = mod => (
    <div className={styles.modLimit}>
      <div className={styles.participantsCount}>
        {mod && modParticipants[mod.id] && modParticipants[mod.id].length > 0 && (
          <span>
            {modParticipants[mod.id].length}
            <ParticipantIcon className={styles.icon} />
          </span>
        )}
      </div>
      <div className={styles.modLimitInputWrapper}>
        <input
          type='text'
          data-tip='Number of Players allowed to join this Mod. Enter 0 to hide this Mod from Players.'
          data-for='mod'
          className={styles.modParticipationLimit}
          value={modState.participationLimit[mod.id] || 0}
          onChange={evt =>
            updateModState({
              id: mod.id,
              field: 'participationLimit',
              value: evt.target.value,
            })
          }
          onBlur={() =>
            mod &&
            maybeUpdateModUpstream({
              field: 'participationLimit',
              id: mod.id,
            })
          }
          onKeyDown={evt =>
            evt.keyCode === 13 &&
            mod &&
            maybeUpdateModUpstream({
              field: 'participationLimit',
              id: mod.id,
            })
          }
        />
      </div>
    </div>
  );
  const renderAddMod = (
    <div className={[styles.modAdd, addModButtonClass(modSectionID)].join(' ')}>
      <button
        type='button'
        className={styles.modAddButton}
        onClick={() => addMod(modSectionID)}
      >
        {newModButtonTitle(modSectionID)}
      </button>
    </div>
  );
  const renderAuxilliarySettings = mod =>
    modsUI[mod.id] === 'expanded' && (
      <div className={[styles.modRow, styles.shifted].join(' ')}>
        <div
          className={[styles.modTimeframe, mod && styles.persisted].join(' ')}
        >
          <input
            type='text'
            placeholder='Mod Time (Optional, ex: Sat 5PM - 7PM)'
            className={styles.modInput}
            value={modState.timeframe[mod.id] || ''}
            onChange={evt =>
              updateModState({
                id: mod.id,
                field: 'timeframe',
                value: evt.target.value,
              })
            }
            onBlur={() =>
              mod && maybeUpdateModUpstream({ field: 'timeframe', id: mod.id })
            }
            onKeyDown={evt =>
              evt.keyCode === 13 &&
              mod &&
              maybeUpdateModUpstream({ field: 'timeframe', id: mod.id })
            }
          />
        </div>
        <div className={styles.modSkillRequirement}>
          <Select
            className={styles.skillSelect}
            classes={{
              select: styles.select,
            }}
            value={skillConstraint(mod.id)}
            onChange={evt =>
              updateSkillConstraintChange(mod.id, evt.target.value)
            }
            MenuProps={{
              className: styles.menu,
            }}
            disableUnderline
          >
            {skillOptions}
          </Select>
        </div>
      </div>
    );
  const renderModDescription = mod =>
    modsUI[mod.id] === 'expanded' && (
      <div className={[styles.modRow, styles.shifted].join(' ')}>
        <TextArea
          placeholder='Mod description (optional)'
          content={modState.description[mod.id]}
          handleUpdate={evt =>
            updateModState({
              id: mod.id,
              field: 'description',
              value: evt.target.value,
            })
          }
          handleBlur={() =>
            maybeUpdateModUpstream({ field: 'description', id: mod.id })
          }
        />
      </div>
    );
  const renderParticipantConstraint = (admissibleCharacters, hasConstraint) => {
    if (!hasConstraint) return;
    if (admissibleCharacters.length === 0)
      return (
        <div className={styles.invalidCharacters}>
          No Eligible Characters for this Mod
        </div>
      );

    return (
      <div className={styles.admissibleCharacters}>
        Eligible Characters: &nbsp;
        {admissibleCharacters.map(character => character.name).join(', ')}
      </div>
    );
  };
  const renderModParticipant = (
    participant,
    position,
    limit,
    hasConstraint,
  ) => {
    const playerID = participant.event_attendee.user.id;
    const playerName = [
      participant.event_attendee.user.first_name,
      participant.event_attendee.user.last_name,
    ]
      .join(' ')
      .trim();
    return (
      <React.Fragment>
        {position === limit && (
          <div className={styles.waitlistSeparator}>
            <div className={styles.text}>Waitlist</div>
            <hr className={styles.separator} />
          </div>
        )}
        <div className={styles.modParticipant}>
          <div className={styles.position}>{`#${position + 1}`}</div>
          <div className={styles.persona}>
            <div className={styles.id}>
              {`#${playerID}`}
              <span
                className={[
                  styles.paidStatus,
                  !participant.event_attendee.paid && styles.unpaid,
                ].join(' ')}
              >
                <PaymentConfirmedIcon className={styles.icon} />
              </span>
            </div>
            <div className={styles.name}>
              <div>{playerName}</div>
              <div className={styles.characters}>
                {renderParticipantConstraint(
                  participant.admissible_characters,
                  hasConstraint,
                )}
              </div>
            </div>
            <div
              className={[
                styles.kick,
                beingKicked === playerID && styles.kicking,
              ].join(' ')}
              onClick={() =>
                maybeKick(participant.event_attendee.user.id, playerName)
              }
            >
              {beingKicked === playerID ? 'Kicking...' : 'Kick'}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  const renderModParticipants = mod =>
    modsUI[mod.id] === 'expanded' && (
      <div
        className={[styles.modRow, styles.shifted, styles.modParticipants].join(
          ' ',
        )}
      >
        {modParticipants[mod.id] &&
          modParticipants[mod.id]
            .sort((a, b) =>
              compareAsc(parseISO(a.admitted_at), parseISO(b.admitted_at)),
            )
            .map((x, i) =>
              renderModParticipant(
                x,
                i,
                modState.participationLimit[mod.id],
                modState.constraint[mod.id] &&
                  modState.constraint[mod.id].length > 0,
              ),
            )}
      </div>
    );
  const renderRefreshButton = mod =>
    modsUI[mod.id] === 'expanded' && (
      <div className={styles.refresh}>
        <div className={styles.text}>{`Updated ${refreshDiff} ago.`}</div>
        <button
          className={[
            styles.refreshButton,
            isRefreshing && styles.disabled,
          ].join(' ')}
          disabled={isRefreshing}
          onClick={() => {
            fetchModParticipants({ withToast: true });
            setIsRefreshing(true);
          }}
        >
          {isRefreshing ? 'Refreshing...' : 'Refresh'}
        </button>
      </div>
    );

  useEffect(() => {
    setBeingKicked(null);
    setLastRefresh(Date.now());
    setIsRefreshing(false);
  }, [modParticipants]);

  useInterval(() => {
    setRefreshDiff(
      formatDistanceToNow(lastRefresh, Date.now, { addSuffix: true }),
    );
  }, 1000);

  return (
    <div className={styles.mod}>
      <ReactTooltip
        id='mod'
        place='left'
        effect='solid'
        className={styles.tooltipInverse}
      />
      <div className={styles.modRow}>
        {mod && renderToggle(mod)}
        {renderModName(mod)}
        {renderModLimitOrAddMod(mod)}
      </div>
      {mod && renderAuxilliarySettings(mod)}
      {mod && renderModDescription(mod)}
      {mod && renderModParticipants(mod)}
      {mod && renderRefreshButton(mod)}
    </div>
  );
};
