export const initialState = {
  isFetching: false,
  data: {},
};

const mergeAttendanceCategory = (init, category, data) => {
  const baseAttendanceState = init && init.attendances ? init.attendances : {};

  return Object.assign(baseAttendanceState, { [category]: data });
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'FETCH_BRANCH_TRANSFERS':
    case 'FETCH_BRANCH_ATTENDANCES':
    case 'FETCH_BRANCH_FINANCIALS':
    case 'FETCH_BRANCH_MEMBERSHIPS':
      return {
        ...state,
        isFetching: true,
      };
    case 'FETCH_BRANCH_FINANCIALS_SUCCEEDED':
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          financials: payload.data,
        },
      };
    case 'FETCH_BRANCH_MEMBERSHIPS_SUCCEEDED':
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          memberships: payload.data,
        },
      };
    case 'FETCH_BRANCH_LOCATIONS':
      return {
        ...state,
        isFetching: true,
      };
    case 'FETCH_BRANCH_LOCATIONS_SUCCEEDED':
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          [payload.branchId]: {
            ...state.data[payload.branchId],
            locations: payload.locations,
          },
        },
      };
    case 'FETCH_BRANCH_REVENUE_SUCCEEDED':
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          [payload.branchID]: {
            ...state.data[payload.branchID],
            revenue: payload.data,
          },
        },
      };
    case 'FETCH_BRANCH_TICKETS_SUCCEEDED':
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          [payload.branchID]: {
            ...state.data[payload.branchID],
            tickets: payload.data,
          },
        },
      };
    case 'UPDATE_BRANCH_LOCATION':
      return {
        ...state,
        data: {
          ...state.data,
          [payload.branchId]: {
            ...state.data[payload.branchId],
            locations: {
              ...state.data[payload.branchId].locations,
              [payload.locationId]: {
                ...state.data[payload.branchId].locations[payload.locationId],
                location: payload.locationData,
              },
            },
          },
        },
      };
    case 'FETCH_BRANCH_TRANSFERS_SUCCEEDED':
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          ...{
            [payload.branchID]: {
              ...state.data[payload.branchID],
              ...{
                transfers: payload.data,
              },
            },
          },
        },
      };
    case 'FETCH_BRANCH_ATTENDANCES_SUCCEEDED':
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          ...{
            [payload.branchID]: {
              ...state.data[payload.branchID],
              attendances: mergeAttendanceCategory(
                state.data[payload.branchID],
                payload.category,
                payload.data,
              ),
            },
          },
        },
      };
    default:
      return state;
  }
};
