import React from 'react';
import LoreGrid from '../../../Shared/Lore/Grid';

const Container = ({
  isEditable,
  isAcquired,
  isPersisted,
  passClick,
  name,
  selector,
}) => {
  const handleClick = () => {
    passClick(selector);
  };

  return (
    <LoreGrid
      handleClick={handleClick}
      isEditable={isEditable}
      isAcquired={isAcquired}
      isPersisted={isPersisted}
      name={name}
    />
  );
};

export default Container;
