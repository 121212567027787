import { useDispatch } from 'react-redux';
import React from 'react';
import Edit from '@material-ui/icons/Edit';
import GuideIcon from '@material-ui/icons/SupervisedUserCircle';
import branches from '../gameData/branches';
import styles from './UserMetadata.module.scss';
import { membershipValidityPeriod } from '../utils/user';
import history from '../history';

export default ({ user, impersonating }) => {
  const dispatch = useDispatch();
  const membershipValidity = membershipValidityPeriod(user);
  const isCharacterEditorPage =
    history.location.pathname === '/' ||
    history.location.pathname.match(/characters/);

  const openUserEditModal = () => {
    history.push('/');
    dispatch({
      type: isCharacterEditorPage ? 'TOGGLE_USER_EDITOR' : 'SHOW_USER_EDITOR',
      payload: user.id,
    });
  };
  const renderMembershipValidity = () =>
    membershipValidity
      ? `Exp. ${membershipValidity.calendarDate} (${membershipValidity.humanDate})`
      : null;

  const isReallyError = user && user.isError && !user.id;
  const playerID = () => (
    <div className={styles.playerID}>
      {isReallyError ? (
        `Player #${user.id} not found`
      ) : (
        <React.Fragment>
          <div>
            {`#${user.id} `}(
            {branches[user.branchId]
              ? branches[user.branchId].shortName
              : 'Unknown'}
            )
          </div>
          <Edit className={styles.edit} onClick={openUserEditModal} />
        </React.Fragment>
      )}
    </div>
  );

  const playerInfo = () => (
    <React.Fragment>
      <div className={styles.playerName}>
        {[user.firstName, user.lastName].join(' ').trim()}
        {user.role === 'guide' ? (
          <GuideIcon fontSize='small' className={styles.icon} />
        ) : null}
      </div>
      <div>{user.emailAddress}</div>
      <div>
        {user.membership === 'basic'
          ? 'Basic Membership'
          : user.memberships
          ? renderMembershipValidity()
          : null}
      </div>
    </React.Fragment>
  );

  return (
    <div
      className={[
        styles.playerMetadata,
        impersonating && !isReallyError ? styles.impersonating : '',
        isReallyError ? styles.error : '',
      ].join(' ')}
    >
      {playerID()}
      {isReallyError ? '' : playerInfo()}
    </div>
  );
};
