import React, { useEffect, useState } from 'react';
import TextField from '../../Shared/FormComponents/TextField';
import styles from './AssistedSearch.module.scss';

export default ({ passSearch, impersonatee, error }) => {
  const [searchText, setSearchText] = useState('');
  const handleChange = (_, value) => setSearchText(value);
  const handleSearch = () => searchText.length > 0 && passSearch(searchText);
  const handleKeyDown = event => (event.keyCode === 13 ? handleSearch() : null);
  const impersonateeMetadata = () =>
    error ? (
      error
    ) : impersonatee.id ? (
      <div className={styles.impersonatee}>
        {`#${impersonatee.id} - `}
        {`${[impersonatee.firstName, impersonatee.lastName].join(' ').trim()}`}
      </div>
    ) : null;

  useEffect(() => {
    setSearchText('');
  }, [impersonatee]);

  return (
    <div className={styles.container}>
      <div className={styles.searchBar}>
        <div className={styles.inputField}>
          <TextField
            fieldKey='searchID'
            title='Player ID'
            revert='never'
            value={searchText}
            passChange={handleChange}
            autoFocus={true}
            passKeyDown={handleKeyDown}
          />
        </div>
        <div className={styles.button} onClick={handleSearch}>
          Search
        </div>
      </div>
      {impersonateeMetadata()}
    </div>
  );
};
