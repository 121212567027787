import React from 'react';
import styles from './Cell.module.scss';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

export default ({
  handleClick,
  infoExpanded,
  category,
  tier,
  levelAcquired,
  achievementIsAcquired,
  remoteLevelAcquired,
  remoteAchievementIsAcquired,
  visible,
  innate,
  isEditable,
  name,
  styleOverride,
  resolveLocked,
}) => {
  const getClassName = () => {
    const classCategoryTier = category + tier;
    const classTier = `tier${tier}`;
    const classUnusedTier = `unused${tier}`;
    const classes = [
      `${styles.cell}`,
      `${styles[classCategoryTier]}`,
      `${styles[classTier]}`,
    ];

    switch (tier) {
      case 0:
        classes.push('skill-lead-left');
        break;
      case 4:
        if (!achievementIsAcquired) classes.push(`${styles[classUnusedTier]}`);
        if (
          !isEditable &&
          !remoteAchievementIsAcquired &&
          achievementIsAcquired
        ) {
          classes.push(styles.planned);
        }
        break;
      default:
        if (innate && tier === 1) {
          // do nothing on innate tier 1
        } else if (levelAcquired < tier) {
          classes.push(styles[classUnusedTier]);
        } else if (
          !isEditable &&
          remoteLevelAcquired < levelAcquired &&
          tier > remoteLevelAcquired
        ) {
          classes.push(styles.planned);
        }
    }

    if (innate) classes.push('skill-innate');
    if (!visible) classes.push(`${styles.hidden}`);

    return classes.join(' ');
  };

  const getTextualRepresentation = () => {
    if (tier === 0) {
      return infoExpanded ? (
        <ExpandLess fontSize='small' />
      ) : (
        <ExpandMore fontSize='small' />
      );
    }

    return (
      <div
        className={[styles.text, resolveLocked && styles.resolveLocked].join(
          ' ',
        )}
      >
        {name}
        {resolveLocked && <div className={styles.resolveLocked}>R</div>}
      </div>
    );
  };

  return (
    <div className={getClassName()} onClick={handleClick} style={styleOverride}>
      {getTextualRepresentation()}
    </div>
  );
};
