import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { toast } from 'react-toastify';
import React from 'react';
import NavBar from './Navigation/Bar';
import store, { runSaga } from './store';
import history from './history';
import LocalStorageDaemon from './LocalStorage/Daemon';
import CreateUserModal from './Shared/Modals/CreateUserModal';
import CreateCharacterModal from './Shared/Modals/CreateCharacterModal';
import EditUserModal from './Shared/Modals/EditUserModal';
import LoginModal from './Shared/Modals/LoginModal';
import SearchModal from './Shared/Modals/SearchModal';
import Sidebar from './Navigation/Sidebar';
import ErrorBoundary from './ErrorBoundary';
import rootSaga from './saga';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBoxOpen,
  faCog,
  faExchangeAlt,
  faExclamationCircle,
  faMailBulk,
  faTools,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

import Router from './Router';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toggle/style.css';
import './App.scss';

library.add(
  faBoxOpen,
  faCog,
  faExchangeAlt,
  faExclamationCircle,
  faMailBulk,
  faTools,
  faUsers,
);

toast.configure({
  position: toast.POSITION.TOP_LEFT,
});

export default () => {
  runSaga(rootSaga);

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <NavBar />
          <div className='main-content'>
            <div className='sidebar'>
              <Sidebar />
            </div>
            <div className='routerable'>
              <Router />
            </div>
          </div>
          <LocalStorageDaemon />
          <CreateUserModal />
          <CreateCharacterModal />
          <EditUserModal />
          <SearchModal />
          <LoginModal />
        </ConnectedRouter>
      </Provider>
    </ErrorBoundary>
  );
};
