import { cloneDeep, fromPairs } from 'lodash';
import SkillInitializer from '../../../gameData/skillInitializer';
import { initialState as blankGridTemplate } from '../../reducers/skills';

export const validateTiers = (skillState, skillLevels, totalBuild) => {
  const skillXPAtTier = t =>
    Object.keys(skillState)
      .map(category => skillState[category][t].build)
      .reduce((a, b) => a + b, 0);

  const skillCountAtTier = t =>
    Object.keys(skillLevels).filter(x => skillLevels[x].levelAcquired === t)
      .length;

  const hasSkillAtTierHigherThan = t =>
    Object.keys(skillState)
      .map(category =>
        Object.keys(skillState[category])
          .filter(x => parseInt(x, 10) > t)
          .filter(x => (t === 3 ? true : parseInt(x, 10) !== 4)) // bypass if T4
          .map(x => skillState[category][x].count > 0)
          .reduce((a, b) => a || b, false),
      )
      .reduce((a, b) => a || b, false);

  const t2Barrier = 50;
  const t25Barrier = 75;
  const t3Barrier = 150;
  const t4Barrier = 100;
  const t2SkillsXP = skillXPAtTier(2);
  const t3SkillsXP = skillXPAtTier(3);
  const t4SkillsXP = skillXPAtTier(4);

  const xpThreshold = totalBuild.used; //totalBuild.earned || totalBuild.used;
  const baselineXP = t => {
    switch (t) {
      case 1:
        return xpThreshold - t2SkillsXP - t3SkillsXP;
      case 2:
        return xpThreshold - t3SkillsXP;
      case '25':
        return xpThreshold - t3SkillsXP + (t25Expansion() ? 4 : 0); //(skillCountAtTier(3) < 1 ? 0 : 4);
      case 3:
        return xpThreshold - t4SkillsXP;
      default:
        return 0;
    }
  };

  const baselineAtTier = t => {
    switch (t) {
      case 2:
        return baselineXP(1) >= t2Barrier;
      case '25':
        return baselineXP(2) >= t25Barrier;
      case 3:
        return baselineXP('25') >= t3Barrier;
      case 4:
        return baselineXP(3) >= t4Barrier;
      default:
        return false;
    }
  };
  const t2Expansion = () =>
    !hasSkillAtTierHigherThan(1) ||
    (hasSkillAtTierHigherThan(1) && baselineAtTier(2));
  const t25Expansion = () => {
    if (!hasSkillAtTierHigherThan(2)) return true;
    return baselineAtTier('25');
  };
  const t3Expansion = () => {
    if (skillCountAtTier(3) > 1) return baselineAtTier(3);
    return true;
  };
  const t4Expansion = () =>
    !hasSkillAtTierHigherThan(3) ||
    (hasSkillAtTierHigherThan(3) && baselineAtTier(4));
  const calcMinBaseline = () => {
    if (!t2Expansion()) return baselineXP(1);
    if (!t25Expansion()) return baselineXP(2);
    if (!t4Expansion()) return baselineXP(3);
    if (!t3Expansion()) return baselineXP('25');
    return null;
  };

  return {
    validAt: {
      t2: t2Expansion(),
      t25: t25Expansion(),
      t3: t3Expansion(),
      t4: t4Expansion(),
    },
    metrics: {
      t2: baselineXP(1),
      t25: baselineXP('25'),
      t3: baselineXP(2),
      t4: baselineXP(3),
    },
    minBaseline: calcMinBaseline(),
  };
};
export default skillState => {
  const tiers = [1, 2, 3];
  const skillTemplate = SkillInitializer().skills;
  const skillCategories = fromPairs(
    Object.keys(skillTemplate).map(x => [x, skillTemplate[x].category]),
  );
  const categoryOf = sid => skillCategories[sid];
  const isInnate = sid => skillTemplate[sid].innate;
  const h = cloneDeep(blankGridTemplate.categories);

  // compute counts
  Object.keys(skillState).forEach(sid => {
    const category = categoryOf(sid);
    const value = skillState[sid];

    tiers
      .filter(x => x <= value.levelAcquired)
      .forEach(stier => {
        const tier = parseInt(stier, 10);
        if (tier > 1 || (tier === 1 && !isInnate(sid)))
          h[category][tier].count += 1;
      });

    if (value.achievementIsAcquired) h[category][4].count += 1;
  });

  // update XP per category
  Object.keys(h).forEach(category => {
    tiers.concat(4).forEach(stier => {
      const tier = parseInt(stier, 10);
      switch (tier) {
        case 4:
          h[category][tier].build = 10 * h[category][tier].count;
          break;
        default:
          const tierCost = tier + 1;
          const count = h[category][tier].count;
          h[category][tier].build = (count * (tierCost + tierCost * count)) / 2;
          h[category][tier].nextCost = (count + 1) * tierCost;
      }
    });
  });

  // sum XP per category
  Object.keys(h).forEach(category => {
    h[category].totalBuild = tiers
      .concat(4)
      .map(stier => h[category][stier].build)
      .reduce((a, b) => a + b, 0);
  });

  return h;
};
