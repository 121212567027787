import { fromPairs } from 'lodash';

export default (shifts, allShifts) =>
  fromPairs(
    Object.keys(shifts).map(shift => [
      shift,
      {
        ...shifts[shift],
        isAttendeeSelected:
          shifts[shift].kind === 'cleanup'
            ? allShifts
              ? false
              : shifts[shift].isAttendeeSelected
            : false,
      },
    ]),
  );
