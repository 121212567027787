import uuid from 'uuid';
import { initialState } from '../Character/reducers/index';

const loadCharacter = ({ characterStorage, currentCharacterID }) => {
  if (currentCharacterID === null) {
    const id = uuid.v1();
    return {
      key: id,
      value: { ...initialState, id },
    };
  }
  if (currentCharacterID !== undefined) {
    return {
      key: currentCharacterID,
      value: characterStorage[currentCharacterID],
    };
  }

  return undefined;
};

export default ({ characterStorage, currentCharacterID, dispatch }) => {
  const loadedCharacter = loadCharacter({
    characterStorage,
    currentCharacterID,
  });

  if (loadedCharacter === undefined) return;
  if (currentCharacterID === loadedCharacter.key) {
    dispatch({
      type: 'SYNC_BUFFER',
      payload: characterStorage[currentCharacterID],
    });
  } else {
    const payload = {
      id: loadedCharacter.key,
      newState: loadedCharacter.value,
    };

    dispatch({ type: 'SWITCH_CHARACTER', payload });
  }
};
