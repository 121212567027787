import React from 'react';
import styles from './Character.module.scss';

const DAYS = {
  0: '',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
  7: 'Sun',
};

export default ({ data }) => {
  const castingShifts = data
    .filter(x => ['casting', 'special'].includes(x.kind))
    .sort((a, b) => a.identifier - b.identifier);
  const cleanupShifts = data.filter(x => x.kind === 'cleanup');
  const identifyDay = (shifts, index) =>
    shifts[index]
      ? `${DAYS[parseInt(shifts[index].identifier / 100, 0)]} ${
          shifts[index].label
        }`
      : null;

  return (
    <div className={styles.shifts}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th colSpan={2}>Shifts</th>
          </tr>
        </thead>
        <tbody>
          {castingShifts.map((shift, index) => (
            <tr>
              <td className={styles.leftHalf}>Casting #{index + 1}</td>
              <td
                className={[
                  styles.text,
                  styles.rightAlign,
                  styles.rightHalf,
                ].join(' ')}
              >
                {identifyDay(castingShifts, index)}
              </td>
            </tr>
          ))}
          <tr>
            <td>Cabin Cleanup</td>
            <td className={[styles.text, styles.rightAlign].join(' ')} />
          </tr>
          <tr>
            <td>Site Cleanup</td>
            <td className={[styles.text, styles.rightAlign].join(' ')}>
              {cleanupShifts[0] ? cleanupShifts[0].label : null}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
