import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fromPairs, snakeCase } from 'lodash';
import ModSection from './ModControls/ModSection';
import ModSignupDescription from './ModControls/ModSignupDescription';
import ModSignupScheduler from './ModControls/ModSignupScheduler';
import styles from './ModControls.module.scss';

export default ({ event, modsUI, modSectionsUI }) => {
  const dispatch = useDispatch();

  const [newSectionButtonState, setNewSectionButtonState] = useState('init');
  const [newSectionName, setNewSectionName] = useState('');

  const [sectionState, setSectionState] = useState({
    name: {},
    signupLimit: {},
  });

  const [newModButtonState, setNewModButtonState] = useState({});
  const [newModName, setNewModName] = useState({});
  const [modState, setModState] = useState({
    participationLimit: {},
    name: {},
    timeframe: {},
    description: {},
    constraint: {},
  });
  const [modParticipants, setModParticipants] = useState([]);
  const addModSection = () => {
    if (newSectionName.trim().length === 0) return;
    if (newSectionButtonState === 'processing') return;
    dispatch({
      type: 'CREATE_EVENT_MOD_SECTION',
      payload: {
        name: newSectionName.trim(),
        eventID: event.eventID,
      },
    });

    setNewSectionButtonState('processing');
    setNewSectionName('');
  };
  const addMod = modSectionID => {
    const d = newModName[modSectionID];
    if (newModButtonState[modSectionID] === 'processing') return;
    if (d.trim().length === 0) return;

    dispatch({
      type: 'CREATE_EVENT_MOD',
      payload: {
        name: d.trim(),
        eventID: event.eventID,
        modSectionID,
      },
    });

    setNewModName({
      ...newModName,
      [modSectionID]: '',
    });
    setNewModButtonState({
      ...newModButtonState,
      [modSectionID]: 'init',
    });
  };
  const maybeUpdateUpstream = ({ id, field }) => {
    const upstreamValue = event.modSections[id][snakeCase(field)];
    const maybeDirtyValue = sectionState[field][id];

    if (upstreamValue === maybeDirtyValue) return;
    dispatch({
      type: 'UPDATE_EVENT_MOD_SECTION',
      payload: {
        field: snakeCase(field),
        id,
        eventID: event.eventID,
        value: maybeDirtyValue,
      },
    });
  };
  const maybeUpdateModUpstream = ({ id, field, alwaysUpdate }) => {
    const upstreamValue = event.mods[id][snakeCase(field)];
    const maybeDirtyValue = modState[field][id];

    if (!alwaysUpdate && upstreamValue === maybeDirtyValue) return;
    dispatch({
      type: 'UPDATE_EVENT_MOD',
      payload: {
        field: snakeCase(field),
        id,
        eventID: event.eventID,
        value: alwaysUpdate || maybeDirtyValue,
      },
    });
  };
  const kickModParticipant = ({ modID, playerID }) =>
    dispatch({
      type: 'KICK_MOD_PARTICIPANT',
      payload: {
        eventID: event.eventID,
        playerID,
        modID,
      },
    });
  const fetchModParticipants = ({ withToast }) =>
    dispatch({
      type: 'FETCH_MOD_PARTICIPANTS',
      payload: {
        eventID: event.eventID,
        withToast,
      },
    });
  const updateModSection = ({ modSection, field, value }) =>
    modSection
      ? setSectionState({
          ...sectionState,
          [field]: {
            ...sectionState[field],
            [modSection.id]: value,
          },
        })
      : setNewSectionName(value);
  const updateNewModName = ({ id, value }) =>
    setNewModName({
      ...newModName,
      [id]: value,
    });
  const updateModState = ({ id, field, value }) =>
    setModState({
      ...modState,
      [field]: {
        ...modState[field],
        [id]: value,
      },
    });
  const transformModSections = () => {
    setSectionState({
      name: fromPairs(
        Object.keys(event.modSections).map(x => [
          event.modSections[x].id,
          event.modSections[x].name,
        ]),
      ),
      signupLimit: fromPairs(
        Object.keys(event.modSections).map(x => [
          event.modSections[x].id,
          event.modSections[x].signup_limit,
        ]),
      ),
    });

    setNewModName({
      ...newModName,
      ...fromPairs(
        Object.keys(event.modSections).map(x => [
          event.modSections[x].id,
          newModName[event.modSections[x].id] || '',
        ]),
      ),
    });
    setNewModButtonState({
      ...newModButtonState,
      ...fromPairs(
        Object.keys(event.modSections).map(x => [
          event.modSections[x].id,
          newModButtonState[event.modSections[x].id] || 'init',
        ]),
      ),
    });

    setModState({
      name: fromPairs(
        Object.keys(event.mods).map(x => [
          event.mods[x].id,
          event.mods[x].name,
        ]),
      ),
      participationLimit: fromPairs(
        Object.keys(event.mods).map(x => [
          event.mods[x].id,
          event.mods[x].participation_limit,
        ]),
      ),
      timeframe: fromPairs(
        Object.keys(event.mods).map(x => [
          event.mods[x].id,
          event.mods[x].timeframe || '',
        ]),
      ),
      description: fromPairs(
        Object.keys(event.mods).map(x => [
          event.mods[x].id,
          event.mods[x].description || '',
        ]),
      ),
      constraint: fromPairs(
        Object.keys(event.mods).map(x => [
          event.mods[x].id,
          event.mods[x].constraint || {},
        ]),
      ),
    });
  };

  const modContent = Object.keys(event.modSections)
    .sort((a, b) => a - b)
    .map(x => (
      <ModSection
        key={x}
        modSection={event.modSections[x]}
        modSectionsUI={modSectionsUI}
        modsUI={modsUI}
        sectionState={sectionState}
        modState={modState}
        modParticipants={modParticipants}
        newModName={newModName}
        newModButtonState={newModButtonState}
        maybeUpdateUpstream={maybeUpdateUpstream}
        maybeUpdateModUpstream={maybeUpdateModUpstream}
        updateModSection={updateModSection}
        updateNewModName={updateNewModName}
        updateModState={updateModState}
        addMod={addMod}
        kickModParticipant={kickModParticipant}
        fetchModParticipants={fetchModParticipants}
      />
    ));

  useEffect(() => {
    setNewSectionButtonState('init');
    transformModSections();
    fetchModParticipants({ withToast: false });
  }, [event.modSections]);

  useEffect(() => {
    setModParticipants(event.modParticipants);
  }, [event.modParticipants]);

  return (
    <div className={styles.container}>
      <ModSignupScheduler
        eventModSignupOpensAt={event.modSignupOpensAt}
        eventModMaxSignup={event.modMaxSignup}
        eventModEnableWaitlist={event.modEnableWaitlist}
        eventID={event.eventID}
        branchID={event.branchID}
        eventStartDate={event.startDate}
      />
      <ModSignupDescription
        eventModDescription={event.modDescription}
        eventID={event.eventID}
        branchID={event.branchID}
      />
      <div className={styles.modContent}>
        <div className={styles.header}>Mods List</div>
        {modContent}
        <ModSection
          modSection={null}
          updateModSection={updateModSection}
          addModSection={addModSection}
          newSectionName={newSectionName}
          newSectionButtonState={newSectionButtonState}
        />
      </div>
    </div>
  );
};
