import { fromPairs } from 'lodash';

export const transformTransactionPartners = transactionPartners =>
  fromPairs(transactionPartners.map(x => [x.id, x]));

export const extractUserInfo = transactionPartners =>
  transactionPartners[0].user;

export const extractUserId = transactionPartners =>
  extractUserInfo(transactionPartners).id;
