import { all, select, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../../Shared/Toastify/Toast';

function* logout() {
  const authConfig = yield select(state => state.user.session);

  try {
    yield axios.delete('/users/logout', authConfig);
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* logoutAllSessions() {
  const authConfig = yield select(state => state.user.session);

  try {
    yield axios.delete('/users/logout_all', authConfig);
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* watchLogout() {
  yield takeLatest('DELETE_SESSION', logout);
}

function* watchLogoutAllSessions() {
  yield takeLatest('LOGOUT_ALL_SESSIONS', logoutAllSessions);
}

export default function* () {
  yield all([watchLogout(), watchLogoutAllSessions()]);
}
