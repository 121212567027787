import React from 'react';
import { format, parseISO } from 'date-fns';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import styles from './CharacterExtraBuild.module.scss';

export default ({
  row,
  override,
  isPrivileged,
  isAdmin,
  ownedBranches,
  user,
}) => {
  const buildBreakdown = x => (
    <React.Fragment>
      {x.build_awarded - x.build_growth}
      {x.build_growth > 0 && ` + ${x.build_growth}`}
    </React.Fragment>
  );

  const maybeLink = row => {
    const content = (
      <React.Fragment>
        {row.event}
        {row.ticket && row.ticket.pass_type === 'day_pass' && ' [Day Pass]'}
      </React.Fragment>
    );

    if (isAdmin || (isPrivileged && ownedBranches.includes(row.branch_id))) {
      return (
        <a
          href={`/admin_event/${row.event_id}?player_id=${user.id}`}
          target='_eventInspect'
          className={styles.hideousLink}
        >
          {content}
          <OpenInNewIcon className={styles.icon} />
        </a>
      );
    }

    return content;
  };

  return row ? (
    <div className={styles.row} key={row.id}>
      <div className={styles.left}>
        <div className={styles.date}>
          {row.awarded_at && format(parseISO(row.awarded_at), 'M/d')}
        </div>
      </div>
      <div className={[styles.middle, styles.history].join(' ')}>
        <div className={styles.event}>
          <div className={styles.homeGameMarker}>
            {row.isTransferLog
              ? '<>'
              : row.home_game_events_index
              ? `${row.kind === 'travel_as_home_game' ? '^' : '#'}${
                  row.home_game_events_index
                }`
              : 'T'}
          </div>
          <div className={styles.text}>{maybeLink(row)}</div>
        </div>
      </div>
      <div className={[styles.right, styles.history].join(' ')}>
        <div className={styles.amount}>
          <div className={styles.text}>
            {!row.isTransferLog && buildBreakdown(row)}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.row} key='genesis'>
      <div className={styles.left}>
        <div className={styles.date}>
          {format(parseISO(override.date), 'yyyy-M-d')}
        </div>
      </div>
      <div className={[styles.middle, styles.history].join(' ')}>
        <div className={styles.event}>
          <div className={styles.homeGameMarker}>{override.marker}</div>
          <div className={styles.text}>{override.text}</div>
        </div>
      </div>
    </div>
  );
};
