import { fromPairs, startCase } from 'lodash';

export default () => {
  const autofill = h => {
    const augmented = h;
    const inverse = {};
    Object.keys(h).forEach(key => {
      augmented[key].innate = augmented[key].innate || false;
      if (!('maxTier' in h[key])) augmented[key].maxTier = 4;
      augmented[key].name = startCase(key);

      if (h[key].remoteID) {
        let tier = augmented[key].innate ? 2 : 1;
        h[key].remoteID
          .filter(x => x !== null)
          .forEach(x => {
            inverse[x] = { key, tier };
            tier += 1;
          });
      }
    });

    return {
      skills: augmented,
      remoteSkills: inverse,
    };
  };

  const categorify = (category, h) => {
    return fromPairs(
      Object.keys(h).map(x => [x, Object.assign(h[x], { category })]),
    );
  };

  const combat = categorify('combat', {
    avoid: {
      maxTier: 1,
      remoteID: [31],
    },
    balance: {
      maxTier: 1,
      remoteID: [32],
    },
    blinding: {
      maxTier: 1,
      remoteID: [35],
    },
    break: {
      maxTier: 1,
      remoteID: [36],
    },
    chokingBlow: {
      maxTier: 1,
      remoteID: [37],
    },
    interfere: {
      maxTier: 1,
      remoteID: [38],
    },
    piercingStrike: {
      maxTier: 1,
      remoteID: [39],
    },
    takeDown: {
      maxTier: 1,
      remoteID: [40],
    },
    combatTactics: {
      t4: 'Battlefield Commander',
      remoteID: [33, 42, 52, 143],
    },
    florentine: {
      t4: 'Mercenary',
      remoteID: [34, 45, 55, 144],
    },
    exoticWeapon: {
      t4: 'Violence Technician',
      innate: true,
      remoteID: [null, 43, 53, 146],
    },
    meleeSmall: {
      t4: 'Assassin',
      innate: true,
      remoteID: [null, 46, 56, 137],
    },
    meleeStandard: {
      t4: 'Bad-Ass',
      innate: true,
      remoteID: [null, 47, 57, 138],
    },
    meleeTwoHanded: {
      t4: 'Bloodied Lance',
      innate: true,
      remoteID: [null, 48, 58, 139],
    },
    projectile: {
      t4: 'Marksman',
      innate: true,
      remoteID: [null, 44, 54, 142],
    },
    shield: {
      t4: 'Phalanx Doyen',
      innate: true,
      remoteID: [null, 49, 59, 145],
    },
    thrownWeapon: {
      t4: 'Death Chuckah',
      innate: true,
      remoteID: [null, 50, 60, 141],
    },
    unarmedCombat: {
      t4: 'Bone Breaker',
      innate: true,
      remoteID: [null, 41, 51, 140],
    },
  });
  const civilized = categorify('civilized', {
    agricultural: {
      t4: 'Shepherd of the Land',
      remoteID: [63, 73, 83, 154],
    },
    artisan: {
      t4: 'Techno Savant',
      remoteID: [62, 72, 82, 155],
    },
    culinary: {
      t4: 'Artisanal Cuisiner',
      remoteID: [65, 75, 85, 147],
    },
    education: {
      t4: 'Sage',
      remoteID: [64, 74, 84, 153],
    },
    larceny: {
      t4: 'Villon',
      remoteID: [66, 76, 86, 156],
    },
    medical: {
      t4: 'Graverobber',
      remoteID: [61, 71, 81, 150],
    },
    criminalInfluence: {
      t4: 'Inner Circle',
      remoteID: [69, 79, 89, 151],
    },
    financialInfluence: {
      t4: 'Fiscal Mysticist',
      remoteID: [67, 77, 87, 149],
    },
    tradeConnections: {
      t4: 'Entrepreneur',
      remoteID: [68, 78, 88, 148],
    },
    socialInfluence: {
      t4: 'Overlord',
      remoteID: [70, 80, 90, 152],
    },
  });
  const wasteland = categorify('wasteland', {
    awareness: {
      t4: 'Veteran',
      remoteID: [1, 11, 21, 135],
    },
    enhancedMovement: {
      t4: 'Flanker',
      remoteID: [2, 12, 22, 129],
    },
    foraging: {
      t4: 'Scavenger',
      remoteID: [3, 13, 23, 133],
    },
    hunting: {
      t4: 'Wasteland Stalker',
      remoteID: [4, 14, 24, 136],
    },
    malicious: {
      t4: 'Inquisitor',
      remoteID: [5, 15, 25, 131],
    },
    mentalEndurance: {
      t4: 'Mind Killer',
      remoteID: [6, 16, 26, 132],
    },
    sailing: {
      t4: 'Bluejacket',
      remoteID: [7, 17, 27, 128],
    },
    salvaging: {
      t4: 'Avontuur',
      remoteID: [8, 18, 28, 127],
    },
    stealth: {
      t4: 'Shadow',
      remoteID: [9, 19, 29, 134],
    },
    trailblazing: {
      t4: 'Gear Head',
      remoteID: [10, 20, 30, 130],
    },
  });
  const anomaly = categorify('anomaly', {
    biogenetics: {
      t4: 'Free Radical',
      remoteID: [91, 101, 111, 160],
    },
    necrokinetics: {
      t4: 'Grave Attuned',
      remoteID: [95, 105, 115, 161],
    },
    pyrokinetics: {
      t4: 'Incinerator',
      remoteID: [92, 102, 112, 162],
    },
    telekinetics: {
      t4: 'Mentalist',
      remoteID: [93, 103, 113, 163],
    },
    telepathy: {
      t4: 'Thought Bender',
      remoteID: [94, 104, 114, 166],
    },
    faithVessel: {
      t4: 'Apocatastian Templar',
      remoteID: [100, 110, 120, 157],
    },
    faithfulMiracles: {
      t4: 'Saint',
      remoteID: [99, 109, 119, 165],
    },
    faithfulPatterns: {
      t4: 'Doctrinarian',
      remoteID: [96, 106, 116, 159],
    },
    faithfulSpirit: {
      t4: 'Pernicious Savior',
      remoteID: [98, 108, 118, 158],
    },
    faithfulWill: {
      t4: 'Life-Binder',
      remoteID: [97, 107, 117, 164],
    },
  });

  const skeleton = Object.assign({}, combat, civilized, wasteland, anomaly);
  return autofill(skeleton);
};
