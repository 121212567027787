export const initialState = {
  body: {
    isDecrementable: false,
    isIncrementable: true,
  },
  mind: {
    isDecrementable: false,
    isIncrementable: true,
  },
  resolve: {
    isDecrementable: false,
    isIncrementable: true,
  },
  infection: {
    isDecrementable: false,
    isIncrementable: true,
  },
  deaths: {
    isDecrementable: false,
    isIncrementable: true,
  },
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'UPDATE_STAT_CONTROLS':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
