import { cloneDeep, fromPairs } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import Info from './Info/Component';
import Row from './Row/Container';
import skills from '../../../gameData/skills';
import SkillInitializer from '../../../gameData/skillInitializer';
import styles from './Component.module.scss';
import updateSkillXP from './calc';
import { isEditable as hasEditPrivilege } from '../../../utils/user';

const SkillGrid = () => {
  const dispatch = useDispatch();
  const {
    skillState,
    skillRemote,
    skillCategoriesShown,
    skillInfoShown,
    currentCharacterID,
    approvedForPlay,
    user,
    hasInteractedWithSkill,
  } = useSelector(state => ({
    skillState: state.character.skills.trees,
    skillRemote: state.character.remotePersistance.skills,
    skillCategoriesShown: state.ui.skills.categoriesShown,
    skillInfoShown: state.ui.skills.infoShown,
    currentCharacterID: state.localStorage.currentCharacterID,
    approvedForPlay: state.character.approvedForPlay,
    user: state.user,
    hasInteractedWithSkill: state.ui.user.hasInteractedWithSkill,
  }));
  const isEditable = hasEditPrivilege(user, approvedForPlay);
  const skillTemplate = SkillInitializer().skills;
  const skillCategories = fromPairs(
    Object.keys(skillTemplate).map(x => [x, skillTemplate[x].category]),
  );

  const updateSkillInfoVisibility = sid => ({
    [sid]: !skillInfoShown[sid],
  });

  const updateSkillState = (sid, tier) => {
    const ssid = cloneDeep(skillState[sid]);

    if (tier > 0 && tier <= 3) {
      const t4acquired =
        'achievementIsAcquired' in ssid && ssid.achievementIsAcquired === true;
      const clickedAcquired = tier <= ssid.levelAcquired;
      const clickedAtTier = tier === ssid.levelAcquired;

      if (clickedAtTier && clickedAcquired) {
        if (tier === 2 && t4acquired) {
          ssid.achievementIsAcquired = false;
        } else {
          ssid.levelAcquired = tier - 1;
        }
      } else {
        ssid.levelAcquired = tier;
      }

      if (ssid.levelAcquired < 2) ssid.achievementIsAcquired = false;
      if (ssid.levelAcquired <= 1 && skillTemplate[sid].innate)
        ssid.levelAcquired = 0;
    } else if (tier === 4) {
      ssid.achievementIsAcquired = !ssid.achievementIsAcquired;

      if (ssid.achievementIsAcquired && ssid.levelAcquired <= 2)
        ssid.levelAcquired = 2;
    }

    return { [sid]: ssid };
  };

  const handleClick = (sid, tier) => {
    if (!hasInteractedWithSkill) dispatch({ type: 'FIRST_SKILL_INTERACTION' });
    if (tier === 0) {
      dispatch({
        type: 'UPDATE_SKILL_INFO_VISIBILITY',
        payload: updateSkillInfoVisibility(sid),
      });
    } else {
      dispatch({
        type: 'UPDATE_SKILL',
        payload: updateSkillState(sid, tier),
      });
    }
  };

  const buildRows = () => {
    return Object.keys(skillTemplate).map(key => (
      <React.Fragment key={key}>
        <Row
          key={`box_${key}`}
          category={skillCategories[key]}
          sid={key}
          t1={skillTemplate[key].name}
          t4={skillTemplate[key].t4}
          innate={skillTemplate[key].innate}
          maxTier={skillTemplate[key].maxTier}
          levelAcquired={skillState[key].levelAcquired}
          achievementIsAcquired={skillState[key].achievementIsAcquired}
          remoteLevelAcquired={
            key in skillRemote ? skillRemote[key].levelAcquired : 0
          }
          remoteAchievementIsAcquired={
            key in skillRemote ? skillRemote[key].achievementIsAcquired : false
          }
          visible={skillCategoriesShown[skillTemplate[key].category]}
          infoExpanded={skillInfoShown[key]}
          passClick={handleClick}
          isEditable={isEditable}
          resolveLocks={fromPairs(
            Object.keys(skills[key])
              .filter(x => skills[key][x].resolveLocked)
              .map(tier => [tier, true]),
          )}
        />
      </React.Fragment>
    ));
  };

  useEffect(() => {
    if (!currentCharacterID) return;
    dispatch({
      type: 'UPDATE_SKILL_BUILD',
      payload: updateSkillXP(skillState),
    });

    if (!hasInteractedWithSkill) return;
    dispatch({
      type: isEditable ? 'UPDATE_SKILL_UPSTREAM' : 'UPDATE_SKILL_DRAFTS',
    });
  }, [
    skillState,
    currentCharacterID,
    dispatch,
    isEditable,
    hasInteractedWithSkill,
  ]);

  return <div className={styles.container}>{buildRows()}</div>;
};

export default React.memo(SkillGrid);
