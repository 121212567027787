export const initialState = {
  popperOpened: null,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'TOGGLE_POPPER':
      return {
        ...state,
        popperOpened:
          state.popperOpened && state.popperOpened === payload ? null : payload,
      };
    default:
      return state;
  }
};
