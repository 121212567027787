import { useDispatch, useSelector } from 'react-redux';
import Close from '@material-ui/icons/ExpandLess';
import Popper from '@material-ui/core/Popper';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import ScaledProgressionBar from './ScaledProgressionBar';
import History from './History';
import Planner from './Planner';
import styles from './Bar.module.scss';
import SharedBuildBar from '../../Shared/TotalBuild/Bar';
import CharacterExtraBuild from './CharacterExtraBuild';
import { validateTiers } from '../Skill/Grid/calc';
import './XPBar.scss';
import {
  isEditable as hasEditPrivilege,
  isPrivileged as hasPrivilege,
} from '../../utils/user';

const Container = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const popperName = 'xpBar';
  const open = Boolean(anchorEl);
  const id = open ? `xp-bar` : undefined;
  const dispatch = useDispatch();
  const {
    currentCharacterID,
    skills,
    skillLevels,
    stats,
    totalBuild,
    popper,
    approvedForPlay,
    characterExtraBuilds,
    user,
  } = useSelector(state => ({
    currentCharacterID: state.localStorage.currentCharacterID,
    stats: state.character.stats,
    skills: state.character.skills.categories,
    skillLevels: state.character.skills.trees,
    totalBuild: state.character.totalBuild,
    popper: state.ui.character.popperOpened,
    approvedForPlay: state.character.approvedForPlay,
    characterExtraBuilds: state.character.extraBuilds,
    user: state.user,
  }));
  const isEditable = hasEditPrivilege(user, approvedForPlay);
  const isPrivileged = hasPrivilege(user);
  const parentRef = useRef(null);
  const t1Ref = useRef(null);
  const t2Ref = useRef(null);
  const t3Ref = useRef(null);
  const t4Ref = useRef(null);
  const tier1bar = 50;
  const tier2bar = 75;
  const tier4bar = 100;
  const tier3bar = 150;
  const tierFree = 175;
  const handleClick = event => {
    return event.target.className
      .toString()
      .match(/(Bar_close|Bar_placeholder|SVGAnimatedString)/)
      ? dispatch({ type: 'TOGGLE_POPPER', payload: popperName })
      : null;
  };

  const handleExtraBuildChanges = data =>
    dispatch({
      type: 'UPDATE_CHARACTER_EXTRA_BUILDS',
      payload: data,
    });

  const getNextTier = () => (totalBuild.earned ? `/${totalBuild.earned}` : '');

  const getWarningClassName = useCallback(
    tier => {
      const { validAt } = validateTiers(skills, skillLevels, totalBuild);
      if (
        (tier === 1 && !validAt.t2) ||
        (tier === 2 && !validAt.t25) ||
        (tier === '25' && !validAt.t3) ||
        (tier === 3 && !validAt.t4)
      ) {
        return `${styles.warned}`;
      }

      return '';
    },
    [skills, skillLevels, totalBuild],
  );

  const exceedsXPLimit = () => {
    if (totalBuild.earned === null) return false;
    return totalBuild.used > totalBuild.earned;
  };

  const getXPLimitClassName = () => {
    const classes = [`${styles.text}`];
    if (exceedsXPLimit()) classes.push(`${styles.error}`);
    return classes.join(' ');
  };

  const getXPDetailedUsage = () => {
    const { validAt, metrics, minBaseline } = validateTiers(
      skills,
      skillLevels,
      totalBuild,
    );
    // const hasWarning = !(validAt.t2 && validAt.t3 &);
    const hasWarning = !Object.values(validAt).reduce((a, b) => a && b, true);
    const t2WarningMessage = () => (
      <div className={styles.violationMessage}>
        {`You must spend at least ${tier1bar} XP before attempting to learn
        Proficient Skills. Your eligible XP spent so far is ${metrics.t2}.
        Please invest in more Stats or Basic Skills first.`}
      </div>
    );
    const t25WarningMessage = () => (
      <div className={styles.violationMessage}>
        {`You must spend at least ${tier2bar} XP before attempting to learn
        a Master Skill. Your eligible XP spent so far is ${metrics.t3}.
        Please invest in more Stats or Basic or Proficient Skills first.`}
      </div>
    );
    const t3WarningMessage = () => (
      <div className={styles.violationMessage}>
        {`You must spend at least ${tier3bar} XP before attempting to learn
        more than one Master Skills. Your eligible XP spent so far is ${metrics.t25}.
        Please invest in anything except Master Skills first.`}
      </div>
    );
    const t4WarningMessage = () => (
      <div className={styles.violationMessage}>
        {`You must spend at least ${tier4bar} XP before attempting to learn
        a Professional Focus Achievement. Your eligible XP spent so far is ${metrics.t4}.`}
      </div>
    );
    const overXPWarningMessage = () => (
      <div className={styles.violationMessage}>
        {`You require ${totalBuild.used} XP, which exceeds the ${totalBuild.earned} XP you have earned.`}
        {isEditable ? ' Your changes have not been saved.' : ''}
      </div>
    );
    const consolidatedWarningMessage = () => {
      return (
        <div>
          {hasWarning ? (
            <div className={styles.violations}>Violations</div>
          ) : (
            ''
          )}
          {validAt.t2 ? '' : t2WarningMessage()}
          {validAt.t25 ? '' : t25WarningMessage()}
          {validAt.t3 ? '' : t3WarningMessage()}
          {validAt.t4 ? '' : t4WarningMessage()}
          {exceedsXPLimit() ? overXPWarningMessage() : ''}
        </div>
      );
    };

    return (
      <div>
        <div className={styles.overwrap}>
          <table className={styles.table}>
            <thead />
            <tbody>
              <tr>
                <td>Skills</td>
                <td className={styles.numeric}>
                  {Object.values(skills).reduce((a, b) => a + b.totalBuild, 0)}
                </td>
              </tr>
              <tr>
                <td>Stats</td>
                <td className={styles.numeric}>
                  {Object.values(stats).reduce((a, b) => a + (b.build || 0), 0)}
                </td>
              </tr>
              <tr>
                <td className={styles.total}>Total</td>
                <td className={[styles.total, styles.numeric].join(' ')}>
                  {totalBuild.used}
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <ScaledProgressionBar
              value={minBaseline || totalBuild.used}
              breakpoints={[tier1bar, tier2bar, tier4bar, tier3bar, tierFree]}
              texts={['T1', 'T2', 'T2+', 'PFA', 'T3']}
            />
          </div>
          <div>{consolidatedWarningMessage()}</div>
          <Planner isEditable={isEditable} />
          <History />
          <CharacterExtraBuild
            data={characterExtraBuilds}
            isPrivileged={isPrivileged}
            passChanges={handleExtraBuildChanges}
          />
        </div>
        <div className={styles.close}>
          <Close className={styles.downshift} />
          Close
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!currentCharacterID) return;

    const parentWidth = parentRef.current.getBoundingClientRect().width;
    const t1width = (tier1bar / tierFree) * parentWidth;
    const t2width = (tier2bar / tierFree) * parentWidth;
    const t3width = (tier3bar / tierFree) * parentWidth;
    const t4width = (tier4bar / tierFree) * parentWidth;
    const backgroundWidth = (totalBuild.used * 100) / tierFree;
    const limitExceeded =
      totalBuild.earned != null && totalBuild.used > totalBuild.earned;
    const acquiredClass = limitExceeded ? '#FCA' : '#777';
    const linearGradientStyle = [
      `${acquiredClass} ${backgroundWidth}%`,
      `#333 ${backgroundWidth}%`,
    ].join(',');
    t1Ref.current.setAttribute('style', `width: ${t1width}px`);
    t2Ref.current.setAttribute('style', `width: ${t2width}px`);
    t3Ref.current.setAttribute('style', `width: ${t3width}px`);
    t4Ref.current.setAttribute(
      'style',
      `width: ${t4width}px; border-right-style: dotted`,
    );

    parentRef.current.setAttribute(
      'style',
      `background: linear-gradient(90deg, ${linearGradientStyle})`,
    );
  }, [currentCharacterID, totalBuild]);

  useEffect(() => {
    if (!currentCharacterID) return;
    dispatch({
      type: 'UPDATE_TOTAL_BUILD',
      payload: {
        used:
          Object.values(stats).reduce((a, b) => a + (b.build || 0), 0) +
          Object.values(skills)
            .map(x => x.totalBuild)
            .reduce((a, b) => a + b, 0),
      },
    });
  }, [currentCharacterID, stats, skills, dispatch]);

  useEffect(() => {
    setAnchorEl(popper === popperName ? parentRef.current : null);
  }, [popper, parentRef, setAnchorEl]);

  return (
    <div className={styles.xpbar} ref={parentRef} onClick={handleClick}>
      <SharedBuildBar
        classNames={getXPLimitClassName()}
        label={`${totalBuild.used}${getNextTier()} XP`}
      />
      <div className={styles.placeholder} ref={t1Ref}>
        <WarningIcon className={getWarningClassName(1)} />
      </div>
      <div className={styles.placeholder} ref={t2Ref}>
        <WarningIcon className={getWarningClassName(2)} />
      </div>
      <div className={styles.placeholder} ref={t3Ref}>
        <WarningIcon className={getWarningClassName('25')} />
      </div>
      <div className={styles.placeholder} ref={t4Ref}>
        <WarningIcon className={getWarningClassName(3)} />
      </div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement='bottom-end'
        className='xpbar-overlay'
      >
        <div>{getXPDetailedUsage()}</div>
      </Popper>
    </div>
  );
};

export default React.memo(Container);
