import initializeShifts from './actions/initializeShifts';
import initializeTickets from './actions/initializeTickets';
import localizeTicketsPayload from './actions/localizeTicketsPayload';
import localizeShiftsPayload from './actions/localizeShiftsPayload';
import hashifyBranchLocations from './actions/hashifyBranchLocations';
import addCleanupShift from './actions/addCleanupShift';
import localizeExternalManagersPayload from './actions/localizeExternalManagersPayload';
import { fromPairs, omit, omitBy } from 'lodash';

const CAPTURE_BASE_RATIO = true;

export const initialState = {
  shifts: initializeShifts(),
  tickets: initializeTickets(),
  modSections: {},
  mods: {},
  modParticipants: {},
  modEnableWaitlist: true,
  isPremiere: false,
  startDate: null,
  endDate: null,
  registrationDate: null,
  selectedBranch: 1,
  locations: {},
  selectedLocation: null,
  dirtyTracking: null,
  isProcessing: false,
  hasModifiedName: false,
  eventName: null,
  pastEvents: {},
  remoteError: null,
  externalManagers: [],
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'RESET_EVENT_SETUP_FORMS':
      return initialState;
    case 'UPDATE_EVENT_FROM_REMOTE':
      return {
        ...state,
        ...payload,
        remoteError: null,
        dirtyTracking: payload,
      };
    case 'UPDATE_EVENT_SETUP':
      return {
        ...state,
        remoteError: null,
        [payload.field]: payload.value,
      };
    case 'UPDATE_EVENT_SETUP_WITHOUT_ERROR_NULLIFY':
      return {
        ...state,
        [payload.field]: payload.value,
      };
    case 'UPDATE_EVENT_PROPERTY':
      return {
        ...state,
        remoteError: null,
        [payload.category]: {
          ...state[payload.category],
          [payload.id]: {
            ...state[payload.category][payload.id],
            [payload.field]: payload.value,
          },
        },
      };
    case 'UPDATE_EVENT_MODLIST':
      return {
        ...state,
        modSections: fromPairs(payload.map(x => [x.id, x])),
        mods: fromPairs(
          payload
            .map(x => x.mods)
            .flat()
            .map(x => [x.id, x]),
        ),
      };
    case 'UPDATE_EVENT_MODS_METADATA_SUCCEEDED':
      return {
        ...state,
        modSignupOpensAt: payload.mod_signup_opens_at,
        modDescription: payload.mod_description,
        modMaxSignup: payload.mod_max_signup,
        modEnableWaitlist: payload.mod_enable_waitlist,
      };
    case 'FETCH_MOD_PARTICIPANTS_SUCCEEDED':
      return {
        ...state,
        modParticipants: fromPairs(payload.map(x => [x.id, x.mod_signups])),
      };
    case 'FETCH_EXTERNAL_MANAGERS_SUCCEEDED':
    case 'UPDATE_EXTERNAL_MANAGERS_SUCCEEDED':
      return {
        ...state,
        externalManagers: localizeExternalManagersPayload(payload),
      };
    case 'REMOVE_EXTERNAL_MANAGER':
      return {
        ...state,
        externalManagers: fromPairs(
          Object.keys(state.externalManagers)
            .filter(x => x !== payload.userID)
            .map(x => [x, state.externalManagers[x]]),
        ),
      };
    case 'FETCH_PAST_EVENT_CONFIGURATIONS_SUCCEEDED':
      return {
        ...state,
        pastEvents: fromPairs(payload.map(x => [x.id, omit(x, ['id'])])),
      };
    case 'APPLY_EVENT_CONFIGURATION':
      return {
        ...state,
        shifts: localizeShiftsPayload(payload.shifts, CAPTURE_BASE_RATIO),
        tickets: localizeTicketsPayload(payload.tickets),
      };
    case 'ADD_CLEANUP_SHIFT':
      return {
        ...state,
        remoteError: null,
        shifts: {
          ...state.shifts,
          ...addCleanupShift(state.shifts)[0],
        },
      };
    case 'REMOVE_CLEANUP_SHIFT':
      if (
        Object.values(state.shifts).filter(x => x.kind === 'cleanup').length < 1
      )
        return state;

      return {
        ...state,
        remoteError: null,
        shifts: omitBy(state.shifts, x => x.order === payload),
      };
    case 'UPDATE_BRANCH_INFO':
      return {
        ...state,
        remoteError: null,
        ...hashifyBranchLocations(
          payload.branch_locations || [],
          state.selectedLocation,
        ),
      };
    default:
      return state;
  }
};
