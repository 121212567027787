import { all, put, select, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../Shared/Toastify/Toast';

function* executeItemSearch({ payload }) {
  const user = yield select(state => state.user);
  const authConfig = user.session;

  try {
    const queryTerm = payload.isTaxonomy
      ? `taxonomy=${payload.query}`
      : `q=${payload.query}${payload.isTangible ? '&tangible=true' : ''}`;
    const filters =
      payload.filters.length === 0
        ? ''
        : payload.filters.map(x => `kinds[]=${x}`).join('&');

    const res = yield axios.get(
      `/items/search?${queryTerm}&${filters}`,
      authConfig,
    );
    const actionName = payload.isLocal
      ? 'EXECUTE_ITEM_SEARCH_LOCAL_SUCCEEDED'
      : 'EXECUTE_ITEM_SEARCH_SUCCEEDED';
    yield put({ type: actionName, payload: res.data });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
    yield put({ type: 'EXECUTE_ITEM_SEARCH_FAILED' });
  }
}

function* fetchItem({ payload }) {
  const user = yield select(state => state.user);
  const authConfig = user.session;

  try {
    const res = yield axios.get(`/items/${payload}`, authConfig);
    yield put({
      type: 'FETCH_ITEM_SUCCEEDED',
      payload: { id: payload, data: res.data },
    });
  } catch (error) {
    yield put({ type: 'FETCH_ITEM_FAILED', payload: { id: payload } });
  }
}

function* watchExecuteItemSearch() {
  yield takeLatest('EXECUTE_ITEM_SEARCH', executeItemSearch);
}

function* watchFetchItem() {
  yield takeLatest('FETCH_ITEM', fetchItem);
}

function* watchFetchItemWithoutSelectedItemChange() {
  yield takeLatest('FETCH_ITEM_WITHOUT_SELECTED_ITEM_CHANGE', fetchItem);
}

export default function* () {
  yield all([
    watchExecuteItemSearch(),
    watchFetchItem(),
    watchFetchItemWithoutSelectedItemChange(),
  ]);
}
