import { fromPairs, uniq, zip } from 'lodash';

const clerical = [
  'Photography',
  'Checkin',
  'Checkout',
  'Clerical',
  'Misc',
  'Special',
  'Others',
];
const early = ['Midnight', '2AM', '4AM', '6AM', '8AM', '10AM', 'Noon'];
const noon = ['Noon', '2PM', '4PM'];
const late = ['4PM', '6PM', '8PM', '10PM'];
const midnight = ['Midnight'];

const expandedClerical = clerical.map((x, i) => ({
  day: 0,
  isPremiere: false,
  isEnabled: true,
  order: i,
  limit: 25,
  kind: 'special',
  label: x,
}));

const packedPremiere = {
  4: late.concat(midnight),
  5: uniq(early.concat(noon)),
};

const packedRegular = {
  5: late.concat(midnight),
  6: uniq(early.concat(noon).concat(late)).concat(midnight),
  7: early,
};

const expand = ({ day, isPremiere, shifts, pad }) =>
  zip(shifts.slice(0, -1), shifts.slice(1)).map((x, i) => ({
    day: parseInt(day),
    isPremiere: isPremiere,
    isEnabled: true,
    order: parseInt(day) * 100 + i + (pad || 0),
    limit: 25,
    kind: 'casting',
    label: `${x[0]} - ${x[1]}`,
  }));

const unpack = ({ packed, isPremiere, pad }) =>
  Object.keys(packed).map(day =>
    expand({
      day: day,
      isPremiere: isPremiere,
      shifts: packed[day],
      pad: pad,
    }),
  );

export default () =>
  Object.assign(
    fromPairs(
      expandedClerical
        .concat(unpack({ packed: packedPremiere, isPremiere: true }))
        .concat(unpack({ packed: packedRegular, isPremiere: false, pad: 50 }))
        .flat()
        .map(x => [x.order, x]),
    ),
    {
      800: {
        kind: 'cleanup',
        limit: 100,
        order: 800,
        day: 8,
        label: 'Cleanup Shift',
        isEnabled: true,
      },
    },
  );
