import React from 'react';
import lores from '../gameData/lores';
import styles from '../Print/Print.module.scss';
import skillInitializer from '../gameData/skillInitializer';
import strains from '../gameData/strains';

export const xpTotalStat = data =>
  Object.values(data.stats).reduce((a, b) => a + (b.build || 0), 0);
export const xpTotalSkill = data =>
  Object.values(data.skills.categories).reduce((a, b) => a + b.totalBuild, 0);
export const xpTotal = data => xpTotalStat(data) + xpTotalSkill(data);
export const makeStatTable = (data, stat, k) => {
  const withReduction = () => {
    let t = data.stats[k].added;
    if (k === 'infection') t += ` - ${data.stats.deaths.count}`;
    return t;
  };

  const withReductionSum = () => {
    let t = strains[data.strain].lineage.stats[k] + data.stats[k].added;
    if (k === 'infection') t -= data.stats.deaths.count;
    return t;
  };
  return (
    <tr>
      <td className={styles.header}>{stat}</td>
      <td className={styles.numeric}>
        {strains[data.strain].lineage.stats[k]}
      </td>
      <td className={styles.numeric}>{withReduction()}</td>
      <td className={styles.numeric}>{withReductionSum()}</td>
      <td className={styles.numeric}>{data.stats[k].build}</td>
    </tr>
  );
};

export const makeSkillXPTable = (data, category, k) => {
  return (
    <tr>
      <td className={styles.header}>{category}</td>
      <td className={styles.numeric}>{data.skills.categories[k][1].build}</td>
      <td className={styles.numeric}>{data.skills.categories[k][2].build}</td>
      <td className={styles.numeric}>{data.skills.categories[k][3].build}</td>
      <td className={styles.numeric}>{data.skills.categories[k][4].build}</td>
      <td className={styles.numeric}>{data.skills.categories[k].totalBuild}</td>
    </tr>
  );
};

export const makeCategoryTable = (data, category, k) => {
  const { skills } = skillInitializer();
  const buildCell = (tier, t4acquired, t4name) => {
    return [
      <td key={2} className={styles.center}>
        {tier >= 2 ? 'X' : ''}
      </td>,
      <td key={3} className={styles.center}>
        {tier >= 3 ? 'X' : ''}
      </td>,
      <td key={4}>{t4acquired ? t4name : ''}</td>,
    ];
  };

  const rows = Object.keys(skills)
    .filter(x => skills[x].category === k)
    .filter(
      x =>
        data.skills.trees[x].levelAcquired > 0 ||
        data.skills.trees[x].achievementIsAcquired,
    )
    .map(x => {
      return (
        <tr key={x}>
          <td>{skills[x].name}</td>
          {buildCell(
            data.skills.trees[x].levelAcquired,
            data.skills.trees[x].achievementIsAcquired,
            skills[x].t4,
          )}
        </tr>
      );
    });

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th style={{ width: '40%' }}>{category}</th>
          <th style={{ width: '10%' }}>P</th>
          <th style={{ width: '10%' }}>M</th>
          <th style={{ width: '40%' }}>Achievement</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

export const makeCommunityAchievementTable = data => {
  const { skills } = skillInitializer();
  const rows = () =>
    Object.keys(skills)
      .filter(x => skills[x].category === 'community')
      .filter(x => data.skills[x].t4acquired)
      .map(x => (
        <tr key={x}>
          <td>{skills[x].name}</td>
        </tr>
      ));
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Community Achievement</th>
        </tr>
      </thead>
      <tbody>{rows()}</tbody>
    </table>
  );
};

export const makeLoreTable = data => {
  let count = 0;
  const rows = () =>
    Object.keys(data.lores)
      .filter(x => data.lores[x])
      .map(x => {
        count += 1;
        return (
          <tr key={x}>
            <td className={styles.center}>{count}</td>
            <td>{lores[x].name}</td>
          </tr>
        );
      });

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th style={{ width: '32px' }}>#</th>
          <th>
            {`Lores (Max allowed: ${
              data.skills.trees.education.levelAcquired * 3
            })`}
          </th>
        </tr>
      </thead>
      <tbody>{rows()}</tbody>
    </table>
  );
};
