import React from 'react';
import styles from './MonthSpace.module.scss';
import Cell from './Cell';
import { getFridays, monthName } from '../../utils/events';
import { find } from 'lodash';
import { getDate, differenceInHours, isSameWeek, parseISO } from 'date-fns';
import branches from '../../gameData/branches';

export default ({
  year,
  month,
  popper,
  data,
  isLoggedIn,
  homeBranchID,
  ownedBranches,
  selectedBranches,
  isGuide,
  isPrivileged,
  isAdmin,
  eventsCheckedIn,
  eventsExternalRun,
}) => {
  const homeEvents = () =>
    Object.keys(data).filter(
      branchID => homeBranchID && branchID === homeBranchID.toString(),
    );

  const allExceptHomeEvents = () => {
    if (homeBranchID === null) {
      return Object.keys(data).filter(branchID =>
        selectedBranches.includes(branchID),
      );
    }

    return Object.keys(data).filter(
      branchID =>
        selectedBranches.includes(branchID) &&
        branchID !== homeBranchID.toString(),
    );
  };

  const getEvents = (events, friday) =>
    events
      .map(x => data[x])
      .flat()
      .map(x => ({
        event: x,
        delta: Math.abs(differenceInHours(friday, parseISO(x.starts_at))),
      }))
      .filter(x => x.delta < 72)
      .filter(x => isPrivileged || x.event.registration_opens_at)
      .map(x => (
        <Cell
          key={x.event.id}
          eventID={x.event.id}
          event={x.event}
          name={branches[x.event.branch_id].shortName}
          popper={popper}
          isLoggedIn={isLoggedIn}
          isGuide={isGuide}
          isPrivileged={isPrivileged}
          isAdmin={isAdmin}
          homeBranchID={homeBranchID}
          ownedBranches={ownedBranches}
          isCheckedIn={eventsCheckedIn[x.event.id]}
          isExternalRun={find(eventsExternalRun, { id: x.event.id })}
        />
      ));

  const homeColumnStyle = isLoggedIn ? '' : styles.noHome;
  const isThisWeek = x => isSameWeek(new Date(), x, { weekStartsOn: 1 });

  // <Cell name='CO' eventID={year * 1000 + month * 100 + x} isPaid popper={popper} />
  const weekRows = () =>
    getFridays({ year, month }).map(x => (
      <div
        className={[styles.eventWeek, isThisWeek(x) && styles.thisWeek].join(
          ' ',
        )}
        key={x}
      >
        <div className={styles.weekDay}>{getDate(x)}</div>
        <div className={[styles.cell, styles.home, homeColumnStyle].join(' ')}>
          {getEvents(homeEvents(), x)}
        </div>
        <div
          className={[styles.cell, styles.travel, homeColumnStyle].join(' ')}
        >
          {getEvents(allExceptHomeEvents(), x)}
        </div>
      </div>
    ));

  return (
    <React.Fragment>
      <div className={styles.monthHeading}>
        <div className={styles.text}>{`${year} ${monthName(month)}`}</div>
        <div className={styles.placebo} />
      </div>
      <div className={styles.eventMonth}>{weekRows()}</div>
    </React.Fragment>
  );
};
