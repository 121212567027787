const isEnabledArbiter = (kind, shiftState, isPremiereShift, isPremiere) => {
  switch (kind) {
    case 'cleanup':
    case 'special':
      return shiftState;
    default:
      return isPremiere ? shiftState : isPremiereShift ? false : shiftState;
  }
};

export default (shifts, isPremiere) =>
  Object.keys(shifts).map(id => ({
    identifier: id,
    label: shifts[id].label,
    limit: shifts[id].limit,
    enabled: isEnabledArbiter(
      shifts[id].kind,
      shifts[id].isEnabled,
      shifts[id].isPremiere,
      isPremiere,
    ),
    kind: shifts[id].kind,
  }));
