import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import styles from '../Branches/Select.module.scss';

export default ({
  isPremiere,
  isEventRunner,
  isExternalRunner,
  selectedPastEvent,
  passDuplicatorChange,
  pastEvents,
  currentEventID,
}) => {
  const handleChange = event => passDuplicatorChange(event.target.value);
  const pastEvent = (label, value, isSelectable) => (
    <MenuItem
      key={value}
      value={value}
      disabled={!isSelectable}
      className={[styles.singleOption, styles.full].join(' ')}
    >
      {label}
    </MenuItem>
  );
  const similarEvents = Object.keys(pastEvents)
    .sort()
    .reverse()
    .filter(x =>
      isPremiere
        ? pastEvents[x].kind === 'premiere'
        : pastEvents[x].kind === 'regular',
    )
    .filter(x => parseInt(x) !== currentEventID)
    .map(x => pastEvent(pastEvents[x].name, x, true));

  const selectablePastEvents = () =>
    similarEvents.length === 0
      ? pastEvent('No valid past events found', -1, false)
      : [
          pastEvent(
            'Select Existing Ticket/Shift Configurations...',
            -1,
            false,
          ),
        ].concat(similarEvents);

  if (!isEventRunner) return null;
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Select
          autoWidth={true}
          className={[styles.base].join(' ')}
          classes={{
            select: styles.select,
            icon: styles.icon,
          }}
          value={-1}
          onChange={handleChange}
          MenuProps={{
            className: [styles.menu, styles.pushUp].join(' '),
          }}
          disableUnderline
        >
          {selectablePastEvents()}
        </Select>
      </div>
    </div>
  );
};
