import React, { useEffect, useState } from 'react';
import { groupBy } from 'lodash';
import { isPrivileged as hasPrivilege } from '../../utils/user';
import { format, parseISO } from 'date-fns';
import BranchSelect from '../../Events/Branches/SingleSelect';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import UndoIcon from '@material-ui/icons/History';
import AddIcon from '@material-ui/icons/Add';
import branches from '../../gameData/branches';
import styles from './Caps.module.scss';

export default ({ user, rootUser, createCaps, updateCaps, deleteCaps }) => {
  const isPrivileged = hasPrivilege(rootUser);
  const [filteredBranch, setFilteredBranch] = useState({});
  const isBranchFilterActive = Object.keys(filteredBranch).length > 0;
  const [newCap, setNewCap] = useState({
    branchId: rootUser.branchId,
    reason: '',
    amount: '',
  });
  const toggleFilter = x =>
    setFilteredBranch({
      ...filteredBranch,
      [x]: filteredBranch[x] ? !filteredBranch[x] : true,
    });
  const clearBranchFilters = x => setFilteredBranch({});
  const handleChange = (id, field, value) => {
    if (id) {
      updateCaps(id, field, value);
    } else {
      setNewCap({ ...newCap, [field]: value });
    }
  };
  const handleAction = (id, state) => {
    if (id) {
      deleteCaps(id, state === 'removed' ? 'undelete' : 'delete');
    } else {
      createCaps(newCap);
    }
  };
  const capRow = cap => (isPrivileged ? privilegedCap(cap) : readOnlyCap(cap));
  const privilegedCap = cap => (
    <div
      className={[
        styles.row,
        cap.state === 'removed' && styles.muted,
        cap.id &&
          isBranchFilterActive &&
          !filteredBranch[cap.branch.id] &&
          styles.hidden,
      ].join(' ')}
      key={cap.id || 'new-cap-row'}
    >
      <div className={styles.firstRow}>
        <div className={styles.branchSelect}>
          <BranchSelect
            user={rootUser}
            selectedBranch={cap.id ? cap.branch.id : newCap.branchId}
            passChange={(_, val) => handleChange(cap.id, 'branchId', val)}
            hideLocations
            allowNational
            allowNetworkWide
            shorthand
          />
        </div>
        <div className={styles.reason}>
          <input
            type='text'
            placeholder='Reason'
            className={styles.editable}
            value={cap.id ? cap.reason : newCap.reason}
            onChange={evt => handleChange(cap.id, 'reason', evt.target.value)}
          />
        </div>
        <div className={styles.amount}>
          <input
            type='number'
            placeholder='Amt'
            className={styles.editable}
            value={cap.id ? cap.amount : newCap.amount}
            onChange={evt => handleChange(cap.id, 'amount', evt.target.value)}
          />
        </div>
        <div
          className={styles.buttons}
          onClick={() => handleAction(cap.id, cap.state)}
        >
          {cap.id ? (
            cap.state === 'removed' ? (
              <UndoIcon className={styles.icon} />
            ) : (
              <DeleteIcon className={styles.icon} />
            )
          ) : (
            <AddIcon className={styles.icon} />
          )}
        </div>
      </div>
      {cap.id && (
        <div className={styles.metadata}>
          Last updated by
          {` ${[cap.grantor.first_name, cap.grantor.last_name]
            .join(' ')
            .trim()}`}
          {` on ${format(parseISO(cap.updated_at), 'yyyy-MM-dd')}`}
        </div>
      )}
    </div>
  );
  const readOnlyCap = cap =>
    cap.id && (
      <div
        key={cap.id}
        className={[
          styles.readOnlyRow,
          cap.state === 'removed' && styles.hidden,
          cap.id &&
            isBranchFilterActive &&
            !filteredBranch[cap.branch.id] &&
            styles.hidden,
        ].join(' ')}
      >
        <div className={styles.branch}>{branches[cap.branch.id].shortName}</div>
        <div className={styles.date}>
          {format(parseISO(cap.updated_at), 'yyyy-MM-dd')}
        </div>
        <div className={styles.reason}>{cap.reason}</div>
        <div className={styles.amount}>{cap.amount}</div>
      </div>
    );
  const addNewCapRow = capRow({});
  const existingCapRows = Object.keys(user.caps)
    .sort((a, b) => user.caps[b].id - user.caps[a].id)
    .map(x => capRow(user.caps[x]));
  const branchFilters = Object.keys(groupBy(user.caps, 'branch.id'))
    .sort((a, b) => branches[a].shortName.localeCompare(branches[b].shortName))
    .map(x => (
      <div
        className={[
          styles.branch,
          isBranchFilterActive && !filteredBranch[x] && styles.muted,
        ].join(' ')}
        key={x}
        onClick={evt => toggleFilter(x)}
      >
        <div className={styles.name}>{branches[x].shortName}</div>
        <div className={styles.badge}>
          {Object.keys(user.caps)
            .filter(y => user.caps[y].state === 'active')
            .filter(y => user.caps[y].branch.id.toString() === x)
            .map(y => user.caps[y].amount)
            .reduce((a, b) => a + b, 0)}
        </div>
      </div>
    ));

  useEffect(() => {
    if (!rootUser) return;
    setNewCap(newCap => ({ ...newCap, branchId: rootUser.branchId }));
  }, [rootUser.branchId, rootUser, setNewCap]);

  return (
    <div className={styles.container}>
      {isPrivileged && (
        <div className={styles.list}>
          <div className={styles.header}>Add CAPS</div>
          <div className={styles.content}>{addNewCapRow}</div>
        </div>
      )}
      {Object.keys(user.caps).length > 0 ? (
        <div className={styles.list}>
          <div className={styles.header}>Existing CAPS</div>
          <div className={styles.branchFilters}>
            {branchFilters}
            {isBranchFilterActive && (
              <div className={styles.clearFilter} onClick={clearBranchFilters}>
                Clear Filters
              </div>
            )}
          </div>
          <div className={styles.content}>{existingCapRows}</div>
        </div>
      ) : (
        <div className={styles.noCaps}>No CAPS recorded</div>
      )}
    </div>
  );
};
