import { clamp, cloneDeep } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import lores from '../../gameData/lores';
import LoreGrid from './Grid/Container';
import LoreLinear from './Linear/Container';
import styles from './Component.module.scss';
import { isEditable as hasEditPrivilege } from '../../utils/user';

const Component = () => {
  const dispatch = useDispatch();
  const {
    educationLevel,
    skills,
    currentCharacterID,
    characterLores,
    persistedLores,
    hasInteractedWithSkill,
    hasInteractedWithLore,
    approvedForPlay,
    user,
  } = useSelector(state => ({
    characterLores: state.character.lores,
    persistedLores: state.character.remotePersistance.lores,
    skills: state.character.skills,
    currentCharacterID: state.localStorage.currentCharacterID,
    educationLevel: state.character.skills.trees.education.levelAcquired,
    hasInteractedWithSkill: state.ui.user.hasInteractedWithSkill,
    hasInteractedWithLore: state.ui.user.hasInteractedWithLore,
    approvedForPlay: state.character.approvedForPlay,
    user: state.user,
  }));
  const isEditable = hasEditPrivilege(user, approvedForPlay);
  const toggleLore = t => {
    if (!hasInteractedWithLore) {
      dispatch({ type: 'FIRST_LORE_INTERACTION' });
    }
    dispatch({
      type: 'UPDATE_LORE',
      payload: {
        [t]: arbitrateLoreChange(t),
      },
    });
  };
  const arbitrateLoreChange = t =>
    approvedForPlay && !isEditable
      ? persistedLores[t]
        ? true
        : !characterLores[t]
      : !characterLores[t];

  const renderLores = () => {
    return Object.keys(lores).map(x => {
      return (
        <LoreGrid
          isEditable={isEditable}
          isAcquired={characterLores[x]}
          isPersisted={persistedLores[x]}
          passClick={toggleLore}
          name={lores[x].name}
          selector={x}
          key={x}
        />
      );
    });
  };
  const computeLoreCount = () =>
    Object.values(characterLores).filter(x => x).length;
  const computeLoreMax = () => educationLevel * 3;
  const handleEducationChange = change => {
    if (!hasInteractedWithSkill) dispatch({ type: 'FIRST_SKILL_INTERACTION' });
    const targetTier = clamp(educationLevel + change, 0, 3);
    dispatch({
      type: 'UPDATE_SKILL',
      payload: {
        education: {
          ...cloneDeep(skills.education),
          ...{ levelAcquired: targetTier },
        },
      },
    });
  };

  useEffect(() => {
    if (!currentCharacterID) return;
    if (!hasInteractedWithLore) return;
    dispatch({
      type: isEditable ? 'UPDATE_LORE_UPSTREAM' : 'UPDATE_LORE_DRAFTS',
    });
  }, [
    characterLores,
    currentCharacterID,
    dispatch,
    isEditable,
    hasInteractedWithLore,
  ]);

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.loreGraph}>
          <LoreLinear
            educationLevel={educationLevel}
            current={computeLoreCount()}
            max={computeLoreMax()}
            passEducationChange={handleEducationChange}
          />
        </div>
        <div className={styles.loreGroup}>{renderLores()}</div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(Component);
