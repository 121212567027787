import { useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import Popper from '@material-ui/core/Popper';
import ThumbUp from '@material-ui/icons/ThumbUp';
import SharedStatButton from '../../Shared/Stat/Button';
import SharedSkillCell from '../../Shared/Skill/Cell';
import SharedLoreGrid from '../../Shared/Lore/Grid';
import SharedTotalBuildBar from '../../Shared/TotalBuild/Bar';
import Slide from '@material-ui/core/Slide';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import styles from './ApprovedInfo.module.scss';

export default ({ isEditable, popper }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const popperName = 'planInfo';
  const open = Boolean(anchorEl);
  const id = open ? 'popper-plan' : undefined;
  const ref = useRef(null);
  const handleClick = () =>
    dispatch({ type: 'TOGGLE_POPPER', payload: popperName });
  const switchPopper = event => {
    event.stopPropagation();
    dispatch({ type: 'TOGGLE_POPPER', payload: 'xpBar' });
  };

  useEffect(() => {
    setAnchorEl(popper === popperName ? ref.current : null);
  }, [popper, ref, setAnchorEl]);

  if (isEditable) return null;
  return (
    <div ref={ref} onClick={handleClick}>
      <ThumbUp className={styles.thumb} />
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={styles.overlayContainer}
        transition
      >
        {({ TransitionProps }) => (
          <Slide {...TransitionProps} timeout={300}>
            <div className={styles.overlay}>
              Your character has been approved for play. As such, any changes
              you made are counted as drafts. When staff members update your
              character, your drafts will be updated accordingly.
              <br />
              <br />
              You can stage drafts to stats, skills, and lores only. Below are
              some examples of how drafts look like:
              <div className={styles.statBar}>
                <SharedStatButton
                  acquired={15}
                  innate={3}
                  isEditable={false}
                  remoteTotal={12}
                  statType={'mind'}
                  icon={faBolt}
                  anchorEl={null}
                  styleOverride={{ width: '100%' }}
                />
              </div>
              <div className={styles.skillCell}>
                <SharedSkillCell
                  category={'anomaly'}
                  tier={1}
                  levelAcquired={1}
                  remoteLevelAcquired={0}
                  isEditable={false}
                  name={'Biogenetics'}
                  visible={true}
                  styleOverride={{
                    width: 'calc(100% - 32px)',
                    borderTopLeftRadius: '16px',
                    borderBottomLeftRadius: '16px',
                  }}
                />
              </div>
              <div className={styles.loreGrid}>
                <SharedLoreGrid
                  isAcquired={true}
                  isEditable={false}
                  name={'Pre-Fall History'}
                  styleOverride={{
                    margin: 0,
                    borderWidth: 0,
                  }}
                />
              </div>
              <br />
              You can see the list of drafts you have made by clicking the Build
              Tracker
              <SharedTotalBuildBar
                label={'Tier/Build Tracker'}
                classNames={styles.buildBar}
                handleClick={switchPopper}
                styleOverride={{ zIndex: 999 }}
              />
            </div>
          </Slide>
        )}
      </Popper>
    </div>
  );
};
