export const initialState = false;

export default (state = initialState, { type }) => {
  switch (type) {
    case 'TOGGLE_MENU':
      return !state;
    case 'TOGGLE_MENU_CLOSED':
      return false;
    default:
      return state;
  }
};
