import React from 'react';
import { range } from 'lodash';
import uuid from 'uuid';
import styles from './Character.module.scss';

export default ({ structuredStat, isBlankSheet }) => {
  const {
    strainStats,
    body,
    mind,
    deciBody,
    deciMind,
    resolve,
    infection,
    deaths,
    totalBodyBuild,
    totalMindBuild,
    resolveBuild,
    infectionBuild,
    statBuildTotal,
  } = structuredStat;

  const blanked = colSpan => (
    <td
      className={[styles.text, styles.rightAlign, styles.blanked].join(' ')}
      colSpan={colSpan || 1}
    />
  );
  const rightAligned = (text, key, colSpan) => (
    <td
      className={[styles.text, styles.rightAlign].join(' ')}
      key={key || uuid.v1()}
      colSpan={colSpan || -1}
    >
      {text}
    </td>
  );

  const maybeBlank = x => (isBlankSheet ? '' : x);

  return (
    <div className={styles.stats}>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td rowSpan={2} className={styles.gridLead}>
              Stats
            </td>
            <td rowSpan={2} className={styles.statColumn}>
              Base
            </td>
            <td className={styles.statColumn}>1-20</td>
            <td className={styles.statColumn}>21-30</td>
            <td className={styles.statColumn}>31-40</td>
            <td className={styles.statColumn}>41-50</td>
            <td className={styles.statColumn}>51-60</td>
            <td className={styles.statColumn}>60+</td>
            <td className={styles.statColumn} rowSpan={2}>
              Stat
              <br />
              Total
            </td>
            <td
              className={[styles.statColumn, styles.buildSubTotal].join(' ')}
              rowSpan={2}
            >
              XP
            </td>
          </tr>
          <tr>
            <td className={styles.statColumn}>1</td>
            <td className={styles.statColumn}>3</td>
            <td className={styles.statColumn}>5</td>
            <td className={styles.statColumn}>7</td>
            <td className={styles.statColumn}>9</td>
            <td className={styles.statColumn}>10</td>
          </tr>
          <tr>
            <td className={styles.lead}>Body</td>
            {rightAligned(maybeBlank(strainStats.body))}
            {range(6).map(i => rightAligned(maybeBlank(deciBody[i]), i))}
            {rightAligned(maybeBlank(strainStats.body + body))}
            {rightAligned(maybeBlank(totalBodyBuild))}
          </tr>
          <tr>
            <td className={styles.lead}>Mind</td>
            {rightAligned(maybeBlank(strainStats.mind))}
            {range(6).map(i => rightAligned(maybeBlank(deciMind[i]), i))}
            {rightAligned(maybeBlank(strainStats.mind + mind))}
            {rightAligned(maybeBlank(totalMindBuild))}
          </tr>
          <tr>
            <td className={styles.lead}>Resolve (max: 6)</td>
            {rightAligned(maybeBlank(strainStats.resolve))}
            {rightAligned(resolve)}
            {blanked(5)}
            {rightAligned(maybeBlank(strainStats.resolve + resolve))}
            {rightAligned(maybeBlank(resolveBuild))}
          </tr>
          <tr>
            <td className={styles.lead}>Infection (max: 8)</td>
            {rightAligned(maybeBlank(strainStats.infection))}
            {rightAligned(infection)}
            {rightAligned('Deaths', null, 4)}
            {rightAligned(maybeBlank(deaths))}
            {rightAligned(
              maybeBlank(infection + strainStats.infection - deaths),
            )}
            {rightAligned(maybeBlank(infectionBuild))}
          </tr>
          <tr>
            <td
              className={[styles.text, styles.rightAlign, styles.padded].join(
                ' ',
              )}
              colSpan={8}
            >
              Stats XP Total
            </td>
            <td
              className={[styles.text, styles.rightAlign, styles.padded].join(
                ' ',
              )}
              colSpan={2}
            >
              {maybeBlank(statBuildTotal)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
