import { useDispatch, useSelector } from 'react-redux';
import { delay, fromPairs, isEqual } from 'lodash';
import React, { useEffect } from 'react';
import Close from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import TransferHistory from '../TransferHistory';
import DropdownField from '../FormComponents/DropdownField';
import TextField from '../FormComponents/TextField';
import styles from './EditUserModal.module.scss';
import sortedBranches from '../../utils/branches';
import { isPrivileged as hasPrivilege } from '../../utils/user';
import queryString from 'query-string';
import history from '../../history';
import { hasValidToken } from '../../utils/token';

export default () => {
  const remoteFieldMap = {
    // name: 'legal_name',
    branchId: 'branch_id',
    firstName: 'first_name',
    lastName: 'last_name',
    emailAddress: 'email_address',
  };

  const presence = value => {
    return value.length > 0 ? null : 'Cannot be empty';
  };
  const validEmail = value => {
    return value.match(/.+@.+\.\w+/) !== null ? null : 'Invalid email format';
  };

  const fieldValidations = {
    lastName: [presence],
    emailAddress: [presence, validEmail],
  };
  const dispatch = useDispatch();
  const { popper, modal, user, resetToken } = useSelector(state => ({
    popper: state.ui.character.popperOpened,
    modal: state.ui.modal,
    user: state.user,
    resetToken: state.ui.resetToken,
  }));
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState(null);
  const [errors, setErrors] = React.useState({});
  const [isResettingPassword, setIsResettingPassword] = React.useState(false);
  const popperName = 'editUserModal';
  const isPrivileged = hasPrivilege(user);

  const isErrorVisible = () => (modal.error ? '' : styles.hidden);
  const handleClose = () => {
    if (isResettingPassword) history.push('/');
    setIsResettingPassword(false);
    dispatch({ type: 'TOGGLE_POPPER', payload: popperName });
  };
  const openLoginModal = () => {
    history.push('/');
    dispatch({ type: 'TOGGLE_POPPER', payload: 'loginModal' });
    dispatch({ type: 'LOAD_MODAL', payload: { username: '', password: '' } });
  };
  const validate = (field, value) => {
    if (!fieldValidations[field]) return null;

    const errorMessages = [];
    fieldValidations[field]
      .map(functor => {
        const errorMessage = functor(value);
        if (errorMessage) errorMessages.push(errorMessage);
        return errorMessage === null;
      })
      .reduce((a, b) => a && b, true);

    return errorMessages.length > 0 ? errorMessages.join('. ') : null;
  };

  const handleChange = (field, value) => {
    setErrors({
      ...errors,
      [field]: validate(field, value),
    });
    dispatch({
      type: 'UPDATE_MODAL',
      payload: {
        field,
        value,
      },
    });
  };

  const filterDelta = () =>
    fromPairs(
      Object.keys(modal.initial)
        .filter(x => modal.initial[x] !== modal.current[x])
        .map(x => [remoteFieldMap[x] || x, modal.current[x]]),
    );

  const handleSubmit = () => {
    setState('updating');

    if (isResettingPassword) {
      dispatch({
        type: 'UPDATE_PASSWORD',
        payload: {
          token: resetPasswordToken,
          password: modal.current.password,
        },
      });
    } else {
      dispatch({
        type: 'UPDATE_USER',
        payload: {
          changes: filterDelta(),
          id: modal.initial.id,
        },
      });
    }
  };

  const onKeyDown = event => {
    if (event.keyCode === 13) handleSubmit();
  };

  // eslint-disable-next-line no-unused-vars
  const handleTestError = () => {
    setState('updating');
    delay(() => setState('error'), 500);
  };

  const actionButton = () => {
    const buttonState = () => {
      switch (state) {
        case 'modified':
          return styles.modified;
        case 'error':
          return styles.error;
        case 'done':
          return styles.done;
        default:
          return styles.disabled;
      }
    };

    const buttonText = () => {
      switch (state) {
        case 'modified':
          return 'Update';
        case 'updating':
          return 'Updating...';
        case 'done':
          return 'Done!';
        case 'error':
          return 'Error, retry?';
        default:
          return 'Update';
      }
    };

    return (
      <button
        className={[styles.button, buttonState()].join(' ')}
        disabled={!['modified', 'error'].includes(state)}
        onClick={handleSubmit}
        type='button'
      >
        {buttonText()}
      </button>
    );
  };

  const hasLocalErrors = () =>
    Object.keys(errors)
      .map(error => errors[error] !== null)
      .reduce((a, b) => a || b, false);

  const resetPasswordToken = queryString.parse(history.location.search)[
    'reset_password_token'
  ];

  const resetPasswordButtonState = () => {
    if (!modal.current.password) return null;
    if (modal.current.password.length < 3) return null;
    if (modal.current.password === modal.current.passwordConfirm)
      return 'modified';

    if (!modal.current.passwordConfirm) return null;
    if (modal.current.passwordConfirm.length === 0) return null;

    return 'doesNotMatch';
  };

  useEffect(() => {
    const isOpen = popper === popperName;
    setOpen(isOpen);
  }, [popper]);

  useEffect(() => {
    if (!open) return;
    if (modal.error) {
      setState('error');
    } else {
      if (isResettingPassword) {
        if (modal.remoteResult === 'passwordUpdated') {
          setState('done');
        } else {
          const buttonState = resetPasswordButtonState();
          if (buttonState === 'doesNotMatch') {
            setErrors({
              ...errors,
              passwordConfirm: 'Passwords do not match',
            });
            setState(null);
          } else {
            setState(buttonState);
          }
        }
      } else {
        setState(
          isEqual(modal.initial, modal.current)
            ? state === 'updating'
              ? 'done'
              : null
            : hasLocalErrors()
            ? null
            : 'modified',
        );
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal, open]); // hack to allow Done to be displayed

  useEffect(() => {
    if (!resetPasswordToken) return;
    if (hasValidToken(user.session)) dispatch({ type: 'DELETE_SESSION' });
    if (popper !== popperName) {
      setIsResettingPassword(true);
      // debugger
      dispatch({ type: 'TOGGLE_POPPER', payload: popperName });
      dispatch({
        type: 'VERIFY_RESET_PASSWORD_TOKEN',
        payload: resetPasswordToken,
      });
    }
  }, [resetPasswordToken, dispatch, popper, user]);

  const fieldsArbiter = () => {
    const nonPasswordFields = (
      <React.Fragment>
        <TextField
          title='First Name'
          fieldKey='firstName'
          value={modal.current.firstName || ''}
          initialValue={modal.initial.firstName}
          errors={errors['firstName']}
          passChange={handleChange}
        />
        <TextField
          title='Last Name'
          fieldKey='lastName'
          value={modal.current.lastName || ''}
          initialValue={modal.initial.lastName}
          errors={errors['lastName']}
          passChange={handleChange}
        />
        <TextField
          title='Email'
          fieldKey='emailAddress'
          value={modal.current.emailAddress || ''}
          initialValue={modal.initial.emailAddress}
          errors={errors['emailAddress']}
          passChange={handleChange}
        />
        <DropdownField
          hidden={!isPrivileged}
          title='Home Branch'
          fieldKey='branchId'
          value={modal.current.branchId || 0}
          options={sortedBranches()}
          initialValue={modal.initial.branchId}
          isError={false}
          passChange={handleChange}
        />
        <TransferHistory
          hidden={!isPrivileged}
          data={modal.initial.branchTransfers}
        />
        <DropdownField
          hidden={
            !isPrivileged ||
            ['branch_owner', 'admin'].includes(modal.initial.role)
          }
          title='Role'
          fieldKey='role'
          value={modal.current.role}
          options={[
            { value: 'guide', label: 'Guide' },
            { value: 'regular', label: 'Regular Player' },
          ]}
          initialValue={modal.initial.role}
          isError={false}
          passChange={handleChange}
        />
      </React.Fragment>
    );

    const passwordFields = (
      <React.Fragment>
        <TextField
          title='Password'
          fieldKey='password'
          placeholder={'At least 3 characters'}
          type='password'
          value={modal.current.password || ''}
          initialValue={modal.initial.password || ''}
          errors={errors['password']}
          passChange={handleChange}
          passKeyDown={onKeyDown}
          revert='clear'
        />
        <TextField
          title='Confirm'
          fieldKey='passwordConfirm'
          placeholder='Type your password again'
          type='password'
          value={modal.current.passwordConfirm || ''}
          initialValue={modal.initial.passwordConfirm || ''}
          errors={errors['passwordConfirm']}
          passChange={handleChange}
          passKeyDown={onKeyDown}
          revert='clear'
          autoComplete={true}
        />
      </React.Fragment>
    );

    const invalidTokenWarning = (
      <div>{`The reset password token ${resetPasswordToken} is either invalid or expired`}</div>
    );

    const passwordUpdatedMessage = (
      <div>
        Password has been successfully updated.
        <br />
        Please&nbsp;
        <span onClick={openLoginModal} className={styles.link}>
          Login
        </span>
        &nbsp;with your new password.
      </div>
    );

    if (!isResettingPassword) return nonPasswordFields;
    switch (resetToken) {
      case 'invalid':
        return invalidTokenWarning;
      case 'passwordUpdated':
        return passwordUpdatedMessage;
      default:
        return passwordFields;
    }
  };

  const modalTitle = () => {
    if (!isResettingPassword) return `Edit Player #${modal.current.id}`;
    if (resetToken === 'invalid') return 'Expired Reset Password Token';

    return resetToken && resetToken.userID
      ? `Reset Password for ${resetToken.userID}`
      : 'Reset Password';
  };

  // <div onClick={handleChange}>Hi!</div>
  // <div onClick={handleTestError}>Error?</div>
  return (
    <Modal open={open}>
      <Fade in={open}>
        <div className={styles.base}>
          <div className={styles.header}>
            <div className={styles.title}>{modalTitle()}</div>
            <Close className={styles.close} onClick={handleClose} />
          </div>
          <div className={styles.body}>{fieldsArbiter()}</div>
          <div className={styles.footer}>
            <div className={[styles.errorMessage, isErrorVisible()].join(' ')}>
              {modal.error}
            </div>
            <button
              className={[styles.button, styles.cancel].join(' ')}
              onClick={handleClose}
              type='button'
            >
              Close
            </button>
            {actionButton()}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
