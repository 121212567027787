import React from 'react';
import Undo from '@material-ui/icons/Undo';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import styles from './DropdownField.module.scss';

export default ({
  fieldKey,
  hidden,
  title,
  value,
  options,
  passChange,
  initialValue,
  isError,
  revert,
}) => {
  const irreversible = ['never', 'placebo'].includes(revert);
  const handleChange = event => passChange(fieldKey, event.target.value, 10);
  const handleUndo = () =>
    irreversible ? null : passChange(fieldKey, initialValue);
  const errorClass = () => (isError ? styles.error : '');
  const identicalClass = () => (value === initialValue ? styles.hidden : '');
  const unrevertableClass = () => (irreversible ? styles.hidden : '');
  const spacelessUnrevertableClass = () =>
    revert === 'never' ? styles.spaceless : '';
  const renderOptions = () =>
    options.map(x => (
      <MenuItem key={x.value} value={x.value} className={styles.item}>
        {x.label}
      </MenuItem>
    ));

  if (hidden) return null;

  return (
    <div className={styles.wrapper}>
      <div className={[styles.title, errorClass()].join(' ')}>{title}</div>
      <Select
        value={value}
        onChange={handleChange}
        className={styles.dropdown}
        classes={{
          select: styles.select,
          icon: styles.icon,
        }}
        MenuProps={{
          className: styles.menu,
        }}
        disableUnderline
      >
        {renderOptions()}
      </Select>
      <Undo
        className={[
          styles.undo,
          identicalClass(),
          unrevertableClass(),
          spacelessUnrevertableClass(),
        ].join(' ')}
        onClick={handleUndo}
      />
    </div>
  );
};
