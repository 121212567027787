import React from 'react';
import LastResortErrorHandler from './LastResortErrorHandler/Page';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) return <LastResortErrorHandler />;

    return this.props.children;
  }
}

export default ErrorBoundary;
