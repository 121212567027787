export const initialState = [];

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'FETCH_ACTIVE_SESSIONS_SUCCEEDED':
      return payload;
    default:
      return state;
  }
};
