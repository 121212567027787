import { all, put, select, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../Shared/Toastify/Toast';

function* fetchEventsCheckedIn({ payload }) {
  const user = yield select(state => state.user);
  const authConfig = user.session;
  const userID = payload.overrideUserId || user.id;

  if (!authConfig) return;

  try {
    const res = yield axios.get(
      `/users/${userID}/events_checked_in`,
      authConfig,
    );
    yield put({
      type: 'FETCH_EVENTS_CHECKED_IN_SUCCEEDED',
      payload: res.data,
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* fetchEvents() {
  const user = yield select(state => state.user);
  const authConfig = user.session;
  const userID = user.id;

  try {
    const res = yield axios.get('/events', authConfig);
    yield put({
      type: 'FETCH_EVENTS_SUCCEEDED',
      payload: res.data,
    });

    if (userID) yield fetchEventsCheckedIn({ payload: {} });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
    put({ type: 'FETCH_EVENTS_FAILED', payload: error });
  }
}

function* watchFetchEvents() {
  yield takeLatest('FETCH_EVENTS', fetchEvents);
}

function* watchFetchEventsAttended() {
  yield takeLatest('FETCH_EVENTS_ATTENDED', fetchEventsCheckedIn);
}

export default function* () {
  yield all([watchFetchEvents(), watchFetchEventsAttended()]);
}
