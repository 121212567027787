import React from 'react';
import styles from './Grid.module.scss';

export default ({
  handleClick,
  isAcquired,
  isEditable,
  isPersisted,
  name,
  styleOverride,
}) => (
  <div
    key={name}
    style={styleOverride}
    className={[
      styles.lore,
      (isEditable && isAcquired) || (!isEditable && isPersisted)
        ? styles.acquired
        : '',
      !isEditable && isAcquired && !isPersisted ? styles.planned : '',
    ].join(' ')}
    onClick={handleClick}
  >
    {name}
  </div>
);
