import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import React, { useEffect } from 'react';
import Close from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import TextField from '../FormComponents/TextField';
import styles from './EditUserModal.module.scss';
import loginStyles from './LoginModal.module.scss';

export default () => {
  const dispatch = useDispatch();
  const presence = value => {
    return value.length > 0 ? null : 'Cannot be empty';
  };
  const fieldValidations = {
    username: [presence],
    password: [presence],
  };
  const { popper, modal, resetPassword } = useSelector(state => ({
    popper: state.ui.character.popperOpened,
    modal: state.ui.modal,
    resetPassword: state.ui.resetPassword,
  }));

  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState(null);
  const [errors, setErrors] = React.useState({});
  const popperName = 'loginModal';
  const isErrorVisible = () => (modal.error ? '' : styles.hidden);
  const validate = (field, value) => {
    if (!fieldValidations[field]) return null;

    const errorMessages = [];
    fieldValidations[field]
      .map(functor => {
        const errorMessage = functor(value);
        if (errorMessage) errorMessages.push(errorMessage);
        return errorMessage === null;
      })
      .reduce((a, b) => a && b, true);

    return errorMessages.length > 0 ? errorMessages.join('. ') : null;
  };
  const handleChange = (field, value) => {
    setErrors({
      ...errors,
      [field]: validate(field, value),
    });
    dispatch({
      type: 'UPDATE_MODAL',
      payload: {
        field: field,
        value: value,
      },
    });
  };
  const handleSubmit = () => {
    setState('updating');
    dispatch({ type: 'CLEAR_MODAL_ERROR' });
    dispatch({
      type: 'LOGIN',
      payload: {
        username: modal.current.username,
        password: modal.current.password,
      },
    });
  };
  const handleResetPasswordClick = () => dispatch({ type: 'RESET_PASSWORD' });
  const handleClose = () => {
    dispatch({ type: 'TOGGLE_POPPER', payload: popperName });
    dispatch({ type: 'CLEAR_MODAL' });
    dispatch({ type: 'CLEAR_MODAL_ERROR' });
  };
  const onKeyDown = event => {
    if (event.keyCode === 13) handleSubmit();
  };

  const actionButton = () => {
    const buttonState = () => {
      switch (state) {
        case 'modified':
          return styles.modified;
        case 'error':
          return styles.error;
        case 'done':
          return styles.done;
        default:
          return styles.disabled;
      }
    };

    const buttonText = () => {
      switch (state) {
        case 'modified':
          return 'Login';
        case 'updating':
          return 'Logging in...';
        case 'done':
          return 'Success!';
        case 'error':
          return 'Error, retry?';
        default:
          return 'Login';
      }
    };

    return (
      <button
        className={[styles.button, buttonState()].join(' ')}
        disabled={!['modified', 'error'].includes(state)}
        onClick={handleSubmit}
      >
        {buttonText()}
      </button>
    );
  };

  const translateError = error => {
    if (!error) return null;
    if (error === 'branch locked') {
      return (
        <div>
          Your account has been disabled as a safety precaution.
          <hr className='hr-error' />
          To unlock your account, please visit the{' '}
          <a
            className='link-badge'
            href='https://community.dystopiarisingnetwork.com'
            target='_forum'
          >
            Forum
          </a>
          and drop a private message to
          <a
            className='link-badge'
            href='https://community.dystopiarisingnetwork.com/u/gloria'
            target='_gloria'
          >
            Gloria
          </a>
          &nbsp;to verify that this account is still active and belongs to the
          rightful owner.
          <br />
          <br />
          If you don't have a forum account yet, please create one then send a
          message to{' '}
          <a className='link-badge' href='mailto:gloria@dystopiarising.com'>
            Gloria
          </a>{' '}
          for proper access elevation.
        </div>
      );
    }
    return error;
  };

  const hasEmailOrID = () =>
    modal &&
    modal.current &&
    modal.current.username &&
    modal.current.username.length > 0;

  const arbitrateResetPassword = () => (
    <span
      className={[
        loginStyles.link,
        hasEmailOrID() ? '' : loginStyles.disabled,
      ].join(' ')}
      onClick={() => (hasEmailOrID() ? handleResetPasswordClick() : null)}
    >
      reset password
    </span>
  );

  const resetPasswordInstruction = () => {
    if (!resetPassword) {
      return (
        <div className={loginStyles.content}>
          Fill the player ID or email address above and click&nbsp;
          {arbitrateResetPassword()}
          .
          <br />
          <br />
          We'll send reset password instructions to email address associated
          with your account.
        </div>
      );
    }

    switch (resetPassword) {
      case 'pending':
        return (
          <div className={loginStyles.content}>
            Generating password reset instructions...
          </div>
        );
      case 'success':
        return (
          <div className={[loginStyles.content, loginStyles.success].join(' ')}>
            An email containing password reset instruction has been dispatched.
            Don't forget to check your spam folder too.
          </div>
        );
      default:
        return (
          <div className={[loginStyles.content, loginStyles.error].join(' ')}>
            An email containing password reset instruction has been dispatched.
            Don't forget to check your spam folder too.
          </div>
        );
    }
  };

  const hasLocalErrors = () =>
    Object.keys(errors)
      .map(error => errors[error] !== null)
      .reduce((a, b) => a || b, false);

  useEffect(() => {
    const isOpen = popper === popperName;
    setOpen(isOpen);
  }, [popper]);

  useEffect(() => {
    if (modal.error) {
      setState('error');
      // translateError(modal.error);
    } else {
      setState(
        isEqual(modal.initial, modal.current)
          ? state === 'updating'
            ? 'done'
            : null
          : hasLocalErrors()
          ? null
          : 'modified',
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]); // hack to allow Done to be displayed

  return (
    <Modal open={open}>
      <Fade in={open}>
        <div className={styles.base}>
          <div className={styles.header}>
            <div className={styles.title}>Login</div>
            <Close className={styles.close} onClick={handleClose} />
          </div>
          <div className={styles.body}>
            <form action='/' method='POST'>
              <TextField
                title={'Player ID'}
                fieldKey={'username'}
                value={modal.current.username || ''}
                initialValue={modal.initial.username || ''}
                errors={errors['username']}
                passChange={handleChange}
                autoFocus={true}
                revert='clear'
                type='email'
                placeholder='Or email address'
                autoComplete
              />
              <TextField
                title={'Password'}
                fieldKey={'password'}
                type={'password'}
                value={modal.current.password || ''}
                initialValue={modal.initial.password || ''}
                errors={errors['password']}
                passChange={handleChange}
                passKeyDown={onKeyDown}
                revert='clear'
                autoComplete
              />
            </form>
          </div>
          <div className={styles.footer}>
            <div className={[styles.errorMessage, isErrorVisible()].join(' ')}>
              {translateError(modal.error)}
            </div>

            <button
              className={[styles.button, styles.cancel].join(' ')}
              onClick={handleClose}
            >
              Close
            </button>
            {actionButton()}

            <div className={styles.break}></div>
            <div className={loginStyles.resetPassword}>
              <div className={loginStyles.header}>
                Can't Login/Forgot Password?
              </div>
              {resetPasswordInstruction()}
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
