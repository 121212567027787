import { useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import LegacyGameControl from './LegacyGame';
import Revertable from './Revertable';
import Popper from '@material-ui/core/Popper';
import PrivilegedEdit from '@material-ui/icons/VpnKey';
import Slide from '@material-ui/core/Slide';
import styles from './AdminAccessInfo.module.scss';

export default ({ isAdminMode, isRemote, hasValidToken, popper }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const popperName = 'adminAccessInfo';
  const open = Boolean(anchorEl);
  const id = open ? 'admin-access' : undefined;
  const ref = useRef(null);
  const handleClick = () =>
    dispatch({ type: 'TOGGLE_POPPER', payload: popperName });

  useEffect(() => {
    setAnchorEl(popper === popperName ? ref.current : null);
  }, [popper, ref, setAnchorEl]);

  if (!hasValidToken) return null;
  if (!isAdminMode || !isRemote) return null;

  return (
    <div ref={ref} onClick={handleClick}>
      <PrivilegedEdit className={styles.privileged} />
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={styles.overlayContainer}
        transition
      >
        {({ TransitionProps }) => (
          <Slide {...TransitionProps} timeout={300}>
            <div className={styles.overlay}>
              You are editing this character using Admin privilege.
              <LegacyGameControl isAdmin={isAdminMode} />
              <Revertable />
              <div className={styles.fakeClose}>I'll behave!</div>
            </div>
          </Slide>
        )}
      </Popper>
    </div>
  );
};
