import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SharedStatControl from '../../Shared/Stat/Control';
import styles from './LegacyGame.module.scss';

export default ({ isAdmin }) => {
  const dispatch = useDispatch();
  const { legacyHomeGames, legacyHomeGamesPersisted, homeGamesAttended } =
    useSelector(state => ({
      homeGamesAttended: state.character.homeGamesAttended,
      legacyHomeGames: state.character.legacyHomeGames.current,
      legacyHomeGamesPersisted: state.character.legacyHomeGames.persisted,
    }));

  const isDecrementable = legacyHomeGames > 0;
  const hasChanged = legacyHomeGames !== legacyHomeGamesPersisted;

  const submitChange = event => {
    event.stopPropagation();
    dispatch({
      type: 'UPDATE_LEGACY_HOME_GAME_COUNT_UPSTREAM',
      payload: legacyHomeGames,
    });
  };

  const handleChange = event => {
    dispatch({
      type: 'UPDATE_LEGACY_HOME_GAME_COUNT',
      payload: parseInt(event.target.value) || 0,
    });
  };

  const handleSubtract = event => {
    event.stopPropagation();
    if (legacyHomeGames === 0) return;

    dispatch({
      type: 'UPDATE_LEGACY_HOME_GAME_COUNT',
      payload: legacyHomeGames - 1,
    });
  };

  const handleAdd = event => {
    event.stopPropagation();
    dispatch({
      type: 'UPDATE_LEGACY_HOME_GAME_COUNT',
      payload: legacyHomeGames + 1,
    });
  };

  const totalHomeGame = () => homeGamesAttended + legacyHomeGames;
  const nextHomeGameGrowth = () => {
    switch (parseInt(totalHomeGame() / 12)) {
      case 0:
        return 8;
      case 1:
        return 4;
      default:
        return 3;
    }
  };

  if (!isAdmin) return null;

  return (
    <div className={styles.container}>
      <div className={styles.title}>Home Games Count</div>
      <div className={styles.title}>
        <span className={styles.bold}>Legacy</span>
        &nbsp;+ 3.0
      </div>
      <div className={styles.control}>
        <SharedStatControl
          innate={homeGamesAttended}
          acquired={legacyHomeGames}
          passChange={handleChange}
          passSubtract={handleSubtract}
          passAdd={handleAdd}
          isIncrementable={true}
          isDecrementable={isDecrementable}
          swapPosition={true}
          invertColor={true}
        >
          <span>&nbsp;{`= ${homeGamesAttended + legacyHomeGames} `}</span>
        </SharedStatControl>
      </div>
      <div className={styles.title}>
        {`Next Home Game Growth: ${nextHomeGameGrowth()}`}
      </div>
      <div
        className={[styles.button, hasChanged ? '' : styles.disabled].join(' ')}
        onClick={submitChange}
      >
        Update and Reload
      </div>
    </div>
  );
};
