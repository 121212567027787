import { fromPairs } from 'lodash';

export default (tickets, withBoost = false) =>
  fromPairs(
    tickets
      .filter(ticket => (withBoost ? true : !ticket.build_growth))
      .map(ticket => [
        ticket.identifier,
        {
          id: ticket.id,
          name: ticket.label,
          dayPass: ticket.pass_type === 'day_pass',
          limit: ticket.limit,
          shiftResponsibility: ticket.shift_responsibilities,
          cost: ticket.cost,
          order: ticket.identifier,
          buildGrowth: ticket.build_growth,
          isFullyBooked: ticket.fully_booked,
        },
      ]),
  );
