import React from 'react';
import styles from './TextArea.module.scss';

export default ({ content, placeholder, handleUpdate, handleBlur }) => {
  return (
    <textarea
      rows={8}
      className={styles.textarea}
      placeholder={placeholder}
      onChange={handleUpdate}
      onBlur={handleBlur}
      value={content || ''}
    />
  );
};
