export default {
  0: 'faithless',
  4: 'churchOfDarwin',
  5: 'cultOfTheFallowHopes',
  6: 'finalKnights',
  10: 'lightOfTheHedon',
  3: 'theNuclearFamily',
  1: 'theTellingVisionaries',
  2: 'sainthoodOfAshes',
  8: 'tribesOfTheSeasons',
  7: 'kingsAndQueens',
  9: 'cults',
};
