import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fromPairs, groupBy } from 'lodash';
import { compareAsc, format, parseISO } from 'date-fns';
import styles from './Revenue.module.scss';

export default ({ user, data, branchID, isFetching }) => {
  const dispatch = useDispatch();
  const revenueCell = snapshots => {
    let latchedRevenue = null;

    return snapshots
      .sort((a, b) => {
        const snapA = parseISO(a.snapshot_for);
        const snapB = parseISO(b.snapshot_for);

        return compareAsc(snapB, snapA);
      })
      .map(x => {
        let renderableRevenue = null;
        const extraXpCost = x.linked_entity.config.extra_xp_cost || 15;
        const ticketRevenue = ticketData
          ? Object.keys(x.data)
              .map(ticketId => {
                const ticket = x.data[ticketId];

                return (
                  ticket.count * ticketData[ticketId].cost +
                  ticket.extra_build * extraXpCost
                );
              })
              .reduce((a, b) => a + b, 0)
          : '...';

        if (ticketRevenue === latchedRevenue) {
          renderableRevenue = '~';
        } else {
          renderableRevenue = `$${ticketRevenue.toLocaleString()}`;
          latchedRevenue = ticketRevenue;
        }

        return (
          <td key={x.snapshot_for} className={styles.cell}>
            {renderableRevenue}
          </td>
        );
      });
  };

  const revenueData = data[branchID] ? data[branchID].revenue : [];
  const ticketData =
    data[branchID] && data[branchID].tickets
      ? fromPairs(data[branchID].tickets.map(x => [x.id, x]))
      : null;
  const eventGrouped = groupBy(revenueData, 'linked_entity.id');
  const eventSorted = Object.keys(eventGrouped)
    .sort((a, b) => {
      const startA = eventGrouped[a][0].linked_entity.starts_at;
      const startB = eventGrouped[b][0].linked_entity.starts_at;

      return compareAsc(parseISO(startB), parseISO(startA));
    })
    .map(x => eventGrouped[x]);

  const snapshotRanges = Object.keys(groupBy(revenueData, 'snapshot_for')).sort(
    (a, b) => compareAsc(parseISO(b), parseISO(a)),
  );

  const revenueMatrix = eventSorted.map(x => {
    const event = x[0].linked_entity;

    return (
      <tr key={event.id} className={styles.eventRow}>
        <td className={styles.stickyColumn}>{event.name}</td>
        {revenueCell(x)}
      </tr>
    );
  });

  useEffect(() => {
    if (!user) return;
    if (!user.session) return;
    if (!branchID) return;

    dispatch({ type: 'FETCH_BRANCH_REVENUE', payload: branchID });
    dispatch({ type: 'FETCH_BRANCH_TICKETS', payload: branchID });
  }, [user, dispatch, branchID]);

  if (
    !(
      data[branchID] &&
      data[branchID].revenue &&
      data[branchID].tickets &&
      data[branchID].revenue.length > 0
    )
  )
    return null;

  return (
    <div className={styles.container}>
      <div className={styles.disclaimer}>
        <b>Note</b>
        : Revenue Table does NOT take Employee discount into account.
        <br />
        <b>~</b> indicates no revenue change from succeeding month.
        <br />
        Snapshot is accurate as of 10th calendar day of each month.
      </div>
      <table className={styles.gridTable}>
        <thead>
          <tr className={styles.stickyRow}>
            <th className={styles.stickyColumn}>Event Revenue</th>
            {snapshotRanges.map(x => (
              <th key={x} className={styles.stickyCell}>
                {format(parseISO(x), 'yyyy MMM')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{revenueMatrix}</tbody>
      </table>
    </div>
  );
};
