export const initialState = {
  hasInteractedWithSkill: false,
  hasInteractedWithStat: false,
  hasInteractedWithLore: false,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'APP_LOADED':
    case 'SWITCH_CHARACTER':
    case 'IMPERSONATE_USER':
    case 'CREATE_SESSION_SUCCESS':
    case 'NUKE_DUE_TO_TOKEN_EXPIRATION':
    case 'REVERT_TO_CHECKPOINT':
      return {
        ...state,
        ...initialState,
      };
    case 'FIRST_SKILL_INTERACTION':
      return { ...state, hasInteractedWithSkill: true };
    case 'FIRST_STAT_INTERACTION':
      return { ...state, hasInteractedWithStat: true };
    case 'FIRST_LORE_INTERACTION':
      return { ...state, hasInteractedWithLore: true };
    default:
      return state;
  }
};
