import { SYNC_BUFFER } from '../types';

export const initialState = 'faithless';

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SYNC_BUFFER:
      return payload.faith;
    default:
      return state;
  }
};
