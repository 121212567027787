import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compareAsc, parseISO } from 'date-fns';
import ItemTransactionAudit from '../../Events/Administration/ItemTransactionAudit';
import SlickDropdown from '../../Shared/SlickDropdown';

export default ({ isPrivileged, userId }) => {
  const dispatch = useDispatch();
  const [eventId, setEventId] = useState(null);
  const { eventsCheckedIn } = useSelector(state => ({
    eventsCheckedIn: state.events.eventsCheckedIn,
  }));

  const eventOptions = useMemo(() => {
    return Object.values(eventsCheckedIn)
      .sort((a, b) =>
        compareAsc(parseISO(b.event.starts_at), parseISO(a.event.starts_at)),
      )
      .map(x => ({ label: x.event.name, value: x.event.id }));
  }, [userId, eventsCheckedIn]);

  useEffect(() => {
    if (!userId) return;
    setEventId(null);
    dispatch({
      type: 'FETCH_EVENTS_ATTENDED',
      payload: {
        overrideUserId: userId,
      },
    });
  }, [userId]);

  return (
    <div>
      <SlickDropdown
        placeholder='Select Event'
        emptyOptions='No Recorded Event Attendance'
        options={eventOptions}
        selectedOptionId={eventId}
        onChange={val => setEventId(val)}
      />
      <ItemTransactionAudit eventId={eventId} userScoping={userId} />
    </div>
  );
};
