import { cloneDeep } from 'lodash';
import strains from '../../gameData/strains';

const combineStatSigma = (plannedStats, remoteStats, innateStats) => {
  const deltaSigma = key => {
    const remoteAdded = remoteStats[key];
    const innateStat = innateStats[key];
    const totalPlanned = plannedStats[key] || remoteAdded + innateStat;

    return Math.max(totalPlanned - innateStat, remoteAdded);
  };

  return {
    body: deltaSigma('body'),
    mind: deltaSigma('mind'),
    resolve: deltaSigma('resolve'),
    infection: deltaSigma('infection'),
  };
};

const combineSkillSigma = (plannedSkills, persistedSkills) => {
  const skills = cloneDeep(persistedSkills);

  Object.keys(plannedSkills)
    .filter(
      x =>
        plannedSkills[x].levelAcquired > persistedSkills[x].levelAcquired ||
        (plannedSkills[x].achievementIsAcquired &&
          !persistedSkills[x].achivementIsAcquired),
    )
    .forEach(x => {
      skills[x].levelAcquired = plannedSkills[x].levelAcquired;
      skills[x].achievementIsAcquired = plannedSkills[x].achievementIsAcquired;
    });

  return skills;
};

const combineLoreSigma = (plannedLores, persistedLores) => {
  const lores = cloneDeep(persistedLores);

  Object.keys(plannedLores).forEach(x => {
    lores[x] = lores[x] || plannedLores[x];
  });

  return lores;
};

export default (plan, persisted, multiplex, strain) => {
  if (!plan) {
    return {
      stats: {
        body: persisted.body,
        mind: persisted.mind,
        resolve: persisted.resolve,
        infection: persisted.infection,
      },
      skills: multiplex.skills,
      lores: multiplex.lores,
    };
  }

  const statSigma = combineStatSigma(
    plan.stats,
    persisted,
    strains[strain].lineage.stats,
  );
  const skillSigma = combineSkillSigma(plan.skills, multiplex.skills);
  const loreSigma = combineLoreSigma(plan.lores, multiplex.lores);

  return {
    stats: statSigma,
    skills: skillSigma,
    lores: loreSigma,
  };
};
