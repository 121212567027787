import { toast } from 'react-toastify';
import uuid from 'uuid';
import 'react-toastify/dist/ReactToastify.css';

// type:string success | error
export default ({ text, type, toastID }) =>
  toast(text, {
    draggable: false,
    autoClose: 5000,
    position: toast.POSITION.BOTTOM_LEFT,
    className: `toast-base ${type}`,
    bodyClassName: 'toast-body',
    progressClassName: 'progress-bar',
    toastId: toastID || uuid.v1(),
  });
