import React from 'react';
import { format } from 'date-fns';
import styles from './Character.module.scss';

export default ({ data, ticketName, ticketCost }) => (
  <div className={styles.eventInfo}>
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Event Information</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={styles.text}>{data.name || 'Event: ________'}</td>
        </tr>
        <tr>
          <td className={styles.text}>
            {`Location: ${data.location.name || '________'}`}
          </td>
        </tr>
        <tr>
          <td className={styles.text}>
            {`Ticket: $${ticketCost === null ? '__' : ticketCost} ${
              ticketName || ''
            }`}
          </td>
        </tr>
        <tr>
          <td className={styles.text}>
            {`Printed ${format(Date.now(), 'yyyy-MM-dd HH:mm')}`}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);
