export const initialState = null;

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'SYNC_BUFFER':
      return payload.playerNotes;
    default:
      return state;
  }
};
