import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fromPairs, groupBy } from 'lodash';
import PunchCard from './PunchCard';
import branches from '../gameData/branches';
import styles from './SubAttendances.module.scss';

export default ({
  users,
  title,
  isTravelers,
  isInactive,
  isDefaultVisible,
  isFetching,
  passFetch,
}) => {
  const dispatch = useDispatch();
  const [userFlags, setUserFlags] = useState({});
  const [visible, setVisible] = useState(!!isDefaultVisible);
  const [isLocallyFetching, setIsLocallyFetching] = useState(false);

  const toggleVisibility = () => {
    setVisible(!visible);
    if (users === null) {
      setIsLocallyFetching(true);
      passFetch();
    }
  };

  const toggleUserFlag = (id, flag) => {
    const currentValue = userFlags[id] && userFlags[id].includes(flag);

    setUserFlags({
      ...userFlags,
      [id]: currentValue
        ? userFlags[id].filter(x => x !== flag)
        : userFlags[id].concat(flag),
    });
    dispatch({
      type: 'TOGGLE_USER_FLAG',
      payload: {
        userID: id,
        flag,
        value: !currentValue,
      },
    });
  };

  const renderRow = x => (
    <div className={styles.row} key={x.id}>
      <div className={styles.playerID}>
        <a
          className={styles.link}
          href={`/?player_id=${x.id}`}
          target='_whodis'
        >
          {x.id}
        </a>
      </div>
      <div className={styles.playerName}>
        {title === 'Guides' && (
          <React.Fragment>
            <FontAwesomeIcon
              icon='cog'
              onClick={() => toggleUserFlag(x.id, 'mod_manager')}
              className={[
                styles.flag,
                userFlags[x.id] &&
                  userFlags[x.id].includes('mod_manager') &&
                  styles.withFlags,
              ].join(' ')}
            />
            <FontAwesomeIcon
              icon='box-open'
              onClick={() => toggleUserFlag(x.id, 'logistics')}
              className={[
                styles.flag,
                userFlags[x.id] &&
                  userFlags[x.id].includes('logistics') &&
                  styles.withFlags,
              ].join(' ')}
            />
          </React.Fragment>
        )}
        {[x.first_name, x.last_name].join(' ').trim()}
      </div>
      {isInactive ? null : (
        <div className={styles.punchCard}>
          <PunchCard data={x.event_attendees} isTravelers={isTravelers} />
        </div>
      )}
    </div>
  );

  const sortedUsers = (users || []).sort((a, b) => a.id - b.id);
  const groupedBranch = groupBy(sortedUsers, 'branch_id');

  const renderUsers = () =>
    isTravelers
      ? Object.keys(groupedBranch)
          .sort((a, b) => groupedBranch[b].length - groupedBranch[a].length)
          .map(branchID => (
            <div key={branchID} className={styles.travelerGroup}>
              <div className={styles.branchHeader}>
                {`${branches[branchID].shortName} (${groupedBranch[branchID].length})`}
              </div>
              <div>{groupedBranch[branchID].map(renderRow)}</div>
            </div>
          ))
      : sortedUsers.map(renderRow);

  const renderCount = () => {
    if (users) return ` (${users.length})`;
    if (isLocallyFetching) return '...';
    if (isDefaultVisible) return '...';
    return <span className={styles.clickToFetch}>Click to Fetch</span>;
  };

  useEffect(() => {
    if (!isFetching) setIsLocallyFetching(false);
  }, [isFetching]);

  useEffect(() => {
    if (!users) return;
    setUserFlags({
      ...userFlags,
      ...fromPairs(users.map(x => [x.id, userFlags[x.id] || x.flags])),
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={toggleVisibility}>
        <div className={styles.text}>
          {title}
          {renderCount()}
        </div>
        <div className={styles.expand}>
          {visible ? <ExpandLess /> : <ExpandMore />}
        </div>
      </div>
      <div className={[styles.content, !visible && styles.hidden].join(' ')}>
        {renderUsers()}
      </div>
    </div>
  );
};
