import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Loader from 'react-spinners/RingLoader';
import BranchSelect from './Branches/Select';
import Calendar from './EventTable/Container';
import CharacterLoader from '../utils/loader';
import LoginModal from '../Shared/Modals/LoginModal';
import { hasValidToken } from '../utils/token';
import {
  isGuide as hasGuideRole,
  isPrivileged as hasPrivilege,
  isAdmin as hasAdminPrivilege,
} from '../utils/user';
import styles from './Page.module.scss';

export default () => {
  const dispatch = useDispatch();
  const {
    data,
    eventsCheckedIn,
    eventsExternalRun,
    isFetchingEvents,
    selectedBranches,
    user,
    currentCharacterID,
    characterStorage,
    ui,
  } = useSelector(state => ({
    data: state.events.data,
    eventsCheckedIn: state.events.eventsCheckedIn,
    eventsExternalRun: state.events.eventsRun,
    isFetchingEvents: state.events.isFetching,
    selectedBranches: state.ui.selectedBranches,
    user: state.user,
    currentCharacterID: state.localStorage.currentCharacterID,
    characterStorage: state.localStorage.characterStorage,
    ui: state.localStorage.ui,
  }));
  const setSelectedBranches = branchIDs =>
    dispatch({ type: 'UPDATE_SELECTED_BRANCHES', payload: branchIDs });

  const isLoggedIn = hasValidToken(user.session);
  const isGuide = hasGuideRole(user);
  const isPrivileged = hasPrivilege(user);
  const isAdmin = hasAdminPrivilege(user);

  useEffect(() => {
    dispatch({ type: 'APP_LOADED' });
  }, [dispatch]);

  useEffect(() => {
    CharacterLoader({ characterStorage, currentCharacterID, dispatch });
  }, [characterStorage, currentCharacterID, dispatch]);

  useEffect(() => {
    if (ui) dispatch({ type: 'SYNC_UI', payload: ui });
  }, [ui, dispatch]);

  useEffect(() => {
    dispatch({ type: 'FETCH_EVENTS' });
  }, [user, dispatch]);

  return (
    <LoadingOverlay
      active={isFetchingEvents}
      spinner={<Loader color='#ccc' size={60} />}
      fadeSpeed={100}
      text='Fetching Events...'
    >
      <LoginModal />
      <div className={styles.container}>
        <BranchSelect
          user={user}
          selectedBranches={selectedBranches}
          setSelectedBranches={setSelectedBranches}
        />
        <Calendar
          data={data}
          homeBranchID={user.branchId}
          isLoggedIn={isLoggedIn}
          isGuide={isGuide}
          isPrivileged={isPrivileged}
          isAdmin={isAdmin}
          ownedBranches={user.ownedBranches}
          selectedBranches={selectedBranches}
          eventsCheckedIn={eventsCheckedIn}
          eventsExternalRun={eventsExternalRun}
        />
      </div>
    </LoadingOverlay>
  );
};
