import { all, put, select, takeLatest } from 'redux-saga/effects';
import { difference, fromPairs, startCase } from 'lodash';
import Toast from '../../Shared/Toastify/Toast';
import buildTicketsPayload from './actions/buildTicketsPayload';
import buildShiftsPayload from './actions/buildShiftsPayload';
import localizeTicketsPayload from './actions/localizeTicketsPayload';
import localizeShiftsPayload from './actions/localizeShiftsPayload';
import axios from 'axios';
import { parseISO } from 'date-fns';

const localizePayload = res => ({
  isPersisted: true,
  eventID: res.data.id,
  branchID: res.data.branch_id,
  tickets: localizeTicketsPayload(res.data.tickets),
  shifts: localizeShiftsPayload(res.data.shifts),
  selectedLocation: res.data.location_id,
  isPremiere: ['premiere', 'national_premiere'].includes(res.data.kind),
  isNational: ['national', 'national_premiere'].includes(res.data.kind),
  startDate: parseISO(res.data.starts_at),
  endDate: parseISO(res.data.ends_at),
  eventName: res.data.name,
  hasModifiedName: true,
  modSections: fromPairs(res.data.mod_sections.map(x => [x.id, x])),
  mods: fromPairs(
    res.data.mod_sections
      .map(x => x.mods)
      .flat()
      .map(x => [x.id, x]),
  ),
  modSignupOpensAt: res.data.mod_signup_opens_at,
  modDescription: res.data.mod_description,
  modMaxSignup: res.data.mod_max_signup,
  modEnableWaitlist: res.data.mod_enable_waitlist,
  registrationDate: res.data.registration_opens_at
    ? parseISO(res.data.registration_opens_at)
    : null,
});

function* setupEvent({ payload }) {
  const authConfig = yield select(state => state.user.session);

  const upstreamPayload = {
    location_id: payload.selectedLocation,
    type: payload.isPremiere ? 'premiere' : 'regular',
    starts_at: payload.startDate,
    ends_at: payload.endDate,
    registration_opens_at: payload.registrationDate,
    tickets: buildTicketsPayload(payload.tickets),
    shifts: buildShiftsPayload(payload.shifts, payload.isPremiere),
    name: payload.eventName,
    branch_id: payload.selectedBranch,
  };

  const action = () =>
    payload.eventID
      ? axios.patch(
          `branches/${payload.branchID}/events/${payload.eventID}`,
          upstreamPayload,
          authConfig,
        )
      : axios.post(
          `branches/${payload.selectedBranch}/events`,
          upstreamPayload,
          authConfig,
        );

  try {
    yield put({
      type: 'UPDATE_EVENT_SETUP',
      payload: {
        field: 'isProcessing',
        value: true,
      },
    });
    const res = yield action();
    yield put({
      type: 'UPDATE_EVENT_FROM_REMOTE',
      payload: localizePayload(res),
    });
    yield put({
      type: 'UPDATE_EVENT_SETUP',
      payload: {
        field: 'isProcessing',
        value: false,
      },
    });
    Toast({
      text: `Event successfully ${payload.eventID ? 'updated' : 'created'}!`,
      type: 'success',
    });
  } catch (error) {
    yield put({
      type: 'UPDATE_EVENT_SETUP_WITHOUT_ERROR_NULLIFY',
      payload: {
        field: 'remoteError',
        value: error.response.data.message,
      },
    });
    yield put({
      type: 'UPDATE_EVENT_SETUP_WITHOUT_ERROR_NULLIFY',
      payload: {
        field: 'isProcessing',
        value: false,
      },
    });
  }
}

function* fetchExistingEvent({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield axios.get(`events/${payload}/checkin_info`, authConfig);
    yield put({
      type: 'UPDATE_EVENT_SETUP',
      payload: {
        field: 'selectedBranch',
        value: res.data.branch_id,
      },
    });
    yield put({
      type: 'UPDATE_EVENT_FROM_REMOTE',
      payload: localizePayload(res),
    });
  } catch (error) {
    yield put({
      type: 'UPDATE_EVENT_SETUP',
      payload: {
        field: 'remoteError',
        value: error.response.data.message,
      },
    });
  }
}

function* fetchExternalManagers({ payload }) {
  const authConfig = yield select(state => state.user.session);
  if (!authConfig) return;

  try {
    const res = yield axios.get(
      `events/${payload}/temporary_managers`,
      authConfig,
    );
    yield put({
      type: 'FETCH_EXTERNAL_MANAGERS_SUCCEEDED',
      payload: res.data,
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* updateExternalManagers({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield axios.post(
      `events/${payload.eventID}/assign_temporary_managers`,
      { user_ids: payload.userIDs },
      authConfig,
    );
    yield put({
      type: 'UPDATE_EXTERNAL_MANAGERS_SUCCEEDED',
      payload: res.data,
    });
    Toast({ text: 'External Managers successfully added', type: 'success' });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* removeExternalManager({ payload }) {
  if (payload.localOnly) return;

  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield axios.delete(
      `events/${payload.eventID}/temporary_managers/${payload.userID}`,
      authConfig,
    );
    yield put({
      type: 'FETCH_EXTERNAL_MANAGERS_SUCCEEDED',
      payload: res.data,
    });
    Toast({ text: 'External Managers successfully removed', type: 'success' });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* fetchPastEventConfigurations({ payload }) {
  const authConfig = yield select(state => state.user.session);
  if (!authConfig) return;

  try {
    const res = yield axios.get(
      `branches/${payload}/past_event_configurations`,
      authConfig,
    );
    yield put({
      type: 'FETCH_PAST_EVENT_CONFIGURATIONS_SUCCEEDED',
      payload: res.data,
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* fetchBranchInfo({ payload }) {
  if (payload.field !== 'selectedBranch') return;

  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield axios.get(`branches/${payload.value}`, authConfig);
    yield put({ type: 'UPDATE_BRANCH_INFO', payload: res.data });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* createEventModSection({ payload }) {
  const authConfig = yield select(state => state.user.session);
  const modSections = yield select(state => state.eventSetup.modSections);

  try {
    const currentModSections = Object.keys(modSections).map(x =>
      parseInt(x, 10),
    );
    const res = yield axios.post(
      `events/${payload.eventID}/mod_sections`,
      { name: payload.name },
      authConfig,
    );
    const updatedModSections = res.data.map(x => x.id);
    yield all(
      difference(updatedModSections, currentModSections).map(x =>
        put({ type: 'EXPAND_MOD_SECTION', payload: x }),
      ),
    );
    yield put({ type: 'UPDATE_EVENT_MODLIST', payload: res.data });
    Toast({ text: 'New Mod Section successfully created', type: 'success' });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* updateEventModSection({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield axios.put(
      `events/${payload.eventID}/mod_sections/${payload.id}`,
      { [payload.field]: payload.value },
      authConfig,
    );
    yield put({ type: 'UPDATE_EVENT_MODLIST', payload: res.data });
    Toast({
      text: `Mod Section ${startCase(payload.field)} successfully updated`,
      type: 'success',
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* createEventMod({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield axios.post(
      `events/${payload.eventID}/mods`,
      {
        name: payload.name,
        timeframe: payload.timeframe,
        mod_section_id: payload.modSectionID,
      },
      authConfig,
    );
    yield put({ type: 'UPDATE_EVENT_MODLIST', payload: res.data });
    Toast({ text: 'New Mod successfully created', type: 'success' });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* updateEventMod({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield axios.put(
      `events/${payload.eventID}/mods/${payload.id}`,
      { [payload.field]: payload.value },
      authConfig,
    );
    yield put({ type: 'UPDATE_EVENT_MODLIST', payload: res.data });
    Toast({
      text: `Mod ${startCase(payload.field)} successfully updated`,
      type: 'success',
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* updateEventModsMetadata({ payload }) {
  const authConfig = yield select(state => state.user.session);
  if (!authConfig) return;

  try {
    const res = yield axios.put(
      `branches/${payload.branchID}/events/${payload.eventID}`,
      { [payload.field]: payload.value },
      authConfig,
    );
    yield put({
      type: 'UPDATE_EVENT_MODS_METADATA_SUCCEEDED',
      payload: res.data,
    });
    Toast({
      text: `Event ${startCase(payload.field)} successfully updated`,
      type: 'success',
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* fetchModParticipants({ payload }) {
  const authConfig = yield select(state => state.user.session);
  if (!authConfig) return;

  try {
    const res = yield axios.get(
      `events/${payload.eventID}/mod_participants`,
      authConfig,
    );
    yield put({
      type: 'FETCH_MOD_PARTICIPANTS_SUCCEEDED',
      payload: res.data,
    });

    if (payload.withToast) {
      Toast({ text: 'Mod Participants Successfully Updated', type: 'success' });
    }
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* kickModParticipant({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield axios.post(
      `events/${payload.eventID}/mod_signup/${payload.modID}/kick`,
      { user_id: payload.playerID },
      authConfig,
    );
    yield put({
      type: 'FETCH_MOD_PARTICIPANTS_SUCCEEDED',
      payload: res.data,
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* watchBranchChange() {
  yield takeLatest('UPDATE_EVENT_SETUP', fetchBranchInfo);
}

function* watchEventSetup() {
  yield takeLatest('EVENT_SETUP', setupEvent);
}

function* watchFetchExistingEvent() {
  yield takeLatest('FETCH_EXISTING_EVENT', fetchExistingEvent);
}

function* watchFetchExternalManagers() {
  yield takeLatest('FETCH_EXTERNAL_MANAGERS', fetchExternalManagers);
}

function* watchUpdateExternalManagers() {
  yield takeLatest('UPDATE_EXTERNAL_MANAGERS', updateExternalManagers);
}

function* watchRemoveExternalManager() {
  yield takeLatest('REMOVE_EXTERNAL_MANAGER', removeExternalManager);
}

function* watchFetchPastEventConfigurations() {
  yield takeLatest(
    'FETCH_PAST_EVENT_CONFIGURATIONS',
    fetchPastEventConfigurations,
  );
}

function* watchCreateEventModSection() {
  yield takeLatest('CREATE_EVENT_MOD_SECTION', createEventModSection);
}

function* watchUpdateEventModSection() {
  yield takeLatest('UPDATE_EVENT_MOD_SECTION', updateEventModSection);
}

function* watchCreateEventMod() {
  yield takeLatest('CREATE_EVENT_MOD', createEventMod);
}

function* watchUpdateEventMod() {
  yield takeLatest('UPDATE_EVENT_MOD', updateEventMod);
}

function* watchUpdateEventModsMetadata() {
  yield takeLatest('UPDATE_EVENT_MODS_METADATA', updateEventModsMetadata);
}

function* watchFetchModParticipants() {
  yield takeLatest('FETCH_MOD_PARTICIPANTS', fetchModParticipants);
}

function* watchKickModParticipant() {
  yield takeLatest('KICK_MOD_PARTICIPANT', kickModParticipant);
}

export default function* () {
  yield all([
    watchEventSetup(),
    watchBranchChange(),
    watchFetchExistingEvent(),
    watchFetchExternalManagers(),
    watchFetchPastEventConfigurations(),
    watchUpdateExternalManagers(),
    watchRemoveExternalManager(),
    watchCreateEventModSection(),
    watchUpdateEventModSection(),
    watchCreateEventMod(),
    watchUpdateEventMod(),
    watchUpdateEventModsMetadata(),
    watchFetchModParticipants(),
    watchKickModParticipant(),
  ]);
}
