export default shifts =>
  Object.values(shifts)
    .slice(-1)
    .map(x => ({
      [x.order + 1]: {
        ...x,
        order: x.order + 1,
        label: 'Additional Cleanup',
      },
    }));
