import {
  osVersion,
  osName,
  browserName,
  browserVersion,
  mobileVendor,
  mobileModel,
  isMobile,
} from 'react-device-detect';

export const clientMetadata = {
  os: `${osName} ${osVersion}`,
  browser: `${browserName} ${browserVersion}`,
  hardware: isMobile ? `${mobileVendor} ${mobileModel}` : null,
};
