import { range, startCase } from 'lodash';
import { useSelector } from 'react-redux';
import React from 'react';
import computePlanDelta from '../actions/computePlanDelta';
import lores from '../../gameData/lores';
import styles from './Planner.module.scss';

const SKILL_NUMERIC = {
  1: 'Basic',
  2: 'Proficient',
  3: 'Master',
  4: 'Achievement',
};

const renderStatDeltas = (planned, persisted) =>
  Object.keys(planned)
    .filter(stat => planned[stat] !== persisted[stat])
    .map(stat => (
      <div key={stat} className={[styles.plan, styles.stat].join(' ')}>
        <div className={styles.title}>{`Total ${startCase(stat)}`}</div>
        <div className={styles.numeric}>
          {`${persisted[stat]} → ${planned[stat]}`}
        </div>
      </div>
    ));

const renderSkillDeltas = (planned, persisted) => {
  const deltas = [];

  Object.keys(planned).forEach(skill => {
    range(
      persisted[skill].levelAcquired + 1,
      planned[skill].levelAcquired + 1,
    ).forEach(n => deltas.push([skill, n]));

    if (
      planned[skill].achievementIsAcquired &&
      !persisted[skill].achievementIsAcquired
    ) {
      deltas.push([skill, 4]);
    }
  });

  return deltas.sort().map(delta => (
    <div
      key={`${delta[0]}${delta[1]}`}
      className={[styles.plan, styles.skill].join(' ')}
    >
      <div className={styles.title}>{startCase(delta[0])}</div>
      <div className={styles.numeric}>{SKILL_NUMERIC[delta[1]]}</div>
    </div>
  ));
};

const renderLoreDeltas = planned =>
  Object.keys(planned).map(lore => (
    <div key={lore} className={[styles.plan, styles.skill].join(' ')}>
      <div className={styles.title}>{`Lore: ${lores[lore].name}`}</div>
    </div>
  ));

const Container = ({ isEditable }) => {
  const {
    persistedStats,
    persistedSkills,
    persistedLores,
    plannedStats,
    plannedSkills,
    plannedLores,
    persistedBuildUsed,
    plannedBuildUsed,
  } = useSelector(state => ({
    persistedStats: state.character.remotePersistance.stats,
    persistedSkills: state.character.remotePersistance.skills,
    persistedLores: state.character.remotePersistance.lores,
    plannedStats: state.character.stats,
    plannedSkills: state.character.skills.trees,
    plannedLores: state.character.lores,
    persistedBuildUsed: state.character.remotePersistance.buildUsed,
    plannedBuildUsed: state.character.totalBuild.used,
  }));

  const y = computePlanDelta({
    persistedSkills,
    persistedLores,
    plannedStats,
    plannedSkills,
    plannedLores,
  });

  if (isEditable) return null;

  const renders = []
    .concat(renderStatDeltas(y.stats, persistedStats))
    .concat(renderSkillDeltas(y.skills, persistedSkills))
    .concat(renderLoreDeltas(y.lores));

  if (renders.length === 0) return null;

  return (
    <React.Fragment>
      <div className={styles.heading}>Plans</div>
      {renders}
      <div className={styles.footer}>
        {`Total XP: ${persistedBuildUsed} → ${plannedBuildUsed}`}
      </div>
    </React.Fragment>
  );
};

export default React.memo(Container);
