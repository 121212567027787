export const initialState = {
  current: 0,
  persisted: 0,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'SYNC_BUFFER':
      return {
        current: payload.legacyHomeGames,
        persisted: payload.legacyHomeGamesPersisted,
      };
    default:
      return state;
  }
};
