import { fromPairs } from 'lodash';

const computeStatDelta = ({ plannedStats }) =>
  Object.assign(
    fromPairs(
      Object.keys(plannedStats)
        .filter(x => x !== 'deaths')
        .filter(x => x !== 'infection')
        .map(x => [x, plannedStats[x].total]),
    ),
    { infection: plannedStats.infection.total + plannedStats.deaths.count },
  );

const computeLoreDelta = ({ persistedLores, plannedLores }) =>
  fromPairs(
    Object.keys(plannedLores)
      .filter(x => !persistedLores[x] && plannedLores[x])
      .map(x => [x, true]),
  );

const computeSkillDelta = ({ persistedSkills, plannedSkills }) =>
  fromPairs(
    Object.keys(plannedSkills)
      .filter(
        x =>
          plannedSkills[x].levelAcquired > 0 ||
          plannedSkills[x].achievementIsAcquired,
      )
      .filter(
        x =>
          !persistedSkills[x] ||
          plannedSkills[x].levelAcquired > persistedSkills[x].levelAcquired ||
          (plannedSkills[x].achievementIsAcquired &&
            !persistedSkills[x].achievementIsAcquired),
      )
      .map(x => [
        x,
        {
          levelAcquired: plannedSkills[x].levelAcquired,
          achievementIsAcquired: plannedSkills[x].achievementIsAcquired,
        },
      ]),
  );

export default ({
  persistedSkills,
  plannedSkills,
  plannedStats,
  persistedLores,
  plannedLores,
}) => {
  console.log(plannedStats);
  console.log(computeStatDelta({ plannedStats }));
  return {
    skills: computeSkillDelta({ persistedSkills, plannedSkills }),
    stats: computeStatDelta({ plannedStats }),
    lores: computeLoreDelta({ persistedLores, plannedLores }),
  };
};
