import { useSelector, useDispatch } from 'react-redux';
import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Lock from '@material-ui/icons/Lock';
import lineages from '../../gameData/lineages';
import strains from '../../gameData/strains';
import styles from './style.module.scss';
import { isEditable as hasEditPrivilege } from '../../utils/user';

const StrainSelect = () => {
  const dispatch = useDispatch();
  const { strain, approvedForPlay, user } = useSelector(state => ({
    strain: state.character.strain,
    approvedForPlay: state.character.approvedForPlay,
    user: state.user,
  }));

  const isEditable = hasEditPrivilege(user, approvedForPlay);
  const handleStrainChange = event => {
    if (!event.target.value) return;
    dispatch({
      type: 'UPDATE_STRAIN',
      payload: event.target.value,
    });
  };

  const lineageHeader = lineage => {
    const lineageName = lineages[lineage].name;
    const stats = lineages[lineage].stats;
    const lineageStats = [
      stats.body,
      stats.mind,
      stats.resolve,
      stats.infection,
    ].join('/');

    return (
      <div className={styles.lineageHeader} key={lineageName}>
        {`${lineageName} - ${lineageStats}`}
      </div>
    );
  };
  const strainsByLineage = lineage =>
    lineages[lineage].strains.map(strainKey => (
      <MenuItem key={strainKey} value={strainKey} className={styles.item}>
        {strains[strainKey].name}
      </MenuItem>
    ));

  const strainBuilder = () =>
    Object.keys(lineages)
      .filter(x => x !== 'default')
      .map(lineage => [lineageHeader(lineage), strainsByLineage(lineage)])
      .flat();

  return (
    <Select
      className={[styles.root, isEditable ? '' : styles.disabled].join(' ')}
      classes={{
        select: styles.select,
      }}
      value={strain || 'default'}
      onChange={handleStrainChange}
      inputProps={{ disabled: !isEditable }}
      IconComponent={isEditable ? ArrowDropDownIcon : Lock}
      MenuProps={{
        className: styles.menu,
      }}
      disableUnderline
    >
      <MenuItem
        value='default'
        className={[styles.item, styles.disabled].join(' ')}
        disabled
      >
        Select Strain
      </MenuItem>
      {strainBuilder()}
    </Select>
  );
};

export default React.memo(StrainSelect);
