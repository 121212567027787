import PersonAdd from '@material-ui/icons/PersonAdd';
import EventDropdownIcon from '@material-ui/icons/ArrowDropDown';
import CreateEventIcon from '@material-ui/icons/AddToPhotos';
import PrintIcon from '@material-ui/icons/Print';
import GuideIcon from '@material-ui/icons/SupervisedUserCircle';
import ActiveSessionsList from '@material-ui/icons/List';
import MembershipsIcon from '@material-ui/icons/ConfirmationNumber';
import FinancialIcon from '@material-ui/icons/MonetizationOn';
import TransferIcon from '@material-ui/icons/SwapHoriz';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkout from '../Events/Checkout/Page';
import history from '../history';
import EventList from './EventList';
import Impersonator from './Impersonator';
import UnImpersonator from './UnImpersonator';
import UserMetadata from './UserMetadata';
import styles from './Sidebar.module.scss';
import { hasValidToken } from '../utils/token';
import { isPrivileged as hasPrivilege } from '../utils/user';
import { isOwnerOrExternal } from '../utils/events';

const Menu = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const { authConfig, isOpen, user, searchResult, event, eventsRun, popper } =
    useSelector(state => ({
      authConfig: state.user.session,
      isOpen: state.ui.navigationIsOpen,
      user: state.user,
      searchResult: state.ui.search,
      event: state.eventAdministration,
      eventsRun: state.events.eventsRun,
      popper: state.ui.character.popperOpened,
    }));
  const [timeoutExceeded, setTimeoutExceeded] = useState(false);
  const eventListPopperName = 'eventList';
  const isValidToken = hasValidToken(authConfig);
  const isPrivileged = hasPrivilege(user);

  const isEventRunner =
    event &&
    event.eventInfo &&
    isOwnerOrExternal({
      event: {
        eventID: event.eventInfo.id,
        branchID: event.eventInfo.branch ? event.eventInfo.branch.id : null,
      },
      eventsRun,
      user,
    });

  const isCheckout = history.location.pathname.match(/checkout\/(\d+)$/);
  const isAdminPrint = history.location.pathname.match(/admin_print\//);

  const openLoginModal = () => {
    dispatch({ type: 'TOGGLE_POPPER', payload: 'loginModal' });
    dispatch({ type: 'LOAD_MODAL', payload: { username: '', password: '' } });
  };
  const openNewPlayerModal = () => {
    dispatch({ type: 'TOGGLE_POPPER', payload: 'createUserModal' });
    dispatch({
      type: 'LOAD_MODAL',
      payload: { firstName: '', lastName: '', emailAddress: '', branchId: 1 },
    });
  };
  const impersonate = userID =>
    dispatch({
      type: 'IMPERSONATE_USER',
      payload: {
        userID,
        isImpersonating: true,
      },
    });
  const unimpersonate = () => {
    history.push('/');
    dispatch({
      type: 'IMPERSONATE_USER',
      payload: {
        userID: user.id,
        isImpersonating: false,
      },
    });
    dispatch({ type: 'CLEANUP_SEARCH_RESULT' });
  };
  const handleEventListToggle = () =>
    dispatch({
      type: 'TOGGLE_POPPER',
      payload: eventListPopperName,
    });

  const currentPath = paths =>
    paths.includes(history.location.pathname) ? styles.selected : '';
  const deleteSession = () => dispatch({ type: 'DELETE_SESSION' });

  const renderMenuElement = ({
    title,
    icon,
    content,
    paths,
    condition,
    onClick,
    anchor,
  }) => {
    if (!condition()) return null;

    const resolvedContent = content || icon || title;
    const classNames = [
      styles.cell,
      icon || content ? styles.iconOnly : null,
      currentPath(paths),
    ].join(' ');

    if (anchor)
      return (
        <a href={anchor} className={classNames}>
          {resolvedContent}
        </a>
      );
    return (
      <div className={classNames} onClick={onClick}>
        {resolvedContent}
      </div>
    );
  };
  // condition() ? (
  //   <div
  //     className={[
  //       styles.cell,
  //       icon || content ? styles.iconOnly : null,
  //       currentPath(paths),
  //     ].join(' ')}
  //     onClick={onClick}
  //   >
  //     {content || icon || title}
  //   </div>
  // ) : null;

  const userMetadata = () =>
    isValidToken ? <UserMetadata user={user} /> : null;
  const impersonateeMetadata = () =>
    isValidToken && user.impersonatee.id ? (
      <UserMetadata user={user.impersonatee} impersonating />
    ) : null;

  useEffect(() => {
    if (!isPrivileged) return;
    dispatch({ type: 'FETCH_EVENTS_RUN' });
  }, [dispatch, isPrivileged]);

  useEffect(() => {
    if (isOpen) setTimeoutExceeded(true);
  }, [isOpen, setTimeoutExceeded]);

  useEffect(() => {
    const handleListenedClick = evt => {
      if (
        ref.current.contains(evt.target) ||
        (evt.target &&
          evt.target.classList.value.match(
            /(sidebarIgnoreable|EventList|hideousLink)/,
          )) ||
        (evt.target &&
          evt.target.parentElement &&
          evt.target.parentElement.classList.value.match(
            /(sidebarIgnoreable|EventList|MuiSvgIcon)/,
          ))
      )
        return;

      dispatch({ type: 'TOGGLE_MENU_CLOSED' });
      if (popper === eventListPopperName && isOpen)
        dispatch({ type: 'TOGGLE_POPPER' });
    };

    document.addEventListener('mousedown', handleListenedClick);

    return () => document.removeEventListener('mousedown', handleListenedClick);
  }, [dispatch, popper, eventListPopperName, isOpen]);

  return (
    <div
      className={[
        styles.sidebar,
        isAdminPrint ? styles.hideOnPrint : '',
        isOpen ? styles.opened : styles.holstered,
        timeoutExceeded ? '' : styles.animationHidden,
      ].join(' ')}
      ref={ref}
    >
      <div className={styles.sharedRow}>
        {renderMenuElement({
          title: 'Login',
          paths: ['/login'],
          condition: () => !isValidToken,
          onClick: openLoginModal,
        })}
      </div>
      {userMetadata()}
      <div className={styles.sharedRow}>
        {renderMenuElement({
          title: 'Characters',
          paths: ['/', '/characters', '/print'],
          condition: () => true,
          anchor: '/',
        })}
        {renderMenuElement({
          icon: <PrintIcon className={styles.icon} />,
          paths: ['/print'],
          condition: () => true,
          anchor: '/print',
        })}
        {renderMenuElement({
          icon: <PersonAdd className={styles.icon} />,
          paths: [],
          condition: () => isValidToken && isPrivileged,
          onClick: openNewPlayerModal,
        })}
      </div>
      {isCheckout && isEventRunner ? (
        <Checkout event={event} impersonate={impersonate} />
      ) : ['/', '/characters'].includes(history.location.pathname) ? (
        <React.Fragment>
          <Impersonator
            impersonate={impersonate}
            visible={isValidToken && isPrivileged}
            searchResult={searchResult}
          />
          {impersonateeMetadata()}
          <UnImpersonator
            unimpersonate={unimpersonate}
            user={user}
            visible={isValidToken && isPrivileged}
          />
        </React.Fragment>
      ) : null}
      <div className={styles.sharedRow}>
        {renderMenuElement({
          title: 'Events',
          paths: ['/events', '/create_event'],
          condition: () => true,
          anchor: '/events',
        })}
        {renderMenuElement({
          icon: <CreateEventIcon className={styles.icon} />,
          paths: ['/create_event'],
          condition: () => isValidToken && isPrivileged,
          anchor: '/create_event',
        })}
        {renderMenuElement({
          content: (
            <EventList
              popper={popper}
              events={eventsRun}
              popperName={eventListPopperName}
              passClick={handleEventListToggle}
            >
              <EventDropdownIcon className={styles.icon} />
            </EventList>
          ),
          paths: [],
          condition: () => isValidToken && isPrivileged,
        })}
      </div>
      <div className={styles.sharedRow}>
        {renderMenuElement({
          title: 'Items DB',
          paths: ['/items'],
          condition: () => true,
          anchor: '/items',
        })}
      </div>
      <div className={styles.sharedRow}>
        {renderMenuElement({
          title: 'Net',
          paths: [
            '/branches/statistics',
            '/branches',
            '/branches/transfers',
            '/branches/financials',
            '/branches/memberships',
          ],
          condition: () => isValidToken && isPrivileged,
          anchor: '/branches/statistics',
        })}
        {renderMenuElement({
          icon: <GuideIcon className={styles.icon} />,
          paths: ['/branches'],
          condition: () => isValidToken && isPrivileged,
          anchor: '/branches',
        })}
        {renderMenuElement({
          icon: <MembershipsIcon className={styles.icon} />,
          paths: ['/branches/memberships'],
          condition: () => isValidToken && isPrivileged,
          anchor: '/branches/memberships',
        })}
        {renderMenuElement({
          icon: <FinancialIcon className={styles.icon} />,
          paths: ['/branches/financials'],
          condition: () => isValidToken && isPrivileged,
          anchor: '/branches/financials',
        })}
        {renderMenuElement({
          icon: <TransferIcon className={styles.icon} />,
          paths: ['/branches/transfers'],
          condition: () => isValidToken && isPrivileged,
          anchor: '/branches/transfers',
        })}
      </div>
      <div className={styles.sharedRow}>
        {renderMenuElement({
          title: 'Logout',
          paths: ['/logout', '/active_sessions'],
          condition: () => isValidToken,
          onClick: () => deleteSession() && history.push('/'),
        })}
        {renderMenuElement({
          icon: <ActiveSessionsList className={styles.icon} />,
          paths: ['/active_sessions'],
          condition: () => isValidToken,
          anchor: '/active_sessions',
        })}
      </div>
    </div>
  );
};

export default React.memo(Menu);
