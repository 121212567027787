import React, { useEffect, useState } from 'react';
import { fromPairs } from 'lodash';
import { useDispatch } from 'react-redux';
import TextArea from '../../Shared/FormComponents/TextArea';
import GuideIcon from '@material-ui/icons/SupervisedUserCircle';
import styles from './Logs.module.scss';

export default ({ eventID, eventAttendees }) => {
  const dispatch = useDispatch();
  const [logs, setLogs] = useState({});

  const updateLogs = (id, val) =>
    setLogs({
      ...logs,
      [id]: val,
    });

  const isDirtyLog = id => (eventAttendees[id].logs || '') !== logs[id];

  const updateUpstream = (id, name, playerID) =>
    isDirtyLog(id) &&
    dispatch({
      type: 'UPDATE_ATTENDEE_LOGS',
      payload: {
        eventID,
        eventAttendeeID: id,
        value: logs[id],
        name,
        playerID,
      },
    });

  const renderAttendee = attendee => (
    <div className={styles.row} key={attendee.attendeeID}>
      <div className={styles.persona}>
        <div>
          {`#${attendee.playerID}`}
          {attendee.role === 'guide' && (
            <GuideIcon className={styles.guideIcon} />
          )}
        </div>
        <div>{attendee.name}</div>
        <div className={styles.characters}>
          {attendee.characters
            .sort((a, b) => a.id - b.id)
            .map(x => (
              <div key={x.id}>{x.name}</div>
            ))}
        </div>
      </div>
      <div className={styles.logs}>
        <TextArea
          content={logs[attendee.attendeeID]}
          placeholder='Use this space to record logs, inventory, etc...'
          handleUpdate={evt =>
            updateLogs(attendee.attendeeID, evt.target.value)
          }
          handleBlur={() =>
            updateUpstream(
              attendee.attendeeID,
              attendee.name,
              attendee.playerID,
            )
          }
        />
      </div>
    </div>
  );

  useEffect(() => {
    setLogs(
      fromPairs(
        Object.keys(eventAttendees).map(x => [x, eventAttendees[x].logs || '']),
      ),
    );
  }, [eventAttendees]);

  return (
    <div className={styles.container}>
      <div className={styles.obsolete}>
        Event Logs is obsolete. Please use
        <a href={`/admin_transactions/${eventID}`} className={styles.link}>
          Event Logistics
        </a>{' '}
        instead.
      </div>
      {Object.keys(eventAttendees)
        .sort((a, b) => eventAttendees[a].playerID - eventAttendees[b].playerID)
        .map(x => renderAttendee(eventAttendees[x]))}
    </div>
  );
};
