export const initialState = {
  isFetchingCharacter: false,
  upstreamSaveState: 'idle',
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'FETCH_BEGIN':
      return { ...state, isFetchingCharacter: true };
    case 'FETCH_COMPLETE':
      return { ...state, isFetchingCharacter: false };
    case 'SAVE_UPSTREAM_BEGIN':
      return { ...state, upstreamSaveState: 'inProgress' };
    case 'SAVE_UPSTREAM_SUCCEEDED':
      return { ...state, upstreamSaveState: 'succeeded' };
    case 'SAVE_UPSTREAM_FAILED':
      return { ...state, upstreamSaveState: 'failed' };
    case 'DELETE_SESSION':
    case 'LOGOUT_ALL_SESSIONS':
    case 'SWITCH_CHARACTER':
    case 'IMPERSONATE_USER':
    case 'NUKE_DUE_TO_TOKEN_EXPIRATION':
    case 'CLEANUP_PREVIOUS_SESSION':
      return { ...state, upstreamSaveState: 'idle' };
    default:
      return state;
  }
};
