import React, { useEffect, useRef, useState } from 'react';
import Players from '@material-ui/icons/People';
import AssistedCheckin from '@material-ui/icons/WhereToVote';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import { groupBy, toPairs } from 'lodash';
import {
  compareDesc,
  fromUnixTime,
  format,
  getYear,
  getMonth,
  getUnixTime,
  parseISO,
} from 'date-fns';
import styles from './EventList.module.scss';

export default ({ popper, popperName, events, children, passClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? `popper-event-lists` : undefined;
  const overlayRef = useRef(null);
  const renderEvent = x => (
    <div className={styles.eventRow} key={x.id}>
      <a className={styles.link} href={`/admin_event/${x.id}`}>
        <Players fontSize='small' />
      </a>
      <a
        className={[styles.link, styles.edit].join(' ')}
        href={`/update_event/${x.id}`}
      >
        <div className={styles.text}>{x.name}</div>
      </a>
      <a className={styles.link} href={`/assisted_checkin/${x.id}`}>
        <AssistedCheckin fontSize='small' />
      </a>
    </div>
  );

  const renderGroupedEvents = (group, groupedEvents) => (
    <div className={styles.monthGroup} key={group}>
      <div className={styles.groupHeader}>
        {format(fromUnixTime(group), 'yyyy MMM')}
      </div>
      <div className={styles.groupContent}>
        {groupedEvents
          .sort((a, b) =>
            compareDesc(parseISO(a.starts_at), parseISO(b.starts_at)),
          )
          .map(x => renderEvent(x))}
      </div>
    </div>
  );

  const renderEvents = () =>
    events && events.length > 0 ? (
      toPairs(
        groupBy(events, x =>
          getUnixTime(
            new Date(
              getYear(parseISO(x.starts_at)),
              getMonth(parseISO(x.starts_at)),
              1,
            ),
          ),
        ),
      )
        .sort((a, b) => b[0] - a[0])
        .map(x => renderGroupedEvents(x[0], x[1]))
    ) : (
      <div className={styles.noEvents}>No Past Events</div>
    );

  useEffect(() => {
    setAnchorEl(popper === popperName ? overlayRef.current : null);
  }, [popper, popperName, overlayRef, setAnchorEl]);

  return (
    <div ref={overlayRef} onClick={passClick}>
      {children}
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={styles.popper}
        placement='bottom'
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={0}>
            <div className={styles.container}>
              <div className={styles.placebo}>Close</div>
              <div className={styles.contents}>{renderEvents()}</div>
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  );
};
