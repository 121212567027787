import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ActiveSessions from './ActiveSessions/Page';
import AdminPrint from './AdminPrint/Page';
import AdminEvent from './Events/Administration/Page';
import Branches from './Branches/Page';
import Staffs from './Staffs/Page';
import BackSheet from './AdminPrint/BackSheet';
import FrontSheet from './AdminPrint/Character';
import Characters from './Character/Page';
import EventList from './Events/Page';
import EventSetup from './Events/Setup/Page';
import EventCheckin from './Events/Checkin/Page';
import EventStatistics from './Events/Statistics/Page';
import ItemSearch from './Items/ItemSearch';
import Locations from './Branches/Locations';
import AssistedCheckin from './Events/Checkin/Assisted';
import IWantToCrashThisApp from './IWantToCrashThisApp';
import Print from './Print/Container';

export default () => {
  return (
    <Switch className='builder'>
      <Route exact path={['/', '/characters']} component={Characters} />
      <Route path='/staffs' component={Staffs} />
      <Route path='/events' component={EventList} />
      <Route path='/print' component={Print} />
      <Route path='/admin_print' component={AdminPrint} />
      <Route path='/admin_event' component={AdminEvent} />
      <Route path='/admin_logs' component={AdminEvent} />
      <Route path='/admin_shift' component={AdminEvent} />
      <Route path='/admin_transactions' component={AdminEvent} />
      <Route path='/event_statistics' component={EventStatistics} />
      <Route path='/create_event' component={EventSetup} />
      <Route path='/update_event' component={EventSetup} />
      <Route path='/manage_locations' component={Locations} />
      <Route path='/checkin' component={EventCheckin} />
      <Route path='/assisted_checkin' component={AssistedCheckin} />
      <Route
        path='/items'
        component={() => <ItemSearch includeDescription />}
      />

      <Route
        path='/backsheet'
        component={() => <BackSheet isPrintable isBackPrintable isSample />}
      />
      <Route
        path='/frontsheet'
        component={() => (
          <FrontSheet
            event={{
              location: {},
            }}
            shifts={[]}
            isPrintable
            isBlankSheet
            attendeeCharacter={{
              character: {
                skills_id: [],
                strain_id: 0,
                lores_id: [],
                build_earned: 0,
                total_home_games: 0,
              },
              ticket: {},
              kind: 'home_game',
            }}
            user={{
              memberships: [],
              branch: {},
            }}
          />
        )}
      />
      <Route path='/branches' component={Branches} />
      <Route path='/checkout' component={() => <Characters isCheckout />} />
      <Route path='/active_sessions' component={ActiveSessions} />
      <Route
        path='/i_want_to_crash_this_app'
        component={() => <IWantToCrashThisApp crash />}
      />
    </Switch>
  );
};
