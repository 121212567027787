import React from 'react';
import Checkin from './Page';

export default () => {
  return (
    <div>
      <Checkin isAssisted />
    </div>
  );
};
