import React, { useState, useEffect } from 'react';
import Popper from '@material-ui/core/Popper';
import Visible from '@material-ui/icons/Visibility';
import Hidden from '@material-ui/icons/VisibilityOff';
import IconRaster from '../../../../Shared/Rasters/IconRaster';
import Close from '@material-ui/icons/Close';
import { upperFirst } from 'lodash';
import styles from './SkillGrid.module.scss';
import './SkillGridOverlay.scss';

export default ({
  category,
  data,
  passPopperToggle,
  passSkillVisibilityToggle,
  placement,
  openState,
  toggleState,
  popper,
  isEditable,
  persistedBuild,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? `popper-${category}` : undefined;
  const isVisible = toggleState === false || toggleState === undefined;
  const isFiltered = !isVisible;
  const overlayRef = React.createRef();

  const handlePopperToggle = event => passPopperToggle(category);
  const getQuadClassName = () =>
    [
      `${styles.skillgrid}`,
      `${styles[category]}`,
      isVisible ? '' : `${styles.muted}`,
      anchorEl === null ? '' : `${styles.overlayActive}`,
    ].join(' ');
  const getOverlayClassName = () =>
    ['skillgrid-overlay', category, isVisible ? '' : 'muted'].join(' ');
  const drawSkillTable = () => {
    const skillSummaryRow = info =>
      [1, 2, 3, 4].map(tier => (
        <td
          key={tier}
          className={`xp numeric ${info === 'build' ? 'bold' : ''} t${tier}`}
        >
          {data[tier][info]}
        </td>
      ));

    const getVisibleClassName = () => (isVisible ? '' : 'hidden');
    const getHiddenClassName = () => (isFiltered ? '' : 'hidden');
    const handleSkillVisibilityToggle = () => {
      passSkillVisibilityToggle(category);
    };

    return (
      <div className='table-container'>
        <table className='summary-table'>
          <tbody>
            <tr>
              <td>{upperFirst(category)}</td>
              {skillSummaryRow('count')}
            </tr>
            <tr>
              <td className='bold'>XP</td>
              {skillSummaryRow('build')}
            </tr>
            <tr>
              <td>Next Cost</td>
              {skillSummaryRow('nextCost')}
            </tr>
          </tbody>
        </table>
        <Visible
          className={getVisibleClassName()}
          onClick={handleSkillVisibilityToggle}
        />
        <Hidden
          className={getHiddenClassName()}
          onClick={handleSkillVisibilityToggle}
        />
      </div>
    );
  };
  const getCategorySymbol = () => {
    if (anchorEl) {
      return <Close fontSize='small' />;
    }

    const styleOverrides = {
      wasteland: {},
      combat: { marginLeft: '-2px' },
      civilized: { marginLeft: '-2px' },
      anomaly: {},
    };

    return (
      <IconRaster source={category} styleOverride={styleOverrides[category]} />
    );
  };
  const wrapTotalBuild = () => {
    if (isEditable || (!isEditable && data.totalBuild === persistedBuild))
      return <div className={styles.bold}>{data.totalBuild}</div>;

    return (
      <div className={styles.bold} style={{ marginTop: '-2px' }}>
        {`${persistedBuild} → ${data.totalBuild}`}
      </div>
    );
  };
  useEffect(() => {
    setAnchorEl(popper === category ? overlayRef.current : null);
  }, [popper, overlayRef, setAnchorEl, category]);

  return (
    <React.Fragment>
      <div
        className={getQuadClassName()}
        onClick={handlePopperToggle}
        ref={overlayRef}
      >
        <div className={styles.symbol}>{getCategorySymbol()}</div>
        {wrapTotalBuild()}
      </div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        className={getOverlayClassName()}
      >
        {drawSkillTable()}
      </Popper>
    </React.Fragment>
  );
};
