import React from 'react';
import { groupBy, range, startCase, fill } from 'lodash';
import uuid from 'uuid';
import SkillRow from './SkillRow';

import styles from './Character.module.scss';

export default ({ category, structuredSkill, isBlankSheet }) => {
  const tierCount = { 1: 0, 2: 0, 3: 0, 4: 0 };
  const renderable = structuredSkill.byKeysSortedTier;
  const renderFillerRow = () => (
    <SkillRow
      key={uuid.v1()}
      level={{ 1: -1, 2: 0, 3: 0 }}
      isInnate={false}
      hasAchievement={false}
      name=''
      achievementName=''
      maxTier={3}
    />
  );
  const renderSkillRow = x => {
    const byTier = groupBy(x, 'tier');
    const lead = x[0];

    range(1, 5).forEach(i => byTier[i] && (tierCount[i.toString()] += 1));
    return (
      <SkillRow
        key={lead.name}
        level={{
          1: byTier[1] ? tierCount['1'] : 0,
          2: byTier[2] ? tierCount['2'] : 0,
          3: byTier[3] ? tierCount['3'] : 0,
        }}
        isInnate={lead.isInnate}
        hasAchievement={byTier[4]}
        skillKey={lead.key}
        name={lead.name}
        achievementName={lead.t4Name}
        maxTier={lead.maxTier}
      />
    );
  };
  const renderSkillRows = () =>
    renderable
      .concat(fill(Array(Math.max(0, 4 - renderable.length)), null))
      .map(x => (x ? renderSkillRow(x) : renderFillerRow()));
  const renderCategoryTotal = () => (
    <React.Fragment>
      <td className={[styles.skillColumn, styles.fixedSkillLevel].join(' ')}>
        {isBlankSheet ? '' : structuredSkill.tierSum['1']}
      </td>
      <td className={[styles.skillColumn, styles.fixedSkillLevel].join(' ')}>
        {isBlankSheet ? '' : structuredSkill.tierSum['2']}
      </td>
      <td className={[styles.skillColumn, styles.fixedSkillLevel].join(' ')}>
        {isBlankSheet ? '' : structuredSkill.tierSum['3']}
      </td>
      <td className={[styles.skillColumn, styles.fixedSkillLevel].join(' ')}>
        {isBlankSheet ? '' : structuredSkill.tierSum['4']}
      </td>
      <td className={[styles.skillColumn, styles.total].join(' ')}>
        {isBlankSheet ? '' : structuredSkill.categorySum}
      </td>
    </React.Fragment>
  );

  return (
    <div className={styles.skills}>
      {category === 'combat' ? (
        <div className={styles.asterisk}>
          Note: All characters have access to basic weapon skills
          <br />
          Curly bracket indicates Skill use at that tier costs Resolve
        </div>
      ) : null}
      <table className={styles.table}>
        <tbody>
          {category === 'combat' ? (
            <tr>
              <td className={[styles.skillColumn].join(' ')}>Skills</td>
              <td
                className={[styles.skillColumn, styles.fixedSkillLevel].join(
                  ' ',
                )}
              >
                B
              </td>
              <td
                className={[styles.skillColumn, styles.fixedSkillLevel].join(
                  ' ',
                )}
              >
                P
              </td>
              <td
                className={[styles.skillColumn, styles.fixedSkillLevel].join(
                  ' ',
                )}
              >
                M
              </td>
              <td
                className={[styles.skillColumn, styles.fixedSkillLevel].join(
                  ' ',
                )}
              >
                A
              </td>
              <td
                className={[styles.skillColumn, styles.rotated].join(' ')}
                rowSpan={64}
              >
                <div className={styles.wrapper}>{`${startCase(category)}`}</div>
              </td>
            </tr>
          ) : (
            <tr>
              <td className={styles.divider} colSpan={5} />
              <td
                className={[styles.skillColumn, styles.rotated].join(' ')}
                rowSpan={64}
              >
                <div className={styles.wrapper}>{`${startCase(category)}`}</div>
              </td>
            </tr>
          )}
          {renderSkillRows()}
        </tbody>
        <tfoot>
          <tr>
            <td className={[styles.skillColumn].join(' ')}>
              {`${startCase(category)} Subtotal`}
            </td>

            {renderCategoryTotal()}
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
