import React from 'react';
import { useSelector } from 'react-redux';
import { max } from 'lodash';
import Fracture from './Fracture';
import { compareAsc, parseISO } from 'date-fns';

const Fractures = ({ divider }) => {
  const { fractures, remoteId } = useSelector(state => ({
    fractures: state.character.fractures,
    remoteId: state.character.remoteID
  }));

  const maxId = max(fractures.map(x => x.id)) || -1;
  const sortFunctor = React.useCallback((a, b) => {
    if (a.removed_at === null && b.removed_at === null) {
      return compareAsc(parseISO(a.created_at), parseISO(b.created_at))
    } else if (a.removed_at !== null && b.removed_at !== null) {
      a.name.localeCompare(b.name)
    } else {
      if (a.removed_at === null) return -1;
      return 1;
    }
  }, []);

  if (!remoteId) return null;

  return (
    <div>
      {divider}
      <Fracture fracture={{ id: null }} key='-1' maxId={maxId} />
      {fractures
        .sort(sortFunctor)
        .map(fracture => (
          <Fracture fracture={fracture} key={fracture.id} />
        ))}
    </div>
  );
};

export default Fractures;
