import { fromPairs, omit } from 'lodash';
import categorizeEvents from './actions/categorizeEvents';

export const initialState = {
  data: [],
  eventsCheckedIn: {},
  eventsRun: [],
  isFetching: false,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'FETCH_EVENTS_SUCCEEDED':
      return {
        ...state,
        data: categorizeEvents(payload),
        isFetching: false,
      };
    case 'FETCH_EVENTS_FAILED':
      return {
        ...state,
        isFetching: false,
      };
    case 'FETCH_EVENTS':
      return {
        ...state,
        isFetching: true,
      };
    case 'FETCH_EVENTS_RUN_SUCCEEDED':
      return {
        ...state,
        eventsRun: payload,
      };
    case 'FETCH_EVENTS_CHECKED_IN_SUCCEEDED':
      return {
        ...state,
        eventsCheckedIn: fromPairs(
          payload.map(x => [x.event_id, omit(x, 'event_id')]),
        ),
      };
    case 'UPDATE_EVENT_CHECKIN_STATUS':
      return {
        ...state,
        eventsCheckedIn: {
          ...state.eventsCheckedIn,
          [payload.eventID]: payload.isCheckedIn,
        },
      };
    default:
      return state;
  }
};
