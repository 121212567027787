import localLores from '../../gameData/lores';
import remoteLores from '../../gameData/remoteLores';
import { fromPairs } from 'lodash';
import { initialState as initialLoreState } from '../../Character/reducers/lores';

export const toLocalLores = remoteMultiplex => ({
  ...initialLoreState,
  ...fromPairs(
    Object.values(remoteMultiplex)
      .filter(x => remoteLores[x])
      .map(x => [remoteLores[x], true]),
  ),
});

export const toRemoteLores = lores =>
  Object.keys(lores)
    .filter(x => lores[x])
    .map(x => localLores[x].remoteID);
