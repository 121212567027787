const linearCalc = x => 10 * x;

const deciCalc = input => {
  const influx = y => {
    if (y > 0) return y - 10 < 0 ? y : 10;
    return 0;
  };

  let x = input;
  let totalCost = influx(x);
  x -= 10;
  totalCost += influx(x);
  x -= 10;
  totalCost += influx(x) * 3;
  x -= 10;
  totalCost += influx(x) * 5;
  x -= 10;
  totalCost += influx(x) * 7;
  x -= 10;
  totalCost += influx(x) * 9;
  x -= 10;
  if (x > 0) totalCost += x * 10;

  return totalCost;
};

export const calcStatBuild = (stat, acquired) => {
  switch (stat) {
    case 'body':
    case 'mind':
      return deciCalc(acquired);
    case 'resolve':
    case 'infection':
      return linearCalc(acquired);
    default:
      return 0;
  }
};

export const calcStatControl = (innate, acquired, limit, remoteValue) => ({
  isDecrementable: acquired > 0 && innate + acquired > remoteValue,
  isIncrementable: limit ? innate + acquired < limit : true,
});

export const calcStatControlDual = (
  innate,
  acquired,
  reduction,
  limit,
  remoteValue,
) => ({
  main: {
    isDecrementable:
      acquired > 0 && innate + acquired - reduction > remoteValue,
    isIncrementable: innate + acquired - reduction < limit,
  },
  reduction: {
    isDecrementable: reduction > 0 && innate + acquired - reduction < limit,
    isIncrementable: innate + acquired - reduction > 0,
  },
});
