import React from 'react';
import styles from './LoreLinear.module.scss';
import { range } from 'lodash';

const EDUCATION = {
  0: 'No Education',
  1: 'Basic Education',
  2: 'Proficient Education',
  3: 'Master Education',
};

const DIRECTIONAL_DISABLER = {
  down: {
    0: true, // disable trump button if so dumb
  },
  up: {
    3: true, // disable obama button if so smart
  },
};

const Container = ({ current, max, educationLevel, passEducationChange }) => {
  const isErrorClassName = () => (current > max ? styles.error : '');
  const getControlClassNames = direction => {
    return [
      styles.controls,
      styles[direction],
      DIRECTIONAL_DISABLER[direction][educationLevel] ? styles.disabled : '',
    ].join(' ');
  };
  const getControlComponent = direction => (
    <div
      className={getControlClassNames(direction)}
      onClick={() => passEducationChange(direction === 'up' ? 1 : -1)}
    >
      <div className={styles.text}>{direction === 'down' ? '-' : '+'}</div>
    </div>
  );
  const getCurrentEducationLevel = () => (
    <div className={styles.educationLevel}>
      <div className={styles.text}>{EDUCATION[educationLevel]}</div>
    </div>
  );

  const getNumericalIndicator = () => (
    <div className={[styles.numeric, isErrorClassName()].join(' ')}>
      <div className={styles.text}>
        {`${current} / ${max}`}
        <span className={styles.optional}>{` Lores`}</span>
      </div>
    </div>
  );
  const availableSlots = () => {
    const isUsed = x => (x <= current ? styles.used : '');
    const isAvailable = x => (x <= max && x > current ? styles.available : '');
    const isError = x =>
      current > max && x <= current && x > max ? styles.error : '';

    return range(1, 10, 1).map(x => {
      return (
        <div
          key={x}
          className={[
            styles.loreMarker,
            isUsed(x),
            isAvailable(x),
            isError(x),
          ].join(' ')}
        ></div>
      );
    });
  };

  return (
    <div className={styles.container}>
      {getControlComponent('down')}
      {getCurrentEducationLevel()}
      {getControlComponent('up')}
      {getNumericalIndicator()}
      {availableSlots()}
    </div>
  );
};

export default Container;
