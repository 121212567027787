import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce, fromPairs, groupBy } from 'lodash';
import { compareAsc, formatDistanceToNow, parseISO } from 'date-fns';
import useInterval from '@use-it/interval';
import TransactionGroup from './TransactionGroup';
import history from '../../history';
import styles from './ItemTransactionAudit.module.scss';

const REFRESH_RATE_MS = 10000;

export default ({
  eventId,
  characterId,
  isPrivileged,
  isPostie,
  userScoping,
}) => {
  const [wildcardFilter, setWildcardFilter] = useState('');
  const [idFilter, setIdFilter] = useState('');
  const [playerIdFilter, setPlayerIdFilter] = useState('');
  const [lastUpdateTimestamp, setLastUpdateTimeStamp] = useState(Date.now());
  const [deltaUpdate, setDeltaUpdate] = useState('Data is up-to-date.');
  const [dirtyLegit, setDirtyLegit] = useState({});
  const [dirtyDescriptions, setDirtyDescriptions] = useState({});
  const auditUrl = history.location.pathname.match(/audit\/(\d+)/);
  const auditId = auditUrl ? auditUrl[1] : '';

  const dispatch = useDispatch();
  const { eventTransactions } = useSelector(state => ({
    eventTransactions: state.eventAdministration.eventTransactions,
  }));

  const rootTransactions = Object.keys(eventTransactions)
    .filter(txnId => eventTransactions[txnId].parent_transaction_id === null)
    .map(txnId => eventTransactions[txnId]);

  const leafTransactions = groupBy(
    Object.keys(eventTransactions)
      .filter(txnId => eventTransactions[txnId].parent_transaction_id !== null)
      .map(txnId => eventTransactions[txnId]),
    x => x.parent_transaction_id,
  );

  const matchWildcardFilter = useCallback(
    txn => {
      if (wildcardFilter.length < 3) return true;

      return txn.some(
        x =>
          x.item.name.toLowerCase().match(wildcardFilter) ||
          x.character.name.toLowerCase().match(wildcardFilter) ||
          x.character.user.full_name.toLowerCase().match(wildcardFilter),
      );
    },
    [wildcardFilter],
  );

  const matchTransactionIdFilter = useCallback(
    txn => {
      if (idFilter.trim().length === 0) return true;

      return txn.some(x => x.id === parseInt(idFilter, 10));
    },
    [idFilter],
  );

  const matchPlayerIdFilter = useCallback(
    txn => {
      if (playerIdFilter.trim().length === 0) return true;

      return txn.some(
        x => x.character.user.id === parseInt(playerIdFilter, 10),
      );
    },
    [playerIdFilter],
  );

  const filteredRootTransactions = rootTransactions.filter(txn => {
    const concatTxn = leafTransactions[txn.id]
      ? leafTransactions[txn.id].concat(txn)
      : [txn];

    return (
      matchWildcardFilter(concatTxn) &&
      matchPlayerIdFilter(concatTxn) &&
      matchTransactionIdFilter(concatTxn)
    );
  });

  const isFilterActive =
    wildcardFilter.trim().length >= 3 ||
    idFilter.trim().length > 0 ||
    playerIdFilter.trim().length > 0;

  const fetchData = useCallback(() => {
    setLastUpdateTimeStamp(Date.now);
    setDeltaUpdate('Data is up-to-date.');
    dispatch({
      type: 'FETCH_EVENT_TRANSACTIONS',
      payload: {
        id: eventId || characterId,
        userScoping,
      },
    });
  }, [eventId, characterId, setLastUpdateTimeStamp]);

  const updateDescription = useCallback(
    debounce(({ transactionId, eventId, value }) => {
      dispatch({
        type: 'UPDATE_TRANSACTION_DESCRIPTION',
        payload: {
          transactionId,
          value,
          eventId,
        },
      });
    }, 1000),
    [],
  );

  const onLegitChange = useCallback(({ transactionId, value }) => {
    setDirtyLegit({
      [transactionId]: value,
    });
    dispatch({
      type: 'UPDATE_TRANSACTION_LEGITIMACY',
      payload: {
        transactionId,
        value,
      },
    });
  }, []);

  const onDescriptionChange = useCallback(
    ({ transactionId, eventId, value }) => {
      setDirtyDescriptions({ [transactionId]: value });
      updateDescription({ transactionId, eventId, value });
    },
    [],
  );

  const txnLegit = useMemo(() => {
    return fromPairs(rootTransactions.map(x => [x.id, x.status === 'legit']));
  }, [rootTransactions]);

  const txnDescriptions = useMemo(() => {
    return fromPairs(rootTransactions.map(x => [x.id, x.description]));
  }, [rootTransactions]);

  useEffect(() => {
    if (!(eventId || characterId)) return;

    fetchData();
  }, [eventId, characterId]);

  useEffect(() => {
    setIdFilter(auditId);
  }, []);

  useInterval(() => {
    const diff = Date.now() - lastUpdateTimestamp;

    if (diff < REFRESH_RATE_MS) return;
    const distance = formatDistanceToNow(lastUpdateTimestamp, {
      addSuffix: true,
    });
    setDeltaUpdate(
      <div className={styles.clickable} onClick={fetchData}>
        {`Updated ${distance}. Click to refresh.`}
      </div>,
    );
  }, REFRESH_RATE_MS);

  // if (!isPrivileged) return null;
  if (!(eventId || characterId)) return null;

  return (
    <div className={styles.container}>
      <div className={styles.transactionsFilter}>
        <div className={styles.wildcardFilter}>
          <input
            type='text'
            placeholder='Filter by anything...'
            className={styles.inputFilter}
            value={wildcardFilter}
            onChange={evt => setWildcardFilter(evt.target.value)}
          />
          {wildcardFilter.trim().length > 0 && (
            <div onClick={() => setWildcardFilter('')} className={styles.clear}>
              ✘
            </div>
          )}
        </div>
        <div className={styles.idFilter}>
          <input
            type='text'
            placeholder='Filter by Transaction ID'
            className={styles.inputFilter}
            value={idFilter}
            onChange={evt => setIdFilter(evt.target.value)}
          />
          {idFilter.trim().length > 0 && (
            <div onClick={() => setIdFilter('')} className={styles.clear}>
              ✘
            </div>
          )}
        </div>
        <div className={styles.playerIdFilter}>
          <input
            type='text'
            placeholder='Filter by Player ID'
            className={styles.inputFilter}
            value={playerIdFilter}
            onChange={evt => setPlayerIdFilter(evt.target.value)}
          />
          {playerIdFilter.trim().length > 0 && (
            <div onClick={() => setPlayerIdFilter('')} className={styles.clear}>
              ✘
            </div>
          )}
        </div>
      </div>
      <div className={styles.recency}>{deltaUpdate}</div>
      <div className={styles.transactionsTable}>
        {filteredRootTransactions.length === 0
          ? isFilterActive
            ? 'No Transactions match Active Filter'
            : 'No Recorded Transactions in this Event'
          : filteredRootTransactions
              .sort((a, b) =>
                compareAsc(parseISO(b.created_at), parseISO(a.created_at)),
              )
              .map(x => (
                <TransactionGroup
                  key={x.id}
                  txn={x}
                  leafTransactions={leafTransactions[x.id]}
                  eventId={eventId}
                  onLegitChange={onLegitChange}
                  isPrivileged={isPrivileged}
                  isLegit={
                    dirtyLegit[x.id] !== undefined
                      ? dirtyLegit[x.id]
                      : txnLegit && txnLegit[x.id]
                  }
                  onDescriptionChange={onDescriptionChange}
                  description={
                    (dirtyDescriptions[x.id] !== undefined
                      ? dirtyDescriptions[x.id]
                      : txnDescriptions[x.id]) || ''
                  }
                  syncStatus={
                    dirtyDescriptions[x.id] === undefined
                      ? 'fresh'
                      : dirtyDescriptions[x.id] === txnDescriptions[x.id]
                      ? 'synced'
                      : 'dirty'
                  }
                />
              ))}
      </div>
    </div>
  );
};
