import React from 'react';
import Toggle from 'react-toggle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Character from './Character';
import GuideIcon from '@material-ui/icons/SupervisedUserCircle';
import WhoDis from '@material-ui/icons/Launch';
import { range } from 'lodash';
import styles from './attendee.module.scss';

export default ({
  playerID,
  role,
  name,
  characters,
  isAdvancedMember,
  displayBranch,
  isPaid,
  isAttending,
  isTraveler,
  isPremiereEvent,
  isNationalEvent,
  isVirtualEvent,
  eventID,
  inviter,
  ticketType,
  ticketCost,
  ticketIdentifier,
  buildGrowth,
  eventBaseBuild,
  allTickets,
  homeBranch,
  attendeeID,
  passCancelAttendee,
  passCancelAttendeeCharacter,
  passAttendeePaid,
  passAttendeeAttending,
  passAttendeeCharacterPaid,
  passAttendeeCharacterAttending,
  passBuildGrowthChange,
  passTicketUpgrade,
  eventConfig,
}) => {
  // console.log(eventConfig);
  // const buildGrowths = range(
  //   isNationalEvent
  //     ? 11
  //     : isPremiereEvent
  //     ? 8
  //     : (eventConfig.max_extra_xp || 2) + 1,
  // );
  const buildGrowthRange = React.useMemo(() => {
    if (eventConfig.max_extra_xp) return eventConfig.max_extra_xp;
    if (isNationalEvent) return 10;
    if (isPremiereEvent) return 7;

    return 2;
  }, [eventConfig.max_extra_xp, isNationalEvent, isPremiereEvent]);

  const buildGrowths = React.useMemo(() => {
    return range(buildGrowthRange + 1);
  }, [buildGrowthRange]);

  const ticketLabel = `$${ticketCost} ${ticketType}`;
  const handleBuildGrowthChange = event =>
    buildGrowth !== event.target.value &&
    passBuildGrowthChange(attendeeID, event.target.value);
  const cancelAttendee = () => passCancelAttendee(attendeeID);
  const handleAttendeePaid = event =>
    passAttendeePaid(attendeeID, event.target.checked);
  const handleAttendeeAttending = event =>
    passAttendeeAttending(attendeeID, event.target.checked);

  const cancelAttendeeCharacter = attendeeCharacterID =>
    passCancelAttendeeCharacter(attendeeID, attendeeCharacterID);
  const handleAttendeeCharacterPaid = (attendeeCharacterID, paid) =>
    passAttendeeCharacterPaid(attendeeID, attendeeCharacterID, paid);
  const handleAttendeeCharacterAttending = (attendeeCharacterID, attending) =>
    passAttendeeCharacterAttending(attendeeID, attendeeCharacterID, attending);

  const advancedBadge = () => {
    if (!isAdvancedMember) return null;
    return <div className={styles.badge}>AM</div>;
  };

  const paidToggle = () => {
    if (!isAdvancedMember) return null;
    return (
      <Toggle
        className={styles.toggle}
        onChange={handleAttendeePaid}
        checked={isPaid}
      />
    );
  };

  const attendingToggle = () => {
    if (!isTraveler) return null;
    return (
      <Toggle
        className={styles.toggle}
        onChange={handleAttendeeAttending}
        checked={isAttending}
      />
    );
  };

  const optionalBuildGrowth = () => (
    <span
      className={[
        styles.buildGrowth,
        buildGrowth ? '' : styles.noExtraBuild,
      ].join(' ')}
    >
      <Select
        value={buildGrowth || 0}
        onChange={handleBuildGrowthChange}
        className={styles.dropdown}
        classes={{
          select: styles.select,
          icon: styles.icon,
        }}
        MenuProps={{
          className: styles.menu,
        }}
        disableUnderline
      >
        {buildGrowths.map(x => (
          <MenuItem key={x} value={x} className={styles.option}>
            {x}
          </MenuItem>
        ))}
      </Select>
    </span>
  );

  const availableAlternateTicketOptions =
    ticketIdentifier < 800
      ? allTickets.filter(
          x =>
            !x.fully_booked &&
            x.identifier !== ticketIdentifier &&
            x.identifier >= (isTraveler ? 700 : 500) &&
            x.identifier < 800,
        )
      : [];

  const renderableTicketOptions = availableAlternateTicketOptions
    .map(x => (
      <MenuItem
        key={x.identifier}
        value={x.identifier}
        className={[styles.option, styles.alternateTickets].join(' ')}
      >
        {`$${x.cost} ${x.label}`}
      </MenuItem>
    ))
    .sort((a, b) => a.identifier - b.identifier)
    .concat([
      <MenuItem
        key={ticketIdentifier}
        value={ticketIdentifier}
        className={[styles.option, styles.currentTicket].join(' ')}
      >
        {ticketLabel}
      </MenuItem>,
    ]);

  const upgradeableTicket = () => (
    <span className={styles.ticketType}>
      <Select
        value={ticketIdentifier}
        onChange={evt =>
          ticketIdentifier !== evt.target.value &&
          passTicketUpgrade(attendeeID, evt.target.value)
        }
        className={styles.dropdown}
        classes={{
          select: styles.select,
          icon: styles.icon,
        }}
        MenuProps={{
          className: styles.ticketMenu,
        }}
        disableUnderline
      >
        {renderableTicketOptions}
      </Select>
    </span>
  );

  // <span className={styles.ticketType}>{ticketLabel}</span>
  const ticketInfo = () => {
    if (!isAdvancedMember) return null;
    return (
      <React.Fragment>
        {upgradeableTicket()}
        {optionalBuildGrowth()}
        <span className={styles.ticketCancel} onClick={cancelAttendee}>
          X
        </span>
      </React.Fragment>
    );
  };

  const homeGameInfo = () => {
    if (!homeBranch) return null;
    return <span className={styles.homeBranch}>{homeBranch}</span>;
  };

  const administrativeInfo = () => {
    if (!isAdvancedMember && !homeBranch) return null;

    return (
      <div className={styles.administrativeInfo}>
        {ticketInfo()}
        {homeGameInfo()}
      </div>
    );
  };

  const inviterInfo = () =>
    inviter ? (
      <div className={styles.inviterInfo}>
        {`Invited by `}
        <a
          className={styles.humanName}
          href={`/?player_id=${inviter.id}`}
          target='_whodis'
        >
          {`#${inviter.id} - `}
          {[inviter.first_name, inviter.last_name].join(' ').trim()}
          <WhoDis className={styles.icon} />
          {inviter.role === 'guide' ? (
            <GuideIcon fontSize='small' className={styles.icon} />
          ) : null}
        </a>
      </div>
    ) : null;

  const charactersRenderer = () =>
    Object.keys(characters)
      .sort((a, b) => characters[a].id - characters[b].id)
      .map(x => (
        <Character
          key={characters[x].name}
          name={characters[x].name}
          ticketLabel={ticketLabel}
          buildGrowth={characters[x].buildGrowth - eventBaseBuild}
          status={characters[x].status}
          isAdvancedMember={isAdvancedMember}
          isPaid={characters[x].isPaid}
          isAttending={characters[x].isAttending}
          isTraveler={isTraveler}
          isPremiereEvent={isPremiereEvent}
          isNationalEvent={isNationalEvent}
          homeBranch={homeBranch}
          buildGrowths={buildGrowths}
          upgradeableTicket={upgradeableTicket}
          attendeeCharacterID={characters[x].attendeeCharacterID}
          passCancelAttendeeCharacter={cancelAttendeeCharacter}
          passAttendeeCharacterPaid={handleAttendeeCharacterPaid}
          passAttendeeCharacterAttending={handleAttendeeCharacterAttending}
          passBuildGrowthChange={handleBuildGrowthChange}
        />
      ));

  const isGuideTicket = id => parseInt(parseInt(id, 10) / 100, 10) === 7;

  if (Object.keys(characters).length === 0) return null;

  return (
    <div className={styles.container}>
      <div className={styles.playerRow}>
        <div className={[styles.cell, styles.playerID].join(' ')}>
          <a
            href={`/admin_print/${eventID}?player_id=${playerID}`}
            target='_blank'
            rel='noopener noreferrer'
            className={styles.numericID}
          >
            {playerID}
          </a>
          {advancedBadge()}
        </div>
        <div className={[styles.cell, styles.playerName].join(' ')}>
          <a
            className={styles.humanName}
            href={`/?player_id=${playerID}`}
            target='_whodis'
          >
            <span
              className={
                !['branch_owner', 'guide'].includes(role) &&
                isGuideTicket(ticketIdentifier)
                  ? styles.misticketed
                  : ''
              }
            >
              {name}
            </span>
            <WhoDis className={styles.icon} />
            {role === 'guide' ? (
              <GuideIcon fontSize='small' className={styles.icon} />
            ) : null}
          </a>
          {administrativeInfo()}
          {inviterInfo()}
        </div>
        <div className={[styles.cell, styles.toggles].join(' ')}>
          {paidToggle()}
        </div>
        <div className={[styles.cell, styles.toggles].join(' ')}>
          {attendingToggle()}
        </div>
      </div>
      {charactersRenderer()}
    </div>
  );
};
