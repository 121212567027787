import { all, put, takeLatest } from 'redux-saga/effects'; // eslint-disable-line no-unused-vars
import axios from 'axios'; // eslint-disable-line no-unused-vars

function* fetchRemoteGameData() {
  // yield dynamic data like fractures/diseases
}

function* watchAppLoaded() {
  yield takeLatest('APP_LOADED', fetchRemoteGameData);
}

export default function* () {
  yield all([watchAppLoaded()]);
}
