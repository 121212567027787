// eslint-disable-next-line import/prefer-default-export
export const hasAnyAcquisition = ({
  maxTier,
  levelAcquired,
  achievementIsAcquired,
  innate,
}) => {
  if (maxTier === 1) return levelAcquired === 1;
  if (innate) return levelAcquired > 1;
  return levelAcquired > 0 || achievementIsAcquired;
};
