import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce, delay } from 'lodash';
import Absent from '@material-ui/icons/LocationOff';
import Present from '@material-ui/icons/LocationOn';
import styles from './TransactionApplicator.module.scss';

export default ({
  type = 'default',
  align = 'right',
  onChange,
  selectedCharacter,
  required,
}) => {
  const dispatch = useDispatch();
  const focusRef = useRef(null);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const [query, setQuery] = useState('');
  const [latchedIsSearching, setLatchedIsSearching] = useState(false);

  const { transactionPartners } = useSelector(state => ({
    transactionPartners: state.eventAdministration.transactionPartners,
  }));
  const { isSearching, data: partners } = transactionPartners;

  const isBlankQuery = query.trim().length === 0;
  const selectedUser = partners[query];
  const searchCharacters = useCallback(query => {
    if (query.trim().length === 0) return;

    dispatch({
      type: 'FETCH_TRANSACTION_PARTNERS',
      payload: { userId: query.trim() },
    });
  }, []);
  const delayedSearch = useCallback(
    debounce(term => searchCharacters(term), 250),
    [],
  );

  const maybeExecuteSearch = term => {
    setQuery(term);
    setLatchedIsSearching(true);
    delayedSearch(term);
  };

  useEffect(() => {
    if (isSearching === false) setLatchedIsSearching(false);
  }, [isSearching, setLatchedIsSearching]);

  return (
    <div className={[styles.container, styles[type], styles[align]].join(' ')}>
      <input
        type='text'
        placeholder='Find Player ID...'
        className={[
          styles.input,
          required &&
            hasInteracted &&
            !hasFocus &&
            !selectedCharacter &&
            styles.error,
        ].join(' ')}
        value={query}
        onChange={evt => maybeExecuteSearch(evt.target.value)}
        onKeyDown={evt => evt.keyCode === 13 && searchCharacters(query)}
        onFocus={() => {
          setHasFocus(true);
          setHasInteracted(true);
        }}
        onBlur={() => delay(() => setHasFocus(false), 250)}
        ref={focusRef}
      />
      {selectedCharacter && (
        <div className={styles.overlay}>
          <div className={styles.text}>
            {`${selectedCharacter.user.id} - ${selectedCharacter.name}`}
          </div>
          <div
            className={styles.close}
            onClick={() => {
              onChange(null);
              focusRef.current.focus();
            }}
          >
            ▾
          </div>
        </div>
      )}
      {hasFocus && (
        <div className={styles.options}>
          {selectedUser ? (
            <React.Fragment>
              <div className={styles.title}>
                {`#${selectedUser.user.id} - ${selectedUser.user.full_name}`}
              </div>
              <div className={styles.content}>
                {Object.keys(selectedUser.characters).map(charId => (
                  <div
                    key={charId}
                    className={styles.character}
                    onClick={() => {
                      onChange(selectedUser.characters[charId]);
                      setHasFocus(false);
                    }}
                  >
                    <div className={styles.checkedIn}>
                      {selectedUser.characters[charId].checked_in_to_event ? (
                        <Present className={styles.icon} />
                      ) : (
                        <Absent className={styles.icon} />
                      )}
                    </div>
                    <div className={styles.name}>
                      {selectedUser.characters[charId].name}
                    </div>
                    <div className={styles.status}>
                      {selectedUser.characters[charId].status !== 'active' && (
                        <div className={styles.text}>
                          {selectedUser.characters[charId].status}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </React.Fragment>
          ) : (
            !isBlankQuery &&
            (latchedIsSearching ? (
              <div className={styles.info}>Searching...</div>
            ) : (
              <div className={styles.info}>
                {`User ID ${query} does not exist`}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};
