import React, { useCallback, useMemo, useState } from 'react';
import styles from './Dispensary.module.scss';

export default ({
  onDispense,
  characterId,
  itemId,
  registeredItemId,
  augmentations,
}) => {
  const [dispenseAmount, setDispenseAmount] = useState(0);
  const dispenseText =
    dispenseAmount >= 0 ? 'Cash-out to Player' : 'Collect from Player';

  const handleDispense = useCallback(() => {
    if (dispenseAmount === 0) return;

    onDispense({
      characterId,
      itemId,
      registeredItemId,
      augmentations,
      dispenseAmount: dispenseAmount * -1,
    });

    setDispenseAmount(0);
  }, [dispenseAmount]);

  const helpText = useMemo(() => {
    if (dispenseAmount === 0)
      return 'Please specify non-zero amount of item to commit transacation';
    if (dispenseAmount < 0)
      return `You are collecting ${Math.abs(
        dispenseAmount,
      )} Physical Item Cards from Player. This is a typical case where Player owes Items`;
    if (dispenseAmount > 0)
      return `You are cashing-out ${dispenseAmount} Physical Item Cards to Player. This is a typical case when Player wants to convert their Virtual Items into Physical Item Cards`;
  }, [dispenseAmount]);

  return (
    <div className={styles.container}>
      <div className={styles.control}>
        <div>
          <input
            type='number'
            className={styles.dispenseAmount}
            value={dispenseAmount}
            onChange={evt => setDispenseAmount(parseInt(evt.target.value, 10))}
            max={999}
            min={-999}
          />
        </div>
        <div>
          <button
            type='button'
            className={[
              styles.commit,
              dispenseAmount === 0 && styles.disabled,
            ].join(' ')}
            onClick={handleDispense}
          >
            {dispenseText}
          </button>
        </div>
      </div>
      <div className={styles.help}>{helpText}</div>
    </div>
  );
};
