import { all, put, select, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

function* fetchEventStatistics({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    const endpoint =
      payload.type === 'branchID'
        ? payload.isNational
          ? 'statistics/global'
          : `branches/${payload.value}/statistics`
        : `events/${payload.value}/statistics`;
    const res = yield axios.get(endpoint, authConfig);
    yield put({ type: 'FETCH_EVENT_STATISTICS_SUCCEEDED', payload: res.data });
  } catch (error) {
    console.log(payload, error);
  }
}

function* fetchEventDetailedStatistics({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    const endpoint =
      payload.type === 'branchID'
        ? `branches/${payload.key}/detailed`
        : `events/${payload.key}/detailed`;
    const res = yield axios.get(endpoint, {
      ...authConfig,
      ...{
        params: {
          [payload.attribute]: payload.value,
        },
      },
    });

    yield put({
      type: 'FETCH_EVENT_DETAILED_STATISTICS_SUCCEEDED',
      payload: {
        attribute: payload.attribute,
        id: payload.value,
        values: res.data,
      },
    });
  } catch (error) {
    console.log(payload, error);
  }
}

function* watchFetchEventStatistics() {
  yield takeLatest('FETCH_EVENT_STATISTICS', fetchEventStatistics);
}

function* watchFetchEventDetailedStatistics() {
  yield takeLatest(
    'FETCH_EVENT_DETAILED_STATISTICS',
    fetchEventDetailedStatistics,
  );
}

export default function* () {
  yield all([watchFetchEventStatistics(), watchFetchEventDetailedStatistics()]);
}
