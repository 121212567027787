import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { compareAsc, format, getYear, parseISO } from 'date-fns';
import { find, groupBy } from 'lodash';
import {
  isPrivileged as hasPrivilege,
  isAdmin as hasAdminPrivilege,
} from '../../utils/user';
import uuid from 'uuid';
import HistoryRow from './HistoryRow';
import styles from './CharacterExtraBuild.module.scss';

export default () => {
  const dispatch = useDispatch();
  const {
    homeGameAttendances,
    travelGameAttendances,
    legacyDatum,
    legacyHomeGames,
    extraBuilds,
    createdAt,
    rootUser,
    ownedBranches,
  } = useSelector(state => ({
    homeGameAttendances: state.character.homeGameAttendances,
    travelGameAttendances: state.character.travelGameAttendances,
    legacyDatum: state.character.legacyDatum,
    legacyHomeGames: state.character.legacyHomeGames.persisted,
    extraBuilds: state.character.extraBuilds,
    createdAt: state.character.createdAt,
    rootUser: state.user,
    ownedBranches: state.user.ownedBranches,
  }));

  const isPrivileged = hasPrivilege(rootUser);
  const isAdmin = hasAdminPrivilege(rootUser);
  const cxb = find(extraBuilds, x => x.reason === 'Legacy 2.0 Build');
  const mismatchedLegacyBuild =
    cxb &&
    cxb.amount &&
    legacyDatum &&
    legacyDatum.xp &&
    cxb.amount !== legacyDatum.xp;
  const branchTransfers = rootUser.impersonatee.id
    ? rootUser.impersonatee.branchTransfers
    : rootUser.branchTransfers;
  const branchTransferLogs = branchTransfers.map(x => ({
    id: uuid.v1(),
    awarded_at: x.created_at,
    isTransferLog: true,
    event: `Transfer ${x.origin.name} -> ${x.destination.name}`,
  }));
  const editLegacyHomeGame = () =>
    dispatch({ type: 'TOGGLE_POPPER', payload: 'adminAccessInfo' });

  const buildSources = homeGameAttendances
    .concat(travelGameAttendances)
    .concat(branchTransferLogs)
    .sort((a, b) => compareAsc(parseISO(b.awarded_at), parseISO(a.awarded_at)));

  const yearlyBuildSources = groupBy(buildSources, x =>
    getYear(parseISO(x.awarded_at)),
  );

  const renderBuildHistory = () =>
    buildSources.length === 0 ? (
      <div className={styles.noCurrentEvents}>-- No 3.0 Events Recorded --</div>
    ) : (
      Object.keys(yearlyBuildSources)
        .sort((a, b) => b - a)
        .map(year => (
          <div className={styles.yearGrouping} key={year}>
            <div className={styles.timeJump}>{`-- ${year} --`}</div>
            {yearlyBuildSources[year].map(row => (
              <HistoryRow
                row={row}
                key={uuid.v1()}
                isPrivileged={isPrivileged}
                isAdmin={isAdmin}
                ownedBranches={ownedBranches}
                user={
                  rootUser.impersonatee.id ? rootUser.impersonatee : rootUser
                }
              />
            ))}
          </div>
        ))
    );

  const renderLegacyData = events => {
    let homeGameIndex = 1;

    return events.map(event => {
      const buildAwarded =
        event.xp_earned +
        event.xp_purchased +
        event.xp_converted +
        event.xp_referral +
        event.xp_cc;
      return (
        <HistoryRow
          key={uuid.v1()}
          row={{
            id: uuid.v1(),
            home_game_events_index: !event.travel_event && homeGameIndex++,
            event: event.event_name,
            build_awarded: buildAwarded,
            build_growth: buildAwarded - event.xp_earned,
          }}
        />
      );
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        XP History
        <span className={styles.small} onClick={editLegacyHomeGame}>
          {(isPrivileged || legacyHomeGames > 0) &&
            ` (Legacy Home Games: ${legacyHomeGames})`}
        </span>
      </div>
      <div className={styles.content}>
        {renderBuildHistory()}
        {legacyDatum && legacyDatum.xp > 0 && (
          <div className={styles.legacy}>
            <div className={styles.timeJump}>-- Time Jump --</div>
            <div className={styles.legacyDatum}>
              <div>XP: {legacyDatum.xp}</div>
              <div>Home Game Count: {legacyDatum.home_game_count}</div>
            </div>
            {mismatchedLegacyBuild && (
              <div className={styles.legacyHeader}>
                <b>DISCLAIMER: Data as recorded in 2.0 database.</b>
                <br />
                If there is a mismatch between 2.0 database and your current
                character data, please <u>DO NOT</u> contact your Branch
                Employees yet. We will announce the next steps to reconcile the
                difference, in which you will have the opportunity to appeal.
              </div>
            )}
            <div className={styles.legacyEvents}>
              {legacyDatum.normalized_events &&
                renderLegacyData(legacyDatum.normalized_events).reverse()}
            </div>
          </div>
        )}
        {createdAt && (
          <div className={styles.timeJump}>
            {`Genesis: ${format(parseISO(createdAt), 'yyyy-M-d')}`}
          </div>
        )}
      </div>
    </div>
  );
};
