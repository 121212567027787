import React from 'react';
import Select from '@material-ui/core/Select';
import Lock from '@material-ui/icons/Lock';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuItem from '@material-ui/core/MenuItem';
import { fromPairs } from 'lodash';
import branches from '../../utils/branches';
import styles from './Select.module.scss';
import { isAdmin as hasAdminPrivilege } from '../../utils/user';

export default ({
  user,
  selectedBranch,
  passChange,
  branchLocations,
  selectedLocation,
  allowNational,
  allowNetworkWide,
  isPersisted,
  isExternalRunner,
  hideLocations,
  shorthand,
}) => {
  const sortedBranches = branches();
  const isAdmin = hasAdminPrivilege(user);
  const isChangeAllowed = !isPersisted;

  const selectableBranches = fromPairs(
    sortedBranches
      .filter(x => {
        if (isAdmin || allowNetworkWide) return x;
        if (isExternalRunner) return parseInt(x.value, 10) === selectedBranch;
        return user.ownedBranches
          .concat(allowNational ? [13] : [])
          .includes(parseInt(x.value));
      })
      .map(x => [x.value, x]),
  );

  const handleBranchChange = event =>
    passChange('selectedBranch', event.target.value);
  const handleLocationChange = event =>
    passChange('selectedLocation', event.target.value);

  const listOfBranches = () =>
    Object.keys(selectableBranches).map(id => (
      <MenuItem
        key={id}
        value={parseInt(selectableBranches[id].value)}
        className={[styles.singleOption, styles.wide].join(' ')}
      >
        {!shorthand && 'Branch: '}
        {selectableBranches[id].label}
      </MenuItem>
    ));

  const listOfLocations = () =>
    Object.keys(branchLocations).map(id => (
      <MenuItem
        key={id}
        value={id}
        className={[styles.singleOption, styles.wide].join(' ')}
      >
        {`Location: ${branchLocations[id].name}`}
      </MenuItem>
    ));

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Select
          autoWidth
          className={[
            styles.base,
            !hideLocations && styles.half,
            isChangeAllowed ? '' : styles.disabled,
          ].join(' ')}
          classes={{
            select: styles.select,
            icon: styles.icon,
          }}
          value={selectedBranch || user.branchId || 1}
          onChange={handleBranchChange}
          MenuProps={{
            className: styles.menu,
          }}
          disableUnderline
          disabled={isChangeAllowed ? false : true}
          IconComponent={isChangeAllowed ? ArrowDropDownIcon : Lock}
        >
          {listOfBranches()}
        </Select>
        {hideLocations ? null : (
          <Select
            autoWidth
            className={[styles.base, styles.half].join(' ')}
            classes={{
              select: styles.select,
              icon: styles.icon,
            }}
            value={selectedLocation || 0}
            onChange={handleLocationChange}
            MenuProps={{
              className: [styles.menu, styles.pushUp].join(' '),
            }}
            disableUnderline
          >
            {listOfLocations()}
          </Select>
        )}
      </div>
    </div>
  );
};
