import { all, debounce, put, select, takeLatest } from 'redux-saga/effects';
import fetchUser from './fetchUser';
import { hasValidToken } from '../../utils/token';
import axios from 'axios';
import Toast from '../../Shared/Toastify/Toast';

const api = id => `/users/${id}`;

const endpointArbiter = ({ remoteID, type, payload, authConfig }) => {
  switch (type) {
    case 'CREATE_USER':
      return axios.post('/users', payload.changes, authConfig);
    case 'UPDATE_USER':
      return axios.patch(api(remoteID), payload, authConfig);
    default:
      return null;
  }
};

function* fetchUserEventLogs({ payload }) {
  const authConfig = yield select(state => state.user.session);

  if (hasValidToken(authConfig)) {
    try {
      const res = yield axios.get(
        `/users/${payload.id}/event_attendance_logs`,
        authConfig,
      );
      yield put({
        type: 'FETCH_USER_EVENT_LOGS_SUCCEEDED',
        payload: {
          data: res.data,
          fragment: payload.fragment,
        },
      });
    } catch (error) {
      Toast({ text: error.response.data.message, type: 'error' });
    }
  } else if (authConfig && !hasValidToken(authConfig)) {
    yield put({ type: 'DELETE_SESSION' });
    yield put({ type: 'NUKE_DUE_TO_TOKEN_EXPIRATION' });
  }
}

function* updateUserEventLogs({ payload }) {
  const authConfig = yield select(state => state.user.session);

  if (hasValidToken(authConfig)) {
    try {
      const res = yield axios.put(
        `/events/${payload.eventID}/event_attendees/${payload.id}`,
        {
          logs: payload.value,
        },
        authConfig,
      );
      yield put({
        type: 'UPDATE_USER_EVENT_LOGS_SUCCEEDED',
        payload: { data: res.data, fragment: payload.fragment },
      });

      Toast({
        text: `Successfully updated Event Logs for ${payload.eventName}`,
        type: 'success',
      });
    } catch (error) {
      Toast({ text: error.response.data.message, type: 'error' });
    }
  } else if (authConfig && !hasValidToken(authConfig)) {
    yield put({ type: 'DELETE_SESSION' });
    yield put({ type: 'NUKE_DUE_TO_TOKEN_EXPIRATION' });
  }
}

function* updateUser({ type, payload }) {
  const authConfig = yield select(state => state.user.session);
  const remoteID = payload.id;

  if (hasValidToken(authConfig)) {
    try {
      const res = yield endpointArbiter({
        remoteID: remoteID,
        payload: payload,
        authConfig: authConfig,
        type: type,
      });
      yield put({
        type: 'UPDATE_USER_SUCCEEDED',
        payload: res.data,
      });
      Toast({ text: 'User data successfully updated', type: 'success' });
    } catch (error) {
      Toast({ text: error.response.data.message, type: 'error' });
      yield put({ type: 'UPDATE_USER_FAILED' });
    }
  } else if (authConfig && !hasValidToken(authConfig)) {
    yield put({ type: 'DELETE_SESSION' });
    yield put({ type: 'NUKE_DUE_TO_TOKEN_EXPIRATION' });
  }
}

function* playerReferral({ payload }) {
  const user = yield select(state => state.user);
  const authConfig = user.session;

  if (hasValidToken(authConfig)) {
    const endpoint = `${payload.action}_referral`;

    try {
      const res = yield axios.post(
        `/users/${payload.inviterID}/${endpoint}`,
        { newbie_id: payload.newbieID },
        authConfig,
      );
      yield put({ type: 'PLAYER_REFERRAL_SUCCEEDED', payload: res.data });
      Toast({
        text: `User Referral ${
          payload.action === 'add' ? 'Added' : 'Removed'
        } Successfully`,
        type: 'success',
      });
      yield fetchUser(authConfig, {
        id:
          payload.predicate === 'newbie' ? payload.inviterID : payload.newbieID,
        isImpersonating: payload.fragment === 'impersonatee',
      });
    } catch (error) {
      Toast({ text: error.response.data.message, type: 'error' });
      yield put({ type: 'PLAYER_REFERRAL_FAILED' });
    }
  } else if (authConfig && !hasValidToken(authConfig)) {
    yield put({ type: 'DELETE_SESSION' });
    yield put({ type: 'NUKE_DUE_TO_TOKEN_EXPIRATION' });
  }
}

function* addMembership({ payload }) {
  const user = yield select(state => state.user);
  const authConfig = user.session;

  if (hasValidToken(authConfig)) {
    try {
      const res = yield axios.post(
        `/users/${payload.id}/add_membership`,
        null,
        authConfig,
      );
      yield put({ type: 'ADD_MEMBERSHIP_SUCCEEDED', payload: res.data });
      yield fetchUser(authConfig, {
        id: payload.id,
        isImpersonating: payload.fragment === 'impersonatee',
      });
      Toast({ text: 'Membership successfully updated', type: 'success' });
    } catch (error) {
      Toast({ text: error.response.data.message, type: 'error' });
      yield put({ type: 'ADD_MEMBERSHIP_FAILED' });
    }
  } else if (authConfig && !hasValidToken(authConfig)) {
    yield put({ type: 'DELETE_SESSION' });
    yield put({ type: 'NUKE_DUE_TO_TOKEN_EXPIRATION' });
  }
}

function* updateMembership({ payload }) {
  const user = yield select(state => state.user);
  const authConfig = user.session;

  if (hasValidToken(authConfig)) {
    try {
      const res = yield axios.patch(
        `/users/${payload.userID}/memberships/${payload.id}`,
        { starts_at: payload.value },
        authConfig,
      );
      yield put({ type: 'UPDATE_MEMBERSHIP_SUCCEEDED', payload: res.data });
      yield fetchUser(authConfig, {
        id: payload.userID,
        isImpersonating: payload.fragment === 'impersonatee',
      });
      Toast({ text: 'Membership successfully updated', type: 'success' });
    } catch (error) {
      Toast({ text: error.response.data.message, type: 'error' });
      yield put({ type: 'UPDATE_MEMBERSHIP_FAILED' });
    }
  } else if (authConfig && !hasValidToken(authConfig)) {
    yield put({ type: 'DELETE_SESSION' });
    yield put({ type: 'NUKE_DUE_TO_TOKEN_EXPIRATION' });
  }
}

function* removeMembership({ payload }) {
  const user = yield select(state => state.user);
  const authConfig = user.session;

  if (hasValidToken(authConfig)) {
    try {
      const res = yield axios.delete(
        `/users/${payload.userID}/memberships/${payload.id}`,
        authConfig,
      );
      yield put({ type: 'DELETE_MEMBERSHIP_SUCCEEDED', payload: res.data });
      yield fetchUser(authConfig, {
        id: payload.userID,
        isImpersonating: payload.fragment === 'impersonatee',
      });
      Toast({ text: 'Membership successfully removed', type: 'success' });
    } catch (error) {
      Toast({ text: error.response.data.message, type: 'error' });
      yield put({ type: 'DELETE_MEMBERSHIP_FAILED' });
    }
  } else if (authConfig && !hasValidToken(authConfig)) {
    yield put({ type: 'DELETE_SESSION' });
    yield put({ type: 'NUKE_DUE_TO_TOKEN_EXPIRATION' });
  }
}

function* updateSelfPassword({ payload }) {
  const user = yield select(state => state.user);
  const authConfig = user.session;

  if (hasValidToken(authConfig)) {
    try {
      yield axios.post(
        `/users/update_own_password`,
        {
          new_password: payload.newPassword,
          old_password: payload.oldPassword,
        },
        authConfig,
      );
      yield put({ type: 'UPDATE_SELF_PASSWORD_SUCCEEDED' });
      Toast({ text: 'Password successfully updated', type: 'success' });
    } catch (error) {
      Toast({ text: error.response.data.message, type: 'error' });
      yield put({ type: 'UPDATE_SELF_PASSWORD_FAILED' });
    }
  } else if (authConfig && !hasValidToken(authConfig)) {
    yield put({ type: 'DELETE_SESSION' });
    yield put({ type: 'NUKE_DUE_TO_TOKEN_EXPIRATION' });
  }
}

function* updateOtherPassword({ payload }) {
  const user = yield select(state => state.user);
  const authConfig = user.session;

  if (hasValidToken(authConfig)) {
    try {
      yield axios.post(
        `/users/reset_password`,
        { username: payload.id },
        authConfig,
      );
      yield put({ type: 'UPDATE_OTHER_PASSWORD_SUCCEEDED' });
      Toast({ text: 'Password Reset Email Sent', type: 'success' });
    } catch (error) {
      Toast({ text: error.response.data.message, type: 'error' });
      yield put({ type: 'UPDATE_OTHER_PASSWORD_FAILED' });
    }
  } else if (authConfig && !hasValidToken(authConfig)) {
    yield put({ type: 'DELETE_SESSION' });
    yield put({ type: 'NUKE_DUE_TO_TOKEN_EXPIRATION' });
  }
}

function* createCaps({ payload }) {
  const user = yield select(state => state.user);
  const authConfig = user.session;

  if (hasValidToken(authConfig)) {
    try {
      const res = yield axios.post(
        `/users/${payload.id}/caps`,
        {
          reason: payload.reason,
          branch_id: payload.branchId,
          amount: payload.amount,
        },
        authConfig,
      );
      yield put({
        type: 'CREATE_CAPS_SUCCEEDED',
        payload: { fragment: payload.fragment, data: res.data },
      });
      Toast({ text: 'New CAPS successfully created', type: 'success' });
    } catch (error) {
      Toast({ text: error.response.data.message, type: 'error' });
      yield put({ type: 'CREATE_CAPS_FAILED' });
    }
  } else if (authConfig && !hasValidToken(authConfig)) {
    yield put({ type: 'DELETE_SESSION' });
    yield put({ type: 'NUKE_DUE_TO_TOKEN_EXPIRATION' });
  }
}

function* updateCaps({ payload }) {
  const user = yield select(state => state.user);
  const authConfig = user.session;

  if (hasValidToken(authConfig)) {
    const record =
      payload.fragment === 'impersonatee'
        ? user.impersonatee.caps[payload.id]
        : user.caps[payload.id];

    try {
      const res = yield axios.patch(
        `/users/${payload.userID}/caps/${payload.id}`,
        {
          reason: record.reason,
          branch_id: record.branchId,
          amount: record.amount,
        },
        authConfig,
      );
      yield put({
        type: 'UPDATE_CAPS_SUCCEEDED',
        payload: { fragment: payload.fragment, data: res.data },
      });
      Toast({ text: 'CAPS successfully updated', type: 'success' });
    } catch (error) {
      Toast({ text: error.response.data.message, type: 'error' });
      yield put({ type: 'UPDATE_CAPS_FAILED' });
    }
  } else if (authConfig && !hasValidToken(authConfig)) {
    yield put({ type: 'DELETE_SESSION' });
    yield put({ type: 'NUKE_DUE_TO_TOKEN_EXPIRATION' });
  }
}

function* deleteCaps({ payload }) {
  const user = yield select(state => state.user);
  const authConfig = user.session;
  let res;

  if (hasValidToken(authConfig)) {
    try {
      if (payload.action === 'delete') {
        res = yield axios.delete(
          `/users/${payload.userID}/caps/${payload.id}`,
          authConfig,
        );
      } else if (payload.action === 'undelete') {
        res = yield axios.post(
          `/users/${payload.userID}/caps/${payload.id}/undelete`,
          {},
          authConfig,
        );
      } else {
        return;
      }

      yield put({
        type: 'DELETE_CAPS_SUCCEEDED',
        payload: { fragment: payload.fragment, data: res.data },
      });
      Toast({
        text: `CAPS successfully ${
          payload.action === 'delete' ? 'deleted' : 'restored'
        }`,
        type: 'success',
      });
    } catch (error) {
      Toast({ text: error.response.data.message, type: 'error' });
      yield put({ type: 'DELETE_CAPS_FAILED' });
    }
  } else if (authConfig && !hasValidToken(authConfig)) {
    yield put({ type: 'DELETE_SESSION' });
    yield put({ type: 'NUKE_DUE_TO_TOKEN_EXPIRATION' });
  }
}

function* watchUpdateUser() {
  yield takeLatest('UPDATE_USER', updateUser);
}

function* watchCreateUser() {
  yield takeLatest('CREATE_USER', updateUser);
}

function* watchAddMembership() {
  yield takeLatest('ADD_MEMBERSHIP', addMembership);
}

function* watchUpdateMembership() {
  yield takeLatest('UPDATE_MEMBERSHIP', updateMembership);
}

function* watchRemoveMembership() {
  yield takeLatest('REMOVE_MEMBERSHIP', removeMembership);
}

function* watchUpdateSelfPassword() {
  yield takeLatest('UPDATE_SELF_PASSWORD', updateSelfPassword);
}

function* watchUpdateOtherPassword() {
  yield takeLatest('UPDATE_OTHER_PASSWORD', updateOtherPassword);
}

function* watchCreateCaps() {
  yield takeLatest('CREATE_CAPS', createCaps);
}

function* watchDeleteCaps() {
  yield takeLatest('DELETE_CAPS', deleteCaps);
}

function* watchUpdateCaps() {
  yield debounce(500, 'UPDATE_CAPS', updateCaps);
}

function* watchPlayerReferral() {
  yield takeLatest('PLAYER_REFERRAL', playerReferral);
}

function* watchFetchUserEventLogs() {
  yield takeLatest('FETCH_USER_EVENT_LOGS', fetchUserEventLogs);
}

function* watchUpdateUserEventLogs() {
  yield takeLatest('UPDATE_USER_EVENT_LOGS', updateUserEventLogs);
}

export default function* () {
  yield all([
    watchUpdateUser(),
    watchCreateUser(),
    watchAddMembership(),
    watchUpdateMembership(),
    watchRemoveMembership(),
    watchUpdateSelfPassword(),
    watchUpdateOtherPassword(),
    watchCreateCaps(),
    watchDeleteCaps(),
    watchUpdateCaps(),
    watchPlayerReferral(),
    watchFetchUserEventLogs(),
    watchUpdateUserEventLogs(),
  ]);
}
