export const initialState = {};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'SYNC_BUFFER':
      return payload.legacyDatum;
    default:
      return state;
  }
};
