import React, { useState } from 'react';
import { fromPairs, groupBy, startCase } from 'lodash';
import styles from './administration.module.scss';

export default ({
  eventAttendees,
  eventInfo,
  ticketBreakdown,
  filteredTicketID,
  setFilteredTicketID,
  registeredTickets,
  isPremiere,
  isNational,
  isVirtual,
  eventConfig,
}) => {
  const [displayCostAs, setDisplayCostAs] = useState('nominal');
  const toggleAttendeeFilter = id =>
    Object.values(filteredTicketID).filter(x => x).length === 0
      ? setFilteredTicketID({
          ...filteredTicketID,
          ...exclusiveSelect(id),
        })
      : setFilteredTicketID({
          ...filteredTicketID,
          [id]: !filteredTicketID[id],
        });

  const exclusiveSelect = id =>
    fromPairs(
      Object.keys(ticketBreakdown)
        .filter(x => x !== id)
        .map(x => [x, true]),
    );
  const clearFilteredTicketID = () => setFilteredTicketID({});
  const maybePaidTicketBreakdown = id => paidTicketBreakdown[id] || [];
  const paidTickets = registeredTickets.filter(x => x.isPaid);
  const discountedTickets = paidTickets.filter(
    x => (x.ticketCost || 0) === 0 && x.ticketIdentifier >= 800,
  );

  const paidTicketSales = paidTickets
    .map(
      x =>
        (displayCostAs === 'nominal'
          ? ticketBreakdown[String(x.ticketIdentifier)][0].cost
          : ticketBreakdown[String(x.ticketIdentifier)][0].networkCost) || 0,
    )
    .reduce((a, b) => a + b, 0);

  const mapExtraBuildTen = x => {
    if (eventInfo.config.extra_xp_cost)
      return eventInfo.config.extra_xp_cost === 10 ? x.buildGrowth : 0;
    if (isNational)
      return isVirtual ? 0 : x.buildGrowth - (eventInfo.baseBuild || 0);
    if (isPremiere) return Math.min(x.buildGrowth, 5);

    return 0;
  };

  const mapExtraBuildFifteen = x => {
    if (eventInfo.config.extra_xp_cost)
      return eventInfo.config.extra_xp_cost === 15 ? x.buildGrowth : 0;
    if (isNational) return isVirtual ? x.buildGrowth : 0;
    if (isPremiere) return Math.max(x.buildGrowth - 5, 0);

    return x.buildGrowth;
  };

  const extraBuildTenInstances = paidTickets.map(mapExtraBuildTen);
  const extraBuildFifteenInstances = paidTickets.map(mapExtraBuildFifteen);

  const extraBuildTen = extraBuildTenInstances.reduce((a, b) => a + b, 0);
  const extraBuildFifteen = extraBuildFifteenInstances.reduce(
    (a, b) => a + b,
    0,
  );

  const extraBuildTenSales = extraBuildTen * 10;
  const extraBuildFifteenSales =
    extraBuildFifteen * (eventConfig.extra_xp_cost || 15);

  const extraBuildTenDiscountedInstances =
    discountedTickets.map(mapExtraBuildTen);
  const extraBuildTenDiscounted = extraBuildTenDiscountedInstances.reduce(
    (a, b) => a + b,
    0,
  );
  const extraBuildFifteenDiscountedInstances =
    discountedTickets.map(mapExtraBuildFifteen);
  const extraBuildFifteenDiscounted =
    extraBuildFifteenDiscountedInstances.reduce((a, b) => a + b, 0);

  const totalTicketSales =
    isNaN(paidTicketSales) ||
    isNaN(extraBuildTenSales) ||
    isNaN(extraBuildFifteenSales)
      ? '...'
      : paidTicketSales + extraBuildTenSales + extraBuildFifteenSales;

  const totalTicketSalesWithDiscount = isNaN(totalTicketSales)
    ? '...'
    : totalTicketSales -
      (extraBuildTenDiscounted || 0) * 10 -
      extraBuildFifteenDiscounted * (eventConfig.extra_xp_cost || 15);

  const paidTicketBreakdown = groupBy(
    paidTickets.map(x => ({
      id: x.ticketIdentifier,
      cost: x.ticketCost,
      networkCost: ticketBreakdown[x.ticketIdentifier][0].networkCost || 0,
    })),
    'id',
  );

  const renderAttendanceTickets = () =>
    Object.keys(ticketBreakdown).map(ticketID => (
      <div className={styles.eventProperty} key={ticketID}>
        <div
          className={[
            styles.left,
            styles.clickable,
            filteredTicketID[ticketID] && styles.masked,
          ].join(' ')}
          onClick={() => toggleAttendeeFilter(ticketID)}
        >
          {ticketBreakdown[ticketID][0].name}
          {displayCostAs === 'network' &&
            ticketBreakdown[ticketID].length > 1 &&
            ticketBreakdown[ticketID][0].networkCost > 0 &&
            ticketBreakdown[ticketID][0].networkCost !==
              ticketBreakdown[ticketID][0].cost &&
            ` [@${ticketBreakdown[ticketID][0].networkCost}] `}
        </div>
        <div className={[styles.splitMid, styles.numeric].join(' ')}>
          {`${maybePaidTicketBreakdown(ticketID).length}`}
          {`/${ticketBreakdown[ticketID].length}`}
        </div>
        <div className={[styles.splitRight, styles.numeric].join(' ')}>
          {maybePaidTicketBreakdown(ticketID)
            .reduce(
              (a, b) =>
                a +
                ((displayCostAs === 'nominal' ? b.cost : b.networkCost) || 0),
              0,
            )
            .toLocaleString()}
        </div>
      </div>
    ));

  const renderTicketBreakdown = () => (
    <React.Fragment>
      {renderAttendanceTickets()}
      {extraBuildTen > 0 && (
        <div className={styles.eventProperty} key='extra-build-ten'>
          <div className={styles.left}>+XP ($10)</div>
          <div className={[styles.splitMid, styles.numeric].join(' ')}>
            {isNaN(extraBuildTen) ? '...' : extraBuildTen}
          </div>
          <div className={[styles.splitRight, styles.numeric].join(' ')}>
            {isNaN(extraBuildTen)
              ? '...'
              : (extraBuildTen * 10).toLocaleString()}
          </div>
        </div>
      )}
      {extraBuildFifteen > 0 && (
        <div className={styles.eventProperty} key='extra-build-fifteen'>
          <div className={styles.left}>{`+XP ($${
            eventConfig.extra_xp_cost || 15
          })`}</div>
          <div className={[styles.splitMid, styles.numeric].join(' ')}>
            {isNaN(extraBuildFifteen) ? '...' : extraBuildFifteen}
          </div>
          <div className={[styles.splitRight, styles.numeric].join(' ')}>
            {isNaN(extraBuildFifteen)
              ? '...'
              : (
                  extraBuildFifteen * (eventConfig.extra_xp_cost || 15)
                ).toLocaleString()}
          </div>
        </div>
      )}
    </React.Fragment>
  );

  return (
    <div className={styles.userSelectable}>
      <div className={[styles.eventProperty, styles.header].join(' ')}>
        <div className={styles.left}>Ticket Category</div>
        <div className={styles.splitMid}>Count</div>
        <div
          className={[styles.splitRight, styles.pointer].join(' ')}
          onClick={() =>
            setDisplayCostAs(x => (x === 'nominal' ? 'network' : 'nominal'))
          }
        >
          {startCase(displayCostAs)}
        </div>
      </div>
      {renderTicketBreakdown()}
      <div
        className={[styles.eventProperty, styles.footer, styles.clickable].join(
          ' ',
        )}
        onClick={clearFilteredTicketID}
      >
        <div className={styles.left}>
          Total Ticket Sales
          {Object.values(filteredTicketID).filter(x => x).length > 0 &&
            ' (Clear Filters)'}
        </div>
        <div className={[styles.mid, styles.numeric].join(' ')}>
          {`${paidTickets.length}/${registeredTickets.length}`}
        </div>
        <div className={[styles.right, styles.numeric].join(' ')}>
          {`$${totalTicketSales.toLocaleString()}`}
        </div>
      </div>
      <div className={[styles.eventProperty].join(' ')}>
        {extraBuildFifteenDiscounted > 0 && (
          <React.Fragment>
            <div className={styles.left}>
              {`Discounted +XP ($${eventConfig.extra_xp_cost || 15})`}
            </div>
            <div className={[styles.splitMid, styles.numeric].join(' ')}>
              {isNaN(extraBuildFifteenDiscounted)
                ? '...'
                : extraBuildFifteenDiscounted}
            </div>
            <div className={[styles.splitRight, styles.numeric].join(' ')}>
              {isNaN(extraBuildFifteenDiscounted)
                ? '...'
                : `(${(
                    extraBuildFifteenDiscounted *
                    (eventConfig.extra_xp_cost || 15)
                  ).toLocaleString()})`}
            </div>
          </React.Fragment>
        )}
      </div>
      <div className={[styles.eventProperty].join(' ')}>
        {extraBuildTenDiscounted > 0 && (
          <React.Fragment>
            <div className={styles.left}>{`Discounted +XP ($10)`}</div>
            <div className={[styles.splitMid, styles.numeric].join(' ')}>
              {isNaN(extraBuildTenDiscounted) ? '...' : extraBuildTenDiscounted}
            </div>
            <div className={[styles.splitRight, styles.numeric].join(' ')}>
              {isNaN(extraBuildTenDiscounted)
                ? '...'
                : `(${(extraBuildTenDiscounted * 10).toLocaleString()})`}
            </div>
          </React.Fragment>
        )}
      </div>
      {(parseInt(extraBuildTenDiscounted, 10) > 0 ||
        parseInt(extraBuildFifteenDiscounted, 10) > 0) && (
        <div
          className={[
            styles.eventProperty,
            styles.footer,
            styles.clickable,
          ].join(' ')}
          onClick={clearFilteredTicketID}
        >
          <div className={styles.left}>Ticket Sales with Discount</div>
          <div className={[styles.mid, styles.numeric].join(' ')} />
          <div className={[styles.right, styles.numeric].join(' ')}>
            {`$${totalTicketSalesWithDiscount.toLocaleString()}`}
          </div>
        </div>
      )}
    </div>
  );
};
