import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextArea from '../../Shared/FormComponents/TextArea';
import styles from '../StaffNotes/NotesContainer.module.scss';
import { isPrivileged as hasPrivilege } from '../../utils/user';
import uuid from 'uuid';

export default ({ divider }) => {
  const dispatch = useDispatch();
  const { notes, user } = useSelector(state => ({
    notes: state.character.playerNotes,
    user: state.user,
  }));
  const [content, setContent] = useState(notes);
  const isPrivileged = hasPrivilege(user);
  const openUserEditor = () =>
    dispatch({
      type: 'TOGGLE_USER_EDITOR',
      payload: (user.impersonatee && user.impersonatee.id) || user.id,
    });
  const placeholder =
    'Add Diseases, Society Memberships, or any other character-related information here. Players will see this note. This will be printed on the character sheet.';
  const updateNotes = event => {
    if (!isPrivileged) return;

    setContent(event.target.value);
    dispatch({ type: 'UPDATE_PLAYER_NOTES', payload: event.target.value });
  };

  const renderDivider = () => (isPrivileged || notes ? divider : null);
  const renderTextarea = () =>
    isPrivileged ? (
      <React.Fragment>
        <div>Tip: Use double pipe (||) to create a newline in Printouts.</div>
        <div>
          Note: This is <u>not</u> the place to record Players' inventories.
          Please use
          <span className={styles.link} onClick={openUserEditor}>
            Player-level Notes
          </span>
          to do so.
        </div>

        <TextArea
          content={content || ''}
          placeholder={placeholder}
          handleUpdate={updateNotes}
        />
      </React.Fragment>
    ) : (
      <div className={styles.noneditableText}>
        {notes
          ? notes.split('\n').map(x => (
              <span key={uuid.v1()}>
                {x}
                <br />
              </span>
            ))
          : ''}
      </div>
    );

  useEffect(() => {
    setContent(notes);
  }, [notes]);

  return (
    <React.Fragment>
      {renderDivider()}
      <div className={styles.container}>{renderTextarea()}</div>
    </React.Fragment>
  );
};
