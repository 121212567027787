import React from 'react';
import styles from './Select.module.scss';

export default ({ inviter, user, selectedTicket, passLookup }) => {
  const helperText =
    selectedTicket === 1
      ? "Required: please enter your Guide's ID"
      : "Optional: please enter your inviter's ID";

  const inviterDetails = () => {
    if (!inviter) return null;
    if (inviter.id === user.id) return 'Cannot self-invite';
    if (
      selectedTicket === 1 &&
      !['guide', 'employee', 'admin', 'branch_owner'].includes(inviter.role)
    )
      return `#${inviter.id} is not a Guide`;
    if (inviter.error) return inviter.error;

    return `#${inviter.id} - ${[inviter.first_name, inviter.last_name]
      .join(' ')
      .trim()}`;
  };

  if (!user.isFirstEvent) return null;

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.header}>Inviter ID</div>
        <div className={styles.combo}>
          <div className={styles.inputBox}>
            <input
              type='number'
              className={styles.inviterInput}
              placeholder='ID'
              onBlur={evt => passLookup(evt.target.value)}
              onKeyDown={evt =>
                evt.keyCode === 13 && passLookup(evt.target.value)
              }
            />
          </div>
          <div className={styles.helper}>
            {inviter ? inviterDetails() : helperText}
          </div>
        </div>
      </div>
    </div>
  );
};
