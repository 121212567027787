import React from 'react';
import { compareAsc, parseISO } from 'date-fns';
import styles from './Character.module.scss';

export default ({ characterData }) => {
  const fractures = characterData.fractures || [];
  const fractureCount = fractures.filter(x => !x.removed_at).length;
  const fractureDifferential = new Array(Math.max(3 - fractureCount, 0)).fill(
    '',
  );
  const maxMindPenalty = React.useMemo(() => {
    if (fractureCount < 1) return 0;
    if (fractureCount === 1) return 5;
    return (fractureCount - 1) * 10 + 5;
  }, [fractureCount]);
  const mindPenaltyText = React.useMemo(() => {
    if (fractureCount < 1) return '';

    return `-${maxMindPenalty} Max Mind`;
  }, [fractureCount, maxMindPenalty]);

  return (
    <div className={styles.fractures}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>
              <div>Fractures</div>
              {fractureCount > 0 && <div>{mindPenaltyText}</div>}
            </th>
          </tr>
        </thead>
        <tbody>
          {fractures
            .filter(x => !x.removed_at)
            .sort((a, b) =>
              compareAsc(parseISO(a.created_at), parseISO(b.created_at)),
            )
            .map(x => (
              <tr>
                <td>{x.name}</td>
              </tr>
            ))}
          {fractureDifferential.map(x => (
            <tr>
              <td>&nbsp;</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
