import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import React, { useEffect } from 'react';
import Close from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import TextField from '../FormComponents/TextField';
import styles from './EditUserModal.module.scss';
import searchStyles from './SearchModal.module.scss';

export default () => {
  const dispatch = useDispatch();
  const { popper, modal, user } = useSelector(state => ({
    popper: state.ui.character.popperOpened,
    modal: state.ui.modal,
    user: state.user,
  }));
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState(null);
  const [result, setResult] = React.useState(null);
  const [message, setMessage] = React.useState(null);
  const handleChange = (field, value) => {
    dispatch({
      type: 'UPDATE_MODAL',
      payload: {
        field: field,
        value: value,
      },
    });
  };
  const handleSubmit = () => {
    if (modal.current.query.length === 0) return;

    setState('updating');
    setMessage('Search in progress...');
    setResult(null);
    dispatch({
      type: 'SEARCH_USER',
      payload: modal.current.query,
    });
  };
  const popperName = 'searchModal';
  const handleClose = () =>
    dispatch({ type: 'TOGGLE_POPPER', payload: popperName });

  const actionButton = () => {
    const buttonState = () => {
      switch (state) {
        case 'modified':
          return styles.modified;
        case 'error':
          return styles.error;
        case 'done':
          return styles.done;
        default:
          return styles.disabled;
      }
    };

    const buttonText = () => {
      switch (state) {
        case 'modified':
          return 'Search';
        case 'updating':
          return 'Searching...';
        case 'done':
          return 'Done!';
        case 'error':
          return 'Error, retry?';
        default:
          return 'Search';
      }
    };

    return (
      <button
        className={[styles.button, buttonState()].join(' ')}
        disabled={!['modified', 'error'].includes(state)}
        onClick={handleSubmit}
      >
        {buttonText()}
      </button>
    );
  };

  const processUserResult = results => {
    return results.map(user => {
      return {
        playerID: user.id,
        playerName: user.legal_name,
      };
    });
  };

  const processCharacterResult = results => {
    return results.map(character => {
      return {
        playerName: character.user.legal_name,
        playerID: character.user.id,
        characterName: character.name,
        characterID: character.id,
      };
    });
  };

  const dispatchImpersonation = playerID => {
    dispatch({
      type: 'IMPERSONATE_USER',
      payload: {
        userID: playerID,
        isImpersonating: true,
      },
    });
  };

  const updateSearchResult = () => {
    if (!['admin', 'branch_owner'].includes(user.role)) return setResult(null); // wipe on logout
    if (!modal.remoteResult) return;

    setMessage(null);
    setResult(
      processUserResult(modal.remoteResult.users[0])
        .concat(processCharacterResult(modal.remoteResult.characters[0]))
        .sort((a, b) => {
          return a.playerName.toLowerCase() > b.playerName.toLowerCase()
            ? 1
            : -1;
        })
        .map(x => {
          const mutedClass = () => (x.characterName ? searchStyles.muted : '');

          return (
            <div
              key={`${x.playerID}_${x.characterID}`}
              className={searchStyles.row}
            >
              <div
                className={searchStyles.id}
                onClick={() => dispatchImpersonation(x.playerID)}
              >
                {`${x.playerID}`}
              </div>
              <div className={searchStyles.name}>
                <span className={mutedClass()}>{x.playerName}</span>
                {x.characterName ? ` » ${x.characterName}` : ''}
              </div>
            </div>
          );
        }),
    );
  };

  const onKeyDown = event => {
    if (event.keyCode === 13) handleSubmit();
  };

  const resultVisibilityClass = () =>
    result === null ? searchStyles.hidden : '';
  const zeroResultClass = () =>
    result && result.length === 0 ? searchStyles.zeroResult : '';
  const resultHeader = () => {
    const n = (result || []).length;

    switch (n) {
      case 0:
        return 'No results found';
      case 1:
        return '1 result found';
      default:
        return `${n} results found`;
    }
  };

  useEffect(() => {
    const isOpen = popper === popperName;
    setOpen(isOpen);
  }, [popper]);

  useEffect(() => {
    updateSearchResult();
    setState(
      isEqual(modal.initial, modal.current)
        ? state === 'updating'
          ? 'done'
          : null
        : 'modified',
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal, user]); // hack to allow Done to be displayed

  return (
    <Modal open={open}>
      <Fade in={open}>
        <div className={styles.base}>
          <div className={styles.header}>
            <div className={styles.title}>Search</div>
            <Close className={styles.close} onClick={handleClose} />
          </div>
          <div className={styles.body}>
            <TextField
              title='Search Query'
              fieldKey='query'
              value={modal.current.query || ''}
              initialValue={modal.initial.query || ''}
              revert='clear'
              passChange={handleChange}
              passKeyDown={onKeyDown}
              autoFocus
            />
            <div className={searchStyles.message}>{message}</div>
            <div
              className={[searchStyles.result, resultVisibilityClass()].join(
                ' ',
              )}
            >
              <div className={searchStyles.wrapper}>
                <div
                  className={[searchStyles.title, zeroResultClass()].join(' ')}
                >
                  {resultHeader()}
                </div>
                <div
                  className={[searchStyles.body, zeroResultClass()].join(' ')}
                >
                  {result}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <button
              className={[styles.button, styles.cancel].join(' ')}
              onClick={handleClose}
              type='button'
            >
              Close
            </button>
            {actionButton()}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
