import React from 'react';
import { format, getYear, parseISO } from 'date-fns';
import { shiftHours } from '../../utils/time';
import NewEvent from '@material-ui/icons/AddCircleOutline';
import NextEvent from '@material-ui/icons/KeyboardArrowRight';
import PrevEvent from '@material-ui/icons/KeyboardArrowLeft';
import styles from './EventHeader.module.scss';
import history from '../../history';

export default ({
  eventName,
  eventLocation,
  eventStartDate,
  eventEndDate,
  nextEvent,
  prevEvent,
  eventBranch,
  isPremiere,
  isPrivileged,
}) => {
  const newEvent = () =>
    history.push(`/create_event?branch_id=${eventBranch.id}`);
  const redirect = id => {
    const firstLevel = history.location.pathname.match(/\/(\w+)\/(\d+)/);
    const redirection = `/${firstLevel[1]}/${id}`;
    const redirectTo = history.location.pathname.replace(
      firstLevel[0],
      redirection,
    );

    history.push(redirectTo);
  };
  const prevEventLink = (
    <div className={styles.prev}>
      {prevEvent ? (
        <PrevEvent
          className={styles.icon}
          onClick={() => redirect(prevEvent.id)}
        />
      ) : null}
    </div>
  );
  const nextEventLink = (
    <div className={styles.next}>
      {nextEvent ? (
        <NextEvent
          className={styles.icon}
          onClick={() => redirect(nextEvent.id)}
        />
      ) : (
        isPrivileged && <NewEvent className={styles.icon} onClick={newEvent} />
      )}
    </div>
  );
  const maybePremiereEvent = () =>
    isPremiere ? <div className={styles.isPremiere}>Premiere</div> : null;

  return (
    <React.Fragment>
      <div className={styles.eventName}>{eventName}</div>
      <div className={styles.eventLogistics}>
        {prevEventLink}
        <div className={styles.location}>{eventLocation}</div>
        {maybePremiereEvent()}
        <div className={styles.year}>{getYear(parseISO(eventStartDate))}</div>
        <div className={styles.time}>
          {format(shiftHours(parseISO(eventStartDate)), 'MMM dd')}
          &nbsp;-&nbsp;
          {format(shiftHours(parseISO(eventEndDate)), 'MMM dd')}
        </div>
        {nextEventLink}
      </div>
    </React.Fragment>
  );
};
