import { all, put, select, takeLatest } from 'redux-saga/effects';
import localizeTicketsPayload from '../Setup/actions/localizeTicketsPayload';
import localizeShiftsPayload from '../Setup/actions/localizeShiftsPayload';
import fetchUser from '../../User/actions/fetchUser';
import { fromPairs } from 'lodash';
import axios from 'axios';
import Toast from '../../Shared/Toastify/Toast';

function* executeCheckin({ payload }) {
  const authConfig = yield select(state => state.user.session);
  const rootUser = yield select(state => state.user);
  const isAssisted = rootUser.impersonatee.id;
  const userID = isAssisted ? rootUser.impersonatee.id : rootUser.id;

  const checkInPayload = {
    ticket_id: payload.tickets[payload.selectedTicket].id,
    shift_ids: Object.keys(payload.shifts)
      .filter(shift => payload.shifts[shift].isAttendeeSelected)
      .map(shift => payload.shifts[shift].id),
    character_ids: [
      payload.selectedCharacter !== -1 ? payload.selectedCharacter : null,
    ],
    build_growth: payload.buildBoost,
    user_id: userID,
    is_paid: payload.isPaid,
    is_attending: payload.isAttending,
    inviter_id:
      [0, 1, 104, 500].includes(payload.selectedTicket) && payload.inviter
        ? payload.inviter.id
        : null,
  };

  const eventID = payload.id;
  const endpoint = isAssisted
    ? `events/${eventID}/assisted_checkin`
    : `events/${eventID}/checkin`;

  try {
    yield put({
      type: 'UPDATE_EVENT_CHECKIN_PROPERTY',
      payload: {
        field: 'isProcessing',
        value: true,
      },
    });
    const res = yield axios.post(endpoint, checkInPayload, authConfig);
    yield put({
      type: 'UPDATE_EVENT_CHECKIN_DATA',
      payload: {
        user: res.data.user,
        purchasedTicket: res.data.ticket,
        assignedShifts: res.data.shifts,
        attendeeCharacters: res.data.attendee_characters,
        hasCheckedIn: true,
        attendeeID: res.data.id,
      },
    });
  } catch (error) {
    yield put({
      type: 'UPDATE_EVENT_CHECKIN_ERROR',
      payload: error.response.data.message,
    });
  }
}

function* fetchEventData({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield axios.get(`events/${payload}/checkin_info`, authConfig);
    yield put({
      type: 'UPDATE_EVENT_CHECKIN_DATA',
      payload: {
        name: res.data.name,
        startDate: res.data.starts_at,
        endDate: res.data.ends_at,
        nextEvent: res.data.next_event,
        prevEvent: res.data.prev_event,
        registrationDate: res.data.registration_opens_at,
        registrationUrl: res.data.branch.registration_url,
        tickets: localizeTicketsPayload(res.data.tickets, true),
        shifts: localizeShiftsPayload(res.data.shifts),
        id: res.data.id,
        isPremiere: ['premiere', 'national_premiere'].includes(res.data.kind),
        isNational: ['national', 'national_premiere'].includes(res.data.kind),
        isVirtual: res.data.location.region === 'VIRTUAL',
        location: res.data.location.name,
        branchID: res.data.branch_id,
        baseBuild: res.data.base_build,
        modDescription: res.data.mod_description,
        modMaxSignup: res.data.mod_max_signup,
        modSignupOpensAt: res.data.mod_signup_opens_at,
        modSections: fromPairs(res.data.mod_sections.map(x => [x.id, x])),
        modEnableWaitlist: res.data.mod_enable_waitlist,
        config: res.data.config,
      },
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* inviterLookup({ payload }) {
  if (!payload) return;
  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield axios.get(`users/${payload}/basic_info`, authConfig);
    yield put({
      type: 'UPDATE_INVITER_DATA',
      payload: res.data,
    });
  } catch (error) {
    yield put({
      type: 'UPDATE_INVITER_DATA',
      payload: {
        error: error.response.data.message,
      },
    });
  }
}

function* assistedSearchByID({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    yield put({
      type: 'UPDATE_EVENT_CHECKIN_PROPERTY',
      payload: {
        field: 'searchError',
        value: null,
      },
    });
    yield put({ type: 'RESET_ASSISTED_EVENT_CHECKIN' });
    yield fetchUser(authConfig, {
      isImpersonating: true,
      id: payload,
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* fetchCheckInStatus({ payload }) {
  const authConfig = yield select(state => state.user.session);
  const rootUser = yield select(state => state.user);
  const isAssisted = rootUser.impersonatee.id;

  try {
    const res = isAssisted
      ? yield axios.get(
          `events/${payload}/assisted_checkin_status?user_id=${rootUser.impersonatee.id}`,
          authConfig,
        )
      : yield axios.get(`events/${payload}/checkin_status`, authConfig);

    // if (res.data.suspended) {

    // } else {
    if (res.data) {
      if (res.data.suspended) {
        yield put({
          type: 'UPDATE_SUSPENSION_RESPONSE',
          payload: {
            isSuspended: true,
            contacts: res.data.contacts,
            isFetching: false,
          },
        });
      } else {
        yield put({
          type: 'UPDATE_EVENT_CHECKIN_PROPERTY',
          payload: {
            field: 'hasCheckedIn',
            value: true,
          },
        });
        yield put({
          type: 'UPDATE_EVENT_CHECKIN_STATUS',
          payload: {
            eventID: payload,
            isCheckedIn: true,
          },
        });
        yield put({
          type: 'UPDATE_EVENT_CHECKIN_DATA',
          payload: {
            user: res.data.user,
            eventID: res.data.event.id,
            name: res.data.event.name,
            startDate: res.data.event.starts_at,
            endDate: res.data.event.ends_at,
            nextEvent: res.data.event.next_event,
            prevEvent: res.data.event.prev_event,
            registrationDate: res.data.event.registration_opens_at,
            location: res.data.event.location.name,
            purchasedTicket: res.data.ticket,
            assignedShifts: res.data.shifts,
            attendeeCharacters: res.data.attendee_characters,
            branchID: res.data.event.branch.id,
            attendeeID: res.data.id,
            baseBuild: res.data.event.base_build,
            inviter: res.data.inviter,
            isVirtual: res.data.event.location.region === 'VIRTUAL',
            modDescription: res.data.event.mod_description,
            modSignupOpensAt: res.data.event.mod_signup_opens_at,
            modMaxSignup: res.data.event.mod_max_signup,
            modSections: fromPairs(
              res.data.event.mod_sections.map(x => [x.id, x]),
            ),
            modEnableWaitlist: res.data.event.mod_enable_waitlist,
            config: res.data.event.config,
          },
        });
      }
    } else {
      yield put({ type: 'RESET_ASSISTED_EVENT_CHECKIN' });
      yield put({
        type: 'UPDATE_EVENT_CHECKIN_STATUS',
        payload: {
          eventID: payload,
          isCheckedIn: false,
        },
      });
    }
  } catch (error) {
    yield put({
      type: 'UPDATE_EVENT_CHECKIN_PROPERTY',
      payload: {
        field: 'error',
        value: error.response.data.message,
      },
    });
  }
}

function* fetchModList({ payload }) {
  const user = yield select(state => state.user);
  const authConfig = user.session;

  try {
    const endpoint = user.impersonatee.id
      ? `events/${payload}/mods?user_id=${user.impersonatee.id}`
      : `events/${payload}/mods`;
    const res = yield axios.get(endpoint, authConfig);
    yield put({
      type: 'UPDATE_EVENT_CHECKIN_DATA',
      payload: {
        mods: fromPairs(res.data.map(x => [x.id, x])),
      },
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* toggleModSignup({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield payload.value
      ? axios.put(
          `events/${payload.eventID}/mod_signup/${payload.modID}`,
          {},
          authConfig,
        )
      : axios.delete(
          `events/${payload.eventID}/mod_signup/${payload.modID}`,
          authConfig,
        );

    yield put({
      type: 'TOGGLE_MOD_SIGNUP_SUCCEEDED',
      payload: {
        action: payload.value ? 'signup' : 'withdraw',
        participantsCount: res.data.participants_count,
        modID: payload.modID,
        value: res.data,
      },
    });

    Toast({
      text: `Mod ${payload.value ? 'Signup' : 'Withdrawal'} completed`,
      type: 'success',
    });

    yield put({ type: 'FETCH_MOD_LIST', payload: payload.eventID });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* watchFetchCheckInStatus() {
  yield takeLatest('FETCH_CHECK_IN_STATUS', fetchCheckInStatus);
}

function* watchFetchEventData() {
  yield takeLatest('FETCH_EVENT_DATA', fetchEventData);
}

function* watchExecuteCheckin() {
  yield takeLatest('EXECUTE_CHECKIN', executeCheckin);
}

function* watchAssistedSearchByID() {
  yield takeLatest('ASSISTED_CHECKIN_PLAYER_SEARCH_BY_ID', assistedSearchByID);
}

function* watchInviterLookup() {
  yield takeLatest('INVITER_LOOKUP', inviterLookup);
}

function* watchFetchModList() {
  yield takeLatest('FETCH_MOD_LIST', fetchModList);
}

function* watchToggleModSignup() {
  yield takeLatest('TOGGLE_MOD_SIGNUP', toggleModSignup);
}

export default function* () {
  yield all([
    watchFetchCheckInStatus(),
    watchFetchEventData(),
    watchExecuteCheckin(),
    watchAssistedSearchByID(),
    watchInviterLookup(),
    watchFetchModList(),
    watchToggleModSignup(),
  ]);
}
