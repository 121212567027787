import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { compareAsc, format, parseISO } from 'date-fns';
import WindowedScrollbar from '../Shared/WindowedScrollbar';
import styles from './Transfers.module.scss';

export default ({ user, branches, branchID }) => {
  const dispatch = useDispatch();
  const transfersData = branches[branchID]
    ? branches[branchID].transfers || []
    : [];
  const transfersIn = transfersData.filter(x => x.destination.id === branchID);
  const transfersOut = transfersData.filter(x => x.origin.id === branchID);

  const renderTransfer = transfers =>
    transfers.length === 0 ? (
      <div className={styles.row}>No Transfers recorded</div>
    ) : (
      transfers
        .sort((a, b) =>
          compareAsc(parseISO(b.created_at), parseISO(a.created_at)),
        )
        .map(x => (
          <div className={styles.row} key={x.id}>
            <div className={styles.timestamp}>
              {format(parseISO(x.created_at), 'yyyy-M-d')}
            </div>
            <div className={styles.playerID}>
              <a
                className={styles.link}
                href={`/?player_id=${x.user.id}`}
                target='_whodis'
              >
                {x.user.id}
              </a>
            </div>
            <div className={styles.playerName}>
              {[x.user.first_name, x.user.last_name].join(' ').trim()}
            </div>
            <div className={styles.branch}>
              {x.origin.id === branchID ? x.destination.name : x.origin.name}
            </div>
          </div>
        ))
    );
  useEffect(() => {
    if (!branchID) return;
    if (!user) return;
    if (!user.session) return;

    dispatch({ type: 'FETCH_BRANCH_TRANSFERS', payload: branchID });
  }, [branchID, dispatch, user]);

  return (
    <WindowedScrollbar styles={styles} autoHeightMax='calc(100vh - 112px)'>
      <div className={styles.container}>
        <div className={styles.subSection}>
          <div className={styles.header}>Transfers In</div>
          <div className={styles.content}>{renderTransfer(transfersIn)}</div>
        </div>
        <div className={styles.subSection}>
          <div className={styles.header}>Transfers Out</div>
          <div className={styles.content}>{renderTransfer(transfersOut)}</div>
        </div>
      </div>
    </WindowedScrollbar>
  );
};
