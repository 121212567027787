import resetShifts from './actions/resetShifts';

export const initialState = {
  hasCheckedIn: null,
  shifts: [],
  tickets: [],
  startDate: null,
  endDate: null,
  location: null,
  buildBoost: 0,
  baseBuild: 0,
  selectedTicket: -1,
  selectedCharacter: -1,
  error: null,
  searchError: null,
  isProcessing: null,
  isPaid: false,
  isAttending: false,
  isFetching: true,
  isSuspended: false,
  contacts: [],
  inviter: null,
  modSections: {},
  mods: {},
  config: {},
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'UPDATE_EVENT_CHECKIN_PROPERTY':
      return {
        ...state,
        [payload.field]: payload.value,
      };
    case 'UPDATE_SUSPENSION_RESPONSE':
      return {
        ...state,
        ...payload,
      };
    case 'UPDATE_EVENT_CHECKIN_SHIFT':
      return {
        ...state,
        shifts: {
          ...state.shifts,
          [payload.id]: {
            ...state.shifts[payload.id],
            [payload.field]: payload.value,
          },
        },
      };
    case 'TOGGLE_MOD_SIGNUP_SUCCEEDED':
      return {
        ...state,
        mods: {
          ...state.mods,
          [payload.modID]: {
            ...state.mods[payload.modID],
            admittance_position:
              payload.action === 'signup' ? payload.value : null,
            participants_count:
              payload.participantsCount ||
              state.mods[payload.modID].participants_count,
          },
        },
      };
    case 'RESET_EVENT_CHECKIN_SHIFTS':
      return {
        ...state,
        shifts: resetShifts(state.shifts, payload),
      };
    case 'RESET_ASSISTED_EVENT_CHECKIN':
      return {
        ...state,
        hasCheckedIn: false,
        buildBoost: 0,
        selectedTicket: -1,
        selectedCharacter: -1,
        error: null,
        isProcessing: null,
        ticket: null,
        isPaid: false,
        isAttending: false,
        inviter: null,
      };
    case 'UPDATE_EVENT_CHECKIN_DATA':
      return {
        ...state,
        ...payload,
        isFetching: false,
      };
    case 'UPDATE_EVENT_CHECKIN_ERROR':
      return {
        ...state,
        isProcessing: false,
        error: payload,
      };
    case 'UPDATE_INVITER_DATA':
      return {
        ...state,
        inviter: payload,
      };
    case 'USER_IMPERSONATE_ERROR':
      return {
        ...state,
        searchError: payload.message,
      };
    default:
      return state;
  }
};
