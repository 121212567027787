import React from 'react';
import { groupBy, startCase } from 'lodash';
import { GRADE_SORT } from './constants';
import styles from './ItemSearch.module.scss';

export default ({ itemData, setUrl, hasElevatedAccess }) => {
  const { parts } = itemData;
  const finalProducts = parts.map(x => x.final_products).flat();
  const partsByFinalProductCategory = groupBy(finalProducts, x => x.kind);

  const isDeadEnd = item =>
    item.visibility !== 'publicly_visible' && !hasElevatedAccess;
  const renderCategorizedFinalProduct = partCategories =>
    partCategories
      .sort((a, b) => {
        if (a.grade === b.grade) return a.name.localeCompare(b.name);

        return GRADE_SORT[a.grade] - GRADE_SORT[b.grade];
      })
      .map(prod => (
        <div className={styles.part} key={prod.id}>
          <div
            className={[styles.name, !isDeadEnd(prod) && styles.clickable].join(
              ' ',
            )}
            onClick={() => !isDeadEnd(prod) && setUrl(prod.id)}
          >
            {prod.name}
          </div>

          <div className={styles.grade}>
            {prod.grade !== 'ungraded' && startCase(prod.grade)}
          </div>
        </div>
      ));

  const renderPartsForFinalProducts = Object.keys(partsByFinalProductCategory)
    .sort((a, b) => a.localeCompare(b))
    .map(x => (
      <div className={styles.category} key={x}>
        <div className={styles.header}>{startCase(x)}</div>
        <div className={styles.categoryContent}>
          {renderCategorizedFinalProduct(partsByFinalProductCategory[x])}
        </div>
      </div>
    ));

  if (itemData.parts.length === 0) return null;
  return (
    <div className={styles.partsForFinalProducts}>
      <div className={styles.title}>Used In</div>
      <div className={styles.content}>{renderPartsForFinalProducts}</div>
    </div>
  );
};
