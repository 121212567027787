export default {
  apocatastianTemplar: {
    name: 'Apocatastian Templar',
    description:
      'Devoted to their faith and the harnessing of psionic abilities to dedicate themselves to containing and releasing large auras of energy through their devotion, acting as a militant leader of the dedicated, and overwhelming heretics with the brute force of the energy they manipulate, the Apocatastian Templar is focused on channeling and directing raw energies.',
  },
  artisanalCuisiner: {
    name: 'Artisanal Cuisiner',
    description:
      "While many people can make an edible meal, only a true artist can create a meal so delicious that you'll be craving another morsel as soon as you take the first bite. An Artisanal Cuisiner has truly mastered the flavors in food, and knows not just how to make a delicious meal but also how to disguise things within one.",
  },
  assassin: {
    name: 'Assassin',
    description:
      "There are only a few select individuals who become true masters of the knife, and they're definitely not ones you want to get in a knife fight with. Assassins are quick, nimble, and know exactly how to get under your guard to place their weapons in your vital organs.",
  },
  avontuur: {
    name: 'Avontuur',
    description:
      'There are gifts buried in the wastelands and deadly threats around the most valuable of materials. The Avontuur not only locates lost and buried pre-Fall reliquary but also laughs in the face of certain death (while still grabbing the prize). Go deep, go dangerous, or go home empty-handed.',
  },
  badAss: {
    name: 'Bad-Ass',
    description:
      'You ever see a person swing a weapon into a zombies head nonchalantly while sipping some hooch and think to yourself: That’s a badass? The Bad-Ass is someone who stands up for the little guy, knows how to look cool, and has become a figure to look up to as, well, a badass.',
  },
  battlefieldCommander: {
    name: 'Battlefield Commander',
    description:
      'In every battle there are leaders; people who act as generals and commanders who take charge of a scenario with intelligence and tactics. Battlefield Commanders are leaders in the fields of violence and war, and have been hardened by numerous battles.',
  },
  bloodiedLance: {
    name: 'Bloodied Lance',
    description:
      'Some people know how to control a battlefield with a lance. There are others, though, who have learned that keeping someone at bay with a lance can be one of the most dangerous things you do. A Blooded Lance is someone who has mastered a two-handed weapon on the battlefield. Working with other postapocalyptic lancers, the Blooded Lance is truly formidable.',
  },
  blueJacket: {
    name: 'Blue Jacket',
    description:
      'Oceans are not the only home for true sailors. Rivers, lakes, and even marshes all require specific techniques in order to survive away from the land. The Bluejacket has been trained not only in sailing techniques but also in how to get the most out of a ship.',
  },
  boneBreaker: {
    name: 'Bone Breaker',
    description:
      'There are rumors across the wasteland of people who can punch and kick hard enough that they can break someone’s bones. These are Bone Breakers, some of the most hardened individuals when it comes to unarmed combat. Known as being rougher around the edges, Bone Breakers are strong, fast, and can knock a person down on their ass if need be.',
  },
  combatVeteran: {
    description:
      'Rewarded for positive feedback based on fair play, creating engaging combat experiences, and assisting above and beyond in promoting safety and inclusive engagement in the contact-safe combat aspect of Dystopia Rising. A true Combat Veteran realizes they as an individual don’t need to win every fight and instead, that the community as a whole all should have the best chance for victory.',
    name: 'Combat Veteran',
    startingPage: 139,
  },
  compassionateGuard: {
    description:
      'Rewarded for positive feedback given to individuals who actively ensure that the spirit of inclusivity is promoted both at and between events. A player who has received positive feedback reports based on their willingness to assist others in trouble, to be a bastion of positivity, and to help others both communicate and listen with care and compassion can earn this 4.',
    name: 'Compassionate Guard',
    startingPage: 139,
  },
  deathChuckah: {
    name: 'Death Chuckah',
    description:
      'Every child learns how to throw something—whether it’s a ball, a rock, or a brick. However, only a person who practices enough becomes deadly when it comes to chucking weapons. A Death Chuckah is someone who has become lethal at chucking all kinds of objects—weapons, bricks, tables, or anything they find lying around at the time.',
  },
  doctrinarian: {
    name: 'Doctrinarian',
    description:
      'The connectivity of the act of faith bonds people together in morality and teachings. Those that follow the route of the Doctrinarian study the letter of the tenets of a faith, focus themselves on the history of the religion, and ensure that the bonds that unite the faithful are kept solid and unsullied in both life and in death. Doctrinarians are often leaders of local religious sects overseeing the unity of a faith.',
  },
  entrepreneur: {
    name: 'Entrepreneur',
    description:
      'There are those who know how to make a quick buck, and then there are those who truly understand the art of investment in trade routes. Entrepreneurs can take a single trade note and turn it into 50. They’ve made financial connections in their own settlement and, sometimes, across the wasteland as they trade goods from one place to another.',
  },
  fiscalMysticist: {
    name: 'Fiscal Mysticist',
    description:
      'A lot of people...don’t really understand the art of making money. There are a lot of financial terms that cause their eyes to glaze over. But Fiscal Mysticists know every single one of those words—and how to use them. A Fiscal Mysticist seems able to turn money into more money, but it’s not by magic. Instead, they’ve learned the ins and outs of investments and prices.',
  },
  flanker: {
    name: 'Flanker',
    description:
      'Some people like standing in place with a giant slab of shield and aiming for elbows, shoulders, and ankles with their blades. For Flankers, staying in one place sounds like a slow death due to boredom and fights of attrition. Move quickly, hit them from the side where they don’t see you, and get out before they can adjust.',
  },
  freeRadical: {
    name: 'Free Radical',
    description:
      'Organic systems are imperfect and in need of steady adaptation for improvement. While there are organic means to trigger the advancement of the living condition, the recreation of the Mortis Amaranthine always returns things to how it was once “remembered.” A Free Radical seeks to break the mold, to change the direction of their organics and their psionic impression, and to change the future.',
  },
  friendToAll: {
    description:
      'Rewarded for positive feedback given to individuals who reach outside of their established friend circles and in-character groups to help others, be inclusive, and build up the community as a whole. While each branch can be small or large, as a larger community it is important to remember to respect and engage the friends you do not yet know with compassion and an earnest effort to be inclusive.',
    name: 'Friend to All',
    startingPage: 139,
  },
  gearHead: {
    name: 'Gear Head',
    description:
      'There are endless roads with endless miles of terrain that need to be traversed. For the Gear Head, simply knowing the best routes is for amateurs. Gear Heads can find many hidden waystations, know how to use and develop specialized gear to travel in dangerous environments, and know how to refine their equipment to make it work in nearly every environment.',
  },
  graveAttuned: {
    name: 'Grave Attuned',
    description:
      'There are whispers, murmurs, and knowledge hidden all around us. The Grave Mind carries information and memories within the Infection, which exists in nearly all things. The Grave Attuned is adept at pulling this information from the imprints left behind, and gleaning knowledge from the Infection itself.',
  },
  graverobber: {
    name: 'Graverobber',
    description:
      'Knowing how to heal and cure others of their ailments and cuts is useful, but truly understanding the Infection and how to manipulate it is what makes a Graverobber so valuable and dangerous. Usually stoic and cold, Graverobbers are the best at what they do—saving others from the grasp of the grave. However, what they do comes at a high cost and many of them wind up losing pieces of their humanity along the way.',
  },
  incinerator: {
    name: 'Incinerator',
    description:
      'There is nothing so pure, so clean, and so all-solving than a liberal use of fire. Undead turned to ashes become little issue. Infectious materials can be purged by flame, transformed, and created new. There is little that the fires cannot touch and make better. Incinerators just want to watch the world burn...forever.',
  },
  innerCircle: {
    name: 'Inner Circle',
    description:
      'There is something whispered about among criminal organizations across the wasteland—something known as the “Inner Circle.” This elite group of assassins, criminals, and thieves don’t look at the little picture. Instead, they look at the larger whole of their criminal organization and decide what’s best for the group rather than any one single person. Located at the top of the food chain in terms of the shadier aspects of the wasteland, the Inner Circle is responsible for making decisions that plot the course between profit, death, and ruin—and they never let others outside their organization know who or what they are.',
  },
  inquisitor: {
    name: 'Inquisitor',
    description:
      'Everyone has information that they don’t want out in the world. Secrets. Truths hidden behind lies. Private details. An Inquisitor makes an art of drawing this information from the unwilling. Everyone knows how to hurt people; however, it takes a unique mind-set to cause harm to a person without making them unable to tell you what you want to know. Forcing someone to tell the truth, even when their hearts are set on keeping silent, is the art of the Inquisitor.',
  },
  lifeBinder: {
    name: 'Life-Binder',
    description:
      'The difference between life and death, clarity and Fracture, and action and apathy is based on the connectivity of the will to force one’s self, or another, to not give up. Life-Binders are individuals who can channel the psionic energies of faith to ensure focus of mind, to not give up when things are at the darkest, and to assist others in finding their way back to life.',
  },
  marksman: {
    name: 'Marksman',
    description:
      'You don’t always stare death in the face. Sometimes it hits you from a rifle held by a person located hundreds of yards away. Marksmen are truly amazing sure shots, trick shots, and snipers. With a gun on their back or at their hip, they’ve truly mastered the art of aiming at almost any Target.',
  },
  mentalist: {
    name: 'Mentalist',
    description:
      'There is a connectivity between all aspects of the world, and the Mentalist seeks to master the ability to focus one’s mind and will to manipulate, move, and control the connection that exists due to the shared Infection. With new means of manipulation, new training, and new tools the Mentalist look to adapt all the world to their will.',
  },
  mercenary: {
    name: 'Mercenary',
    description:
      'Mercenaries act as whirlwinds of death—for the right price. No less effective than a battlefield commander, Mercenaries are known for leaving death and destruction in their wake on a battlefield—as long as they’re paid for it.',
  },
  mindKiller: {
    name: 'Mind Killer',
    description:
      'What was once a small group of people who hunted the psionically dangerous has become an entire tradition of fortifying one’s mind against all forms of assault. This involves locking one’s mind against aberrations to reality and repelling psionic-powered and Mortis Amaranthine–fueled changes.',
  },
  naturalLeader: {
    description:
      'Rewarded for positive feedback based on fair play, inclusivity, and creating social experiences that encourage and empower newer participants in being involved with the shared narrative of the Dystopia Rising universe.',
    name: 'Natural Leader',
    startingPage: 138,
  },
  ohCaptain: {
    description:
      'Rewarded for positive feedback given to individuals who assist others in steering their story, negotiating shared experiences, and guiding their role-play and interpersonal engagements in a direction that makes for a more enjoyable experience for as many people as possible.',
    name: 'Oh Captain',
    startingPage: 139,
  },
  overlord: {
    name: 'Overlord',
    description:
      'Have you ever met a person who you find to be so charismatic that you automatically decide to do what they say? Overlords are masters at managing and controlling large groups of people to do their bidding. Acting as cult leaders, heads of large organizations, and group managers, Overlords are people who know that what they tell their subordinates might as well be law.',
  },
  perniciousSavior: {
    name: 'Pernicious Savior',
    description:
      'Mastering the life force that permeates an individual and mingling it with faithful psionic energy, the Pernicious Savior can strengthen sentient beings or rip apart those unable to contain control of their own life forces. Often Pernicious Saviors are judges and executors within faiths. These individuals have devoted themselves to the workings of the psionic energies of faith to force the mind to function over matter.',
  },
  phalanxDoyen: {
    name: 'Phalanx Doyen',
    description:
      'Some people are only passable with a shield. Then there are those who know the ins and outs of shield tactics and are true masters of blocking blows with one. The Phalanx Doyen is most often seen rallying shield users to them in a shield wall, or regularly practicing with other shield users to form tactics that make them truly deadly on a battlefield.',
  },
  sage: {
    name: 'Sage',
    description:
      'There is a difference between being intelligent and being knowledgeable. A Sage, however, is almost always both. Sages seek out esoteric knowledge throughout the wasteland, working to better understand the history of the world and natural and unnatural phenomena. These individuals create books, collect information, and are some of the most knowledgeable people in the wastes.',
  },
  saint: {
    name: 'Saint',
    description:
      'To a Saint, the body is a temple. While we may decorate the walls of the temple as we will and inhabit the temple in many different ways, to the Saint the innate sanctity of life and health are the most important aspects of faith and living.',
  },
  scavenger: {
    name: 'Scavenger',
    description:
      'The world is a ripe oyster ready to be plucked. More accurately, the world is a garbage dump filled with rewards for those who are able to separate the quality materials from the useless trash. Scavengers learn where materials tend to be pushed up out of the earth most often, can find value in most seemingly useless gear, and know how to use tools to repurpose all sorts of discarded resources.',
  },
  shadow: {
    name: 'Shadow',
    description:
      'There are those who can walk unseen and unheard—unnoticed unless they wish to be. They are shadows in the night, and their machinations and goals are all their own. Shadows take stealth to the next level, moving quietly through all types of terrain and able to slip in and out of locations unnoticed.',
  },
  sheperdOfTheLand: {
    name: 'Sheperd of the Land',
    description:
      'Farmers aren’t usually given accolades, but they are the ones who literally feed armies. They make sure cities and towns don’t starve and that everyone is fed. A Shepherd of the Land takes that philosophy to another level. Usually kind-hearted and well-meaning, Shepherds take it upon themselves to care for the settlement that they’re part of, through growing food and nurturing the land for current and future generations.',
  },
  technoSavant: {
    name: 'Techno Savant',
    description:
      'Some people know how to make everyday items, and then there are the true inventors of the wasteland. These individuals create new items and technological marvels that help spur the world forward into the future. Improving wasteland vehicles, creating new ways to filter water, and finding ways to generate electricity are all things a Techno Savant may do.',
  },
  thoughtBender: {
    name: 'Thought Bender',
    description:
      'The mind controls all things. While the Infection and death itself do not have a sentience, the do allow a shared connectivity between all sentient life that carry the Infection. The grave is not a single thought, or even a majority of thoughts, but instead the collection of millions of disjointed sentient beings clinging to shreds of existence. Reality is defined by the perception of the mind, and those that train to master the minds of others, such as Thought Benders, can control the reality others perceive.',
  },
  veteran: {
    name: 'Veteran',
    description:
      'After spending time in a town, Veterans begin to notice things. They learn more about the people around them and can pretty much pin someone as having good or bad intentions. A Veteran has been around the block a time or two, and has a heightened sense of knowledge and awareness of the world around them.',
  },
  villon: {
    name: 'Villon',
    description:
      'Some individuals are good at stealing a wallet from a pocket, and then there are those who will steal priceless artifacts from a guarded, underground compound without anyone knowing. Villons are true masters of thievery and the art of deception. Con artists, criminals, and thieves, Villons are truly masterful in their less-than-savory deeds.',
  },
  violenceTechnician: {
    name: 'Violence Technician',
    description:
      'Some weapons are not as straightforward as others. Sometimes, weapons can take the form of a gun that launches unusual projectiles or a bottle of acid thrown at an enemy. A Violence Technician has truly learned the science behind violent actions and weapons. While their methods may not be “normal,” they certainly are effective.',
  },
  wastelandMentor: {
    description:
      'Rewarded for positive feedback given to individuals who exemplify positivity and healthy community engagement between participants when they are not in character or sometimes not at events but engaging members of our community. Wasteland Mentors help one another outside of the wastelands as much as they help one another within them.',
    name: 'Wasteland Mentor',
    startingPage: 139,
  },
  wastelandStalker: {
    name: 'Wasteland Stalker',
    description:
      'There are those who hunt and track their prey, and then there are those who truly know their prey. A Wasteland Stalker is a master of understanding and learning about the monstrous creatures within the wastes, in order to be able to better track and hunt them down. It’s said that a Wasteland Stalker only goes after those most dangerous of animals and monsters.',
  },
};
