import React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import styles from './ScaledProgressionBar.module.scss';

export default ({ value, breakpoints, texts }) => {
  const scale = breakpoints[breakpoints.length - 1]; // unit

  const segments = breakpoints.map((x, i) => {
    const barWidth = (x / parseFloat(scale)) * 100;
    const leftShift = ((breakpoints[i - 1] || 0) / parseFloat(scale)) * 100;

    return (
      <div
        key={texts[i]}
        className={styles.segment}
        style={{
          width: `${barWidth}%`,
          marginLeft: `${-leftShift}%`,
        }}
      >
        <div className={styles.bar} />
        {i < breakpoints.length - 1 && (
          <div
            className={[styles.circle, value < x && styles.unfilled].join(' ')}
          />
        )}
      </div>
    );
  });

  const fillBar = () => {
    const barWidth = (value / parseFloat(scale)) * 100;
    const clampedBarWidth = value > scale ? 100 : barWidth;

    return (
      <div className={styles.filledBarWrapper}>
        <div
          className={styles.filledBar}
          style={{ width: `${clampedBarWidth}%` }}
        />
      </div>
    );
  };

  const textSegments = breakpoints.map((x, i) => {
    const barWidth = (x / parseFloat(scale)) * 100;
    const leftShift = ((breakpoints[i - 1] || 0) / parseFloat(scale)) * 100;

    return (
      <div
        key={texts[i]}
        className={styles.segments}
        style={{
          width: `${barWidth - leftShift}%`,
        }}
      >
        <div
          className={[
            styles.segment,
            value < (breakpoints[i - 1] || 0) && styles.muted,
          ].join(' ')}
        >
          {texts[i]}
        </div>
      </div>
    );
  });

  return (
    <div className={styles.container}>
      <div className={styles.textSegments}>{textSegments}</div>
      <div className={styles.chart}>
        <div className={styles.circle} />
        {segments}
      </div>
      {fillBar()}
      <a
        href='https://community.dystopiarisingnetwork.com/t/2020-june-4-tier-system-explanation/640'
        target='_xp_tier_system'
        className={styles.tierSystem}
      >
        What is this?
        <OpenInNewIcon className={styles.icon} />
      </a>
    </div>
  );
};
