import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import {
  makeStatTable,
  makeSkillXPTable,
  makeCategoryTable,
  makeLoreTable,
  xpTotalStat,
  xpTotalSkill,
  xpTotal,
} from '../utils/print';
import CharacterLoader from '../utils/loader';
import branches from '../gameData/branches';
import faiths from '../gameData/faiths';
import strains from '../gameData/strains';
import styles from './Print.module.scss';
import './Print.scss';

export default () => {
  const dispatch = useDispatch();
  const { currentCharacterID, characterStorage, character, user } = useSelector(
    state => ({
      currentCharacterID: state.localStorage.currentCharacterID,
      characterStorage: state.localStorage.characterStorage,
      character: state.character,
      user: state.user,
    }),
  );

  useEffect(() => {
    dispatch({ type: 'APP_LOADED' });
  }, [dispatch]);

  useEffect(() => {
    CharacterLoader({ characterStorage, currentCharacterID, dispatch });
  }, [characterStorage, currentCharacterID, dispatch]);

  return (
    <div className={[styles.superContainer, 'print'].join(' ')}>
      <div className={styles.container}>
        <div className={styles.administration}>
          <div className={styles.character}>
            <div className={styles.name}>{character.name}</div>
            <div className={styles.strain}>
              Strain:&nbsp;
              {strains[character.strain]
                ? strains[character.strain].name
                : 'Not Selected'}
            </div>
            <div className={character.faith}>
              Faith:&nbsp;
              {faiths[character.faith].name}
            </div>
            <div className={styles.faith}>
              XP:&nbsp;
              {xpTotal(character)}
              {character.totalBuild.earned
                ? ` / ${character.totalBuild.earned}`
                : ''}
            </div>
          </div>

          <div className={styles.player}>
            <div className={styles.name}>{`${user.name || 'Player Name'}`}</div>
            <div className={styles.number}>
              {`#${user.id || '<offline user>'}`}
            </div>
            <div className={styles.branch}>
              Home Branch:&nbsp;
              {branches[user.branchId]
                ? branches[user.branchId].shortName
                : 'Unknown'}
            </div>
          </div>
        </div>

        <div className={styles.xp}>
          <div className={styles.stat}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>STATS</th>
                  <th>Starting</th>
                  <th>Change</th>
                  <th>Current</th>
                  <th>XP</th>
                </tr>
              </thead>
              <tbody>
                {makeStatTable(character, 'Body', 'body')}
                {makeStatTable(character, 'Mind', 'mind')}
                {makeStatTable(character, 'Resolve', 'resolve')}
                {makeStatTable(character, 'Infection', 'infection')}
                <tr>
                  <td colSpan={4} className={styles.right}>
                    Total
                  </td>
                  <td className={styles.numeric}>{xpTotalStat(character)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={styles.skill}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>SKILLS</th>
                  <th>B</th>
                  <th>P</th>
                  <th>M</th>
                  <th>Achievements</th>
                  <th>XP</th>
                </tr>
              </thead>
              <tbody>
                {makeSkillXPTable(character, 'Combat', 'combat')}
                {makeSkillXPTable(character, 'Civilized', 'civilized')}
                {makeSkillXPTable(character, 'Wasteland', 'wasteland')}
                {makeSkillXPTable(character, 'Anomaly', 'anomaly')}
                <tr>
                  <td colSpan={5} className={styles.right}>
                    Total
                  </td>
                  <td className={styles.numeric}>{xpTotalSkill(character)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.build}>
          <div className={styles.skills}>
            {makeCategoryTable(character, 'Combat', 'combat')}
            {makeCategoryTable(character, 'Civilized', 'civilized')}
            {makeCategoryTable(character, 'Wasteland', 'wasteland')}
            {makeCategoryTable(character, 'Anomaly', 'anomaly')}
          </div>

          <div className={styles.lores}>{makeLoreTable(character)}</div>
        </div>
      </div>
    </div>
  );
};
