import React from 'react';
import Select from '@material-ui/core/Select';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import MenuItem from '@material-ui/core/MenuItem';
import styles from './Select.module.scss';
import { buildBoostCost } from './actions/shiftCalc';

const ALL = true;
const firstPlayerTicketOptions = [0, 1, 3, 4, 104, 500];
const SORT_ORDER = {
  700: -10,
  701: -9,
  702: -8,
  703: -7,
  800: -6,
};

export default ({
  tickets,
  isVirtual,
  isPremiere,
  isNational,
  isFirstEvent,
  hasExpandedTicketOptions,
  isTravelEvent,
  isEventRunner,
  buildBoost,
  selectedTicket,
  passBuildBoost,
  passTicketChange,
  passResetShifts,
  eventConfig,
}) => {
  const handleTicketChange = event => {
    passTicketChange(event.target.value);
    if (event.target.value >= 500) passResetShifts(ALL);
    if ([100, 150].includes(event.target.value)) passResetShifts();
  };
  const boostBuild = value => passBuildBoost(value);
  const maxXPBoost = () => {
    if (eventConfig.max_extra_xp) return eventConfig.max_extra_xp;
    if (isNational) return 10;
    if (isPremiere) return 7;

    return 2;
  };
  const ticketShiftResponsibility = (id, shiftResponsibility) =>
    id >= 700 && id < 800
      ? 'Variable shift'
      : `${shiftResponsibility * 2} hours shift`;

  const ticketLabel = (id, ticket) =>
    `${ticket.name} ($${ticket.cost} - ${ticketShiftResponsibility(
      id,
      ticket.shiftResponsibility,
    )})`;

  const ticketsBuilder = () =>
    [
      <MenuItem key={-1} value={-1} className={styles.option} disabled>
        -- Please Select Ticket Option --
      </MenuItem>,
    ].concat(
      Object.keys(tickets)
        .filter(x =>
          isFirstEvent
            ? firstPlayerTicketOptions.includes(parseInt(x)) ||
              (!tickets[0] && x >= 700 && x < 800)
            : ![0, 1].includes(parseInt(x)),
        )
        .filter(x => (isTravelEvent ? ![500, 550].includes(parseInt(x)) : x))
        .filter(x =>
          isEventRunner ? (tickets[800] ? x >= 800 : x >= 700) : x < 800,
        )
        .filter(x => (hasExpandedTicketOptions ? x : x < 700))
        .filter(x => tickets[x].name.length > 0)
        .sort((a, b) => SORT_ORDER[a] || a - SORT_ORDER[b] || b)
        .map(id => (
          <MenuItem
            key={id}
            value={parseInt(id)}
            className={styles.option}
            disabled={tickets[id].isFullyBooked}
          >
            {ticketLabel(id, tickets[id])}
          </MenuItem>
        )),
    );

  const extraBuild = () => (
    <div className={styles.row}>
      <div className={styles.header}>Extra XP</div>
      <div className={styles.buildBooster}>
        <div
          className={[
            styles.slider,
            isNational && styles.national,
            isPremiere && styles.premiere,
            isVirtual && styles.virtual,
          ].join(' ')}
        >
          <Slider
            min={0}
            max={maxXPBoost()}
            onChange={boostBuild}
            dots={true}
          />
        </div>
        <div className={styles.boostAmount}>
          {`+${buildBoost} ($${buildBoostCost({
            buildBoost,
            isEventRunner,
            isNational,
            isPremiere,
            isVirtual,
            eventConfig,
          })})`}
        </div>
      </div>
    </div>
  );

  // <Toggle checked={isBuildBoosted || false} onChange={boostBuild} />
  // <div className={styles.cost}>{isBuildBoosted ? '$50' : ''}</div>
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.header}>Ticket</div>
        <div className={styles.selector}>
          <Select
            autoWidth={true}
            className={styles.base}
            classes={{
              select: styles.select,
              icon: styles.icon,
            }}
            value={selectedTicket}
            onChange={handleTicketChange}
            MenuProps={{
              className: styles.menu,
            }}
            disableUnderline
          >
            {ticketsBuilder()}
          </Select>
        </div>
      </div>
      {extraBuild()}
    </div>
  );
};
