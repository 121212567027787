import { combineReducers } from 'redux';
import adminPrint, {
  initialState as initialAdminPrintState,
} from './adminPrint';
import character, { initialState as initialCharacterState } from './character';
import modal, { initialState as initialModalState } from './modal';
import mods, { initialState as initialModsState } from './mods';
import modSections, {
  initialState as initialModSectionsState,
} from './modSections';
import navigationIsOpen, {
  initialState as initialNavigationState,
} from '../../Navigation/reducer';
import remote, { initialState as initialRemoteState } from './remote';
import resetPassword, {
  initialState as initialResetPasswordState,
} from './resetPassword';
import resetToken, {
  initialState as initialResetTokenState,
} from './resetToken';
import selectedBranches, {
  initialState as initialSelectedBranches,
} from './selectedBranches';
import skills, { initialState as initialSkillState } from './skills';
import search, { initialState as initialSearchState } from './search';
import stats, { initialState as initialStatState } from './stats';
import user, { initialState as initialUserState } from './user';

export const initialState = {
  adminPrint: initialAdminPrintState,
  character: initialCharacterState,
  selectedBranches: initialSelectedBranches,
  modal: initialModalState,
  mods: initialModsState,
  modSections: initialModSectionsState,
  navigationIsOpen: initialNavigationState,
  remote: initialRemoteState,
  resetPassword: initialResetPasswordState,
  resetToken: initialResetTokenState,
  skills: initialSkillState,
  search,
  initialSearchState,
  stats: initialStatState,
  user: initialUserState,
};

export default combineReducers({
  adminPrint,
  character,
  selectedBranches,
  modal,
  mods,
  modSections,
  navigationIsOpen,
  remote,
  resetPassword,
  resetToken,
  skills,
  search,
  stats,
  user,
});
