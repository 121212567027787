import React from 'react';
import Close from '@material-ui/icons/Close';
import styles from './UnImpersonator.module.scss';

export default ({ unimpersonate, user, visible }) => {
  if (!visible) return null;
  if (!user.impersonatee.id) return null;
  if (user.impersonatee.isError) return null;

  return (
    <div className={styles.unimpersonate} onClick={unimpersonate}>
      <Close fontSize='small' />
      <div className={styles.text}>Close</div>
    </div>
  );
};
