import React from 'react';
import Toggle from 'react-toggle';
import styles from './PaidOrAttending.module.scss';

export default ({
  isAssisted,
  isPaid,
  isAttending,
  isTravelEvent,
  passPaidOrAttending,
}) => {
  const handleChange = (field, value) => {
    passPaidOrAttending(field, value);

    if (field === 'isAttending' && value === true) {
      passPaidOrAttending('isPaid', true);
    }
    if (field === 'isPaid' && value === false) {
      passPaidOrAttending('isAttending', false);
    }
  };

  const renderAttending = ({ checked, field, condition, label, alt }) =>
    condition() ? (
      <label className={styles.half}>
        <Toggle
          checked={checked}
          onChange={event => handleChange(field, event.target.checked)}
        />
        <div className={styles.label}>{`${checked ? '' : 'Not'} ${label}`}</div>
      </label>
    ) : (
      <div className={styles.half}>{alt}</div>
    );

  if (!isAssisted) return null;
  return (
    <div className={styles.container}>
      <div className={styles.title}>Payment and Attendance</div>
      <div className={styles.toggles}>
        {renderAttending({
          checked: isPaid,
          field: 'isPaid',
          condition: () => true,
          label: 'Paid',
        })}
        {renderAttending({
          checked: isAttending,
          field: 'isAttending',
          condition: () => isTravelEvent,
          label: 'Attending',
          alt: 'Local Player',
        })}
      </div>
    </div>
  );
};
