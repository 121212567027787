import React from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ReactTooltip from 'react-tooltip';
import Mod from './Mod.js';
import { useDispatch } from 'react-redux';
import styles from '../ModControls.module.scss';

export default ({
  modSection,
  modSectionsUI,
  modsUI,
  sectionState,
  addModSection,
  newSectionName,
  newSectionButtonState,
  modState,
  modParticipants,
  addMod,
  newModName,
  newModButtonState,
  updateModSection,
  updateNewModName,
  updateModState,
  maybeUpdateUpstream,
  maybeUpdateModUpstream,
  kickModParticipant,
  fetchModParticipants,
}) => {
  const dispatch = useDispatch();
  const newSectionButtonTitle = () => {
    switch (newSectionButtonState) {
      case 'init':
        return 'Add Section';
      case 'processing':
        return 'Adding...';
      default:
        return 'Huh???';
    }
  };
  const toggleModSection = id =>
    dispatch({ type: 'TOGGLE_MOD_SECTION', payload: id });
  const renderToggle = modSection => (
    <div
      className={styles.modSectionToggle}
      onClick={() => toggleModSection(modSection.id)}
    >
      {modSectionsUI[modSection.id] === 'expanded' ? (
        <ExpandLess className={styles.icon} />
      ) : (
        <ExpandMore className={styles.icon} />
      )}
    </div>
  );
  const renderSectionName = modSection => (
    <div
      className={[styles.modSectionLeft, modSection && styles.persisted].join(
        ' ',
      )}
    >
      <input
        type='text'
        className={styles.modSectionName}
        placeholder='Section Name (Required, ex: First Twelves)'
        value={
          (modSection ? sectionState.name[modSection.id] : newSectionName) || ''
        }
        onChange={evt =>
          updateModSection({
            modSection,
            field: 'name',
            value: evt.target.value,
          })
        }
        onKeyDown={evt => {
          if (evt.keyCode !== 13) return;

          modSection
            ? maybeUpdateUpstream({ field: 'name', id: modSection.id })
            : addModSection();
        }}
        onBlur={() =>
          modSection &&
          maybeUpdateUpstream({ field: 'name', id: modSection.id })
        }
      />
      {modSection && (
        <div
          className={styles.modCount}
        >{`${modSection.mods.length} Mods`}</div>
      )}
    </div>
  );
  const renderSectionLimitOrAddNewSection = modSection =>
    modSection ? renderSectionLimit(modSection) : renderAddNewSection;
  const renderSectionLimit = modSection => (
    <div
      className={[
        styles.modSectionRight,
        styles.persisted,
        styles.inverse,
      ].join(' ')}
    >
      <input
        type='number'
        className={styles.modSectionSignupLimit}
        value={sectionState.signupLimit[modSection.id] || ''}
        data-tip='Number of Mods a Player can signup within this Section. Enter 0 to hide this Section from Players.'
        data-for='modSection'
        onChange={evt =>
          updateModSection({
            modSection,
            field: 'signupLimit',
            value: evt.target.value,
          })
        }
        onKeyDown={evt =>
          evt.keyCode === 13 &&
          modSection &&
          maybeUpdateUpstream({ field: 'signupLimit', id: modSection.id })
        }
        onBlur={() =>
          maybeUpdateUpstream({ field: 'signupLimit', id: modSection.id })
        }
      />
    </div>
  );
  const renderAddNewSection = (
    <div
      className={[
        styles.modSectionRight,
        (!newSectionName || newSectionName.length === 0) && styles.disabled,
      ].join(' ')}
    >
      <button className={styles.addSection} onClick={addModSection}>
        {newSectionButtonTitle()}
      </button>
    </div>
  );
  const renderModList = modSection =>
    modSectionsUI[modSection.id] === 'expanded' &&
    modSection.mods
      .sort((a, b) => a.id - b.id)
      .map(mod => (
        <Mod
          key={mod.id}
          mod={mod}
          modSectionID={modSection.id}
          modsUI={modsUI}
          modState={modState}
          modParticipants={modParticipants}
          updateModState={updateModState}
          maybeUpdateModUpstream={maybeUpdateModUpstream}
          kickModParticipant={kickModParticipant}
          fetchModParticipants={fetchModParticipants}
        />
      ));
  const renderAddNewMod = modSection =>
    modSectionsUI[modSection.id] === 'expanded' && (
      <Mod
        mod={null}
        modSectionID={modSection.id}
        addMod={addMod}
        newModName={newModName}
        newModButtonState={newModButtonState}
        updateNewModName={updateNewModName}
      />
    );
  return (
    <div className={styles.modSection}>
      <ReactTooltip
        id='modSection'
        place='left'
        effect='solid'
        className={styles.tooltip}
      />
      <div className={styles.modSectionHeader}>
        {modSection && renderToggle(modSection)}
        {renderSectionName(modSection)}
        {renderSectionLimitOrAddNewSection(modSection)}
      </div>
      {modSection && renderModList(modSection)}
      {modSection && renderAddNewMod(modSection)}
    </div>
  );
};
