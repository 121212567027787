import { all, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../Shared/Toastify/Toast';

function* fetchStaffs() {
  try {
    const res = yield axios.get('/staffs');
    yield put({ type: 'FETCH_STAFFS_SUCCEEDED', payload: res.data });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* watchFetchStaffs() {
  yield takeLatest('FETCH_STAFFS', fetchStaffs);
}

export default function* () {
  yield all([watchFetchStaffs()]);
}
