import React from 'react';
import TransactionApplicator from './TransactionApplicator';
import styles from './ItemTransactionUnique.module.scss';

export default ({
  entry,
  onDescriptionUpdate,
  onDescriptionAdd,
  onDescriptionRemove,
  onRecipientChange,
  onRemoveBatch,
}) => {
  const renderDescriptions = () =>
    entry.data.map((row, index) => (
      <div className={styles.descriptionStruct} key={`description-${index}`}>
        <div className={styles.controlRow}>
          <div className={styles.characterSelector}>
            <TransactionApplicator
              type='compact'
              align='left'
              onChange={value =>
                onDescriptionUpdate({ index, field: 'character', value })
              }
              selectedCharacter={row.character}
              required
            />
          </div>
          <div className={styles.quantityGroup}>
            <div>Quantity</div>
            <div>
              <input
                type='number'
                className={styles.numeric}
                value={row.quantity}
                min={1}
                onChange={evt =>
                  onDescriptionUpdate({
                    index,
                    field: 'quantity',
                    value: Number(evt.target.value),
                  })
                }
              />
            </div>
          </div>
          <button
            onClick={() => onDescriptionRemove(index)}
            className={styles.remove}
          >
            Remove
          </button>
        </div>
        <div className={styles.textRow}>
          <textarea
            onChange={evt =>
              onDescriptionUpdate({
                index,
                field: 'description',
                value: evt.target.value,
              })
            }
            className={styles.textarea}
            value={row.description || ''}
            placeholder='Annotate to create a Unique Item'
          />
        </div>
      </div>
    ));

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{`Batch: ${entry.item.name}`}</div>
        <button onClick={onRemoveBatch} className={styles.remove}>
          Remove Batch
        </button>
      </div>
      <div className={styles.descriptions}>{renderDescriptions()}</div>
      <button onClick={onDescriptionAdd} className={styles.addDescription}>
        Add More Item To Batch
      </button>
    </div>
  );
};
