import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SubAttendances from './SubAttendances';
import WindowedScrollbar from '../Shared/WindowedScrollbar';
import styles from './Attendances.module.scss';

export default ({ user, branches, branchID, isFetching }) => {
  const dispatch = useDispatch();
  const attendances = branches[branchID]
    ? branches[branchID].attendances || {}
    : {};

  const privilegedFilter = x => ['branch_owner', 'admin'].includes(x.role);
  const guideFilter = x => x.role === 'guide';

  const privilegedUsers = attendances.staffs
    ? attendances.staffs.filter(privilegedFilter)
    : null;
  const guides = attendances.staffs
    ? attendances.staffs.filter(guideFilter)
    : null;
  const activePlayers = attendances.actives ? attendances.actives : null;
  const travelers = attendances.travelers ? attendances.travelers : null;
  const inactivePlayers = attendances.inactives || null;

  const handleFetchRequest = category => {
    dispatch({
      type: 'FETCH_BRANCH_ATTENDANCES',
      payload: { branchID, category },
    });
  };

  useEffect(() => {
    if (!branchID) return;
    if (!user) return;
    if (!user.session) return;

    dispatch({
      type: 'FETCH_BRANCH_ATTENDANCES',
      payload: { branchID, category: 'staffs' },
    });
  }, [branchID, dispatch, user]);

  return (
    <WindowedScrollbar styles={styles} autoHeightMax='calc(100vh - 112px)'>
      <div className={styles.container}>
        <SubAttendances
          users={privilegedUsers}
          title='Staffs'
          isDefaultVisible
          isFetching={isFetching}
        />
        <SubAttendances
          users={guides}
          title='Guides'
          isDefaultVisible
          isFetching={isFetching}
        />
        <SubAttendances
          users={activePlayers}
          title='Active Players'
          isFetching={isFetching}
          passFetch={() => handleFetchRequest('actives')}
        />
        <SubAttendances
          users={travelers}
          title='Travelers'
          isTravelers
          isFetching={isFetching}
          passFetch={() => handleFetchRequest('travelers')}
        />
        <SubAttendances
          users={inactivePlayers}
          title='Inactive Players'
          isInactive
          isFetching={isFetching}
          passFetch={() => handleFetchRequest('inactives')}
        />
      </div>
    </WindowedScrollbar>
  );
};
