import { SYNC_BUFFER } from '../types';

export const initialState = {};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SYNC_BUFFER:
      return payload.societies;
    default:
      return state;
  }
};
