export const initialState = {
  initial: {},
  current: {},
  error: null,
  remoteResult: null,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'LOAD_MODAL':
      return {
        initial: payload,
        current: payload,
        error: null,
        remoteResult: null,
      };
    case 'UPDATE_MODAL':
      return {
        ...state,
        current: {
          ...state.current,
          [payload.field]: payload.value,
        },
        error: null,
      };
    case 'UPDATE_MODAL_ERROR':
      return {
        ...state,
        error: payload,
      };
    case 'UPDATE_RESULT':
      return {
        ...state,
        remoteResult: payload,
      };
    case 'CLEAR_MODAL':
    case 'DELETE_SESSION':
    case 'LOGOUT_ALL_SESSIONS':
      return initialState;
    default:
      return state;
  }
};
