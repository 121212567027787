import React, { useCallback } from 'react';
import { format, parseISO } from 'date-fns';
import Toggle from 'react-toggle';
import Badge from '../../Shared/Badge';
import history from '../../history';
import uuid from 'uuid';
import styles from './TransactionGroup.module.scss';

export default ({
  txn,
  leafTransactions,
  highlightItemId,
  highlightCharacterId,
  eventId,
  showEventName,
  onLegitChange,
  isLegit,
  description,
  onDescriptionChange,
  syncStatus,
  isPrivileged,
}) => {
  const withHighlight = highlightItemId && highlightCharacterId;

  const renderTransactionRow = txn => (
    <div
      key={txn.id}
      className={[
        styles.transaction,
        withHighlight && styles.withHighlight,
        withHighlight &&
          txn.item.id === parseInt(highlightItemId, 10) &&
          (txn.character.id === parseInt(highlightCharacterId, 10) ||
            (txn.transaction_partner && txn.transaction_partner.id) ===
              parseInt(highlightCharacterId, 10)) &&
          styles.highlight,
        withHighlight &&
          (txn.transaction_partner && txn.transaction_partner.id) ===
            parseInt(highlightCharacterId, 10) &&
          styles.outbound,
      ].join(' ')}
    >
      <div className={styles.transactionId}>{txn.id}</div>
      <div className={styles.transactionPartner}>
        <div
          className={styles.abbreviated}
        >{`#${txn.character.user.id} - ${txn.character.name}`}</div>

        <div className={styles.autoPopup}>
          <div>{`#${txn.character.user.id} - ${txn.character.user.full_name}`}</div>
          <div>{txn.character.name}</div>
          {isPrivileged && eventId && (
            <div
              className={styles.link}
              onClick={() =>
                history.push(
                  `/admin_transactions/${txn.event.id}/lockboxes/${txn.character.user.id}?highlightItemId=${txn.item.id}&highlightCharacterId=${txn.character.id}`,
                )
              }
            >
              {`Check ${txn.item.name} inventory`}
            </div>
          )}
        </div>
      </div>
      <div className={styles.transactLeft}>
        {txn.stack > 0 && (
          <React.Fragment>
            <div>&larr;</div>
            <div className={styles.itemAmount}>{txn.stack}</div>
          </React.Fragment>
        )}
      </div>
      <div className={styles.itemName}>
        <div className={styles.augmentationAndName}>
          {txn.item.kind === 'blueprint' && 'Blueprint: '}
          {txn.augmentations &&
            txn.augmentations.map(x => <Badge text={x} key={x} />)}
          {txn.item.name}
        </div>
        {txn.registered_item && (
          <div>
            <span className={styles.unique}>Unique Item: </span>
            {txn.registered_item.description}
          </div>
        )}
        {txn.transaction_partner && (
          <div>{`From #${txn.transaction_partner.user.id} - ${txn.transaction_partner.name}`}</div>
        )}
      </div>
      <div className={styles.transactRight}>
        {txn.stack < 0 && (
          <React.Fragment>
            <div className={styles.itemAmount}>{txn.stack * -1}</div>
            <div>&rarr;</div>
          </React.Fragment>
        )}
      </div>
    </div>
  );

  const renderDescription = useCallback(() => {
    if (isPrivileged && onDescriptionChange) {
      return (
        <div>
          <textarea
            rows={4}
            placeholder='Add transaction details (optional)'
            value={description}
            onChange={evt =>
              onDescriptionChange({
                value: evt.target.value,
                transactionId: txn.id,
                eventId,
              })
            }
            className={[styles.description, styles.editable].join(' ')}
          />
          {syncStatus !== 'fresh' && (
            <div
              className={[syncStatus === 'dirty' && styles.blinking].join(' ')}
            >
              {syncStatus === 'dirty'
                ? 'Keep typing. Background sync in progress...'
                : 'Sync completed'}
            </div>
          )}
        </div>
      );
    }

    return (
      description && (
        <div className={styles.description}>
          {description.split(/\n/).map(x => (
            <div key={uuid.v1()}>{x}</div>
          ))}
        </div>
      )
    );
  }, [description, onDescriptionChange, syncStatus]);

  return (
    <div className={styles.transactionGroup} key={txn.id}>
      <div className={styles.header}>
        {showEventName && (
          <div className={styles.eventName}>{txn.event.name}</div>
        )}
        <div className={styles.chronology}>
          <div className={styles.timestamp}>
            {format(parseISO(txn.created_at), 'MM/dd HH:mm')}
          </div>
          <div className={styles.postie}>
            {`#${txn.postie.id} - ${txn.postie.full_name}`}
          </div>
          {isPrivileged && onLegitChange && (
            <div className={styles.legit}>
              <Toggle
                checked={isLegit}
                onChange={evt =>
                  onLegitChange({
                    transactionId: txn.id,
                    value: evt.target.checked,
                  })
                }
              />
            </div>
          )}
        </div>
      </div>
      <div
        className={[
          styles.transactions,
          isLegit === false && styles.invalidated,
        ].join(' ')}
      >
        {renderTransactionRow(txn)}
        {leafTransactions &&
          leafTransactions
            .sort((a, b) => {
              if (
                (a.stack > 0 && b.stack > 0) ||
                (a.stack < 0 && b.stack < 0)
              ) {
                return b.id - a.id;
              } else {
                return a.stack - b.stack;
              }
            })
            .map(leafTxn => renderTransactionRow(leafTxn))}
      </div>
      {renderDescription()}
    </div>
  );
};
