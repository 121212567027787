import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TextArea from '../../../Shared/FormComponents/TextArea';
import styles from '../ModControls.module.scss';

export default ({ eventModDescription, eventID, branchID }) => {
  const dispatch = useDispatch();
  const [modDescription, setModDescription] = useState('');
  const maybeUpdateUpstream = () => {
    const upstream = eventModDescription;
    const maybeDirty = modDescription;

    if (upstream !== maybeDirty) {
      dispatch({
        type: 'UPDATE_EVENT_MODS_METADATA',
        payload: {
          field: 'mod_description',
          value: maybeDirty,
          eventID,
          branchID,
        },
      });
    }
  };

  useEffect(() => {
    setModDescription(eventModDescription);
  }, [eventModDescription]);

  return (
    <div className={styles.description}>
      <TextArea
        content={modDescription || ''}
        placeholder='Use this space to describe the rules of conduct, signup, or anything else pertaining to Mods for this Event'
        handleUpdate={evt => setModDescription(evt.target.value)}
        handleBlur={maybeUpdateUpstream}
      />
    </div>
  );
};
