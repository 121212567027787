import InputBase from '@material-ui/core/InputBase';
import PlayArrow from '@material-ui/icons/PlayArrow';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import SoftDelete from '@material-ui/icons/DeleteForever';
import Inactive from '@material-ui/icons/NotInterested';
import Synchronized from '@material-ui/icons/CloudDone';
import Retired from '@material-ui/icons/Hotel';
import Reload from '@material-ui/icons/Sync';
import Staged from '@material-ui/icons/SettingsEthernet';
import Undo from '@material-ui/icons/Undo';
import Upload from '@material-ui/icons/CloudUpload';
import styles from './CharacterName.module.scss';

const Container = ({
  name,
  state,
  passChange,
  passDelete,
  passSwitch,
  passUndelete,
  uuid,
  currentCharacterID,
  existance,
  totalBuild,
  remoteID,
  isEditable,
  hasValidToken,
}) => {
  const handleChange = event =>
    isEditable ? passChange(uuid, event.target.value, remoteID) : null;
  const handleSwitch = () => passSwitch(uuid);
  const handleDelete = () => passDelete(uuid);
  const handleUndelete = () => passUndelete(uuid);
  const handleSelectAllText = event =>
    isEditable ? event.target.select() : null;
  const isSelected = () => uuid === currentCharacterID;
  const isOffline = () => remoteID === null;
  // eslint-disable-next-line no-alert
  const notImplementedAlert = () => alert('Upload not implemented yet...');
  const activationInteraction = () => {
    if (isOffline() && isSelected() && hasValidToken) {
      return <Upload className={styles.svg} onClick={notImplementedAlert} />;
    }

    return isSelected() ? (
      <Reload className={styles.svg} onClick={handleSwitch} />
    ) : (
      <PlayArrow className={styles.svg} onClick={handleSwitch} />
    );
  };
  const syncStatus = () => {
    if (remoteID) {
      switch (state) {
        case 'staged':
          return (
            <Staged
              data-tip='This character is Staged. It can receive XP from Checkin but it cannot be played.'
              className={[styles.svg, styles.hidden].join(' ')}
            />
          );
        case 'inactive':
          return (
            <Inactive
              data-tip='This character is Inactive. It can not be brought into game and will not receive any further XP.'
              className={[styles.svg, styles.hidden].join(' ')}
            />
          );
        case 'retired':
          return (
            <Retired
              data-tip='This character is Retired. It can not be brought into game and will not receive any further XP.'
              className={[styles.svg, styles.hidden].join(' ')}
            />
          );
        default:
          return (
            <Synchronized
              data-tip='This character is Active. It can be played and receive XP from Checkin.'
              className={[styles.svg, styles.hidden].join(' ')}
            />
          );
      }
    }
    return (
      <React.Fragment>
        <SoftDelete
          key='softDelete'
          className={[
            styles.svg,
            styles.delete,
            existance === 'deleted' ? styles.hidden : '',
          ].join(' ')}
          onClick={handleDelete}
        />
        <Undo
          key='undelete'
          className={[
            styles.svg,
            styles.undelete,
            existance === 'enabled' ? styles.hidden : '',
          ].join(' ')}
          onClick={handleUndelete}
        />
      </React.Fragment>
    );
  };
  const buildSummary = () => {
    const t = [totalBuild.used];
    if (!totalBuild.earned) return t.join('');
    return t.concat(totalBuild.earned).join(' / ');
  };
  return (
    <div className={styles.row}>
      {syncStatus()}
      <ReactTooltip place='bottom' effect='solid' className={styles.tooltip} />
      <div
        className={[styles.subrow, isSelected() ? styles.highlighted : ''].join(
          ' ',
        )}
      >
        <InputBase
          className={styles.entry}
          value={name}
          onChange={handleChange}
          onClick={handleSelectAllText}
          disabled={existance === 'deleted'}
        />
        {activationInteraction()}
        <div className={styles.metadata}>
          <div className={styles.metaElement}>{`XP: ${buildSummary()}`}</div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Container);
