import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ExternalManagerList from './ExternalManagerList';
import styles from './ExternalManagers.module.scss';

export default ({ eventID, managers, isExternalRunner }) => {
  const dispatch = useDispatch();
  const [currentInput, setCurrentInput] = useState('');
  const dispatchUpdateExternalManagers = () =>
    dispatch({
      type: 'UPDATE_EXTERNAL_MANAGERS',
      payload: {
        eventID: eventID,
        userIDs: currentInput.split(/,/),
      },
    });
  const handleRemove = (userID, localOnly) =>
    dispatch({
      type: 'REMOVE_EXTERNAL_MANAGER',
      payload: {
        eventID: eventID,
        userID: userID,
        localOnly: localOnly,
      },
    });

  useEffect(() => {
    setCurrentInput('');
    if (!eventID) return;
    dispatch({ type: 'FETCH_EXTERNAL_MANAGERS', payload: eventID });
  }, [eventID, dispatch]);

  useEffect(() => {
    setCurrentInput('');
  }, [managers]);

  if (!eventID) return null;
  return (
    <div className={styles.container}>
      <div className={styles.header}>External Managers</div>
      <div className={styles.description}>
        External Managers are Branch Owners from different branch. They have
        edit access to this event.
      </div>
      <div className={styles.managerList}>
        <ExternalManagerList
          managers={managers}
          passRemove={handleRemove}
          isExternalRunner={isExternalRunner}
        />
      </div>
      {isExternalRunner ? null : (
        <div className={styles.inputContainer}>
          <input
            type='text'
            className={styles.box}
            onChange={evt => setCurrentInput(evt.target.value)}
            value={currentInput}
            placeholder="Add other Branch Owners' ID separated by comma"
          />
          <button
            type='button'
            onClick={dispatchUpdateExternalManagers}
            className={[
              styles.buttonAdd,
              currentInput.length === 0 ? styles.disabled : '',
            ].join(' ')}
          >
            Add
          </button>
        </div>
      )}
    </div>
  );
};
