import React from 'react';
import { compareAsc, format, formatDistanceToNow, parseISO } from 'date-fns';
import uuid from 'uuid';
import styles from './TransferHistory.module.scss';

export default ({ hidden, data, genesisDate }) => {
  const transfers = data
    .sort((a, b) => compareAsc(parseISO(b.created_at), parseISO(a.created_at)))
    .map(x => (
      <div className={styles.row} key={() => uuid.v1()}>
        <div className={styles.date}>
          <div className={styles.numeric}>
            {format(parseISO(x.created_at), 'yyyy-M-d')}
          </div>
          <div className={styles.human}>
            {formatDistanceToNow(parseISO(x.created_at), { addSuffix: true })}
          </div>
        </div>
        <div className={styles.description}>
          {`${x.origin.name} -> ${x.destination.name}`}
        </div>
      </div>
    ));

  if (hidden) return true;
  return (
    <div className={styles.container}>
      <div className={styles.title}>Transfer History</div>
      <div className={styles.content}>
        {transfers}
        <div className={styles.row} key='genesis'>
          <div className={styles.date}>
            <div className={styles.numeric}>
              {genesisDate
                ? format(parseISO(genesisDate), 'yyyy-M-d')
                : 'Unknown'}
            </div>
            <div className={styles.human}>
              {genesisDate
                ? formatDistanceToNow(parseISO(genesisDate), {
                    addSuffix: true,
                  })
                : 'Unknown'}
            </div>
          </div>
          <div className={styles.description}>Player Account Created</div>
        </div>
      </div>
    </div>
  );
};
