import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fromPairs } from 'lodash';
import { Transition } from 'react-transition-group';
import {
  compareAsc,
  format,
  formatDistanceToNow,
  isPast,
  parseISO,
} from 'date-fns';
import Toggle from 'react-toggle';
import Blocked from '@material-ui/icons/Block';
import ScheduledTime from '@material-ui/icons/Alarm';
import BlockedCheckin from '@material-ui/icons/ErrorOutline';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import uuid from 'uuid';
import skillInitializer from '../../gameData/skillInitializer';
import styles from './ModSignup.module.scss';

export default ({ event, user, ui, isAssisted }) => {
  const dispatch = useDispatch();
  const [signups, setSignups] = useState({});
  const { skills, remoteSkills } = skillInitializer();
  const { modEnableWaitlist } = event;
  const isOpenForSignup = isPast(parseISO(event.modSignupOpensAt));
  const eventHasEnded = isPast(parseISO(event.endDate));
  const signupCount = sectionID =>
    Object.keys(signups)
      .filter(x => signups[x] !== null)
      .filter(x => event.mods[x].mod_section.id === sectionID).length;
  const globalSignupCount = Object.keys(signups).filter(
    x => signups[x] !== null,
  ).length;
  const isWithinSignupLimit = sectionID =>
    signupCount(sectionID) < event.modSections[sectionID].signup_limit;
  const isWithinGlobalSignupLimit = () => {
    if (!event.modMaxSignup) return true;
    return globalSignupCount < event.modMaxSignup;
  };
  const localizeSignups = mods =>
    setSignups({
      ...signups,
      ...fromPairs(
        Object.keys(mods).map(x => [mods[x].id, mods[x].admittance_position]),
      ),
    });
  const isToggleable = modID => {
    if (signups[modID] !== null) return true;

    return modEnableWaitlist
      ? isWithinSignupLimit(event.mods[modID].mod_section.id) &&
          isWithinGlobalSignupLimit()
      : event.mods[modID].participants_count <
          event.mods[modID].participation_limit;
  };
  const toggleSignup = (modID, modName) => {
    if (signups[modID] && !window.confirm(`Withdraw from ${modName}?`)) return;

    dispatch({
      type: 'TOGGLE_MOD_SIGNUP',
      payload: {
        modID: modID,
        eventID: event.id || event.eventID,
        value: !(signups[modID] !== null),
      },
    });
    setSignups({ ...signups, [modID]: signups[modID] === null ? 0 : null });
  };
  const toggle = (modID, modConstraint, modName) => {
    if (!isOpenForSignup) return <ScheduledTime />;
    if (eventHasEnded) return;
    if (!isEligible(modID, modConstraint)) return <Blocked />;
    if (!event.hasCheckedIn) return;
    if (!(event.mods && event.mods[modID])) return;

    return (
      <Toggle
        onChange={() => toggleSignup(modID, modName)}
        checked={signups[modID] !== undefined && signups[modID] !== null}
        disabled={!isToggleable(modID)}
      />
    );
  };
  const position = modID => {
    if (!(event.mods && event.mods[modID])) return '...';

    const mod = event.mods[modID];

    if (!isEligible(modID, mod.constraint)) return;
    if (mod.admittance_position) {
      if (mod.admittance_position > mod.participation_limit) {
        const waitlist = mod.admittance_position - mod.participation_limit;
        return (
          <React.Fragment>
            <div className={styles.waitlist}>{`Wait #${waitlist}`}</div>
            <div>{`${mod.participation_limit} Limit`}</div>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <div
              className={styles.seated}
            >{`Seat #${mod.admittance_position}`}</div>
            <div>{`${mod.participation_limit} Limit`}</div>
          </React.Fragment>
        );
      }
    } else {
      return (
        <React.Fragment>
          <span
            className={[
              mod.participants_count > mod.participation_limit &&
                styles.overLimit,
            ].join(' ')}
          >
            {mod.participants_count}
          </span>
          &nbsp;/&nbsp;
          <span>{mod.participation_limit}</span>
        </React.Fragment>
      );
    }
  };
  const eligibleCharacters = (modID, constraint) => {
    if (!(constraint && constraint.length > 0)) return true;
    if (!(event.mods && event.mods[modID])) return true;

    return event.mods[modID].signupable_characters;
  };
  const isEligible = (modID, constraint) =>
    eligibleCharacters(modID, constraint) === true ||
    (Array.isArray(eligibleCharacters(modID, constraint)) &&
      eligibleCharacters(modID, constraint).length > 0);

  const renderConstraint = constraint => {
    if (!(constraint && constraint.length > 0)) return;

    return (
      <div>
        <span>Required Skill:&nbsp;</span>
        {constraint.map(x => (
          <span
            className={[
              styles.constraint,
              styles[skills[remoteSkills[x.skill_id].key].category],
            ].join(' ')}
            key={x.skill_id}
          >
            {skills[remoteSkills[x.skill_id].key].name}
          </span>
        ))}
      </div>
    );
  };
  const renderEligibleCharacters = (modID, constraint) => {
    const characters = eligibleCharacters(modID, constraint);

    if (characters === true) return;
    return (
      <div
        className={[
          styles.eligibleCharacters,
          characters.length === 0 && styles.notEligible,
        ].join(' ')}
      >
        {characters.length === 0
          ? 'No eligible Characters'
          : 'Eligible Characters: '}
        {characters.map(x => x.name).join(', ')}
      </div>
    );
  };

  const renderModParticipants = ({
    constraint,
    participantsCount,
    participationLimit,
    participants,
  }) => {
    if (!participants) return;
    if (participantsCount === 0) return;
    if (constraint && constraint.length > 0 && participants.length === 0)
      return;

    const sorted = participants.sort((a, b) =>
      compareAsc(parseISO(a.admitted_at), parseISO(b.admitted_at)),
    );
    const seated = sorted.slice(0, participationLimit);
    const waitlisted = sorted.slice(participationLimit, participantsCount);
    const isSelf = x => user.id === x.event_attendee.user.id;

    if (seated.length === 0 && waitlisted.length === 0) return;
    return (
      <div className={styles.participants}>
        <div className={styles.seated}>
          <div className={styles.title}>Seated</div>
          <div className={styles.content}>
            {seated.map(x => (
              <div
                key={x.event_attendee.user.id}
                className={[styles.row, isSelf(x) && styles.self].join(' ')}
              >
                #{`${x.event_attendee.user.id} - `}
                {[
                  x.event_attendee.user.first_name,
                  x.event_attendee.user.last_name,
                ]
                  .join(' ')
                  .trim()}
                <div className={styles.floating}>
                  #{`${x.event_attendee.user.id} - `}
                  {[
                    x.event_attendee.user.first_name,
                    x.event_attendee.user.last_name,
                  ]
                    .join(' ')
                    .trim()}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.waitlisted}>
          {(!modEnableWaitlist || waitlisted.length > 0) && (
            <React.Fragment>
              <div className={styles.title}>
                {modEnableWaitlist ? 'Waitlisted' : 'Waitlist Disabled'}
              </div>
              <div className={styles.content}>
                {waitlisted.map(x => (
                  <div
                    key={x.event_attendee.user.id}
                    className={[styles.row, isSelf(x) && styles.self].join(' ')}
                  >
                    #{`${x.event_attendee.user.id} - `}
                    {[
                      x.event_attendee.user.first_name,
                      x.event_attendee.user.last_name,
                    ]
                      .join(' ')
                      .trim()}
                    <div className={styles.floating}>
                      #{`${x.event_attendee.user.id} - `}
                      {[
                        x.event_attendee.user.first_name,
                        x.event_attendee.user.last_name,
                      ]
                        .join(' ')
                        .trim()}
                    </div>
                  </div>
                ))}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };

  const renderMod = mod => (
    <div
      key={mod.id}
      className={[
        styles.mod,
        !isEligible(mod.id, mod.constraint) && styles.notEligible,
      ].join(' ')}
    >
      <div className={styles.left}>
        <div className={styles.toggle}>
          {toggle(mod.id, mod.constraint, mod.name)}
        </div>
        <div className={styles.position}>{position(mod.id)}</div>
      </div>
      <div className={styles.right}>
        <div className={styles.modInfo}>
          <div className={styles.modName}>{mod.name}</div>
          <div>{mod.timeframe}</div>
          <div className={styles.modConstraint}>
            {renderConstraint(mod.constraint)}
            {renderEligibleCharacters(mod.id, mod.constraint)}
          </div>
          {mod.description && mod.description.length > 0 && (
            <div className={styles.modDescription}>
              {mod.description.split(/\n/).map(x => (
                <div key={uuid.v1()}>{x}</div>
              ))}
            </div>
          )}
          <div className={styles.modParticipants}>
            {renderModParticipants({
              constraint: mod.constraint,
              participantsCount: mod.participants_count,
              participationLimit: mod.participation_limit,
              participants:
                event.mods[mod.id] && event.mods[mod.id].filtered_signups,
            })}
          </div>
        </div>
      </div>
    </div>
  );

  const renderScheduleBlock = () =>
    !isOpenForSignup && (
      <div className={styles.scheduler}>
        <div className={styles.icon}>
          <ScheduledTime />
        </div>
        {event.modSignupOpensAt ? (
          <div className={styles.text}>
            Mod Signup will be available on&nbsp;
            {format(parseISO(event.modSignupOpensAt), 'E, M/d h:mm aa')}
            &nbsp;
            {`(in ${formatDistanceToNow(
              parseISO(event.modSignupOpensAt, { addSuffix: true }),
            )})`}
          </div>
        ) : (
          <div className={styles.text}>
            Mod Signup is not available for this Event
          </div>
        )}
      </div>
    );

  const renderCheckinBlock = () =>
    !event.hasCheckedIn && (
      <div className={styles.scheduler}>
        <div className={styles.icon}>
          <BlockedCheckin />
        </div>
        <div className={styles.text}>
          Please Checkin first before signing up for Mods
        </div>
      </div>
    );

  const renderGlobalLimit = () => {
    if (globalSignupCount === 0) return;

    const quantifier = event.modMaxSignup
      ? `${globalSignupCount}/${event.modMaxSignup}`
      : globalSignupCount;
    const warningClass =
      event.modMaxSignup &&
      globalSignupCount > event.modMaxSignup &&
      styles.over;
    return (
      <span
        className={
          event.modMaxSignup &&
          globalSignupCount > event.modMaxSignup &&
          styles.over
        }
      >
        {`Signed up to ${quantifier} Mods`}
      </span>
    );
  };

  const renderMods = Object.keys(event.modSections)
    .filter(x => event.modSections[x].mods.length > 0)
    .filter(x => event.modSections[x].signup_limit > 0)
    .map(x => (
      <div className={styles.modSection} key={x}>
        <div className={styles.modSectionHeader}>
          <div
            className={styles.expander}
            onClick={() => dispatch({ type: 'TOGGLE_MOD_SECTION', payload: x })}
          >
            {ui.modSections[x] === 'expanded' ? <ExpandLess /> : <ExpandMore />}
          </div>
          <div className={styles.modSectionTitle}>
            {event.modSections[x].name}
          </div>
          <div className={styles.modSectionLimit}>
            {signupCount(event.modSections[x].id)}/
            {event.modSections[x].signup_limit}
            &nbsp;
            {event.modSections[x].signup_limit === 1 ? 'Mod' : 'Mods'}
            &nbsp;limit
          </div>
        </div>
        <Transition in={ui.modSections[x] === 'expanded'} timeout={300}>
          {state => (
            <div className={[styles.wrapper, styles[state]].join(' ')}>
              <div className={styles.mods}>
                {event.modSections[x].mods
                  .filter(x => x.participation_limit > 0)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(mod => renderMod(mod))}
              </div>
            </div>
          )}
        </Transition>
        <Transition in={ui.modSections[x] !== 'expanded'} timeout={300}>
          {state => (
            <div className={[styles.inverseWrapper, styles[state]].join(' ')}>
              <div
                className={styles.collapsedNote}
                onClick={() =>
                  dispatch({ type: 'TOGGLE_MOD_SECTION', payload: x })
                }
              >
                {`-- ${event.modSections[x].mods.length} collapsed Mods --`}
              </div>
            </div>
          )}
        </Transition>
      </div>
    ));

  useEffect(() => {
    const id = event.id || event.eventID;
    if (!id) return;
    dispatch({ type: 'FETCH_MOD_LIST', payload: id });
  }, [event.id, event.eventID, dispatch, user]);

  useEffect(() => {
    if (!event.mods) return;
    localizeSignups(event.mods);
  }, [event.mods]);

  if (isAssisted) return null;
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>Mod Signup</div>
        <div className={styles.globalLimit}>{renderGlobalLimit()}</div>
      </div>
      {renderCheckinBlock()}
      {renderScheduleBlock()}
      <div className={styles.description}>
        {(event.modDescription || '').split(/\n/).map(x => (
          <div key={uuid.v1()}>{x}</div>
        ))}
      </div>
      <div className={styles.modContent}>{renderMods}</div>
    </div>
  );
};
