import { all, put, select, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

function* resetPassword() {
  try {
    yield put({ type: 'RESET_PASSWORD_PENDING' });
    const username = yield select(state => state.ui.modal.current.username);
    yield axios.post('/users/reset_password', { username });
    yield put({ type: 'RESET_PASSWORD_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'RESET_PASSWORD_ERROR',
      payload: error.response.data.message,
    });
  }
}

function* verifyResetPasswordToken({ payload }) {
  try {
    const res = yield axios.get(`/users/check_reset_token?token=${payload}`);
    yield put({ type: 'TOKEN_VERIFIED', payload: { userID: res.data.id } });
  } catch (error) {
    yield put({ type: 'TOKEN_INVALID' });
  }
}

function* updatePassword({ payload }) {
  try {
    //yield put({ type: 'PASSWORD_UPDATED' });
    //yield put({ type: 'PASSWORD_UPDATE_ERROR', payload: 'uh error' })
    yield axios.post('/users/update_password', {
      token: payload.token,
      password: payload.password,
    });
    yield put({ type: 'PASSWORD_UPDATED' });
    yield put({ type: 'UPDATE_RESULT', payload: 'passwordUpdated' });
  } catch (error) {
    yield put({
      type: 'UPDATE_MODAL_ERROR',
      payload: error.response.data.message,
    });
  }
}

function* watchResetPassword() {
  yield takeLatest('RESET_PASSWORD', resetPassword);
}

function* watchVerifyResetPasswordToken() {
  yield takeLatest('VERIFY_RESET_PASSWORD_TOKEN', verifyResetPasswordToken);
}

function* watchUpdatePassword() {
  yield takeLatest('UPDATE_PASSWORD', updatePassword);
}

export default function* () {
  yield all([
    watchResetPassword(),
    watchVerifyResetPasswordToken(),
    watchUpdatePassword(),
  ]);
}
