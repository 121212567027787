import React from 'react';
import {
  differenceInDays,
  isBefore,
  parseISO,
  startOfMonth,
  subMonths,
} from 'date-fns';
import styles from './PunchCard.module.scss';

const now = new Date();
const firstDateOfThisMonth = startOfMonth(now);
const lineThicknessPercentage = 0.5;
const monthBorders = [
  subMonths(firstDateOfThisMonth, 5),
  subMonths(firstDateOfThisMonth, 4),
  subMonths(firstDateOfThisMonth, 3),
  subMonths(firstDateOfThisMonth, 2),
  subMonths(firstDateOfThisMonth, 1),
  firstDateOfThisMonth,
];
const monthMarkerPositions = monthBorders.map(
  x => 100 + (100 * differenceInDays(x, now)) / 180,
);
const linePositions = monthMarkerPositions
  .map(x => [
    `#333 ${x - lineThicknessPercentage}%`,
    `#555 ${x}%`,
    `#333 ${x + lineThicknessPercentage}%`,
  ])
  .flat()
  .join(', ');

export default ({ data, isTravelers }) => {
  const punchPosition = date =>
    `${100 + (100 * differenceInDays(parseISO(date), now)) / 180}%`;

  const punchDates = () =>
    data
      .filter(x => isBefore(parseISO(x.event.starts_at), now))
      .map((x, i) => (
        <div
          className={styles.holeContainer}
          style={{ top: `-${i * 15}px` }}
          key={x.event.id}
        >
          <div
            className={styles.flat}
            style={{
              width: punchPosition(x.event.starts_at),
            }}
          />
          <div
            className={[
              styles.hole,
              x.event.kind === 'national' && styles.rhombus,
              x.kind === 'travel_game' && !isTravelers && styles.muted,
            ].join(' ')}
            title={x.event.name}
          />
        </div>
      ));
  return (
    <div className={styles.container}>
      <div
        className={styles.base}
        style={{
          background: `linear-gradient(90deg, #333, ${linePositions})`,
        }}
      >
        {punchDates()}
      </div>
    </div>
  );
};
