import React, { useState, useRef } from 'react';
import styles from './DebugReset.module.scss';

export default () => {
  const confirmLeft = useRef(null);
  const confirmRight = useRef(null);
  const [debugState, setDebugState] = useState('idle');

  const handleClearClick = () => {
    if (confirmLeft.current.checked && confirmRight.current.checked) {
      localStorage.clear();
      sessionStorage.clear();
      setDebugState('resetting');
      window.location.reload();
    }
  };

  const getText = () => {
    switch (debugState) {
      case 'idle':
        return (
          <React.Fragment>
            <div>Debug: Clear Local Data</div>
            <div className={styles.small}>(Check both boxes to confirm)</div>
          </React.Fragment>
        );
      default:
        return <div>Resetting page...</div>;
    }
  };

  return (
    <div className={styles.debug}>
      <div>
        <input type='checkbox' ref={confirmLeft} onClick={handleClearClick} />
      </div>
      <div className={styles.instructions}>{getText()}</div>
      <div>
        <input type='checkbox' ref={confirmRight} onClick={handleClearClick} />
      </div>
    </div>
  );
};
