import { fromPairs } from 'lodash';

export const initialState = {
  emailAddress: null,
  name: null,
  id: null,
  session: null,
  branchId: null,
  role: null,
  membership: null,
  impersonatee: {},
  isEditorVisible: false,
  isUpdating: false,
  isError: false,
  lastEditUserID: null,
  isSuccessful: null,
  caps: {},
  eventLogs: {},
  branchTransfers: [],
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'SYNC_USER':
    case 'SYNC_USER_SHALLOW':
      return {
        ...state,
        ...payload,
        isEditorVisible: state.isEditorVisible,
        lastEditUserID: state.lastEditUserID,
        impersonatee: payload.impersonatee || state.impersonatee,
      };
    case 'SYNC_IMPERSONATEE':
      return {
        ...state,
        impersonatee: payload.impersonatee,
        lastEditUserID: payload.impersonatee.id || state.lastEditUserID,
      };
    case 'DEBUG_EXPIRE_TOKEN':
      return {
        ...state,
        session: {
          ...state.session,
          expiration: 0,
        },
      };
    case 'HIDE_USER_EDITOR':
      return {
        ...state,
        isEditorVisible: false,
      };
    case 'SHOW_USER_EDITOR':
      return {
        ...state,
        isEditorVisible: true,
        lastEditUserID: payload,
      };
    case '_DEBUG_FORCE_OPEN_EDITOR':
      return {
        ...state,
        isEditorVisible: true,
        lastEditUserID: payload,
      };
    case 'TOGGLE_USER_EDITOR':
      return {
        ...state,
        lastEditUserID: payload,
        isEditorVisible: state.lastEditUserID
          ? state.lastEditUserID === payload
            ? !state.isEditorVisible
            : true
          : !state.isEditorVisible,
      };
    case 'FETCH_USER_EVENT_LOGS_SUCCEEDED':
      if (payload.fragment === 'impersonatee') {
        return {
          ...state,
          impersonatee: {
            ...state.impersonatee,
            eventLogs: fromPairs(
              payload.data.map(x => [
                x.id,
                {
                  event: x.event,
                  logs: x.logs,
                },
              ]),
            ),
          },
        };
      }

      return {
        ...state,
        eventLogs: fromPairs(
          payload.data.map(x => [
            x.id,
            {
              event: x.event,
              logs: x.logs,
            },
          ]),
        ),
      };
    case 'UPDATE_USER_EVENT_LOGS_SUCCEEDED':
      if (payload.fragment === 'impersonatee') {
        return {
          ...state,
          impersonatee: {
            ...state.impersonatee,
            eventLogs: {
              ...state.impersonatee.eventLogs,
              [payload.data.id]: {
                ...state.impersonatee.eventLogs[payload.data.id],
                logs: payload.data.logs,
              },
            },
          },
        };
      }

      return {
        ...state,
        eventLogs: {
          ...state.eventLogs,
          [payload.data.id]: {
            ...state.eventLogs[payload.data.id],
            logs: payload.data.logs,
          },
        },
      };
    case 'CREATE_CAPS_SUCCEEDED':
    case 'UPDATE_CAPS_SUCCEEDED':
    case 'DELETE_CAPS_SUCCEEDED':
      if (payload.fragment === 'impersonatee') {
        return {
          ...state,
          impersonatee: {
            ...state.impersonatee,
            caps: fromPairs(payload.data.map(cap => [cap.id, cap])),
          },
        };
      }
      return {
        ...state,
        caps: fromPairs(payload.data.map(cap => [cap.id, cap])),
      };

    case 'UPDATE_CAPS':
      if (payload.fragment === 'impersonatee') {
        return {
          ...state,
          impersonatee: {
            ...state.impersonatee,
            caps: {
              ...state.impersonatee.caps,
              [payload.id]: {
                ...state.impersonatee.caps[payload.id],
                [payload.field]: payload.value,
              },
            },
          },
        };
      }
      return {
        ...state,
        caps: {
          ...state.caps,
          [payload.id]: {
            ...state.caps[payload.id],
            [payload.field]: payload.value,
          },
        },
      };

    case 'SIMPLE_USER_SEARCH_SUCCEEDED':
      return {
        ...state,
        simpleUserSearch: payload,
      };

    case 'UPDATE_USER':
    case 'ADD_MEMBERSHIP':
    case 'UPDATE_MEMBERSHIP':
    case 'REMOVE_MEMBERSHIP':
    case 'UPDATE_SELF_PASSWORD':
    case 'UPDATE_OTHER_PASSWORD':
      return {
        ...state,
        isUpdating: true,
        isError: false,
        isSuccessful: false,
      };
    case 'UPDATE_USER_SUCCEEDED':
    case 'ADD_MEMBERSHIP_SUCCEEDED':
    case 'UPDATE_MEMBERSHIP_SUCCEEDED':
    case 'REMOVE_MEMBERSHIP_SUCCEEDED':
    case 'UPDATE_SELF_PASSWORD_SUCCEEDED':
    case 'UPDATE_OTHER_PASSWORD_SUCCEEDED':
      return {
        ...state,
        isUpdating: false,
        isError: false,
        isSuccessful: true,
      };
    case 'UPDATE_USER_FAILED':
    case 'ADD_MEMBERSHIP_FAILED':
    case 'UPDATE_MEMBERSHIP_FAILED':
    case 'REMOVE_MEMBERSHIP_FAILED':
    case 'UPDATE_SELF_PASSWORD_FAILED':
    case 'UPDATE_OTHER_PASSWORD_FAILED':
      return {
        ...state,
        isUpdating: false,
        isError: true,
        isSuccessful: false,
      };
    default:
      return state;
  }
};
