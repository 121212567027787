import branches from '../gameData/branches';

export default () =>
  Object.keys(branches)
    .sort((a, b) => {
      return branches[a].shortName.toLowerCase() >
        branches[b].shortName.toLowerCase()
        ? 1
        : -1;
    })
    .map(branchID => ({
      value: branchID,
      label: branches[branchID].shortName,
    }));
