import React from 'react';
import { difference } from 'lodash';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import CheckBox from '@material-ui/icons/CheckBox';
import BlankCheckBox from '@material-ui/icons/CheckBoxOutlineBlank';
import branches from '../../utils/branches';
import rawBranches from '../../gameData/branches';
import styles from './Select.module.scss';

export default ({ user, selectedBranches, setSelectedBranches }) => {
  const sortedBranches = branches();
  const isAllSelected =
    difference(Object.keys(rawBranches), selectedBranches).length === 0;

  const checkbox = id => {
    const inclusive = selectedBranches.includes(id);

    if ((id === 'all' && isAllSelected) || inclusive)
      return <CheckBox className={styles.checkbox} />;
    return <BlankCheckBox className={styles.checkbox} />;
  };

  const handleChange = event => {
    if (
      selectedBranches.includes('all') &&
      !event.target.value.includes('all')
    ) {
      setSelectedBranches(['none']);
    } else if (
      !selectedBranches.includes('all') &&
      event.target.value.includes('all')
    ) {
      setSelectedBranches(
        Object.values(sortedBranches)
          .map(x => x.value)
          .concat('all'),
      );
    } else {
      if (
        event.target.value.length === sortedBranches.length &&
        !event.target.value.includes('all')
      ) {
        setSelectedBranches(
          Object.values(sortedBranches)
            .map(x => x.value)
            .concat('all'),
        );
      } else {
        setSelectedBranches(
          event.target.value.filter(x => !['all', 'none'].includes(x)),
        );
      }
    }
  };

  const listOfBranches = () =>
    [
      <MenuItem key='all' value='all' className={styles.option}>
        <div>
          {checkbox('all')}
          Select All
        </div>
      </MenuItem>,
    ].concat(
      Object.keys(sortedBranches).map(id => (
        <MenuItem
          key={id}
          value={sortedBranches[id].value}
          className={styles.option}
        >
          <div>
            {checkbox(sortedBranches[id].value)}
            {sortedBranches[id].label}
          </div>
        </MenuItem>
      )),
    );
  const renderSelectedBranches = () => {
    if (isAllSelected) return 'All Branches';
    if (selectedBranches.length === 1 && selectedBranches[0] === 'none')
      return 'Home Game only';
    return selectedBranches
      .filter(id => rawBranches[id])
      .map(id => rawBranches[id].shortName)
      .sort()
      .join(', ');
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Select
          autoWidth={true}
          className={styles.base}
          classes={{
            select: styles.select,
            icon: styles.icon,
          }}
          multiple
          value={selectedBranches}
          onChange={handleChange}
          input={<Input id='select-multiple-checkbox' />}
          renderValue={renderSelectedBranches}
          MenuProps={{
            className: styles.menu,
          }}
          disableUnderline
        >
          {listOfBranches()}
        </Select>
      </div>
    </div>
  );
};
