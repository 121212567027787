export default {
  animals: { name: 'Animals', verbatim: '', remoteID: 167 },
  aberrants: { name: 'Aberrants', verbatim: '', remoteID: 178 },
  faith: { name: 'Faith', verbatim: '', remoteID: 168 },
  mortisAmaranthine: {
    name: 'Mortis Amaranthine / Infection',
    verbatim: '',
    remoteID: 171,
  },
  lineages: { name: 'Lineages', verbatim: '', remoteID: 169 },
  medical: { name: 'Medical', verbatim: '', remoteID: 170 },
  nature: { name: 'Nature', verbatim: '', remoteID: 172 },
  postFallHistory: { name: 'Post-Fall History', verbatim: '', remoteID: 173 },
  preFallHistory: { name: 'Pre-Fall History', verbatim: '', remoteID: 174 },
  raiders: { name: 'Raiders', verbatim: '', remoteID: 175 },
  undead: { name: 'Undead', verbatim: '', remoteID: 176 },
  wastelandScience: { name: 'Wasteland Science', verbatim: '', remoteID: 177 },
};
