import { fromPairs } from 'lodash';

export default ({ state, payload }) => {
  const userData = {
    id: payload.data.id,
    firstName: payload.data.first_name,
    lastName: payload.data.last_name,
    emailAddress: payload.data.email_address,
    branchId: payload.data.branch.id,
    branchTransfers: payload.data.branch_transfers,
    role: payload.data.role,
    flags: payload.data.flags,
    membership: payload.data.membership,
    memberships: payload.data.memberships,
    membershipValidUntil: payload.data.membership_expiration,
    isFirstEvent: payload.data.first_event,
    correctiveActions: payload.data.corrective_actions,
    ownedBranches: Object.keys(payload.data.owned_branches).map(
      x => payload.data.owned_branches[x].id,
    ),
    notes: payload.data.notes,
    caps: fromPairs(payload.data.caps.map(cap => [cap.id, cap])),
    genesisDate: payload.data.genesis_at,
    userReferrals: payload.data.user_referrals,
    inviter: payload.data.inviters[0],
  };
  if (payload.isImpersonating) {
    return {
      ...state.user,
      impersonatee: userData,
    };
  } else {
    return {
      ...state.user,
      ...userData,
      impersonatee: {},
    };
  }
};
