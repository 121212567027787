import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ItemTransactionTutorial.module.scss';

export default ({ page }) => {
  const content = useMemo(() => {
    switch (page) {
      case 'crafting':
        return (
          <div>
            Use this page to record Item production via:
            <ul>
              <li>Blueprint-based Crafting</li>
              <li>Checkin skills Buy List</li>
              <li>Conversion (e.g. Timber Shipment to High Grade Lumber)</li>
            </ul>
            If you need to Cure or Preserve existing Items, please use the
            Postal workflow.
            <br />
            If you need to produce Unique Items (e.g. chapter-specific or Plot
            Item) via Crafting, click the Item name and follow the instructions.
          </div>
        );
      case 'postal':
        return (
          <div>
            Use this page to record Item acquisition via:
            <ul>
              <li>Modroom Loot Drops</li>
              <li>Character-to-Postal Item Exchanges</li>
              <li>Curing or Preserving Procedure</li>
            </ul>
            If you need to issue Unique Items (e.g. chapter-specific or Plot
            Item), click the Item name designated to Players and follow the
            instructions.
          </div>
        );
      case 'trading':
        return (
          <div>
            <div>
              Use this page to record Item Exchanges between 2 Characters.
            </div>
            <br />
            <div>
              If you need to trade Unique Items, select the Item and Character
              first, then click the Item name to select Unique Items currently
              in their Inventory.
            </div>
            <br />
            <div>
              Item Exchange are restricted to inter-Player trade. I.e. it's not
              possible for 2 Characters belonging to the same Player to execute
              Item Exchange.
            </div>
          </div>
        );
    }
  }, [page]);

  const icon = useMemo(() => {
    switch (page) {
      case 'crafting':
        return 'tools';
      case 'postal':
        return 'mail-bulk';
      case 'trading':
        return 'exchange-alt';
    }
  }, [page]);

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className={styles.content}>{content}</div>
    </div>
  );
};
