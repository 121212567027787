import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import activeSessions from './ActiveSessions/reducer';
import branches from './Branches/reducers';
import character from './Character/reducers/index';
import items from './Items/reducer';
import localStorage from './LocalStorage/reducers';
import events from './Events/reducer';
import ui from './UI/reducers/index';
import user from './User/reducer';
import eventAdministration from './Events/Administration/reducer';
import eventSetup from './Events/Setup/reducer';
import eventCheckin from './Events/Checkin/reducer';
import eventStatistics from './Events/Statistics/reducer';
import staffs from './Staffs/reducer';

export default history =>
  combineReducers({
    activeSessions,
    branches,
    character,
    events,
    items,
    localStorage,
    router: connectRouter(history),
    ui,
    user,
    eventAdministration,
    eventSetup,
    eventCheckin,
    eventStatistics,
    staffs,
  });
