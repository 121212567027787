import branches from '../../gameData/branches';
export const initialState = Object.keys(branches);

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'SYNC_UI':
      return payload.selectedBranches || initialState;
    default:
      return state;
  }
};
