import { fromPairs } from 'lodash';
import SkillTable from '../../gameData/skillInitializer';

const localSkills = SkillTable().skills;
const { remoteSkills } = SkillTable();

export const toLocalSkills = payload => {
  const t = fromPairs(
    Object.keys(localSkills).map(x => [
      x,
      { levelAcquired: 0, achievementIsAcquired: false },
    ]),
  );

  payload
    .filter(x => remoteSkills[x])
    .forEach(x => {
      const { key, tier } = remoteSkills[x];

      if (tier <= 3 && tier >= t[key].levelAcquired) {
        t[key].levelAcquired = tier;
      } else if (tier === 4) {
        t[key].achievementIsAcquired = true;
      }
    });

  return t;
};

export const toRemoteSkills = skillState => {
  const t = [];
  Object.keys(skillState)
    .filter(
      x =>
        skillState[x].levelAcquired > 0 || skillState[x].achievementIsAcquired,
    )
    .forEach(x => {
      const { remoteID } = localSkills[x];
      for (let tier = 0; tier < skillState[x].levelAcquired; tier += 1) {
        if (remoteID && tier in remoteID && remoteID[tier])
          t.push(remoteID[tier]);
      }
      if (skillState[x].achievementIsAcquired) t.push(remoteID[3]);
    });

  return t;
};
