import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fromPairs, startCase } from 'lodash';
import { Transition } from 'react-transition-group';
import EventHeader from '../Checkin/EventHeader';
import EventLinks from '../Checkin/EventLinks';
import CharacterLoader from '../../utils/loader';
import BarChart from './BarChart';
import LineChart from './LineChart';
import Floater from '../../Shared/Floater/Floater';
import PieChart from './PieChart';
import StackedBarChart from './StackedBarChart';
import WindowedScrollbar from '../../Shared/WindowedScrollbar';
import history from '../../history';
import { isPrivileged as hasPrivilege } from '../../utils/user';
import remoteFaiths from '../../gameData/remoteFaiths';
import remoteLores from '../../gameData/remoteLores';
import remoteStrains from '../../gameData/remoteStrains';
import skillInitializer from '../../gameData/skillInitializer';
import branches from '../../gameData/branches';
import faiths from '../../gameData/faiths';
import lores from '../../gameData/lores';
import strains from '../../gameData/strains';
import styles from './Statistics.module.scss';

const TIER_NAME = {
  1: 'basic',
  2: 'proficient',
  3: 'master',
  4: 'achievement',
};
const TIER_ID = {
  basic: 1,
  proficient: 2,
  master: 3,
  achievement: 4,
};
export default ({ overrideBranchID }) => {
  const dispatch = useDispatch();
  const eventID = history.location.pathname.split(/\//).pop();
  const {
    user,
    userID,
    currentCharacterID,
    characterStorage,
    statistics,
    eventInfo,
  } = useSelector(state => ({
    user: state.user,
    userID: state.user.id,
    currentCharacterID: state.localStorage.currentCharacterID,
    characterStorage: state.localStorage.characterStorage,
    statistics: state.eventStatistics,
    eventInfo: state.eventStatistics.event,
  }));

  const [showFloater, setShowFloater] = useState(false);
  const [stackedFilterSort, setStackedFilterSort] = useState('category');
  const [stackedQuantitySort, setStackedQuantitySort] = useState('cumulative');
  const [currentModalTitle, setCurrentModalTitle] = useState('');
  const [modalContent, setModalContent] = useState({
    title: null,
    content: null,
  });
  const [drilldownID, setDrilldownID] = useState(null);
  const isPrivileged = hasPrivilege(user);
  const isBranch = overrideBranchID !== undefined;
  const isNational = overrideBranchID === 13;

  useEffect(() => {
    dispatch({ type: 'APP_LOADED' });
  }, [dispatch]);

  useEffect(() => {
    CharacterLoader({ characterStorage, currentCharacterID, dispatch });
  }, [characterStorage, currentCharacterID, dispatch]);

  useEffect(() => {
    if (!userID) return;
    if (overrideBranchID && overrideBranchID === -1) return;

    dispatch({
      type: 'FETCH_EVENT_STATISTICS',
      payload: {
        type: isBranch ? 'branchID' : 'eventID',
        value: overrideBranchID || eventID,
        isNational,
      },
    });
  }, [userID, eventID, overrideBranchID, dispatch, isBranch, isNational]);

  useEffect(() => {
    if (isNational) return;
    if (!drilldownID) return;
    const drilldownGroup = statistics.reverseStatistics[drilldownID.title];
    const drilldownData = drilldownGroup[drilldownID.id];
    const hasData = drilldownGroup && drilldownData;

    setModalContent({
      ...modalContent,
      title: hasData
        ? `${currentModalTitle}: ${drilldownData.length}`
        : 'Updating...',
      content: hasData ? modalText(drilldownData) : 'Fetching data...',
    });

    !hasData &&
      dispatch({
        type: 'FETCH_EVENT_DETAILED_STATISTICS',
        payload: {
          type: isBranch ? 'branchID' : 'eventID',
          attribute: drilldownID.title,
          value: drilldownID.id,
          key: overrideBranchID || eventInfo.id,
        },
      }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statistics.reverseStatistics, isNational, isBranch, dispatch]);

  useEffect(() => {
    if (isNational) setShowFloater(false);
  }, [isNational]);

  const modalTitle = (title, data) =>
    title === 'skills'
      ? `${data.indexValue} - ${startCase(data.id)}`
      : `${data.id}`;

  const modalText = data =>
    data.map(row => (
      <div className={styles.reverse} key={row.id}>
        <div className={styles.playerName}>
          {[row.user.first_name, row.user.last_name].join(' ').trim()}
        </div>
        <div className={styles.characterName}>{row.name}</div>
      </div>
    ));

  const getReverseData = ({ title, point }) => {
    const reverseStatistic = statistics.reverseStatistics[title];
    let reverseData;
    let attributeID;

    if (title === 'skills') {
      const remoteIDs = point.data.remoteID;
      attributeID = remoteIDs[TIER_ID[point.id] - 1];
      reverseData = reverseStatistic ? reverseStatistic[attributeID] : null;
    } else {
      const remoteID = point.remoteID;
      attributeID = remoteID;
      reverseData = reverseStatistic ? reverseStatistic[remoteID] : null;
    }

    return {
      reverseStatistic,
      reverseData,
      attributeID,
    };
  };

  const onClick = (title, point) => {
    if (isNational) return;
    const { reverseStatistic, reverseData, attributeID } = getReverseData({
      title,
      point,
    });

    setShowFloater(true);
    setDrilldownID({ title, id: attributeID });
    setCurrentModalTitle(modalTitle(title, point));

    if (reverseStatistic && reverseData) {
      setModalContent({
        ...modalContent,
        title: `${currentModalTitle}: ${point.value}`,
        content: modalText(reverseData),
      });
    } else {
      setModalContent({
        ...modalContent,
        title: `${currentModalTitle}: ${point.value}`,
        content: 'Fetching data...',
      });
      dispatch({
        type: 'FETCH_EVENT_DETAILED_STATISTICS',
        payload: {
          type: isBranch ? 'branchID' : 'eventID',
          attribute: title,
          value: attributeID,
          key: overrideBranchID || eventInfo.id,
        },
      });
    }
  };

  const closeModal = () => setShowFloater(false);

  const barTransformer = stat => {
    if (!statistics[stat]) return [];

    return Object.keys(statistics[stat])
      .map(x => ({ bin: x, count: statistics[stat][x] }))
      .sort((a, b) => parseInt(a.bin, 10) - parseInt(b.bin, 10));
  };
  const bar = stat => (
    <div className={styles.chart}>
      <BarChart
        data={barTransformer(stat)}
        title={stat}
        keys={['count']}
        indexBy='bin'
      />
    </div>
  );
  const pieTransformer = (type, labelTransformer) => {
    if (!statistics[type]) return [];

    return Object.keys(statistics[type]).map(x => ({
      id: labelTransformer(x),
      label: labelTransformer(x),
      value: statistics[type][x],
      remoteID: x,
    }));
  };
  const pie = (type, labelTransformer) => (
    <div className={[styles.chart, styles.pie].join(' ')}>
      <PieChart
        data={pieTransformer(type, labelTransformer)}
        title={type}
        onClick={onClick}
      />
    </div>
  );
  const lineTransformer = type => {
    if (!statistics[type]) return [];

    const stat = statistics[type];
    const vals = Object.values(stat);
    const max = vals[vals.length - 1];
    return [
      {
        id: type,
        data: Object.keys(stat).map(x => ({
          x: x,
          y: stat[x],
          max: max,
        })),
      },
    ];
  };
  const line = type => (
    <div className={[styles.chart, styles.cdf].join(' ')}>
      <LineChart data={lineTransformer(type)} title={type} />
    </div>
  );
  const stackedBarTransformer = type => {
    if (!statistics[type]) return [];
    const t = skillInitializer();
    const localSkills = t.skills;
    const aggregates = statistics[type];
    const data = Object.keys(localSkills).map(x =>
      Object.assign(
        {
          skill: localSkills[x].name,
          category: localSkills[x].category,
          t4: localSkills[x].t4,
          maxTier: localSkills[x].maxTier,
          remoteID: localSkills[x].remoteID,
        },
        fromPairs(
          localSkills[x].remoteID.map((t, i) => [
            TIER_NAME[i + 1],
            aggregates[t] || 0,
          ]),
        ),
      ),
    );

    if (stackedFilterSort === 'category') return data.reverse();
    return data.sort((a, b) => {
      switch (stackedQuantitySort) {
        case 'basic':
          return (a.basic || 0) - (b.basic || 0);
        case 'proficient':
          return (a.proficient || 0) - (b.proficient || 0);
        case 'master':
          return (a.master || 0) - (b.master || 0);
        case 'achievement':
          return (a.achievement || 0) - (b.achievement || 0);
        case 'cumulative':
          const acl =
            (a.basic || 0) +
            (a.proficient || 0) +
            (a.master || 0) +
            (a.achivement || 0);
          const bcl =
            (b.basic || 0) +
            (b.proficient || 0) +
            (b.master || 0) +
            (b.achivement || 0);

          return acl - bcl;
        default:
          return 0;
      }
    });
  };
  const stackedBar = type => (
    <div
      className={[
        styles.chart,
        styles.stackedBar,
        styles[stackedQuantitySort],
      ].join(' ')}
    >
      <StackedBarChart
        data={stackedBarTransformer(type)}
        title={type}
        onClick={onClick}
      />
    </div>
  );

  const configureStackFilter = (level, predicate) => {
    switch (level) {
      case 'stacked':
        setStackedFilterSort(predicate);
        if (predicate === 'category') setStackedQuantitySort('cumulative');
        return;
      case 'quantity':
        setStackedQuantitySort(predicate);
        return;
      default:
        return null;
    }
  };

  const stackedFilterClassNames = (level, predicate, state) =>
    [
      styles.static,
      styles.selectable,
      styles[predicate],
      styles[state],
      stackedFilterClassPredicate(level, predicate) && styles.selected,
    ].join(' ');

  const stackedFilterClassPredicate = (level, predicate) => {
    switch (level) {
      case 'stacked':
        return stackedFilterSort === predicate;
      case 'quantity':
        return stackedQuantitySort === predicate;
      default:
        return false;
    }
  };

  return (
    <div className={styles.container}>
      <Floater
        isOpen={showFloater}
        closeModal={closeModal}
        title={modalContent.title}
        content={modalContent.content}
      />
      {!isBranch && (
        <div className={styles.eventLinks}>
          {eventInfo ? (
            <EventHeader
              eventName={eventInfo.name}
              eventLocation={eventInfo.location.name}
              eventStartDate={eventInfo.starts_at}
              eventEndDate={eventInfo.ends_at}
              eventBranch={eventInfo.branch}
              nextEvent={eventInfo.next_event}
              prevEvent={eventInfo.prev_event}
              isPremiere={eventInfo.kind === 'premiere'}
              isPrivileged={isPrivileged}
            />
          ) : null}
          <EventLinks />
        </div>
      )}
      <WindowedScrollbar styles={styles} autoHeightMax={`calc(100vh - 164px)`}>
        <div className={styles.charts}>
          {bar('body')}
          {bar('mind')}
          {bar('resolve')}
          {bar('infection')}
          {pie('strains', x => strains[remoteStrains[x]].name)}
          {pie('faiths', x => faiths[remoteFaiths[x]].name)}
          {pie('lores', x => lores[remoteLores[x]].name)}
          {pie('residency', x =>
            !isBranch && !isNational && x === eventInfo.branch_id.toString()
              ? 'Local'
              : branches[x].shortName,
          )}
        </div>

        <div className={styles.charts}>{line('builds')}</div>
        <div className={[styles.charts, styles.withControls].join(' ')}>
          <div className={styles.sortGroup}>
            <div className={[styles.sorter, styles.tight].join(' ')}>
              <div className={styles.static}>Sort By:</div>
              {['category', 'quantity'].map(x => (
                <div
                  className={stackedFilterClassNames('stacked', x)}
                  onClick={() => configureStackFilter('stacked', x)}
                  key={`stacked-${x}`}
                >
                  {startCase(x)}
                </div>
              ))}
            </div>
            <Transition in={stackedFilterSort === 'quantity'} timeout={300}>
              {state => (
                <div
                  className={[
                    styles.sorter,
                    styles.secondGroup,
                    styles[state],
                  ].join(' ')}
                >
                  {[
                    'cumulative',
                    'basic',
                    'proficient',
                    'master',
                    'achievement',
                  ].map(x => (
                    <div
                      className={stackedFilterClassNames('quantity', x, state)}
                      onClick={() => configureStackFilter('quantity', x)}
                      key={`quantity-${x}`}
                    >
                      {startCase(x)}
                    </div>
                  ))}
                </div>
              )}
            </Transition>
          </div>
          <div className={styles.vis}>{stackedBar('skills')}</div>
        </div>
        <div style={{ height: '32px' }} />
      </WindowedScrollbar>
    </div>
  );
};
