import { all, select, put, takeLatest } from 'redux-saga/effects';
import { hasValidToken } from '../../utils/token';
import fetchUser from './fetchUser';

function* executeSessionChain({ payload }) {
  const authConfig = yield select(state => state.user.session);

  if (hasValidToken(authConfig)) {
    yield put({ type: 'FETCH_BEGIN' });
    try {
      yield fetchUser(authConfig, payload);
    } catch (error) {
      yield put({
        type: 'SESSION_ERROR',
        payload: error,
      });
    } finally {
      yield put({ type: 'FETCH_COMPLETE' });
    }
  }
}

function* watchImpersonateUser() {
  yield takeLatest('IMPERSONATE_USER', executeSessionChain);
}

function* watchSyncUser() {
  yield takeLatest('SYNC_USER', executeSessionChain);
}

export default function* () {
  yield all([watchImpersonateUser(), watchSyncUser()]);
}
