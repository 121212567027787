import { cloneDeep } from 'lodash';
import cleanUpOrphanedRemoteCharacters from './cleanUpOrphanedRemoteCharacters';

export default ({ currentCharacterID, storage }) => {
  let newCharacterID = currentCharacterID;
  const newStorage = cloneDeep(storage);
  cleanUpOrphanedRemoteCharacters({}, newStorage);

  if (!(newCharacterID in newStorage)) {
    newCharacterID = Object.keys(newStorage)[0] || null;
  }

  return { newCharacterID, newStorage };
};
