import { put, select } from 'redux-saga/effects';
import { hasValidToken } from './token';
import Toast from '../Shared/Toastify/Toast';

export function* upstream(remoteID, generator) {
  const authConfig = yield select(state => state.user.session);

  if (remoteID) {
    if (hasValidToken(authConfig)) {
      yield generator();
    } else {
      yield put({ type: 'DELETE_SESSION' });
      yield put({ type: 'NUKE_DUE_TO_TOKEN_EXPIRATION' });
    }
  } else if (authConfig && !hasValidToken(authConfig)) {
    // TODO(gbudiman): find non-hacky solution
    yield put({ type: 'DELETE_SESSION' });
    yield put({ type: 'NUKE_DUE_TO_TOKEN_EXPIRATION' });
  }
}

export function* updateUpstream(remoteID, generator, message) {
  yield upstream(remoteID, function* () {
    try {
      yield generator();
      yield put({ type: 'SAVE_UPSTREAM_SUCCEEDED' });
      if (message) Toast({ text: message, type: 'success', toastID: message });
    } catch (error) {
      Toast({ text: error.response.data.message, type: 'error' });
      yield put({ type: 'SAVE_UPSTREAM_FAILED' });
    }
  });
}
