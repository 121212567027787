import React from 'react';
import { startCase } from 'lodash';
import Staged from '@material-ui/icons/SettingsEthernet';
import Inactive from '@material-ui/icons/NotInterested';
import Retired from '@material-ui/icons/Hotel';
import GuideIcon from '@material-ui/icons/SupervisedUserCircle';
import styles from './Character.module.scss';
import faiths from '../gameData/remoteFaiths';
import remoteStrains from '../gameData/remoteStrains';
import strains from '../gameData/strains';
import { isMembershipActive } from '../utils/user';

export default ({ atcData, userData, eventData }) => {
  const characterData = atcData.character;
  const strainKey = remoteStrains[characterData.strain_id];
  const strain = strains[strainKey];
  const renderStatusIcon = status => {
    switch (status) {
      case 'staged':
        return <Staged className={styles.iconified} />;
      case 'inactive':
        return <Inactive className={styles.iconified} />;
      case 'retired':
        return <Retired className={styles.iconified} />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.persona}>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td className={[styles.text, styles.large].join(' ')}>
              {renderStatusIcon(characterData.status)}
              {characterData.name || 'Name: ________'}
            </td>
            <td
              className={[styles.text, styles.large, styles.rightAlign].join(
                ' ',
              )}
            >
              {`#${userData.id || '________'}`}
              {isMembershipActive(userData, Date.parse(eventData.startsAt))
                ? ' [AM]'
                : null}
            </td>
          </tr>
          <tr>
            <td className={[styles.text, styles.leftHalf].join(' ')}>
              {`${strain.lineage.name || 'Lineage: ________'} - ${
                strain.lineage.name ? strain.name : 'Strain: ________'
              }`}
            </td>
            <td className={[styles.text, styles.rightAlign].join(' ')}>
              {userData.role === 'guide' && (
                <GuideIcon className={styles.icon} />
              )}
              {[userData.first_name, userData.last_name].join(' ').trim() ||
                'Player Name: ________________________'}
            </td>
          </tr>
          <tr>
            <td className={[styles.text].join(' ')}>
              {startCase(faiths[characterData.faith_id]) || 'Faith: ________'}
            </td>
            <td className={[styles.text, styles.rightAlign].join(' ')}>
              {`${atcData.kind !== 'home_game' ? 'Traveler' : 'Local'}: ${
                userData.branch.name || 'Branch: ________'
              }`}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
