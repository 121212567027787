import { all, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { clientMetadata } from '../../utils/client';
import { CREATE_SESSION_SUCCESS, CREATE_SESSION_FAILURE } from '../types';

function* login({ payload }) {
  try {
    const res = yield axios.post('/users/generate_token', {
      username: payload.username,
      password: payload.password,
      os: clientMetadata.os,
      browser: clientMetadata.browser,
      hardware: clientMetadata.hardware,
    });

    yield put({ type: CREATE_SESSION_SUCCESS, payload: res.data });
    yield put({ type: 'TOGGLE_POPPER' });
    yield put({ type: 'CLEAR_MODAL' });
    yield put({ type: 'CLEANUP_SEARCH_RESULT' });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    yield put({ type: CREATE_SESSION_FAILURE, payload: errorMessage });
    yield put({
      type: 'UPDATE_MODAL_ERROR',
      payload: errorMessage,
    });
  }
}

function* watchLogin() {
  yield takeLatest('LOGIN', login);
}

export default function* () {
  yield all([watchLogin()]);
}
