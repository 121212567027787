import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce, delay, startCase } from 'lodash';
import RestrictedView from '@material-ui/icons/VisibilityOff';
import ItemDetails from './ItemDetails';
import { isPrivileged, isGuideWithLogisticsPrivilege } from '../utils/user';
import history from '../history';
import styles from './ItemSearch.module.scss';

export default ({
  filters = [],
  placeholder,
  renderItemDetails,
  isLocal,
  isTangible,
  includeSynonyms,
  includeDescription,
  align,
}) => {
  const dispatch = useDispatch();
  const autofocusRef = useRef(null);
  const rawIdFromUrl = history.location.search;
  const idFromUrl = rawIdFromUrl.match(/id=(\d+)/);
  const matchedIdFromUrl = idFromUrl && idFromUrl[1];
  const [localItemId, setLocalItemId] = useState(null);
  const [hasSearchPerformed, setHasSearchPerformed] = useState(false);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const { items, user } = useSelector(state => ({
    items: state.items,
    user: state.user,
  }));
  const {
    isSearching,
    lastExecutedSearch,
    searchResults: globalSearchResults,
    localSearchResults,
    selectedItemId: globalSelectedItemId,
    cachedItems,
  } = items;
  const searchResults = isLocal ? localSearchResults : globalSearchResults;
  const selectedItemId = isLocal ? localItemId : globalSelectedItemId;
  const [searchTerm, setSearchTerm] = useState('');

  const hasElevatedAccess =
    isPrivileged(user) || isGuideWithLogisticsPrivilege(user);
  const setUrl = id => history.push(`/items?id=${id}`);
  const fetchItem = useCallback(
    id =>
      dispatch({
        type: isLocal
          ? 'FETCH_ITEM_WITHOUT_SELECTED_ITEM_CHANGE'
          : 'FETCH_ITEM',
        payload: id,
      }),
    [dispatch, isLocal],
  );
  const fireSearch = (query, setFocus, isTaxonomy) => {
    if (query.trim().length < 3) return;

    dispatch({
      type: 'EXECUTE_ITEM_SEARCH',
      payload: {
        query: query.trim(),
        isTaxonomy,
        filters,
        isLocal,
        isTangible,
      },
    });
    setHasSearchPerformed(true);
    setShowSearchResult(true);
    if (setFocus) autofocusRef.current.focus();
  };
  const delayedSearch = useCallback(
    debounce(term => fireSearch(term), 250),
    [],
  );
  const maybeExecuteSearch = term => {
    setSearchTerm(term);
    delayedSearch(term);
  };
  const renderSearchResult = x => (
    <div
      key={x.id}
      className={styles.searchResult}
      onClick={() => {
        setLocalItemId(x.id);
        renderItemDetails ? fetchItem(x.id) : setUrl(x.id);
      }}
    >
      <div className={styles.itemName}>
        {x.visibility !== 'publicly_visible' && (
          <RestrictedView className={styles.restrictedIcon} />
        )}
        <div className={styles.name}>{x.name}</div>
        <div className={styles.kind}>
          {startCase(x.kind)}
          {x.grade !== 'ungraded' && `: ${startCase(x.grade)}`}
        </div>
      </div>
      {includeSynonyms && (
        <div className={styles.synonym}>
          <span className={styles.lead}>Synonym: </span>
          {x.synonym}
        </div>
      )}
      {includeDescription && x.pg_search_highlight && (
        <div
          className={styles.matches}
          dangerouslySetInnerHTML={{ __html: x.pg_search_highlight }}
        />
      )}
    </div>
  );

  const renderSearchHeader = isSearching
    ? 'Searching...'
    : `${searchResults.length} result${
        searchResults.length !== 1 ? 's' : ''
      } found`;

  const renderSearchResults = () => {
    if (isSearching) return '';
    if (searchResults.length === 0 && lastExecutedSearch.length > 0) return '';
    return searchResults.map(x => renderSearchResult(x));
  };

  useEffect(() => {
    dispatch({ type: 'APP_LOADED' });
  }, [dispatch]);

  useEffect(() => {
    autofocusRef.current.focus();
  }, []);

  useEffect(() => {
    const parsedIdFromUrl = parseInt(matchedIdFromUrl, 10);
    if (Number.isNaN(parsedIdFromUrl)) return;

    fetchItem(parsedIdFromUrl);
  }, [fetchItem, matchedIdFromUrl]);

  useEffect(() => {
    if (!renderItemDetails) return;
    renderItemDetails(cachedItems[selectedItemId]);

    if (
      isLocal &&
      cachedItems[selectedItemId] &&
      cachedItems[selectedItemId].data
    )
      setLocalItemId(null);
  }, [cachedItems, renderItemDetails, selectedItemId, isLocal]);

  return (
    <div
      className={[
        styles.container,
        renderItemDetails && styles.subComponent,
        isLocal && styles.lightweight,
        align && styles[align],
        renderItemDetails && styles.multiTenant,
      ].join(' ')}
    >
      <div className={styles.searchControl}>
        <input
          type='text'
          placeholder={placeholder || 'Search Items...'}
          className={styles.searchBox}
          value={searchTerm}
          onChange={evt => maybeExecuteSearch(evt.target.value)}
          onKeyDown={evt => evt.keyCode === 13 && fireSearch(searchTerm)}
          onBlur={() => delay(() => setShowSearchResult(false), 250)}
          onFocus={() => hasSearchPerformed && setShowSearchResult(true)}
          ref={autofocusRef}
        />
      </div>

      <div
        className={[
          styles.searchResults,
          !showSearchResult && styles.hidden,
        ].join(' ')}
      >
        <div className={styles.searchHeader}>{renderSearchHeader}</div>
        <div className={styles.searchContents}>{renderSearchResults()}</div>
      </div>
      {!renderItemDetails && (
        <ItemDetails
          cachedItem={cachedItems[selectedItemId]}
          setUrl={setUrl}
          fireSearch={fireSearch}
          hasElevatedAccess={hasElevatedAccess}
        />
      )}
    </div>
  );
};
