import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fromPairs } from 'lodash';
import { compareAsc, parseISO, format } from 'date-fns';
import {
  isPrivileged as hasPrivilege,
  isAdmin as hasAdminPrivilege,
} from '../../utils/user';
import uuid from 'uuid';
import TextArea from '../../Shared/FormComponents/TextArea';
import styles from './EventLogs.module.scss';

export default ({ user, nonceID, fragment, rootUser }) => {
  const dispatch = useDispatch();
  const isAdmin = hasAdminPrivilege(rootUser);
  const isPrivileged = hasPrivilege(rootUser);
  const [isFetching, setIsFetching] = useState(true);
  const [userEventLogs, setUserEventLogs] = useState({});
  const isDirtyLogs = id =>
    userEventLogs[id] !== (user.eventLogs[id].logs || '');

  const hasEditAccess = branchID =>
    isAdmin || (isPrivileged && rootUser.ownedBranches.includes(branchID));
  const updateLogs = (id, val) =>
    setUserEventLogs({
      ...userEventLogs,
      [id]: val,
    });
  const updateUpstream = (id, eventID, eventName) =>
    isDirtyLogs(id) &&
    dispatch({
      type: 'UPDATE_USER_EVENT_LOGS',
      payload: {
        id,
        eventID,
        eventName,
        value: userEventLogs[id],
        fragment,
      },
    });

  const renderEventLogs = (id, frame) => (
    <div className={styles.log} key={id}>
      <div className={styles.eventHeader}>
        <div className={styles.eventName}>{frame.event.name}</div>
        <div className={styles.eventDate}>
          {format(parseISO(frame.event.starts_at), 'yyyy MMM dd')}
        </div>
      </div>
      {hasEditAccess(frame.event.branch_id) ? (
        <div>
          <TextArea
            content={userEventLogs[id]}
            placeholder='Use this space to record logs, inventory, etc...'
            handleUpdate={evt => updateLogs(id, evt.target.value)}
            handleBlur={() =>
              updateUpstream(id, frame.event.id, frame.event.name)
            }
          />
        </div>
      ) : (
        <div className={styles.readOnlyLogs}>
          {userEventLogs[id] ? (
            userEventLogs[id]
              .split(/\n/)
              .map(x => <div key={uuid.v1()}>{x}</div>)
          ) : (
            <div className={styles.noLogs}>No Logs recorded...</div>
          )}
        </div>
      )}
    </div>
  );

  useEffect(() => {
    setIsFetching(true);

    dispatch({
      type: 'FETCH_USER_EVENT_LOGS',
      payload: {
        id: nonceID,
        fragment: fragment,
      },
    });
  }, [nonceID, fragment, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!(user && user.eventLogs)) return;

    setUserEventLogs(
      fromPairs(
        Object.keys(user.eventLogs).map(attendeeID => [
          attendeeID,
          user.eventLogs[attendeeID].logs || '',
        ]),
      ),
    );

    setIsFetching(false);
  }, [user.eventLogs]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      {isFetching || !user.eventLogs
        ? 'Fetching Event Logs...'
        : Object.keys(user.eventLogs).length > 0
        ? Object.keys(user.eventLogs)
            .sort((a, b) =>
              compareAsc(
                parseISO(user.eventLogs[b].event.starts_at),
                parseISO(user.eventLogs[a].event.starts_at),
              ),
            )
            .map(x => renderEventLogs(x, user.eventLogs[x]))
        : 'No Event Attendance Records'}
    </div>
  );
};
