import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode-react';
import { format, parseISO } from 'date-fns';
import styles from './StylishTicket.module.scss';
import { buildBoostCost } from './actions/shiftCalc';
import { DAYS } from '../../constants';

export default ({
  player,
  ticket,
  shifts,
  characters,
  eventName,
  isEventRunner,
  isNational,
  isPremiere,
  isVirtual,
  eventLocation,
  eventStartDate,
  eventEndDate,
  eventAttendeeID,
  eventBaseBuild,
  eventInviter,
  mods,
  eventConfig,
}) => {
  const extraBuild = Object.values(characters).map(x => x.build_growth)[0] || 0;
  const [modsSignedUp, setModsSignedUp] = useState([]);
  const [isFetchingMods, setIsFetchingMods] = useState(false);

  const listShifts = () =>
    shifts.length === 0 ? (
      <div className={styles.list}>
        <div className={styles.shiftKind} />
        <div className={styles.label}>No assigned shifts</div>
      </div>
    ) : (
      shifts
        .sort((a, b) => a.identifier - b.identifier)
        .map(shift => (
          <div className={styles.list} key={shift.identifier}>
            <div className={styles.shiftKind}>
              {DAYS[parseInt(parseInt(shift.identifier) / 100)]}
            </div>
            <div className={styles.label}>{shift.label}</div>
          </div>
        ))
    );

  const invitationWaiver = eventInviter
    ? `(${ticket.identifier === 1 ? `Waived via Referral` : `Referral`} #${
        eventInviter.id
      })`
    : '';
  const extraBuildLabel = extraBuild > 0 ? ` (+${extraBuild} Build)` : null;
  const extraBuildCost =
    extraBuild > 0 && !isEventRunner
      ? ` +$${buildBoostCost({
          buildBoost: extraBuild,
          isEventRunner,
          isNational,
          isPremiere,
          isVirtual,
          eventConfig,
        })}`
      : null;

  const listCharacters = () =>
    characters
      .sort((a, b) => (a.character.id > b.character.id ? 1 : -1))
      .map(atc => (
        <div className={styles.list} key={atc.character.id}>
          <div className={styles.label}>{atc.character.name}</div>
        </div>
      ));

  const listMods = () =>
    modsSignedUp
      .sort((a, b) => a.mod_section.id - b.mod_section.id)
      .map(mod => (
        <div className={styles.list} key={mod.id}>
          <div className={styles.shiftKind}>
            {mod.admittance_position > mod.participation_limit
              ? `Wait #${mod.admittance_position - mod.participation_limit}`
              : `Seat #${mod.admittance_position}`}
          </div>
          <div className={styles.label}>
            <div className={styles.modTitle}>
              <div className={styles.underlined}>{mod.mod_section.name}</div>
              <div>{mod.name}</div>
            </div>
            <div className={styles.modTimeframe}>{mod.timeframe}</div>
          </div>
        </div>
      ));

  useEffect(() => {
    setModsSignedUp([]);
    setIsFetchingMods(true);
  }, [player]);

  useEffect(() => {
    setModsSignedUp(
      Object.keys(mods)
        .filter(x => mods[x].admittance_position)
        .map(x => mods[x]),
    );
    setIsFetchingMods(false);
  }, [mods]);

  return (
    <div className={styles.container}>
      <div className={styles.landscape}>
        <div className={styles.drLabel}>Dystopia Rising</div>
        <div className={styles.eventName}>{eventName}</div>
        <div className={styles.logistics}>
          <div className={styles.row}>
            <div className={styles.left}>{eventLocation}</div>
            <div className={styles.right}>
              {`#${player.id} `}
              {[player.first_name, player.last_name].join(' ').trim()}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.left}>
              {eventStartDate
                ? format(parseISO(eventStartDate), 'MMM dd')
                : null}
              &nbsp;-&nbsp;
              {eventEndDate ? format(parseISO(eventEndDate), 'MMM dd') : null}
            </div>
            <div className={styles.right}>
              {ticket.label}
              {extraBuildLabel}
            </div>
          </div>
          <div className={styles.row}>
            <div className={[styles.left, styles.muted].join(' ')}>
              {`Reg ID #${eventAttendeeID}`}
            </div>
            <div className={styles.right}>
              {`$${ticket.cost} ${invitationWaiver}`}
              {extraBuildCost}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.portrait}>
        <div className={styles.header}>
          <div className={styles.eventName}>{eventName}</div>
          <div className={styles.playerName}>
            {[player.first_name, player.last_name].join(' ').trim()}
          </div>
        </div>
        <div className={styles.qrCode}>
          <QRCode
            value={`https://characters.dystopiarisingnetwork.com/attendees/${eventAttendeeID}`}
            includeMargin={true}
            level='M'
            fgColor='#ccc'
            bgColor='#000'
            renderAs='svg'
          />
        </div>
        <div className={styles.listGroup}>
          <div className={styles.header}>Checked-In Characters</div>
          <div className={styles.content}>{listCharacters()}</div>
        </div>
        <div className={styles.listGroup}>
          <div className={styles.header}>Shifts Informations</div>
          <div className={styles.content}>{listShifts()}</div>
        </div>
        <div className={styles.listGroup}>
          <div className={styles.header}>Mods Informations</div>
          <div className={styles.content}>
            {isFetchingMods
              ? 'Fetching Mods Data...'
              : modsSignedUp.length > 0
              ? listMods()
              : 'No Mods Signed Up'}
          </div>
        </div>
      </div>
    </div>
  );
};
