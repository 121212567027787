import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './NotesContainer.module.scss';
import TextArea from '../../Shared/FormComponents/TextArea';
import { isPrivileged as hasPrivilege } from '../../utils/user';

export default ({ divider }) => {
  const dispatch = useDispatch();
  const { notes, user } = useSelector(state => ({
    notes: state.character.notes,
    user: state.user,
  }));
  const [content, setContent] = useState(notes);
  const isPrivileged = hasPrivilege(user);
  const updateNotes = event => {
    if (!isPrivileged) return;

    setContent(event.target.value);
    dispatch({ type: 'UPDATE_NOTES', payload: event.target.value });
  };
  const renderDivider = () => (isPrivileged || notes ? divider : null);
  const renderTextarea = () =>
    isPrivileged ? (
      <TextArea
        content={content || ''}
        placeholder=''
        handleUpdate={updateNotes}
      />
    ) : (
      <div className={styles.noneditableText}>{notes || ''}</div>
    );

  useEffect(() => {
    setContent(notes);
  }, [notes]);

  if (!isPrivileged) return null;

  return (
    <React.Fragment>
      {renderDivider()}
      <div className={styles.container}>{renderTextarea()}</div>
    </React.Fragment>
  );
};
