export default ({ state, payload }) => {
  const changes = {
    emailAddress: payload.email_address,
    firstName: payload.first_name,
    lastName: payload.last_name,
    branchId: payload.branch.id,
    branchTransfers: payload.branch_transfers,
    membership: payload.membership,
    memberships: payload.memberships,
    membershipValidUntil: payload.membership_expiration,
    role: payload.role,
    notes: payload.notes,
    genesisDate: payload.genesis_at,
  };

  if (payload.id === state.user.id) {
    return {
      ...state,
      user: {
        ...state.user,
        ...changes,
      },
    };
  } else if (payload.id && payload.id === state.user.impersonatee.id) {
    return {
      ...state,
      user: {
        ...state.user,
        impersonatee: {
          ...state.user.impersonatee,
          ...changes,
        },
      },
    };
  } else {
    // create user
    return {
      ...state,
      user: {
        ...state.user,
        newUser: payload,
      },
    };
  }
};
