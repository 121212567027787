export const BEGIN_CHARACTER_FETCH = 'BEGIN_CHARACTER_FETCH';
export const END_CHARACTER_FETCH = 'END_CHARACTER_FETCH';
export const CLOSE_POPPER = 'CLOSE_POPPER';
export const OPEN_POPPER = 'OPEN_POPPER';
export const SWITCH_CHARACTER = 'SWITCH_CHARACTER';
export const SYNC_BUFFER = 'SYNC_BUFFER';
export const TOGGLE_COMBAT_VISIBILITY = 'TOGGLE_COMBAT_VISIBILITY';
export const TOGGLE_CIVILIZED_VISIBILITY = 'TOGGLE_CIVILIZED_VISIBILITY';
export const TOGGLE_WASTELAND_VISIBILITY = 'TOGGLE_WASTELAND_VISIBILITY';
export const TOGGLE_ANOMALY_VISIBILITY = 'TOGGLE_WASTELAND_VISIBILITY';
export const TOGGLE_COMMUNITY_VISIBILITY = 'TOGGLE_COMMUNITY_VISIBILITY';
