import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { isEqual } from 'lodash';
import buildPayload from '../../Character/actions/buildPayload';
import styles from './Revertable.module.scss';

export default () => {
  const dispatch = useDispatch();
  const { character } = useSelector(state => ({
    character: state.character,
  }));
  const currentPayload = buildPayload(character);
  const isDirty = !isEqual(currentPayload, character.dirtyTracking);
  const revert = () => dispatch({ type: 'REVERT_TO_CHECKPOINT' });

  return !isDirty ? null : (
    <div className={styles.container}>
      <div onClick={revert} className={styles.button}>
        Revert Changes
      </div>
    </div>
  );
};
