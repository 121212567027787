import { fromPairs } from 'lodash';

const userIdentifier = user => `${user.full_name} (#${user.id})`;
const branchesIdentifier = branches =>
  `[Runs ${branches.map(x => x.name).join(',')}]`;
const validExternalIdentifier = manager => ({
  user: `${userIdentifier(manager.user)} ${branchesIdentifier(
    manager.user.owned_branches,
  )}`,
});

export default payload => {
  if (payload.messages) {
    return fromPairs(
      payload.messages
        .map(x => [x.user_id, { message: x.message }])
        .concat(
          payload.temporary_managers.map(x => [
            x.user.id,
            validExternalIdentifier(x),
          ]),
        ),
    );
  }

  return fromPairs(payload.map(x => [x.user.id, validExternalIdentifier(x)]));
};
