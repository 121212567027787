import lineages from './lineages';

export default {
  digitarian: {
    name: 'Digitarian',
    trait: '',
    lineage: lineages.elitariat,
    remoteID: 3,
  },
  solestros: {
    name: 'Solestros',
    trait: '',
    lineage: lineages.elitariat,
    remoteID: 20,
  },
  pureBlood: {
    name: 'Pure Blood',
    trait: '',
    lineage: lineages.elitariat,
    remoteID: 9,
  },
  baywalker: {
    name: 'Baywalker',
    trait: '',
    lineage: lineages.townie,
    remoteID: 1,
  },
  yorker: {
    name: 'Yorker',
    trait: '',
    lineage: lineages.townie,
    remoteID: 16,
  },
  vegasian: {
    name: 'Vegasian',
    trait: '',
    lineage: lineages.townie,
    remoteID: 15,
  },
  dieselJock: {
    name: 'Diesel Jock',
    trait: '',
    lineage: lineages.nomad,
    remoteID: 17,
  },
  rover: {
    name: 'Rover',
    trait: '',
    lineage: lineages.nomad,
    remoteID: 13,
  },
  saltwise: {
    name: 'Saltwise',
    trait: '',
    lineage: lineages.nomad,
    remoteID: 19,
  },
  fullDead: {
    name: 'Full Dead',
    trait: '',
    lineage: lineages.gorger,
    remoteID: 2,
  },
  semperMort: {
    name: 'Semper Mort',
    trait: '',
    lineage: lineages.gorger,
    remoteID: 14,
  },
  lascarian: {
    name: 'Lascarian',
    trait: '',
    lineage: lineages.gorger,
    remoteID: 5,
  },
  remnant: {
    name: 'Remnant',
    trait: '',
    lineage: lineages.mutant,
    remoteID: 11,
  },
  retrograde: {
    name: 'Retrograde',
    trait: '',
    lineage: lineages.mutant,
    remoteID: 12,
  },
  tainted: {
    name: 'Tainted',
    trait: '',
    lineage: lineages.mutant,
    remoteID: 22,
  },
  merican: {
    name: 'Merican',
    trait: '',
    lineage: lineages.landsman,
    remoteID: 6,
  },
  naturalOne: {
    name: 'Natural One',
    trait: '',
    lineage: lineages.landsman,
    remoteID: 8,
  },
  quietFolk: {
    name: 'Quiet Folk',
    trait: '',
    lineage: lineages.landsman,
    remoteID: 23,
  },
  accensorite: {
    name: 'Accensorite',
    trait: '',
    lineage: lineages.devoted,
    remoteID: 7,
  },
  redStar: {
    name: 'Red Star',
    trait: '',
    lineage: lineages.devoted,
    remoteID: 10,
  },
  unborn: {
    name: 'Unborn',
    trait: '',
    lineage: lineages.devoted,
    remoteID: 21,
  },
  iron: {
    name: 'Iron',
    trait: '',
    lineage: lineages.evolved,
    remoteID: 4,
  },
  reclaimer: {
    name: 'Reclaimer',
    trait: '',
    lineage: lineages.evolved,
    remoteID: 18,
  },
  unstable: {
    name: 'Unstable',
    trait: '',
    lineage: lineages.evolved,
    remoteID: 24,
  },
  default: {
    name: '!!Not Selected!!',
    trait: '',
    lineage: lineages.default,
    remoteID: 0,
  },
};
