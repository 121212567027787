export const hasValidToken = authConfig => {
  if (
    authConfig === null ||
    !('expiration' in authConfig) ||
    authConfig.expiration === null
  )
    return false;
  if (Date.now() > authConfig.expiration) return false;
  return true;
};

export const ageLessThanMs = (authConfig, ms) => {
  return hasValidToken(authConfig) && Date.now() - authConfig.initiation < ms;
};
