import axios from 'axios';
import Toast from '../../Shared/Toastify/Toast';
import { put } from 'redux-saga/effects';

export default function* (authConfig, payload) {
  const userID = payload.userID || payload.id;
  const url = `/users/${userID}`;

  try {
    const res = yield axios.get(url, authConfig);

    yield put({
      type: 'FETCH_REMOTE_METADATA',
      payload: {
        data: res.data,
        isImpersonating: payload.isImpersonating,
        tokenInitiation: authConfig.initiation,
      },
    });
    yield put({ type: 'FETCH_REMOTE_CHARACTER' });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
    if (payload.isImpersonating) {
      yield put({
        type: 'USER_IMPERSONATE_ERROR',
        payload: {
          message: error.response.data.message,
          data: {
            id: payload.userID,
          },
        },
      });
    }
  }
}
