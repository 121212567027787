import { fromPairs, groupBy } from 'lodash';
import { isMembershipActive } from '../../../utils/user';

const hashedCharacters = attendeeCharacters =>
  fromPairs(
    attendeeCharacters.map(attendeeCharacter => [
      attendeeCharacter.id,
      {
        id: attendeeCharacter.character.id,
        name: attendeeCharacter.character.name,
        buildGrowth: attendeeCharacter.build_growth,
        isPaid: attendeeCharacter.payment === 'paid',
        isAttending: attendeeCharacter.attendance === 'attending',
        attendeeCharacterID: attendeeCharacter.id,
        status: attendeeCharacter.character.status,
      },
    ]),
  );

export const updateEventAttendee = (
  eventAttendee,
  attendeeCharacters,
  existingCharacters,
) => {
  const mergedChanges = Object.assign(
    existingCharacters,
    hashedCharacters(attendeeCharacters),
  );

  return {
    isPaid: Object.values(mergedChanges).every(x => x.isPaid),
    isAttending: Object.values(mergedChanges).every(x => x.isAttending),
    characters: mergedChanges,
    buildGrowth: Object.values(mergedChanges)[0].buildGrowth,
  };
};

export const populateEventAttendeeLogs = attendees => {
  return fromPairs(
    attendees.map(attendee => [
      attendee.id,
      {
        name: [attendee.user.first_name, attendee.user.last_name]
          .join(' ')
          .trim(),
        playerID: attendee.user.id,
        role: attendee.user.role,
        attendeeID: attendee.id,
        ticketIdentifier: '',
        characters: attendee.characters,
        logs: attendee.logs,
      },
    ]),
  );
};

export const populateEventAttendees = (attendees, eventStartTime) => {
  if (!attendees) return {};

  // console.log(attendees)

  return fromPairs(
    attendees.map(attendee => [
      attendee.id,
      {
        name: [attendee.user.first_name, attendee.user.last_name]
          .join(' ')
          .trim(),
        playerID: attendee.user.id,
        role: attendee.user.role,
        isTraveler: attendee.attendee_characters.every(
          x => x.kind !== 'home_game',
        ),
        isAdvancedMember: isMembershipActive(attendee.user, eventStartTime),
        shifts: attendee.shifts,
        ticketType: attendee.ticket.label,
        ticketIdentifier: attendee.ticket.identifier,
        ticketLabel: attendee.ticket.label,
        ticketCost: attendee.ticket.cost,
        buildGrowth: attendee.attendee_characters[0]
          ? attendee.attendee_characters[0].build_growth
          : 0,
        isPaid: attendee.attendee_characters.every(x => x.payment === 'paid'),
        isAttending: attendee.attendee_characters.every(
          x => x.attendance === 'attending',
        ),
        attendeeID: attendee.id,
        characters: hashedCharacters(attendee.attendee_characters),
        inviter: attendee.inviter,
      },
    ]),
  );
};

export const populateAttendeeShifts = attendeeShifts =>
  groupBy(attendeeShifts, x => parseInt(parseInt(x.identifier) / 100));
