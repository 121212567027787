import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WindowedScrollbar from '../Shared/WindowedScrollbar';
import CharacterLoader from '../utils/loader';
import styles from './Staffs.module.scss';

export default () => {
  const dispatch = useDispatch();
  const { staffs, currentCharacterID, characterStorage } = useSelector(
    state => ({
      staffs: state.staffs,
      currentCharacterID: state.localStorage.currentCharacterID,
      characterStorage: state.localStorage.characterStorage,
    }),
  );

  const renderableBranches = staffs.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  const renderStaff = staff => (
    <div className={styles.staff} key={staff.id}>
      <div className={styles.name}>
        {[staff.first_name, staff.last_name].join(' ').trim()}
      </div>
      <div className={styles.email}>{staff.email_address}</div>
    </div>
  );

  const renderStaffs = entities =>
    entities.length === 0 ? (
      <div className={styles.noOwner}>No owners</div>
    ) : (
      entities
        .sort((a, b) => a.first_name.localeCompare(b.first_name))
        .map(x => renderStaff(x))
    );

  const renderBranch = branch => (
    <div className={styles.branch} key={branch.name}>
      <div className={styles.branchHeader}>{branch.name}</div>
      {renderStaffs(branch.owners)}
    </div>
  );

  const renderBranches = () =>
    renderableBranches.map(branch => renderBranch(branch));

  useEffect(() => {
    dispatch({ type: 'APP_LOADED' });
  }, [dispatch]);

  useEffect(() => {
    CharacterLoader({ characterStorage, currentCharacterID, dispatch });
  }, [characterStorage, currentCharacterID, dispatch]);

  useEffect(() => {
    dispatch({ type: 'FETCH_STAFFS' });
  }, [dispatch]);

  return (
    <WindowedScrollbar styles={styles} autoHeightMax='calc(100vh)'>
      <div className={styles.container}>
        <div className={styles.header}>Staffs</div>
        {renderBranches()}
      </div>
    </WindowedScrollbar>
  );
};
