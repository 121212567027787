import axios from 'axios';
import { fromPairs } from 'lodash';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import Toast from '../Shared/Toastify/Toast';

function* fetchBranchLocations({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    const res = yield axios.get(`/branches/${payload.branchId}`, authConfig);
    const locations = fromPairs(
      res.data.branch_locations.map(x => [x.location.id, x]),
    );
    yield put({
      type: 'FETCH_BRANCH_LOCATIONS_SUCCEEDED',
      payload: {
        branchId: res.data.id,
        locations,
      },
    });
  } catch (e) {
    Toast({ text: e.response.data.message, type: 'error' });
  }
}

function* updateBranchLocation({ payload }) {
  const authConfig = yield select(state => state.user.session);

  try {
    yield axios.patch(
      `/branches/${payload.branchId}/locations/${payload.locationData.id}`,
      payload.locationData,
      authConfig,
    );
    Toast({ text: 'Location updated successfully', type: 'success' });
  } catch (e) {
    Toast({ text: e.response.data.message, type: 'error' });
  }
}

function* watchFetchBranchLocations() {
  yield takeLatest('FETCH_BRANCH_LOCATIONS', fetchBranchLocations);
}

function* watchUpdateBranchLocation() {
  yield takeLatest('UPDATE_BRANCH_LOCATION', updateBranchLocation);
}

export default function* () {
  yield all([watchFetchBranchLocations(), watchUpdateBranchLocation()]);
}
