import { fromPairs } from 'lodash';

const hashify = branchLocations =>
  fromPairs(
    branchLocations.map(branchLocation => [
      branchLocation.location.id,
      {
        priority: branchLocation.priority,
        name: branchLocation.location.name,
      },
    ]),
  );

const arbitrateSelectedLocation = ({
  hashifiedLocations,
  branchLocations,
  selectedLocation,
}) => {
  if (selectedLocation && hashifiedLocations[selectedLocation])
    return selectedLocation;

  return branchLocations && branchLocations.length > 0
    ? branchLocations[0].location.id
    : 0;
};

export default (branchLocations, selectedLocation) => {
  const hashifiedLocations = hashify(branchLocations);

  return {
    locations: hashifiedLocations,
    selectedLocation: arbitrateSelectedLocation({
      hashifiedLocations,
      branchLocations,
      selectedLocation,
    }),
  };
};
