export default ({ attendeeShifts, payload }) => {
  const dayKey = parseInt(parseInt(payload.identifier, 10) / 100, 10);
  const shiftBlocks = attendeeShifts[dayKey];
  const affectedShifts = shiftBlocks.map(x =>
    x.identifier === payload.identifier
      ? { ...x, [payload.kind]: payload.value }
      : x,
  );

  return {
    ...attendeeShifts,
    [dayKey]: affectedShifts,
  };
};
