import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ItemTransactionAudit from './ItemTransactionAudit';

export default ({ isAuditCapable, isPostie }) => {
  const { eventInfo } = useSelector(state => ({
    eventInfo: state.eventAdministration.eventInfo,
  }));

  const { id: eventId } = eventInfo;

  return (
    <ItemTransactionAudit
      eventId={eventId}
      isPrivileged={isAuditCapable}
      isPostie={isPostie}
    />
  );
};
