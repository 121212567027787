import { range } from 'lodash';
import {
  addDays,
  addMonths,
  differenceInMonths,
  getYear,
  getMonth,
  isFriday,
} from 'date-fns';
import { isPrivileged, isBranchOwner, isAdmin } from './user';

const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getFridays = ({ year, month }) => {
  const baseDate = new Date(year, month - 1); // month is 0-indexed

  const firstFriday =
    range(7)
      .map(x => addDays(baseDate, x))
      .map(x => isFriday(x))
      .indexOf(true) + 1;

  return range(6)
    .map(x => new Date(year, month - 1, firstFriday + 7 * x))
    .filter(x => getMonth(x) === month - 1);
};

export const monthName = monthIndex => MONTH_NAMES[monthIndex - 1];

export const currentYearMonth = (today = new Date()) => {
  return {
    year: getYear(today),
    month: getMonth(today),
  };
};

export const visibleDatePoints = ({ start, end, adjustment = 0 }) => {
  if (adjustment === 0) {
    const present = currentYearMonth();

    return {
      start: addMonths(new Date(present.year, present.month), -1),
      end: addMonths(new Date(present.year, present.month), 3),
    };
  }
  if (adjustment < 0) {
    return {
      start: addMonths(start, adjustment),
      end,
    };
  }

  return {
    start,
    end: addMonths(end, adjustment),
  };
};

export const visibleDateRange = (start, end) =>
  range(differenceInMonths(end, start))
    .map(x => addMonths(start, x))
    .map(x => ({ year: getYear(x), month: getMonth(x) }));

export const isEventRunner = ({ event, user }) =>
  isAdmin(user) ||
  (isPrivileged(user) && user.ownedBranches.includes(event.branchID));

export const isExternalRunner = ({ event, eventsRun, user }) =>
  isBranchOwner(user) && eventsRun.some(x => x.id === event.eventID);

export const isOwnerOrExternal = ({ event, eventsRun, user }) =>
  isEventRunner({ event, user }) ||
  isExternalRunner({ event, eventsRun, user });
