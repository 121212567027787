export const initialState = null;

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'LOAD_MODAL':
      return initialState;
    case 'RESET_PASSWORD_PENDING':
      return 'pending';
    case 'RESET_PASSWORD_SUCCESS':
      return 'success';
    case 'RESET_PASSWORD_ERROR':
      return payload;
    default:
      return state;
  }
};
