import React from 'react';
import styles from './SuspensionMessage.module.scss';

export default ({ isSuspended, contacts }) => {
  const contactLists = contacts
    .sort((a, b) => a.first_name.localeCompare(b.first_name))
    .map(x => (
      <div className={styles.row} key={x.email_address}>
        <div className={styles.name}>
          {[x.first_name, x.last_name].join(' ').trim()}
        </div>
        <div className={styles.email}>{x.email_address}</div>
      </div>
    ));

  if (!isSuspended) return null;

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        You have active Suspension and/or Refusal of Service
      </div>
      <div className={styles.subTitle}>
        Please contact your Branch Owners for resolution:
      </div>
      <div className={styles.contacts}>{contactLists}</div>
    </div>
  );
};
