import { addDays, format, formatDistanceToNow } from 'date-fns';

export const isAdmin = user => user.role === 'admin';
export const isBranchOwner = user => user.role === 'branch_owner';
export const isGuide = user => user.role === 'guide';
export const isGuideWithLogisticsPrivilege = user =>
  isGuide(user) && user.flags.includes('logistics');
export const ownsBranch = (user, branchID) =>
  user.ownedBranches.includes(branchID);

export const isImpersonating = user =>
  user.impersonatee.id && !user.impersonatee.isError;
export const isPrivileged = user => isAdmin(user) || isBranchOwner(user);

export const isEditable = (user, approvedForPlay) =>
  isPrivileged(user) || !approvedForPlay;

export const membershipValidityPeriod = user =>
  user.membershipValidUntil
    ? {
        calendarDate: format(Date.parse(user.membershipValidUntil), 'M/d/yy'),
        humanDate: formatDistanceToNow(Date.parse(user.membershipValidUntil), {
          addSuffix: true,
        }),
      }
    : null;

export const fullName = user =>
  [user.firstName, user.lastName].join(' ').trim();

export const isMembershipActive = (user, time) =>
  user.memberships.some(
    x =>
      addDays(Date.parse(x.starts_at), -3) < time &&
      Date.parse(x.ends_at) > time,
  );
