import React from 'react';
import styles from '../../Character/Stat/Button.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Close from '@material-ui/icons/Close';

const wrapRemote = ({ plannedTotal, remoteTotal, isEditable }) => {
  if (isEditable || (!isEditable && plannedTotal === remoteTotal))
    return <div>{plannedTotal}</div>;

  if (!remoteTotal) return <div>...</div>;
  return (
    <div style={{ marginTop: '-2px' }}>
      {`${remoteTotal} → ${plannedTotal}`}
    </div>
  );
};

const getClassName = anchorEl =>
  [styles.statgrid, anchorEl === null ? '' : styles.overlayActive].join(' ');

export default ({
  handleToggle,
  overlayRef,
  innate,
  acquired,
  reduction,
  isEditable,
  remoteTotal,
  anchorEl,
  statType,
  icon,
  styleOverride,
}) => (
  <div
    className={getClassName(anchorEl)}
    onClick={handleToggle}
    ref={overlayRef}
    style={styleOverride}
  >
    <div className={styles.attribute}>
      {anchorEl ? (
        <Close className={styles.upshift} fontSize='small' />
      ) : (
        <FontAwesomeIcon
          className={`${styles.icon} ${styles[statType]}`}
          icon={icon}
        />
      )}
    </div>
    {wrapRemote({
      plannedTotal: innate + acquired - (reduction || 0),
      remoteTotal,
      isEditable,
    })}
  </div>
);
