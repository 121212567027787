export const initialState = null;

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'UPDATE_SEARCH_RESULT':
      return payload;
    case 'CLEANUP_PREVIOUS_SESSION':
    case 'NUKE_DUE_TO_TOKEN_EXPIRATION':
    case 'DELETE_SESSION':
    case 'LOGOUT_ALL_SESSIONS':
    case 'CLEANUP_SEARCH_RESULT':
      return initialState;
    case 'SEARCH_USER_ERROR':
      return initialState;
    default:
      return state;
  }
};
