import { useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import Popper from '@material-ui/core/Popper';
import Slide from '@material-ui/core/Slide';
import Inactive from '@material-ui/icons/NotInterested';
import Retired from '@material-ui/icons/Hotel';
import Staged from '@material-ui/icons/SettingsEthernet';
import Synchronized from '@material-ui/icons/VerifiedUser';
import DropdownField from '../../Shared/FormComponents/DropdownField';
import CharacterRetirement from './CharacterRetirement';
import styles from './AdminAccessInfo.module.scss';

const PREVENT_CLOSE = true;
const ALLOW_CLOSE = false;

export default ({
  isAdminMode,
  isRemote,
  hasValidToken,
  popper,
  characterData,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tab, setTab] = useState('status');
  const dispatch = useDispatch();
  const popperName = 'stagedInfo';
  const open = Boolean(anchorEl);
  const id = open ? 'admin-access' : undefined;
  const ref = useRef(null);
  const remoteStatus = characterData && characterData.remoteStatus;
  const isRetired = remoteStatus === 'retired';
  const characterName = characterData && characterData.name;
  const characterID = characterData && characterData.remoteID;
  const retirementBuild = characterData && characterData.retirementBuild;
  const futureAttendances = characterData && characterData.futureAttendances;
  const options = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
    { value: 'staged', label: 'Staged' },
  ];
  const handleStatusChange = (key, value) =>
    dispatch({
      type: 'UPDATE_CHARACTER_PLAYABLE_STATE',
      payload: value,
    });
  const handleRetire = () => {
    dispatch({ type: 'TOGGLE_POPPER', payload: 'characterSelector' });
    dispatch({
      type: 'RETIRE_CHARACTER',
      payload: characterID,
    });
  };
  const text = () => {
    if (!isRemote) return null;
    switch (remoteStatus) {
      case 'active':
        return 'This character is Active. It can be played and receive XP from Checkin.';
      case 'inactive':
        return 'This character is Inactive. It cannot be played and will not receive any further XP.';
      case 'staged':
        return 'This character is Staged. It can receive XP from Checkin but it cannot be played.';
      case 'retired':
        return 'This character is Retired. It can not be brought into game and will not receive any further XP.';
      default:
        return null;
    }
  };

  const control = (
    <React.Fragment>
      {text()}
      <div
        className={styles.controlRow}
        onClick={event => event.preventDefault()}
      >
        <DropdownField
          fieldKey='characterState'
          hidden={!isAdminMode || remoteStatus === 'retired'}
          title='Status'
          value={remoteStatus}
          options={options}
          passChange={handleStatusChange}
          initialValue={remoteStatus}
          revert='never'
        />
      </div>
    </React.Fragment>
  );

  const activeTab = currentTab =>
    [styles.tab, currentTab === tab ? styles.active : ''].join(' ');

  const tabbed = (
    <div className={styles.tabControl}>
      <div className={activeTab('status')} onClick={() => setTab('status')}>
        Status
      </div>
      <div
        className={activeTab('retirement')}
        onClick={() => setTab('retirement')}
      >
        Retirement
      </div>
    </div>
  );

  const icon = () => {
    if (!isRemote) return null;
    switch (remoteStatus) {
      case 'active':
        return <Synchronized className={styles.privileged} />;
      case 'inactive':
        return <Inactive className={styles.privileged} />;
      case 'staged':
        return <Staged className={styles.privileged} />;
      case 'retired':
        return <Retired className={styles.privileged} />;
      default:
        return null;
    }
  };

  const handleClick = preventClose => {
    if (preventClose && popper === popperName) return;
    dispatch({ type: 'TOGGLE_POPPER', payload: popperName });
  };

  useEffect(() => {
    setAnchorEl(popper === popperName ? ref.current : null);
  }, [popper, ref, setAnchorEl]);

  useEffect(() => {
    if (popper !== popperName) return;
    if (!characterID || remoteStatus === 'retired') return;
    dispatch({
      type: 'FETCH_CHARACTER_RETIREMENT_BUILD',
      payload: characterID,
    });
  }, [characterID, dispatch, remoteStatus, popper]);

  useEffect(() => {
    setTab('status');
  }, [characterID]);

  if (!isAdminMode && !isRemote && remoteStatus === 'active') return null;

  return (
    <div ref={ref} onClick={() => handleClick(PREVENT_CLOSE)}>
      {icon()}
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={styles.overlayContainer}
        transition
      >
        {({ TransitionProps }) => (
          <Slide {...TransitionProps} timeout={300}>
            <div className={styles.overlay}>
              {!isRetired && isAdminMode && tabbed}
              {tab === 'status' ? (
                control
              ) : (
                <CharacterRetirement
                  remoteStatus={remoteStatus}
                  characterName={characterName}
                  passRetire={handleRetire}
                  retirementBuild={retirementBuild}
                  futureAttendances={futureAttendances}
                  isAdminMode={isAdminMode}
                />
              )}

              <div
                className={styles.fakeClose}
                onClick={() => handleClick(ALLOW_CLOSE)}
              >
                Close
              </div>
            </div>
          </Slide>
        )}
      </Popper>
    </div>
  );
};
