import AddCharacter from '@material-ui/icons/NoteAdd';
import React from 'react';
import styles from './NewCharacter.module.scss';

export default ({ handleNewCharacter }) => (
  <div className={styles.new} onClick={handleNewCharacter}>
    <div className={styles.row}>
      <div className={styles.text}>Add Character</div>
      <AddCharacter />
    </div>
  </div>
);
