import React from 'react';
import { range } from 'lodash';
import styles from './Character.module.scss';

export default () => (
  <div className={styles.newLearnings}>
    <table className={styles.table}>
      <thead>
        <tr>
          <th className={styles.leftSide}>New Learnings</th>
          <th className={styles.rightSide}>XP</th>
        </tr>
      </thead>
      <tbody>
        {range(8).map(i => (
          <tr className={styles.placeholder} key={i}>
            <td />
            <td />
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);
